export enum productIdToProductNameEnum {
    AANS = 'aansprakelijkheidsverzekering',
    AUTO = 'autoversicherung',
    BROM = 'brommerverzekering',
    CVAN = 'caravanverzekering',
    DIER = 'dierenverzekering',
    INBL = 'inboedelverzekering',
    INSH = 'InShared',
    MOTR = 'motorverzekering',
    ONGV = 'ongevallenverzekering',
    OPST = 'opstalverzekering',
    RB = 'rechtsbijstandverzekering',
    REIS = 'reisverzekering',
    WOON = 'woonverzekering',
    SCHD = 'schade',
    SCHH = 'schadeherstel',
    SVJR = 'schadevrije-jaren',
    FIET = 'fietsverzekering',
}

export enum ProductIdToProductNameUpperEnum {
    AANS = 'Aansprakelijkheidsverzekering',
    AUTO = 'Autoversicherung',
    BROM = 'Brommerverzekering',
    CVAN = 'Caravanverzekering',
    DIER = 'Dierenverzekering',
    INBL = 'Inboedelverzekering',
    INSH = 'InShared',
    MOTR = 'Motorverzekering',
    ONGV = 'Ongevallenverzekering',
    OPST = 'Opstalverzekering',
    RB = 'Rechtsbijstandverzekering',
    REIS = 'Reisverzekering',
    WOON = 'Woonverzekering',
    SCHD = 'Schade',
    SCHH = 'Schadeherstel',
    SVJR = 'Schadevrije-jaren',
    FIET = 'Fietsverzekering',
}
