<form (ngSubmit)="onFormSubmit()" *ngIf="!relation?.family_composition_future" [formGroup]="form">
    <cyno-field>
        <cyno-label>Familienzusammensetzung</cyno-label>
        <select
            cynoSelect
            formControlName="familyComposition"
            id="gezinssamenstelling-gezinssamenstelling"
            e2e="select-familiy-composition"
        >
            <ng-container *ngIf="familyCompositions">
                <option *ngFor="let composition of familyCompositions" [ngValue]="composition">
                    {{ composition.content_description }}
                </option>
            </ng-container>
        </select>
    </cyno-field>
    <cyno-field>
        <cyno-label>Beginndatum</cyno-label>
        <ins-datepicker
            [minDate]="minDate"
            separator="."
            formControlName="startDate"
            id="gezinssamenstelling-ingangsdatum"
        ></ins-datepicker>
    </cyno-field>
    <ins-alert *ngIf="errors.length > 0" [errors]="errors" type="validation"></ins-alert>
    <ins-details-submit-button [disabled]="loading">Speichern</ins-details-submit-button>
</form>
