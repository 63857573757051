import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID, Renderer2, RendererFactory2 } from '@angular/core';
import { ANALYTICS_CONFIGURATION } from '../configuration';
import { CynolyticsTypeEnum } from '../enums';
import {
    AnalyticsConfigurationInterface,
    ApiCynolyticsEventInterface,
    ApiCynolyticsNotificationInterface,
    ApiCynolyticsPageviewInterface,
    CynoLytics,
    CynoLyticsConfig,
    CynolyticsEventInterface,
    CynolyticsNotificationInterface,
    CynolyticsPageviewInterface,
} from '../interfaces';
import {
    cynolyticsEventRequestMapper,
    cynolyticsNotificationMapper,
    cynolyticsPageviewMapper,
} from '../mappers/cynolytics.request.mapper';

@Injectable({
    providedIn: 'root',
})
export class CynolyticsApiService {
    public _cs: CynoLyticsConfig;
    private renderer: Renderer2;

    constructor(
        private rendererFactory: RendererFactory2,
        @Inject(ANALYTICS_CONFIGURATION) private configuration: AnalyticsConfigurationInterface,
        @Inject(DOCUMENT) private document: Document,
        @Inject(PLATFORM_ID) private platformId: any
    ) {
        if (typeof window !== 'undefined' || Boolean(this.configuration.cynolyticsUrl)) {
            this.init();
        }
    }

    public send(data: CynolyticsEventInterface | CynolyticsNotificationInterface | CynolyticsPageviewInterface): void {
        if (typeof window !== 'undefined' || Boolean(this.configuration.cynolyticsUrl)) {
            let mappedData;

            const { type } = data;
            switch (type) {
                case CynolyticsTypeEnum.Event: {
                    mappedData = cynolyticsEventRequestMapper(data as CynolyticsEventInterface);
                    break;
                }
                case CynolyticsTypeEnum.Notification: {
                    mappedData = cynolyticsNotificationMapper(data as CynolyticsNotificationInterface);
                    break;
                }
                case CynolyticsTypeEnum.Pageview: {
                    mappedData = cynolyticsPageviewMapper(data as CynolyticsPageviewInterface);
                    break;
                }
            }
            this.sendToCynoLytics(mappedData);
        }
    }

    private sendToCynoLytics(
        data: ApiCynolyticsEventInterface | ApiCynolyticsNotificationInterface | ApiCynolyticsPageviewInterface
    ): void {
        const { type } = data;
        const cl = this.getCynolytics();

        if (typeof cl === 'undefined') {
            return;
        }

        cl('send', type, data);
    }

    private init(): void {
        this.setConfig();
        this.addCynoLyticsScript();
    }

    private getConfig(): CynoLyticsConfig {
        window._cs = window._cs || {};
        return window._cs;
    }

    private getCynolytics(): CynoLytics {
        window.cl =
            window.cl ||
            function (): void {
                {
                }
            };
        return window.cl;
    }

    private setConfig(): void {
        const config = this.getConfig();
        this._cs = {
            ...config,
            ...{
                cynolytics_url: this.configuration.cynolyticsUrl,
                cynolytics_partner_id: this.configuration.cynolyticsPartnerId, // '00001',
                taal_id: this.configuration.cynolyticsLanguageId, //'nl-NL',
            },
        };
        window._cs = this._cs;
    }

    private addCynoLyticsScript(): void {
        this.renderer = this.rendererFactory.createRenderer(null, null);

        const script = this.renderer.createElement('script');

        this.renderer.setAttribute(script, 'type', 'text/javascript');
        this.renderer.setAttribute(script, 'src', `${this._cs.cynolytics_url}/js/cs_min.js`);
        this.renderer.setProperty(script, 'async', true);

        this.document.head.appendChild(script);
    }
}
