import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { AbstractControl, UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import {
    DateService,
    ProductBaseInterface,
    ProductIdEnum,
    ProductNameEnum,
    TerminateActivePurchasedProductRequestInterface,
    TerminateReasonEnum,
} from 'outshared-lib';
import { Observable, Subscription } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { InsuranceCompanyInterface } from '@app-de/core/interfaces/insurance-company.interface';
import { InsuranceCompaniesService } from '@app-de/core/services/insurance-companies.service';
import { IntersectionObserverService } from '@app-de/core/services/intersection-observer.service';

@Component({
    selector: 'ins-terminate-insurance',
    templateUrl: 'terminate-insurance.component.html',
    styleUrls: ['terminate-insurance.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TerminateInsuranceComponent implements OnDestroy {
    @Output() public readonly destroyed: EventEmitter<void> = new EventEmitter();
    @Output() public readonly terminated: EventEmitter<TerminateActivePurchasedProductRequestInterface> =
        new EventEmitter();

    @Input() public product: ProductBaseInterface;
    @Input() public errorMessages: string[];
    @Input() public loading: boolean;

    public form: UntypedFormGroup;
    public productNameEnum: typeof ProductNameEnum = ProductNameEnum;
    public productIdEnum: typeof ProductIdEnum = ProductIdEnum;
    public terminateReasonEnum: typeof TerminateReasonEnum = TerminateReasonEnum;
    public hasExtraInfo: boolean = false;
    public isDisabled: boolean = false;
    public isFixedEndDate: boolean = false;
    public isScreenSmall$: Observable<boolean>;
    public companies$: Observable<InsuranceCompanyInterface[]>;

    private subscriptions = new Subscription();

    constructor(
        private readonly dateService: DateService,
        private intersectionObserverService: IntersectionObserverService,
        private companiesService: InsuranceCompaniesService,
    ) {
        this.isScreenSmall$ = this.intersectionObserverService.isScreenSmall$;
        this.form = this.createForm();
        this.watchReasonValueChanged();

        this.companies$ = this.companiesService
            .getInsuranceCompanies$()
            .pipe(shareReplay({ bufferSize: 1, refCount: false }));
    }

    public get reasonCode(): AbstractControl {
        return this.form.get('reasonCode');
    }

    public get confirmation(): AbstractControl {
        return this.form.get('confirmation');
    }

    public get endDate(): AbstractControl {
        return this.form.get('endDate');
    }

    public get nextInsuranceCompanyId(): AbstractControl {
        return this.form.get('nextInsuranceCompanyId');
    }

    public get nextInsuranceCompanyExternalReferenceId(): AbstractControl {
        return this.form.get('nextInsuranceCompanyExternalReferenceId');
    }

    public get today(): string {
        return this.dateService.now().toString();
    }

    public get canTerminate(): boolean {
        return (
            [
                TerminateReasonEnum.AfterAClaim,
                TerminateReasonEnum.ChangeOfInsurer,
                TerminateReasonEnum.ChangeOfTermsAndConditions,
                TerminateReasonEnum.DecommissioningWithSuspension,
                TerminateReasonEnum.Deregistration,
                TerminateReasonEnum.InsureSameCarAgain,
                TerminateReasonEnum.PremiumIncrease,
            ].includes(this.reasonCode.value) && this.confirmation.value
        );
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
        this.destroyed.emit();
    }

    public onSubmit(): boolean {
        if (!this.form.valid) {
            this.form.markAllAsTouched();
            return;
        }

        this.terminated.emit({
            endDate: this.dateService.add(this.endDate.value, 1, 'day'),
            purchasedProductSequenceNumber: this.product.purchased_product_sequence_number,
            reasonCode: this.reasonCode.value,
            nextInsuranceCompanyId: this.nextInsuranceCompanyId.value,
            nextInsuranceCompanyExternalReferenceId: this.nextInsuranceCompanyExternalReferenceId.value,
        });

        return false;
    }

    private watchReasonValueChanged(): void {
        const reasonValueChanged = this.reasonCode.valueChanges
            .pipe(map(this.validateExtraInfo))
            .subscribe((hasExtraInfo) => {
                if (hasExtraInfo) {
                    this.addFutureInsurerValidators();
                    this.validateFixedDate();
                } else {
                    this.removeFutureInsurerValidators();
                    this.removeFixedEndDate();
                }

                this.hasExtraInfo = hasExtraInfo;
            });

        this.subscriptions.add(reasonValueChanged);
    }

    // If fixed_end_date_on_cancellation equals yes and
    // reason_code equals OPWI the end_date is fixed
    // to the first of januari next year (for the backend, the user will see december 31th).
    private validateFixedDate(): void {
        if (
            this.product?.fixed_end_date_on_cancellation === 'J' &&
            this.reasonCode.value === TerminateReasonEnum.ChangeOfInsurer
        ) {
            this.isFixedEndDate = true;
            this.endDate.setValue(this.calculatedFixedEndDate());
        } else {
            this.isFixedEndDate = false;
            this.endDate.setValue('');
        }
    }

    private calculatedFixedEndDate(): string {
        const now = new Date();
        const currentYear = now.getFullYear();
        const lastDayOfDecember = new Date(currentYear, 11, 31);

        return this.dateService.format(lastDayOfDecember, 'YYYY-MM-DD');
    }

    private removeFixedEndDate(): void {
        this.isFixedEndDate = false;
        this.endDate.setValue('');
    }

    private validateExtraInfo(reason: TerminateReasonEnum): boolean {
        return [TerminateReasonEnum.ChangeOfInsurer, TerminateReasonEnum.AfterAClaim].includes(reason);
    }

    private createForm(): UntypedFormGroup {
        return new UntypedFormGroup({
            reasonCode: new UntypedFormControl(null, [Validators.required]),
            confirmation: new UntypedFormControl(false, [Validators.required]),
            endDate: new UntypedFormControl('', [Validators.required]),
            nextInsuranceCompanyId: new UntypedFormControl(null),
            nextInsuranceCompanyExternalReferenceId: new UntypedFormControl(''),
        });
    }

    private addFutureInsurerValidators(): void {
        this.nextInsuranceCompanyId.setValidators([Validators.required]);
        this.nextInsuranceCompanyExternalReferenceId.setValidators([Validators.required]);
    }

    private removeFutureInsurerValidators(): void {
        this.nextInsuranceCompanyId.clearValidators();
        this.nextInsuranceCompanyId.updateValueAndValidity();
        this.nextInsuranceCompanyExternalReferenceId.clearValidators();
        this.nextInsuranceCompanyExternalReferenceId.updateValueAndValidity();
    }
}
