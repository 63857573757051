import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    DoCheck,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    Optional,
    Self,
    ViewChild,
} from '@angular/core';
import { ControlValueAccessor, FormGroupDirective, NgControl, NgForm } from '@angular/forms';
import { DateService } from 'outshared-lib';
import { Subject } from 'rxjs';
import { CynoFieldControl } from '../core/cyno-field-control';
import { ErrorStateMatcher } from '../core/error-options';
import { CanUpdateErrorState, mixinErrorState } from '../core/error-state';
import { CynoBirthdateService } from './cyno-birthdate.service';

class CynoBirthdateBase {
    constructor(
        public _defaultErrorStateMatcher: ErrorStateMatcher,
        public _parentForm: NgForm,
        public _parentFormGroup: FormGroupDirective,
        public ngControl: NgControl,
    ) {}
}

const cynoBirthdateMixinBase = mixinErrorState(CynoBirthdateBase);

class CynoBirthdate {
    constructor(public birthdate: number = null) {}
}

@Component({
    selector: 'cyno-birthdate',
    templateUrl: './cyno-birthdate.component.html',
    providers: [{ provide: CynoFieldControl, useExisting: CynoBirthdateInputComponent }],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CynoBirthdateInputComponent
    extends cynoBirthdateMixinBase
    implements ControlValueAccessor, CynoFieldControl<CynoBirthdate>, OnInit, DoCheck, OnDestroy, CanUpdateErrorState
{
    @Input() public get value(): CynoBirthdate | null {
        return null;
    }

    public set value(birthdate: CynoBirthdate | null) {
        this.stateChanges.next();
    }

    @Input() public get placeholder(): string {
        return this._placeholder;
    }

    public set placeholder(plh) {
        this._placeholder = plh;
        this.stateChanges.next();
    }

    @Input() public get required(): boolean {
        return this._required;
    }

    public set required(req) {
        this._required = req;
        this.stateChanges.next();
    }

    @Input() public get disabled(): boolean {
        return this._disabled;
    }

    public set disabled(value: boolean) {
        this._disabled = value;
        this.stateChanges.next();
    }

    protected _disabled: boolean = false;

    public static nextId = 0;

    @Input() public separator: string;

    public readonly stateChanges: Subject<void> = new Subject<void>();

    public focused: boolean = false;
    public errorState: boolean = false;
    public controlType: string = 'cyno-date-input';

    @ViewChild('birthdate', { static: true }) public birthdate: ElementRef;

    public id = `cyno-date-input-${CynoBirthdateInputComponent.nextId++}`;
    private _placeholder: string = 'TT.MM.JJJJ';
    private _required = false;

    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        defaultErrorStateMatcher: ErrorStateMatcher,
        @Optional() parentForm: NgForm,
        @Optional() parentFormGroup: FormGroupDirective,
        @Optional() @Self() public ngControl: NgControl,
        private _elementRef: ElementRef,
        private dateService: DateService,
        private cynoBirthdateService: CynoBirthdateService,
    ) {
        super(defaultErrorStateMatcher, parentForm, parentFormGroup, ngControl);

        if (this.ngControl !== null) {
            this.ngControl.valueAccessor = this;
        }
    }

    public ngOnInit(): void {
        this.stateChanges.next();
    }

    public ngDoCheck(): void {
        if (this.ngControl) {
            this.updateErrorState();
        }
    }

    public ngOnDestroy(): void {
        this.stateChanges.complete();
    }

    /**
     * This method is called by the forms API to write to the view
     * when programmatic changes from model to view are requested.
     * @param value string
     */
    public writeValue(value: string): void {
        if (value) {
            this.setValue(value);
        } else {
            this.resetValue();
        }
    }

    /**
     * Registers a callback function that is called when
     * the control's value changes in the UI.
     * @param fn function
     */
    public registerOnChange(fn: (value: string) => void): void {
        this._onChange = fn;
    }

    /**
     * Registers a callback function that is called by the forms API on
     * initialization to update the form model on blur.
     * @param fn function
     */
    public registerOnTouched(fn: () => {}): void {
        this._onTouched = fn;
    }

    /**
     * setDisabledState function - Function that is called by the forms API
     * when the control status changes to or from 'DISABLED'.
     * Depending on the status, it enables or disables the appropriate DOM element.
     * @param isDisabled boolean
     */
    public setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
        this._changeDetectorRef.markForCheck();
        this.stateChanges.next();
    }

    /**
     * onContainerClick function - This method will be called when the form field is clicked on
     * @param event MouseEvent
     */
    public onContainerClick(event: MouseEvent): void {
        this.focus();
    }

    /**
     * onInput function - event handler function for input change
     */
    public onInput(): void {
        const value = this.cynoBirthdateService.formatInput(this.getNativeElementValue(), this.separator);
        this.setNativeElementValue(value);

        const formattedValue = this.cynoBirthdateService.resetFormat(value);
        this._onChange(this.cynoBirthdateService.reverseDate(formattedValue));
    }

    /**
     * onBackSpace function - event handler function for backpace key down
     */
    public onBackspace(): void {
        const value = this.cynoBirthdateService.formatBackspace(this.getNativeElementValue());
        this.setNativeElementValue(value);

        const formattedValue = this.cynoBirthdateService.resetFormat(value);
        this._onChange(this.cynoBirthdateService.reverseDate(formattedValue));
    }

    /**
     * We want to prevent DELETE, because that messes up the order for the formatters
     */
    public preventDefault(event: KeyboardEvent): void {
        event.preventDefault();
    }

    /**
     * onFocus function - event handler
     */
    public onFocus(): void {
        this.focused = true;
    }

    /**
     * onBlur function -  - event handler
     */
    public onBlur(): void {
        this.focused = false;
        setTimeout(() => this._onTouched(), 0);
    }

    /**
     * onPaste function - event handler
     */
    public onPaste(): boolean {
        return false;
    }

    /**
     * callback function that is called when
     * the control's value changes in the UI.
     */
    private _onChange: (value: string) => void = () => {};

    /**
     * callback function that is called by the forms API
     * to update the form model on blur.
     */
    private _onTouched = (): any => {};

    /**
     * set value - validate and set value.
     * @param value string - YYYY-MM-DD
     */
    private setValue(value: string): void {
        const isValidDate = !this.dateService.isValid(value);

        if (isValidDate) {
            return;
        }

        let formattedValue = this.cynoBirthdateService.reverseDate(value);
        formattedValue = this.cynoBirthdateService.formatInput(formattedValue, this.separator);
        this.setNativeElementValue(this.cynoBirthdateService.formatInitial(formattedValue));
    }

    /**
     * resetValue function - resets native element value
     */
    private resetValue(): void {
        this.setNativeElementValue('');
    }

    /**
     * set native element value - sets a value to the native (HTML) element
     * @param value string
     */
    private setNativeElementValue(value: string): void {
        this.birthdate.nativeElement.value = value;
    }

    /**
     * getNativeElementValue function - returns the value of native HTML element
     */
    private getNativeElementValue(): string {
        return this.birthdate.nativeElement.value;
    }

    /**
     * focus function - set focus to native (HTML) element
     */
    private focus(): void {
        this._elementRef.nativeElement.focus();
    }
}
