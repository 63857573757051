<div
    class="banner"
    *ngIf="size === 'large'; else bannerSmall"
    [class.banner--image-right]="imagePosition === 'right'"
    [class.banner--image-left]="imagePosition === 'left'"
>
    <picture>
        <source
            [attr.defaultImage]="'/assets/default-image-1x1.png'"
            [attr.lazyLoad]="getImageUrl('520x400.webp')"
            media="(max-width: 600px)"
        />
        <source
            [attr.defaultImage]="'/assets/default-image-1x1.png'"
            [attr.lazyLoad]="getImageUrl('1068x274.webp')"
            media="(min-width: 601px)"
        />
        <img
            src="/assets/default-image-1x1.png"
            [lazyLoad]="getImageUrl('1068x274.webp')"
            [title]="imageTitle"
            [alt]="imageAlt"
        />
    </picture>
</div>

<ng-template #bannerSmall>
    <picture>
        <source [attr.defaultImage]="'/assets/default-image-1x1.png'" [attr.lazyLoad]="getImageUrl('674x330.webp')" />
        <img
            src="/assets/default-image-1x1.png"
            [lazyLoad]="getImageUrl('674x330.webp')"
            [title]="imageTitle"
            [alt]="imageAlt"
        />
    </picture>
</ng-template>
