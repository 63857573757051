import { Injectable } from '@angular/core';
import {
    ApiProductIdEnum,
    ApiValidityStatusCodeEnum,
    mapProductIdResponse,
    mapValidityStatusCodeResponse,
    PolicyValidityStatusCodeEnum,
    ProductBaseInterface,
} from 'outshared-lib';
import { DirectControlButtonIdEnum } from '../enums/direct-control-button-id.enum';
import { DirectControlOptions } from '@app-de/my-zone/shared/direct-control-buttons/direct-control-buttons-config';

@Injectable({
    providedIn: 'root',
})
export class DirectControlService {
    public initConfig(options: DirectControlOptions[], product: ProductBaseInterface): DirectControlOptions[] {
        const buttons: DirectControlOptions[] = [];

        for (const option of options) {
            let isDisabled = option.disabled || false;
            const isExtraCar: boolean = option.id === DirectControlButtonIdEnum.ExtraCar;
            const productArray: string[] = [...option.product];
            const statusArray: string[] = [...option.status];
            const validityStatusCode = mapValidityStatusCodeResponse(
                product.validity_status_code as ApiValidityStatusCodeEnum,
            );
            // have to cast to unknown first to erase the initial type, it would complain about not matching otherwise.
            const productId = mapProductIdResponse(product.product_id as unknown as ApiProductIdEnum);

            if (option.id === DirectControlButtonIdEnum.InsuranceRegularDriver && product.non_regular_driver_future) {
                continue;
            }

            if (
                (option.id === DirectControlButtonIdEnum.InsuranceRegularDriver ||
                    option.id === DirectControlButtonIdEnum.ChangeCoverage) &&
                (statusArray.includes(PolicyValidityStatusCodeEnum.Future) ||
                    statusArray.includes(PolicyValidityStatusCodeEnum.Transfer)) &&
                (validityStatusCode === PolicyValidityStatusCodeEnum.Future ||
                    validityStatusCode === PolicyValidityStatusCodeEnum.Transfer)
            ) {
                isDisabled = true;
            }

            if (
                option.id === DirectControlButtonIdEnum.InternationalMotorInsuranceCard &&
                statusArray.includes(PolicyValidityStatusCodeEnum.Transfer) &&
                validityStatusCode === PolicyValidityStatusCodeEnum.Transfer
            ) {
                isDisabled = true;
            }

            // TODO: WIDL-1793 - Remove if statement when feature toggle is removed
            if (
                option.id === DirectControlButtonIdEnum.InsuranceRegularDriver &&
                Boolean(product.non_regular_driver && product.non_regular_driver?.start_date)
            ) {
                isDisabled = true;
            }

            if (!productArray.includes(productId)) {
                continue;
            }

            if (!statusArray.includes(validityStatusCode) && !isExtraCar) {
                continue;
            }

            buttons.push({
                name: option.name,
                id: option.id,
                actionButton: option.actionButton,
                action: option.action,
                actionLink: option.actionLink,
                hash: option.hash,
                icon: option.icon,
                productId: productId,
                statusCode: validityStatusCode,
                disabled: isDisabled,
            });
        }

        return buttons;
    }
}
