import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthenticationDataService } from 'outshared-lib';
import { Observable, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { StorageService } from '../core/storage/universal.inject';
import { AuthenticationRedirectService } from '@app-de/authentication/services/authentication-redirect.service';

@Injectable({
    providedIn: 'root',
})
export class AuthenticateGuard implements CanActivate {
    constructor(
        private authenticationDataService: AuthenticationDataService,
        private authenticationRedirectService: AuthenticationRedirectService,
        private router: Router,
        @Inject(PLATFORM_ID) private platformId: Object,
        @Inject(StorageService) private storage: Storage,
    ) {}

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        if (isPlatformServer(this.platformId)) {
            return of(true);
        }

        return this.authenticationDataService.initializationPending$().pipe(
            filter((pending: boolean) => !pending),
            switchMap(() =>
                this.authenticationDataService.isLoggedIn$().pipe(
                    map((loggedIn: boolean) => {
                        return loggedIn
                            ? this.authenticationRedirectService.redirectOrPassThrough()
                            : this.authenticationRedirectService.redirectThroughLogin(state.url);
                    }),
                ),
            ),
        );
    }
}
