import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ErrorInterface, RelationInterface } from 'outshared-lib';
import { DetailsTableDisplayEnum } from '../../details-table/details-table-display.enum';

@Component({
    selector: 'ins-residential-address-future',
    templateUrl: './residential-address-future.component.html',
    styleUrls: ['./residential-address-future.component.scss'],
})
export class ResidentialAddressFutureComponent {
    public detailsTableDisplayEnum = DetailsTableDisplayEnum;

    @Output() public readonly cancelFutureResidentialAddress: EventEmitter<void> = new EventEmitter<void>();
    @Input() public relation!: RelationInterface;
    @Input() public loading!: boolean;
    @Input() public errors!: ErrorInterface[];

    public onCancelFutureResidentialAddress(): void {
        this.cancelFutureResidentialAddress.emit();
    }
}
