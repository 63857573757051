import { inject, Injectable } from '@angular/core';
import { SalesfunnelRoutes } from './routing/salesfunnel-routing.routes';
import { Router } from '@angular/router';
import { ClearInsuranceData, SalesState, SalesStorageService } from 'outshared-lib';
import { Store } from '@ngrx/store';
import { AppRoutesEnum } from '@app-de/routes';

@Injectable({ providedIn: 'root' })
export class SalesfunnelService {
    private router = inject(Router);
    private readonly salesStorageService = inject(SalesStorageService);
    private readonly salesStore$ = inject(Store<SalesState>);

    public isSalesfunnel(url: string): boolean {
        const fragments = url.split('/').filter((item: string) => item);
        return fragments.length > 0 && fragments[1] === `${fragments[0]}-${ SalesfunnelRoutes.Calculate }`;
    }

    public navigateToCleanedCarSalesfunnel() {
        this.salesStore$.dispatch(new ClearInsuranceData());
        Promise.all([
            this.salesStorageService.removeItem('insurances'),
            this.salesStorageService.removeItem('coverages')
          ]).then(() => {
            this.router.navigate([AppRoutesEnum.CarInsurance, SalesfunnelRoutes.CalculateCarInsurance]);
          });
    }
}
