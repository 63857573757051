import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ProductBaseInterface, ProductIdEnum } from 'outshared-lib';

@Component({
    selector: 'ins-insurance-detail',
    templateUrl: './insurance-detail.component.html',
    styleUrls: ['./insurance-detail.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InsuranceDetailComponent {
    @Input() public product: ProductBaseInterface;

    /**
     * @condition should match valid product from ProductIdEnum
     * @condition should be ACTIVE insurance
     *
     * @param {ProductBaseInterface} product
     * @return {*}  {boolean}
     * @memberof InsuranceDetailComponent
     */
    public hasInternationalMotorInsuranceCard(product: ProductBaseInterface): boolean {
        return (
            !![ProductIdEnum.Car].includes(product.product_id) &&
            !!['ACTI', 'ACEI', 'NEVT', 'ACWI'].includes(product.validity_status_code)
        );
    }
}
