import { ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorInterface } from 'outshared-lib';

export class DetailsFormClass {
    public errors: ErrorInterface[] = [];
    public disabled: boolean = false;

    constructor(private changeDetectorRef: ChangeDetectorRef, private router: Router) {}

    public beforeOnSubmit(): void {
        this.disabled = true;
        this.errors = [];
        this.changeDetectorRef.detectChanges();
    }

    public handleSuccess(): void {
        this.closePanel();
        this.disabled = false;
        this.changeDetectorRef.detectChanges();
    }

    public handleErrors(errors: ErrorInterface[]): void {
        this.errors = errors;
        this.disabled = false;
        this.changeDetectorRef.detectChanges();
    }

    public closePanel(): void {
        this.router.navigate([], {
            queryParams: {
                panel: null,
                scrollTo: null,
                expand: null,
            },
            queryParamsHandling: 'merge',
        });
    }
}
