import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AccountRecoveryModal } from '@app-de/account-recovery/account-recovery-modal';
import { accountRecoveryRoutes } from '@app-de/account-recovery/account-recovery.routes';
import { CompleteGeneratedAccountModal } from '@app-de/account-recovery/complete-generated-account-modal';
import { ForgotEmailModal } from '@app-de/account-recovery/forgot-email-modal';
import { ForgotPasswordModal } from '@app-de/account-recovery/forgot-password-modal';
import { SendPasswordModal } from '@app-de/account-recovery/send-password-modal/send-password.modal';
import { TemporaryPasswordModal } from '@app-de/account-recovery/temporary-password-modal';
import { UnblockAccountModal } from '@app-de/account-recovery/unblock-account-modal';
import { ValidateGeneratedAccountModal } from '@app-de/account-recovery/validate-generated-account-modal';
import { ModalComponentsModule } from '@app-de/components';
import { AlertModule } from '@app-de/components/alert/alert.module';
import { ElementValidateErrorsModule } from '@app-de/salesfunnel/shared/element-validate-errors/element-validate-errors.module';
import { ClickTooltipModule } from '@app-de/shared/click-tooltip/click-tooltip.module';
import { CynoFormModule } from '@app-de/shared/cyno-form/cyno-form.module';
import { ShowPasswordModule } from '@app-de/shared/directives/show-password/show-password.module';

const COMPONENTS = [
    AccountRecoveryModal,
    CompleteGeneratedAccountModal,
    ForgotEmailModal,
    ForgotPasswordModal,
    SendPasswordModal,
    TemporaryPasswordModal,
    UnblockAccountModal,
    ValidateGeneratedAccountModal,
];

@NgModule({
    declarations: COMPONENTS,
    exports: COMPONENTS,
    imports: [
        RouterModule.forChild(accountRecoveryRoutes),
        ModalComponentsModule,
        CommonModule,
        CynoFormModule,
        ClickTooltipModule,
        AlertModule,
        ShowPasswordModule,
        ElementValidateErrorsModule,
    ],
})
export class AccountRecoveryModule {}
