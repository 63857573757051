import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ErrorInterface, RelationInterface } from 'outshared-lib';
import { DetailsTableDisplayEnum } from '../../details-table/details-table-display.enum';

@Component({
    selector: 'ins-correspondence-address-future',
    templateUrl: './correspondence-address-future.component.html',
})
export class CorrespondenceAddressFutureComponent {
    @Input() public relation!: RelationInterface;
    @Input() public loading!: boolean;
    @Input() public errors!: ErrorInterface[];

    @Output() public readonly cancelCorrespondenceAddress = new EventEmitter<void>();

    public detailsTableDisplayEnum = DetailsTableDisplayEnum;

    public onCancelFutureMailingAddress(): void {
        this.cancelCorrespondenceAddress.emit();
    }
}
