import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RackBodyComponent } from './rack-body/rack-body.component';
import { RackColComponent } from './rack-col/rack-col.component';
import { RackHeadComponent } from './rack-head/rack-head.component';
import { RackRowComponent } from './rack-row/rack-row.component';
import { RackComponent } from './rack.component';

const COMPONENTS = [
    RackComponent,
    RackRowComponent,
    RackHeadComponent,
    RackColComponent,
    RackBodyComponent,
];

@NgModule({
    imports: [CommonModule],
    exports: [COMPONENTS],
    declarations: [COMPONENTS],
})
export class RackModule { }
