import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import {
    EditAccountCredentialsInterface,
    ErrorInterface,
    RelationActionService,
    RelationDataService,
    RelationInterface,
} from 'outshared-lib';
import { Observable, Subscription, throwError } from 'rxjs';
import { catchError, filter, take, tap } from 'rxjs/operators';

@Component({
    selector: 'ins-password-form-container',
    template: `
        <ins-password-form
            [relation]="relation$ | async"
            (submitted)="onSubmit($event)"
            [errors]="errors"
        ></ins-password-form>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PasswordFormContainer implements OnDestroy {
    public relation$: Observable<RelationInterface> = this.relationDataService.getRelation$().pipe(
        filter((relation: RelationInterface) => !!relation),
        take(1),
    );

    public errors: ErrorInterface[] = [];
    private subscriptions = new Subscription();

    constructor(
        private router: Router,
        private changeDetectorRef: ChangeDetectorRef,
        private relationActionService: RelationActionService,
        private relationDataService: RelationDataService,
    ) {}

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    public onSubmit(payload: EditAccountCredentialsInterface): void {
        const request$ = this.relationActionService.editAccountCredentialsAction$({
            ...payload,
        });

        this.errors = [];

        const requestSubscription = request$
            .pipe(
                tap(() => {
                    this.errors = [];
                    this.router.navigate([], {
                        queryParams: {
                            panel: null,
                            scrollTo: null,
                            expand: null,
                        },
                        queryParamsHandling: 'merge',
                    });
                    this.changeDetectorRef.detectChanges();
                }),
                catchError((errors: ErrorInterface[]) => {
                    this.errors = errors;
                    this.changeDetectorRef.detectChanges();
                    return throwError(errors);
                }),
            )
            .subscribe();

        this.subscriptions.add(requestSubscription);
    }
}
