export enum SelectedPanelEnum {
    Close = 'close',
    Detail = 'detail',
    ChangeDriver = 'change-driver',
    ChangeCoverage = 'change-coverage',
    InternationalMotorInsuranceCard = 'international-motor-insurance-card',
    TerminateInsurance = 'terminate-insurance',
    CancelInsuranceEarlier = 'cancel-insurance-earlier',
    ChangeStartDate = 'change-start-date',
    SetStartDateInsurance = 'set-start-date-insurance',
    TransferInsurance = 'transfer-insurance',
}
