import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { LayoutSelectorService } from '@app-de/store/layout/layout-selector.service';

@Component({
    selector: 'ins-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})

export class HeaderComponent implements OnInit {
    public showMainMenu$: Observable<boolean>;
    public showMobileMenu$: Observable<boolean>;

    constructor(private layoutSelectorService: LayoutSelectorService) {
    }

    public ngOnInit(): void {

        this.showMainMenu$ = this.layoutSelectorService.getMainMenu$();
        this.showMobileMenu$ = this.layoutSelectorService.getMobileMenu$();
    }

}
