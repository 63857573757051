import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
    DamageFreeYearsImpactActionService,
    DamageFreeYearsImpactDataService,
    ProductSelectorService,
} from 'outshared-lib';
import { combineLatest, from, Observable, Subscription } from 'rxjs';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { MyZoneRoutes } from '@app-de/my-zone/routing/my-zone-routing.routes';

@Component({
    selector: 'ins-no-damage-free-years-notification-container',
    template: ` <ins-no-damage-free-years-notification
        *ngIf="showDamageImpactNotification"
        [purchasedProductNumber]="purchasedProductNumber$ | async"
    ></ins-no-damage-free-years-notification>`,
})
export class NoDamageFreeYearsNotificationContainer implements OnDestroy {
    public subscriptions: Subscription = new Subscription();

    public showDamageImpactNotification: boolean;

    public purchasedProductNumber$: Observable<number> = this.productSelectorService.getProductCollection$.pipe(
        filter((products) => products?.length > 0),
        map((products) => products[0]?.purchased_product_sequence_number),
    );

    public showDamageFreeYearsImpactNotification$(): Observable<boolean> {
        const isNavigatedToDamageFreeYears$ = this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd),
            map((event) => (<NavigationEnd>event).url.includes(MyZoneRoutes.MyDamageFreeYears)),
        );

        const containsImpactChanges$ = this.damageFreeYearsImpactDataService.getDamageFreeYearsImpact$().pipe(
            filter((impact) => !!impact),
            map(
                (impact) =>
                    impact?.damageFreeYearsWaCurrentCode !== impact?.damageFreeYearsWaFutureCode ||
                    impact?.damageFreeYearsCascoCurrentCode !== impact?.damageFreeYearsCascoFutureCode,
            ),
        );

        return combineLatest([containsImpactChanges$, isNavigatedToDamageFreeYears$]).pipe(
            map(([containsImpactChanges, navigated]) => containsImpactChanges && !navigated),
            tap((showNotification) => (this.showDamageImpactNotification = showNotification)),
            tap(() => this.changeDetectorRef.detectChanges()),
        );
    }

    private getDamageFreeYearsImpact$(): Observable<number | void> {
        return combineLatest([
            this.activatedRoute.queryParams,
            this.damageFreeYearsImpactDataService.getDamageFreeYearsImpact$(),
        ]).pipe(
            switchMap(([queryParams, impact]) => {
                const purchasedProductNumber = queryParams?.purchased_product;

                if (purchasedProductNumber) {
                    return this.getDamageFreeYearsImpactFromService$(purchasedProductNumber);
                }

                return this.productSelectorService.getProductCollection$.pipe(
                    filter((products) => products?.length > 0),
                    switchMap((products) => {
                        const sequenceNumbers$ = from(
                            products.map((product) => product?.purchased_product_sequence_number),
                        );

                        const damageFreeYearsImpact$ = this.damageFreeYearsImpactDataService
                            .getDamageFreeYearsImpact$()
                            .pipe(filter((impact) => !!impact));

                        return sequenceNumbers$.pipe(
                            tap((sequenceNumber) => this.getDamageFreeYearsImpactFromService$(sequenceNumber)),
                            takeUntil(damageFreeYearsImpact$),
                        );
                    }),
                );
            }),
        );
    }

    constructor(
        private router: Router,
        private damageFreeYearsImpactDataService: DamageFreeYearsImpactDataService,
        private damageFreeYearsImpactActionService: DamageFreeYearsImpactActionService,
        private changeDetectorRef: ChangeDetectorRef,
        private productSelectorService: ProductSelectorService,
        private activatedRoute: ActivatedRoute,
    ) {
        this.subscriptions.add(this.getDamageFreeYearsImpact$().subscribe());
        this.subscriptions.add(this.showDamageFreeYearsImpactNotification$().subscribe());
    }

    private getDamageFreeYearsImpactFromService$(purchasedProductNumber: number): Observable<void> {
        return this.damageFreeYearsImpactActionService.damageFreeYearsImpact$({
            productSequenceNumber: +purchasedProductNumber,
        });
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
