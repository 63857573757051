import { Pipe, PipeTransform } from '@angular/core';

/**
 * @deprecated
 * Use the `titlecase` pipe from Angular instead. 
 */
@Pipe({
    name: 'capitalize',
})
export class CapitalizePipe implements PipeTransform {
    public transform(input: string): string {
        if (!input) {
            return;
        }

        return input.charAt(0).toUpperCase() + input.substr(1).toLowerCase();
    }
}
