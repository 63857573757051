import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { DetailsSectionEnum } from '@app-de/core/enums/detail-section.enum';
import { MyZoneRoutes } from '@app-de/my-zone/routing/my-zone-routing.routes';
import { AppRoutesEnum } from '@app-de/routes';

@Component({
    selector: 'ins-details-toggle-button',
    templateUrl: './details-toggle-button.component.html',
})
export class DetailsToggleButtonComponent {
    @Input() public expand: boolean = true;
    @Input() public section: DetailsSectionEnum;

    constructor(private router: Router) {}

    public open(): void {
        this.navigate(true);
    }

    public close(): void {
        this.navigate(false);
    }

    private navigate(expand: boolean): void {
        this.router.navigate(['/', AppRoutesEnum.MyZone, MyZoneRoutes.Details], {
            queryParams: {
                section: this.section,
                expand: expand,
            },
        });
    }
}
