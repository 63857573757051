import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DatepickerComponent } from './datepicker.component';

@NgModule({
    imports: [CommonModule],
    exports: [DatepickerComponent],
    declarations: [DatepickerComponent],
})
export class DatepickerModule {}
