import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AccountRecoveryActionService, ErrorInterface, RecoveryMethodsRequestInterface } from 'outshared-lib';
import { AccountRecoveryModalRoutesEnum } from '@app-de/routes';

@Component({
    selector: 'ins-forgot-password-modal',
    templateUrl: './forgot-password.modal.html',
})
export class ForgotPasswordModal implements OnInit {
    public form!: UntypedFormGroup;
    public errors: ErrorInterface[] = [];
    public loading: boolean = false;
    public backLink = ['', { outlets: { modal: AccountRecoveryModalRoutesEnum.AccountRecovery } }];

    public get birthdate(): AbstractControl {
        return this.form.get('birthdate');
    }

    public get email(): AbstractControl {
        return this.form.get('email');
    }

    constructor(private router: Router, private accountRecoveryActionService: AccountRecoveryActionService) {}

    public ngOnInit(): void {
        this.form = new UntypedFormGroup({
            birthdate: new UntypedFormControl('', [Validators.required]),
            email: new UntypedFormControl('', [Validators.required, Validators.email]),
        });
    }

    public onSubmit(): void {
        if (this.form.invalid || this.loading) {
            return;
        }

        this.errors = [];
        this.loading = true;

        const request: RecoveryMethodsRequestInterface = {
            birthdate: this.birthdate.value,
            email: this.email.value,
        };

        this.accountRecoveryActionService.getRecoveryMethods$(request).subscribe(
            () => {
                this.router.navigate(['', { outlets: { modal: AccountRecoveryModalRoutesEnum.SendPassword } }]);
            },
            (errors: ErrorInterface[]) => {
                this.errors = errors;
                this.loading = false;
            },
        );
    }
}
