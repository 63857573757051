import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { LayoutSelectorService } from '../../../../store/layout/layout-selector.service';
import { BreadCrumbInterface } from '@app-de/core/interfaces/bread-crumb.interface';

@Component({
    selector: 'ins-breadcrumb-bordered-container',
    templateUrl: './breadcrumb-bordered.container.html',
})
export class BreadcrumbBorderedContainer implements OnInit {
    public breadcrumb$: Observable<BreadCrumbInterface[]>;

    constructor(private layoutSelectorService: LayoutSelectorService) {}

    public ngOnInit(): void {
        this.breadcrumb$ = this.layoutSelectorService.breadcrumb$();
    }
}
