<div class="news-carousel">
    <div class="news-carousel__carousel" #newsCarousel>
        <out-carousel
            timing="250ms cubic-bezier(0.645, 0.045, 0.355, 1)"
            [showControls]="true"
            [showNavigation]="false"
            [autoPlay]="false"
        >
            <!-- Button Prev -->
            <ng-container buttonPrev>
                <div class="news-carousel__button">
                    <svg class="svg-icon svg-icon--xs">
                        <use href="assets/icons-ui.svg#links-open"></use>
                    </svg>
                </div>
            </ng-container>

            <!-- Button Next -->
            <ng-container buttonNext>
                <div class="news-carousel__button">
                    <svg class="svg-icon svg-icon--xs">
                        <use href="assets/icons-ui.svg#rechts-open"></use>
                    </svg>
                </div>
            </ng-container>

            <!-- Items -->
            <ng-container items>
                <ng-content></ng-content>
            </ng-container>
            <!-- Items -->
        </out-carousel>
    </div>
</div>
