import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { DetailsTableDisplayEnum } from './details-table-display.enum';
import { IntersectionObserverService } from '@app-de/core/services/intersection-observer.service';

@Component({
    selector: 'ins-details-table',
    templateUrl: './details-table.component.html',
    styleUrls: ['./details-table.component.scss'],
})
export class DetailsTableComponent {
    public isScreenSmall$: Observable<boolean>;
    public detailsTableDisplayEnum = DetailsTableDisplayEnum;
    @Input() public display: DetailsTableDisplayEnum = DetailsTableDisplayEnum.Current;
    constructor(private intersectionObserverService: IntersectionObserverService) {
        this.isScreenSmall$ = this.intersectionObserverService.isScreenSmall$;
    }
}
