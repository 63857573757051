import { Component, inject } from '@angular/core';
import { WucButtonModule } from '@inshared/website-ui-components';
import { CookieConsent } from '@inshared/shared/core';
import { COOKIE_SETTING_KEY } from 'outshared-lib/core/constants';
import { RouteHelperService, WINDOW } from '@inshared/shared/util';
import { CookieStorage } from '@inshared/shared/util-storage';
import { Router } from '@angular/router';
import {CookieSettingsService} from "../cookie-settings-service";

@Component({
    standalone: true,
    selector: 'ins-cookie-settings-buttons',
    templateUrl: 'cookie-settings-buttons.component.html',
    styleUrl: '../cookie-settings-modal/cookie-settings-modal.component.scss',
    imports: [WucButtonModule],
})
export class CookieSettingsButtonComponent {
    public ariaLabelAll = $localize`:@@feature-cookie.modal.button-all-arialabel:Accept cookies`;
    public ariaLabelBasic = $localize`:@@feature-cookie.modal.button-basic-arialabel:Decline cookies`;

    public cookieConsent = CookieConsent;

    private window = inject(WINDOW);
    private routeHelperService = inject(RouteHelperService);
    private router = inject(Router);
    private cookieSettingsService = inject(CookieSettingsService);

    private saveSettings(cookieSettings: CookieConsent): void {
       this.cookieSettingsService.saveSettings(cookieSettings)
    }

    private clearModalOnRoute() {
        this.router
            .navigate([this.routeHelperService.getCleanUrl(), { outlets: { modal: null } }], {
                queryParamsHandling: 'preserve',
                onSameUrlNavigation: 'reload',
            })
            .finally(() => this.window.location?.reload());
    }

    public onClick(value: CookieConsent) {
        this.saveSettings(value);
        this.clearModalOnRoute();
    }
}
