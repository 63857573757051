/* eslint-disable @typescript-eslint/no-use-before-define */
import { DebitCreditEnum } from '../enums';
import {
    ApiGetInvoiceDetailsAdvanceInterface,
    ApiGetInvoiceDetailsCoverageInterface,
    ApiGetInvoiceDetailsInterface,
    ApiGetInvoiceDetailsInvoiceInterface,
    ApiGetInvoiceDetailsProductInterface,
    ApiGetInvoiceDetailsSettlingInterface,
    ApiGetInvoiceProductDetailsInterface,
    GetInvoiceDetailsAdvanceInterface,
    GetInvoiceDetailsCoverageInterface,
    GetInvoiceDetailsInterface,
    GetInvoiceDetailsInvoiceInterface,
    GetInvoiceDetailsProductInterface,
    GetInvoiceDetailsSettlingInterface,
    GetInvoiceProductDetailsInterface,
} from '../interfaces';

export function getInvoiceDetailsMapper(response: ApiGetInvoiceDetailsInterface): GetInvoiceDetailsInterface {
    if (!response) {
        return null;
    }

    return {
        advance: advanceMapper(response.advance),
        invoice: invoiceMapper(response.invoice),
        settling: settlingMapper(response.settling),
    };
}

function settlingMapper(response: ApiGetInvoiceDetailsSettlingInterface): GetInvoiceDetailsSettlingInterface {
    if (!response) {
        return null;
    }

    return {
        amount: response.amount,
        charged: {
            debitCredit: debitCreditMapper(response.charged.debit_credit),
            amount: response.charged.amount,
            premiumTax: response.charged.premium_tax,
            total: response.charged.total,
        },
        debitCredit: debitCreditMapper(response.debit_credit),
        description: response.description,
        premiumTax: response.premium_tax,
        product: productMapper(response.product),
        purchased: {
            debitCredit: debitCreditMapper(response.purchased.debit_credit),
            amount: response.purchased.amount,
            premiumTax: response.purchased.premium_tax,
            total: response.purchased.total,
        },
        total: response.total,
    };
}

function invoiceMapper(response: ApiGetInvoiceDetailsInvoiceInterface): GetInvoiceDetailsInvoiceInterface {
    if (!response) {
        return null;
    }

    return {
        amount: response.amount,
        debitCredit: debitCreditMapper(response.debit_credit),
        description: response.description,
        invoiceNumber: response.factuurnummer,
        invoiceCode: response.invoice_code,
        invoiceDate: response.invoice_date,
        invoiceDescription: response.invoice_description,
        invoiceId: response.invoice_id,
        month: response.month,
        premiumTax: response.premium_tax,
        total: response.total,
    };
}

function advanceMapper(response: ApiGetInvoiceDetailsAdvanceInterface): GetInvoiceDetailsAdvanceInterface {
    if (!response) {
        return null;
    }

    return {
        amount: response.amount,
        debitCredit: debitCreditMapper(response.debit_credit),
        description: response.description,
        premiumTax: response.premium_tax,
        product: productMapper(response.product),
        total: response.total,
    };
}

function productMapper(response: ApiGetInvoiceDetailsProductInterface[]): GetInvoiceDetailsProductInterface[] {
    if (!response) {
        return null;
    }

    return response.map((product) => ({
        amount: product.amount,
        debitCredit: debitCreditMapper(product.debit_credit),
        modified: invoiceDetailMapper(product.modified),
        new: invoiceDetailMapper(product.new),
        period: product.period,
        premiumTax: product.premium_tax,
        productDescription: product.product_description,
        productId: product.product_id,
        purchased: invoiceDetailMapper(product.purchased),
        purchasedProductSequenceNumber: product.purchased_product_sequence_number,
        reference: product.reference,
        terminated: invoiceDetailMapper(product.terminated),
        total: product.total,
    }));
}

function invoiceDetailMapper(response: ApiGetInvoiceProductDetailsInterface): GetInvoiceProductDetailsInterface {
    if (!response) {
        return null;
    }

    return {
        periods: response.periods.map((period) => ({
            amount: period.amount,
            coverages: coverageMapper(period.coverages),
            debitCredit: debitCreditMapper(period.debit_credit),
            originReasonCode: period.origin_reason_code,
            originReasonDescription: period.origin_reason_description,
            originReasonDetailCodes: period.origin_reason_detail_codes.map((code) => ({
                originReasonDetailCode: code.origin_reason_detail_code,
                originReasonDetailDescription: code.origin_reason_detail_description,
            })),
            period: period.period,
            premiumTax: period.premium_tax,
            total: period.total,
        })),
    };
}

function coverageMapper(response: ApiGetInvoiceDetailsCoverageInterface[]): GetInvoiceDetailsCoverageInterface[] {
    if (!response) {
        return null;
    }

    return response.map((coverage) => ({
        amount: coverage.amount,
        coverage: coverage.coverage.map((detail) => ({
            amount: detail.amount,
            coverageDescription: detail.coverage_description,
            coverageId: detail.coverage_id,
            debitCredit: debitCreditMapper(detail.debit_credit),
            notificationType: detail.notification_type,
            premiumTax: detail.premium_tax,
            total: detail.total,
            typeDescription: detail.type_description,
        })),
        debitCredit: debitCreditMapper(coverage.debit_credit),
        premiumTax: coverage.premium_tax,
        total: coverage.total,
    }));
}

export function debitCreditMapper(response: string): DebitCreditEnum {
    switch (response) {
        case 'C':
            return DebitCreditEnum.Credit;
        case 'D':
            return DebitCreditEnum.Debit;
    }

    return null;
}
