import { Component, HostBinding } from '@angular/core';

@Component({
    selector: 'ins-rack',
    templateUrl: 'rack.component.html',
    styleUrls: ['rack.component.scss'],
})
export class RackComponent {
    @HostBinding('class.rack') public hostClass = true;
}
