import { Component, inject, Input } from '@angular/core';
import { RelationInterface } from 'outshared-lib';
import { AppRoutesEnum } from '@app-de/routes';
import { SalesfunnelRoutes } from '@app-de/salesfunnel/routing';
import { SalesfunnelService } from '@app-de/salesfunnel/salesfunnel.service';

@Component({
    selector: 'ins-my-zone',
    templateUrl: './my-zone.component.html',
    styleUrls: ['./my-zone.component.scss'],
})
export class MyZoneComponent {
    private readonly salesfunnelServcie = inject(SalesfunnelService);
    @Input() public relation: RelationInterface;
    public appRoutesEnum = AppRoutesEnum;
    public salesfunnelRoutesEnum = SalesfunnelRoutes;

    onAddSecondVehicleClick() {
        this.salesfunnelServcie.navigateToCleanedCarSalesfunnel();
    }
}
