import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NewModalModule } from 'outshared-lib';
import { AdditionalCoverageInfoModal } from './additional-coverage-info.modal';
import { BaseCoverageInfoModal } from './base-coverage-info.modal';
import { CarCoverageInfoModule } from './car-coverage-info/car-coverage-info.module';
import { GeneralCoverageInfoModule } from './general-coverage-info/general-coverage-info.module';
import { ModalComponentsModule } from '@app-de/components';
import { ListModule } from '@app-de/shared/lists/list.module';
import { NoCoverageModal } from '@app-de/shared/modals/coverage-modals/no-coverage.modal';

@NgModule({
    imports: [
        CommonModule,
        ListModule,
        NewModalModule,
        CarCoverageInfoModule,
        GeneralCoverageInfoModule,
        ModalComponentsModule,
    ],
    declarations: [
        BaseCoverageInfoModal,
        NoCoverageModal,
        AdditionalCoverageInfoModal,
    ],
    exports: [
        BaseCoverageInfoModal,
        NoCoverageModal,
        AdditionalCoverageInfoModal,
    ],
})
export class CoverageModalsModule {}
