/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap, withLatestFrom } from 'rxjs/operators';
import { NotificationInterface } from '../../../core';
import { CoveragePremiumCoverageInterface, CoveragePremiumInterface } from '../../interfaces';
import * as fromMyZone from '../../my-zone';
import { CoverageTransformService } from '../../services/coverage-transform.service';
import { CoveragePremiumSelectorService } from './coverage-premium-selector.service';
import * as coveragePremiumActions from './coverage-premium.actions';
import { CoveragePremiumService } from './coverage-premium.service';

/** @deprecated use InsurancePolicyActionService */
@Injectable({
    providedIn: 'root',
})
export class CoveragePremiumActionService {
    constructor(
        private store$: Store<fromMyZone.State>,
        private coveragePremiumService: CoveragePremiumService,
        private coveragePremiumSelectorService: CoveragePremiumSelectorService,
        private coverageTransformService: CoverageTransformService
    ) {}

    /** @deprecated use InsurancePolicyActionService */
    // eslint-disable-next-line rxjs/finnish
    public load(props: {
        purchased_product_sequence_number: number;
    }): Observable<{ result: boolean } | NotificationInterface[]> {
        const { purchased_product_sequence_number } = props;

        this.store$.dispatch(new coveragePremiumActions.Load(props));

        return this.coveragePremiumService.calculateCoveragePremiumPurchasedProduct$(props).pipe(
            tap((coverages: CoveragePremiumInterface) =>
                this.store$.dispatch(
                    new coveragePremiumActions.LoadSuccess({
                        purchased_product_sequence_number,
                        coverages,
                    })
                )
            ),
            map(() => ({ result: true })),
            catchError((notifications) => {
                this.store$.dispatch(new coveragePremiumActions.LoadError());
                return throwError(notifications);
            })
        );
    }

    /** @deprecated use InsurancePolicyActionService */
    // eslint-disable-next-line rxjs/finnish
    public changeGenericPurchasedCoverages(props: {
        purchased_product_sequence_number: number;
        start_date: string;
        coverage_ids: { coverage_id: string }[];
    }): Observable<{ result: boolean } | NotificationInterface[]> {
        const { purchased_product_sequence_number, start_date, coverage_ids } = props;

        this.store$.dispatch(new coveragePremiumActions.ChangeGenericPurchasedCoverages(props));

        return this.coveragePremiumService.changeGenericPurchasedCoverages(props).pipe(
            withLatestFrom(this.coveragePremiumSelectorService.getAllCoverages$),
            map(([response, coverages]) => {
                const foundCoverage: CoveragePremiumInterface = coverages.find(
                    (coverage) => coverage.purchased_product_sequence_number === purchased_product_sequence_number
                );
                const { product } = foundCoverage;

                const currentPeriod =
                    product?.invoice_periods?.find(
                        (period) =>
                            period.invoice_period_months_number === product.purchased_invoice_period_months_number
                    ) ?? product;

                const baseCoverages = currentPeriod.base_coverages ? currentPeriod.base_coverages : [];
                const additionalCoverage = currentPeriod.additional_coverage ? currentPeriod.additional_coverage : [];

                return [...baseCoverages, ...additionalCoverage];
            }),
            map((purchasedCoverages) =>
                this.coverageTransformService.mapCoveragesToFutureState({
                    purchased_coverages: purchasedCoverages,
                    start_date,
                    coverage_ids,
                })
            ),
            tap((purchasedCoverages) => {
                this.store$.dispatch(
                    new coveragePremiumActions.ChangeGenericPurchasedCoveragesSuccess({
                        purchased_coverages: purchasedCoverages,
                        start_date,
                        purchased_product_sequence_number,
                    })
                );
            }),
            map(() => ({ result: true })),
            catchError((notifications) => {
                this.store$.dispatch(new coveragePremiumActions.LoadError());
                return throwError(notifications);
            })
        );
    }

    /** @deprecated use InsurancePolicyActionService */
    // eslint-disable-next-line rxjs/finnish
    public cancelFuturePurchasedCoverage(props: {
        purchased_product_sequence_number: number;
    }): Observable<{ result: boolean } | NotificationInterface[]> {
        const purchasedProductSequenceNumber = props.purchased_product_sequence_number;

        this.store$.dispatch(new coveragePremiumActions.CancelFuturePurchasedCoverage(props));

        return this.coveragePremiumService.cancelFuturePurchasedCoverage(props).pipe(
            withLatestFrom(this.coveragePremiumSelectorService.getAllCoverages$),
            map(([response, coverages]) => {
                const foundCoverage: CoveragePremiumInterface = coverages.find(
                    (calculation) => calculation.purchased_product_sequence_number === purchasedProductSequenceNumber
                );
                const { product } = foundCoverage;
                const baseCoverages = product.base_coverages ? product.base_coverages : [];
                const additionalCoverage = product.additional_coverage ? product.additional_coverage : [];

                return [...baseCoverages, ...additionalCoverage];
            }),
            map((purchasedCoverages) => this.coverageTransformService.mapCoveragesToCurrentState(purchasedCoverages)),
            tap((purchasedCoverages: CoveragePremiumCoverageInterface[]) => {
                this.store$.dispatch(
                    new coveragePremiumActions.CancelFuturePurchasedCoverageSuccess({
                        purchased_coverages: purchasedCoverages,
                        purchased_product_sequence_number: purchasedProductSequenceNumber,
                    })
                );
            }),
            map(() => ({ result: true })),
            catchError((notifications) => {
                this.store$.dispatch(new coveragePremiumActions.LoadError());
                return throwError(notifications);
            })
        );
    }

    /** @deprecated use InsurancePolicyActionService */
    public clear(): void {
        this.store$.dispatch(new coveragePremiumActions.Clear());
    }

    /** @deprecated use InsurancePolicyActionService */
    public select(purchasedProductSequenceNumber: number): void {
        this.store$.dispatch(
            new coveragePremiumActions.Select({
                purchased_product_sequence_number: purchasedProductSequenceNumber,
            })
        );
    }
}
