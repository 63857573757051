import { ChangeDetectorRef, Directive, DoCheck, ElementRef, Input, OnInit, Optional, Self } from '@angular/core';
import { ControlValueAccessor, FormGroupDirective, NgControl, NgForm } from '@angular/forms';
import { CynoFieldControl } from '../core/cyno-field-control';
import { CanDisable, mixinDisabled } from '../core/disabled';
import { ErrorStateMatcher } from '../core/error-options';
import { CanUpdateErrorState, mixinErrorState } from '../core/error-state';
import { HasTabIndex, mixinTabIndex } from '../core/tabindex';
import { CYNO_OPTION_PARENT_COMPONENT } from './cyno-option.directive';

export class CynoSelectBase {
    constructor(
        public _elementRef: ElementRef,
        public _defaultErrorStateMatcher: ErrorStateMatcher,
        public _parentForm: NgForm,
        public _parentFormGroup: FormGroupDirective,
        public ngControl: NgControl,
    ) {}
}
export const cynoSelectMixinBase = mixinTabIndex(mixinDisabled(mixinErrorState(CynoSelectBase)));

@Directive({
    selector: 'select[cynoSelect]',
    exportAs: 'cynoSelect',
    host: {
        class: 'form-control form-control--dropdown',
        role: 'listbox',
        '[attr.id]': 'id',
        '[attr.tabindex]': 'tabIndex',
        '[attr.aria-required]': 'required.toString()',
        '[attr.aria-disabled]': 'disabled.toString()',
        '[attr.aria-invalid]': 'errorState',
        '[attr.aria-multiselectable]': 'multiple',
        '[disabled]': 'disabled',
        '[class.cyno-select-invalid]': 'errorState',
        '[class.cyno-select-required]': 'required',
        '(change)': 'onChange()',
        '(focus)': '_onFocus()',
        '(blur)': '_onBlur()',
    },
    providers: [
        { provide: CynoFieldControl, useExisting: CynoSelectDirective },
        { provide: CYNO_OPTION_PARENT_COMPONENT, useExisting: CynoSelectDirective },
    ],
})
export class CynoSelectDirective
    extends cynoSelectMixinBase
    implements
        OnInit,
        DoCheck,
        ControlValueAccessor,
        CanDisable,
        HasTabIndex,
        CynoFieldControl<any>,
        CanUpdateErrorState
{
    @Input()
    public get disabled(): boolean {
        if (this.ngControl && this.ngControl.disabled !== null) {
            return this.ngControl.disabled;
        }
        return this._disabled;
    }

    public set disabled(value: boolean) {
        this._disabled = value;

        if (this.focused) {
            this.focused = false;
        }
    }

    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        defaultErrorStateMatcher: ErrorStateMatcher,
        elementRef: ElementRef,
        @Optional() parentForm: NgForm,
        @Optional() parentFormGroup: FormGroupDirective,
        @Self() @Optional() public ngControl: NgControl,
    ) {
        super(elementRef, defaultErrorStateMatcher, parentForm, parentFormGroup, ngControl);
    }

    public focused: boolean = false;

    @Input() public id: string;
    @Input() public value: string;
    @Input() public required: boolean = false;
    @Input() public placeholder: string;
    @Input() public multiple: boolean = false;
    @Input() public tabIndex: number = 0;
    protected _disabled = false;
    public _onChange: (value: any) => void = () => {};
    public _onTouched = (): void => {};

    public ngOnInit(): void {
        this.id = `${this.ngControl.name}`;
    }

    public ngDoCheck(): void {
        if (this.ngControl) {
            this.updateErrorState();
        }
    }

    public writeValue(value: any): void {
        if (value) {
            this._elementRef.nativeElement.value = value;
        }
    }

    public registerOnChange(fn: (value: any) => void): void {
        this._onChange = fn;
    }

    public registerOnTouched(fn: () => {}): void {
        this._onTouched = fn;
    }

    public setDisabledState(disabled: boolean): void {
        this.disabled = disabled;
    }

    public onChange(value: any): void {
        this._onChange(this._elementRef.nativeElement.value);
        this._onTouched();
    }

    public _onFocus(): void {
        if (!this.disabled) {
            this._onTouched();
            this.focused = true;
        }
    }

    public _onBlur(): void {
        this.focused = false;

        if (!this.disabled) {
            this._onTouched();
            this._changeDetectorRef.markForCheck();
        }
    }

    public focus(): void {
        this._elementRef.nativeElement.focus();
    }

    public onContainerClick(): void {
        this.focus();
    }
}
