import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from '../../core';
import { VehicleModelSearchRequestInterface, VehicleModelSearchResponseInterface } from '../interfaces';
import { mapVehicleModelSearchRequest, mapVehicleModelSearchResponse } from '../mappers';

const RESOURCES = {
    SEARCH: 'en-gb/motorvehicle-general/search/type-motorvehicles-interactive',
};

@Injectable({
    providedIn: 'root',
})
export class VehicleModelSearchApiService {
    constructor(private apiService: ApiService) {}

    public vehicleModelSearch$(
        filter: VehicleModelSearchRequestInterface
    ): Observable<VehicleModelSearchResponseInterface> {
        const payload = mapVehicleModelSearchRequest(filter);

        return this.apiService.post$(RESOURCES.SEARCH, payload).pipe(map(mapVehicleModelSearchResponse));
    }
}
