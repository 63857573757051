import { Component, Input } from '@angular/core';
import { DebitCreditEnum, InvoiceDetailsInterface } from 'outshared-lib';

@Component({
    selector: 'ins-invoice-settling',
    templateUrl: 'invoice-settling.component.html',
})
export class InvoiceSettlingComponent {
    @Input() public invoiceDetails: InvoiceDetailsInterface;
    public debitCreditEnum = DebitCreditEnum;
}
