import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { ProductBaseInterface } from 'outshared-lib';
import { SalesfunnelRoutes } from '@app-de/salesfunnel/routing/salesfunnel-routing.routes';
import { AppRoutesEnum } from '@app-de/routes';
import { SalesfunnelService } from '@app-de/salesfunnel/salesfunnel.service';

@Component({
    selector: 'ins-my-insurances',
    templateUrl: './my-insurances.component.html',
    styleUrls: ['./my-insurances.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MyInsurancesComponent {
    private readonly salesfunnelServcie = inject(SalesfunnelService);
    @Input() public products: ProductBaseInterface[];
    @Input() public loading: boolean;

    public salesfunnelRoutesEnum: typeof SalesfunnelRoutes = SalesfunnelRoutes;
    public appRoutesEnum = AppRoutesEnum;

    onAddSecondVehicleClick() {
        this.salesfunnelServcie.navigateToCleanedCarSalesfunnel();
    }
}
