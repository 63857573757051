import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {
    AccountRecoveryActionService,
    AccountRecoveryDataService,
    AuthenticationActionService,
    AuthenticationDataService,
    AuthenticationStatusEnum,
    CompleteGeneratedAccountRequestInterface,
    ElementValidator,
    ErrorInterface,
    LoginRequestInterface,
    NewModalRef,
    PersonalInformationInterface,
} from 'outshared-lib';
import { Subscription } from 'rxjs';
import { AppRoutesEnum } from '@app-de/routes';
import { equalPassword } from '@app-de/shared/validators/equal-password.validator';

const WEBSERVICE_ID = 'VERZ.PROD.VSLG.NREL.PROD.AUTO';
const VALIDATOR_NAME_PHONE = 'telephone_number';
const CONTEXT_PHONE = 'landline_or_mobile_telephone_number';
const VALIDATOR_NAME_PASSWORD = 'password';
const CONTEXT_PASSWORD = 'relation_login';

@Component({
    selector: 'ins-complete-generated-account-modal',
    templateUrl: 'complete-generated-account.modal.html',
    styleUrls: ['./complete-generated-account.modal.scss'],
})
export class CompleteGeneratedAccountModal implements OnInit, OnDestroy {
    public submitting: boolean = false;
    public errors: ErrorInterface[] = [];
    public form: UntypedFormGroup;

    private email: string;
    private temporaryPassword: string;
    private subscriptions!: Subscription;

    public get newPassword(): AbstractControl {
        return this.form.get('newPassword');
    }

    public get repeatPassword(): AbstractControl {
        return this.form.get('repeatPassword');
    }

    public get optOutIndication(): AbstractControl {
        return this.form.get('optOutIndication');
    }

    public get phone(): AbstractControl {
        return this.form.get('phone');
    }

    constructor(
        private router: Router,
        private accountRecoveryDataService: AccountRecoveryDataService,
        private accountRecoveryActionService: AccountRecoveryActionService,
        private authenticationDataService: AuthenticationDataService,
        private authenticationActionService: AuthenticationActionService,
        private elementValidator: ElementValidator,
        private newModalRef: NewModalRef<CompleteGeneratedAccountModal>,
    ) {}

    public ngOnInit(): void {
        this.subscriptions = new Subscription();
        this.checkAuthenticationStatus();
        this.initializeForm();
        this.loadPersonalInformation();
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    public onSubmit(): void {
        if (!this.form.valid) {
            this.form.markAllAsTouched();
            return;
        }
        this.errors = [];
        this.submitting = true;

        const request: CompleteGeneratedAccountRequestInterface = {
            email: this.email,
            temporaryPassword: this.temporaryPassword,
            newPassword: this.newPassword.value,
            phone: this.phone.value,
            optOut: this.optOutIndication.value === 'true',
        };
        this.accountRecoveryActionService.completeGeneratedAccount$(request).subscribe(
            () => {
                this.autoLogin();
            },
            (errors: ErrorInterface[]) => {
                this.errors = errors;
                this.submitting = false;
            },
        );
    }

    private initializeForm(): void {
        this.form = new UntypedFormGroup(
            {
                newPassword: new UntypedFormControl('', {
                    validators: [Validators.required],
                    asyncValidators: [
                        this.elementValidator.validate(VALIDATOR_NAME_PASSWORD, WEBSERVICE_ID, CONTEXT_PASSWORD),
                    ],
                    updateOn: 'blur',
                }),
                repeatPassword: new UntypedFormControl('', [Validators.required]),
                optOutIndication: new UntypedFormControl('false', [Validators.required]),
                phone: new UntypedFormControl('', {
                    validators: [Validators.required, Validators.minLength(8)],
                    asyncValidators: [
                        this.elementValidator.validate(VALIDATOR_NAME_PHONE, WEBSERVICE_ID, CONTEXT_PHONE),
                    ],
                    updateOn: 'change',
                }),
            },
            {
                validators: equalPassword('newPassword', 'repeatPassword'),
            },
        );
    }

    private loadPersonalInformation(): void {
        const personalInformationSubscription = this.accountRecoveryDataService
            .getPersonalInformation$()
            .subscribe((personalInformation: PersonalInformationInterface) => {
                if (!this.submitting && (!personalInformation.email || !personalInformation.temporaryPassword)) {
                    this.router.navigate([AppRoutesEnum.Login]);
                }

                if (personalInformation) {
                    this.email = personalInformation.email;
                    this.temporaryPassword = personalInformation.password;
                }
            });
        this.subscriptions.add(personalInformationSubscription);
    }

    private checkAuthenticationStatus(): void {
        const statusSubscription = this.authenticationDataService
            .getStatus$()
            .subscribe((status: AuthenticationStatusEnum) => {
                if (!this.submitting && status !== AuthenticationStatusEnum.GeneratedAccount) {
                    this.router.navigate([AppRoutesEnum.Login]);
                }
            });
        this.subscriptions.add(statusSubscription);
    }

    private autoLogin(): void {
        const request: LoginRequestInterface = {
            email: this.email,
            password: this.newPassword.value,
        };
        this.authenticationActionService.login$(request).subscribe(
            () => this.closeModalAndNavigate([AppRoutesEnum.MyZone]),
            () => this.closeModalAndNavigate([AppRoutesEnum.Login]),
        );
    }

    private closeModalAndNavigate(route: AppRoutesEnum[]): void {
        this.newModalRef.close();
        this.router.navigate(route);
    }

    protected readonly VALIDATOR_NAME_PASSWORD = VALIDATOR_NAME_PASSWORD;
}
