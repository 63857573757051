import { Injectable } from '@angular/core';
import { CoveragePeriodInterface, CoveragePremiumProductInterface } from 'outshared-lib';

@Injectable({ providedIn: 'root' })
export class AnnualPaymentService {
    public getCurrentPeriod(
        product: CoveragePremiumProductInterface,
    ): CoveragePremiumProductInterface | CoveragePeriodInterface {
        return (
            product?.invoice_periods?.find(
                (period) => period.invoice_period_months_number === product.purchased_invoice_period_months_number,
            ) ?? product
        );
    }
}
