import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ListFlatItemComponent } from './list-flat-item.component';
import { ListFlatComponent } from './list-flat.component';
import { ListGroupComponent } from './list-group.component';

@NgModule({
    declarations: [
        ListFlatComponent,
        ListFlatItemComponent,
        ListGroupComponent,
    ],
    imports: [CommonModule],
    exports: [
        ListFlatComponent,
        ListFlatItemComponent,
        ListGroupComponent,
    ],
})
export class ListModule {}
