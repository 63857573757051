import { Component, Input } from '@angular/core';
import { RelationInterface } from 'outshared-lib';

@Component({
    selector: 'ins-opt-out-data',
    templateUrl: './opt-out-data.component.html',
})
export class OptOutDataComponent {
    @Input() public relation: RelationInterface;
}
