import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorInterface, ProductActionService, ProductBaseInterface, ProductSelectorService } from 'outshared-lib';
import { Observable } from 'rxjs';

@Component({
    selector: 'ins-change-start-date-insurance-container',
    template: `
        <ins-change-start-date-insurance (submitted)="onSubmit($event)" [product]="product$ | async" [errors]="errors">
        </ins-change-start-date-insurance>
    `,
})
export class ChangeStartDateInsuranceContainer {
    public product$: Observable<ProductBaseInterface> = this.productSelectorService.getSelectedProduct$;

    public errors: ErrorInterface[];

    constructor(
        private productSelectorService: ProductSelectorService,
        private productActionService: ProductActionService,
        private router: Router,
    ) {}

    public onSubmit(request: { startDate: string; productSequenceNumber: number }): void {
        this.productActionService
            .modifyFuturePurchasedProductStartDate({
                start_date: request.startDate,
                purchased_product_sequence_number: request.productSequenceNumber,
            })
            .subscribe(
                () => this.closePanel(),
                (errors) => (this.errors = errors),
            );
    }

    private closePanel(): void {
        this.router.navigate([], {
            queryParams: {
                panel: null,
                scrollTo: null,
                expand: null,
            },
            queryParamsHandling: 'merge',
        });
    }
}
