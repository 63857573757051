export enum AccountRecoveryModalRoutesEnum {
    AccountRecovery = 'konto-wiederherstellung',
    CompleteGeneratedAccount = 'konto-vervollstandigen',
    ForgotEmail = 'e-mail-adresse-vergessen',
    ForgotPassword = 'passwort-vergessen',
    SendPassword = 'passwort-senden',
    TemporaryPassword = 'temporares-passwort',
    UnblockAccount = 'konto-entsperren',
    ValidateGeneratedAccount = 'konto-validieren',
}
