import { Component, Input } from '@angular/core';

@Component({
    selector: 'ins-details-delete-button',
    templateUrl: './details-delete-button.component.html',
})
export class DetailsDeleteButtonComponent {
    @Input() public title: string = '';
    @Input() public isDisabled: boolean = false;
}
