import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
    name: 'sanitize',
})
export class SanitizePipe implements PipeTransform {
    constructor(private domSanitizer: DomSanitizer) {}

    public transform(style): any {
        return this.domSanitizer.bypassSecurityTrustHtml(style);
        // return this.domSanitizer.bypassSecurityTrustResourceUrl('data:' + mimetype + ';base64,' + url);
    }
}
