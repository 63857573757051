<!-- Auto -->
<ng-container *ngIf="isCar(product.product_id)">
    <ins-panel-expandable direction="right" #car="panel">
        <ins-panel-header>
            <ins-panel-label>Auto</ins-panel-label>
            <ins-panel-button [target]="car" openText="Downloaden" closeText="Schließen"></ins-panel-button>
        </ins-panel-header>
        <ins-panel-body>
            <ins-international-motor-insurance-card-buttons
                *ngIf="car.visible"
                [product]="product"
            ></ins-international-motor-insurance-card-buttons>
        </ins-panel-body>
    </ins-panel-expandable>
</ng-container>
