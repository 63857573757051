export enum MyZoneRoutes {
    MyInsurances = 'versicherungen',
    Details = 'meine-angaben',
    Account = 'zugangsdaten',
    TermsAndConditions = 'meine-dokumente',
    ClaimStatus = 'claimStatus', // TODO : Translate
    MyDamageFreeYears = 'meine-sf-klassen',
    InsuranceInfoCheck = 'angaben-ueberpruefen',
    Invoices = 'meine-rechnungen',
    InvoiceDetails = 'rechnungs-details',
    AnnualReward = 'jahresbelohnung',
}
