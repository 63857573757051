import { Component, Input } from '@angular/core';
import { ReadMoreDirective } from '@app-de/shared/directives/read-more/read-more.directive';

@Component({
    selector: 'ins-read-more-toggle-button',
    template: ` <a (click)="readMore.toggle($event)" class="link-secondary">
        {{ readMore.visible ? '&#60;&nbsp;Einklappen' : '&#62;&nbsp;Weiterlesen' }}
    </a>`,
})
export class ReadMoreToggleButtonComponent {
    @Input() public readMore: ReadMoreDirective;
}
