import { Component, EventEmitter, Input, Output } from '@angular/core';
import { WucIconModule, WucIconUiModule, WucLinkModule } from '../../common';
import { AnchorNavigationLinkInterface } from './anchor-navigation-link-interface';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'wuc-anchor-navigation',
    standalone: true,
    imports: [CommonModule, WucLinkModule, WucIconModule, WucIconUiModule],
    templateUrl: 'anchor-navigation.component.html',
    styleUrls: ['anchor-navigation.component.scss'],
})
export class WucAnchorNavigationComponent {
    @Input() public links: AnchorNavigationLinkInterface[] = [];
    @Output() scrollTo = new EventEmitter();
    public expanded = false;

    public emitScrollTo(scrollAnchor: string) {
        this.scrollTo.emit(scrollAnchor);
    }
}
