import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ErrorInterface } from '../../core';

@Injectable({
    providedIn: 'root',
})
export class ProductScenariosEventService {
    private _loadProductScenarios$: Subject<void>;

    public loadProductScenarios$(): Observable<void> {
        this._loadProductScenarios$ = new Subject();
        return this._loadProductScenarios$.asObservable();
    }

    public onLoadProductScenariosSuccess(): void {
        this._loadProductScenarios$.next();
        this._loadProductScenarios$.complete();
    }

    public onLoadProductScenariosError(errors: ErrorInterface[]): void {
        this._loadProductScenarios$.error(errors);
    }
}
