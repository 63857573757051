<ins-change-coverage
    [product]="product$ | async"
    [hasCancelButton]="hasCancelButton"
    [notifications]="notifications$ | async"
    [compareTableColumns]="compareTableColumns$ | async"
    [isScreenSmall]="isScreenSmall$ | async"
    [compareTableCoverages]="compareTableCoverages"
    [additionalCoverages]="additionalCoverages"
    [additionalCoveragePrices]="additionalCoveragePrices"
    [payment]="payment"
    [currentlyPurchasedCoverage]="currentlyPurchasedCoverage"
    [isSelectedCoveragePanelOpen]="isSelectedCoveragePanelOpen"
    [selectedBaseCoverage]="selectedBaseCoverage"
    [purchasedPeriod]="calculation?.product?.purchased_invoice_period_months_number"
    [extraInfo]="extraInfo"
    (purchase)="onPurchase($event)"
    (exit)="toggle()"
    (formValueChanges)="onFormValueChanges($event)"
    (toggleSelectedCoveragePanel)="toggleSelectedCoveragePanel()"
></ins-change-coverage>
