<wuc-alert
    icon="privacy"
    title="Bitte beachten: Wir müssen Ihre SF-Klassen anpassen"
    text="Das kann sich auf Ihren Beitrag auswirken."
    type="primary"
    iconType="primary"
>
    <wuc-button type="secondary" [isFullWidth]="true" (buttonClick)="goToDamageFreeYearsImpact()">
        Jetzt alle Details lesen!
    </wuc-button>
</wuc-alert>
