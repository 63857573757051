import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import { InsuranceInfoCheckEventService, InsuranceInfoCheckApiService } from '../services';
import * as InsuranceInfoCheckActions from './insurance-info-check.actions';

@Injectable()
export class InsuranceInfoCheckEffects {
    public getInsuranceInfoCheck$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(InsuranceInfoCheckActions.getInsuranceInfoCheck),
            exhaustMap(({ request }) =>
                this.insuranceInfoCheckApiService.getInsuranceInfoCheck$(request).pipe(
                    map((response) => InsuranceInfoCheckActions.getInsuranceInfoCheckSuccess({ response })),
                    catchError((errors) => of(InsuranceInfoCheckActions.getInsuranceInfoCheckFailure({ errors })))
                )
            )
        );
    });

    public getInsuranceInfoCheckSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(InsuranceInfoCheckActions.getInsuranceInfoCheckSuccess),
                tap(() => this.insuranceInfoCheckEventService.onGetInsuranceInfoCheckSuccess())
            );
        },
        { dispatch: false }
    );

    public getInsuranceInfoCheckError$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(InsuranceInfoCheckActions.getInsuranceInfoCheckFailure),
                tap(({ errors }) => this.insuranceInfoCheckEventService.onGetInsuranceInfoCheckError(errors))
            );
        },
        { dispatch: false }
    );

    public saveInsuranceInfoCheck$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(InsuranceInfoCheckActions.saveInsuranceInfoCheckInfo),
            exhaustMap(({ request }) =>
                this.insuranceInfoCheckApiService.saveInsuranceInfoCheck$(request).pipe(
                    map((response) => InsuranceInfoCheckActions.saveInsuranceInfoCheckSuccess()),
                    catchError((errors) => of(InsuranceInfoCheckActions.saveInsuranceInfoCheckFailure({ errors })))
                )
            )
        );
    });

    public saveInsuranceInfoCheckSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(InsuranceInfoCheckActions.saveInsuranceInfoCheckSuccess),
                tap(() => this.insuranceInfoCheckEventService.onSaveInsuranceInfoCheckSuccess())
            );
        },
        { dispatch: false }
    );

    public saveInsuranceInfoCheckError$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(InsuranceInfoCheckActions.saveInsuranceInfoCheckFailure),
                tap(({ errors }) => this.insuranceInfoCheckEventService.onSaveInsuranceInfoCheckError(errors))
            );
        },
        { dispatch: false }
    );

    constructor(
        private actions$: Actions,
        private insuranceInfoCheckApiService: InsuranceInfoCheckApiService,
        private insuranceInfoCheckEventService: InsuranceInfoCheckEventService
    ) {}
}
