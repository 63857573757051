import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WucLinkModule } from '@inshared/website-ui-components';
import { InsurancesRoutingModule } from './insurances-routing.module';
import { InsurancesComponent } from './insurances.component';
import { CardModule } from '@app-de/shared/card/card.module';
import { ChatbotEntryModule } from '@app-de/shared/customer-service/chatbot/chatbot-entry/chatbot-entry.module';
import { ListModule } from '@app-de/shared/lists/list.module';
import { ProductCardModule } from '@app-de/shared/product-card/product-card.module';

@NgModule({
    declarations: [InsurancesComponent],
    imports: [
        CommonModule,
        InsurancesRoutingModule,
        CardModule,
        ListModule,
        ProductCardModule,
        ChatbotEntryModule,
        WucLinkModule
    ],
    exports: [InsurancesComponent],
})
export class InsurancesModule {}
