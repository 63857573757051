import {
    ChangeDetectorRef,
    ContentChild,
    Directive,
    ElementRef,
    forwardRef,
    Inject,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { Subject } from 'rxjs';
import { AutocloseService } from './autoclose.service';

@Directive({
    selector: '[insDropdownToggle]',
})
export class DropdownToggleDirective {
    public toggleElement;

    constructor(
        @Inject(forwardRef(() => DropdownDirective)) public dropdown,
        private _elementRef: ElementRef<HTMLElement>,
    ) {
        this.toggleElement = _elementRef.nativeElement;
    }

    public getNativeElement(): HTMLElement {
        return this._elementRef.nativeElement;
    }
}

@Directive({
    selector: '[insDropdownMenu]',
    host: {
        '[class.dropdown]': 'true',
        '[class.show]': 'dropdown.isOpen()',
        '[class.dropdown--left]': 'dropdown.classes?.left',
        '[class.dropdown--center]': 'dropdown.classes?.center',
        '[class.dropdown--right]': 'dropdown.classes?.right',
        '[class.dropdown--small]': 'dropdown.classes?.small',
        '[class.dropdown--medium]': 'dropdown.classes?.medium',
        '[class.dropdown--large]': 'dropdown.classes?.large',
        '[class.dropup--right]': 'dropdown.classes?.topright',
        '[class.dropup]': 'dropdown.classes?.top',
    },
})
export class DropdownMenuDirective {
    constructor(@Inject(forwardRef(() => DropdownDirective)) public dropdown) {}
}

export interface DropdownOptions {
    align?: string;
    valign?: string;
    size?: string;
}

@Directive({
    selector: '[insDropdown]',
    exportAs: 'dropdown',
    host: {
        class: 'dropdown-container',
    },
})
export class DropdownDirective implements OnInit, OnDestroy {
    @Input() public options: DropdownOptions;

    public closed$: Subject<void> = new Subject<void>();
    public classes: { [key: string]: boolean };

    @ContentChild(DropdownMenuDirective, { static: true }) private _menu: DropdownMenuDirective;
    @ContentChild(DropdownMenuDirective, { static: true, read: ElementRef }) private _menuElement: ElementRef;
    @ContentChild(DropdownToggleDirective, { static: true }) private _toggle: DropdownToggleDirective;

    private _open: boolean = false;
    private _defaultOptions = {
        align: 'left',
        size: 'small',
        valign: 'bottom',
    };

    constructor(private cd: ChangeDetectorRef, private autoclose: AutocloseService) {}

    public ngOnInit(): void {
        this.setClasses({ ...this._defaultOptions, ...this.options });
    }

    public isOpen(): boolean {
        return this._open;
    }

    public open(): void {
        if (!this.isOpen()) {
            this.cd.markForCheck();
            this._open = true;

            this.setCloseHandlers();
        }
    }

    public close(): void {
        if (this.isOpen()) {
            this.cd.markForCheck();
            this._open = false;

            this.closed$.next();
        }
    }

    public toggle(): void {
        this.isOpen() ? this.close() : this.open();
    }

    public ngOnDestroy(): void {
        this.closed$.next();
    }

    private setClasses(options: DropdownOptions): void {
        this.classes = {
            top: options.valign === 'top',
            left: options.align === 'left',
            center: options.align === 'center',
            right: options.align === 'right',
            topright: options.align === 'top-right',
            small: options.size === 'small',
            medium: options.size === 'medium',
            large: options.size === 'large',
        };
    }

    private setCloseHandlers(): void {
        this.autoclose.setup(
            () => this.close(),
            this.closed$,
            this._menu ? [this._menuElement.nativeElement] : [],
            this._toggle ? [this._toggle.getNativeElement()] : [],
        );
    }
}
