import { Component, Input } from '@angular/core';

@Component({
    selector: 'ins-list-flat',
    templateUrl: 'list-flat.component.html',
    styleUrls: ['list-flat.component.scss'],
})
export class ListFlatComponent {
    @Input() public icon: string;
    @Input() public svg: boolean = false;
    @Input() public usp: boolean = false;
    @Input() public expanded: boolean = false;
    @Input() public numbered: boolean = false;
    @Input() public lined: boolean = false;
    @Input() public table: boolean = false;
}
