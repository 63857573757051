import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ErrorInterface, IndicationEnum, RelationInterface } from 'outshared-lib';
import { Subscription } from 'rxjs';

@Component({
    selector: 'ins-opt-out-form',
    templateUrl: './opt-out-form.component.html',
})
export class OptOutFormComponent implements OnChanges, OnDestroy {
    @Output() public readonly submitted = new EventEmitter<IndicationEnum>();

    @Input()
    public errors: ErrorInterface[];

    @Input('relation') public set setRelation(relation: RelationInterface) {
        if (!this.form) {
            this.createForm();
        }

        if (relation && this.form) {
            this.resetForm(relation);
        }
    }

    public form: UntypedFormGroup;
    public disabled: boolean = false;
    public indication: typeof IndicationEnum = IndicationEnum;
    private subscriptions = new Subscription();

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes?.errors?.currentValue) {
            this.disabled = false;
        }
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    public get optOutIndication(): AbstractControl {
        return this.form.get('optOutIndication');
    }

    public onFormSubmit(): void {
        this.errors = null;
        this.disabled = true;
        this.submitted.emit(this.optOutIndication.value);
    }

    private createForm(): void {
        this.form = new UntypedFormGroup({
            optOutIndication: new UntypedFormControl('', [Validators.required]),
        });
    }

    private resetForm(relation: RelationInterface): void {
        this.disabled = true;

        this.form.reset({
            optOutIndication: relation.opt_out_timestamp ? this.indication.Yes : this.indication.No,
        });

        const optOutValueChanges = this.optOutIndication.valueChanges.subscribe((value) => {
            this.disabled =
                (relation.opt_out_timestamp && value === this.indication.Yes) ||
                (!relation.opt_out_timestamp && value === this.indication.No);
        });

        this.subscriptions.add(optOutValueChanges);
    }
}
