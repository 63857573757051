import { Injectable } from '@angular/core';
import { FormGroupDirective, NgForm, UntypedFormControl } from '@angular/forms';

@Injectable()
export class ErrorStateMatcher {
    public isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        if (control && control.touched) {
            return control.invalid;
        }

        if (form && form.submitted) {
            return control.invalid;
        }
        return null;
    }
}
