import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ErrorInterface } from '../../core';

@Injectable({
    providedIn: 'root',
})
export class TestimonialsEventService {
    private _getTestimonials$: Subject<void>;

    public getTestimonials$(): Observable<void> {
        this._getTestimonials$ = new Subject();
        return this._getTestimonials$.asObservable();
    }

    public onGetTestimonialsSuccess(): void {
        this._getTestimonials$.next();
        this._getTestimonials$.complete();
    }

    public onGetTestimonialsError(errors: ErrorInterface[]): void {
        if (this._getTestimonials$) {
            this._getTestimonials$.error(errors);
        }
    }
}
