import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NewModalModule } from 'outshared-lib';
import { InsuranceControlItemComponent } from './insurance-control-item/insurance-control-item.component';
import { InsuranceControlListComponent } from './insurance-control-list/insurance-control-list.component';
import { InsuranceControlComponent } from './insurance-control.component';
import { InsuranceControlContainer } from '@app-de/my-zone/shared/insurance-control/insurance-control.container';
import { YearRewardModalModule } from '@app-de/my-zone/shared/year-reward-modal/year-reward-modal.module';
import { InsurancesModule } from '@app-de/pages/insurances/insurances.module';

@NgModule({
    imports: [
        CommonModule,
        InsurancesModule,
        YearRewardModalModule,
        NewModalModule,
    ],
    exports: [InsuranceControlContainer],
    declarations: [
        InsuranceControlComponent,
        InsuranceControlContainer,
        InsuranceControlListComponent,
        InsuranceControlItemComponent,
    ],
})
export class InsuranceControlModule {}
