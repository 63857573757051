import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { InvoicePriceModule } from '../invoice-price/invoice-price.module';
import { InvoiceSettlingComponent } from './invoice-settling.component';
import { CardModule } from '@app-de/shared/card/card.module';
import { MobileScrollModule } from '@app-de/shared/mobile-scroll/mobile-scroll.module';
import { RackModule } from '@app-de/shared/rack/rack.module';

@NgModule({
    imports: [CommonModule, CardModule, RackModule, InvoicePriceModule, MobileScrollModule],
    exports: [InvoiceSettlingComponent],
    declarations: [InvoiceSettlingComponent],
})
export class InvoiceSettlingModule { }
