<ins-panel-body
    *ngIf="
        calculation &&
        calculation.product &&
        (calculation.product.additional_coverage || calculation.product.base_coverages)
    "
>
    <div class="panel__title">
        <h3>Deckungen & Tarife</h3>
    </div>

    <!-- Current -->
    <ins-coverage-overview-current
        *ngIf="!calculation.product.coverages_adjustment_start_date"
        [modulesCurrent]="modulesCurrent"
        [calculation]="calculation"
    ></ins-coverage-overview-current>
    <!-- // Current -->

    <ng-container *ngIf="calculation.product.coverages_adjustment_start_date" [ngSwitch]="product.validity_status_code">
        <ng-container *ngSwitchCase="'TOEK'">
            <!-- Future -->
            <ins-coverage-overview-future
                [modulesFuture]="modulesFuture"
                [calculation]="calculation"
            ></ins-coverage-overview-future>
            <!-- // Future -->
        </ng-container>
        <ng-container *ngSwitchCase="'OSTP'">
            <!-- Future -->
            <ins-coverage-overview-future
                [modulesFuture]="modulesFuture"
                [calculation]="calculation"
            ></ins-coverage-overview-future>
            <!-- // Future -->
        </ng-container>
        <ng-container *ngSwitchDefault>
            <ins-tabset>
                <ins-tab title="Aktueller Versicherungsschutz">
                    <!-- Current -->
                    <ins-coverage-overview-current
                        [modulesCurrent]="modulesCurrent"
                        [calculation]="calculation"
                    ></ins-coverage-overview-current>
                    <!-- // Current -->
                </ins-tab>
                <ins-tab
                    [active]="true"
                    title="Versicherungsschutz ab {{
                        calculation.product.coverages_adjustment_start_date | dateFormat
                    }}"
                >
                    <!-- Future -->
                    <ins-coverage-overview-future
                        [modulesFuture]="modulesFuture"
                        [calculation]="calculation"
                    ></ins-coverage-overview-future>
                    <!-- // Future -->
                </ins-tab>
            </ins-tabset>
        </ng-container>
    </ng-container>
</ins-panel-body>
