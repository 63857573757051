import { NgModule } from '@angular/core';
import { DateFormatPipe } from './date-format.pipe';

@NgModule({
    imports: [],
    exports: [DateFormatPipe],
    declarations: [DateFormatPipe],
})

export class DateFormatPipeModule {}
