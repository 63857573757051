import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { InvoicePriceComponent } from './invoice-price.component';

@NgModule({
    imports: [CommonModule],
    exports: [InvoicePriceComponent],
    declarations: [InvoicePriceComponent],
})
export class InvoicePriceModule { }
