import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DownloadingModal } from './downloading.modal';
import { ModalComponentsModule } from '@app-de/components';
import { CatTreadmillAnimationModule } from '@app-de/components/cat/cat-treadmill-animation.module';

@NgModule({
    imports: [
        CommonModule,
        CatTreadmillAnimationModule,
        ModalComponentsModule,
    ],
    exports: [DownloadingModal],
    declarations: [DownloadingModal],
})
export class DownloadingModalModule {}
