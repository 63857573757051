import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MyZoneContainer } from '../my-zone.container';
import { MyZoneRoutes } from './my-zone-routing.routes';

const routes: Routes = [
    {
        path: '',
        component: MyZoneContainer,
        children: [
            {
                path: '',
                redirectTo: MyZoneRoutes.MyInsurances,
                pathMatch: 'full',
            },
            {
                path: MyZoneRoutes.MyInsurances,
                loadChildren: () =>
                    import('../pages/my-insurances/my-insurances.module').then((m) => m.MyInsurancesModule),
                data: {
                    title: 'Meine Versicherungsmappe - Meine Versicherungen - InShared',
                    description:
                        'In Ihrer digitalen Versicherungsmappe regeln Sie Ihre Versicherungen einfach und schnell online.',
                },
            },
            {
                path: MyZoneRoutes.Details,
                loadChildren: () => import('../pages/details/details.module').then((m) => m.DetailsModule),
                data: {
                    title: 'Meine Versicherungsmappe - Meine Angaben - InShared',
                    description:
                        'In Ihrer Online-Versicherungsmappe können Sie persönliche Angaben einfach und schnell anpassen. Loggen Sie sich mit E-Mail-Adresse und Passwort ein.',
                },
            },
            {
                path: MyZoneRoutes.TermsAndConditions,
                loadChildren: () =>
                    import('../pages/terms-and-condition/terms-and-condition.module').then(
                        (m) => m.TermsAndConditionModule,
                    ),
                data: {
                    title: 'Meine Versicherungsmappe - Meine Dokumente - InShared',
                },
            },
            {
                path: MyZoneRoutes.MyDamageFreeYears,
                loadChildren: () =>
                    import('../pages/my-damage-free-years/my-damage-free-years.module').then(
                        (m) => m.MyDamageFreeYearsModule,
                    ),
                data: {
                    title: 'Meine Versicherungsmappe - Meine SF-Klassen - InShared',
                },
            },
            {
                path: MyZoneRoutes.InsuranceInfoCheck,
                loadChildren: () =>
                    import('@app-de/my-zone/pages/insurance-info-check/insurance-info-check.module').then(
                        (m) => m.InsuranceInfoCheckModule,
                    ),
                data: {
                    title: 'Meine Versicherungsmappe - Angaben überprüfen - InShared',
                },
            },
            {
                path: MyZoneRoutes.Invoices,
                loadChildren: () => import('../pages/invoices/invoices.module').then((m) => m.InvoicesModule),
                data: {
                    title: 'Meine Versicherungsmappe - Meine Rechnungen - InShared',
                },
            },
            {
                path: MyZoneRoutes.InvoiceDetails,
                loadChildren: () =>
                    import('../pages/invoice-details/invoice-details.module').then((m) => m.InvoiceDetailsModule),
                data: {
                    title: 'Meine Versicherungsmappe - Invoice Details - InShared',
                },
            },
            {
                path: MyZoneRoutes.AnnualReward,
                loadChildren: () => import('../pages/reward/reward.module').then((m) => m.RewardModule),
                data: {
                    title: 'Meine Versicherungsmappe - Jahresbelohnung - InShared',
                },
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class MyZoneRoutingModule {}
