import { Component } from '@angular/core';
import { AllesOnlineRoutes } from '@app-de/pages/about-inshared/alles-online/alles-online-routing.routes';
import { AutoversicherungRoutesEnum } from '@app-de/pages/car-insurance/autoversicherung/autoversicherung-routes.enum';
import { AppRoutesEnum, ModalRoutesEnum } from '@app-de/routes';

@Component({
    selector: 'ins-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
    public appRoutesEnum = AppRoutesEnum;
    public modalRoutesEnum = ModalRoutesEnum;
    public autoversicherungRoutesEnum = AutoversicherungRoutesEnum;
    public allesOnlineRoutes = AllesOnlineRoutes;
}
