import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { InsuranceDriverDataComponent } from './insurance-regular-driver-data.component';
import { PanelExpandableModule } from '@app-de/shared/panel-expandable/panel-expandable.module';
import { DateFormatPipeModule } from '@app-de/shared/pipes/date-format/date-format.module';

@NgModule({
    declarations: [InsuranceDriverDataComponent],
    imports: [
        CommonModule,
        DateFormatPipeModule,
        PanelExpandableModule,
    ],
    exports: [InsuranceDriverDataComponent],
})
export class InsuranceRegularDriverDataModule {
}
