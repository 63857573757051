import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NewModalModule } from 'outshared-lib';
import { DetailsDeleteButtonModule } from '../details-delete-button/details-delete-button.module';
import { DetailsSubmitButtonModule } from '../details-submit-button/details-submit-button.module';
import { DetailsTableModule } from '../details-table/details-table.module';
import { PremiumImpactModule } from '../premium-impact-modal/premium-impact.module';
import { FamilyCompositionDataComponent } from './family-composition-data/family-composition-data.component';
import { FamilyCompositionFormComponent } from './family-composition-form/family-composition-form.component';
import { FamilyCompositionModal } from './family-composition-modal/family-composition.modal';
import { FutureFamilyCompositionComponent } from './future-family-composition/future-family-composition.component';
import { ModalComponentsModule } from '@app-de/components';
import { FamilyCompositionFormContainer } from '@app-de/my-zone/shared/family-composition/family-composition-form/family-composition-form.container';
import { FutureFamilyCompositionContainer } from '@app-de/my-zone/shared/family-composition/future-family-composition/future-family-composition.container';
import { CynoFormModule } from '@app-de/shared/cyno-form/cyno-form.module';
import { DatepickerModule } from '@app-de/shared/datepicker/datepicker.module';
import { DateFormatPipeModule } from '@app-de/shared/pipes/date-format/date-format.module';

@NgModule({
    imports: [
        CommonModule,
        CynoFormModule,
        DatepickerModule,
        DateFormatPipeModule,
        NewModalModule,
        ModalComponentsModule,
        PremiumImpactModule,
        DetailsTableModule,
        DetailsSubmitButtonModule,
        DetailsDeleteButtonModule,
    ],
    exports: [
        FamilyCompositionDataComponent,
        FamilyCompositionFormContainer,
        FutureFamilyCompositionComponent,
        FutureFamilyCompositionContainer,
    ],
    declarations: [
        FamilyCompositionDataComponent,
        FamilyCompositionFormComponent,
        FamilyCompositionFormContainer,
        FutureFamilyCompositionComponent,
        FamilyCompositionModal,
        FutureFamilyCompositionContainer,
    ],
})
export class FamilyCompositionModule {}
