import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DetailsDeleteButtonModule } from '../details-delete-button/details-delete-button.module';
import { DetailsSubmitButtonModule } from '../details-submit-button/details-submit-button.module';
import { DetailsTableModule } from '../details-table/details-table.module';
import { ResidentialAddressDataComponent } from './residential-address-data/residential-address-data.component';
import { ResidentialAddressFormComponent } from './residential-address-form/residential-address-form.component';
import { ResidentialAddressFormContainer } from './residential-address-form/residential-address-form.container';
import { ResidentialAddressFutureComponent } from './residential-address-future/residential-address-future.component';
import { ResidentialAddressFutureContainer } from './residential-address-future/residential-address-future.container';
import { AlertModule } from '@app-de/components/alert/alert.module';
import { AddressAutocompleteModule } from '@app-de/shared/address-autocomplete/address-autocomplete.module';
import { CynoFormModule } from '@app-de/shared/cyno-form/cyno-form.module';
import { DatepickerModule } from '@app-de/shared/datepicker/datepicker.module';
import { DateFormatPipeModule } from '@app-de/shared/pipes/date-format/date-format.module';
import { WucBalloonModule } from '@inshared/website-ui-components';

@NgModule({
    declarations: [
        ResidentialAddressDataComponent, ResidentialAddressFormComponent,
        ResidentialAddressFutureComponent, ResidentialAddressFormContainer,
        ResidentialAddressFutureContainer,
    ],
    exports: [ResidentialAddressDataComponent, ResidentialAddressFormContainer, ResidentialAddressFutureContainer],
    imports: [
        CommonModule,
        DetailsTableModule,
        AddressAutocompleteModule,
        AlertModule,
        CynoFormModule,
        DetailsSubmitButtonModule,
        DatepickerModule,
        DateFormatPipeModule,
        DetailsDeleteButtonModule,
        WucBalloonModule,
    ],
})
export class ResidentialAddressModule { }
