import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DateService, InvoiceActionService, InvoiceDataService, InvoiceDetailsInterface } from 'outshared-lib';
import { Observable, Subscription } from 'rxjs';
import { PdfDownloadUtilities } from '@app-de/shared/utilities/pdf-download-utilities';

@Component({
    selector: 'ins-invoice-details-container',
    template: ` <ins-invoice-details [invoiceDetails]="invoiceDetails$ | async" (download)="onDownload($event)">
    </ins-invoice-details>`,
})
export class InvoiceDetailsContainer implements OnInit, OnDestroy {
    public invoiceDetails$: Observable<InvoiceDetailsInterface>;
    public getInvoices$: Observable<void>;

    private subscriptions: Subscription = new Subscription();

    constructor(
        private invoiceDataService: InvoiceDataService,
        private invoiceActionService: InvoiceActionService,
        private dateService: DateService,
        private route: ActivatedRoute,
        @Inject(DOCUMENT) private document: Document,
    ) {}

    public ngOnInit(): void {
        const handleQueryParams = this.route.queryParams.subscribe((queryParams) =>
            this.handleQueryParams(queryParams),
        );

        this.subscriptions.add(handleQueryParams);
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    public handleQueryParams(queryParams): void {
        const invoiceId = queryParams.invoiceId;

        this.invoiceDetails$ = this.invoiceDataService.invoiceDetails$({ invoiceId });
        this.invoiceActionService.getInvoiceDetails$({ invoiceId });
    }

    public onDownload(invoiceId: string): void {
        const timestamp = this.dateService.now().format('DDMMYYYYHHmmssSSS').toString();

        const downloadInvoice = this.invoiceActionService
            .downloadInvoice$({ invoiceId })
            .subscribe(({ filename, base64 }) =>
                PdfDownloadUtilities.DownloadDocument(base64, `${filename}-${timestamp}`, this.document),
            );

        this.subscriptions.add(downloadInvoice);
    }
}
