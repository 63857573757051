import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
    selector: 'ins-address-autocomplete-field',
    templateUrl: './address-autocomplete-field.component.html',
})
export class AddressAutocompleteFieldComponent {
    @Input() public control: AbstractControl;
}
