import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {
    AccountRecoveryActionService,
    AccountRecoveryDataService,
    ErrorInterface,
    NewModalRef,
    PersonalInformationInterface,
    RecoveryMethodEnum,
    RecoveryMethodsResponseInterface,
    SendPasswordRequestInterface,
} from 'outshared-lib';
import { filter, take } from 'rxjs/operators';
import { AccountRecoveryModalRoutesEnum } from '@app-de/routes';

@Component({
    selector: 'ins-send-password-modal',
    templateUrl: 'send-password.modal.html',
})
export class SendPasswordModal implements OnInit {
    public form!: UntypedFormGroup;
    public errors: ErrorInterface[] = [];
    public notifications: ErrorInterface[] = [];
    public sending: boolean = false;
    public success: boolean = false;
    public options: { value: string; description: string }[];
    public backLink = ['', { outlets: { modal: AccountRecoveryModalRoutesEnum.ForgotPassword } }];

    private email: string;
    private birthdate: string;

    constructor(
        private accountRecoveryDataService: AccountRecoveryDataService,
        private accountRecoveryActionService: AccountRecoveryActionService,
        private router: Router,
        private newModalRef: NewModalRef<SendPasswordModal>,
    ) {}

    public ngOnInit(): void {
        this.initializeForm();
        this.loadPersonalData();
        this.loadRecoveryMethods();
    }

    public onSubmit(): void {
        this.errors = [];
        this.notifications = [];
        this.sending = true;

        const request: SendPasswordRequestInterface = {
            recoveryMethod: this.form.get('recoveryMethod').value,
            birthdate: this.birthdate,
            email: this.email,
        };
        this.accountRecoveryActionService.sendPassword$(request).subscribe(
            (notifications: ErrorInterface[]) => {
                this.notifications = notifications;
                this.success = true;
                this.sending = false;
            },
            (errors: ErrorInterface[]) => {
                this.errors = errors;
                this.sending = false;
            },
        );
    }

    public onCloseAfterSuccess(): void {
        this.newModalRef.close(true);
    }

    private loadPersonalData(): void {
        this.accountRecoveryDataService
            .getPersonalInformation$()
            .pipe(
                filter((personalInformation) => !!personalInformation),
                take(1),
            )
            .subscribe((personalInformation: PersonalInformationInterface) => {
                this.email = personalInformation.email;
                this.birthdate = personalInformation.birthdate;
            });
    }

    private loadRecoveryMethods(): void {
        this.accountRecoveryDataService
            .getRecoveryMethods$()
            .pipe(take(1))
            .subscribe((recoveryMethods?: RecoveryMethodsResponseInterface) => {
                if (!recoveryMethods) {
                    this.navigateBack();
                    return;
                }
                this.setMethods(recoveryMethods);
            });
    }

    private setMethods(recoveryMethods: RecoveryMethodsResponseInterface): void {
        this.options = [];
        if (recoveryMethods.primaryEmail) {
            this.options.push({
                value: RecoveryMethodEnum.PrimaryEmail,
                description: recoveryMethods.primaryEmail.recipient,
            });
        }
        if (recoveryMethods.recoveryEmail) {
            this.options.push({
                value: RecoveryMethodEnum.RecoveryEmail,
                description: recoveryMethods.recoveryEmail.recipient,
            });
        }
        if (recoveryMethods.phone) {
            this.options.push({
                value: RecoveryMethodEnum.Phone,
                description: recoveryMethods.phone.recipient,
            });
        }
    }

    private navigateBack(): void {
        this.router.navigate(['', { outlets: { modal: AccountRecoveryModalRoutesEnum.ForgotPassword } }]);
    }

    private initializeForm(): void {
        this.form = new UntypedFormGroup({
            recoveryMethod: new UntypedFormControl('', [Validators.required]),
        });
    }
}
