import { Component, Input } from '@angular/core';
import { ErrorInterface, NotificationInterface } from 'outshared-lib';

@Component({
    selector: 'ins-cyno-error-abstract-component',
    template: '',
})
export abstract class CynoErrorAbstractComponent {
    @Input() public id = '';
    @Input() public errors: (NotificationInterface | ErrorInterface)[] = [];
}
