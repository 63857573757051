<div
    class="form-group"
    [ngClass]="{
        'has-error': control.touched && control.invalid,
        'has-success': control.valid,
        'is-pending': control.pending
    }"
    e2e="address"
>
    <ng-content></ng-content>
</div>
