<wuc-drop-trigger *ngIf="!hasOpenPanel">
    <wuc-button [small]="true" [disabled]="!loadedProduct">Direkt erledigen</wuc-button>
    <wuc-balloon>
        <div class="direct-control-buttons__balloon">
            <div class="direct-control-buttons__button-wrapper" *ngFor="let button of buttons">
                <wuc-button
                    type="secondary"
                    [small]="true"
                    [disabled]="button.disabled"
                    (click)="clickAction(button)"
                    [isFullWidth]="true"
                    [id]="'direct_control-' + button.id"
                >
                    {{ button.name }}
                </wuc-button>
            </div>
        </div>
    </wuc-balloon>
</wuc-drop-trigger>

<wuc-button [small]="true" *ngIf="hasOpenPanel" type="primary" iconRight="up-open" (click)="closeAction()">
    Schließen
</wuc-button>
