import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, LOCALE_ID, Renderer2, RendererFactory2 } from '@angular/core';
import { Event, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { Observable, ReplaySubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import countrySelectorConfigJson from './country-selector-config.json';
import countrySelectorUrlMappingJson from './country-selector-urls.json';
import { CountrySelectorComponentConfig } from '@app-de/shared/country-selector/service/country-selector-component-config';
import { CountrySelectorConfigItemInterface } from '@app-de/shared/country-selector/service/country-selector-config.interface';
import { CountrySelectorUrlsInterface } from '@app-de/shared/country-selector/service/country-selector-urls.interface';

@Injectable()
export class CountrySelectorService {
    public onRouteChange$: Observable<CountrySelectorComponentConfig>;

    private renderer: Renderer2;
    private onRouteChangeSubject = new ReplaySubject<CountrySelectorComponentConfig>(1);

    constructor(
        @Inject(LOCALE_ID) private locale: string,
        @Inject(DOCUMENT) private document: any,
        private router: Router,
        private rendererFactory: RendererFactory2,
    ) {
        this.renderer = this.rendererFactory.createRenderer(null, null);
        this.onRouteChange$ = this.onRouteChangeSubject.asObservable();
    }

    public init(): void {
        this.router.events
            .pipe(filter((event: Event | RouterEvent) => event instanceof NavigationEnd))
            .subscribe((event: NavigationEnd) => this.onRouteChange(event.url));
    }

    private onRouteChange(url: string): void {
        let matchForUrl = this.getMatchForUrl(url);
        this.updateLinksInHead(matchForUrl);

        if (!matchForUrl) {
            matchForUrl = this.getMatchForUrl(url, true);
        }
        this.onRouteChangeSubject.next(this.renderCountrySelectorComponentConfig(url, matchForUrl));
    }

    private renderCountrySelectorComponentConfig(
        url: string,
        data: CountrySelectorUrlsInterface,
    ): CountrySelectorComponentConfig {
        const localeConfig: CountrySelectorConfigItemInterface = countrySelectorConfigJson[this.locale];

        const options = localeConfig.options.map((item, index) => {
            if (index === 0) {
                return { url: url, label: item.label };
            } else {
                return {
                    url: `${countrySelectorConfigJson[item.country].url}${data ? data[item.country] : '/'}`,
                    label: item.label,
                };
            }
        });

        return {
            label: localeConfig.label,
            options: options,
        };
    }

    private getMatchForUrl(url: string, traverseUp = false): CountrySelectorUrlsInterface {
        let data = countrySelectorUrlMappingJson.find(
            (entry: CountrySelectorUrlsInterface) => entry[this.locale] === url,
        );
        if (!data && traverseUp) {
            const parts = url.split('/');
            while (!data && parts.length) {
                parts.pop();
                data = this.getMatchForUrl(parts.join('/'));
            }
        }
        return data;
    }

    private updateLinksInHead(data: CountrySelectorUrlsInterface): void {
        Object.keys(countrySelectorConfigJson).forEach((key: string) => {
            const localeConfig: CountrySelectorConfigItemInterface = countrySelectorConfigJson[key];
            if (data?.[key]) {
                this.updateLinkInHead(localeConfig.hreflang, `${localeConfig.url}${data[key]}`);
            } else {
                this.removeLinkFromHead(localeConfig.hreflang);
            }
        });
    }

    private updateLinkInHead(hreflang: string, href: string): void {
        let link = this.getLinkFromHead(hreflang);

        if (link) {
            this.renderer.setAttribute(link, 'href', href);
        } else {
            link = this.renderer.createElement('link');
            this.renderer.setAttribute(link, 'rel', 'alternate');
            this.renderer.setAttribute(link, 'hreflang', hreflang);
            this.renderer.setAttribute(link, 'href', href);
            this.renderer.appendChild(this.document.head, link);
        }
    }

    private removeLinkFromHead(hreflang: string): void {
        const link = this.getLinkFromHead(hreflang);

        if (link) {
            this.renderer.removeChild(this.document.head, link);
        }
    }

    private getLinkFromHead(hreflang: string): HTMLLinkElement | undefined {
        return Array.from<HTMLLinkElement>(this.document.head.children).find(
            (linkElement: HTMLLinkElement) => linkElement.rel === 'alternate' && linkElement.hreflang === hreflang,
        );
    }
}
