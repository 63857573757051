<form [formGroup]="form">
    <wuc-form-field name="endDate">
        <wuc-form-field-label>Beginndatum</wuc-form-field-label>
        <wuc-input-date-picker
            formControlName="endDate"
            placeholder="TT.MM.JJJJ"
            [selectPastDates]="false"
            [selectToday]="false"
        ></wuc-input-date-picker>
        <wuc-form-error *ngIf="endDate.touched && endDate.hasError('required')">
            Hoppla, da fehlt noch das Beginndatum.
        </wuc-form-error>
    </wuc-form-field>
    <wuc-form-field name="error" *ngIf="errors && errors.length">
        <wuc-form-error>
            <p *ngFor="let error of errors">{{ error.message }}</p>
        </wuc-form-error>
    </wuc-form-field>
    <wuc-form-field>
        <wuc-form-field-label>&nbsp;</wuc-form-field-label>
        <wuc-button [disabled]="isLoading" type="primary" [isFullWidth]="true" (click)="onSubmit()">Ändern</wuc-button>
    </wuc-form-field>
</form>
