<ins-modal-close></ins-modal-close>

<ins-modal-title>Ihre Versicherung ist gekündigt</ins-modal-title>
<ins-modal-content>
    <h3>Bekommen Sie noch Geld von uns?</h3>
    <p>
        Keine Sorge. Rund um den Monatsersten rechnen wir ab, und vorausgezahlte Beiträge werden automatisch
        zurückerstattet (oder verrechnet).
    </p>

    <h3>Kündigungsbestätigung</h3>
    <p>
        Sie haben Ihre Versicherung gekündigt. Der letzte Tag, an dem Sie versichert sind, ist der
        {{ data.endDate | dateFormat }}.
    </p>
</ins-modal-content>
<ins-modal-actions alignment="between">
    <button class="btn btn--primary" (click)="dismiss()">Ok</button>
</ins-modal-actions>
