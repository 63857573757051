import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { OptOutDataComponent } from './opt-out-data';
import { OptOutFormComponent, OptOutFormContainer } from './opt-out-form';
import { AlertModule } from '@app-de/components/alert/alert.module';
import { DetailsSubmitButtonModule } from '@app-de/my-zone/shared/details-submit-button';
import { DetailsTableModule } from '@app-de/my-zone/shared/details-table';
import { CynoFormModule } from '@app-de/shared/cyno-form/cyno-form.module';

@NgModule({
    declarations: [OptOutDataComponent, OptOutFormComponent, OptOutFormContainer],
    exports: [OptOutDataComponent, OptOutFormContainer],
    imports: [CommonModule, CynoFormModule, DetailsTableModule, DetailsSubmitButtonModule, AlertModule],
})
export class OptOutModule {}
