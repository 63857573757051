import { Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { NewModal as ModalService, NotificationInterface, ProductBaseInterface, ProductIdEnum } from 'outshared-lib';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { PremiumDeterminingFactorsService } from '../premium-determining-factors.service';
import { PremiumDeterminingFactorComponent } from './premium-determining-factor.component';

@Component({
    selector: 'ins-premium-determining-factor-container',
    templateUrl: './premium-determining-factor.container.html',
})
export class PremiumDeterminingFactorContainer implements OnDestroy {
    @Input() public premiumDeterminingFactor: any /* PurchasedProductPremiumDeterminingFactorInterface */;
    @Input() public product: ProductBaseInterface;

    @ViewChild(PremiumDeterminingFactorComponent)
    public premiumDeterminingFactorComponent: PremiumDeterminingFactorComponent;

    private subscriptions: Subscription = new Subscription();
    private errorsSubject$: BehaviorSubject<NotificationInterface[]> = new BehaviorSubject([]);
    private optionsSubject$: BehaviorSubject<any[] /* ElementInhoudInterface[] */> = new BehaviorSubject([]);

    /* tslint:disable */
    public errors$: Observable<NotificationInterface[]> = this.errorsSubject$.asObservable();
    public options$: Observable<any[] /* ElementInhoudInterface[] */> = this.optionsSubject$.pipe(
        filter((options) => options.length > 0),
    );
    /* tslint:enable */

    constructor(
        private premiumDeterminingFactorsService: PremiumDeterminingFactorsService,
        // private store: Store<fromMyZone.State>,
        private modalService: ModalService,
    ) {}

    public ngOnDestroy(): void {
        this.clearErrors();
        this.subscriptions.unsubscribe();
    }

    public panelOpened(elementName: string): void {
        const getOptions$ = this.premiumDeterminingFactorsService
            .getOptions$(elementName)
            .subscribe((options) => this.optionsSubject$.next(options));

        this.subscriptions.add(getOptions$);
    }

    public updatePremiumDeterminingFactor($event: any /* UpdateModifiablePremiumFactorPropsInterface */): void {
        // const updateModifiablePremiumDeterminingFactor$ = this.premiumDeterminingFactorService
        //     .updateModifiablePremiumDeterminingFactor(this.purchased_product_sequence_number, $event)
        //     .pipe(
        //         catchError(errors => of(this.errorsSubject$.next(errors))),
        //         tap(() => this.closePanel()),
        //         tap(() => this.resetForm()),
        //         tap(() => this.updatePremiumDeterminingFactorSuccess($event)),
        //         tap(() => this.displayPremiumImpact($event)),
        //     ).subscribe();
        // this.clearErrors();
        // this.subscriptions
        //     .add(updateModifiablePremiumDeterminingFactor$);
    }

    // TODO: Add CancelFutureModifiablePremiumDeterminingFactorPropsInterface interface
    public cancelPremiumDeterminingFactorFuture($event: any): void {
        // this.store.dispatch(new productActions.CancelFutureModifiablePremiumDeterminingFactor($event));
    }

    public showModal($event: string): void {
        if ($event === 'SVJR') {
            switch (this.product.product_id) {
                case ProductIdEnum.Car:
                    // this.modalService.open(SvjrModal);
                    break;
                case ProductIdEnum.Motorcycle:
                    // this.modalService.open(SvjrMotorModal);
                    break;
                case ProductIdEnum.Moped:
                    // this.modalService.open(SvjrBromModal);
                    break;
                default:
                // this.modalService.open(SvjrModal);
            }
        } else if ($event === 'BMTR') {
            // const modalRef: ModalRef<BmtrModal> = this.modalService.open(BmtrModal);
            // modalRef.componentInstance.product_id = this.product_id;
        }
    }

    private clearErrors(): void {
        this.errorsSubject$.next([]);
    }

    private displayPremiumImpact(props: any /* UpdateModifiablePremiumFactorPropsInterface */): void {
        const { start_date } = props;

        // this.store.dispatch(new productActions.DisplayPremiumImpact({
        //     reference_date: start_date,
        //     purchased_product_sequence_number: this.purchased_product_sequence_number
        // }));
    }

    private updatePremiumDeterminingFactorSuccess(props: any /* UpdateModifiablePremiumFactorPropsInterface */): void {
        const { premium_determining_factor_id, premium_determining_factor_value_description, start_date } = props;

        // this.store.dispatch(new productActions.UpdatePremiumDeterminingFactorSuccess({
        //     purchased_product_sequence_number: this.purchased_product_sequence_number,
        //     premium_determining_factor_id,
        //     premium_determining_factor_value_description,
        //     start_date
        // }));
    }

    private resetForm(): void {
        this.premiumDeterminingFactorComponent.resetForm();
    }

    private closePanel(): void {
        this.premiumDeterminingFactorComponent.panel.close();
    }
}
