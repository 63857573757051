import {
    Component,
    Optional,
    Self,
    ChangeDetectionStrategy,
    ViewChild,
    ElementRef,
    Input,
    DoCheck,
} from '@angular/core';
import { NgControl, NgForm, FormGroupDirective, ControlValueAccessor } from '@angular/forms';
import { Subject } from 'rxjs';
import { CynoFieldControl } from '../core/cyno-field-control';
import { ErrorStateMatcher } from '../core/error-options';
import { mixinErrorState, CanUpdateErrorState } from '../core/error-state';

class CynoIbanBase {
    constructor(
        public _defaultErrorStateMatcher: ErrorStateMatcher,
        public _parentForm: NgForm,
        public _parentFormGroup: FormGroupDirective,
        public ngControl: NgControl,
    ) {}
}

const cynoIbanMixinBase = mixinErrorState(CynoIbanBase);

class CynoIban {
    constructor() {}
}

@Component({
    selector: 'cyno-iban',
    template: `<input
        #iban
        class="form-control"
        type="text"
        placeholder="DE91100000000123456789"
        [id]="id"
        [disabled]="disabled"
        [required]="required"
        (focus)="onFocus()"
        (blur)="onBlur()"
        autocomplete="off"
    />`,
    styles: ['.form-control { text-transform: uppercase; }'],
    providers: [{ provide: CynoFieldControl, useExisting: CynoIbanInputComponent }],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CynoIbanInputComponent
    extends cynoIbanMixinBase
    implements ControlValueAccessor, CynoFieldControl<CynoIban>, CanUpdateErrorState, DoCheck
{
    @Input()
    public get id(): string {
        return this._id ? this._id : `cyno-iban-${CynoIbanInputComponent.nextId++}`;
    }

    public set id(id) {
        this._id = id;
        this.stateChanges.next();
    }

    @Input()
    public get placeholder(): string {
        return this._placeholder;
    }

    public set placeholder(plh: string) {
        this._placeholder = plh;
        this.stateChanges.next();
    }

    @Input()
    public get required(): boolean {
        return this._required;
    }

    public set required(req) {
        this._required = req;
        this.stateChanges.next();
    }

    @Input()
    public get disabled(): boolean {
        return this._disabled;
    }

    public set disabled(value: boolean) {
        this._disabled = value;
        this.stateChanges.next();
    }

    constructor(
        public _defaultErrorStateMatcher: ErrorStateMatcher,
        @Optional() parentForm: NgForm,
        @Optional() parentFormGroup: FormGroupDirective,
        @Optional() @Self() public ngControl: NgControl,
    ) {
        super(_defaultErrorStateMatcher, parentForm, parentFormGroup, ngControl);

        if (this.ngControl) {
            this.ngControl.valueAccessor = this;
        }
    }

    public static nextId = 0;
    @ViewChild('iban', { static: true }) public iban: ElementRef;

    public value: string;
    public focused: boolean = false;
    private _id: string;
    private _placeholder: string;
    private _required = false;
    protected _disabled: boolean = false;

    public stateChanges: Subject<void> = new Subject();

    private _onChange: (value: any) => void = () => {};
    private _onTouched = (): any => {};

    public ngDoCheck(): void {
        if (this.ngControl) {
            this.updateErrorState();
        }
    }

    public writeValue(value: string): void {
        if (value) {
            this.iban.nativeElement.value = this._transform(value);
        }
    }

    public registerOnChange(fn: (value: any) => void): void {
        this._onChange = fn;
    }

    public registerOnTouched(fn: () => {}): void {
        this._onTouched = fn;
    }

    public onContainerClick(): void {}

    public onFocus(): void {
        if (this.iban.nativeElement.value) {
            this._onTouched();
        }
    }

    public onBlur(): void {
        const value = this._transform(this.iban.nativeElement.value);

        this.iban.nativeElement.value = value;

        this._onChange(value);
        this._onTouched();
    }

    private _transform(value: string): string {
        value = value.toUpperCase();
        value = value.replace(/\W/g, '');

        const arrValue = value.split('');

        return arrValue.reduce((previousValue: string, currentValue: string) => {
            return `${previousValue}${currentValue}`;
        }, '');
    }
}
