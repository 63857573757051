import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ElementValidator, ErrorInterface, RelationInterface } from 'outshared-lib';
import { Subscription } from 'rxjs';

@Component({
    selector: 'ins-email-address-form',
    templateUrl: './email-address-form.component.html',
})
export class EmailAddressFormComponent {
    @Input() public errors: ErrorInterface[];

    @Output() public readonly submitted = new EventEmitter<{
        password: string;
        email: string;
    }>();

    @Input('relation') public set setRelation(relation: RelationInterface) {
        if (!this.form) {
            this.createForm();
        }

        if (relation && this.form) {
            this.resetForm(relation);
        }
    }

    public form: UntypedFormGroup;
    public disabled: boolean = false;
    private subscriptions = new Subscription();

    constructor(private elementValidator: ElementValidator) {}

    public get emailAddress(): AbstractControl {
        return this.form.get('emailAddress');
    }

    public onFormSubmit(): void {
        this.disabled = true;

        this.submitted.emit({
            password: this.form.value.password,
            email: this.emailAddress.value,
        });
    }

    private createForm(): void {
        if (!this.form) {
            this.form = new UntypedFormGroup({
                password: new UntypedFormControl('', {
                    validators: [Validators.required],
                }),
                emailAddress: new UntypedFormControl('', {
                    validators: [Validators.required, Validators.email],
                    updateOn: 'blur',
                }),
            });
        }
        const valueChanges = this.form.valueChanges.subscribe(() => (this.disabled = false));

        this.subscriptions.add(valueChanges);
    }

    private resetForm(relation: RelationInterface): void {
        this.disabled = false;
        this.emailAddress.clearAsyncValidators();

        this.form.reset({
            emailAddress: relation.primary_email_address.email_address,
        });

        setTimeout(() => {
            this.emailAddress.setAsyncValidators(
                this.elementValidator.validate(
                    'email_address',
                    'VERZ.PROD.VSLG.NREL.PROD.AUTO',
                    'primary_email_address',
                ),
            );
        }, 0);
    }
}
