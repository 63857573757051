import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
    WucButtonModule,
    WucFormErrorModule,
    WucFormFieldLabelModule,
    WucFormFieldModule,
    WucInputDateModule,
    WucInputDatePickerModule,
    WucInputInitialsModule,
    WucInputRadioModule,
    WucInputTextModule,
} from '@inshared/website-ui-components';
import { InsuranceDeviantDriverFormComponent } from './insurance-deviant-driver-form.component';
import { AlertModule } from '@app-de/components/alert/alert.module';
import { CynoFormModule } from '@app-de/shared/cyno-form/cyno-form.module';
import { DatepickerModule } from '@app-de/shared/datepicker/datepicker.module';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        CynoFormModule,
        DatepickerModule,
        AlertModule,
        WucFormFieldModule,
        WucFormFieldLabelModule,
        WucInputTextModule,
        WucInputRadioModule,
        WucInputInitialsModule,
        WucFormErrorModule,
        WucInputDateModule,
        WucInputDatePickerModule,
        WucButtonModule,
    ],
    exports: [InsuranceDeviantDriverFormComponent],
    declarations: [InsuranceDeviantDriverFormComponent],
})
export class InsuranceDeviantDriverFormModule {}
