<ins-modal-close></ins-modal-close>
<ins-modal-title>
    <span *ngIf="data.coverage_title === 'FSVS'">Fahrerschutz&shy;versicherung</span>
    <span *ngIf="data.coverage_title === 'PHEU'">Pannenhilfe Europa</span>
</ins-modal-title>
<ins-modal-content>
    <ng-container *ngIf="['FSVS', 'PHEU'].includes(data.coverage_id)">
        <ins-car-additional-coverage-info [coverageId]="data.coverage_id"></ins-car-additional-coverage-info>
    </ng-container>
</ins-modal-content>
<ins-modal-actions alignment="end">
    <a href="#" title="Schließen" class="modal__close-bottom link-secondary" (click)="close()">
        <span>Schließen</span>
        <svg class="svg-icon svg-icon--xs"><use href="assets/icons-ui.svg#cross-closed"></use></svg>
    </a>
</ins-modal-actions>
