export enum SmartTipsEnum {
    // Categories
    AutoFakten = 'auto-fakten',
    Autofahren = 'autofahren',
    Elektroauto = 'elektroauto',
    Autopflege = 'autopflege',
    Pannenvermeidung = 'pannenvermeidung',
    Jahreszeiten = 'jahreszeiten',
    Sparen = 'sparen',
    Ubersicht = 'ubersicht',

    // Tips
    AutoFitFurDenWinter = 'auto-winterfest-machen',
    AutoSicherheitHerbstWinter = 'auto-sicherheit-herbst-winter',
    AutoSommerfitMachen = 'sommer-urlaubscheck-auto',
    AutoVerleihenIstVersichert = 'versicherung-auto-verleihen',
    AutofahrenBeiGefahrlichemWetter = 'witterungsverhaeltnisse-auto',
    AutofahrenUndGeldsparen = 'autofahren-und-geldsparen',
    DeutscheLiebenIhrAuto = 'deutsche-lieben-ihr-auto',
    EinfadelnAuffahrtAutobahn = 'auffahrt-autobahn-einfaedeln',
    EnergieEffizienzLabelAuto = 'auto-effizienzklasse-energie-label',
    FaktenKlimaanlageAuto = '11-fakten-klimaanlage-auto',
    FallenMietautoImUrlaub = 'auto-mieten-ausland',
    FehlerEiskratzenAutoscheiben = 'autoscheiben-enteisen',
    FehlerVermeidenAutoWaschanlage = 'tipps-autowaschanlage',
    FragenAntwortenHauptuntersuchungAuto = 'fragen-antworten-hauptuntersuchung-auto',
    FragenZurGrossenInspektion = 'fragen-zur-grossen-inspektion',
    FehlendeAnhangerkupplungElektroauto = 'e-auto-anhaengerkupplung',
    HaufigeFehlerBeimTanken = 'wie-tankt-man-richtig',
    HoheStrafenImStrassenverkehr = 'hohe-strafen-im-strassenverkehr',
    KlimaanlageBeiHeuschnupfenEinschalten = 'klimaanlage-bei-heuschnupfen-einschalten',
    KontrolleWichtigsteFlussigkeitenAuto = 'kontrolle-wichtigste-flussigkeiten-auto',
    LegendenAutoBatterie = 'autobatterie-lebensdauer-verlaengern',
    MitSicherheitAutopannenVermeiden = 'mit-sicherheit-autopannen-vermeiden',
    MythenElektrischesAutoFahren = 'mythen-elektroauto',
    NutzlichesWissenAutoschlussel = 'nutzliches-wissen-autoschluessel',
    PraktischeChecklisteAutokauf = 'praktische-checkliste-autokauf',
    ReichweiteElektroautoErhohen = 'e-auto-reichweite-erhoehen',
    RichtigesVerhaltenBeiStau = 'richtiges-verhalten-bei-stau',
    SicherUnterwegsBeiEisGlatte = 'autofahren-bei-glaette-eis',
    SichereAutoreisenMitKindern = 'lange-autofahrt-mit-kindern',
    SicheresAutofahrenImSturm = 'sicheres-autofahren-bei-sturm',
    StausMitAutoMeiden = 'staus-mit-auto-umfahren',
    TippsAutoReisenAusland = 'tipps-mit-dem-auto-ins-ausland',
    TippsAutoVorbereitenHu = 'tipps-auto-vorbereiten-hu',
    TippsAutounfalleVermeiden = 'tipps-autounfaelle-vermeiden',
    TippsPreiswertTanken = '6-tipps-guenstig-tanken',
    TippsSpritsparen = '13-tipps-kraftstoff-sparen',
    TippsVerhaltenBeiAutopanne = 'tipps-verhalten-bei-autopanne',
    VorteileZeitFurWinterreifen = '6-vorteile-zeit-fur-winterreifen',
    WallboxLadesauleElektroAuto = 'welche-wallbox-ladesaeule',
    WannReifenErsetzenCheckliste = 'wann-neue-reifen-checkliste',
    WichtigeFaktenSicherheitsgurt = 'wichtige-fakten-sicherheitsgurt',
    WissenswertesAutobahnDeutschland = 'autobahnen-deutschland',
    WissenswertesTankenAusland = 'wissenswertes-tanken-im-ausland',
    ParkenOhneStress = 'auto-einparken-ohne-stress',
    ArgerlicheSituationenImAuto = 'aergerliche-situationen-im-auto',
    WichtigeDingeFursAuto = '6-wichtige-dinge-fuers-auto',
    AutofahrenAblenkungenVermeiden = 'auto-apps-ablenkungen-vermeiden',
    WenigerStressBeimAutofahren = 'weniger-stress-beim-autofahren',
    WissenAutoHalterwechselVersicherungswechsel = 'wechsel-fahrzeughalter',
}
