import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { InsuranceInfoCheckEffects, insuranceInfoCheckReducer } from './store';
import { INSURANCE_INFO_CHECK_FEATURE } from './store/insurance-info-check.state';

@NgModule({
    imports: [
        StoreModule.forFeature(INSURANCE_INFO_CHECK_FEATURE, insuranceInfoCheckReducer),
        EffectsModule.forFeature([InsuranceInfoCheckEffects]),
    ],
})
export class OutInsuranceInfoCheckModule {}
