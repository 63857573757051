import { Component } from '@angular/core';
import { NewModalRef } from 'outshared-lib';
import { AppRoutesEnum, ModalRoutesEnum } from '@app-de/routes';

@Component({
    selector: 'ins-decease-modal',
    templateUrl: './decease.modal.html',
})
export class DeceaseModal {
    public appRoutesEnum = AppRoutesEnum;
    public modalRoutesEnum = ModalRoutesEnum;

    constructor(private activeModal: NewModalRef<DeceaseModal>) {}

    public close(): void {
        this.activeModal.close();
    }
}
