import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DatepickerModule } from '../datepicker/datepicker.module';
import { ErrorStateMatcher } from './core/error-options';
import { CynoAlertComponent } from './cyno-alert/cyno-alert.component';
import { CynoBirthdateInputComponent } from './cyno-birthdate/cyno-birthdate.component';
import { CynoCheckboxInputComponent } from './cyno-checkbox/cyno-checkbox.component';
import { CynoDateInputComponent } from './cyno-date/cyno-date.component';
import { CynoFormatDirective } from './cyno-directives/cyno-format.directive';
import { CynoInfoDirective } from './cyno-directives/cyno-info.directive';
import { CynoInputDirective } from './cyno-directives/cyno-input.directive';
import { CynoLabelDirective } from './cyno-directives/cyno-label.directive';
import { CynoOptionDirective } from './cyno-directives/cyno-option.directive';
import { CynoSelectDirective } from './cyno-directives/cyno-select.directive';
import { CynoErrorComponent } from './cyno-error/cyno-error.component';
import { CynoFieldComponent } from './cyno-field/cyno-field.component';
import { CynoIbanInputComponent } from './cyno-iban/cyno-iban.component';
import { CynoInitialsInputComponent } from './cyno-initials/cyno-initials.component';
import { CynoPasswordModule } from './cyno-password/cyno-password.module';
import { CynoPhoneInputComponent } from './cyno-phone/phone.component';
import { CynoPostcodeInputComponent } from './cyno-postcode/cyno-postcode.component';
import { CynoLabelNewDirective } from './cyno-radio/cyno-label-new.directive';
import { CynoRadioNewDirective, CynoRadioNewGroupDirective } from './cyno-radio/cyno-radio-new.directive';
import { CynoRadioInputComponent } from './cyno-radio/cyno-radio.component';
import { CynoSelectModule } from './cyno-select/cyno-select.module';
import { CynoStartDateInputComponent } from './cyno-startdate/cyno-startdate.component';
import { CynoTimeInputComponent } from './cyno-time/cyno-time.component';
import { ClickOutsideModule } from '@app-de/shared/directives/click-outside/click-outside.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        CynoPasswordModule,
        CynoSelectModule,
        DatepickerModule,
        ClickOutsideModule,
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        CynoSelectDirective,
        CynoOptionDirective,
        CynoInputDirective,
        CynoLabelDirective,
        CynoInfoDirective,
        CynoFormatDirective,
        CynoErrorComponent,
        CynoFieldComponent,
        CynoPasswordModule,
        CynoDateInputComponent,
        CynoInitialsInputComponent,
        CynoPhoneInputComponent,
        CynoIbanInputComponent,
        CynoRadioInputComponent,
        CynoCheckboxInputComponent,
        CynoTimeInputComponent,
        CynoAlertComponent,
        CynoBirthdateInputComponent,
        CynoPostcodeInputComponent,
        CynoStartDateInputComponent,
        CynoRadioNewDirective,
        CynoLabelNewDirective,
        CynoRadioNewGroupDirective,
        CynoSelectModule,
    ],
    declarations: [
        CynoFieldComponent,
        CynoSelectDirective,
        CynoOptionDirective,
        CynoInputDirective,
        CynoLabelDirective,
        CynoInfoDirective,
        CynoErrorComponent,
        CynoFormatDirective,
        CynoDateInputComponent,
        CynoInitialsInputComponent,
        CynoPhoneInputComponent,
        CynoIbanInputComponent,
        CynoRadioInputComponent,
        CynoCheckboxInputComponent,
        CynoTimeInputComponent,
        CynoAlertComponent,
        CynoBirthdateInputComponent,
        CynoPostcodeInputComponent,
        CynoStartDateInputComponent,
        CynoRadioNewDirective,
        CynoLabelNewDirective,
        CynoRadioNewGroupDirective,
    ],
    providers: [ErrorStateMatcher],
})
export class CynoFormModule {}
