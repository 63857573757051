import { ChangeDetectorRef, Component } from '@angular/core';
import {
    ErrorInterface,
    NewModal,
    ProductActionService,
    ProductBaseInterface,
    ProductSelectorService,
    TerminateActivePurchasedProductRequestInterface,
} from 'outshared-lib';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { TerminateInsuranceFeedbackModal } from '@app-de/my-zone/shared/terminate-insurance-feedback/terminate-insurance-feedback.modal';

@Component({
    selector: 'ins-terminate-insurance-container',
    template: `
        <ins-terminate-insurance
            (terminated)="onTerminate($event)"
            (destroyed)="onDestroy()"
            [product]="product$ | async"
            [errorMessages]="errorMessages"
            [loading]="loading"
        ></ins-terminate-insurance>
    `,
})
export class TerminateInsuranceContainer {
    public product$: Observable<ProductBaseInterface> = this.productSelectorService.getSelectedProduct$;
    public errorMessages: string[] = [];
    public loading: boolean;
    private subscriptions: Subscription = new Subscription();

    constructor(
        private productSelectorService: ProductSelectorService,
        private productActionService: ProductActionService,
        private newModal: NewModal,
        private changeDetectorRef: ChangeDetectorRef,
    ) {}

    public onDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    public onTerminate(request: TerminateActivePurchasedProductRequestInterface): void {
        const terminatePurchasedProduct = combineLatest([
            this.product$,
            this.productActionService.terminateActivePurchasedProduct(request),
        ]).subscribe({
            next: ([product]) => this.onSuccess(request, product),
            error: (errors) => this.onError(errors),
        });

        this.startRequest();
        this.subscriptions.add(terminatePurchasedProduct);
    }

    private startRequest(): void {
        this.loading = true;
        this.errorMessages = [];
        this.changeDetectorRef.detectChanges();
    }

    private onSuccess(request: TerminateActivePurchasedProductRequestInterface, product: ProductBaseInterface): void {
        this.loading = false;
        this.newModal.open(TerminateInsuranceFeedbackModal, {
            data: {
                ...request,
                product,
            },
        });
        this.changeDetectorRef.detectChanges();
    }

    private onError(errors: ErrorInterface[]): void {
        this.loading = false;
        this.errorMessages = this.mapErrorsToString(errors);
        this.changeDetectorRef.detectChanges();
    }

    private mapErrorsToString(errors: ErrorInterface[]): string[] {
        return errors.map((error) => error.message);
    }
}
