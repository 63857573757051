export const environment = {
    cwv: true,
    production: true,
    isServer: false,
    // for prerender
    host: 'https://www.inshared.de',
    cynolyticsUrl: 'https://isde-pixel-cynolytics.outshared.services',
    blueconicUrl: '//isdebc.inshared.de/script.js',
    gtmId: 'GTM-5LM5333',
    loqateKey: 'MM96-RB34-ND49-YD22',
    cynolyticsLanguageId: 'de-DE',
    cynolyticsPartnerId: '00001',
};
