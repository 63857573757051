import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NewModalModule } from 'outshared-lib';
import { LogoutModal } from './logout.modal';
import { ModalComponentsModule } from '@app-de/components';
import { ListModule } from '@app-de/shared/lists/list.module';

@NgModule({
    imports: [ListModule, CommonModule, NewModalModule, ModalComponentsModule],
    exports: [LogoutModal],
    declarations: [LogoutModal],
})
export class LogoutModalModule { }
