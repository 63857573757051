import { Component, EventEmitter, Output } from '@angular/core';
import { IndicationEnum } from 'outshared-lib';

@Component({
    selector: 'ins-opt-in-notification',
    templateUrl: './opt-in-notification.component.html',
})
export class OptInNotificationComponent {
    public indicationEnum: typeof IndicationEnum = IndicationEnum;

    @Output()
    public optIn: EventEmitter<IndicationEnum> = new EventEmitter<IndicationEnum>();

    @Output()
    public cancel: EventEmitter<void> = new EventEmitter<void>();

    public changeOptOut(value: IndicationEnum): void {
        this.optIn.emit(value);
    }

    public onCancel(): void {
        this.cancel.emit();
    }
}
