import { DOCUMENT, isPlatformServer } from '@angular/common';
import { Inject, Injectable, OnDestroy, PLATFORM_ID } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Data, NavigationEnd, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { RouteHelperService } from '@app-de/core/services/route-helper.service';
import { WINDOW } from '@inshared/shared/util';

@Injectable({
    providedIn: 'root',
})
export class SeoService implements OnDestroy {
    public readonly routeData$: Observable<Data> = this.router.events.pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route: ActivatedRoute) => {
            while (route.firstChild) {
                route = route.firstChild;
            }
            return route;
        }),
        filter((route: ActivatedRoute) => route.outlet === 'primary'),
        mergeMap((route: ActivatedRoute) => route.data),
    );

    private previousData: Data;

    private subscriptions: Subscription = new Subscription();

    public constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private title: Title,
        private meta: Meta,
        private routeHelperService: RouteHelperService,
        @Inject(DOCUMENT) private document: Document,
        @Inject(WINDOW) private window: Window,
        @Inject(PLATFORM_ID) private platformId: object,
    ) {}

    public init(): void {
        if (isPlatformServer(this.platformId)) {
            return;
        }
        this.subscriptions.add(
            this.routeData$.subscribe((data: Data) => {
                this.previousData = data;
                if (data.hasOwnProperty('title')) {
                    this.setTitle(data.title);
                }
                if (data.hasOwnProperty('description')) {
                    this.updateTag('description', data.description);
                }
                if (data.hasOwnProperty('robots')) {
                    this.updateTag('robots', data.robots);
                }

                if (this.isSubDomain(this.window.location.host)) {
                    this.updateTag('robots', 'noindex');
                }

                if (!data.doNotSetCanonical) {
                    this.updateCanonicalLink();
                }
            }),
        );
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
        this.removeCanonicalLink();
    }

    public setTitle(title: string): void {
        this.title.setTitle(title);
    }

    public updateTag(name: string, content: string): void {
        this.meta.updateTag({ name, content });
    }

    public getPreviousData(): Data {
        return this.previousData;
    }

    private isSubDomain(url): boolean {
        // SubDomain test is allowed for testing reasons; Test cannot be indexed because its running in a non public env
        const whitelist: string[] = ['www', 'test', 'isde-frontend-website', 'acceptatie'];
        const urlArray = url.split('.');
        const subdomain = urlArray[0];

        if (urlArray.length < 3) {
            return false;
        }

        return whitelist.findIndex((item) => item === subdomain) < 0;
    }

    private addCanonicalLink(): HTMLLinkElement {
        const linkElement: HTMLLinkElement = this.document.createElement('link');
        linkElement.setAttribute('rel', 'canonical');
        this.document.head.appendChild(linkElement);
        return linkElement;
    }

    private updateCanonicalLink(): void {
        const linkElement: HTMLLinkElement =
            this.document.querySelector('link[rel=canonical]') || this.addCanonicalLink();
        const href =
            this.window.location.protocol + '//' + this.window.location.host + this.routeHelperService.getCleanUrl();
        linkElement.setAttribute('href', href || this.document.URL);
    }

    private removeCanonicalLink(): void {
        const linkElement: HTMLLinkElement = this.document.querySelector('link[rel=canonical]');
        if (linkElement) {
            linkElement.remove();
        }
    }
}
