import { Component, Input } from '@angular/core';

@Component({
    selector: 'ins-details-submit-button',
    templateUrl: './details-submit-button.component.html',
    styleUrls: ['./details-submit-button.component.scss'],
})
export class DetailsSubmitButtonComponent {
    @Input() public disabled: boolean = false;
}
