import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WucPaginationComponent } from './pagination.component';

@NgModule({
    declarations: [WucPaginationComponent],
    exports: [WucPaginationComponent],
    imports: [CommonModule],
})
export class WucPaginationModule {}
