import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { NotificationInterface } from '../../../core';
import { ScenarioInterface } from '../../interfaces';
import * as fromMyZone from '../../my-zone';
import * as scenariosActions from './scenarios.actions';
import { ScenariosService } from './scenarios.service';

/* @deprecated - Use new ScenariosActionService */
@Injectable({
    providedIn: 'root',
})
export class ScenariosActionService {
    constructor(private scenariosService: ScenariosService, private store$: Store<fromMyZone.State>) {}

    public load(): Observable<{ result: boolean } | NotificationInterface[]> {
        this.store$.dispatch(new scenariosActions.Load());

        return this.scenariosService.loadScenarios().pipe(
            tap((scenarios: ScenarioInterface[]) => {
                this.store$.dispatch(new scenariosActions.LoadSuccess({ scenarios }));
            }),
            map(() => ({ result: true })),
            catchError((notifications) => {
                this.store$.dispatch(new scenariosActions.LoadError());
                return throwError(notifications);
            })
        );
    }

    public clear(): void {
        this.store$.dispatch(new scenariosActions.Clear());
    }
}
