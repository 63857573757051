import { Component } from '@angular/core';
import { NewModalRef } from 'outshared-lib';

@Component({
    selector: 'ins-cancel-terminate-insurance',
    templateUrl: 'cancel-terminate-insurance.modal.html',
})
export class CancelTerminateInsuranceModal {
    constructor(
        public modalRef: NewModalRef<CancelTerminateInsuranceModal>
    ) {
    }

    public submit(): void {
        this.modalRef.close(true);
    }

    public close(): void {
        this.modalRef.close(false);
    }
}
