import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { WucChatbotMiniModule } from '@inshared/website-ui-components';
import { NewModalModule } from 'outshared-lib';
import { ChatbotEntryComponent } from './chatbot-entry.component';
import { ChatbotEntryContainer } from './chatbot-entry.container';
import { CharCounterModule } from '@app-de/shared/char-counter/char-counter.module';

@NgModule({
    imports: [CommonModule, NewModalModule, ReactiveFormsModule, CharCounterModule, WucChatbotMiniModule],
    declarations: [ChatbotEntryContainer, ChatbotEntryComponent],
    exports: [ChatbotEntryContainer],
})
export class ChatbotEntryModule {}
