import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ErrorInterface, ProductBaseInterface } from 'outshared-lib';
import { removeRegularDriverRequestInterface } from '../../interfaces';

@Component({
    selector: 'ins-insurance-policy-holder-form',
    templateUrl: './insurance-policy-holder-form.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InsurancePolicyHolderFormComponent {
    @Input() public errors: ErrorInterface[];
    @Input() public product: ProductBaseInterface;
    @Input() public isLoading: boolean;

    @Output() public readonly removeRegularDriver: EventEmitter<removeRegularDriverRequestInterface> =
        new EventEmitter();

    public form: UntypedFormGroup;

    constructor() {
        this.form = this.createForm();
    }

    public onSubmit(): void {
        if (!this.form.valid) {
            this.form.markAllAsTouched();
            return;
        }

        this.removeRegularDriver.emit({
            end_date: this.endDate.value,
            purchased_product_sequence_number: this.product.purchased_product_sequence_number,
        });
    }

    public get endDate(): AbstractControl {
        return this.form.get('endDate');
    }

    private createForm(): UntypedFormGroup {
        return new UntypedFormGroup({
            endDate: new UntypedFormControl('', [Validators.required]),
        });
    }
}
