import { Routes } from '@angular/router';
import { AutoversicherungRoutesEnum } from './../../autoversicherung/autoversicherung-routes.enum';

export const autoSicherheitRatgeberRoutes: Routes = [
    {
        path: AutoversicherungRoutesEnum.AutoSicherheitRatgeber,
        loadChildren: () =>
            import('./auto-sicherheit-ratgeber/auto-sicherheit-ratgeber.module').then(
                (m) => m.AutoSicherheitRatgeberModule,
            ),
    },
    {
        path: `${AutoversicherungRoutesEnum.AutoSicherheitRatgeber}/${AutoversicherungRoutesEnum.Fahrerassistenzsysteme}/${AutoversicherungRoutesEnum.Notbremsassistent}`,
        loadChildren: () =>
            import('./notbremsassistent/notbremsassistent.module').then((m) => m.NotbremsassistentModule),
    },
    {
        path: `${AutoversicherungRoutesEnum.AutoSicherheitRatgeber}/${AutoversicherungRoutesEnum.Fahrerassistenzsysteme}/${AutoversicherungRoutesEnum.Fernlichtassistent}`,
        loadChildren: () =>
            import('./fernlichtassistent/fernlichtassistent.module').then((m) => m.FernlichtassistentModule),
    },
    {
        path: `${AutoversicherungRoutesEnum.AutoSicherheitRatgeber}/${AutoversicherungRoutesEnum.Fahrerassistenzsysteme}/${AutoversicherungRoutesEnum.AdaptiveGeschwindigkeitsregelanlage}`,
        loadChildren: () =>
            import('./adaptive-geschwindigkeitsregelanlage/adaptive-geschwindigkeitsregelanlage.module').then(
                (m) => m.AdaptiveGeschwindigkeitsregelanlageModule,
            ),
    },
    {
        path: `${AutoversicherungRoutesEnum.AutoSicherheitRatgeber}/${AutoversicherungRoutesEnum.Fahrerassistenzsysteme}`,
        loadChildren: () =>
            import('./fahrerassistenzsysteme/fahrerassistenzsysteme.module').then(
                (m) => m.FahrerassistenzsystemeModule,
            ),
    },
    {
        path: `${AutoversicherungRoutesEnum.AutoSicherheitRatgeber}/${AutoversicherungRoutesEnum.Fahrerassistenzsysteme}/${AutoversicherungRoutesEnum.Verkehrszeichenerkennung}`,
        loadChildren: () =>
            import('./verkehrszeichenerkennung/verkehrszeichenerkennung.module').then(
                (m) => m.VerkehrszeichenerkennungModule,
            ),
    },
    {
        path: `${AutoversicherungRoutesEnum.AutoSicherheitRatgeber}/${AutoversicherungRoutesEnum.Fahrerassistenzsysteme}/${AutoversicherungRoutesEnum.Spurwechselassistent}`,
        loadChildren: () =>
            import('./spurwechselassistent/spurwechselassistent.module').then(
                (m) => m.SpurwechselassistentModule,
            ),
    },
    {
        path: `${AutoversicherungRoutesEnum.AutoSicherheitRatgeber}/${AutoversicherungRoutesEnum.Fahrerassistenzsysteme}/${AutoversicherungRoutesEnum.AutonomesFahren}`,
        loadChildren: () =>
            import('./autonomes-fahren/autonomes-fahren.module').then(
                (m) => m.AutonomesFahrenModule,
            ),
    },    
    {
        path: `${AutoversicherungRoutesEnum.AutoSicherheitRatgeber}/${AutoversicherungRoutesEnum.Fahrerassistenzsysteme}/${AutoversicherungRoutesEnum.Spurhalteassistent}`,
        loadChildren: () =>
            import('./spurhalteassistent/spurhalteassistent.module').then(
                (m) => m.SpurhalteassistentModule,
            ),
    },
    {
        path: `${AutoversicherungRoutesEnum.AutoSicherheitRatgeber}/${AutoversicherungRoutesEnum.Fahrerassistenzsysteme}/${AutoversicherungRoutesEnum.Lichtautomatik}`,
        loadChildren: () =>
            import('./lichtautomatik/lichtautomatik.module').then(
                (m) => m.LichtautomatikModule,
            ),
    },       
];
