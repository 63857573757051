import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TestimonialsEffects } from './store/testimonials.effects';
import { testimonialsReducers } from './store/testimonials.reducers';
import { TESTIMONIALS_FEATURE } from './store/testimonials.state';

@NgModule({
    imports: [
        StoreModule.forFeature(TESTIMONIALS_FEATURE, testimonialsReducers),
        EffectsModule.forFeature([TestimonialsEffects]),
    ],
})
export class OutTestimonialsModule {}
