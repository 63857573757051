import { FavoritesOnlyIndicationEnum } from '../enums';
import { ApiTestimonialsRequestInterface, TestimonialsRequestInterface } from '../interfaces';

function mapQuestionSetIds(ids: string[]): Array<{ questionset_id: string }> {
    return ids.map((id: string) => {
        return { questionset_id: id };
    });
}

function indicationMapper(indication: boolean): FavoritesOnlyIndicationEnum {
    return indication ? FavoritesOnlyIndicationEnum.Yes : FavoritesOnlyIndicationEnum.No;
}

export function testimonialRequestMapper(request: TestimonialsRequestInterface): ApiTestimonialsRequestInterface {
    return {
        vragenset: mapQuestionSetIds(request.questionsetIds),
        filter: {
            favourites_only_indication: indicationMapper(request.filter.favouritesOnlyIndication) ?? undefined,
            age_insocial: request.filter.ageInsocial ?? undefined,
            province_code: request.filter.provinceCode ?? undefined,
            family_composition_code: request.filter.familyCompositionCode ?? undefined,
            row_number_from: request.filter.rowNumberFrom ?? undefined,
            row_number: request.filter.rowNumber ?? undefined,
        },
    };
}
