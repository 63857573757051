import { RouterAction, ROUTER_NAVIGATED, SerializedRouterStateSnapshot } from '@ngrx/router-store';
import * as routerActions from './router.actions';

export interface State {
    params: {};
    path: string;
}

export const initialState: State = {
    params: {},
    path: null,
};

export function reducer(
    state: State = initialState,
    action: routerActions.RouterActionsUnion | RouterAction<SerializedRouterStateSnapshot>,
): State {
    switch (action.type) {
        case ROUTER_NAVIGATED: {
            return {
                ...state,
                params: action.payload.routerState.root.queryParams,
                path: action.payload.event.url,
            };
        }

        default: {
            return state;
        }
    }
}

/* selectors */
export const getPath = (state: State): string => state.path;
export const getParams = (state: State): any => state.params;
