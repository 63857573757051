
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { TopbarComponent } from './topbar.component';
import { AuthenticationMenuModule } from '@app-de/authentication/authentication-menu/authentication-menu.module';

@NgModule({
    declarations: [TopbarComponent],
    imports: [
        CommonModule,
        RouterModule,
        AuthenticationMenuModule,
        LazyLoadImageModule,
    ],
    exports: [TopbarComponent],
})
export class TopbarModule {}
