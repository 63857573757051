import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NewModalModule } from 'outshared-lib';
import { CookieSettingsWallModal } from './cookie-settings-wall.modal';
import { ModalComponentsModule } from '@app-de/components';
import { CardModule } from '@app-de/shared/card/card.module';
import { CynoFormModule } from '@app-de/shared/cyno-form/cyno-form.module';
import { ListModule } from '@app-de/shared/lists/list.module';

@NgModule({
    declarations: [CookieSettingsWallModal],
    imports: [
        CommonModule,
        NewModalModule,
        CynoFormModule,
        CardModule,
        ListModule,
        RouterModule,
        ModalComponentsModule,
    ],
    exports: [CookieSettingsWallModal],
})
export class CookieSettingsWallModule {}
