import { Component, Input, OnChanges } from '@angular/core';
import { CoveragePremiumCoverageInterface, CoveragePremiumInterface } from 'outshared-lib';
import { AnnualPaymentService } from '@app-de/my-zone/services/annual-payment.service';

@Component({
    selector: 'ins-coverage-overview-future',
    templateUrl: 'coverage-overview-future.component.html',
    styleUrls: ['../coverage-overview.shared.scss'],
})
export class CoverageOverviewFutureComponent implements OnChanges {
    @Input() public modulesFuture: CoveragePremiumCoverageInterface[];
    @Input() public calculation: CoveragePremiumInterface;
    public isYearlyPayment = false;
    public currentPeriod = null;
    public totalFuture = 0;
    public totalFutureDiscount = 0;

    constructor(private annualPaymentService: AnnualPaymentService) {}

    public ngOnChanges(): void {
        // Reset values to prevent weird behaviour where it would keep adding up
        this.totalFuture = 0;
        this.totalFutureDiscount = 0;

        this.currentPeriod = this.annualPaymentService.getCurrentPeriod(this.calculation?.product);
        this.isYearlyPayment = this.calculation.product?.purchased_invoice_period_months_number === 12;

        this.modulesFuture.forEach((module) => {
            this.totalFuture += module.premium_including_discount?.premium_including_premium_tax;
            this.totalFutureDiscount += module.discount?.premium_including_premium_tax;
        });
    }
}
