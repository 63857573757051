import {
    AfterContentInit,
    Directive,
    DoCheck,
    ElementRef,
    Inject,
    Input,
    OnDestroy,
    Optional,
    Self,
} from '@angular/core';
import { FormGroupDirective, NgControl, NgForm, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs';
import { CynoFieldControl } from '../core/cyno-field-control';
import { ErrorStateMatcher } from '../core/error-options';
import { CanUpdateErrorState, mixinErrorState } from '../core/error-state';
import { getSupportedInputTypes } from '../core/features';

export class InputBase {
    constructor(
        public _defaultErrorStateMatcher: ErrorStateMatcher,
        public _parentForm: NgForm,
        public _parentFormGroup: FormGroupDirective,
        public ngControl: NgControl,
    ) {}
}

export const inputMixinBase = mixinErrorState(InputBase);

@Directive({
    selector: '[cynoInput]',
    exportAs: 'cynoInput',
    providers: [{ provide: CynoFieldControl, useExisting: CynoInputDirective }],
    host: {
        class: 'form-control',
        '[attr.id]': 'id',
        '[attr.placeholder]': 'placeholder',
        '[disabled]': 'disabled',
        '[required]': 'required',
        '[readonly]': 'readonly',
        '(blur)': '_focusChanged(false)',
        '(focus)': '_focusChanged(true)',
        '(input)': '_onInput()',
    },
})
export class CynoInputDirective
    extends inputMixinBase
    implements CynoFieldControl<any>, OnDestroy, DoCheck, CanUpdateErrorState, AfterContentInit
{
    protected _previousNativeValue: any;

    @Input() public get disabled(): boolean {
        if (this.ngControl && this.ngControl.disabled !== null) {
            return this.ngControl.disabled;
        }
        return this._disabled;
    }

    public set disabled(value: boolean) {
        this._disabled = value;

        if (this.focused) {
            this.focused = false;
            this.stateChanges.next();
        }
    }

    protected _disabled = false;

    @Input() public get required(): boolean {
        return this._required;
    }

    public set required(value: boolean) {
        this._required = value;
    }

    protected _required = false;

    @Input() public get type(): string {
        return this._type;
    }

    public set type(value: string) {
        this._type = value || 'text';
        // this._validateType();

        //   When using Angular inputs, developers are no longer able to set the properties on the native
        //   input element. To ensure that bindings for `type` work, we need to sync the setter
        //   with the native property. Textarea elements don't support the type property or attribute.
        if (!this._isTextarea() && getSupportedInputTypes().has(this._type)) {
            this._elementRef.nativeElement.type = this._type;
        }
    }

    protected _type = 'text';

    @Input() public get value(): string {
        return this._inputValueAccessor.value;
    }

    public set value(value: string) {
        if (value !== this.value) {
            this._inputValueAccessor.value = value;
            this._inputValueAccessor.touched = true;
            this.stateChanges.next();
        }
    }

    @Input() public get readonly(): boolean {
        return this._readonly;
    }

    public set readonly(value: boolean) {
        this._readonly = value;
    }

    @Input() public get placeholder(): string {
        return this._placeholder;
    }

    public set placeholder(value: string) {
        this._placeholder = value;
    }

    protected _dirtyCheckNativeValue(): void {
        const newValue = this.value;

        if (this._previousNativeValue !== newValue) {
            this._previousNativeValue = newValue;
            this.stateChanges.next();
        }
    }

    protected _isTextarea(): boolean {
        return this._elementRef.nativeElement.nodeName.toLowerCase() === 'textarea';
    }

    public focused: boolean = false;
    public readonly stateChanges: Subject<void> = new Subject<void>();

    @Input() public id: string | number = '';
    private _inputValueAccessor: { value: any; touched: boolean };

    private _readonly = false;

    private _placeholder: string;

    constructor(
        protected _elementRef: ElementRef,
        @Optional() @Self() public ngControl: NgControl,
        @Optional() parentForm: NgForm,
        @Optional() parentFormGroup: FormGroupDirective,
        @Optional() @Self() @Inject(NG_VALUE_ACCESSOR) inputValueAccessor: any,
        defaultErrorStateMatcher: ErrorStateMatcher,
    ) {
        super(defaultErrorStateMatcher, parentForm, parentFormGroup, ngControl);
        const element = this._elementRef.nativeElement;
        this._inputValueAccessor = inputValueAccessor || element;
        this._previousNativeValue = this.value;
        this.id = this.id;
    }

    public ngOnDestroy(): void {
        this.stateChanges.complete();
    }

    public ngDoCheck(): void {
        if (this.ngControl) {
            this.updateErrorState();
        }

        this._dirtyCheckNativeValue();
    }

    public ngAfterContentInit(): void {
        if (!this.id) {
            this.id = this.ngControl.name;
        }
    }

    public focus(): void {
        this._elementRef.nativeElement.focus();
    }

    public _focusChanged(isFocused: boolean): void {
        if (isFocused !== this.focused && !this.readonly) {
            this.focused = isFocused;
            this.stateChanges.next();
        }
    }

    public _onInput(): void {
        this.stateChanges.next();
        this._inputValueAccessor.touched = true;
    }

    public onContainerClick(): void {
        if (!this.focused) {
            this.focus();
        }
    }
}
