export const genericTexts = {
    female: $localize`:@@generic.text.female:Female`,
    freeOfCharge: $localize`:@@generic.text.free-of-charge:free`,
    inclusive: $localize`:@@generic.button-text.inclusive:Inclusive`,
    male: $localize`:@@generic.text.male:Male`,
    monthly: $localize`:@@generic.text.monthly:per month`,
    mostChosen: $localize`:@@generic.button-text.most-chosen:Most chosen`,
    no: $localize`:@@generic.text.no:No`,
    notQuiteYourType: $localize`:@@generic.text.not-quite-your-type:Is this not quite your type? Don't worry, we insure the version that matches your license plate.`,
    yes: $localize`:@@generic.text.yes:Yes`,
    your: $localize`:@@generic.text.your:Your`,
};
