<div class="click-tooltip" ins-clickOutside (clickedOutside)="close()">
    <span title="Informationen" (click)="toggle()" [ngClass]="customClass">
        <ng-container *ngIf="title">{{ title }}</ng-container>
        <ng-container *ngIf="image">
            <img src="{{ image }}" [alt]="title + 'Image'" />
        </ng-container>
        <ng-container *ngIf="!title && !image">
            <svg class="svg-icon svg-icon--xs">
                <use href="assets/icons-ui.svg#informatie"></use>
            </svg>
        </ng-container>
    </span>
    <div
        class="click-tooltip__collapse"
        [class.click-tooltip__collapse--bottom-left]="bottomLeft"
        [class.click-tooltip__collapse--bottom-right]="bottomRight"
        [class.show]="visible"
    >
        <span title="Schließen" (click)="close()" class="click-tooltip__close">
            <svg class="svg-icon svg-icon--xs">
                <use href="assets/icons-ui.svg#cross-closed"></use>
            </svg>
        </span>
        <ng-content></ng-content>
    </div>
</div>
