<form (ngSubmit)="onSubmit()" [formGroup]="form">
    <ins-address-autocomplete-field [control]="address">
        <ins-address-autocomplete-label for="address">Adresse</ins-address-autocomplete-label>
        <ins-address-autocomplete-input>
            <ins-address-autocomplete-container
                id="address"
                formControlName="address"
            ></ins-address-autocomplete-container>
            <cyno-error *ngIf="address.touched && address.hasError('required')">
                Hoppla, diese Adresse ist nicht vollständig.
            </cyno-error>
        </ins-address-autocomplete-input>
    </ins-address-autocomplete-field>
    <cyno-field>
        <cyno-label for="startDate" e2e="start-date-correspondence-address">Einzugsdatum</cyno-label>
        <ins-datepicker formControlName="startDate" id="startDate" separator="." [minDate]="minDate"></ins-datepicker>
        <div class="correspondence-address-alert">
            <div class="alert alert--warning">
                Bitte beachten: Sind Sie umgezogen oder ziehen Sie bald um? Ändern Sie dann Ihre
                <a href="#" (click)="goToResidentialAddress($event)">Wohnadresse.</a>
            </div>
        </div>
    </cyno-field>
    <ins-alert *ngIf="errors?.length > 0" [errors]="errors" type="validation"></ins-alert>
    <ins-details-submit-button [disabled]="loading">Speichern</ins-details-submit-button>
</form>
