import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { PanelExpandableDirectionEnum } from './panel-expandable.enum';

@Component({
    selector: 'ins-panel-expandable',
    templateUrl: './panel-expandable.component.html',
    styleUrls: ['./panel-expandable.component.scss'],
    exportAs: 'panel',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PanelExpandableComponent {
    @Input()
    public panelClass: boolean = true;

    @Input()
    public border: boolean = true;

    public get hidden(): boolean {
        return !this.visible;
    }

    public get isLeft(): boolean {
        return this.direction === PanelExpandableDirectionEnum.Left;
    }

    public get isRight(): boolean {
        return this.direction === PanelExpandableDirectionEnum.Right;
    }

    public get isDirectControl(): boolean {
        return this.direction === PanelExpandableDirectionEnum.DirectControl;
    }

    @Input() public id: string;
    @Input() public visible: boolean = false;
    @Input() public direction: PanelExpandableDirectionEnum = PanelExpandableDirectionEnum.Left;
    @Input() public borderless: boolean = false;

    @Output() public readonly opened: EventEmitter<void> = new EventEmitter();
    @Output() public readonly closed: EventEmitter<void> = new EventEmitter();

    constructor(private changeDetectorRef: ChangeDetectorRef) {}

    public toggle(): void {
        if (this.visible) {
            return this.close();
        }
        return this.open();
    }

    public open(): void {
        this.visible = true;
        this.opened.emit();
        this.changeDetectorRef.detectChanges();
    }

    public close(): void {
        this.visible = false;
        this.closed.emit();
        this.changeDetectorRef.detectChanges();
    }
}
