import { Component, Input } from '@angular/core';
import { RelationInterface } from 'outshared-lib';
import { MyZoneRoutes } from '@app-de/my-zone/routing/my-zone-routing.routes';
import { AppRoutesEnum } from '@app-de/routes';

@Component({
    selector: 'ins-passport',
    templateUrl: './passport.component.html',
    styleUrls: ['./passport.component.scss'],
})
export class PassportComponent {
    @Input() public relation: RelationInterface;

    public isDetailsVisible: boolean = false;
    public appRoutes: typeof AppRoutesEnum = AppRoutesEnum;
    public myZoneRoutes: typeof MyZoneRoutes = MyZoneRoutes;

    public toggle(): void {
        this.isDetailsVisible = !this.isDetailsVisible;
    }
}
