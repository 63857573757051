<div class="details-submit-button">
    <div class="form-group button-group">
        <div class="control-label">&nbsp;</div>
        <div class="control-input">
            <button
                class="btn btn--primary btn--primary-sm btn--block"
                type="submit"
                e2e="details-submit-button"
                [disabled]="disabled"
            >
                <ng-content></ng-content>
            </button>
        </div>
    </div>
</div>
