import { Component, Input } from '@angular/core';

@Component({
    selector: 'ins-sticky-bar-first',
    templateUrl: 'sticky-bar-first.component.html',
    styleUrls: ['./sticky-bar-first.component.scss'],
})
export class StickyBarFirstComponent {
    @Input() public hideMobile: boolean;
}
