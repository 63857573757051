import { ChangeDetectorRef, Component, DoCheck, Input, OnDestroy, OnInit, Optional, Self } from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    FormGroupDirective,
    NgControl,
    NgForm,
    UntypedFormControl,
    UntypedFormGroup,
    ValidatorFn,
} from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { CynoFieldControl } from '../core/cyno-field-control';
import { ErrorStateMatcher } from '../core/error-options';
import { CanUpdateErrorState, mixinErrorState } from '../core/error-state';

export class PasswordStrengthValidator {
    public static hasRequiredCharacters(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const match = (control.value || '').match(/[@.\-?+!#&=]/g);
            return match ? null : { requiredCharacters: { value: control.value } };
        };
    }

    public static hasInvalidCharacters(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const match = (control.value || '').match(/[^\w\s*?+@&!#=.-]+/g);
            return match ? { invalidCharacters: { value: control.value } } : null;
        };
    }

    public static hasNoWordCharacters(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const match = (control.value || '').match(/([A-Z]{2})/gi);
            return !match ? { characters: { value: control.value } } : null;
        };
    }

    public static hasNoNumbers(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            return (control.value || '').match(/([0-9])/g) ? null : { invalidNumbers: { value: control.value } };
        };
    }

    public static isBetween(min: number, max: number): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const currentLength = (control.value || '').trim().length;
            return currentLength < min || currentLength > max ? { currentLength: { value: currentLength } } : null;
        };
    }
}

class CynoPasswordBase {
    constructor(
        public _defaultErrorStateMatcher: ErrorStateMatcher,
        public _parentForm: NgForm,
        public _parentFormGroup: FormGroupDirective,
        public ngControl: NgControl,
    ) {}
}

const cynoPasswordMixinBase = mixinErrorState(CynoPasswordBase);

class CynoPassword {}

@Component({
    selector: 'cyno-password',
    templateUrl: './cyno-password.component.html',
    styleUrls: ['./cyno-password.component.scss'],
    providers: [{ provide: CynoFieldControl, useExisting: CynoPasswordInputComponent }],
})
export class CynoPasswordInputComponent
    extends cynoPasswordMixinBase
    implements CynoFieldControl<CynoPassword>, ControlValueAccessor, OnInit, CanUpdateErrorState, DoCheck, OnDestroy
{
    public get password(): AbstractControl {
        return this.form.get('password');
    }

    public get hasRequiredCharacters(): boolean {
        const password = this.password;

        if (password.errors === null) {
            return false;
        }

        return !password.errors.requiredCharacters;
    }

    public get isValid(): boolean {
        const password = this.password;

        return password.valid;
    }

    @Input() public passwordRequirements: boolean = false;
    @Input() public autocomplete?: string = 'current-password';
    @Input() public showPassword: boolean = false;
    @Input() public placeholder: string = '';

    public id: string | number;
    public value: string;
    public type: string = 'password';
    public required: boolean = true;
    public disabled: boolean = false;
    public focused: boolean = false;

    public form: UntypedFormGroup;

    public stateChanges$: Subject<void> = new Subject<void>();

    public popoverZichtbaar: boolean = false;
    public hasSpecialCharacter: boolean = false;
    public progressValue: number = 0;
    private _onChange: any;
    private _onTouched: any;

    private timeout: any;

    private subscriptions = new Subscription();

    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        defaultErrorStateMatcher: ErrorStateMatcher,
        @Optional() parentForm: NgForm,
        @Optional() parentFormGroup: FormGroupDirective,
        @Optional() @Self() public ngControl: NgControl,
    ) {
        super(defaultErrorStateMatcher, parentForm, parentFormGroup, ngControl);

        if (this.ngControl != null) {
            this.ngControl.valueAccessor = this;
        }

        this.createForm();
    }

    public ngOnInit(): void {
        this.id = this.ngControl.name;

        const passwordChanges = this.password.valueChanges.subscribe(this._onChange);

        this.subscriptions.add(passwordChanges);
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    public ngDoCheck(): void {
        if (this.ngControl) {
            this.updateErrorState();
        }
    }

    public createForm(): void {
        this.form = new UntypedFormGroup({
            password: new UntypedFormControl('', [
                PasswordStrengthValidator.hasNoWordCharacters(),
                PasswordStrengthValidator.hasNoNumbers(),
                PasswordStrengthValidator.isBetween(8, 40),
                PasswordStrengthValidator.hasInvalidCharacters(),
            ]),
        });
    }

    public toggle(): boolean {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }

        if (this.type === 'password') {
            this.type = 'text';
            this.timeout = setTimeout(() => (this.type = 'password'), 2000);
        } else {
            this.type = 'password';
        }
        return false;
    }

    public writeValue(value: string): void {
        this.password.setValue(value);
    }

    public registerOnChange(fn: any): void {
        this._onChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this._onTouched = fn;
    }

    public setDisabledState(): void {}

    public onContainerClick(): void {}

    public _onFocus(): void {
        if (!this.disabled) {
            this.focused = true;

            if (this.passwordRequirements) {
                this.popoverZichtbaar = true;
            }
        }
    }

    public _onBlur(): void {
        this.focused = false;
        this.popoverZichtbaar = false;

        if (!this.disabled && this.id !== 'huidig_wachtwoord') {
            this._onTouched();
            this._changeDetectorRef.markForCheck();
        }

        if (this.id === 'nieuwe_wachtwoord') {
            this._onTouched();
        }
    }

    public onInputChange(): void {
        if (this.id === 'nieuwe_wachtwoord') {
            if (this.isValid) {
                this._onTouched();
                this._onChange(this.password.value);
                this._changeDetectorRef.markForCheck();
            } else {
                this._onChange(null);
            }
        }

        if (this.password.value) {
            this.hasSpecialCharacter = !!this.password.value.match(/[@.\-?+!#&=]/g);
        }

        this.progressValue = this.checkProgress(this.password.errors);
    }

    private checkProgress(errors: any): number {
        let errorCounter: number = 1;
        if (errors) {
            errorCounter = Object.keys(errors).length + 1;
        }

        if (this.hasSpecialCharacter) {
            errorCounter = errorCounter - 1;
        }

        switch (errorCounter) {
            case 3:
                return 25;
            case 2:
                return 50;
            case 1:
                return 75;
            case 0:
                return 100;
            default:
                return 0;
        }
    }
}
