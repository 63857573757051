import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RelationDataService, RelationInterface } from 'outshared-lib';
import { Observable } from 'rxjs';

@Component({
    selector: 'ins-passport-container',
    template: ` <ins-passport [relation]="relation$ | async"></ins-passport> `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PassportContainer {
    public relation$: Observable<RelationInterface> = this.relationDataService.getRelation$();

    constructor(private relationDataService: RelationDataService) {}
}
