import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { SharedModule } from '../../shared/shared.module';
import { GrosseInspektionSummaryComponent } from './grosse-inspektion-summary.component';
import { CardModule } from '@app-de/shared/card/card.module';

@NgModule({
    declarations: [GrosseInspektionSummaryComponent],
    imports: [CommonModule, RouterModule, CardModule, LazyLoadImageModule, SharedModule],
    exports: [GrosseInspektionSummaryComponent],
})
export class GrosseInspektionSummaryModule {}
