export const genericInputLabels = {
    address: $localize`:@@generic.label.address:Address`,
    bankAccount: $localize`:@@generic.label.bank-account:Account number`,
    bankAccountHolderName: $localize`:@@generic.label.bank-account-holder-name:Account registration`,
    bicycleKeyNumber: $localize`:@@generic.label.bicycle-key-number:Bicycle key number`,
    bicycleType: $localize`:@@generic.label.bicycle-type:Type of bicycle`,
    brand: $localize`:@@generic.label.brand:Brand`,
    chipNumber: $localize`:@@generic.label.chip-number:chip number`,
    city: $localize`:@@generic.label.city:City`,
    civicNumberAndSuffix: $localize`:@@generic.label.civicnumber-and-suffix:House number / addition`,
    currentPassword: $localize`:@@generic.label.current-password:Current password`,
    dateOfBirth: $localize`:@@generic.label.date-of-birth:Date of birth`,
    email: $localize`:@@generic.label.email:E-mailaddress`,
    endDate: $localize`:@@generic.label.end-date:End date`,
    familyComposition: $localize`:@@generic.label.family-composition:Family composition`,
    framenumber: $localize`:@@generic.label.framenumber:Frame number`,
    futureDriver: $localize`:@@generic.label.future-driver:Future driver`,
    gender: $localize`:@@generic.label.gender:SexGender`,
    initials: $localize`:@@generic.label.initials:Initials`,
    landlinePhonenumber: $localize`:@@generic.label.landline-phonenumber:Land line`,
    lastName: $localize`:@@generic.label.last-name:Last name`,
    lastNamePrefix: $localize`:@@generic.label.last-name-prefix:Prefix(es)`,
    licenseplateIssueDate: $localize`:@@generic.label.licenseplate-issue-date:Issue date license plate`,
    licenseplatenumber: $localize`:@@generic.label.licenseplate:License Plate`,
    mobilePhonenumber: $localize`:@@generic.label.mobile-phonenumber:Mobile phone number`,
    name: $localize`:@@generic.label.name:Name`,
    newPassword: $localize`:@@generic.label.new-password:New password`,
    password: $localize`:@@generic.label.password:Password`,
    postalcode: $localize`:@@generic.label.postalcode:Postalcode`,
    purchaseDate: $localize`:@@generic.label.purchase-date:Purchase date`,
    purchaseValue: $localize`:@@generic.label.purchase-value:Purchase value`,
    recoveryEmailAddress: $localize`:@@generic.label.recovery-emailaddress:Recovery email address`,
    regularDriver: $localize`:@@generic.label.regular-driver:Regular driver`,
    relationId: $localize`:@@generic.label.relation-id:Policy / customer number`,
    repeatPassword: $localize`:@@generic.label.repeat-password:Repeat password`,
    startDate: $localize`:@@generic.label.start-date:Starting date`,
    streetName: $localize`:@@generic.label.street-name:Street name`,
    veterinarianName: $localize`:@@generic.label.veterinarian-name:Veterinary surgeon`,
};
