<ins-details-table [display]="detailsTableDisplayEnum.Future">
    <ins-details-delete-button
        title="Löschen"
        e2e="deleteNewAddress"
        (click)="onCancelFutureMailingAddress()"
        [isDisabled]="loading"
    >
        Löschen
    </ins-details-delete-button>
    <ins-alert *ngIf="errors.length > 0" [errors]="errors" type="validation"></ins-alert>
    <tr>
        <td>Zukünftige Postadresse</td>
        <!-- prettier-ignore -->
        <td e2e="future-correspondence-address">
            <span>{{ relation.mailing_address_future.address.street_name }}</span>
            <span>
                {{ relation.mailing_address_future.civic_number }}{{ relation.mailing_address_future.civic_number_suffix }},
            </span>
            <!-- prettier-ignore -->
            <span>{{ relation.mailing_address_future.postal_code }} </span>
            <span>{{ relation.mailing_address_future.address.city }}</span>
        </td>
    </tr>
    <tr>
        <td e2e="future-address-text">Beginndatum</td>
        <td e2e="future-correspondence-date">{{ relation.mailing_address_future.start_date | dateFormat }}</td>
    </tr>
</ins-details-table>
