import { Injectable } from '@angular/core';
import { Router, UrlSegmentGroup } from '@angular/router';

const DEFAULT_SEGMENT = 'primary';

@Injectable({ providedIn: 'root' })
export class RouteHelperService {
    constructor(private router: Router) {}

    public getUrl(): string {
        return this.router.url;
    }

    public getCleanUrl(): string {
        const urlTree = this.router.parseUrl(this.router.url);
        urlTree.queryParams = {};
        urlTree.fragment = null;

        if (urlTree.root.hasChildren()) {
            urlTree.root = this.clearSegments(urlTree.root);
        }

        return urlTree.toString();
    }

    private clearSegments(node: UrlSegmentGroup): UrlSegmentGroup {
        Object.keys(node.children).forEach((key: string) => {
            const child = node.children[key];
            if (child.hasChildren()) {
                this.clearSegments(child);
            }

            if (key !== DEFAULT_SEGMENT) {
                delete node.children[key];
            }
        });
        return node;
    }
}
