import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
    PremiumDeterminingFactorComponent,
} from './premium-determining-factor/premium-determining-factor.component';
import {
    PremiumDeterminingFactorContainer,
} from './premium-determining-factor/premium-determining-factor.container';
import { PremiumDeterminingFactorsContainer } from './premium-determining-factors.container';
import { CynoFormModule } from '@app-de/shared/cyno-form/cyno-form.module';
import { PanelExpandableModule } from '@app-de/shared/panel-expandable/panel-expandable.module';
import { DateFormatPipeModule } from '@app-de/shared/pipes/date-format/date-format.module';

@NgModule({
    imports: [
        CommonModule,
        CynoFormModule,
        PanelExpandableModule,
        DateFormatPipeModule,
    ],
    declarations: [
        PremiumDeterminingFactorContainer,
        PremiumDeterminingFactorComponent,
        PremiumDeterminingFactorsContainer,
    ],
    exports: [PremiumDeterminingFactorsContainer],
})
export class PremiumDeterminingFactorsModule {}
