<ins-modal-close></ins-modal-close>

<ins-modal-title>Account entsperren</ins-modal-title>

<ins-modal-content *ngIf="!success">
    <p>
        Ihr Account ist leider gesperrt. Sie erhalten wieder Zugang, indem Sie unten das Geburtsdatum des
        Versicherungsnehmers eingeben. Wir senden Ihnen dann ein vorläufiges Passwort zu, mit dem Sie sich erneut
        einloggen können. Bitte beachten: Dieses Passwort ist nur 24 Stunden gültig.
    </p>

    <form [formGroup]="form" role="form" (ngSubmit)="onSubmit()">
        <cyno-field [showSuccess]="false">
            <cyno-label>E-Mail-Adresse</cyno-label>
            <input cynoInput formControlName="email" id="modal-email" />
        </cyno-field>

        <cyno-field>
            <cyno-label>Geburtsdatum</cyno-label>
            <cyno-birthdate
                formControlName="birthdate"
                id="modal-birthdate"
                placeholder="TT.MM.JJJJ"
                separator="."
            ></cyno-birthdate>
        </cyno-field>
    </form>
    <ins-alert type="validation" *ngIf="errors.length > 0" [errors]="errors"></ins-alert>
</ins-modal-content>

<ins-modal-content *ngIf="success">
    <p *ngFor="let notification of notifications">
        {{ notification.message }}
    </p>
</ins-modal-content>

<ins-modal-actions alignment="end" *ngIf="!success">
    <button class="btn btn--primary" [disabled]="submitting || form.invalid" (click)="onSubmit()">Weiter</button>
</ins-modal-actions>

<ins-modal-actions alignment="end" *ngIf="success">
    <button class="btn btn--primary" (click)="onCloseAfterSuccess()">Schließen</button>
</ins-modal-actions>
