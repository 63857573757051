import { NgModule } from '@angular/core';
// Do not import from index, creates a cyclic import
import { WucBrandIconModule } from '../../layout/brand-image/';
import { WucLogoSpinnerComponent } from './logo-spinner.component';

@NgModule({
    declarations: [WucLogoSpinnerComponent],
    exports: [WucLogoSpinnerComponent],
    imports: [WucBrandIconModule],
})
export class WucLogoSpinnerModule {}
