import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HighlightPipe } from './highlight.pipe';

@NgModule({
    declarations: [HighlightPipe],
    exports: [HighlightPipe],
    imports: [CommonModule],
})
export class HighlightModule {}
