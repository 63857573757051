import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorInterface, RelationActionService, RelationDataService, RelationInterface } from 'outshared-lib';
import { Observable, Subscription, throwError } from 'rxjs';
import { catchError, filter, take, tap } from 'rxjs/operators';

@Component({
    selector: 'ins-personal-details-form-container',
    template: ` <ins-personal-details-form
        [errors]="errors"
        [relation]="relation$ | async"
        (submitted)="onSubmit($event)"
    ></ins-personal-details-form>`,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PersonalDetailsFormContainer implements OnDestroy {
    public relation$: Observable<RelationInterface> = this.relationDataService.getRelation$().pipe(
        filter((relation: RelationInterface) => !!relation),
        take(1),
    );

    public errors: ErrorInterface[];
    private subscriptions = new Subscription();

    constructor(
        private router: Router,
        private relationActionService: RelationActionService,
        private relationDataService: RelationDataService,
        private changeDetectorRef: ChangeDetectorRef,
    ) {}

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    public onSubmit(payload: { mobilePhoneNumber: string; genderCode: string }): void {
        const request$ = this.relationActionService.updatePersonalDetails$({
            genderCode: payload.genderCode,
            mobileTelephoneNumber: {
                telephoneNumber: payload.mobilePhoneNumber,
            },
        });

        const requestSubscription = request$
            .pipe(
                tap(() => {
                    this.router.navigate([], {
                        queryParams: {
                            panel: null,
                            scrollTo: null,
                            expand: null,
                        },
                        queryParamsHandling: 'merge',
                    });
                }),
                catchError((errors: ErrorInterface[]) => {
                    this.errors = errors;
                    this.changeDetectorRef.detectChanges();
                    return throwError(errors);
                }),
            )
            .subscribe();

        this.subscriptions.add(requestSubscription);
    }
}
