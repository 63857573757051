import { ViewportScroller } from '@angular/common';
import { NavigationEnd, Router, Scroll } from '@angular/router';
import { filter, map, pairwise, withLatestFrom } from 'rxjs/operators';

export class DisableScrollToTopUtility {
    public static urls: string[];

    public static disable(urls: string[], router: Router, viewportScroller: ViewportScroller): void {
        this.urls = urls;
        this.scrollToTopOnForwardNavigation(router, viewportScroller);
    }

    public static scrollToTopOnForwardNavigation(router: Router, viewportScroller: ViewportScroller): void {
        const scrollEvents$ = router.events.pipe(
            filter((event) => event instanceof Scroll),
            map((event) => event as Scroll),
        );

        // Emits the previous URL after the router navigates to a new URL
        const originUrl$ = router.events.pipe(
            filter((event) => event instanceof NavigationEnd),
            map((event) => event as NavigationEnd),
            pairwise(),
            map((navigationEvents) => navigationEvents),
        );

        scrollEvents$
            .pipe(withLatestFrom(originUrl$))
            .subscribe(([scrollEvent, navigationEvents]) =>
                this.handleScrollNavigationEvent(viewportScroller, scrollEvent, navigationEvents),
            );
    }

    public static handleScrollNavigationEvent(
        viewportScroller: ViewportScroller,
        scrollEvent: Scroll,
        navigationEvents: [NavigationEnd, NavigationEnd],
    ): void {
        const [previousNavigationEvents, nextNavigationEvents] = navigationEvents;
        const urls: string[] = this.getUrlList(nextNavigationEvents);
        const activeRoute: string = nextNavigationEvents.url.split('?')[0];

        if (scrollEvent.position) {
            // backward navigation
            viewportScroller.scrollToPosition(scrollEvent.position);
        } else if (scrollEvent.anchor) {
            // anchor navigation
            viewportScroller.scrollToAnchor(scrollEvent.anchor);
            // dirty hack for modals
        } else if (!urls.includes(activeRoute) && !previousNavigationEvents.url.includes('(modal:')) {
            // forward navigation from non routes
            viewportScroller.scrollToPosition([0, 0]);
        }
    }

    public static getUrlList(nextNavigationEvents: NavigationEnd): string[] {
        const urls = Object.assign([], this.urls);
        const url = nextNavigationEvents.url.split('?');

        if (url.length === 1) {
            urls.pop();
        }

        return urls;
    }
}
