<form [formGroup]="form" class="change-coverage">
    <wuc-compare-table-coverage
        *ngIf="data.columns.length > 0 && data.labels.length > 0"
        tableBackgroundColor="light"
        labelColumnBackgroundColor="light"
        dataColumnBackgroundColor="dark"
        [data]="data"
        [isMobileView]="isScreenSmall"
        [selectedColumnKey]="selectedColumnKey"
        (clicked)="onSelectColumnKey($event)"
    ></wuc-compare-table-coverage>

    <div class="change-coverage__mallorca-police-section">
        <wuc-card padding="large" borderColor="primary" additionalContentLocation="top-right">
            <h3>Mallorca-Police</h3>
            <p>Ihre Autoversicherung deckt auch im Urlaub mit dem Mietwagen verursachte Schäden.</p>
            <ng-container slot="additionalContent">
                <wuc-scribble-circle scribbleText="Inbegriffen"></wuc-scribble-circle>
            </ng-container>
        </wuc-card>
    </div>

    <h3>Zusätzliche Deckungen</h3>
    <div class="change-coverage__extra-coverage change-coverage__extra-coverage--driver-protection">
        <div class="change-coverage__extra-coverage-checkbox">
            <wuc-input-checkbox formControlName="driverProtection" name="Fahrerschutz">
                Fahrerschutz
                <span class="change-coverage__most-popular"><ins-label>Meistgewählt</ins-label></span>
            </wuc-input-checkbox>
        </div>
        <div class="change-coverage__extra-coverage-more-info">
            <wuc-link type="secondary" (click)="openAdditionalCoverageModal(coverageIdEnum.Fahrerschutz)">
                Mehr Info
            </wuc-link>
        </div>
        <wuc-price size="m" [price]="additionalCoveragePrices.driverProtection" locale="de"></wuc-price>
    </div>

    <div class="change-coverage__extra-coverage">
        <div class="change-coverage__extra-coverage-checkbox">
            <wuc-input-checkbox formControlName="roadsideAssistance" name="Pannenhilfe">Pannenhilfe</wuc-input-checkbox>
        </div>
        <div class="change-coverage__extra-coverage-more-info">
            <wuc-link type="secondary" (click)="openAdditionalCoverageModal(coverageIdEnum.Pannenhilfe)">
                Mehr Info
            </wuc-link>
        </div>
        <wuc-price size="m" [price]="additionalCoveragePrices.roadsideAssistance" locale="de"></wuc-price>
    </div>

    <div class="change-coverage__monthly-payment">
        <wuc-page-block>
            <div class="change-coverage__collapse-icon" (click)="onSelectedCoverageToggle()">
                <wuc-icon-ui
                    *ngIf="!isSelectedCoveragePanelOpen"
                    icon="bottom-open"
                    type="primary"
                    size="xs"
                ></wuc-icon-ui>
                <wuc-icon-ui *ngIf="isSelectedCoveragePanelOpen" icon="up-open" type="primary" size="xs"></wuc-icon-ui>
            </div>
            <div class="change-coverage__selected-coverage-panel" *ngIf="isSelectedCoveragePanelOpen">
                <h3>Gewählte Versicherung</h3>
                <div class="change-coverage__selected-coverage-panel-item">
                    <span>{{ selectedBaseCoverage.coverageName }}</span>
                    <span>{{ selectedBaseCoverage.price | number : '1.0-2' }}</span>
                </div>
                <div
                    class="change-coverage__selected-coverage-panel-item change-coverage__selected-coverage-panel-item--mallorca"
                >
                    <span>Mallorca-Police</span>
                    <span>inbegriffen</span>
                </div>
                <div class="change-coverage__selected-coverage-panel-item" *ngIf="driverProtection.value">
                    <span>Fahrerschutz</span>
                    <span>{{ additionalCoveragePrices.driverProtection | number : '1.0-2' }}</span>
                </div>
                <div class="change-coverage__selected-coverage-panel-item" *ngIf="roadsideAssistance.value">
                    <span>Pannenhilfe</span>
                    <span>{{ additionalCoveragePrices.roadsideAssistance | number : '1.0-2' }}</span>
                </div>
            </div>
            <div class="change-coverage__monthly-payment-header">
                <h3>{{ purchasedPeriod === 12 ? 'Jahresbeitrag' : 'Monatsbeitrag' }}</h3>
                <wuc-price size="l" [price]="payment" locale="de"></wuc-price>
            </div>
            <p>
                <small>
                    Alle genannten Preise sind in Euro und inklusive Versicherungssteuer angegeben. Noch nicht
                    verrechnet ist die Jahresbelohnung, die Sie am Jahresbeginn bekommen, wenn Geld übrig bleibt.
                    <a href="#" (click)="getInfo()">Was ist die Jahresbelohnung?</a>
                </small>
            </p>

            <div class="change-coverage__start-date">
                <wuc-form-field name="eingangsdatum">
                    <wuc-form-field-label>Beginndatum</wuc-form-field-label>
                    <wuc-input-date-picker
                        [selectToday]="false"
                        [selectPastDates]="false"
                        formControlName="startDate"
                    ></wuc-input-date-picker>
                </wuc-form-field>
            </div>

            <wuc-form-error *ngFor="let notification of notifications">
                {{ notification.message_content }}
            </wuc-form-error>

            <div class="btn-group btn-group--spread change-coverage__btn-group">
                <wuc-link type="secondary" (click)="onClose()" *ngIf="hasCancelButton">Zurück</wuc-link>

                <div class="change-coverage__explanation-arrow-wrapper">
                    <wuc-explanation-arrow position="bottomLeft" text="Kann täglich gekündigt werden.">
                        <div class="change-coverage__explanation-arrow-button-wrapper">
                            <wuc-button
                                [isFullWidth]="true"
                                type="primary"
                                [disabled]="isLoading && notifications.length === 0"
                                (click)="onSubmit()"
                            >
                                Ändern
                            </wuc-button>
                        </div>
                    </wuc-explanation-arrow>
                </div>
            </div>
        </wuc-page-block>
    </div>
</form>
