import { Component, Input } from '@angular/core';
import { AppRoutesEnum } from '@app-de/routes';
import { SmartTipsImageEnum } from '../../smart-tips-image.enum';
import { SmartTipsEnum } from '../../smart-tips.enum';

@Component({
    selector: 'ins-verhalten-autopanne-summary',
    templateUrl: './verhalten-autopanne-summary.component.html',
})
export class VerhaltenAutopanneSummaryComponent {
    @Input() public smartTipsCarousel: boolean = false;

    public smartTipsEnum = SmartTipsEnum;
    public appRoutesEnum = AppRoutesEnum;
    public smartTipsImageEnum = SmartTipsImageEnum;
    public url = `/${this.appRoutesEnum.CarInsurance}/${this.smartTipsEnum.Pannenvermeidung}/${this.smartTipsEnum.TippsVerhaltenBeiAutopanne}`;
}
