import { Component, inject, OnDestroy } from '@angular/core';
import { Promise } from 'cypress/types/cy-bluebird';
import {
    AuthenticationActionService,
    AuthenticationDataService,
    ErrorInterface,
    LoginRequestInterface,
    NewModalRef as ModalRef,
} from 'outshared-lib';
import { Observable, Subscription } from 'rxjs';
import { LivechatService } from '@app-de/core/livechat/livechat.service';

@Component({
    selector: 'ins-is-customer-modal',
    templateUrl: './is-customer.modal.html',
    styleUrls: ['./is-customer.modal.scss'],
})
export class IsCustomerModalComponent implements OnDestroy {
    private subscriptions: Subscription = new Subscription();

    public loading$: Observable<boolean> = this.authenticationDataService.loginPending$();
    public errors: ErrorInterface[] = [];

    constructor(
        private modalRef: ModalRef<IsCustomerModalComponent>,
        private livechatService: LivechatService,
        private authenticationActionService: AuthenticationActionService,
        private authenticationDataService: AuthenticationDataService,
    ) {}

    public _showLogin: boolean = false;

    public cancel(): void {
        this.livechatService.init(false);
        this.modalRef.close();
    }

    public close(): void {
        this.modalRef.close();
    }

    public showLogin(): void {
        this._showLogin = true;
    }

    public loginUser(credentials: LoginRequestInterface): void {
        const loginSubscription = this.authenticationActionService.login$(credentials).subscribe({
            next: (): Promise<boolean> | void | string => this.handleSuccess(),
            error: (errors): void => this.handleErrors(errors),
        });

        this.subscriptions.add(loginSubscription);
    }

    private handleSuccess(): Promise<boolean> | void | string {
        this.livechatService.init(true);
        this.modalRef.close();
    }

    private handleErrors(errors: ErrorInterface[]): void {
        this.errors = errors;
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
