import {
    ComponentFactory,
    ComponentFactoryResolver,
    ComponentRef,
    Directive,
    HostBinding,
    HostListener,
    Input,
    Type,
    ViewContainerRef,
} from '@angular/core';
import { TooltipComponent } from './tooltip.component';

@Directive({
    selector: '[insTooltip]',
})
export class TooltipDirective {
    @HostBinding('style.position') public position = 'relative';
    @HostBinding('style.cursor') public cursor = 'pointer';
    @HostBinding('style.display') public display = 'inline-block';

    @Input('insTooltip') public tooltipContent: string;
    @Input() public orientation: 'left' | 'right' | 'bottom' = 'bottom';

    private componentInstance: ComponentRef<TooltipComponent>;
    private animating = false;

    constructor(
        private viewContainerRef: ViewContainerRef,
        private componentFactoryResolver: ComponentFactoryResolver,
    ) {}

    @HostListener('click') public click(event: Event): boolean {
        return false;
    }

    @HostListener('mouseenter') public mouseover(event: Event): void {
        this.show();
    }

    @HostListener('mouseleave') public mouseleave(event: Event): void {
        this.hide();
    }

    private show(): void {
        if (this.animating || !this.tooltipContent) {
            return;
        }

        const componentfactory = this.createComponentFactory();
        const componentInstance = this.viewContainerRef.createComponent(componentfactory);

        componentInstance.instance.content = this.tooltipContent;
        componentInstance.instance.orientation = this.orientation;
        componentInstance.instance.visible$.next(true);

        this.componentInstance = componentInstance;
        this.insertComponent();
    }

    private hide(): void {
        if (this.animating || !this.tooltipContent) {
            return;
        }

        this.componentInstance.instance.visible$.next(false);
        this.animating = true;

        setTimeout(() => {
            this.componentInstance.destroy();
            this.animating = false;
        }, 75);
    }

    private createComponentFactory(): ComponentFactory<TooltipComponent> {
        const component: Type<TooltipComponent> = TooltipComponent;
        const componentfactory: ComponentFactory<TooltipComponent> =
            this.componentFactoryResolver.resolveComponentFactory(component);

        return componentfactory;
    }

    private insertComponent(): void {
        const tooltipComponent = this.componentInstance.location.nativeElement;
        const sibling: HTMLElement = tooltipComponent.previousSibling;

        sibling.insertBefore(tooltipComponent, sibling.firstChild);
    }
}
