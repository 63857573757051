import { InjectionToken, Input, Optional, Inject, OnInit, Directive } from '@angular/core';

export interface CynoOptionParentComponent {
    id: string;
}

export const CYNO_OPTION_PARENT_COMPONENT = new InjectionToken<CynoOptionParentComponent>(
    'CYNO_OPTION_PARENT_COMPONENT',
);

@Directive({
    selector: 'option',
    exportAs: 'cynoOption',
    host: {
        class: 'cyno-option',
        role: 'option',
        '[attr.id]': 'id',
        '[attr.aria-disabled]': 'disabled.toString()',
        '[class.cyno-option-disabled]': 'disabled',
    },
})
export class CynoOptionDirective implements OnInit {
    @Input() public disabled: boolean = false;
    @Input() public value: any;
    public id: string;

    constructor(@Optional() @Inject(CYNO_OPTION_PARENT_COMPONENT) private _parent: CynoOptionParentComponent) {}

    public ngOnInit(): void {
        if (this._parent && this.value) {
            this.id = `${this._parent.id}-${this.value}`;
        }
    }
}
