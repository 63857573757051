import { Component } from '@angular/core';
import { RelationDataService, RelationInterface } from 'outshared-lib';
import { Observable } from 'rxjs';

@Component({
    selector: 'ins-correspondence-address-data-container',
    template: '<ins-correspondence-address-data [relation]="relation$ | async"></ins-correspondence-address-data>',
})
export class CorrespondenceAddressDataContainer {

    public relation$: Observable<RelationInterface> = this.relationDataService.getRelation$();

    constructor(public relationDataService: RelationDataService) {
    }
}
