import { Component, Input } from '@angular/core';
import { ProductBaseInterface } from 'outshared-lib';

@Component({
    selector: 'ins-no-entry-date-notification',
    templateUrl: './no-entry-date-notification.component.html',
})
export class NoEntryDateNotificationComponent {
    @Input() public product: ProductBaseInterface;
}
