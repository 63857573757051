import {
    ApiGetInvoicesInterface,
    ApiInvoiceInterface,
    ApiInvoiceYearsInterface,
    ApiOpenInvoiceDetailsInterface,
    ApiOpenInvoiceInterface,
    GetInvoicesInterface,
    GetOpenInvoiceDetailsInterface,
    GetOpenInvoiceInterface,
    InvoiceInterface,
} from '../interfaces';
import { debitCreditMapper } from './get-invoice-details.mapper';

function openPaymentsDetailMapper(
    openPaymentsDetails: ApiOpenInvoiceDetailsInterface[]
): GetOpenInvoiceDetailsInterface[] {
    if (!openPaymentsDetails) {
        return [];
    }

    return openPaymentsDetails.map((openPaymentDetail: ApiOpenInvoiceDetailsInterface) => {
        return {
            invoiceDate: openPaymentDetail.invoice_date,
            invoiceId: openPaymentDetail.invoice_id,
            month: openPaymentDetail.month,
            amount: openPaymentDetail.amount,
            premiumTax: openPaymentDetail.premium_tax,
            total: openPaymentDetail.total,
            description: openPaymentDetail.description,
        };
    });
}

function openPaymentsMapper(openPayments: ApiOpenInvoiceInterface): GetOpenInvoiceInterface | null {
    if (!openPayments) {
        return null;
    }

    return {
        amount: openPayments.amount,
        details: openPaymentsDetailMapper(openPayments.detail),
    };
}

function invoiceMapper(response: ApiInvoiceInterface[]): InvoiceInterface[] {
    if (!response) {
        return [];
    }

    return response.map((invoice) => ({
        advance: invoice.advance,
        amount: invoice.amount,
        debitCredit: debitCreditMapper(invoice.debit_credit),
        debitedIndication: Boolean(invoice.debited_indication === 'J'),
        description: invoice.description,
        invoiceNumber: invoice.factuurnummer,
        indicationInvoiceRow: invoice.indication_invoice_row,
        invoiceCode: invoice.invoice_code,
        invoiceCodeDescription: invoice.invoice_code_description,
        invoiceDate: invoice.invoice_date,
        invoiceDescription: invoice.invoice_description,
        invoiceId: invoice.invoice_id,
        invoiceSequenceNumber: invoice.invoice_sequence_number,
        month: invoice.month,
        paidOnlineIndication: invoice.online_betaald_indicatie,
        paidIndication: Boolean(invoice.paid_indication === 'J'),
        paymentStatusCode: invoice.payment_status_code,
        paymentStatusCodeDescription: invoice.payment_status_code_description,
        paymentStatusDescription: invoice.payment_status_description,
        paymentTimestamp: invoice.payment_timestamp,
        premiumTax: invoice.premium_tax,
        relationId: invoice.relation_id,
        settling: invoice.settling,
        total: invoice.total,
    }));
}

function yearsMapper(response: ApiInvoiceYearsInterface): number[] {
    if (!response || !response.date_year_part) {
        return [];
    }

    return response.date_year_part.map((dateYearPart) => dateYearPart.year);
}

export function mapInvoicesResponse(response: ApiGetInvoicesInterface): GetInvoicesInterface {
    return {
        invoices: invoiceMapper(response.invoice),
        years: yearsMapper(response.date_year_parts),
        openInvoice: openPaymentsMapper(response.te_betalen_online),
    };
}
