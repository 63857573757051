import { Component, Input } from '@angular/core';
import { CoveragePremiumCoverageInterface, CoveragePremiumInterface, ProductBaseInterface } from 'outshared-lib';

@Component({
    selector: 'ins-coverage-overview',
    templateUrl: 'coverage-overview.component.html',
    styleUrls: ['./coverage-overview.shared.scss'],
})
export class CoverageOverviewComponent {
    @Input() public calculation: CoveragePremiumInterface;
    @Input() public product: ProductBaseInterface;
    @Input() public modulesCurrent: CoveragePremiumCoverageInterface[];
    @Input() public modulesFuture: CoveragePremiumCoverageInterface[] = [];
}
