import { Component, OnDestroy, OnInit } from '@angular/core';
import { ProductBaseInterface, ProductSelectorService } from 'outshared-lib';
import { Subscription } from 'rxjs';

@Component({
    selector: 'ins-insurance-regular-driver-container',
    template: ` <ins-insurance-regular-driver [product]="product"> </ins-insurance-regular-driver> `,
})
export class InsuranceRegularDriverContainer implements OnInit, OnDestroy {
    public product: ProductBaseInterface;
    private subscriptions: Subscription;

    constructor(private productSelectorService: ProductSelectorService) {
        this.subscriptions = new Subscription();
    }

    public ngOnInit(): void {
        const getSelectedProduct$ = this.productSelectorService.getSelectedProduct$.subscribe(
            (product) => (this.product = product),
        );

        this.subscriptions.add(getSelectedProduct$);
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
