import { Component, Input } from '@angular/core';
import { AppRoutesEnum } from '@app-de/routes';
import { SmartTipsImageEnum } from '../../smart-tips-image.enum';
import { SmartTipsEnum } from '../../smart-tips.enum';

@Component({
    selector: 'ins-hoechsten-bussgelder-summary',
    templateUrl: './hoechsten-bussgelder-summary.component.html',
})
export class HoechstenBussgelderSummaryComponent {
    @Input() public smartTipsCarousel: boolean = false;

    public smartTipsEnum = SmartTipsEnum;
    public appRoutesEnum = AppRoutesEnum;
    public smartTipsImageEnum = SmartTipsImageEnum;
    public url = `/${this.appRoutesEnum.CarInsurance}/${this.smartTipsEnum.Autofahren}/${this.smartTipsEnum.HoheStrafenImStrassenverkehr}`;
}
