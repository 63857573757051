import { Component, Input } from '@angular/core';
import { RelationInterface } from 'outshared-lib';

@Component({
    selector: 'ins-family-composition-data',
    templateUrl: 'family-composition-data.component.html',
})
export class FamilyCompositionDataComponent {
    @Input() public relation: RelationInterface;
}
