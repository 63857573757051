import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlButtonInterface } from '@app-de/my-zone/shared/insurance-control/shared/interfaces/control-button.interface';

@Component({
    selector: 'ins-insurance-control-item',
    templateUrl: './insurance-control-item.component.html',
    styleUrls: ['./insurance-control-item.component.scss'],
})
export class InsuranceControlItemComponent {

    @Input()
    public button: ControlButtonInterface;

    @Input()
    public disabled: boolean;

    @Output()
    private readonly clicked: EventEmitter<ControlButtonInterface> = new EventEmitter<ControlButtonInterface>();

    public clickButton(button: ControlButtonInterface): void {
        this.clicked.emit(button);
    }
}
