import { Component } from '@angular/core';
import { NewModalRef } from 'outshared-lib';

@Component({
    selector: 'ins-modal-close',
    templateUrl: 'modal-close.component.html',
    styleUrls: ['modal-close.component.scss'],
})
export class ModalCloseComponent {
    constructor(
        private modalRef: NewModalRef<ModalCloseComponent>
    ) {
    }

    public onClose(): void {
        this.modalRef.close();
    }
}
