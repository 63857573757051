import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { TestimonialsApiService, TestimonialsEventService } from '../services';
import {
    getTestimonialsErrorAction,
    getTestimonialsSuccessAction,
    TestimonialsActions,
    TestimonialsActionsEnum,
} from './testimonials.actions';

@Injectable()
export class TestimonialsEffects {
    constructor(
        private action$: Actions<TestimonialsActions>,
        private testimonialsApiService: TestimonialsApiService,
        private testimonialsEventService: TestimonialsEventService
    ) {}

    public getTestimonials$: Observable<Action> = createEffect(() => {
        return this.action$.pipe(
            ofType(TestimonialsActionsEnum.GetTestimonials),
            switchMap((request) => {
                return this.testimonialsApiService.getTestimonials$(request).pipe(
                    map((response) => getTestimonialsSuccessAction(response)),
                    catchError((errors) => of(getTestimonialsErrorAction({ errors })))
                );
            })
        );
    });

    public getTestimonialsSuccess$ = createEffect(
        () => {
            return this.action$.pipe(
                ofType(TestimonialsActionsEnum.GetTestimonialsSuccess),
                tap(() => this.testimonialsEventService.onGetTestimonialsSuccess())
            );
        },
        { dispatch: false }
    );

    public getTestimonialsError$: Observable<void> = createEffect(
        () => {
            return this.action$.pipe(
                ofType(TestimonialsActionsEnum.GetTestimonialsError),
                map((action) => this.testimonialsEventService.onGetTestimonialsError(action.errors))
            );
        },
        { dispatch: false }
    );
}
