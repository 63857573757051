export enum SmartTipsImageEnum {
    AdvantagesSnowTiresWinter = '6-advantages-snow-tires-winter',
    AutoMist = 'auto-mist',
    AutoPanne = 'auto-panne',
    AutoReisezeit = 'Auto-Ausland-Reise',
    AutoSommerStarklar = 'auto-sommer-starklar',
    AutoWaschen = 'auto-waschen',
    AutopanneVermeiden = 'Hilfe-Autopannen-vermeiden',
    Autoschluessel = '5-facts-car-keys',
    AvoidTrafficJam = 'avoid-traffic',
    BiggestMythsElectricCars = 'biggest-myths-electric-cars',
    CarseatTodlerTeddybear = 'carseat-todler-teddybear',
    CarBatteryConsole = 'car-battery-console',
    CheckAutoFluessigkeiten = 'check-auto-fluessigkeiten',
    ChecklisteAutoHauptuntersuchung = 'Checkliste-Auto-Hauptuntersuchung',
    ChecklisteAutokauf = 'checklist-new-used-car',
    ChecklisteAutoreifen = 'Check-Zeit-neue-Reifen',
    ECarMissingHitch = 'e-car-missing-hitch',
    EnergyLabelCar = 'energy-label-car',
    FahrenSturm = 'Auto-Sturm-sicher-fahren',
    FaktenSicherheitsgurt = '9-facts-seat-belt',
    GeldSparenAutofahrt = 'geld-sparen-autofahrt',
    GrosseInspektion = 'Auto-grosse-Inspektion-Tipps',
    HeuschnupfenKlimaanlage = 'Heuschnupfen-Auto-Klimaanlage',
    HilfeAuffahrtAutobahnEinordnen = 'Hilfe-Auffahrt-Autobahn-einordnen',
    HoechstenBussgelder = 'policecap-fine',
    IncreaseRangeElectricCar = 'increase-range-electric-car',
    KraftstoffVerbrauchReduzieren = 'kraftstoff-verbrauch-reduzieren',
    MistakesWinterIceScraping = 'Mistakes-Winter-Ice-Scraping',
    SparenAnDerTankstelle = 'Sparen-an-der-Tankstelle',
    TankenFehler = 'Häufige-Fehler-Tanken',
    TipsBlackIceSnowWinter = '6-Tips-Black-Ice-Snow-Winter',
    TipsCarWinterReady = '6-tips-car-winter-ready',
    TipsHomeECarChargingStation = '6-tips-home-e-car-charging-station',
    TrafficJamBehavior = 'traffic-jam-behavior',
    UrlaubAutomiete = 'Fallen_Auto_leihen_Urlaub',
    VerhaltenAutopanne = 'Verhalten-Panne-mit-Auto',
    VorbereitungHu = 'Fehler-Auto-Waschanlage',
    WenigerAutounfaelleTipps = 'Weniger-Autounfaelle-Tipps',
    WetterAutofahren = 'Wetter_Gefahren_Autofahren',
    WissenAutoKlima = 'wissen-auto-klima',
    WissenTankenImAusland = 'Wissen-Tanken-im-Ausland',
    WissenswertesAutobahn = 'unknown-facts-highway',
    WissenswertesHu = 'Wissen-Tipps-TÜV-HU',
    ParkenOhneStress = 'parking-without-stress',
    ArgerlicheSituationenImAuto = 'traffic-annoyances',
    WichtigeDingeFursAuto = '6-items-car',
    AutofahrenAblenkungenVermeiden  = '3-traffic-distractions',
    WenigerStressBeimAutofahren = 'less-stress-traffic',
    WissenAutoHalterwechselVersicherungswechsel = 'handing-over-car-keys',
}
