export enum BreakPointsEnum {
    ScreenXxsMax = 320,
    ScreenSmMin = 576,
    ScreenXsMax = BreakPointsEnum.ScreenSmMin - 1,
    ScreenMdMin = 768,
    ScreenSmMax = BreakPointsEnum.ScreenMdMin - 1,
    ScreenLgMin = 992,
    ScreenMdMax = BreakPointsEnum.ScreenLgMin - 1,
    ScreenTabletMin = 1024,
    ScreenXlMin = 1200,
    ScreenLgMax = BreakPointsEnum.ScreenXlMin - 1,
}
