import { Component } from '@angular/core';
import { NewModalRef } from 'outshared-lib';

@Component({
    selector: 'ins-family-composition-modal',
    templateUrl: './family-composition.modal.html',
})
export class FamilyCompositionModal {
    constructor(private newModalRef: NewModalRef<FamilyCompositionModal>) {}

    public close(): void {
        this.newModalRef.close(true);
    }

    public dismiss(): void {
        this.newModalRef.close(false);
    }
}
