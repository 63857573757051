import { Component, Input } from '@angular/core';
import { RelationInterface } from 'outshared-lib';

@Component({
    selector: 'ins-correspondence-address-data',
    templateUrl: './correspondence-address-data.component.html',
})
export class CorrespondenceAddressDataComponent {
    @Input() public relation!: RelationInterface;
}
