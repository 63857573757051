import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DetailsSectionComponent } from '@app-de/my-zone/shared/details-section/details-section.component';

@NgModule({
    imports: [CommonModule],
    declarations: [DetailsSectionComponent],
    exports: [DetailsSectionComponent],
})
export class DetailsSectionModule {}
