import { Component, Input } from '@angular/core';

@Component({
    selector: 'ins-smart-tips-card',
    templateUrl: './smart-tips-card.component.html',
    styleUrls: ['./smart-tips-card.component.scss'],
})
export class SmartTipsCardComponent {
    @Input() public smartTipsCarousel: boolean = false;
    @Input() public horizontal: boolean = false;
}
