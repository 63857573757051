<ins-smart-tips-card [smartTipsCarousel]="smartTipsCarousel">
    <a class="card__link" [href]="url">
        <ins-card-image>
            <ins-banner
                [image]="smartTipsImageEnum.VerhaltenAutopanne"
                imageAlt="Ein Frau hat mit ihrem Auto hinter einer Notfallsäule wegen einer Panne auf der Autobahn angehalten."
                imageTitle="Verhalten Sie sich richtig, wenn Sie mit Ihrem Auto eine Panne in der Stadt, auf der Landstraße oder Autobahn haben."
                size="small"
            ></ins-banner>
        </ins-card-image>
        <ins-card-head>
            <h3>Autopanne? So warten Sie sicher auf Hilfe</h3>
        </ins-card-head>
        <ins-card-content>
            <p class="card__content-text">
                Da fährt man gemütlich auf der Autobahn, und plötzlich hat man einen Platten oder der Tank ist leer.
                Nachdem das Fahrzeug am Straßenrand abgestellt und der Pannendienst benachrichtigt wurde, bleibt einem
                nichts anderes übrig, als zu warten. Was sollten Sie jetzt für Ihre eigene Sicherheit und die der
                anderen Autofahrer beachten?
            </p>
        </ins-card-content>
        <ins-card-footer>
            <ins-read-article [smartTip]="url"></ins-read-article>
        </ins-card-footer>
    </a>
</ins-smart-tips-card>
