import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { OutInvoiceModule } from 'outshared-lib';
import { InvoiceDetailsRoutingModule } from './invoice-details-routing.module';
import { InvoiceDetailsComponent } from './invoice-details.component';
import { InvoiceDetailsContainer } from './invoice-details.container';
import { InvoiceAdvanceModule } from '@app-de/my-zone/shared/invoice-advance/invoice-advance.module';
import { InvoiceSettlingModule } from '@app-de/my-zone/shared/invoice-settling/invoice-settling.module';
import { InvoiceTotalModule } from '@app-de/my-zone/shared/invoice-total/invoice-total.module';
import { CapitalizeModule } from '@app-de/shared/pipes/capitalize/capitalize.module';
import { DateFormatPipeModule } from '@app-de/shared/pipes/date-format/date-format.module';
import { RackModule } from '@app-de/shared/rack/rack.module';

@NgModule({
    imports: [
        CommonModule, InvoiceDetailsRoutingModule, OutInvoiceModule,
        CapitalizeModule, RackModule, DateFormatPipeModule, InvoiceAdvanceModule,
        InvoiceSettlingModule, InvoiceTotalModule,
    ],
    exports: [InvoiceDetailsComponent, InvoiceDetailsContainer],
    declarations: [InvoiceDetailsComponent, InvoiceDetailsContainer],
})
export class InvoiceDetailsModule { }
