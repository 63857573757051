import { Component } from '@angular/core';
import { ProductBaseInterface, ProductSelectorService } from 'outshared-lib';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Component({
    selector: 'ins-policy-details-container',
    template: `
        <ins-policy-details [product]="product$ | async" [productTerms]="productTerms$ | async"> </ins-policy-details>
    `,
})
export class PolicyDetailsContainer {
    public product$: Observable<ProductBaseInterface> = this.productSelectorService.getSelectedProduct$;
    public productTerms$: Observable<string> = this.productSelectorService.getSelectedProduct$.pipe(
        filter((product) => Boolean(product)),
        map((product) => `voorwaarden-${product.product_description}.pdf`),
    );

    constructor(private productSelectorService: ProductSelectorService) {}
}
