<div
    class="card"
    [ngClass]="{
        'card--border': borders,
        'card--ezine': eZine,
        'card--smart-tips': smartTips,
        'card--smart-tips-horizontal': smartTipsHorizontal,
        'card--border-primary': borderPrimary,
        'card--text text--center': textCenter,
        'card--white': white,
        'card--small': small,
        'card--no-margin': !margin,
        'card--vertical-align': verticalAlign,
        'card--highlight': highlight
    }"
>
    <ng-content></ng-content>
</div>
