import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DetailsContainer } from '@app-de/my-zone/pages/details/details.container';

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        component: DetailsContainer,
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class DetailsRoutingModule {}
