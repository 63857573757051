import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from '../../core';
import {
    GetTestimonialsResponseInterface,
    ApiTestimonialResponseInterface,
    ApiTestimonialsRequestInterface,
} from '../interfaces';
import { mapTestimonialsResponse } from '../mappers/testimonials.response.mapper';

const RESOURCES = {
    GET_TESTIMONIALS: 'en-gb/reviews-general/request/testimonial',
};

@Injectable({
    providedIn: 'root',
})
export class TestimonialsApiService {
    public constructor(private apiService: ApiService) {}

    public getTestimonials$(payload: ApiTestimonialsRequestInterface): Observable<GetTestimonialsResponseInterface> {
        return this.apiService
            .post$<ApiTestimonialResponseInterface>(RESOURCES.GET_TESTIMONIALS, payload)
            .pipe(map((response) => mapTestimonialsResponse(payload, response)));
    }
}
