import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorInterface, RelationActionService, RelationDataService, RelationInterface } from 'outshared-lib';
import { Observable, Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';

@Component({
    selector: 'ins-residential-address-future-container',
    template: ` <ins-residential-address-future
        [relation]="relation$ | async"
        [loading]="loading"
        [errors]="errors"
        (cancelFutureResidentialAddress)="onCancelFutureResidentialAddress()"
    >
    </ins-residential-address-future>`,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResidentialAddressFutureContainer implements OnDestroy {
    public loading: boolean;
    public errors: ErrorInterface[];
    public relation$: Observable<RelationInterface> = this.relationDataService.getRelation$().pipe(
        filter((relation: RelationInterface) => !!relation),
        take(1),
    );

    private subscriptions = new Subscription();

    constructor(
        private router: Router,
        private relationActionService: RelationActionService,
        private relationDataService: RelationDataService,
    ) {
        this.errors = [];
        this.loading = false;
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    public onCancelFutureResidentialAddress(): void {
        const cancelResidentialAddress = this.relationActionService.cancelResidentialAddress$().subscribe({
            next: () => this.onSuccess(),
            error: (errors) => this.onError(errors),
        });

        this.loading = true;
        this.errors = [];
        this.subscriptions.add(cancelResidentialAddress);
    }

    private onSuccess(): void {
        this.loading = false;
        this.router.navigate([], {
            queryParams: {
                panel: null,
                scrollTo: null,
                expand: null,
            },
            queryParamsHandling: 'merge',
        });
    }

    private onError(errors: ErrorInterface[]): void {
        this.loading = false;
        this.errors = errors;
    }
}
