import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CoveragePremiumCoverageInterface, CoveragePremiumInterface, ProductBaseInterface } from 'outshared-lib';
import { Observable } from 'rxjs';
import { CoverageDetailService } from '../coverage-detail.service';

@Component({
    selector: 'ins-coverage-overview-container',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <ins-coverage-overview
            *ngIf="calculation$ | async"
            [calculation]="calculation$ | async"
            [product]="product$ | async"
            [modulesCurrent]="modulesCurrent$ | async"
            [modulesFuture]="modulesFuture$ | async"
        >
        </ins-coverage-overview>
    `,
})
export class CoverageOverviewContainer {
    public calculation$: Observable<CoveragePremiumInterface> = this.coverageDetailService.calculation$;
    public product$: Observable<ProductBaseInterface> = this.coverageDetailService.selectedProduct$;
    public modulesCurrent$: Observable<CoveragePremiumCoverageInterface[]> = this.coverageDetailService.modulesCurrent$;
    public modulesFuture$: Observable<CoveragePremiumCoverageInterface[]> = this.coverageDetailService.modulesFuture$;

    constructor(private coverageDetailService: CoverageDetailService) {}
}
