import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {
    AccountRecoveryActionService,
    AuthenticationDataService,
    AuthenticationStatusEnum,
    ErrorInterface,
    NewModal,
    NewModalRef,
    ValidateGeneratedAccountRequestInterface,
} from 'outshared-lib';
import { Subscription } from 'rxjs';
import { CompleteGeneratedAccountModal } from '../complete-generated-account-modal';
import { AppRoutesEnum } from '@app-de/routes';

@Component({
    selector: 'ins-validate-generated-account-modal',
    templateUrl: './validate-generated-account.modal.html',
})
export class ValidateGeneratedAccountModal implements OnInit, OnDestroy {
    public submitting: boolean = false;
    public errors: ErrorInterface[] = [];
    public form: UntypedFormGroup;

    private subscriptions!: Subscription;

    constructor(
        private router: Router,
        private accountRecoveryActionService: AccountRecoveryActionService,
        private authenticationDataService: AuthenticationDataService,
        private newModal: NewModal,
        private newModalRef: NewModalRef<ValidateGeneratedAccountModal>,
    ) {}

    public ngOnInit(): void {
        this.subscriptions = new Subscription();
        this.initializeForm();
        this.checkAuthenticationStatus();
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    public onSubmit(): void {
        if (!this.form.valid) {
            return;
        }
        this.submitting = true;
        this.errors = [];
        const request: ValidateGeneratedAccountRequestInterface = {
            birthdate: this.form.value.birthdate,
            postalCode: this.form.value.postalCode.trim(),
        };

        this.accountRecoveryActionService.validateGeneratedAccount$(request).subscribe(
            () => {
                this.newModalRef.close();
                this.newModal.open<CompleteGeneratedAccountModal>(CompleteGeneratedAccountModal);
            },
            (errors: ErrorInterface[]) => {
                this.errors = errors;
                this.submitting = false;
            },
        );
    }

    private initializeForm(): void {
        this.form = new UntypedFormGroup({
            birthdate: new UntypedFormControl('', [Validators.required]),
            postalCode: new UntypedFormControl('', [Validators.required]),
        });
    }

    private checkAuthenticationStatus(): void {
        const statusSubscription = this.authenticationDataService
            .getStatus$()
            .subscribe((status: AuthenticationStatusEnum) => {
                if (status !== AuthenticationStatusEnum.GeneratedAccount) {
                    this.router.navigate([AppRoutesEnum.Login]);
                }
            });

        this.subscriptions.add(statusSubscription);
    }
}
