import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { InvoiceDetailsInterface, InvoiceInterface, OpenInvoiceInterface } from '../interfaces';
import { InvoiceSelectors } from '../store';
import { Invoice, InvoiceState } from '../store/invoice.state';

@Injectable({
    providedIn: 'root',
})
export class InvoiceDataService {
    constructor(private store$: Store<Invoice>) {}

    /** Only for testing in demo */
    public getState$(): Observable<InvoiceState> {
        return this.store$.select(InvoiceSelectors.getState);
    }

    public invoices$(): Observable<InvoiceInterface[]> {
        return this.store$.select(InvoiceSelectors.invoices);
    }

    public openInvoice$(): Observable<OpenInvoiceInterface> {
        return this.store$.select(InvoiceSelectors.openInvoice);
    }

    public years$(): Observable<number[]> {
        return this.store$.select(InvoiceSelectors.years);
    }

    public invoiceDetails$(props: { invoiceId: string }): Observable<InvoiceDetailsInterface> {
        return this.store$.select(InvoiceSelectors.invoiceDetails).pipe(
            map((invoiceDetails) => {
                const invoice = invoiceDetails.find(
                    (invoice: InvoiceDetailsInterface) => invoice.invoiceId === props.invoiceId
                );

                if (!invoiceDetails) {
                    throw new Error(`Invoice with invoiceId: '${props.invoiceId}' was not found.`);
                }

                return invoice;
            })
        );
    }
}
