export enum DirectControlButtonIdEnum {
    InsuranceStartDateSet = 'Set Startdate Insurance',
    InsuranceStartDateChange = 'Change Startdate Insurance',
    InsuranceRegularDriver = 'Change Regular Driver',
    InsuranceEndProduct = 'End Insurance',
    InsuranceEndDateProduct = 'End Date Insurance',
    InternationalMotorInsuranceCard = 'International Motor Insurance Card',
    TransferService = 'Insurance Transfer Service',
    CancelAddedProductModal = 'Cancel Added Product Modal',
    ChangeCoverage = 'Change Coverage',
    ReplaceCar = 'Replace Car Insurance',
    ExtraCar = 'Extra Car Insurance',
    ReplaceMotor = 'Extra Motor Insurance',
    ExtraMotor = 'Extra Motor Insurance',
    NewHome = 'New Home Insurance',
    NewCaravan = 'New Caravan Insurance',
    NewMoped = 'New Moped Insurance',
    NewBicycle = 'New Bicycle Insurance',
    ReportClaim = 'Report Claim',
    ReportLegalConflic = 'Report Legal Conflict',
    ReportMedicalClaim = 'Report Medical Claim',
    ReviveProductModal = 'Revive Product Modal',
}
