<wuc-modal
    [showClose]="false"
    [closeOnBackdropClick]="false"
    [isVisible]="cookieSettingsService.isCookieWallVisible()"
>
    <ng-content/>
    <div slot="footer">
        <ng-content select="[slot=footer]" />
    </div>
</wuc-modal>
