import { isPlatformServer } from '@angular/common';
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
} from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { AnalyticsActionService, CynolyticsTypeEnum } from 'outshared-lib';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { SalesfunnelService } from '@app-de/salesfunnel/salesfunnel.service';

@Injectable()
export class SalesfunnelErrorInterceptor implements HttpInterceptor {
    constructor(
        private salesfunnelService: SalesfunnelService,
        private readonly analyticsService: AnalyticsActionService,
        @Inject(PLATFORM_ID) private platformId: Object,
    ) {}

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (isPlatformServer(this.platformId)) {
            return next.handle(request);
        }

        return next.handle(request).pipe(
            tap((response: HttpResponse<any>) => {
                if (response.status === 200 && response.url.includes('/api/v2')) {
                    if (response.body.notifications) {
                        const { notifications } = response.body;
                        notifications.forEach((notification) => {
                            this.analyticsService.sendAnalytics({
                                event: CynolyticsTypeEnum.Notification,
                                eventCategory: 'foutmelding-backend',
                                eventAction: notification.notification_text,
                                eventLabel: notification.element_name,
                                eventValue: notification.notification_sequence_number,
                                cookieId: '',
                                productId: null,
                            });
                        });
                    }
                }
            }),
            catchError((error: HttpErrorResponse) => {
                if (this.salesfunnelService.isSalesfunnel(request.url)) {
                    this.analyticsService.sendAnalytics({
                        event: CynolyticsTypeEnum.Notification,
                        eventCategory: error.error.meldingen[0].soort,
                        eventAction: error.error.meldingen[0].boodschap_inhoud,
                        eventLabel: error.error.meldingen[0].boodschap_code,
                    });
                }
                return throwError(error);
            }),
        );
    }
}
