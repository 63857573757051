import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WucLinkModule, WucTableModule, WucTextHighlightModule } from '@inshared/website-ui-components';
import { NewModalModule } from 'outshared-lib';
import { PremiumImpactModal } from './premium-impact.modal';
import { ModalComponentsModule } from '@app-de/components';

@NgModule({
    declarations: [PremiumImpactModal],
    imports: [
        CommonModule,
        NewModalModule,
        ModalComponentsModule,
        WucTextHighlightModule,
        WucTableModule,
        WucLinkModule,
    ],
})
export class PremiumImpactModule {}
