export enum DocumentsEnum {
    AllgemeineBedingungenAutoversicherung = '/dokumente/download/Allgemeine_Bedingungen_Autoversicherung.pdf',
    AllgemeineBedingungenAutoversicherung_01_01_2025 = '/dokumente/download/Allgemeine_Bedingungen_Kraftfahrtversicherung_2025.pdf', 
    AnnualReward = '/dokumente/download/Reglement-Jahresbelohnung.pdf',
    Beschwerdeprozess = '/dokumente/download/Beschwerdeprozess.pdf',
    CookieOverview = '/dokumente/download/Cookieubersicht.pdf',
    Disclaimer = '/dokumente/download/Verzichtserklarung.pdf',
    EuropeanAccidentReportForm = '/dokumente/download/Europaeischer-Unfallbericht.pdf',
    InformationsblattZuVersicherungsprodukten = '/dokumente/download/Informationsblatt_zu_Versicherungsprodukten_InShared.pdf',
    Informationspflichtenverordnung = '/dokumente/download/Informationspflichtenverordnung.pdf',
    InsuranceFraud = '/dokumente/download/Umgang-mit-Versicherungsbetrug.pdf',
    InsurancePolicy = '/dokumente/download/Versicherungsschein.pdf',
    KategorienEmpfangernPersonenbezogenenDaten = '/dokumente/download/Empfangern_personenbezogenen_Daten.pdf',
    NutzungsbedingungenOnlineVersicherungsmappe = '/dokumente/download/Nutzungsbedingungen_Online-Versicherungsmappe.pdf',
    PrivacyDocument = '/dokumente/download/Datenschutzinformationen.pdf',
    SFClassesFullyComprehensive = '/dokumente/download/Ruckstufungstabelle SF-Klassen Vollkasko.pdf',
    SFClassesMotorVehicleLiability = '/dokumente/download/Ruckstufungstabelle SF-Klassen Kfz-Haftpflicht.pdf',
    SFClassesNoClaimDiscount = '/dokumente/download/Einstufung in SF-klassen en Beitragssätze.pdf',
    VorvertraglicheAnzeigepflicht = '/dokumente/download/Die_vorvertragliche_Anzeigepflicht.pdf',
    Widerrufsbelehrung = '/dokumente/download/Widerrufsbelehrung.pdf',
}
