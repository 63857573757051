import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
    WucButtonModule,
    WucCardModule,
    WucFormErrorModule,
    WucFormFieldLabelModule,
    WucFormFieldModule,
    WucInputCheckboxModule,
    WucInputDateModule,
    WucInputDatePickerModule,
    WucInputRadioModule,
    WucInputReadOnlyModule,
    WucInputTextModule,
    WucLinkModule,
    WucSelectModule,
} from '@inshared/website-ui-components';
import { TerminateInsuranceComponent } from './terminate-insurance.component';
import { TerminateInsuranceContainer } from '@app-de/my-zone/shared/terminate-insurance/terminate-insurance.container';
import { TerminateInsuranceFeedbackModule } from '@app-de/my-zone/shared/terminate-insurance-feedback/terminate-insurance-feedback.module';
import { DateFormatPipeModule } from '@app-de/shared/pipes/date-format/date-format.module';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        TerminateInsuranceFeedbackModule,
        DateFormatPipeModule,
        WucInputRadioModule,
        WucInputTextModule,
        WucFormFieldModule,
        WucFormFieldLabelModule,
        WucInputCheckboxModule,
        WucInputDateModule,
        WucInputDatePickerModule,
        WucButtonModule,
        WucInputReadOnlyModule,
        WucFormErrorModule,
        WucCardModule,
        WucLinkModule,
        WucSelectModule,
    ],
    exports: [TerminateInsuranceContainer],
    declarations: [TerminateInsuranceComponent, TerminateInsuranceContainer],
})
export class TerminateInsuranceModule {}
