import { Component, HostBinding, Input } from '@angular/core';
import { DebitCreditEnum } from 'outshared-lib';

@Component({
    selector: 'ins-invoice-price',
    templateUrl: 'invoice-price.component.html',
    styleUrls: ['invoice-price.component.scss'],
})
export class InvoicePriceComponent {
    @Input() public debitCredit: DebitCreditEnum;
    @Input() public price: number;

    @HostBinding('class.invoice-price') public hostClass = true;
    @HostBinding('class.invoice-price--highlight') @Input() public highlight = false;

    public debitCreditEnum = DebitCreditEnum;
}
