import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WucFormErrorModule, WucLinkModule } from '@inshared/website-ui-components';
import { DetailsDeleteButtonModule } from '../../details-delete-button/details-delete-button.module';
import { DetailsTableModule } from '../../details-table/details-table.module';
import { InsuranceRegularDriverFutureComponent } from './insurance-regular-driver-future.component';
import { DateFormatPipeModule } from '@app-de/shared/pipes/date-format/date-format.module';

@NgModule({
    declarations: [InsuranceRegularDriverFutureComponent],
    imports: [
        CommonModule,
        DetailsTableModule,
        DetailsDeleteButtonModule,
        DateFormatPipeModule,
        WucLinkModule,
        WucFormErrorModule,
    ],
    exports: [InsuranceRegularDriverFutureComponent],
})
export class InsuranceRegularDriverFutureModule {}
