import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MobileMenuMyZoneComponent } from './mobile-menu-my-zone.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
    ],
    exports: [MobileMenuMyZoneComponent],
    declarations: [MobileMenuMyZoneComponent],
})
export class MobileMenuMyZoneModule {}
