<div class="mobile-menu active" [ngClass]="{ active: active }">
    <ul class="mobile-menu-list">
        <li class="mobile-menu-item" [ngClass]="{ active: homeActive }">
            <a class="mobile-menu-link" [routerLink]="['/']" (click)="closeMenu()">
                <svg class="svg-icon svg-icon--s"><use href="assets/icons-ui.svg#huis"></use></svg>
                <span class="mobile-menu-description">Home</span>
            </a>
        </li>
        <li class="mobile-menu-item">
            <a
                class="mobile-menu-link"
                [routerLink]="['/', appRoutes.MyZone, myZoneRoutes.MyInsurances]"
                (click)="closeMenu()"
                routerLinkActive="mobile-menu-link--active"
            >
                <svg class="svg-icon svg-icon--s"><use href="assets/icons-ui.svg#folder"></use></svg>
                <span class="mobile-menu-description">Meine Versicherungen</span>
            </a>
        </li>
        <li class="mobile-menu-item">
            <a class="mobile-menu-link" href="#" (click)="toggle()">
                <ng-container *ngIf="!active">
                    <svg class="svg-icon svg-icon--s"><use href="assets/icons-ui.svg#menu"></use></svg>
                </ng-container>
                <ng-container *ngIf="active">
                    <svg class="svg-icon svg-icon--s"><use href="assets/icons-ui.svg#close"></use></svg>
                </ng-container>
                <span class="mobile-menu-description">Menu</span>
            </a>
        </li>
        <li class="mobile-menu-item">
            <a
                class="mobile-menu-link"
                [routerLink]="['/', appRoutes.MyZone, myZoneRoutes.Details]"
                (click)="closeMenu()"
                routerLinkActive="mobile-menu-link--active"
            >
                <svg class="svg-icon svg-icon--s"><use href="assets/icons-ui.svg#gebruiker"></use></svg>
                <span class="mobile-menu-description">Meine Angaben</span>
            </a>
        </li>
        <li class="mobile-menu-item">
            <a
                class="mobile-menu-link"
                [routerLink]="['/', appRoutes.MyZone, myZoneRoutes.TermsAndConditions]"
                (click)="closeMenu()"
                routerLinkActive="mobile-menu-link--active"
            >
                <svg class="svg-icon svg-icon--s"><use href="assets/icons-ui.svg#formulier"></use></svg>
                <span class="mobile-menu-description">Meine Dokumente</span>
            </a>
        </li>
        <li class="mobile-menu-item" (click)="closeMenu()">
            <a class="mobile-menu-link" [routerLink]="['/', appRoutes.Contact]">
                <svg class="svg-icon svg-icon--s"><use href="assets/icons-ui.svg#mail"></use></svg>
                <span class="mobile-menu-description">Kontakt</span>
            </a>
        </li>
    </ul>
</div>
