import { Injectable } from '@angular/core';
import { CookieConsent } from '../../core';
import {
    CynolyticsEventInterface,
    CynolyticsNotificationInterface,
    CynolyticsPageviewInterface,
    DatalayerInterface,
} from '../interfaces';
import { dataLayerToCynolyticsEventMapper } from '../mappers/cynolytics.request.mapper';
import { datalayerEventMapper } from '../mappers/datalayer.event.mapper';
import { CynolyticsApiService } from './cynolytics.api.service';
import { DatalayerApiService } from './datalayer/datalayer.api.service';

@Injectable({
    providedIn: 'root',
})
export class AnalyticsActionService {
    constructor(private cynolyticsService: CynolyticsApiService, private datalayerService: DatalayerApiService) { }

    private isDatalayerData(
        data:
            | DatalayerInterface
            | CynolyticsEventInterface
            | CynolyticsNotificationInterface
            | CynolyticsPageviewInterface
    ): data is DatalayerInterface {
        return (data as DatalayerInterface).event !== undefined;
    }

    public updateCookieSettings(cookieConsent: CookieConsent) {
        return this.datalayerService.setCookieConsent(cookieConsent);
    }

    public sendAnalytics(data: DatalayerInterface): void {
        this.sendToDatalayer(data);
        this.sendToCynolytics(data);
    }

    public sendToDatalayer(data: DatalayerInterface): void {
        this.datalayerService.send(datalayerEventMapper(data));
    }

    public sendToCynolytics(
        data:
            | DatalayerInterface
            | CynolyticsEventInterface
            | CynolyticsNotificationInterface
            | CynolyticsPageviewInterface
    ): void {
        this.isDatalayerData(data)
            ? this.cynolyticsService.send(dataLayerToCynolyticsEventMapper(data))
            : this.cynolyticsService.send(data);
    }
}
