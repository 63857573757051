import { Component, Input } from '@angular/core';

@Component({
    selector: 'ins-cat-treadmill-animation',
    templateUrl: './cat-treadmill-animation.component.html',
    styleUrls: ['./cat-treadmill-animation.component.scss'],
})
export class CatTreadmillAnimationComponent {
    @Input() public reverse: boolean = false;
    @Input() public speed: 'slow' | 'fast';
    @Input() public small: boolean = false;
}
