import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WucAlertModule, WucButtonModule, WucIconModule } from '@inshared/website-ui-components';
import { OutProductScenariosModule } from 'outshared-lib';
import {
    MyZoneNotificationsContainer,
    NoEntryDateNotificationComponent,
    NoEntryDateNotificationContainer,
    OptInNotificationComponent,
    OptInNotificationContainer,
    NoDamageFreeYearsNotificationComponent,
    NoDamageFreeYearsNotificationContainer,
    IncompleteInformationNotificationComponent,
} from './';

@NgModule({
    declarations: [
        NoEntryDateNotificationContainer,
        NoEntryDateNotificationComponent,
        NoDamageFreeYearsNotificationContainer,
        NoDamageFreeYearsNotificationComponent,
        MyZoneNotificationsContainer,
        OptInNotificationComponent,
        OptInNotificationContainer,
        IncompleteInformationNotificationComponent,
    ],
    exports: [MyZoneNotificationsContainer],
    imports: [CommonModule, OutProductScenariosModule, WucAlertModule, WucButtonModule, WucIconModule],
})
export class MyZoneNotificationsModule {}
