import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from '../../core';
import { ApiLoadProductScenarioResponseInterface } from '../interfaces/api-load-product-scenario-response.interface';
import { LoadProductScenarioResponseInterface } from '../interfaces/load-product-scenario-response.interface';
import { mapLoadProductScenariosResponse } from '../mappers/product-scenarios.mapper';

const RESOURCES = {
    LOAD_PRODUCT_SCENARIOS: 'en-gb/relation-general/request/scenarios',
};

@Injectable({
    providedIn: 'root',
})
export class ProductScenariosApiService {
    constructor(private apiService: ApiService) {}

    public loadProductScenarios$(): Observable<LoadProductScenarioResponseInterface[]> {
        return this.apiService
            .post$<ApiLoadProductScenarioResponseInterface>(RESOURCES.LOAD_PRODUCT_SCENARIOS, null)
            .pipe(map(mapLoadProductScenariosResponse));
    }
}
