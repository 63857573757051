import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import {
    AuthenticationActionService,
    AuthenticationDataService,
    NewModal as Modal,
    RelationDataService,
    RelationInterface,
} from 'outshared-lib';
import { Observable, Subscription } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { LogoutModal } from '@app-de/modals/logout-modal/logout.modal';
import { MyZoneRoutes } from '@app-de/my-zone/routing/my-zone-routing.routes';
import { AppRoutesEnum } from '@app-de/routes';
import { SalesfunnelService } from '@app-de/salesfunnel/salesfunnel.service';

@Component({
    selector: 'ins-authentication-menu',
    templateUrl: './authentication-menu.component.html',
    styleUrls: ['./authentication-menu.component.scss'],
})
export class AuthenticationMenuComponent implements OnDestroy {
    public loggedIn$: Observable<boolean>;
    public showUser: boolean;
    public name?: string;
    public relation: RelationInterface;

    public appRoutesEnum = AppRoutesEnum;
    public myZoneRoutes = MyZoneRoutes;

    private subscriptions!: Subscription;

    constructor(
        private router: Router,
        private authenticationActionService: AuthenticationActionService,
        private authenticationDataService: AuthenticationDataService,
        private relationDataService: RelationDataService,
        private salesfunnelService: SalesfunnelService,
        private modal: Modal,
    ) {
        this.initialize();
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    public onLogout(event: Event): void {
        event.preventDefault();

        // TODO: Doorlopende inlogtoken check toevoegen
        this.modal
            .open(LogoutModal)
            .afterClosed$()
            .pipe(filter((logoutConfirmed) => !!logoutConfirmed))
            .subscribe(() => this.logoutAction());
    }

    private logoutAction(): void {
        this.authenticationActionService.logout$().subscribe(
            () => this.handleLogout(),
            () => this.handleLogout(),
        );
    }

    private initialize(): void {
        this.loggedIn$ = this.authenticationDataService.initializationPending$().pipe(
            filter((pending: boolean) => !pending),
            switchMap(() => this.authenticationDataService.isLoggedIn$().pipe(map((loggedIn: boolean) => loggedIn))),
        );

        this.subscriptions = new Subscription();

        const relationSubscription = this.relationDataService
            .getRelation$()
            .subscribe(
                (relation?: RelationInterface) =>
                    (this.name = !!relation ? `${relation.first_name} ${relation.surname}` : null),
            );

        this.subscriptions.add(relationSubscription);
    }

    private handleLogout(): void {
        if (this.salesfunnelService.isSalesfunnel(this.router.routerState.snapshot.url)) {
            return; // we don't navigate away in salesfunnel, it will react to relation
        }

        this.router.navigate([AppRoutesEnum.Logout]);
    }
}
