import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import {
    CoveragePremiumActionService,
    CoveragePremiumInterface,
    CoveragePremiumSelectorService,
    ProductBaseInterface,
    ProductSelectorService,
} from 'outshared-lib';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class CoveragePremiumResolver implements Resolve<boolean> {
    private products$: Observable<ProductBaseInterface[]>;
    private coverages$: Observable<CoveragePremiumInterface[]>;

    constructor(
        private productSelectorService: ProductSelectorService,
        private coveragePremiumSelectorService: CoveragePremiumSelectorService,
        private coveragePremiumActionService: CoveragePremiumActionService,
        @Inject(PLATFORM_ID) private platformId: object,
    ) {}

    public resolve(route: ActivatedRouteSnapshot): Observable<boolean> {
        if (isPlatformServer(this.platformId)) {
            return of(true);
        }
        this.products$ = this.productSelectorService.getActiveProducts$;
        this.coverages$ = this.coveragePremiumSelectorService.getAllCoverages$;

        // If products and no coverages => load coverages
        combineLatest([this.products$, this.coverages$])
            .pipe(
                filter(([products, coverages]) => products.length > 0 && coverages.length < 1),
                take(1),
            )
            .subscribe(([products]) => this.loadCoverages(products));

        return of(true);
    }

    private loadCoverages(products: ProductBaseInterface[]): void {
        products.forEach(({ purchased_product_sequence_number }) =>
            this.loadCoverage(purchased_product_sequence_number),
        );
    }

    private loadCoverage(purchasedProductSequenceNumber: number): void {
        this.coveragePremiumActionService
            .load({ purchased_product_sequence_number: purchasedProductSequenceNumber })
            .pipe(take(1))
            .subscribe();
    }
}
