import { Component, Inject, OnInit } from '@angular/core';
import { NewModalRef as ModalRef, NEW_MODAL_DATA as MODAL_DATA } from 'outshared-lib';

@Component({
    selector: 'ins-chatbot-modal',
    templateUrl: './chatbot-modal.component.html',
    styleUrls: ['./chatbot-modal.component.scss'],
})
export class ChatbotModalComponent implements OnInit {
    public isFullScreen: boolean = false;

    constructor(private modalRef: ModalRef<ChatbotModalComponent>, @Inject(MODAL_DATA) public question: string) {}

    public ngOnInit(): void {
        this.isFullScreen = this.modalRef._containerInstance._config.fullScreen;
    }

    public close(): void {
        this.modalRef.close();
    }
}
