import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from '../../core';
import {
    ApiDamageFreeYearsImpactRequest,
    ApiDamageFreeYearsImpactResponse,
    DamageFreeYearsImpactRequestInterface,
    DamageFreeYearsImpactResponseInterface,
} from '../interfaces';
import { mapDamageFreeYearsImpactRequest, mapDamageFreeYearsImpactResponse } from '../mappers';

const RESOURCES = {
    DAMAGE_FREE_YEARS_IMPACT: 'en-gb/insurance-product/request/impact-schadevrijejaren',
};

@Injectable({
    providedIn: 'root',
})
export class DamageFreeYearsImpactApiService {
    constructor(private apiService: ApiService) {}

    public damageFreeYearsImpact$(
        payload: DamageFreeYearsImpactRequestInterface
    ): Observable<DamageFreeYearsImpactResponseInterface> {
        const _payload: ApiDamageFreeYearsImpactRequest = mapDamageFreeYearsImpactRequest(payload);
        return this.apiService
            .post$<ApiDamageFreeYearsImpactResponse>(RESOURCES.DAMAGE_FREE_YEARS_IMPACT, _payload)
            .pipe(map(mapDamageFreeYearsImpactResponse));
    }
}
