import { Injectable } from '@angular/core';
import { CalculationService, Coverage, DateService, PremiumProduct , CoveragePeriod, Insurance, InsuranceService } from 'outshared-lib';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { CoverageIdEnum } from '@app-de/core/enums';
import { productReducer } from '@app-de/core/helpers/premium';
import { SalesfunnelBackendHelper } from '@app-de/salesfunnel/services/salesfunnel-backend.helper';


@Injectable({
    providedIn: 'root',
})
export class CalculatePremiumService {
    public coverageIdFromToken$: BehaviorSubject<string> = new BehaviorSubject(null);

    public modules$: Observable<{ [key: string]: Coverage }> = combineLatest([
        this.calculationService.calculation$,
        this.insuranceService.currentInsuranceData$,
    ]).pipe(
        filter(([result]) => !!result),
        map(([result, insurance]) => this.mapProduct(result.product, insurance)),
    );

    public mapping: { [key: string]: { [key: string]: string[] } } = {
        base_coverages: {
            KFZH: [CoverageIdEnum.KfzHaftpflicht],
            TLKK: [CoverageIdEnum.KfzHaftpflicht, CoverageIdEnum.Teilkasko],
            VLKK: [CoverageIdEnum.KfzHaftpflicht, CoverageIdEnum.Vollkasko],
        },
    };

    public baseCoverages: string[] = [
        CoverageIdEnum.KfzHaftpflicht,
        CoverageIdEnum.Teilkasko,
        CoverageIdEnum.Vollkasko,
    ];

    public additionalCoverages: string[] = [
        CoverageIdEnum.MallorcaPolice,
        CoverageIdEnum.Fahrerschutz,
        CoverageIdEnum.Pannenhilfe,
    ];

    constructor(
        private calculationService: CalculationService,
        private readonly dateService: DateService,
        private insuranceService: InsuranceService,
    ) {}

    public mapProduct(product: PremiumProduct, insurance: Insurance): { [key: string]: Coverage } {
        const chosenPeriod = product.invoice_periods.find(
            (coveragePeriod: CoveragePeriod) =>
                coveragePeriod.invoice_period_months_number === insurance.premium_period_amount,
        );

        const xsell = product.xsell || [];

        const modules: Coverage[] = [
            ...(chosenPeriod ? chosenPeriod.base_coverages : product.base_coverages),
            ...(chosenPeriod ? chosenPeriod.additional_coverage : product.additional_coverage),
            ...xsell.reduce((acc, curr) => [...acc, ...curr.coverages], []),
        ];

        return productReducer(modules, this.mapping);
    }

    // Convert selected modules to the corresponding backend modules
    public preSubmit(values: { [key: string]: string }): string[] {
        return SalesfunnelBackendHelper.mapToBackend(values, this.mapping);
    }

    public prePatch<T>(values, selectedCoverages: string[]): T {
        return SalesfunnelBackendHelper.mapFromBackend(values, selectedCoverages, this.mapping);
    }

    public getMostPopular(buildYear: number): string {
        const ageVehicle = buildYear ? this.dateService.calculateAge({ year: +buildYear }) : null;

        if (ageVehicle < 8) {
            return CoverageIdEnum.Vollkasko;
        }

        if (ageVehicle >= 9 && ageVehicle <= 16) {
            return CoverageIdEnum.Teilkasko;
        }

        if (ageVehicle >= 17) {
            return CoverageIdEnum.KfzHaftpflicht;
        }
        return CoverageIdEnum.KfzHaftpflicht;
    }
}
