import { Injectable } from '@angular/core';
import Pikaday from 'pikaday';
import { pickadayConfig } from '@app-de/core/services/pickaday.config';

@Injectable({
    providedIn: 'root',
})
export class PikadayService {

    private config = pickadayConfig.germany;

    public async getInstance(options: Pikaday.PikadayOptions): Promise<Pikaday> {
        return await import('pikaday').then((pikaday) => {
            return new pikaday.default({
                format: this.config.format,
                i18n: this.config.i18n,
                ...options,
            });
        });
    }
}
