import { Component } from '@angular/core';
import { NewModalRef as ModalRef } from 'outshared-lib';

@Component({
    templateUrl: 'cancel-insurance.modal.html',
})
export class CancelInsuranceModal {
    constructor(private modal: ModalRef<CancelInsuranceModal>) {}

    public onCancel(): void {
        this.modal.close();
    }

    public onConfirm(): void {
        this.modal.close(true);
    }
}
