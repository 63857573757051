import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
    WucLinkModule,
    WucListModule,
    WucNavigationModule,
    WucNavigationItemModule,
    WucRackModule,
} from '@inshared/website-ui-components';
import { NavigationComponent } from './navigation.component';

@NgModule({
    declarations: [NavigationComponent],
    imports: [
        CommonModule,
        RouterModule,
        WucLinkModule,
        WucListModule,
        WucNavigationItemModule,
        WucNavigationModule,
        WucRackModule,
    ],
    exports: [
        NavigationComponent,
        RouterModule,
    ],
})
export class NavigationModule {
}
