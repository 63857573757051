import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DetailsSubmitButtonModule } from '../../details-submit-button/details-submit-button.module';
import { CorrespondenceAddressFormComponent } from './correspondence-address-form.component';
import { CorrespondenceAddressFormContainer } from './correspondence-address-form.container';
import { AlertModule } from '@app-de/components/alert/alert.module';
import { AddressAutocompleteModule } from '@app-de/shared/address-autocomplete/address-autocomplete.module';
import { CynoFormModule } from '@app-de/shared/cyno-form';
import { DatepickerModule } from '@app-de/shared/datepicker/datepicker.module';

@NgModule({
    declarations: [
        CorrespondenceAddressFormComponent,
        CorrespondenceAddressFormContainer,
    ],
    exports: [CorrespondenceAddressFormContainer],
    imports: [
        CommonModule,
        CynoFormModule,
        DatepickerModule,
        AddressAutocompleteModule,
        AlertModule,
        DetailsSubmitButtonModule,
    ],
})
export class CorrespondenceAddressFormModule {
}
