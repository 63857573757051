import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RelationActionService, RelationDataService, RelationInterface } from 'outshared-lib';
import { Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { DetailsFormClass } from '@app-de/my-zone/classes';

@Component({
    selector: 'ins-recovery-settings-form-container',
    template: `
        <ins-recovery-settings-form
            [relation]="relation"
            [disabled]="disabled"
            [errors]="errors"
            (submitted)="onSubmit($event)"
        ></ins-recovery-settings-form>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecoverySettingsFormContainer extends DetailsFormClass implements OnDestroy, OnInit {
    public relation: RelationInterface;
    private subscriptions;

    constructor(
        private relationActionService: RelationActionService,
        private relationDataService: RelationDataService,
        router: Router,
        changeDetectorRef: ChangeDetectorRef,
    ) {
        super(changeDetectorRef, router);
    }

    public ngOnInit(): void {
        const getRelation = this.relationDataService
            .getRelation$()
            .pipe(
                filter((relation: RelationInterface) => !!relation),
                take(1),
            )
            .subscribe((relation) => (this.relation = relation));

        this.subscriptions = new Subscription();
        this.subscriptions.add(getRelation);
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    public onSubmit(payload: { mobilePhoneNumber: string }): void {
        const updatePersonalDetails = this.relationActionService
            .updatePersonalDetails$({
                mobileTelephoneNumber: {
                    telephoneNumber: payload.mobilePhoneNumber,
                },
            })
            .subscribe(
                () => this.handleSuccess(),
                (errors) => this.handleErrors(errors),
            );

        this.beforeOnSubmit();
        this.subscriptions.add(updatePersonalDetails);
    }
}
