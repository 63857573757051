<table class="table-data" *ngIf="(selectedProduct | async)?.additional_driver as additionalDriver">
    <colgroup>
        <col width="33%" />
        <col width="33%" />
        <col width="33%" />
    </colgroup>
    <tbody>
    <tr>
        <td>Geburtsdatum jüngster <br /> Fahrer</td>
        <td>{{ additionalDriver.birthdate  | dateFormat }}</td>
        <td>
        </td>
    </tr>
    </tbody>
</table>
