import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DetailsTableComponent } from './details-table.component';

@NgModule({
    declarations: [DetailsTableComponent],
    exports: [DetailsTableComponent],
    imports: [CommonModule],
})
export class DetailsTableModule { }
