import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DetailsTableModule } from '../details-table/details-table.module';
import { PaymentDetailsDataComponent } from './payment-details-data/payment-details-data.component';
import { PaymentDetailsFormComponent } from './payment-details-form-component/payment-details-form.component';
import { PaymentDetailsFormContainer } from './payment-details-form-container/payment-details-form.container';
import { DetailsSubmitButtonModule } from '@app-de/my-zone/shared/details-submit-button';
import { ElementValidateErrorsModule } from '@app-de/salesfunnel/shared/element-validate-errors/element-validate-errors.module';
import { CynoFormModule } from '@app-de/shared/cyno-form/cyno-form.module';
import { ReadMoreModule } from '@app-de/shared/directives/read-more';

@NgModule({
    declarations: [PaymentDetailsDataComponent, PaymentDetailsFormContainer, PaymentDetailsFormComponent],
    exports: [PaymentDetailsDataComponent, PaymentDetailsFormContainer],
    imports: [
        CommonModule,
        DetailsTableModule,
        ReactiveFormsModule,
        CynoFormModule,
        DetailsSubmitButtonModule,
        ElementValidateErrorsModule,
        ReadMoreModule,
    ],
})
export class PaymentDetailsModule {}
