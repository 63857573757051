import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { InvoicePriceModule } from '../invoice-price/invoice-price.module';
import { InvoiceTotalComponent } from './invoice-total.component';
import { CardModule } from '@app-de/shared/card/card.module';
import { MobileScrollModule } from '@app-de/shared/mobile-scroll/mobile-scroll.module';
import { DateFormatPipeModule } from '@app-de/shared/pipes/date-format/date-format.module';
import { RackModule } from '@app-de/shared/rack/rack.module';

@NgModule({
    imports: [CommonModule, CardModule, RackModule, InvoicePriceModule, DateFormatPipeModule, MobileScrollModule],
    exports: [InvoiceTotalComponent],
    declarations: [InvoiceTotalComponent],
})
export class InvoiceTotalModule { }
