import { ApiGetPaymentProcessPayloadRequestInterface, GetPaymentProcessPayloadRequestInterface } from '../interfaces';

export function mapPaymentProcessRequest(
    appData: GetPaymentProcessPayloadRequestInterface
): ApiGetPaymentProcessPayloadRequestInterface {
    return {
        relation_id: appData.relationId,
        amount: appData.amount,
    };
}
