import { Component, Input } from '@angular/core';
import { DebitCreditEnum, InvoiceDetailsInterface } from 'outshared-lib';

@Component({
    selector: 'ins-invoice-total',
    templateUrl: 'invoice-total.component.html',
})
export class InvoiceTotalComponent {
    @Input() public invoiceDetails: InvoiceDetailsInterface;
    public debitCreditEnum = DebitCreditEnum;
}
