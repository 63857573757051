import { DetailsSectionEnum } from '@app-de/core/enums/detail-section.enum';
import { MyZoneRoutes } from '@app-de/my-zone/routing/my-zone-routing.routes';
import { ControlButtonIdsEnum } from '@app-de/my-zone/shared/insurance-control/shared/enums/control-button-ids.enum';
import { ControlCategoryEnum } from '@app-de/my-zone/shared/insurance-control/shared/enums/control-category.enum';
import { ControlButtonInterface } from '@app-de/my-zone/shared/insurance-control/shared/interfaces/control-button.interface';
import { ModalControlAction, NavigateControlAction } from '@app-de/my-zone/shared/insurance-control/shared/models';
import { DeceaseModal } from '@app-de/my-zone/shared/modals/decease-modal/decease.modal';
import { AppRoutesEnum } from '@app-de/routes';

export const controlButtons: ControlButtonInterface[] = [
    // WIDL-1254: Temporary disabled
    // {
    //     id: ControlButtonIdsEnum.ChangeLoginInformation,
    //     order: 30,
    //     name: 'Zugangsdaten ändern',
    //     action: new NavigateControlAction({
    //         uri: ['/', AppRoutesEnum.MyZone, MyZoneRoutes.Details],
    //         params: true,
    //         hashParam: DetailsSectionEnum.EmailPanel,
    //     }),
    //     icon: 'inlog-aanpassen',
    //     category: ControlCategoryEnum.DirectlyTo,
    //     status: ControlStatusEnum.Always,
    // },
    // WIDL-1254: Temporary disabled
    {
        id: ControlButtonIdsEnum.ChangePersonalDetails,
        order: 100,
        name: 'Persönliche Angaben ändern',
        action: new NavigateControlAction({
            uri: ['/', AppRoutesEnum.MyZone, MyZoneRoutes.Details],
            params: true,
            hashParam: DetailsSectionEnum.PersonalDetailsForm,
        }),
        icon: 'persoongegevens-bewerken',
        category: ControlCategoryEnum.PersonalOrFamily,
    },
    {
        id: ControlButtonIdsEnum.AddKidsToInsurance,
        order: 110,
        name: 'Kind(er) mitversichern',
        action: new NavigateControlAction({
            uri: ['/', AppRoutesEnum.MyZone, MyZoneRoutes.Details],
            params: true,
            expand: true,
            hashParam: DetailsSectionEnum.FamilyPanel,
        }),
        icon: 'kinderen-toevoegen',
        category: ControlCategoryEnum.PersonalOrFamily,
    },
    {
        id: ControlButtonIdsEnum.Marriage,
        order: 120,
        name: 'Heirat',
        action: new NavigateControlAction({
            uri: ['/', AppRoutesEnum.MyZone, MyZoneRoutes.Details],
            params: true,
            expand: true,
            hashParam: DetailsSectionEnum.FamilyPanel,
        }),
        icon: 'trouwen',
        category: ControlCategoryEnum.PersonalOrFamily,
    },
    {
        id: ControlButtonIdsEnum.Breakup,
        order: 140,
        name: 'Trennung',
        action: new NavigateControlAction({
            uri: ['/', AppRoutesEnum.MyZone, MyZoneRoutes.Details],
            params: true,
            expand: true,
            hashParam: DetailsSectionEnum.FamilyPanel,
        }),
        icon: 'uit-elkaar',
        category: ControlCategoryEnum.PersonalOrFamily,
    },
    {
        id: ControlButtonIdsEnum.Deceased,
        order: 140,
        name: 'Sterbefall',
        action: new ModalControlAction({
            modalComponent: DeceaseModal,
            confirmation: 'Sluiten',
        }),
        icon: 'overlijden',
        category: ControlCategoryEnum.PersonalOrFamily,
    },
    // TODO WIDL-1025: Remove the comments below to activate this button whenever the download implemention is available
    // {
    //     id: ControlButtonIdsEnum.PBDL,
    //     order: 160,
    //     name: 'Zus Versicherugsübersicht',
    //     action: new DownloadControlAction({
    //         fileName: 'J',
    //         type: ControlDownloadTypesEnum.Polis
    //     }),
    //     icon: 'document-inzien',
    //     category: ControlCategoryEnum.PRNT,
    //     status: ControlStatusEnum.ALWZ
    // },
    {
        id: ControlButtonIdsEnum.TermsAndConditions,
        order: 190,
        name: 'Allgemeine Bedingungen Ihrer Versicherungen',
        action: new NavigateControlAction({
            uri: ['/', AppRoutesEnum.MyZone, MyZoneRoutes.TermsAndConditions],
        }),
        icon: 'document-inzien',
        category: ControlCategoryEnum.ViewOrPrint,
    },
];
