import { Component, Input } from '@angular/core';
import { SmartTipsImageEnum } from '@app-de/pages/smart-tips/smart-tips-image.enum';

@Component({
    selector: 'ins-banner',
    templateUrl: './banner.component.html',
    styleUrls: ['./banner.component.scss'],
})
export class BannerComponent {
    @Input() public size: 'large' | 'small' = 'large';
    @Input() public image: SmartTipsImageEnum;
    @Input() public imageAlt: string;
    @Input() public imageTitle: string;
    @Input() public imagePosition: string;

    public getImageUrl(format: string): string {
        return `/assets/shared/smart-tips/${this.image}-${format}`;
    }
}
