<div class="topbar">
    <a id="topbar_brand" class="topbar__brand" routerLink="/">
        <picture *ngIf="isValentinesDay">
            <source
                type="image/webp"
                [attr.defaultImage]="'/assets/default-image-1x1.png'"
                [attr.lazyLoad]="'/assets/shared/logo-valentijn-small.webp'"
            />
            <!-- IE11 fallback -->
            <img
                src="/assets/default-image-1x1.png"
                lazyLoad="/assets/shared/logo-valentijn-small.png"
                class="topbar__logo"
                alt="InShared"
            />
        </picture>
        <svg
            *ngIf="!isValentinesDay"
            aria-label="inshared"
            class="topbar__logo"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 237 60"
        >
            <title>InShared</title>
            <path d="M-1-1H238V61H-1Z" style="fill: none" />
            <path
                d="M2.13,15.18A2.87,2.87,0,0,1,3,13.08a2.91,2.91,0,0,1,2.13-.89,3,3,0,0,1,2.15.89,3,3,0,0,1,0,4.27,2.91,2.91,0,0,1-2.14.89A2.91,2.91,0,0,1,3,17.35,3,3,0,0,1,2.13,15.18Zm5.34,8.36v20.1H2.83V23.54Z"
                style="fill: #f173ac"
            />
            <path
                d="M13.35,23.54H18V25.4A7.59,7.59,0,0,1,23.5,23,6.91,6.91,0,0,1,29,25.2q1.7,1.88,1.69,6.13V43.65H26V32.42a7.36,7.36,0,0,0-.82-4.1,3.33,3.33,0,0,0-2.93-1.16A3.57,3.57,0,0,0,19,28.69Q18,30.19,18,33.94v9.68H13.36V23.54Z"
                style="fill: #f173ac"
            />
            <path
                d="M48.2,27l-3.84,2c-.6-1.22-1.36-1.84-2.25-1.84a1.53,1.53,0,0,0-1.09.42,1.42,1.42,0,0,0-.46,1.08c0,.77.89,1.53,2.68,2.29a15.86,15.86,0,0,1,5,2.93,5,5,0,0,1,1.28,3.61,6.33,6.33,0,0,1-2.14,4.87,7.3,7.3,0,0,1-5,1.85q-5.06,0-7.16-4.93l4-1.84a8.94,8.94,0,0,0,1.26,1.84,2.88,2.88,0,0,0,2,.78c1.57,0,2.35-.71,2.35-2.15,0-.82-.61-1.59-1.82-2.31l-1.4-.68-1.42-.68a8.71,8.71,0,0,1-2.85-2,4.89,4.89,0,0,1-1-3.24A5.71,5.71,0,0,1,38,24.69,6.3,6.3,0,0,1,42.46,23Q46.33,23,48.2,27Z"
                style="fill: #888780"
            />
            <path
                d="M53.67,8.79h4.64V25.21A8,8,0,0,1,63.78,23a7.06,7.06,0,0,1,5.45,2.21Q71,27.11,71,31.32V43.64H66.33V31.75a5.67,5.67,0,0,0-.86-3.5,3.31,3.31,0,0,0-2.76-1.08,3.8,3.8,0,0,0-3.43,1.51c-.65,1-1,2.76-1,5.24v9.72H53.67Z"
                style="fill: #888780"
            />
            <path
                d="M91.66,23.54h4.66v20.1H91.66V41.53A8.92,8.92,0,0,1,78.6,41.2a11.21,11.21,0,0,1-2.7-7.67A10.87,10.87,0,0,1,78.6,26a8.71,8.71,0,0,1,6.77-3,8.51,8.51,0,0,1,6.29,2.89Zm-11,10a7,7,0,0,0,1.55,4.7,5,5,0,0,0,4,1.84,5.31,5.31,0,0,0,4.17-1.78A6.86,6.86,0,0,0,92,33.62,6.86,6.86,0,0,0,90.36,29a5.26,5.26,0,0,0-4.13-1.8,5.12,5.12,0,0,0-4,1.82A6.71,6.71,0,0,0,80.64,33.52Z"
                style="fill: #888780"
            />
            <path
                d="M102.18,23.54h4.64v1.79a8.94,8.94,0,0,1,2.27-1.84,5.23,5.23,0,0,1,2.39-.51,7.53,7.53,0,0,1,3.83,1.19l-2.12,4.25a4.45,4.45,0,0,0-2.58-1q-3.8,0-3.8,5.74V43.63h-4.64V23.54Z"
                style="fill: #888780"
            />
            <path
                d="M136,34.68H121.6a6.09,6.09,0,0,0,1.61,3.94,4.89,4.89,0,0,0,3.65,1.45,4.75,4.75,0,0,0,2.87-.82,11.79,11.79,0,0,0,2.54-3.06l3.92,2.18A14.84,14.84,0,0,1,134.27,41a10.19,10.19,0,0,1-2.17,1.82,8.54,8.54,0,0,1-2.49,1,12.23,12.23,0,0,1-2.91.33,9.43,9.43,0,0,1-7.22-2.89,10.81,10.81,0,0,1-2.72-7.71,11.15,11.15,0,0,1,2.64-7.72A9.1,9.1,0,0,1,126.46,23a9,9,0,0,1,7,2.83c1.7,1.87,2.56,4.46,2.56,7.78l0,1.09Zm-4.77-3.8a4.46,4.46,0,0,0-4.69-3.72,4.91,4.91,0,0,0-1.59.26,4.43,4.43,0,0,0-1.35.74,4.5,4.5,0,0,0-1,1.17,5.29,5.29,0,0,0-.66,1.55Z"
                style="fill: #888780"
            />
            <path
                d="M155.89,8.79h4.66V43.64h-4.66V41.53a8.65,8.65,0,0,1-6.21,2.68,8.86,8.86,0,0,1-6.85-3,11.21,11.21,0,0,1-2.7-7.67,10.84,10.84,0,0,1,2.7-7.51,8.69,8.69,0,0,1,13.07-.14V8.79Zm-11,24.73a6.94,6.94,0,0,0,1.55,4.7,5,5,0,0,0,4,1.84,5.31,5.31,0,0,0,4.17-1.78,6.86,6.86,0,0,0,1.59-4.66A6.86,6.86,0,0,0,154.59,29a5.26,5.26,0,0,0-4.13-1.8,5.12,5.12,0,0,0-4,1.82A6.71,6.71,0,0,0,144.87,33.52Z"
                style="fill: #888780"
            />
            <path
                d="M176.6,27.86c-2.39,1.58-3.37,5.87-6.75,5-1.17-3.66,2.5-6.29,4.85-8.51,2-1.91,4.4-3.9,5.35-6.6,1.37-.22,1.25-1.58,3.12-.7,2.06,1,1.6,1.5,2.5,3.26C184.4,25.17,188.1,31,187.86,36.1c-.19,3.93-4.35,1.66-4.24-1.47.11-3.48-.88-6.12-2.15-9.27-3.13,4.42-3.36,13.55-.13,18.2,3.65,5.26,7.37,9,14.2,10.54,5.36,1.18,11.13,1.35,15.68-1.82,1.91-1.94,4.23-3.17,5.91-5.28.72-.9.58-2.22,1.07-2.92a2.8,2.8,0,0,1,4.08.65c-1.06,2.67-1.78,5.47-4.55,7.12a76.61,76.61,0,0,1-7.6,4.17,23.41,23.41,0,0,1-16.85.78c-6.77-2.06-13.33-6.89-16.12-13.55-2.19-5.17-1.3-9.89-.56-15.39"
                style="fill: #888780; fill-rule: evenodd"
            />
            <path
                d="M226.8,30.21c2.3-.52,3.52-2.39,5.3-3.51,2.2-1.4,2.87,1.21,2,2.89-1.1,2.21-3.34,3.71-5.49,4.85a26.48,26.48,0,0,1-3.08,1.37c-1.33,1.34-.18,1.66-2.43,2.3-2.86.82-2.51-3.34-3.15-5.28-1-3-2.38-6.56-4.6-8.82s-.49-2.69,1.44-3.21A39.27,39.27,0,0,1,223,28.87c1.73-4.33,1-10.15-.85-14.34C219.6,8.64,214,6,207.89,5.76c-4.43-.15-9.19,1.82-12.67,3.84-1.39.81-8.9,6.34-8.85,1.67,0-3.7,9.88-6.23,12.83-7.6,5.22-2.42,10.49-2,16.14-.06a18.22,18.22,0,0,1,11.2,11.48c1.77,5.09.17,10.07.26,15.12"
                style="fill: #f173ac; fill-rule: evenodd"
            />
        </svg>
        <svg aria-label="slogan" class="topbar__slogan" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 260 60">
            <title>InShared slogan</title>
            <path d="M-1-1H261V61H-1Z" style="fill: none" />
            <path
                d="M5.19,23.27a.33.33,0,0,1,.35.21l5,11c.1.23.29.23.4,0l5.41-12.6c.1-.25.27-.25.37,0l5.39,12.6c.1.23.29.23.4,0l5-11a.35.35,0,0,1,.35-.23h4.64c.23,0,.33.15.21.35L22.36,44.75c-.1.23-.29.21-.4,0L16.69,32.51c-.1-.25-.27-.25-.37,0L11,44.73c-.13.23-.31.25-.42,0L.31,23.62c-.1-.21,0-.35.21-.35Z"
                style="fill: #131611"
            />
            <path
                d="M53.43,34.28a.28.28,0,0,1-.31.29h-14c-.21,0-.31.1-.29.31C39.17,38,41.21,40,44.13,40c2.29,0,3.5-.87,5.33-3.68a.27.27,0,0,1,.42-.1l3.48,1.94a.26.26,0,0,1,.1.4,12.33,12.33,0,0,1-4,4.27A10.19,10.19,0,0,1,44,44.25c-6.1,0-10.08-4.25-10.08-10.74a11.35,11.35,0,0,1,2.71-7.83,9.18,9.18,0,0,1,7.16-3c6,0,9.7,4,9.68,10.76Zm-5.18-3.56a.23.23,0,0,0,.25-.33A4.46,4.46,0,0,0,43.84,27a4.74,4.74,0,0,0-3,1,5.11,5.11,0,0,0-1.62,2.44c-.06.21,0,.33.23.33Z"
                style="fill: #131611"
            />
            <path
                d="M86.49,23.56a.26.26,0,0,1,.29-.29h4.14a.26.26,0,0,1,.29.29v19.8a.32.32,0,0,1-.33.29H86.74a.26.26,0,0,1-.29-.29V42c0-.25-.17-.33-.35-.15a8.9,8.9,0,0,1-5.89,2.37,9,9,0,0,1-7-3.06,11.37,11.37,0,0,1-2.73-7.77c0-6.12,4.12-10.7,9.64-10.7a8.5,8.5,0,0,1,6,2.58c.19.19.35.1.35-.15Zm-11.2,9.85c0,3.89,2.35,6.62,5.62,6.62,3.5,0,5.85-2.71,5.85-6.52S84.41,27,81,27C77.75,27,75.31,29.74,75.29,33.41Z"
                style="fill: #131611"
            />
            <path
                d="M101.36,8.32a.26.26,0,0,1,.29.29V43.36a.35.35,0,0,1-.35.29H97.18a.26.26,0,0,1-.29-.29V8.61a.35.35,0,0,1,.35-.29Z"
                style="fill: #131611"
            />
            <path
                d="M111.78,8.32a.26.26,0,0,1,.29.29V43.36a.35.35,0,0,1-.35.29H107.6a.26.26,0,0,1-.29-.29V8.61a.35.35,0,0,1,.35-.29Z"
                style="fill: #131611"
            />
            <path
                d="M135.34,8.32a.26.26,0,0,1,.29.29V25.14c0,.25.13.33.31.15A8.55,8.55,0,0,1,142,22.71c5.5,0,9.6,4.6,9.58,10.7,0,6.27-4.1,10.83-9.7,10.83a8.71,8.71,0,0,1-6-2.37c-.19-.17-.35-.1-.35.15v1.35a.26.26,0,0,1-.29.29h-4.12a.26.26,0,0,1-.29-.29V8.61a.35.35,0,0,1,.35-.29Zm11.43,25.3c0-3.91-2.37-6.66-5.6-6.66-3.46,0-5.85,2.67-5.85,6.52S137.63,40,141.07,40c3.27,0,5.7-2.76,5.7-6.42Z"
                style="fill: #131611"
            />
            <path
                d="M174.82,34.28a.28.28,0,0,1-.31.29h-14c-.21,0-.31.1-.29.31C160.56,38,162.6,40,165.52,40c2.29,0,3.5-.87,5.33-3.68a.27.27,0,0,1,.42-.1l3.48,1.94a.26.26,0,0,1,.1.4,12.33,12.33,0,0,1-4,4.27,10.19,10.19,0,0,1-5.48,1.4c-6.1,0-10.08-4.25-10.08-10.74A11.35,11.35,0,0,1,158,25.68a9.18,9.18,0,0,1,7.16-3c6,0,9.7,4,9.68,10.76Zm-5.18-3.56a.23.23,0,0,0,.25-.33A4.46,4.46,0,0,0,165.23,27a4.74,4.74,0,0,0-3,1,5.11,5.11,0,0,0-1.62,2.44c-.06.21,0,.33.23.33Z"
                style="fill: #131611"
            />
            <path
                d="M179.6,23.56a.29.29,0,0,1,.31-.29h4.14a.27.27,0,0,1,.31.29v1.1c0,.27.15.33.33.17a7.58,7.58,0,0,1,5.23-2.12c4.75,0,7.24,2.71,7.24,8.45v12.2a.28.28,0,0,1-.31.29h-4.14a.26.26,0,0,1-.29-.29V32.26c0-4-.92-5.33-3.79-5.33-3.12,0-4.29,1.79-4.29,6.89v9.54a.28.28,0,0,1-.31.29h-4.14a.26.26,0,0,1-.29-.29V23.56Z"
                style="fill: #131611"
            />
            <path
                d="M221.35,34.28a.28.28,0,0,1-.31.29H207c-.21,0-.31.1-.29.31.35,3.16,2.39,5.14,5.31,5.14,2.29,0,3.5-.87,5.33-3.68a.27.27,0,0,1,.42-.1l3.48,1.94a.26.26,0,0,1,.1.4,12.33,12.33,0,0,1-4,4.27,10.19,10.19,0,0,1-5.48,1.4c-6.1,0-10.08-4.25-10.08-10.74a11.35,11.35,0,0,1,2.71-7.83,9.18,9.18,0,0,1,7.16-3c6,0,9.7,4,9.68,10.76v.8Zm-5.19-3.56a.23.23,0,0,0,.25-.33A4.46,4.46,0,0,0,211.75,27a4.74,4.74,0,0,0-3,1,5.11,5.11,0,0,0-1.62,2.44c-.06.21,0,.33.23.33Z"
                style="fill: #131611"
            />
            <path
                d="M231.38,27.66a.26.26,0,0,0-.29.29V43.36a.29.29,0,0,1-.31.29h-4.14a.26.26,0,0,1-.29-.29V28c0-.19-.08-.29-.27-.29H225a.26.26,0,0,1-.29-.29V23.56a.26.26,0,0,1,.29-.29h1.08a.26.26,0,0,0,.29-.29V15.4c0-5.12,2-7.66,6.73-7.66a7.69,7.69,0,0,1,2.94.67.31.31,0,0,1,.23.33V12.9c0,.23-.15.31-.35.21l-.13-.06a6,6,0,0,0-2.52-.77,1.69,1.69,0,0,0-1.69.9,8.21,8.21,0,0,0-.46,3.39V23a.26.26,0,0,0,.29.29H236a.26.26,0,0,1,.29.29v3.81a.29.29,0,0,1-.31.29Z"
                style="fill: #131611"
            />
            <path
                d="M238.65,14.8a3.09,3.09,0,0,1,3.06-3,3.07,3.07,0,1,1-3.06,3Zm5.1,8.47c.19,0,.31.1.29.29v19.8a.29.29,0,0,1-.31.29h-4.12a.26.26,0,0,1-.29-.29V23.56a.29.29,0,0,1,.31-.29Z"
                style="fill: #131611"
            />
            <path
                d="M255.16,27.66a.26.26,0,0,0-.29.29V43.36a.29.29,0,0,1-.31.29h-4.12a.26.26,0,0,1-.29-.29V28c0-.19-.08-.29-.27-.29h-1.42a.26.26,0,0,1-.29-.29V23.56a.26.26,0,0,1,.29-.29h1.42a.26.26,0,0,0,.29-.29V16.09a.29.29,0,0,1,.31-.29h4.12a.26.26,0,0,1,.29.29V23a.26.26,0,0,0,.29.29h3.06a.26.26,0,0,1,.29.29v3.81a.26.26,0,0,1-.29.29Z"
                style="fill: #131611"
            />
        </svg>
    </a>
    <div class="topbar__content">
        <ins-authentication-menu></ins-authentication-menu>
    </div>
</div>
