<ins-modal-close></ins-modal-close>
<ins-modal-title>Änderung bestätigt</ins-modal-title>
<ins-modal-content>
    <ng-container [ngSwitch]="contentType">
        <ng-container *ngSwitchCase="premiumImpactEnum.NoChange">
            Vielen Dank für Ihre Mitteilung.
        </ng-container>
        <ng-container *ngSwitchCase="premiumImpactEnum.OnlyPremium">
            Vielen Dank für Ihre Mitteilung. Ihr Beitrag hat sich geändert:
        </ng-container>
        <!-- prettier-ignore -->
        <ng-container *ngSwitchCase="premiumImpactEnum.OnlyEvb">
        Da Sie in einen anderen Zulassungsbezirk umziehen, müssen Sie Ihr Auto ummelden. Dazu benötigen Sie eine
        neue eVB-Nummer, die Sie unten sehen können und die wir Ihnen sofort per E-Mail zuschicken. Die eVB-Nummer
        finden Sie auch in Ihrer
            <wuc-link
                [route]="'/' + appRoutesEnum.MyZone + '/' + appRoutesEnum.Insurances"
                (clicked)="close()"
            >Online-Versicherungsmappe</wuc-link>.
        </ng-container>
        <ng-container *ngSwitchCase="premiumImpactEnum.PremiumAndEvb">
        Da Sie in einen anderen Zulassungsbezirk umziehen, ändert sich Ihr Versicherungsbeitrag. Außerdem müssen
        Sie Ihr Auto ummelden. Dazu benötigen Sie eine neue eVB-Nummer, die Sie unten sehen können. Die eVB-Nummer
        finden Sie auch in Ihrer
            <wuc-link
                [route]="'/' + appRoutesEnum.MyZone + '/' + appRoutesEnum.Insurances"
                (clicked)="close()"
            >Online-Versicherungsmappe</wuc-link>
            und schicken wir Ihnen zudem per Mail zu.
        </ng-container>
    </ng-container>

    <table *ngIf="contentType !== premiumImpactEnum.NoChange" class="table premium-impact__table">
        <colgroup>
            <col/>
            <col width="100" *ngIf="someEvb">
            <col width="100" *ngIf="somePremium"/>
            <col width="100" *ngIf="somePremium"/>
        </colgroup>
        <thead>
        <tr>
            <th>&nbsp;</th>
            <th *ngIf="someEvb"><div [ngClass]="somePremium ? 'text-primary' : 'text-dark'">eVB-<br/>Nummer</div></th>
            <th *ngIf="somePremium"><div class="text-secondary">alter<br/>Beitrag</div></th>
            <th *ngIf="somePremium"><div class="text-dark">neuer<br/>Beitrag</div></th>
        </tr>
        </thead>
        <tbody>
        <ng-container *ngFor="let item of items">
            <tr>
                <td>
                    <div class="premium-impact__product text-capitalize">{{ item.description }}</div>
                    <div class="premium-impact__product-info">{{ item.reference }}</div>
                </td>
                <td *ngIf="someEvb"><div [ngClass]="somePremium ? 'text-primary' : 'text-dark'">{{ item.evb }}</div>
                </td>
                <td *ngIf="somePremium"><div class="text-secondary">{{ item.current | number : '1.2-2' }}</div></td>
                <td *ngIf="somePremium"><div class="text-dark">{{ item.future | number : '1.2-2' }}</div></td>
            </tr>
        </ng-container>
        </tbody>
    </table>
</ins-modal-content>

<ins-modal-actions alignment="end">
    <button (click)="confirm()" class="premium-impact__submit btn btn--primary" type="button">OK</button>
</ins-modal-actions>
