import { Component } from '@angular/core';
import { NewModalRef } from 'outshared-lib';
import { AppRoutesEnum } from '@app-de/routes';

@Component({
    selector: 'ins-year-reward-modal',
    templateUrl: './year-reward.modal.html',
})
export class YearRewardModal {
    public appRoutesEnum = AppRoutesEnum;

    constructor(
        private activeModal: NewModalRef<YearRewardModal>
    ) {
    }

    public dismiss(): void {
        this.activeModal.close();
    }
}
