import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';

export function equalPassword(controlName: string, matchingControlName: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const baseControl = control.get(controlName);
        const matchingControl = control.get(matchingControlName);

        if (matchingControl.errors && !matchingControl.errors.mismatchedPassword) {
            return;
        }

        if (baseControl.value && baseControl.value !== matchingControl.value) {
            matchingControl.setErrors({ mismatchedPassword: true });
        } else {
            matchingControl.setErrors(null);
        }
        return null;
    };
}
