import {
    ApiKbaNumbersResponseInterface,
    ApiNextOptionResponseInterface,
    ApiPossibleValueResponseInterface,
    ApiVehicleModelSearchRequestInterface,
    ApiVehicleModelSearchResponseInterface,
    KbaNumberInterface,
    NextStepInterface,
    VehicleModelSearchRequestInterface,
    VehicleModelSearchResponseInterface,
} from '../interfaces';

export function mapVehicleModelSearchRequest(
    request: VehicleModelSearchRequestInterface
): ApiVehicleModelSearchRequestInterface {
    if (!request.filter?.length) {
        return {};
    }
    return {
        selected_options: request.filter.map((item: { stepId: string; optionId: string }) => ({
            vehicle_option_key: item.stepId,
            selected_value: {
                vehicle_option_value: item.optionId,
            },
        })),
    };
}

export function mapKbaResultsResponse(response: ApiKbaNumbersResponseInterface[]): KbaNumberInterface[] {
    return response.map((value: ApiKbaNumbersResponseInterface) => ({
        name: value.vehicle_display_value,
        startYear: value.start_year,
        endYear: value.end_year,
        kbaNumber: value.kba_number,
    }));
}

export function mapNextOptionResponse(response: ApiNextOptionResponseInterface): NextStepInterface {
    return {
        currentStepId: response.vehicle_option_key,
        currentStepName: response.vehicle_option_name,
        currentStepOptions: response.possible_values.map((value: ApiPossibleValueResponseInterface) => ({
            id: value.vehicle_option_value,
            name: value.vehicle_option_value_label,
        })),
    };
}

export function mapVehicleModelSearchResponse(
    response: ApiVehicleModelSearchResponseInterface
): VehicleModelSearchResponseInterface {
    if (response.next_option) {
        return {
            nextStep: mapNextOptionResponse(response.next_option),
        };
    }
    if (response.results) {
        return {
            kbaNumbers: mapKbaResultsResponse(response.results),
        };
    }
    return null;
}
