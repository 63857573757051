import { NgModule } from '@angular/core';
import {
    AutoversicherungRoutingModule,
} from '@app-de/pages/car-insurance/autoversicherung/autoversicherung-routing.module';

@NgModule({
    imports: [AutoversicherungRoutingModule],
})
export class AutoversicherungModule {
}
