import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CharCounterComponent } from './char-counter.component';

@NgModule({
    imports: [CommonModule],
    declarations: [CharCounterComponent],
    exports: [CharCounterComponent],
})
export class CharCounterModule {}
