import { isPlatformServer } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ProductBaseInterface, ProductSelectorService, RelationDataService } from 'outshared-lib';
import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { PolicyLoadingStateService } from '@app-de/my-zone/services/policy-loading-state.service';
import { LayoutService } from '@app-de/store/layout/layout.service';

@Component({
    selector: 'ins-insurances-container',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: ` <ins-my-insurances [products]="products$ | async" [loading]="loading$ | async"></ins-my-insurances> `,
})
export class MyInsurancesContainer implements OnInit {
    public loading$: Observable<boolean>;
    public products$: Observable<ProductBaseInterface[]>;

    constructor(
        private layoutService: LayoutService,
        private productSelectorService: ProductSelectorService,
        private policyLoadingStateService: PolicyLoadingStateService,
        private relationDataService: RelationDataService,
        @Inject(PLATFORM_ID) private platformId: object,
    ) {
        if (isPlatformServer(this.platformId)) {
            this.loading$ = of(true);
            this.products$ = of([]);
        } else {
            // TODO: WIDL-1495 use outLoading pipe (loadingModule) in combination with InsurancePolicyLoadingService
            this.loading$ = combineLatest([
                this.policyLoadingStateService.getLoading$(),
                this.relationDataService.getRelation$(),
            ]).pipe(map(([loading, relation]) => Boolean(loading || !relation)));

            this.products$ = this.productSelectorService.getProductCollection$;
        }
    }

    public ngOnInit(): void {
        this.layoutService.setBreadcrumbs([{ title: 'Home', routerLink: '/' }, { title: 'Meine Versicherungsmappe' }]);
    }
}
