import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WucBalloonModule } from '../balloon';
import { WucSpeechBalloonComponent } from './speech-balloon.component';

@NgModule({
    declarations: [WucSpeechBalloonComponent],
    exports: [WucSpeechBalloonComponent],
    imports: [WucBalloonModule, CommonModule],
})
export class WucSpeechBalloonModule {}
