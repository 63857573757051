import { Component, HostBinding, Input } from '@angular/core';

@Component({
    selector: 'ins-rack-col',
    templateUrl: 'rack-col.component.html',
    styleUrls: ['rack-col.component.scss'],
})
export class RackColComponent {
    @HostBinding('class.rack-col') public hostClass = true;
    @HostBinding('class') public get flexClass(): string {
        return `rack-col--${this.flex}`;
    }

    @Input() public flex: number = 1;
    @Input() public textAlign: 'left' | 'center' | 'right' = 'left';
}
