import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
    CreateMailingAddressRequestInterface,
    ErrorInterface,
    RelationActionService,
    RelationDataService,
    RelationInterface,
} from 'outshared-lib';
import { Observable, Subscription } from 'rxjs';
import { MyZoneRoutes } from '../../../routing/my-zone-routing.routes';
import { DetailsSectionEnum } from '@app-de/core/enums/detail-section.enum';
import { AppRoutesEnum } from '@app-de/routes';

@Component({
    selector: 'ins-correspondence-address-form-container',
    template: `
        <ins-correspondence-address-form
            [errors]="errors"
            [loading]="loading"
            (submitted)="onSubmit($event)"
        ></ins-correspondence-address-form>
    `,
})
export class CorrespondenceAddressFormContainer implements OnInit, OnDestroy {
    public relation$: Observable<RelationInterface>;
    public errors: ErrorInterface[] = [];
    public loading: boolean = false;

    private subscriptions = new Subscription();

    constructor(
        private router: Router,
        private relationActionService: RelationActionService,
        private relationDataService: RelationDataService,
    ) {}

    public ngOnInit(): void {
        this.relation$ = this.relationDataService.getRelation$();
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    public onSubmit(payload: CreateMailingAddressRequestInterface): void {
        const requestSubscription = this.relationActionService.addMailingAddress$(payload).subscribe({
            next: () => this.onAddMailingAddressSuccess(),
            error: (errors) => this.onAddMailingAddressError(errors),
        });

        this.loading = true;
        this.errors = [];
        this.subscriptions.add(requestSubscription);
    }

    private onAddMailingAddressSuccess(): void {
        this.loading = false;
        this.router.navigate([AppRoutesEnum.MyZone, MyZoneRoutes.Details], {
            queryParams: {
                section: DetailsSectionEnum.CorrespondencePanel,
                expand: false,
            },
        });
    }

    private onAddMailingAddressError(errors: ErrorInterface[]): void {
        this.loading = false;
        this.errors = errors;
    }
}
