<ins-modal-close></ins-modal-close>

<ins-modal-title>Probleme beim Anmelden?</ins-modal-title>

<ins-modal-content>
    <p>
        Wählen Sie unten die gewünschten Informationen aus. Haben Sie sowohl die E-Mail-Adresse als auch das Passwort
        vergessen, mit denen Sie sich bei uns angemeldet haben? Fragen Sie dann erst Ihre E-Mail-Adresse ab und damit
        anschließend Ihr Passwort.
    </p>

    <form (ngSubmit)="onSubmit()" role="form" [formGroup]="form">
        <cyno-field [showSuccess]="false" [inline]="false">
            <cyno-radio value="password" formControlName="type">Ich habe mein Passwort vergessen.</cyno-radio>
            <br />
            <cyno-radio value="email" formControlName="type">
                Ich weiß nicht mehr, mit welcher E-Mail-Adresse ich mich als Kunde angemeldet habe bzw. ich habe keinen
                Zugriff mehr auf diese Adresse.
            </cyno-radio>
        </cyno-field>
    </form>
</ins-modal-content>

<ins-modal-actions alignment="end">
    <button (click)="onSubmit()" class="btn btn--primary">Weiter</button>
</ins-modal-actions>
