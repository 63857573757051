import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ErrorInterface, RelationInterface } from 'outshared-lib';
import { DetailsTableDisplayEnum } from '../../details-table/details-table-display.enum';

@Component({
    selector: 'ins-future-family-composition',
    templateUrl: './future-family-composition.component.html',
})
export class FutureFamilyCompositionComponent {
    public detailsTableDisplayEnum = DetailsTableDisplayEnum;
    @Output() public readonly cancelFutureFamilyComposition = new EventEmitter<void>();
    @Input() public relation!: RelationInterface;
    @Input() public loading!: boolean;
    @Input() public errors!: ErrorInterface[];

    public onCancelFutureFamilyComposition(event: MouseEvent): void {
        this.cancelFutureFamilyComposition.emit();
    }
}
