import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'insHighlight',
})
export class HighlightPipe implements PipeTransform {
    public transform(value: string, args: string): any {
        if (!args) {
            return value;
        }

        const re = new RegExp(args, 'gi');
        return value.replace(re, '<mark>$&</mark>');
    }
}
