export class PdfDownloadUtilities {
    public static OpenPdfTargetBlank(url: string, windowRef: Window): void {
        windowRef.open(url, '_blank');
    }

    public static OpenPdfBase64TargetBlank(base64String: string, mimeType: string, windowRef: Window): void {
        PdfDownloadUtilities.ShowDocument(base64String, mimeType, windowRef);
    }

    public static DownloadDocument(base64: string, fileName: string, doc: Document): void {
        const source = `data:application/pdf;base64,${base64}`;
        const link = doc.createElement('a');
        link.href = source;
        link.download = `${fileName}.pdf`;
        link.click();
    }

    private static Base64ToArrayBuffer(base64Str, windowRef: Window): Uint8Array {
        const binaryString = windowRef.atob(base64Str);
        const binaryLen = binaryString.length;
        const bytes = new Uint8Array(binaryLen);
        for (let i = 0; i < binaryLen; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        return bytes;
    }

    private static ShowDocument(base64Str: string, contentType: string, windowRef: Window): void {
        const byte = PdfDownloadUtilities.Base64ToArrayBuffer(base64Str, windowRef);
        const blob = new Blob([byte], { type: contentType });
        windowRef.open(URL.createObjectURL(blob), '_blank');
    }
}
