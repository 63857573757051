import { Injectable } from '@angular/core';
import { CoverageBaseInterface, CoveragePremiumCoverageInterface } from 'outshared-lib';
import { CoveragePriceService } from './coverage-price.service';
import { CoverageDisplayEnum } from '@app-de/my-zone/enums';
import {
    CoverageMergeOptionsInterface,
    CoverageMergePriceOptionsInterface,
    CoverageRemoveOptionsInterface,
    CoverageRenameOptionsInterface,
} from '@app-de/my-zone/interfaces';

@Injectable({
    providedIn: 'root',
})
export class CoverageTransformService {
    constructor(private coveragePriceService: CoveragePriceService) {}

    public orderCoverages(
        coverages: CoverageBaseInterface[],
        options: { [id: string]: number },
    ): CoverageBaseInterface[] {
        if (!options) {
            return coverages;
        }

        return coverages.sort((firstCoverage, secondCoverage) => {
            const firstOrder = options[firstCoverage.coverage_id];
            const secondOrder = options[secondCoverage.coverage_id];

            return firstOrder - secondOrder;
        });
    }

    public filterCoveragesByDisplay(
        coverages: CoverageBaseInterface[],
        display: CoverageDisplayEnum,
    ): CoverageBaseInterface[] {
        if (display === CoverageDisplayEnum.Future) {
            return coverages.filter(this.filterFuture);
        } else {
            return coverages.filter(this.filterCurrent);
        }
    }

    public filterUnnecessaryCoverages(coverages: CoverageBaseInterface[]): CoverageBaseInterface[] {
        return coverages.filter(this.filterUnnecessary);
    }

    public mergePriceCoverages(
        coverages: CoveragePremiumCoverageInterface[],
        options: CoverageMergePriceOptionsInterface[],
    ): CoveragePremiumCoverageInterface[] {
        if (!options) {
            return coverages;
        }

        options.forEach((option) => (coverages = this.mergePriceCoverage(coverages, option)));

        return coverages;
    }

    public removeCoverages(
        coverages: CoverageBaseInterface[],
        options: CoverageRemoveOptionsInterface,
    ): CoverageBaseInterface[] {
        if (!options) {
            return coverages;
        }

        const { match, removeIfMatch } = options;
        const matched: CoverageBaseInterface = coverages.find((coverage) => match.includes(coverage.coverage_id));

        if (matched) {
            return coverages.filter((coverage) => !removeIfMatch.includes(coverage.coverage_id));
        }

        return coverages;
    }

    public mergeCoverages(
        coverages: CoverageBaseInterface[],
        options: CoverageMergeOptionsInterface,
    ): CoverageBaseInterface[] {
        if (!options) {
            return coverages;
        }

        const { match, mergeIfMatch } = options;
        const matched: CoverageBaseInterface = coverages.find((coverage) => match.includes(coverage.coverage_id));
        const toMerge: CoverageBaseInterface = coverages.find((coverage) =>
            mergeIfMatch.includes(coverage.coverage_id),
        );

        if (matched && toMerge) {
            const merged = this.mergeCoverage(toMerge, matched);
            coverages.push(merged);

            return coverages.filter(
                (coverage) => ![toMerge.coverage_id, matched.coverage_id].includes(coverage.coverage_id),
            );
        }

        return coverages;
    }

    public mergeCoverage(source1: CoverageBaseInterface, source2: CoverageBaseInterface): CoverageBaseInterface {
        const coverageDescription = `${source1.coverage_description.split(' ')[0]}`;
        const coverageId = `${source2.coverage_id}_${source1.coverage_id}`;
        const merged: CoverageBaseInterface = Object.assign({}, source1, source2, {
            adn_coverage_code: null,
            coverage_id: coverageId,
            coverage_description: coverageDescription,
            purchased_coverage: null,
            purchased_coverage_future: null,
        });

        return merged;
    }

    public renameCoverages(
        coverages: CoverageBaseInterface[],
        options: CoverageRenameOptionsInterface,
    ): CoverageBaseInterface[] {
        if (!options) {
            return coverages;
        }

        const { match, renameIfMatch, rename } = options;
        const matched: CoverageBaseInterface = coverages.find((coverage) => match.includes(coverage.coverage_id));
        const toRename: CoverageBaseInterface = coverages.find((coverage) =>
            renameIfMatch.includes(coverage.coverage_id),
        );

        if (matched && toRename) {
            const renamed = this.renameCoverage(toRename, rename);
            coverages.push(renamed);

            return coverages.filter((coverage) => ![toRename.coverage_id].includes(coverage.coverage_id));
        }

        return coverages;
    }

    private mergePriceCoverage(
        coverages: CoveragePremiumCoverageInterface[],
        options: CoverageMergePriceOptionsInterface,
    ): CoveragePremiumCoverageInterface[] {
        if (!options) {
            return coverages;
        }

        const { match, mergePriceIfMatch } = options;
        const matched: CoveragePremiumCoverageInterface = coverages.find((coverage) =>
            match.includes(coverage.coverage_id),
        );
        const toMerge: CoveragePremiumCoverageInterface = coverages.find((coverage) =>
            mergePriceIfMatch.includes(coverage.coverage_id),
        );

        if (matched && toMerge) {
            const merged = this.coveragePriceService.mergePremium(matched, toMerge);

            coverages = coverages.filter((coverage) => matched.coverage_id !== coverage.coverage_id);
            coverages.push(merged);

            return coverages;
        }

        return coverages;
    }

    private renameCoverage(coverage: CoverageBaseInterface, rename: string): CoverageBaseInterface {
        const coverageId = `${coverage.coverage_id}_RENAME`;
        const coverageDescription = rename;

        return Object.assign({}, coverage, {
            adn_coverage_code: null,
            coverage_id: coverageId,
            coverage_description: coverageDescription,
            purchased_coverage: null,
            purchased_coverage_future: null,
        });
    }

    private filterCurrent(coverage: CoverageBaseInterface): boolean {
        return !!coverage.purchased_coverage;
    }

    private filterFuture(coverage: CoverageBaseInterface): boolean {
        if (coverage.purchased_coverage_future) {
            return true;
        }

        return coverage.purchased_coverage && !coverage.purchased_coverage.end_date;
    }

    private filterUnnecessary(coverage: CoverageBaseInterface): boolean {
        return !['AVHS', 'MVHS'].includes(coverage.coverage_id);
    }
}
