<ins-details-table>
    <tr>
        <td>Kontonummer</td>
        <td>{{ relation.person_bank_account.account_number }}</td>
    </tr>
    <tr>
        <td>Kontoinhaber</td>
        <td data-hj-suppress>{{ relation.person_bank_account.account_holder_name }}</td>
    </tr>
</ins-details-table>
