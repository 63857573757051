import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CoveragePremiumInterface, CoveragePremiumSelectorService, ProductBaseInterface } from 'outshared-lib';
import { Observable } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { DirectControlOptions, regelenButtonsConfig } from './direct-control-buttons-config';
import { DirectControlButtonActionEnum, SelectedPanelEnum } from '@app-de/my-zone/enums';
import { InsuranceListQueryParams } from '@app-de/my-zone/interfaces';
import { MyZoneRoutes } from '@app-de/my-zone/routing';
import { DirectControlService } from '@app-de/my-zone/services/direct-control.service';
import { AppRoutesEnum } from '@app-de/routes';

export interface ProductPanelInterface {
    selectedId: number;
    selectedPanel: SelectedPanelEnum;
}

@Component({
    selector: 'ins-direct-control-buttons-container',
    template: `
        <ins-direct-control-buttons
            (clicked)="onClick($event)"
            [loadedProduct]="loadedProduct$ | async"
            [buttons]="buttons"
            [showCloseButton]="showCloseButton$ | async"
            [queryParams]="queryParams$ | async"
            [hasOpenPanel]="hasOpenPanel"
        ></ins-direct-control-buttons>
    `,
})
export class DirectControlButtonsContainer implements OnInit {
    @Input() public product: ProductBaseInterface;

    public buttons: DirectControlOptions[] = [];
    public calculations$: Observable<CoveragePremiumInterface[]> = this.coveragePremiumSelectorService.getAllCoverages$;
    public loadedProduct$: Observable<CoveragePremiumInterface> = this.calculations$.pipe(
        map((calculations) =>
            calculations.filter(
                (calculation) =>
                    calculation.purchased_product_sequence_number === this.product.purchased_product_sequence_number,
            ),
        ),
        filter((calculations) => calculations.length > 0),
        mergeMap((calculations) => calculations),
    );

    public showCloseButton$: Observable<boolean> = new Observable();
    public hasOpenPanel: boolean = false;
    public queryParams$: Observable<InsuranceListQueryParams> = this.route.queryParams.pipe(
        map((params) => {
            const panel = params.panel ?? SelectedPanelEnum.Close;
            const purchasedProduct = Number(params.purchased_product) ?? null;
            const hasScroll = Boolean(params.scroll === 'true') ?? null;

            if (
                this.product.purchased_product_sequence_number === purchasedProduct &&
                (panel === SelectedPanelEnum.InternationalMotorInsuranceCard ||
                    panel === SelectedPanelEnum.ChangeCoverage ||
                    panel === SelectedPanelEnum.TerminateInsurance ||
                    panel === SelectedPanelEnum.ChangeDriver)
            ) {
                this.hasOpenPanel = true;
            } else {
                this.hasOpenPanel = false;
            }

            return {
                panel: panel,
                purchased_product: purchasedProduct,
                scroll: hasScroll,
            };
        }),
    );

    constructor(
        private router: Router,
        private directControlService: DirectControlService,
        private coveragePremiumSelectorService: CoveragePremiumSelectorService,
        private route: ActivatedRoute,
    ) {}

    public ngOnInit(): void {
        this.buttons = this.directControlService.initConfig(regelenButtonsConfig, this.product);
    }

    // this is a switch case to support all the other options in the future as we do in Inshared NL
    public onClick(options: DirectControlOptions): void {
        switch (options.action) {
            case DirectControlButtonActionEnum.TogglePanel: {
                this.router.navigate([AppRoutesEnum.MyZone, MyZoneRoutes.MyInsurances], {
                    queryParams: {
                        purchased_product: this.product.purchased_product_sequence_number,
                        panel: options.hash,
                    },
                });
                break;
            }
            case DirectControlButtonActionEnum.Link: {
                this.router.navigate([options.actionLink]);
                break;
            }
            case DirectControlButtonActionEnum.Close: {
                this.router.navigate([AppRoutesEnum.MyZone, MyZoneRoutes.MyInsurances], {
                    queryParams: {
                        purchased_product: this.product.purchased_product_sequence_number,
                        panel: SelectedPanelEnum.Close,
                    },
                });
                break;
            }
            default: {
                // no action to execute
                break;
            }
        }
    }
}
