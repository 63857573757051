<h3>Ihr {{ productNameEnum[product.product_id] | capitalize }}</h3>

<table class="table-data" *ngIf="product">
    <colgroup>
        <col width="33%" />
        <col width="33%" />
        <col width="33%" />
    </colgroup>
    <tbody>
        <tr *ngIf="product.auto?.motor_vehicle_type" e2e="vehicle_type">
            <td>Marke Auto</td>
            <td colspan="2">
                {{ product.auto?.motor_vehicle_make }} {{ product.auto?.motor_vehicle_model }}
                {{ product.auto?.motor_vehicle_type }}
                <span
                    *ngIf="product.auto?.motor_vehicle_type"
                    insTooltip="Nicht exakt Ihr Fahrzeugmodell? Keine Sorge: Wir versichern die Ausführung, die Ihrer HSN/TSN entspricht."
                >
                    <svg class="svg-icon svg-icon--xs"><use href="assets/icons-ui.svg#informatie"></use></svg>
                </span>
            </td>
        </tr>
        <tr *ngIf="product.auto?.license_plate" e2e="license_plate">
            <td>Kennzeichen</td>
            <td [attr.colspan]="!canBeReplaced ? 2 : 1">{{ product.auto?.license_plate }}</td>
            <td class="text-right" *ngIf="canBeReplaced">
                <a
                    class="btn btn--more"
                    routerLink="/autoverzekering/berekenen-autoverzekering"
                    [queryParams]="{
                        replace: true,
                        purchased_product_sequence_number: product.purchased_product_sequence_number
                    }"
                >
                    Ändern
                    <svg class="svg-icon svg-icon--xs"><use href="assets/icons-ui.svg#pijl-down-bold"></use></svg>
                </a>
            </td>
        </tr>
        <tr *ngIf="product.auto?.VIN" e2e="VIN">
            <td>FIN</td>
            <td colspan="2">{{ product.auto?.VIN }}</td>
        </tr>
        <tr *ngIf="product.evb_number" e2e="EVB">
            <td>eVB-Nummer</td>
            <td colspan="2">{{ product.evb_number }}</td>
        </tr>
    </tbody>
</table>
