import { Component, OnDestroy } from '@angular/core';
import { ErrorInterface, NewModal, RelationActionService, RelationDataService, RelationInterface } from 'outshared-lib';
import { Observable, Subscription } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import { CancelCorrespondenceAddressModal } from '@app-de/my-zone/shared/modals/cancel-correspondence-address-modal/cancel-correspondence-address.modal';

@Component({
    selector: 'ins-correspondence-address-future-container',
    template: ` <ins-correspondence-address-future
        [relation]="relation$ | async"
        [loading]="loading"
        [errors]="errors"
        (cancelCorrespondenceAddress)="openModal()"
    >
    </ins-correspondence-address-future>`,
})
export class CorrespondenceAddressFutureContainer implements OnDestroy {
    public relation$: Observable<RelationInterface> = this.relationDataService.getRelation$();
    public loading: boolean;
    public errors: ErrorInterface[];
    private subscriptions: Subscription = new Subscription();

    constructor(
        public relationDataService: RelationDataService,
        public relationActionService: RelationActionService,
        public newModal: NewModal,
    ) {
        this.loading = false;
        this.errors = [];
    }

    public openModal(): void {
        const confirmCancellation = this.relation$
            .pipe(
                map((relation) => relation.mailing_address_future),
                switchMap((mailingAddressFuture) =>
                    this.newModal
                        .open(CancelCorrespondenceAddressModal, { data: { mailingAddress: mailingAddressFuture } })
                        .afterClosed$(),
                ),
                first(),
            )
            .subscribe({
                next: (response) => this.cancelCorrespondenceAddress(response),
            });

        this.loading = true;
        this.errors = [];
        this.subscriptions.add(confirmCancellation);
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    private cancelCorrespondenceAddress(response): void {
        if (!response) {
            return;
        }

        const cancelFutureMailingAddress = this.relationActionService.cancelFutureMailingAddress$().subscribe({
            next: () => this.onCancelCorrespondenceAddressSuccess(),
            error: (errors) => this.onCancelCorrespondenceAddressError(errors),
        });

        this.subscriptions.add(cancelFutureMailingAddress);
    }

    private onCancelCorrespondenceAddressSuccess(): void {
        this.loading = false;
        this.errors = [];
    }

    private onCancelCorrespondenceAddressError(errors: ErrorInterface[]): void {
        this.loading = false;
        this.errors = errors;
    }
}
