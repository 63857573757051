import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { PdfInternationalMotorInsuranceCardInterface } from '../interfaces';
import { InternationalMotorInsuranceCardSelectors } from '../store/international-motor-insurance-card.selectors';
import { InternationalMotorInsuranceCardState } from '../store/international-motor-insurance-card.state';

@Injectable({
    providedIn: 'root',
})
export class InternationalMotorInsuranceCardDataService {
    constructor(private store$: Store<InternationalMotorInsuranceCardState>) {}

    public getState$(): Observable<InternationalMotorInsuranceCardState> {
        return this.store$.select((InternationalMotorInsuranceCardSelectors.getState));
    }

    public getPdf$(): Observable<PdfInternationalMotorInsuranceCardInterface> {
        return this.store$.select((InternationalMotorInsuranceCardSelectors.getPdf));
    }

    public getEmail$(): Observable<string> {
        return this.store$.select((InternationalMotorInsuranceCardSelectors.getEmail));
    }
}
