import { Component } from '@angular/core';
import { ActivationEnd, Event, NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { IntersectionObserverService } from '@app-de/core/services/intersection-observer.service';
import * as fromRoot from '@app-de/store/app.reducer';

@Component({
    selector: 'ins-chatbot-fixed-container',
    template: `
        <ins-chatbot-fixed
            *ngIf="isScreenSmall$ | async"
            [currentUrl]="currentUrl$ | async"
            [currentPath]="currentPath$ | async"
        >
        </ins-chatbot-fixed>
    `,
})
export class ChatbotFixedContainer {
    public currentUrl$: Observable<any> = this.router.events.pipe(
        filter((routerEvent: Event): boolean => routerEvent instanceof NavigationEnd),
        map((event: NavigationEnd): string => event.urlAfterRedirects),
    );

    public currentPath$: Observable<any> = this.router.events.pipe(
        filter((event): boolean => event instanceof ActivationEnd && !!!event.snapshot.routeConfig.matcher),
        map((event: ActivationEnd): string => event.snapshot.routeConfig.path),
    );

    public isScreenSmall$: Observable<boolean> = this.intersectionObserverService.isScreenSmall$;

    constructor(
        private intersectionObserverService: IntersectionObserverService,
        private store$: Store<fromRoot.State>,
        private router: Router,
    ) {}
}
