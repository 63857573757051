import { Component, HostBinding, Input } from '@angular/core';
import { ErrorInterface } from 'outshared-lib';

@Component({
    selector: 'cyno-alert',
    templateUrl: './cyno-alert.component.html',
    styleUrls: ['./cyno-alert.component.scss'],
    exportAs: 'alert',
})
export class CynoAlertComponent {
    @Input() public notifications: (ErrorInterface | string)[] = [];
    @Input() public type: 'danger' | 'error' | 'warning' | 'success';
    @Input() public customClass: string = '';

    public iconMap: Map<string, string> = new Map<string, string>([
        ['danger', 'cross-closed'],
        ['error', 'cross-closed'],
        ['warning', null],
        ['success', 'check-closed'],
    ]);

    @HostBinding('class') public class: string = 'cyno-alert';

    public isString(value: any): boolean {
        return typeof value === 'string';
    }
}
