import { Component, Input } from '@angular/core';
import { PanelExpandableComponent } from '../panel-expandable.component';

@Component({
    selector: 'ins-panel-button',
    styleUrls: ['panel-button.component.scss'],
    templateUrl: 'panel-button.component.html',
})
export class PanelButtonComponent {
    @Input() public target: PanelExpandableComponent;
    @Input() public openText: string;
    @Input() public closeText: string;
}
