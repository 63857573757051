import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ContentInterface, ErrorInterface, ModifyFamilyCompositionInterface, RelationInterface } from 'outshared-lib';

@Component({
    selector: 'ins-family-composition-form',
    templateUrl: './family-composition-form.component.html',
})
export class FamilyCompositionFormComponent {
    @Output() public readonly submitted: EventEmitter<ModifyFamilyCompositionInterface> =
        new EventEmitter<ModifyFamilyCompositionInterface>();

    @Input() public familyCompositions: ContentInterface[];
    @Input() public errors: ErrorInterface[];
    @Input() public loading: boolean;

    public relation: RelationInterface;

    public get minDate(): Date {
        const date = new Date();
        date.setDate(date.getDate() + 1);
        return date;
    }

    public get familyCompositionCode(): AbstractControl {
        return this.form.get('familyComposition');
    }

    public get startDate(): AbstractControl {
        return this.form.get('startDate');
    }

    @Input('relation') public set setRelation(relation: RelationInterface) {
        if (!this.form) {
            this.createForm();
        }

        if (relation) {
            this.relation = relation;
        }
    }

    public form: UntypedFormGroup;

    public onFormSubmit(): void {
        if (!this.form.valid) {
            this.form.markAllAsTouched();
            return;
        }

        this.submitted.emit({
            familyCompositionCode: this.familyCompositionCode.value.content_id,
            startDate: this.startDate.value,
        });
    }

    private createForm(): void {
        this.form = new UntypedFormGroup({
            familyComposition: new UntypedFormControl('', [Validators.required]),
            startDate: new UntypedFormControl('', [Validators.required]),
        });
    }
}
