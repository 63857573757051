<ins-modal-close></ins-modal-close>
<ins-modal-title>InShared Jahresbelohnung</ins-modal-title>
<ins-modal-content>
    <p>
        Das Prinzip ist einfach: Wenn Sie Schäden vermeiden und wir die Kosten niedrig halten, dann bleibt am Jahresende
        Geld übrig. Und das bekommen Sie zurück! Ihre Versicherungen werden damit noch günstiger.
    </p>
    <p>
        <picture>
            <source
                type="image/webp"
                [attr.defaultImage]="'/assets/default-image-1x1.png'"
                [attr.lazyLoad]="'/assets/shared/06_annual-reward/geldubrig-730x313.webp'"
            />
            <!-- IE11 fallback -->
            <img
                src="/assets/default-image-1x1.png"
                lazyLoad="/assets/shared/06_annual-reward/geldubrig-730x313.png"
                alt="Jahresbelohnung Geld übrig"
                class="image"
            />
        </picture>
    </p>
    <p>
        <a [routerLink]="['/', appRoutesEnum.AnnualReward]" tabindex="-1" target="_blank" class="btn--link">
            &gt; Und so funktioniert's
        </a>
    </p>
</ins-modal-content>
