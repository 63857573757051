<ng-container *ngIf="role === 'link'; else menu">
  <a
    *ngIf="route && !url"
    class="wuc-navigation-mobile-item"
    (click)="onClick($event)"
    [routerLink]="route"
    [routerLinkActiveOptions]="{ exact: true }"
    routerLinkActive="wuc-navigation-mobile-item--active"
    #rla="routerLinkActive"
  >
    <wuc-icon-ui [icon]="icon" [type]="rla.isActive ? 'tertiary' : 'primary'" size="s"></wuc-icon-ui>
    <span class="wuc-navigation-mobile-item__description">{{ name }}</span>
  </a>
  <a
    *ngIf="!route && url"
    class="wuc-navigation-mobile-item"
    [href]="url"
    nativeRouterLinkActive="wuc-navigation-mobile-item--active"
    #rla="nativeRouterLinkActive"
  >
    <wuc-icon-ui [icon]="icon" [type]="rla.isActive() ? 'tertiary' : 'primary'" size="s"></wuc-icon-ui>
    <span class="wuc-navigation-mobile-item__description">{{ name }}</span>
  </a>
</ng-container>
<ng-template #menu>
  <span class="wuc-navigation-mobile-item" (click)="onClick($event)" tabindex="0">
    <wuc-icon-ui type="primary" [icon]="active ? 'close' : 'navigation'" size="s"></wuc-icon-ui>
    <span class="wuc-navigation-mobile-item__description">{{ name }}</span>
  </span>
</ng-template>
