import { DatePipe } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ElementValidator, ErrorInterface, RelationInterface } from 'outshared-lib';
import { Subscription } from 'rxjs';
import { GenderCodeEnum } from '@app-de/core/enums';
import { AppRoutesEnum, ModalRoutesEnum } from '@app-de/routes';

const VALIDATOR_NAME = 'telephone_number';
// TODO: WIDL-1272 Regular id 'RELA.ALGM.WIJZ.RELG' not working. Temporary use:
const WEBSERVICE_ID = 'VERZ.PROD.VSLG.NREL.PROD.AUTO';
const CONTEXT = 'landline_or_mobile_telephone_number';

@Component({
    selector: 'ins-personal-details-form',
    templateUrl: './personal-details-form.component.html',
})
export class PersonalDetailsFormComponent implements AfterViewInit, OnDestroy {
    @Input() public disabled: boolean = false;
    @Input() public errors: ErrorInterface[];

    @Input('relation') public set setRelation(relation: RelationInterface) {
        if (!this.form) {
            this.createForm();
        }

        if (relation && this.form) {
            this.resetForm(relation);
        }
    }

    @Output() public readonly submitted = new EventEmitter<{
        mobilePhoneNumber: string;
        genderCode: string;
    }>();

    @Output() public readonly statusChange = new EventEmitter<string>();

    public form: UntypedFormGroup;
    public genderCodes = GenderCodeEnum;
    public appRoutesEnum = AppRoutesEnum;
    public modalRoutesEnum = ModalRoutesEnum;

    public get phoneNumber(): AbstractControl {
        return this.form.get('mobilePhoneNumber');
    }

    public get genderCode(): AbstractControl {
        return this.form.get('genderCode');
    }

    private subscriptions: Subscription = new Subscription();

    constructor(
        private datePipe: DatePipe,
        private elementValidator: ElementValidator,
        private changeDetectorRef: ChangeDetectorRef,
    ) {}

    public ngAfterViewInit(): void {
        this.form
            .get('mobilePhoneNumber.telephoneNumber')
            .setAsyncValidators(this.elementValidator.validate(VALIDATOR_NAME, WEBSERVICE_ID, CONTEXT));
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    public onFormSubmit(): void {
        this.submitted.emit({
            mobilePhoneNumber: this.phoneNumber.value.telephoneNumber,
            genderCode: this.genderCode.value,
        });
    }

    private createForm(): void {
        this.form = new UntypedFormGroup({
            name: new UntypedFormControl(
                {
                    value: '',
                    disabled: true,
                },
                [Validators.required],
            ),
            birthdate: new UntypedFormControl(
                {
                    value: '',
                    disabled: true,
                },
                [Validators.required],
            ),
            genderCode: new UntypedFormControl('', [Validators.required]),
            mobilePhoneNumber: new UntypedFormGroup({
                telephoneNumber: new UntypedFormControl('', {
                    validators: [Validators.required],
                    updateOn: 'change',
                }),
            }),
        });

        this.subscriptions.add(this.form.statusChanges.subscribe(() => this.changeDetectorRef.detectChanges()));
    }

    private resetForm(relation: RelationInterface): void {
        this.form.reset({
            name: `${relation.first_name} ${relation.surname}`,
            birthdate: relation.birthdate,
            genderCode: relation.gender_code,
            mobilePhoneNumber: relation.mobile_telephone_number
                ? { telephoneNumber: relation.mobile_telephone_number.telephone_number }
                : { telephoneNumber: '' },
        });
    }
}
