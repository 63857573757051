import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CoverageBaseInterface, ProductBasePermittedCoverageInterface, ProductIdEnum } from 'outshared-lib';
import mergeOptions from '@app-de/my-zone/config/coverage-merge-options.config.json';
import orderOptions from '@app-de/my-zone/config/coverage-order-options.config.json';
import removeOptions from '@app-de/my-zone/config/coverage-remove-options.config.json';
import { CoverageDisplayEnum } from '@app-de/my-zone/enums';
import { CoverageMergeOptionsInterface, CoverageRemoveOptionsInterface } from '@app-de/my-zone/interfaces';
import { CoverageTransformService } from '@app-de/my-zone/services/coverage-transform.service';

@Component({
    selector: 'ins-coverage-list',
    templateUrl: 'coverage-list.component.html',
})
export class CoverageListComponent implements OnChanges {
    @Input() public permittedCoverages: ProductBasePermittedCoverageInterface[];
    @Input() public productId: ProductIdEnum;
    @Input() public display: CoverageDisplayEnum;

    public coverages: ProductBasePermittedCoverageInterface[] = [];

    constructor(private coverageTransformService: CoverageTransformService) {}

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.permittedCoverages) {
            this.coverages = this.createCoverageList(changes.permittedCoverages.currentValue);
        }
    }

    private createCoverageList(
        permittedCoverages: ProductBasePermittedCoverageInterface[],
    ): ProductBasePermittedCoverageInterface[] {
        const toRemove: CoverageRemoveOptionsInterface = removeOptions[this.productId];
        const toMerge: CoverageMergeOptionsInterface = mergeOptions[this.productId];
        const order: { [id: string]: number } = orderOptions.options[this.productId];
        let coverages: CoverageBaseInterface[] = permittedCoverages;

        if (coverages.length < 1) {
            return [];
        }

        coverages = this.coverageTransformService.filterCoveragesByDisplay(coverages, this.display);
        coverages = this.coverageTransformService.filterUnnecessaryCoverages(coverages);
        coverages = this.coverageTransformService.removeCoverages(coverages, toRemove);
        coverages = this.coverageTransformService.mergeCoverages(coverages, toMerge);
        coverages = this.coverageTransformService.orderCoverages(coverages, order);

        return coverages as ProductBasePermittedCoverageInterface[];
    }
}
