import { mapGenderCode } from '../../core';
import {
    ApiTestimonialResponseInterface,
    GetTestimonialsResponseInterface,
    ApiQuestionInterface,
    QuestionInterface,
    ApiGetTestimonialsResponseInterface,
    TestimonialInterface,
    ApiTestimonialsRequestInterface,
} from '../interfaces';

function questionMapper(response: ApiQuestionInterface[]): QuestionInterface[] {
    if (!response) {
        return [];
    }

    return response.map((question) => ({
        question: question.question,
        subject: question.subject,
        subjectDescription: question.subject_description,
        answer: question.answer,
        score: question.score,
    }));
}

function testimonialMapper(
    request: ApiTestimonialsRequestInterface,
    response: ApiGetTestimonialsResponseInterface[]
): TestimonialInterface[] {
    if (!response) {
        return [];
    }

    return response.map((testimonial) => ({
        name: testimonial.name,
        genderCode: mapGenderCode(testimonial.gender_code),
        genderDescription: testimonial.gender_description,
        age: testimonial.age,
        province: testimonial.province,
        familyComposition: testimonial.family_composition,
        avatar: testimonial.avatar,
        score: testimonial.score,
        testimonial: testimonial.testimonial,
        answer: testimonial.answer,
        reviewTimeDescription: testimonial.review_time_description,
        questions: questionMapper(testimonial.questions),
        questionsetIds: request.vragenset.map((questionsetId) => questionsetId.questionset_id),
    }));
}

export function mapTestimonialsResponse(
    request: ApiTestimonialsRequestInterface,
    response: ApiTestimonialResponseInterface
): GetTestimonialsResponseInterface {
    return {
        totalNumberOfRows: response.total_number_of_rows,
        rowNumberFrom: response.row_number_from,
        rowNumber: response.row_number,
        rowNumberDescription: response.row_number_description,
        testimonials: testimonialMapper(request, response.testimonials),
    };
}
