import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { DetailsSubmitButtonModule } from '../details-submit-button/details-submit-button.module';
import { DetailsTableModule } from '../details-table/details-table.module';
import { PasswordDataComponent } from './password-data/password-data.component';
import { PasswordFormComponent } from './password-form/password-form.component';
import { PasswordFormContainer } from '@app-de/my-zone/shared/password/password-form/password-form.container';
import { CynoFormModule } from '@app-de/shared/cyno-form/cyno-form.module';

@NgModule({
    declarations: [PasswordDataComponent, PasswordFormComponent, PasswordFormContainer],
    exports: [
        PasswordDataComponent,
        PasswordFormContainer,
    ],
    imports: [
        CommonModule,
        CynoFormModule,
        RouterModule,
        DetailsTableModule,
        DetailsSubmitButtonModule,
    ],
})
export class PasswordModule { }
