import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WucBalloonModule, WucButtonModule, WucDropTriggerModule } from '@inshared/website-ui-components';
import { DirectControlButtonsComponent } from './direct-control-buttons.component';
import { DirectControlButtonsContainer } from './direct-control-buttons.container';

@NgModule({
    declarations: [DirectControlButtonsComponent, DirectControlButtonsContainer],
    imports: [CommonModule, WucDropTriggerModule, WucButtonModule, WucBalloonModule],
    exports: [DirectControlButtonsContainer, DirectControlButtonsComponent],
})
export class DirectControlButtonsModule {}
