import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ProductBaseInterface, ProductNameEnum, ValidityStatusCodeEnum } from 'outshared-lib';
import { GenderCodeEnum } from '@app-de/core/enums';

@Component({
    selector: 'ins-insurance-data',
    templateUrl: './insurance-data.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InsuranceDataComponent {
    @Input() public product: ProductBaseInterface;
    @Input() public canBeReplaced: boolean = false;

    public productNameEnum: typeof ProductNameEnum = ProductNameEnum;
    public validityStatusCodeEnum: typeof ValidityStatusCodeEnum = ValidityStatusCodeEnum;
    public genderCodes: typeof GenderCodeEnum = GenderCodeEnum;
}
