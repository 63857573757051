<div class="coverage-detail" *ngIf="!changeCoverage">
    <!-- Coverage Overview -->
    <ins-coverage-overview-container></ins-coverage-overview-container>
    <!-- // Coverage Overview -->

    <div class="coverage-detail__buttons">
        <ng-container *ngIf="!calculation.product.coverages_adjustment_start_date">
            <ng-container *ngIf="product.product_id !== 'DIER' && product.product_id !== 'AANS'">
                <button
                    class="btn btn--secondary coverage-detail__button"
                    *ngIf="!['TOEK', 'NEVT', 'ACEI'].includes(product.validity_status_code)"
                    (click)="toggle.emit()"
                >
                    Versicherungsschutz ändern
                </button>
                <button
                    class="btn btn--secondary coverage-detail__button disabled"
                    *ngIf="['TOEK', 'NEVT', 'ACEI'].includes(product.validity_status_code)"
                    [insTooltip]="
                        'U kunt nu de dekking niet wijzigen, omdat de verzekering stopt op ' +
                        (product.end_date | dateFormat) +
                        '. Klik op \'toch niet opzeggen\' als u uw dekking wilt aanpassen.'
                    "
                >
                    Versicherungsschutz ändern
                </button>
            </ng-container>
        </ng-container>

        <ng-container *ngIf="calculation.product.coverages_adjustment_start_date">
            <ng-container *ngIf="!['TOEK', 'OSTP'].includes(product.validity_status_code)">
                <button
                    [disabled]="IsLoadingCancelFutureCoverage"
                    class="btn btn--secondary coverage-detail__button"
                    (click)="cancelFutureCoverage()"
                >
                    Änderung widerrufen
                </button>
            </ng-container>
        </ng-container>

        <p>
            <small>
                Alle genannten Preise sind in Euro und inklusive Versicherungssteuer angegeben. Noch nicht verrechnet
                ist die Jahresbelohnung, die Sie am Jahresbeginn bekommen, wenn Geld übrig bleibt.
                <a href="#" (click)="getInfo()">Was ist die Jahresbelohnung?</a>
            </small>
        </p>
    </div>
</div>

<!-- Change Coverage Form -->
<ng-container *ngIf="changeCoverage">
    <ins-panel-body id="dekking-wijzigen-{{ product.purchased_product_sequence_number }}">
        <div class="panel__title">
            <h3 class="coverage-detail__title">Versicherungsschutz ändern</h3>
        </div>
        <ins-change-coverage-container [hasCancelButton]="hasCancelButton"></ins-change-coverage-container>
    </ins-panel-body>
</ng-container>
