import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { CookieOptions, CookieService } from 'ngx-cookie';
import { DateService } from 'outshared-lib';

@Injectable({
    providedIn: 'root',
})
export class CookieStorage implements Storage {
    [index: number]: string;
    [key: string]: any;

    public length: number;

    private cookieDomain: string;
    private cookieSetting: string;

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private cookieService: CookieService,
        private dateService: DateService,
    ) {
        if (this.document.location.hostname === 'localhost') {
            this.cookieDomain = 'localhost';
        } else {
            this.cookieDomain = `.${this.document.location.hostname}`;
        }
    }

    public clear(options?: CookieOptions): void {
        this.cookieService.removeAll(options);
    }

    public getItem(key: string): string {
        return this.cookieService.get(key);
    }

    public key(index: number): string {
        return this.cookieService.getAll().propertyIsEnumerable[index];
    }

    public removeItem(key: string, options?: CookieOptions): void {
        const _options = Object.assign(
            {
                domain: this.cookieDomain,
            },
            options,
        );
        this.cookieService.remove(key, _options);
    }

    public setItem(key: string, data: string, options?: CookieOptions): void {
        const _options: CookieOptions = {
            domain: this.cookieDomain,
            expires: this.dateService.add(1, 'year'),
            ...options,
        };

        if (key === 'ls.cookie.setting') {
            this.cookieSetting = data;
        }

        this.cookieService.put(key, data, _options);
    }

    public getObject(key: string): Object {
        return this.cookieService.getObject(key);
    }

    public putObject(key: string, value: object, options?: CookieOptions): void {
        const _options: CookieOptions = {
            domain: this.cookieDomain,
            expires: this.dateService.add(1, 'year'),
            ...options,
        };

        return this.cookieService.putObject(key, value, _options);
    }
}
