<ins-insurance-regular-driver-data
    [product]="product"
    [panel]="insuranceDriverForm"
></ins-insurance-regular-driver-data>
<ins-insurance-regular-driver-future
    [product]="product"
    *ngIf="product.non_regular_driver_future"
></ins-insurance-regular-driver-future>
<ins-panel-expandable class="panel--info" direction="right" #insuranceDriverForm="panel">
    <ins-insurance-regular-driver-form
        id="insurance-regular-driver-form_{{ product.purchased_product_sequence_number }}"
        [product]="product"
    ></ins-insurance-regular-driver-form>
</ins-panel-expandable>
