import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BreadcrumbModule } from './breadcrumb/breadcrumb.module';
import { FooterModule } from './footer/footer.module';
import { HeaderModule } from './header/header.module';
import { SloganModule } from './slogan/slogan.module';

@NgModule({
    imports: [
        CommonModule,
        HeaderModule,
        BreadcrumbModule,
        FooterModule,
        SloganModule,
    ],
    exports: [
        HeaderModule,
        BreadcrumbModule,
        FooterModule,
        SloganModule,
    ],
})
export class BaseLayoutModule {}
