import { NgModule } from '@angular/core';
import { WucIconUiModule } from '../icon-ui';
import { WucTagComponent } from './tag.component';

@NgModule({
    imports: [WucIconUiModule],
    declarations: [WucTagComponent],
    exports: [WucTagComponent],
})
export class WucTagModule {}
