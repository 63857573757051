import { Component } from '@angular/core';
import { NewModalRef, ProductEnGbInterface } from 'outshared-lib';
import { PremiumImpactEnum } from '@app-de/my-zone/shared/premium-impact-modal/premium-impact.enum';
import { PremiumImpactInterface } from '@app-de/my-zone/shared/premium-impact-modal/premium-impact.interface';
import { AppRoutesEnum } from '@app-de/routes';

@Component({
    selector: 'ins-premium-impact-modal',
    templateUrl: './premium-impact.modal.html',
    styleUrls: ['./premium-impact.modal.scss'],
})
export class PremiumImpactModal {
    public premiumImpactEnum = PremiumImpactEnum;
    public contentType: PremiumImpactEnum = PremiumImpactEnum.NoChange;
    public someEvb: boolean = false;
    public somePremium: boolean = false;
    public appRoutesEnum = AppRoutesEnum;

    /** @deprecated - use 'items' input property **/
    public set products(products: ProductEnGbInterface[]) {
        this.items = products.map((item) => ({
            description: item.productDescription,
            reference: item.reference,
            evb: null,
            current: item.premiumCurrent,
            future: item.premiumReferenceDate,
        }));
    }

    public set items(items: PremiumImpactInterface[]) {
        this.#items = items;
        this.init();
    }

    public get items(): PremiumImpactInterface[] {
        return this.#items;
    }

    #items: PremiumImpactInterface[] = [];

    constructor(private newModalRef: NewModalRef<PremiumImpactModal>) {}

    public close(): void {
        this.newModalRef.close(false);
    }

    public confirm(): void {
        this.newModalRef.close(true);
    }

    private init(): void {
        this.someEvb = this.#items.some((item: PremiumImpactInterface) => Boolean(item.evb));
        this.somePremium = this.#items.some((item: PremiumImpactInterface) => Boolean(item.future));
        this.contentType = this.getContentType();
    }

    private getContentType(): PremiumImpactEnum {
        if (this.someEvb && this.somePremium) {
            return PremiumImpactEnum.PremiumAndEvb;
        }
        if (this.somePremium) {
            return PremiumImpactEnum.OnlyPremium;
        }
        if (this.someEvb) {
            return PremiumImpactEnum.OnlyEvb;
        }
        return PremiumImpactEnum.NoChange;
    }
}
