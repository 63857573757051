<div class="details-table" [ngClass]="{ 'details-table--future': display === detailsTableDisplayEnum.Future }">
    <table class="table-data">
        <colgroup>
            <!-- Layout corrections for tables on mobile. Could not fix this with the current website ui components. -->
            <col [width]="(isScreenSmall$ | async) ? '100%' : '40%'" />
            <col [width]="(isScreenSmall$ | async) ? '100%' : '60%'" />
        </colgroup>
        <tbody>
            <ng-content></ng-content>
        </tbody>
    </table>
</div>
