import { Injectable } from '@angular/core';
import {
    CoverageBaseInterface,
    CoveragePremiumCoverageInterface,
    CoveragePremiumInterface,
    CoveragePremiumSelectorService,
    NotificationInterface,
    ProductBaseInterface,
    ProductSelectorService,
} from 'outshared-lib';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import mergeOptions from '@app-de/my-zone/config/coverage-merge-options.config.json';
import mergePriceOptions from '@app-de/my-zone/config/coverage-merge-price-options.config.json';
import orderOptions from '@app-de/my-zone/config/coverage-order-options.config.json';
import removeOptions from '@app-de/my-zone/config/coverage-remove-options.config.json';
import { CoverageDisplayEnum } from '@app-de/my-zone/enums';
import {
    CoverageMergeOptionsInterface,
    CoverageMergePriceOptionsInterface,
    CoverageRemoveOptionsInterface,
} from '@app-de/my-zone/interfaces';
import { AnnualPaymentService } from '@app-de/my-zone/services/annual-payment.service';
import { CoverageTransformService } from '@app-de/my-zone/services/coverage-transform.service';

@Injectable({
    providedIn: 'root',
})
export class CoverageDetailService {
    public selectedProduct$: Observable<ProductBaseInterface> = this.productSelectorService.getSelectedProduct$;
    public calculation$: Observable<CoveragePremiumInterface> = this.coveragePremiumSelectorService.getSelected$;
    public notifications$: BehaviorSubject<NotificationInterface[]> = new BehaviorSubject([]);
    public coverages$ = of(null);
    public changeCoverage$: Observable<boolean>;

    public modulesCurrent$: Observable<CoveragePremiumCoverageInterface[]> = combineLatest(
        this.selectedProduct$,
        this.calculation$,
    ).pipe(
        filter(([product, calculation]) => !!product),
        map(([product, calculation]) => {
            const currentPeriod = this.annualPaymentService.getCurrentPeriod(calculation?.product);
            const coverages: CoveragePremiumCoverageInterface[] = [
                ...(currentPeriod.base_coverages || []),
                ...(currentPeriod.additional_coverage || []),
            ];
            return this.createCoverageList(coverages, {
                product_id: product.product_id,
                display: CoverageDisplayEnum.Current,
            });
        }),
    );

    public modulesFuture$: Observable<CoveragePremiumCoverageInterface[]> = combineLatest(
        this.selectedProduct$,
        this.calculation$,
    ).pipe(
        filter(([product, calculation]) => !!product),
        map(([product, calculation]) => {
            const currentPeriod = this.annualPaymentService.getCurrentPeriod(calculation?.product);
            const coverages: CoveragePremiumCoverageInterface[] = [
                ...(currentPeriod?.base_coverages || []),
                ...(currentPeriod?.additional_coverage || []),
            ];

            return this.createCoverageList(coverages, {
                product_id: product.product_id,
                display: CoverageDisplayEnum.Future,
            });
        }),
    );

    private changeCoverageState$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private purchasedProductSequenceNumber$: BehaviorSubject<number> = new BehaviorSubject(null);

    constructor(
        private coverageTransformService: CoverageTransformService,
        private productSelectorService: ProductSelectorService,
        private coveragePremiumSelectorService: CoveragePremiumSelectorService,
        private annualPaymentService: AnnualPaymentService,
    ) {
        this.changeCoverage$ = this.changeCoverageState$.asObservable();
    }

    public setSelectedPurchasedProductSequenceNumber(id: number): void {
        this.purchasedProductSequenceNumber$.next(id);
    }

    public toggleChangeCoverage(): void {
        this.changeCoverageState$
            .pipe(
                take(1),
                map((changeCoverage) => this.changeCoverageState$.next(!changeCoverage)),
            )
            .subscribe();
    }

    public setChangeCoverageState(value: boolean): void {
        this.changeCoverageState$.next(value);
    }

    public createCoverageList(
        coverages: CoveragePremiumCoverageInterface[],
        props: {
            product_id: string;
            display: CoverageDisplayEnum;
        },
    ): CoveragePremiumCoverageInterface[] {
        const { product_id, display } = props;
        const toRemove: CoverageRemoveOptionsInterface = removeOptions[product_id];
        const toMerge: CoverageMergeOptionsInterface = mergeOptions[product_id];
        const toMergePrice: CoverageMergePriceOptionsInterface[] = mergePriceOptions[product_id];
        const order: { [id: string]: number } = orderOptions.options[product_id];
        let currentCoverages: CoverageBaseInterface[] = coverages;

        if (coverages.length < 1) {
            return [];
        }

        currentCoverages = this.coverageTransformService.filterCoveragesByDisplay(currentCoverages, display);
        currentCoverages = this.coverageTransformService.filterUnnecessaryCoverages(currentCoverages);
        currentCoverages = this.coverageTransformService.mergePriceCoverages(
            currentCoverages as CoveragePremiumCoverageInterface[],
            toMergePrice,
        );
        currentCoverages = this.coverageTransformService.removeCoverages(currentCoverages, toRemove);
        currentCoverages = this.coverageTransformService.mergeCoverages(currentCoverages, toMerge);
        currentCoverages = this.coverageTransformService.orderCoverages(currentCoverages, order);

        return currentCoverages as CoveragePremiumCoverageInterface[];
    }
}
