/*
* WARNING: When adding a new item to the enum,
* also add a ReviewProductNameIdData object to ProductIdNameService.items
* with at least the reviewId property
* */

export enum ReviewIdEnum {
    AccidentInsurance = 5754,
    BicycleInsurance = 7775,
    BuildingInsurance = 5958,
    CarInsurance = 5748,
    CaravanInsurance = 5755,
    Carglass = 7555,
    Contact = 5747,
    Damage = 5758,
    DamageRepair = 5759,
    HerstelDirect = 5761,
    HomeInsurance = 5749,
    InShared = 5747,
    InventoryInsurance = 5957,
    LegalInsurance = 5756,
    LiabilityInsurance = 5751,
    MopedInsurance = 5750,
    MopedSnorInsurance = 5750,
    ScooterInsurance = 5750,
    MotorInsurance = 5752,
    PetInsurance = 5757,
    TravelInsurance = 5753,
}
