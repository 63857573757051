import { Component, Inject } from '@angular/core';
import { NewModalRef, NEW_MODAL_DATA } from 'outshared-lib';

interface ModalData {
    coverage_id: string;
    coverage_title: string;
}
@Component({
    selector: 'cyno-no-coverage-modal',
    templateUrl: './no-coverage.modal.html',
})
export class NoCoverageModal {
    constructor(
        public modalRef: NewModalRef<NoCoverageModal>,
        @Inject(NEW_MODAL_DATA) public data: ModalData
    ) {}

    public close(): boolean {
        this.modalRef.close();
        return false;
    }
}
