import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, exhaustMap, map, switchMap, tap } from 'rxjs/operators';
import { InvoiceApiService, InvoiceEventService } from '../services';
import {
    DownloadInvoiceAction,
    DownloadInvoiceErrorAction,
    DownloadInvoiceSuccessAction,
    GetInvoiceDetailsAction,
    GetInvoiceDetailsErrorAction,
    GetInvoiceDetailsSuccessAction,
    GetInvoicesAction,
    GetInvoicesErrorAction,
    GetInvoicesSuccessAction,
    InvoiceActions,
} from './invoice.actions';

@Injectable({
    providedIn: 'root',
})
export class InvoiceEffects {
    public getInvoices$: Observable<Action> = createEffect(() => {
        return this.action$.pipe(
            ofType(InvoiceActions.GetInvoices),
            switchMap((action: GetInvoicesAction) => {
                return this.invoiceApiService.getInvoices$(action.payload).pipe(
                    map((response) => new GetInvoicesSuccessAction(response)),
                    catchError((errors) => of(new GetInvoicesErrorAction({ errors })))
                );
            })
        );
    }
    );

    public getInvoicesSuccess$: Observable<void> = createEffect(
        () => {
            return this.action$.pipe(
                ofType(InvoiceActions.GetInvoicesSuccess),
                tap(() => this.invoiceEventService.onGetInvoicesSuccess())
            );
        },
        { dispatch: false }
    );

    public getInvoicesError$: Observable<void> = createEffect(
        () => {
            return this.action$.pipe(
                ofType(InvoiceActions.GetInvoicesError),
                map((action: GetInvoicesErrorAction) =>
                    this.invoiceEventService.onGetInvoicesError(action.payload.errors)
                )
            );
        },
        { dispatch: false }
    );

    public getInvoiceDetails$: Observable<Action> = createEffect(() => {
        return this.action$.pipe(
            ofType(InvoiceActions.GetInvoiceDetails),
            switchMap((action: GetInvoiceDetailsAction) => {
                return this.invoiceApiService.getInvoiceDetails$(action.payload).pipe(
                    map((response) => new GetInvoiceDetailsSuccessAction(response)),
                    catchError((errors) => of(new GetInvoiceDetailsErrorAction({ errors })))
                );
            })
        );
    }
    );

    public getInvoiceDetailsSuccess$: Observable<void> = createEffect(
        () => {
            return this.action$.pipe(
                ofType(InvoiceActions.GetInvoiceDetailsSuccess),
                tap(() => this.invoiceEventService.onGetInvoiceDetailsSuccess())
            );
        },
        { dispatch: false }
    );

    public getInvoiceDetailsError$: Observable<void> = createEffect(
        () => {
            return this.action$.pipe(
                ofType(InvoiceActions.GetInvoiceDetailsError),
                map((action: GetInvoicesErrorAction) =>
                    this.invoiceEventService.onGetInvoiceDetailsError(action.payload.errors)
                )
            );
        },
        { dispatch: false }
    );

    public downloadInvoice$: Observable<Action> = createEffect(() => {
        return this.action$.pipe(
            ofType(InvoiceActions.DownloadInvoice),
            exhaustMap((action: DownloadInvoiceAction) => {
                return this.invoiceApiService.downloadInvoice$(action.payload.invoiceId).pipe(
                    map((download) => new DownloadInvoiceSuccessAction(download)),
                    catchError((errors) => of(new DownloadInvoiceErrorAction({ errors })))
                );
            })
        );
    }
    );

    public downloadInvoiceSuccess$: Observable<DownloadInvoiceSuccessAction> = createEffect(
        () => {
            return this.action$.pipe(
                ofType(InvoiceActions.DownloadInvoiceSuccess),
                tap((action: DownloadInvoiceSuccessAction) =>
                    this.invoiceEventService.onDownloadInvoiceSuccess(action.payload)
                )
            );
        },
        { dispatch: false }
    );

    public downloadInvoiceError$: Observable<void> = createEffect(
        () => {
            return this.action$.pipe(
                ofType(InvoiceActions.DownloadInvoiceError),
                map((action: GetInvoicesErrorAction) =>
                    this.invoiceEventService.onGetInvoiceDetailsError(action.payload.errors)
                )
            );
        },
        { dispatch: false }
    );

    constructor(
        private action$: Actions,
        private invoiceApiService: InvoiceApiService,
        private invoiceEventService: InvoiceEventService
    ) { }
}
