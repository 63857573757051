import {
    ChangeDetectionStrategy,
    Component,
    DoCheck,
    ElementRef,
    Input,
    Optional,
    Self,
    ViewChild,
} from '@angular/core';
import { ControlValueAccessor, FormGroupDirective, NgControl, NgForm } from '@angular/forms';
import { Subject } from 'rxjs';
import { CynoFieldControl } from '../core/cyno-field-control';
import { ErrorStateMatcher } from '../core/error-options';
import { CanUpdateErrorState, mixinErrorState } from '../core/error-state';
import { PhoneService } from '@app-de/shared/cyno-form/cyno-phone/phone.service';

class CynoPhoneBase {
    constructor(
        public _defaultErrorStateMatcher: ErrorStateMatcher,
        public _parentForm: NgForm,
        public _parentFormGroup: FormGroupDirective,
        public ngControl: NgControl,
    ) {}
}

const cynoPhoneMixinBase = mixinErrorState(CynoPhoneBase);

class CynoPhone {}

@Component({
    selector: 'cyno-phone',
    templateUrl: './phone.component.html',
    providers: [
        {
            provide: CynoFieldControl,
            useExisting: CynoPhoneInputComponent,
        },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CynoPhoneInputComponent
    extends cynoPhoneMixinBase
    implements ControlValueAccessor, CynoFieldControl<CynoPhone>, CanUpdateErrorState, DoCheck
{
    @Input()
    public get placeholder(): string {
        return this._placeholder;
    }

    public set placeholder(plh) {
        this._placeholder = plh;
        this.stateChanges.next();
    }

    @Input()
    public get required(): boolean {
        return this._required;
    }

    public set required(req) {
        this._required = req;
        this.stateChanges.next();
    }

    @Input()
    public get disabled(): boolean {
        return this._disabled;
    }

    public set disabled(dis: boolean) {
        this._disabled = dis;
        this.stateChanges.next();
    }

    protected _disabled: boolean = false;

    public static nextId = 0;
    @ViewChild('phone_number', { static: true }) public phoneNumber: ElementRef;

    public id: string = `cyno-phone_${CynoPhoneInputComponent.nextId++}`;

    public value: string | null = null;

    public focused: boolean = false;

    public stateChanges: Subject<void> = new Subject();

    private _placeholder: string;
    private _required = false;

    constructor(
        public _defaultErrorStateMatcher: ErrorStateMatcher,
        private cynoPhoneService: PhoneService,
        @Optional() parentForm: NgForm,
        @Optional() parentFormGroup: FormGroupDirective,
        @Optional() @Self() public ngControl: NgControl,
    ) {
        super(_defaultErrorStateMatcher, parentForm, parentFormGroup, ngControl);
        if (this.ngControl != null) {
            this.ngControl.valueAccessor = this;
        }
    }

    public ngDoCheck(): void {
        if (this.ngControl) {
            this.updateErrorState();
        }
    }

    public writeValue(value: string): void {
        this.value = value;
        this.phoneNumber.nativeElement.value = this.cynoPhoneService.format(this.value)?.formatted || value;
    }

    public registerOnChange(fn: (value: any) => void): void {
        this._onChange = fn;
    }

    public registerOnTouched(fn: () => {}): void {
        this._onTouched = fn;
    }

    public onContainerClick(): void {}

    public onKeyup(): void {
        this.value = this.phoneNumber.nativeElement.value;
        this._onChange(this.value);
    }

    public onBlur(): void {
        const formatted = this.cynoPhoneService.format(this.value);
        if (formatted) {
            this.phoneNumber.nativeElement.value = formatted.formatted;
            this.value = formatted.cleaned;
            this._onChange(this.value);
        }
        this._onTouched();
    }

    // tslint:disable-next-line:no-empty
    private _onChange: (value: string) => void = () => {};
    private _onTouched = (): any => {};
}
