import { Component, Input } from '@angular/core';

@Component({
    selector: 'ins-card',
    templateUrl: 'card.component.html',
    styleUrls: ['card.component.scss'],
})
export class CardComponent {
    @Input() public borders: boolean = false;
    @Input() public eZine: boolean = false;
    @Input() public smartTips: boolean = false;
    @Input() public smartTipsHorizontal: boolean = false;
    @Input() public borderPrimary: boolean = false;
    @Input() public textCenter: boolean = false;
    @Input() public white: boolean = false;
    @Input() public small: boolean = false;
    @Input() public margin: boolean = true;
    @Input() public verticalAlign: boolean = false;
    @Input() public highlight: boolean = false;
}
