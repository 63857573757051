<ng-container *ngIf="scenario$ | async as scenarios">
    <ng-container *ngIf="!(isNavigatedToInfoCheckPage$ | async)">
        <wuc-alert
                *ngFor="let scenario of scenarios"
                icon="privacy"
                title="Wichtig: Die Angaben Ihres Vorversicherers wurden nicht bestätigt"
                text="Bitte kontrollieren Sie schnellstmöglich Ihre Versicherungsdaten, um eine Beitragsanpassung zu vermeiden."
                type="primary"
                iconType="primary"
        >
            <wuc-button type="secondary" [isFullWidth]="true" (buttonClick)="redirectToInsuranceInfoCheck(scenario.productSequenceNumber)">Direkt erledigen</wuc-button>
        </wuc-alert>
    </ng-container>
</ng-container>



