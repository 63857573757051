<h2>Einfach selbst regeln</h2>

<!-- WIDL-1254: Temporary disabled
<h3 class="insurance-control__heading">Direkt zu</h3>
<ins-insurance-control-list *ngIf="relation">
    <ng-container *ngFor="let button of buttons">
        <ins-insurance-control-item
            *ngIf="button.category === productCategories.DirectlyTo"
            [button]="button"
            [disabled]="!(allProductsLoaded$ | async)"
            (clicked)="clickAction($event)"
        >
            {{ button.name }}
        </ins-insurance-control-item>
    </ng-container>
</ins-insurance-control-list>
-->

<h3 class="insurance-control__heading">Änderung der individuellen oder familiären Situation</h3>
<ins-insurance-control-list *ngIf="relation">
    <ng-container *ngFor="let button of buttons">
        <ins-insurance-control-item
            *ngIf="button.category === productCategories.PersonalOrFamily"
            [button]="button"
            [disabled]="!isLoggedIn"
            (clicked)="clickAction($event)"
        >
            {{ button.name }}
        </ins-insurance-control-item>
    </ng-container>
</ins-insurance-control-list>

<h3 class="insurance-control__heading">Ansehen oder ausdrucken</h3>
<ins-insurance-control-list *ngIf="relation">
    <ng-container *ngFor="let button of buttons">
        <ins-insurance-control-item
            *ngIf="button.category === productCategories.ViewOrPrint"
            [button]="button"
            [disabled]="!(allProductsLoaded$ | async)"
            (clicked)="clickAction($event)"
        >
            {{ button.name }}
        </ins-insurance-control-item>
    </ng-container>
</ins-insurance-control-list>
