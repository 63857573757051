import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { InsuranceRegularDriverDataModule } from './insurance-regular-driver-data';
import { InsuranceRegularDriverFormModule } from './insurance-regular-driver-form';
import { InsuranceRegularDriverFutureModule } from './insurance-regular-driver-future';
import { InsuranceRegularDriverModalModule } from './insurance-regular-driver-modal';
import { InsuranceRegularDriverComponent } from './insurance-regular-driver.component';
import { InsuranceRegularDriverContainer } from './insurance-regular-driver.container';
import { PanelExpandableModule } from '@app-de/shared/panel-expandable/panel-expandable.module';

@NgModule({
    imports: [
        CommonModule,
        InsuranceRegularDriverDataModule,
        InsuranceRegularDriverFutureModule,
        InsuranceRegularDriverFormModule,
        InsuranceRegularDriverModalModule,
        PanelExpandableModule,
    ],
    declarations: [
        InsuranceRegularDriverContainer,
        InsuranceRegularDriverComponent,
    ],
    exports: [InsuranceRegularDriverContainer],
})
export class InsuranceRegularDriverModule {}
