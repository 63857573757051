import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    forwardRef,
    Input,
    OnDestroy,
    Renderer2,
    ViewChild,
} from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
} from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AddressAutocompleteComponent } from './address-autocomplete.component';
import { AddressInterface } from './address.interface';
import { AddressAutocompleteService } from '@app-de/shared/address-autocomplete/address-autocomplete.service';
import {
    AddressFindResponseInterface,
    AddressFindResponseOptionsInterface,
} from '@app-de/shared/address-autocomplete/address-retrieve-option.interface';

@Component({
    selector: 'ins-address-autocomplete-container',
    templateUrl: './address-autocomplete.container.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AddressAutocompleteContainer),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => AddressAutocompleteContainer),
            multi: true,
        },
    ],
})
export class AddressAutocompleteContainer implements ControlValueAccessor, Validator, OnDestroy {
    @ViewChild(AddressAutocompleteComponent, { static: true })
    public addressAutocompleteComponent: AddressAutocompleteComponent;

    @Input() public formControlName: string;

    public value: AddressInterface;
    public disabled: boolean;

    public addressOptions$: Observable<AddressFindResponseOptionsInterface[]> =
        this.addressAutocompleteService.addressOptions$;

    public _onChange: (value: AddressInterface) => {};
    public _onTouched: () => {};

    private subscriptions: Subscription = new Subscription();
    private noStreetSelected: boolean = false;

    constructor(public addressAutocompleteService: AddressAutocompleteService, private renderer: Renderer2) {}

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    public writeValue(value: AddressInterface): void {
        const inputRef = this.addressAutocompleteComponent.addressIdInputRef;
        if (inputRef && value) {
            this.addressAutocompleteComponent.prefill(value);
        }
    }

    public registerOnChange(fn: any): void {
        this._onChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this._onTouched = fn;
    }

    public setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;

        const inputRef = this.addressAutocompleteComponent.addressIdInputRef;
        if (inputRef) {
            inputRef.nativeElement.disabled = isDisabled;
        }
    }

    public validate(control: AbstractControl): ValidationErrors {
        return this.noStreetSelected ? { noStreetSelected: true } : null;
    }

    public onPerformRetrieveSearch(configObject: {
        addressOption: AddressFindResponseOptionsInterface;
        optionsRef: ElementRef;
        inputRef: ElementRef;
    }): void {
        if (configObject.addressOption.Type === 'Address') {
            const requestResidentialAdressDetail$ = this.addressAutocompleteService.requestResidentialAdressDetail$(
                configObject.addressOption.Id,
            );

            this.subscriptions.add(
                requestResidentialAdressDetail$.subscribe((addressObject) =>
                    this.handleAdressDetails(configObject.optionsRef, addressObject),
                ),
            );

            return;
        }

        const addressAutocomplete$ = this.addressAutocompleteService
            .performFindSearch$(configObject)
            .pipe(
                tap((res: AddressFindResponseInterface) =>
                    this.addressAutocompleteService.setAddressOptions(this.flatten(res.Items)),
                ),
            );

        this.subscriptions.add(addressAutocomplete$.subscribe());
    }

    public onPerformAddressSearch(configObject: {
        searchQuery: string;
        optionsRef: ElementRef<HTMLUListElement>;
        postcode: string;
    }): void {
        this.noStreetSelected = false;

        // hide options and delete value when searchQuery is empty
        if (!configObject.searchQuery) {
            if (configObject.optionsRef && configObject.optionsRef.nativeElement) {
                this.renderer.setStyle(configObject.optionsRef.nativeElement, 'display', 'none');
            }

            this._onChange(null);

            return;
        }

        // show options when user starts typing
        if (configObject.optionsRef) {
            this.renderer.setStyle(configObject.optionsRef.nativeElement, 'display', 'block');
        }

        const userPostcode = configObject.postcode;
        const textToFilter = 'Postfach';
        const addressAutocomplete$ = this.addressAutocompleteService
            .performFindAddressSearch$(configObject, userPostcode)
            .pipe(
                map((res: AddressFindResponseInterface) =>
                    res.Items.filter((item) => !item.Text.includes(textToFilter)),
                ),
                tap((res: AddressFindResponseOptionsInterface[]) =>
                    this.addressAutocompleteService.setAddressOptions(new Set(res)),
                ),
            );

        this.subscriptions.add(addressAutocomplete$.subscribe());
    }

    private flatten(arr): any {
        return arr.reduce((flat, toFlatten) => {
            return flat.concat(Array.isArray(toFlatten) ? this.flatten(toFlatten) : toFlatten);
        }, []);
    }

    private handleAdressDetails(optionsRef: ElementRef, addressObject: AddressInterface): void {
        // hide options when user has selected final address
        this.renderer.setStyle(optionsRef.nativeElement, 'display', 'none');
        this.addressAutocompleteComponent.prefill(addressObject);

        this.addressAutocompleteService.setAddressOptions(null);

        if (!addressObject.street_name || !addressObject.civic_number) {
            this.noStreetSelected = true;
            this._onChange(null);
            this._onTouched();
            return;
        }

        this._onChange(addressObject);
    }
}
