import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CoveragePremiumInterface, NewModal, ProductBaseInterface } from 'outshared-lib';
import { YearRewardModal } from '../year-reward-modal/year-reward.modal';

@Component({
    selector: 'ins-coverage-detail',
    templateUrl: './coverage-detail.component.html',
    styleUrls: ['./coverage-detail.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoverageDetailComponent {
    @Input() public product: ProductBaseInterface;
    @Input() public calculation: CoveragePremiumInterface;
    @Input() public changeCoverage: boolean;
    @Input() public hasCancelButton: boolean = true;

    @Output() public readonly toggle: EventEmitter<void> = new EventEmitter();
    @Output() public readonly cancelFuturePurchasedCoverage: EventEmitter<{
        purchasedProductSequenceNumber: number;
        scrollId: string;
    }> = new EventEmitter();

    public IsLoadingCancelFutureCoverage: boolean = false;

    constructor(private modalService: NewModal) {}

    public getInfo(): boolean {
        this.modalService.open(YearRewardModal);
        return false;
    }

    public cancelFutureCoverage(): void {
        this.IsLoadingCancelFutureCoverage = true;
        this.cancelFuturePurchasedCoverage.emit({
            purchasedProductSequenceNumber: this.calculation.purchased_product_sequence_number,
            scrollId: this.product.purchased_product_sequence_number.toString(),
        });
    }
}
