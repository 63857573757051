import { ApiGetPaymentProcessResponseInterface, GetPaymentProcessResponseInterface } from '../interfaces';

export function mapPaymentProcessResponse(
    response: ApiGetPaymentProcessResponseInterface
): GetPaymentProcessResponseInterface {
    return {
        paymentSequenceNumber: response.betaling_volgnummer,
        externalTransactionId: response.extern_transactie_id,
        externalReferenceId: response.external_reference_id,
        internalTransactionId: response.intern_transactie_id,
    };
}
