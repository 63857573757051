import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NewModalModule } from 'outshared-lib';
import { ModalComponentsModule } from '@app-de/components';
import { CancelCorrespondenceAddressModal } from '@app-de/my-zone/shared/modals/cancel-correspondence-address-modal/cancel-correspondence-address.modal';
import { DateFormatPipeModule } from '@app-de/shared/pipes/date-format/date-format.module';

@NgModule({
    declarations: [CancelCorrespondenceAddressModal],
    imports: [
        CommonModule,
        NewModalModule,
        ModalComponentsModule,
        DateFormatPipeModule,
    ],
    exports: [CancelCorrespondenceAddressModal],
})
export class CancelCorrespondenceAddressModalModule {
}
