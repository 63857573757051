import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AccountRecoveryActionService, ErrorInterface, ForgotEmailRequestInterface, NewModalRef } from 'outshared-lib';
import { Subscription } from 'rxjs';
import { AccountRecoveryModalRoutesEnum } from '@app-de/routes';

enum EmailRecoveryMethodEnum {
    Phone = 'phone',
}

@Component({
    selector: 'ins-forgot-email-modal',
    templateUrl: 'forgot-email.modal.html',
})
export class ForgotEmailModal implements OnInit, OnDestroy {
    public get method(): AbstractControl {
        return this.form.get('method');
    }

    public get birthdate(): AbstractControl {
        return this.form.get('birthdate');
    }

    public get phone(): AbstractControl {
        return this.form.get('phone');
    }

    public submitting: boolean = false;
    public errors: ErrorInterface[] = [];
    public notifications: ErrorInterface[]; // TODO : Implement in outshared-lib
    public success: boolean;
    public recoveryMethod = EmailRecoveryMethodEnum;

    public form: UntypedFormGroup;
    public backLink = ['', { outlets: { modal: AccountRecoveryModalRoutesEnum.AccountRecovery } }];

    private subscriptions: Subscription = new Subscription();

    constructor(
        private actionService: AccountRecoveryActionService,
        private newModalRef: NewModalRef<ForgotEmailModal>,
    ) {}

    public ngOnInit(): void {
        this.initializeForm();
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    public onSubmit(): void {
        if (!this.form.valid) {
            this.form.markAllAsTouched();
            return;
        }
        this.submitting = true;
        this.errors = [];
        const request = this.createRequest();
        const forgotEmailSubscription = this.actionService.forgotEmail$(request).subscribe(
            (notifications: ErrorInterface[]) => {
                this.notifications = notifications;
                this.success = true;
                this.submitting = false;
            },
            (errors: ErrorInterface[]) => {
                this.errors = errors;
                this.submitting = false;
            },
        );

        this.subscriptions.add(forgotEmailSubscription);
    }

    public onCloseAfterSuccess(): void {
        this.newModalRef.close(true);
    }

    private createRequest(): ForgotEmailRequestInterface {
        return {
            recoveryData: {
                birthdate: this.birthdate.value,
                phone: this.phone.value,
            },
        };
    }

    private initializeForm(): void {
        this.form = new UntypedFormGroup({
            method: new UntypedFormControl(EmailRecoveryMethodEnum.Phone, [Validators.required]),
            birthdate: new UntypedFormControl('', [Validators.required]),
            phone: new UntypedFormControl('', [Validators.required]),
        });
    }
}
