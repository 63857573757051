import { ChangeDetectorRef, Component, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import {
    ApiErrorLevelEnum,
    ApiGenderCodeEnum,
    ApiLegacyError,
    ApiValidityStatusCodeEnum,
    ErrorInterface,
    mapErrors,
    NewModal as ModalService,
    ProductActionService,
    ProductBaseInterface,
    ProductEnGbInterface,
    RelationActionService,
} from 'outshared-lib';
import { Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { PremiumImpactModal } from '../../premium-impact-modal';
import { InsuranceRegularDriverModal } from '../insurance-regular-driver-modal';
import { CancelFutureRegularDriverRequestInterface } from '../interfaces';
import { SelectedPanelEnum } from '@app-de/my-zone/enums';

@Component({
    selector: 'ins-insurance-regular-driver-future',
    templateUrl: './insurance-regular-driver-future.component.html',
    styleUrls: ['./insurance-regular-driver-future.component.scss'],
})
export class InsuranceRegularDriverFutureComponent implements OnDestroy {
    @Input() public product: ProductBaseInterface;

    public isLoading: boolean;
    public errors: ErrorInterface[];

    // Change to ValidityStatusCodeEnum if insurance-policy store is implemented
    public apiValidityStatusCodeEnum: typeof ApiValidityStatusCodeEnum;
    // Change to GenderCodeEnum if insurance-policy store is implemented
    public apiGenderCodeEnum: typeof ApiGenderCodeEnum;

    public get isProductActive(): boolean {
        return this.product?.validity_status_code === ApiValidityStatusCodeEnum.Active ||
            this.product?.validity_status_code === ApiValidityStatusCodeEnum.ActiveEnded;
    }

    private subscriptions: Subscription;

    constructor(
        private modalService: ModalService,
        private productActionService: ProductActionService,
        private relationActionService: RelationActionService,
        private changeDetectorRef: ChangeDetectorRef,
        private router: Router
    ) {
        this.isLoading = false;
        this.errors = [];
        this.apiValidityStatusCodeEnum = ApiValidityStatusCodeEnum;
        this.apiGenderCodeEnum = ApiGenderCodeEnum;
        this.subscriptions = new Subscription();
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    public cancelDriver(event: MouseEvent): void {
        event.preventDefault();

        this.modalService
            .open<InsuranceRegularDriverModal>(InsuranceRegularDriverModal)
            .afterClosed$()
            .pipe(filter((result) => Boolean(result)))
            .subscribe(() => this.onAfterClosed());
    }

    private onAfterClosed(): void {
        this.onCancelFutureRegularDriver({
            purchased_product_sequence_number: this.product.purchased_product_sequence_number,
        });
    }

    // TODO: WIDL-1504 replace with new insurance-policy store
    private onCancelFutureRegularDriver(request: CancelFutureRegularDriverRequestInterface): void {
        const cancelFutureNonRegularDriver = this.productActionService
            .cancelFutureNonRegularDriver$(request)
            .subscribe({
                next: () => this.displayModifiedPremium(),
                error: (errors) => this.onError(errors),
            });

        this.onStart();
        this.subscriptions.add(cancelFutureNonRegularDriver);
    }

    private onStart(): void {
        this.isLoading = true;
        this.errors = [];
        this.changeDetectorRef.detectChanges();
    }

    private displayModifiedPremium(): void {
        this.relationActionService
            .premiumModificationPurchasedProducts$(
                {
                    referenceDate: this.product.non_regular_driver_future.start_date,
                    sequenceNumber: this.product.purchased_product_sequence_number,
                }
            ).pipe(
                take(1)
            ).subscribe(response => {
                this.isLoading = false;
                this.changeDetectorRef.detectChanges();
                this.closePanel();
                this.showModal(response);
            });
    }

    private onError(errors: ApiLegacyError[]): void {
        this.isLoading = false;
        this.errors = this.mapErrors(errors);
        this.changeDetectorRef.detectChanges();
    }

    private closePanel(): void {
        const queryParams = {
            panel: SelectedPanelEnum.Close,
            purchased_product: this.product.purchased_product_sequence_number,
            scroll: true,
        };

        this.router.navigate([], {
            queryParams,
            queryParamsHandling: 'merge',
        });
    }

    private showModal(products: ProductEnGbInterface[]): void {
        this.isLoading = false;
        const modalRef = this.modalService.open(PremiumImpactModal);
        modalRef.componentInstance.products = products;
    }

    // Some notification types are not recognized so we map everything as Error.
    private mapErrors(errors: ApiLegacyError[]): ErrorInterface[] {
        const _errors = errors.map((error) => ({
            ...error,
            notification_type: ApiErrorLevelEnum.Error,
        }));

        return mapErrors(_errors);
    }
}
