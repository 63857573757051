<ins-card [white]="true" [borders]="true" [margin]="false">
    <ins-mobile-scroll>
        <ins-rack>
            <ins-rack-head size="big">
                <ins-rack-row>
                    <ins-rack-col [flex]="2">
                        <ng-container *ngIf="invoiceDetails?.invoice.debitCredit === debitCreditEnum.Credit">
                            Gesamtbetrag
                        </ng-container>
                        <ng-container *ngIf="invoiceDetails?.invoice.debitCredit === debitCreditEnum.Debit">
                            Gesamtbetrag
                        </ng-container>
                    </ins-rack-col>
                    <ins-rack-col [flex]="1" textAlign="right">
                        <ins-invoice-price
                            [highlight]="true"
                            [debitCredit]="invoiceDetails?.invoice.debitCredit"
                            [price]="invoiceDetails?.invoice.total"
                        ></ins-invoice-price>
                    </ins-rack-col>
                </ins-rack-row>
            </ins-rack-head>
            <ins-rack-body *ngIf="invoiceDetails?.invoice.debitCredit === debitCreditEnum.Debit">
                <ins-rack-row>
                    <ins-rack-col>
                        <small>
                            Der Beitrag wird 3–6 Tage nach dem
                            {{ invoiceDetails?.invoice.invoiceDate | dateFormat : 'DD.MM.YYYY' }} abgebucht.
                        </small>
                    </ins-rack-col>
                </ins-rack-row>
            </ins-rack-body>
        </ins-rack>
    </ins-mobile-scroll>
</ins-card>
