import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
    TermsAndConditionRoutingModule,
} from '@app-de/my-zone/pages/terms-and-condition/terms-and-condition-routing.module';
import {
    TermsAndConditionComponent,
} from '@app-de/my-zone/pages/terms-and-condition/terms-and-condition.component';
import {
    TermsAndConditionContainer,
} from '@app-de/my-zone/pages/terms-and-condition/terms-and-condition.container';
import { CardModule } from '@app-de/shared/card/card.module';
import { ListModule } from '@app-de/shared/lists/list.module';

@NgModule({
    declarations: [
        TermsAndConditionComponent,
        TermsAndConditionContainer,
    ],
    imports: [
        CommonModule,
        TermsAndConditionRoutingModule,
        ListModule,
        CardModule,
    ],
})
export class TermsAndConditionModule {}
