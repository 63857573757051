import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { ScrollService } from '@app-de/core/services/scroll.service';
import { MyZoneRoutes } from '@app-de/my-zone/routing/my-zone-routing.routes';
import { AppRoutesEnum } from '@app-de/routes';

@Component({
    selector: 'ins-mobile-menu-my-zone',
    templateUrl: './mobile-menu-my-zone.component.html',
    styleUrls: ['./mobile-menu-my-zone.component.scss'],
})
export class MobileMenuMyZoneComponent {
    public active: boolean;
    public baseUrl: string;
    public homeActive: boolean;
    public appRoutes = AppRoutesEnum;
    public myZoneRoutes = MyZoneRoutes;

    constructor(
        private scrollService: ScrollService,
        @Inject(DOCUMENT) private document: Document
    ) {
        this.baseUrl = this.document.location.origin;
    }

    public toggle(): boolean {
        this.active = !this.active;
        return false;
    }

    public closeMenu(): void {
        this.active = false;
        this.scrollService.scrollToTop();
    }
}
