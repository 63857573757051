import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CoverageDetailModule } from '../coverage-detail/coverage-detail.module';
import { InsuranceDataModule } from '../insurance-data/insurance-data.module';
import { InsuranceRegularDriverModule } from '../insurance-regular-driver';
import { PolicyDetailsModule } from '../policy-details/policy-details.module';
import {
    PremiumDeterminingFactorsModule,
} from '../premium-determining-factors/premium-determining-factors.module';
import { InsuranceDetailComponent } from './insurance-detail.component';
import {
    InsuranceDetailContainer,
} from '@app-de/my-zone/shared/insurance-detail/insurance-detail.container';
import { InternationalMotorInsuranceCardModule } from '@app-de/my-zone/shared/international-motor-insurance-card/international-motor-insurance-card.module';
import { PanelExpandableModule } from '@app-de/shared/panel-expandable/panel-expandable.module';
import {
    InsuranceAdditionalDriverComponent
} from "@app-de/my-zone/shared/insurance-additional-driver/insurance-additional-driver.component";

@NgModule({
    imports: [
        CommonModule,
        PanelExpandableModule,
        PolicyDetailsModule,
        InsuranceDataModule,
        InsuranceRegularDriverModule,
        PremiumDeterminingFactorsModule,
        CoverageDetailModule,
        InternationalMotorInsuranceCardModule,
        InsuranceAdditionalDriverComponent,
    ],
    exports: [InsuranceDetailContainer],
    declarations: [
        InsuranceDetailComponent,
        InsuranceDetailContainer,
    ],
})
export class InsuranceDetailModule {}
