import {
    AfterContentInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ContentChild,
    ContentChildren,
    ElementRef,
    HostListener,
    Input,
    OnDestroy,
    QueryList,
} from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { CynoFieldControl } from '../core/cyno-field-control';
import { CynoInfoDirective } from '../cyno-directives/cyno-info.directive';
import { CynoLabelDirective } from '../cyno-directives/cyno-label.directive';
import { CynoErrorComponent } from '@app-de/shared/cyno-form/cyno-error/cyno-error.component';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'cyno-field',
    templateUrl: './cyno-field.component.html',
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: {
        class: 'form-group',
        '[class.has-error]': 'hasError()',
        '[class.has-success]': 'hasSuccess()',
        '[class.is-pending]': 'isPending()',
    },
    styles: [
        `
            :host {
                display: block;
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CynoFieldComponent implements AfterContentInit, OnDestroy {
    @ContentChild(CynoFieldControl, { static: true }) public control: CynoFieldControl<any>;
    @ContentChild(CynoInfoDirective, { static: true }) public info: CynoInfoDirective;
    @ContentChild(CynoLabelDirective, { static: true }) public label: CynoLabelDirective;

    @ContentChildren(CynoFieldControl) public controls: QueryList<CynoFieldControl<any>>;
    @ContentChildren(CynoErrorComponent) public errorChildren: QueryList<CynoErrorComponent>;

    @Input() public tooltip: string = '';
    @Input() public inline: boolean = true;
    @Input() public withButton: boolean = false;
    @Input() public showSuccess: boolean = true;
    @Input() public checkParent: boolean = false; // when true, valid/error/pending state of parent form will also be checked

    public showInfo: boolean = false;

    private subscriptions = new Subscription();

    constructor(private changeDetectorRef: ChangeDetectorRef, private _el: ElementRef) {}

    @HostListener('document:click', ['$event'])
    public onDocumentClick(event): void {
        if (!this.showInfo) {
            return;
        }

        if (!this._el.nativeElement.contains(event.target)) {
            this.showInfo = false;
        }
    }

    public ngAfterContentInit(): void {
        this.checkInputExists();

        this.controls.forEach((control: CynoFieldControl<any>) => {
            if (control.ngControl) {
                const valueChanges = control.ngControl.valueChanges.subscribe(() =>
                    this.changeDetectorRef.markForCheck(),
                );

                const statusChanges = control.ngControl.statusChanges.subscribe(() =>
                    this.changeDetectorRef.markForCheck(),
                );

                this.subscriptions.add(valueChanges);
                this.subscriptions.add(statusChanges);
            }
        });
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    public hasError(): boolean {
        let hasError = this.controls.some(
            (control: CynoFieldControl<any>) => control.ngControl.invalid && control.ngControl.touched,
        );
        if (!hasError && this.checkParent) {
            const hasValuesAllControls = !this.controls.some(
                (control: CynoFieldControl<any>) => !control.ngControl.value,
            );
            if (hasValuesAllControls) {
                hasError = this.parentForm.invalid;
            }
        }
        return hasError;
    }

    public hasSuccess(): boolean {
        const isValidParentForm = this.checkParent ? this.parentForm.valid : true;
        const isValidControls =
            this.controls.filter((control: CynoFieldControl<any>) => control.ngControl.invalid).length === 0;
        return this.showSuccess && isValidParentForm && isValidControls;
    }

    public isPending(): boolean {
        const isPendingParentForm = this.checkParent ? this.parentForm.pending : false;
        const isPendingSomeControls = this.controls.some((control: CynoFieldControl<any>) => control.ngControl.pending);
        return isPendingParentForm || isPendingSomeControls;
    }

    public hasIconInfo(): boolean {
        if (this.hasInfoDirective()) {
            return this.info.visible;
        }
        return this.tooltip.length > 0;
    }

    public hasInfoDirective(): boolean {
        return !!this.info;
    }

    public onTooltip(event: Event): boolean {
        if (!this.hasInfoDirective()) {
            return;
        }

        this.showInfo = !this.showInfo;

        event.stopPropagation();

        return false;
    }

    private checkInputExists(): void {
        if (!this.control && this.controls.length === 0) {
            throw new Error('Input is missing from field!');
        }
    }

    private get parentForm(): UntypedFormGroup | UntypedFormArray {
        return this.controls.first.ngControl.control.parent;
    }

    public closeTooltip() {
        this.showInfo = false;
    }
}
