import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CarAdditionalCoverageInfoComponent } from './car-additional-coverage-info.component';
import { CarBaseCoverageInfoComponent } from './car-base-coverage-info.component';
import { ListModule } from '@app-de/shared/lists/list.module';
import { TabsetModule } from '@app-de/shared/tabs/tabset.module';

@NgModule({
    declarations: [
        CarBaseCoverageInfoComponent,
        CarAdditionalCoverageInfoComponent,
    ],
    imports: [
        CommonModule,
        ListModule,
        TabsetModule,
    ],
    exports: [
        CarBaseCoverageInfoComponent,
        CarAdditionalCoverageInfoComponent,
    ],
})
export class CarCoverageInfoModule {}
