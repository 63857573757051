import { Component, Inject } from '@angular/core';
import { NewModalRef, NEW_MODAL_DATA } from 'outshared-lib';

interface ModalData {
    coverage_id: string;
    coverage_title: string;
}
@Component({
    selector: 'ins-additional-coverage-info-modal',
    templateUrl: './additional-coverage-info.modal.html',
})
export class AdditionalCoverageInfoModal {
    constructor(
        public modalRef: NewModalRef<AdditionalCoverageInfoModal>,
        @Inject(NEW_MODAL_DATA) public data: ModalData
    ) {}

    public close(): boolean {
        this.modalRef.close();
        return false;
    }
}
