import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { InternationalRelationInterface } from 'outshared-lib';

@Component({
    selector: 'ins-personal-details-data',
    templateUrl: 'personal-details-data.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PersonalDetailsDataComponent {
    @Input() public relation: InternationalRelationInterface;
}
