import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
    ErrorInterface,
    IndicationEnum,
    RelationActionService,
    RelationDataService,
    RelationInterface,
} from 'outshared-lib';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';

@Component({
    selector: 'ins-opt-out-form-container',
    template: `
        <ins-opt-out-form
            [errors]="errors | async"
            [relation]="relation$ | async"
            (submitted)="onSubmit($event)"
        ></ins-opt-out-form>
    `,
})
export class OptOutFormContainer implements OnInit, OnDestroy {
    public relation$: Observable<RelationInterface>;
    public errors: BehaviorSubject<ErrorInterface[]> = new BehaviorSubject<ErrorInterface[]>(null);

    private subscriptions: Subscription = new Subscription();

    constructor(
        private router: Router,
        private relationActionService: RelationActionService,
        private relationDataService: RelationDataService,
    ) {}

    public ngOnInit(): void {
        this.relation$ = this.relationDataService.getRelation$().pipe(
            filter((relation: RelationInterface) => !!relation),
            take(1),
        );
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    public onSubmit(optOutIndication: IndicationEnum): void {
        const request$ = this.relationActionService.saveOptOut$({ optOutIndication });
        const requestSubscription = request$.subscribe({
            next: () => this.closePanel(),
            error: (errors) => this.errors.next(errors),
        });
        this.subscriptions.add(requestSubscription);
    }

    private closePanel(): void {
        this.router.navigate([], {
            queryParams: {
                panel: null,
                scrollTo: null,
                expand: null,
            },
            queryParamsHandling: 'merge',
        });
    }
}
