import { Component, Input } from '@angular/core';
import { CoverageIdEnum } from '@app-de/core/enums/coverage-id.enum';

@Component({
    selector: 'ins-car-base-coverage-info',
    templateUrl: './car-base-coverage-info.component.html',
})
export class CarBaseCoverageInfoComponent {
    @Input() public coverageId: string;

    public CoverageIdEnum = CoverageIdEnum;
}
