import { Component, Input } from '@angular/core';
import { InvoiceDetailsInterface } from 'outshared-lib';

@Component({
    selector: 'ins-invoice-advance',
    templateUrl: 'invoice-advance.component.html',
})
export class InvoiceAdvanceComponent {
    @Input() public invoiceDetails: InvoiceDetailsInterface;
}
