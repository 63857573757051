import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ModalComponentsModule } from '@app-de/components';
import { ConfirmationModal } from '@app-de/shared/modals/confirmation-modal/confirmation.modal';

@NgModule({
    declarations: [ConfirmationModal],
    imports: [
        CommonModule,
        ModalComponentsModule,
    ],
    exports: [ConfirmationModal],
})
export class ConfirmationModalModule {
}
