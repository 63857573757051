import { AfterViewInit, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { NewModal } from 'outshared-lib';
import { Subscription } from 'rxjs';
import { IntersectionObserverService } from '@app-de/core/services/intersection-observer.service';
import { AppRoutesEnum } from '@app-de/routes';
import { ChatbotModalComponent } from '@app-de/shared/customer-service/chatbot/chatbot-modal/chatbot-modal.component';

@Component({
    selector: 'ins-chatbot-fixed',
    templateUrl: './chatbot-fixed.component.html',
    styleUrls: ['./chatbot-fixed.component.scss'],
})
export class ChatbotFixedComponent implements AfterViewInit, OnChanges, OnDestroy {
    @Input() public currentPath: string;
    @Input() public currentUrl: string;

    public positionY: number;
    public hide: boolean = false;
    public funnel: boolean = false;
    public active: boolean = true;
    private subscription: Subscription = new Subscription();

    constructor(
        private newModal: NewModal,
        private changeDetectorRef: ChangeDetectorRef,
        private intersectionObserverService: IntersectionObserverService,
    ) {}

    public ngAfterViewInit(): void {
        const scroller = this.intersectionObserverService.onScroll$.subscribe((scrollData): void => {
            this.hide = scrollData?.percentage > 86;
            this.changeDetectorRef.detectChanges();
        });

        this.subscription.add(scroller);
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (this.currentUrl) {
            this.active = this.currentUrl !== '/' + AppRoutesEnum.Contact;
        }
        if (changes.currentPath) {
            const regex = new RegExp('berekenen-', 'g');
            const regexTemporaryURL = new RegExp('-berekenen', 'g');

            this.funnel =
                changes.currentPath.currentValue?.match(regex) ||
                changes.currentPath.currentValue?.match(regexTemporaryURL);
        }
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public openChat(): void {
        this.newModal.open<ChatbotModalComponent>(ChatbotModalComponent);
    }
}
