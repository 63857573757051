import { Component, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'ins-nav-sidebar',
    templateUrl: './nav-sidebar.component.html',
    styleUrls: ['./nav-sidebar.component.scss'],
    encapsulation: ViewEncapsulation.None,
})

export class NavSidebarComponent {
}
