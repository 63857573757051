import { ChangeDetectionStrategy, Component, Input, OnDestroy } from '@angular/core';
import {
    CoveragePremiumActionService,
    CoveragePremiumInterface,
    NotificationInterface,
    ProductBaseInterface,
} from 'outshared-lib';
import { Observable, Subscription } from 'rxjs';
import { CoverageDetailService } from './coverage-detail.service';
import { ScrollService } from '@app-de/core/services/scroll.service';

@Component({
    selector: 'ins-coverage-detail-container',
    template: `
        <ins-coverage-detail
            *ngIf="calculation$ | async"
            [product]="product$ | async"
            [calculation]="calculation$ | async"
            [changeCoverage]="changeCoverage$ | async"
            [hasCancelButton]="hasCancelButton"
            (toggle)="toggle()"
            (cancelFuturePurchasedCoverage)="cancelFuturePurchasedCoverage($event)"
        ></ins-coverage-detail>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoverageDetailContainer implements OnDestroy {
    @Input() public hasCancelButton: boolean;

    public product$: Observable<ProductBaseInterface> = this.coverageDetailService.selectedProduct$;
    public calculation$: Observable<CoveragePremiumInterface> = this.coverageDetailService.calculation$;
    public changeCoverage$: Observable<boolean> = this.coverageDetailService.changeCoverage$;

    private subscriptions: Subscription = new Subscription();

    constructor(
        private coverageDetailService: CoverageDetailService,
        private coveragePremiumActionService: CoveragePremiumActionService,
        private scrollService: ScrollService,
    ) {}

    public toggle(): void {
        this.coverageDetailService.toggleChangeCoverage();
    }

    public cancelFuturePurchasedCoverage({
        purchasedProductSequenceNumber,
        scrollId,
    }: {
        purchasedProductSequenceNumber: number;
        scrollId: string;
    }): void {
        // reset notifications
        this.coverageDetailService.notifications$.next([]);

        this.subscriptions.add(
            this.coveragePremiumActionService
                .cancelFuturePurchasedCoverage({
                    purchased_product_sequence_number: purchasedProductSequenceNumber,
                })
                .subscribe({
                    error: (error: NotificationInterface[]) => {
                        this.coverageDetailService.notifications$.next(error);
                    },
                    next: (): void => {
                        this.scrollService.scrollElementOffset(`#product_${scrollId}`);
                    },
                }),
        );
    }

    public ngOnDestroy(): void {
        this.coverageDetailService.setChangeCoverageState(false);
        this.subscriptions.unsubscribe();
    }
}
