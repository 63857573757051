import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RelationDataService, RelationInterface } from 'outshared-lib';
import { Observable } from 'rxjs';

@Component({
    selector: 'ins-my-zone-container',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <ins-my-zone [relation]="relation$ | async"></ins-my-zone>
    `,
})
export class MyZoneContainer {
    public relation$: Observable<RelationInterface> = this.relationDataService.getRelation$();

    constructor(private relationDataService: RelationDataService) {}
}
