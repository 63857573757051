import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { GetInsuranceInfoCheckResponseInterface } from '../interfaces';
import { InsuranceInfoCheckSelectors, InsuranceInfoCheckState } from '../store';

@Injectable({
    providedIn: 'root',
})
export class InsuranceInfoCheckDataService {
    constructor(private store$: Store<InsuranceInfoCheckState>) {}

    public getState$(): Observable<InsuranceInfoCheckState> {
        return this.store$.select(InsuranceInfoCheckSelectors.getState);
    }

    public getInsuranceInfoCheck$(): Observable<GetInsuranceInfoCheckResponseInterface> {
        return this.store$.select(InsuranceInfoCheckSelectors.getInsuranceInfoCheck);
    }
}
