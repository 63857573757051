<div class="cookie-settings__items">
    <div class="cookie-settings__button cookie-settings__consent-all-button">
        <wuc-button
            [isFullWidth]="true"
            type="primary"
            (buttonClick)="onClick(cookieConsent.All)"
            i18n="@@feature-cookie.modal.button-all"
            [ariaLabel]="ariaLabelAll"
        >
            Agree
        </wuc-button>
    </div>

    <div class="cookie-settings__button cookie-settings__consent-basic-button">
        <wuc-button
            [isFullWidth]="true"
            type="secondary"
            (buttonClick)="onClick(cookieConsent.Basic)"
            i18n="@@feature-cookie.modal.button-basic"
            [ariaLabel]="ariaLabelBasic"
        >
            Refuse
        </wuc-button>
    </div>
</div>
