import { Directive, ElementRef, Output, EventEmitter, HostListener } from '@angular/core';

@Directive({
    selector: '[ins-clickOutside]',
})
export class ClickOutsideDirective {
    @Output() public readonly clickedOutside = new EventEmitter<MouseEvent>();
    constructor(private _elementRef: ElementRef) {}

    @HostListener('document:click', ['$event', '$event.target']) public onClick(
        event: MouseEvent,
        targetElement: HTMLElement,
    ): void {
        if (!targetElement) {
            return;
        }

        const clickedInside = this._elementRef.nativeElement.contains(targetElement);
        if (!clickedInside) {
            this.clickedOutside.emit(event);
        }
    }
}
