import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as productScenariosActions from '../store/product-scenarios.actions';
import { ProductScenariosState } from '../store/product-scenarios.state';
import { ProductScenariosEventService } from './product-scenarios.event.service';

@Injectable({
    providedIn: 'root',
})
export class ProductScenariosActionService {
    constructor(
        private store$: Store<ProductScenariosState>,
        private productScenariosEventService: ProductScenariosEventService
    ) {}

    public loadProductScenarios$(): Observable<void> {
        this.store$.dispatch(productScenariosActions.loadProductScenarios());
        return this.productScenariosEventService.loadProductScenarios$();
    }

    public clearProductScenarios(): void {
        this.store$.dispatch(productScenariosActions.clearProductScenarios());
    }
}
