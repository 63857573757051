import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BreadcrumbBorderedComponent } from './breadcrumb-bordered.component';
import { BreadcrumbBorderedContainer } from './breadcrumb-bordered.container';
import { BreadcrumbComponent } from './breadcrumb.component';
import { BreadcrumbContainer } from './breadcrumb.container';
import { SloganModule } from '@app-de/shared/layout/base-layout/slogan/slogan.module';

@NgModule({
    imports: [CommonModule, RouterModule, SloganModule],
    declarations: [BreadcrumbContainer, BreadcrumbComponent, BreadcrumbBorderedContainer, BreadcrumbBorderedComponent],
    exports: [BreadcrumbContainer, BreadcrumbComponent, BreadcrumbBorderedContainer, BreadcrumbBorderedComponent],
})
export class BreadcrumbModule {}
