<ins-modal-close></ins-modal-close>

<div class="is-customer-modal">
    <h3>Sind Sie bereits inshared-Kunde?</h3>
    <ins-login *ngIf="_showLogin" [loading]="loading$ | async" (login)="loginUser($event)"></ins-login>
    <wuc-alert *ngFor="let error of errors" icon="privacy" type="primary" text="{{ error.message }}"> </wuc-alert>

    <div class="btn-group btn-group--spread">
        <button (click)="cancel()" *ngIf="!_showLogin" class="btn btn--link">Nein, noch nicht</button>
        <button (click)="showLogin()" *ngIf="!_showLogin" class="btn btn--primary" e2e="modal_submit">Anmelden</button>
    </div>
</div>
