import { Injectable } from '@angular/core';

export interface BackendMapping {
    [key: string]: {
        [key: string]: string[];
    };
}

export interface FormValues {
    [key: string]: string;
}

@Injectable({
    providedIn: 'root',
})
export class SalesfunnelBackendHelper {
    public static mapToBackend(values: FormValues, mapping: BackendMapping): string[] {
        const result: string[] = Object.values(values).filter((value) => !!value);
        const reducer = (accumulator: string[], value: string): string[] => {
            for (const category in mapping) {
                if (!mapping.hasOwnProperty(category)) {
                    continue;
                }

                const collection = mapping[category];
                if (collection.hasOwnProperty(value)) {
                    return [...accumulator, ...collection[value]];
                }
            }
            return [...accumulator, value];
        };
        return result.reduce(reducer, []);
    }

    public static mapFromBackend<T>(values, selectedCoverages: string[], mapping: BackendMapping): T {
        for (const category in mapping) {
            if (!mapping.hasOwnProperty(category)) {
                continue;
            }

            const collection = mapping[category];
            for (const deviationModule in collection) {
                if (!collection.hasOwnProperty(deviationModule)) {
                    continue;
                }

                const hasResult = collection[deviationModule].every((module) => selectedCoverages.includes(module));
                if (hasResult) {
                    values[category] = deviationModule;
                }
            }
        }

        for (const value in values) {
            if (!values.hasOwnProperty(value)) {
                continue;
            }

            if (selectedCoverages.includes(value)) {
                values[value] = value;
            }
        }

        return values;
    }
}
