<ins-modal-close></ins-modal-close>
<ins-modal-title>Änderung widerrufen</ins-modal-title>
<ins-modal-content>
    <p>Haben Sie aus Versehen Ihre Adresse geändert? Kein Problem.</p>
    <div *ngIf="data?.mailingAddress?.address">
        <div class="form-group">
            <label class="control-label" for="address">Zukünftige Postadresse</label>
            <div id="address" class="control-input">
                <span>{{ data?.mailingAddress.address.street_name }}</span>
                <span>{{ data?.mailingAddress.civic_number }}{{ data?.mailingAddress.civic_number_suffix }},</span>
                <!-- prettier-ignore -->
                <span>{{ data?.mailingAddress.postal_code }} </span>
                <span>{{ data?.mailingAddress.address.city }}</span>
            </div>
        </div>

        <div class="form-group">
            <label class="control-label" for="startDate">Beginndatum</label>
            <div id="startDate" class="control-input">
                <span>{{ data?.mailingAddress?.start_date | dateFormat }}</span>
            </div>
        </div>
    </div>
</ins-modal-content>
<ins-modal-actions alignment="between">
    <button class="btn btn--link" (click)="close()">Widerrufen</button>
    <button class="btn btn--primary" ins_debounceClick (click)="confirm()" e2e="cancel-correspondence-address-button">
        Ja, Änderung widerrufen
    </button>
</ins-modal-actions>
