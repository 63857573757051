import { createSelector } from '@ngrx/store';
import * as fromLayout from './layout/layout.reducer';
import * as fromRouter from './router/router.reducer';

export interface State {
    layout: fromLayout.State;
    router: fromRouter.State;
}

export const reducers = {
    layout: fromLayout.reducer,
    router: fromRouter.reducer,
};

/**
 *  Router selectors
 */
export const getRouterState = (state: State): fromRouter.State => state.router;
export const getPath = createSelector(getRouterState, fromRouter.getPath);
export const getParams = createSelector(getRouterState, fromRouter.getParams);
