import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DropdownToggleModule } from '../../directives/dropdown/dropdown.module';
import { CountrySelectorComponent } from './country-selector.component';
import { CountrySelectorService } from '@app-de/shared/country-selector/service/country-selector.service';

@NgModule({
    declarations: [CountrySelectorComponent],
    imports: [
        CommonModule,
        DropdownToggleModule,
    ],
    exports: [CountrySelectorComponent],
    providers: [CountrySelectorService],
})
export class CountrySelectorModule {
}
