import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { InvoiceEffects } from './store/invoice.effects';
import { InvoiceReducers } from './store/invoice.reducers';
import { INVOICE_FEATURE } from './store/invoice.state';

@NgModule({
    imports: [
        StoreModule.forFeature(INVOICE_FEATURE, InvoiceReducers.reducer),
        EffectsModule.forFeature([InvoiceEffects]),
    ],
})
export class OutInvoiceModule {}
