import { AfterViewInit, Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
    selector: '[insShowPassword]',
    exportAs: 'showPassword',
})
export class ShowPasswordDirective implements AfterViewInit {
    private visible = false;
    private timer;

    constructor(private renderer: Renderer2, private element: ElementRef) {}

    public ngAfterViewInit(): void {
        const parent = this.element.nativeElement.parentNode as HTMLElement;
        const input = this.element.nativeElement as HTMLElement;

        this.renderer.insertBefore(parent, this.eyeRef(), input);
    }

    private eyeRef(): HTMLElement {
        const parent = this.renderer.createElement('div') as HTMLElement;
        const anker = this.renderer.createElement('a') as HTMLElement;

        this.renderer.addClass(parent, 'show-password__icon');
        this.renderer.addClass(anker, 'icon-eye');
        this.renderer.appendChild(parent, anker);

        anker.addEventListener('click', () => this.toggle(anker));

        return parent;
    }

    private toggle(anker: HTMLElement): void {
        const input = this.element.nativeElement as HTMLElement;

        this.visible = !this.visible;
        this.clearTimer();

        if (this.visible) {
            this.show(true, anker, input);
            this.setTimer(anker, input);
        } else {
            this.show(false, anker, input);
        }
    }

    private setTimer(anker: HTMLElement, input: HTMLElement): void {
        this.timer = setTimeout(() => {
            this.show(false, anker, input);
            this.visible = false;
        }, 2000);
    }

    private clearTimer(): void {
        if (this.timer) {
            clearTimeout(this.timer);
        }
    }

    private show(state: boolean, anker: HTMLElement, input: HTMLElement): void {
        if (state) {
            this.renderer.removeClass(anker, 'icon-eye');
            this.renderer.addClass(anker, 'icon-eye-slash');

            this.renderer.removeAttribute(input, 'type');
            this.renderer.setAttribute(input, 'type', 'text');
        } else {
            this.renderer.addClass(anker, 'icon-eye');
            this.renderer.removeClass(anker, 'icon-eye-slash');

            this.renderer.removeAttribute(input, 'type');
            this.renderer.setAttribute(input, 'type', 'password');
        }
    }
}
