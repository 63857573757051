import { Component, Inject, OnInit } from '@angular/core';
import { DateService, NewModalRef as ModalRef, NEW_MODAL_DATA } from 'outshared-lib';
import { TerminateInsuranceFeedbackModelData } from './terminate-insurance-feedback-modal-data';

@Component({
    selector: 'ins-terminate-insurance-feedback',
    templateUrl: 'terminate-insurance-feedback.modal.html',
})
export class TerminateInsuranceFeedbackModal implements OnInit {

    constructor(
        private modalRef: ModalRef<TerminateInsuranceFeedbackModal>,
        private readonly dateService: DateService,
        @Inject(NEW_MODAL_DATA) public data: TerminateInsuranceFeedbackModelData
    ) {
    }

    public ngOnInit(): void {
        this.data.endDate = this.dateService.subtract(this.data.endDate, 1, 'day');
    }

    public dismiss(): void {
        this.modalRef.close();
    }
}
