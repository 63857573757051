<ins-modal-close></ins-modal-close>

<ins-modal-title>{{ model.title }}</ins-modal-title>

<ins-modal-content>
    <p>{{ model.body }}</p>
</ins-modal-content>

<ins-modal-actions [alignment]="model?.alignment">
    <button *ngIf="model.cancel" class="btn btn--link" (click)="dismiss()">
        {{ model.cancel }}
    </button>
    <button *ngIf="model.confirm" class="btn btn--primary" (click)="close()">
        {{ model.confirm }}
    </button>
</ins-modal-actions>
