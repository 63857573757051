import { Component } from '@angular/core';
import {
    NewModalRef as ModalRef,
} from 'outshared-lib';

@Component({
    selector: 'ins-insurance-regular-driver-modal',
    templateUrl: './insurance-regular-driver.modal.html',
})
export class InsuranceRegularDriverModal {
    constructor(
        private modalRef: ModalRef<InsuranceRegularDriverModal>
    ) {}

    public close(): void {
        this.modalRef.close(false);
    }

    public submit(): void {
        this.modalRef.close(true);
    }
}
