import { Component, HostBinding, Input } from '@angular/core';

@Component({
    selector: 'ins-rack-head',
    templateUrl: 'rack-head.component.html',
    styleUrls: ['rack-head.component.scss'],
})
export class RackHeadComponent {
    @HostBinding('class.rack-head') public hostClass = true;
    @HostBinding('class') public get value(): string {
        return `rack-head--${this.size}`;
    }

    @Input() public size: 'small' | 'big';
}
