import { ModuleWithProviders, NgModule } from '@angular/core';
import { LIVECHAT_CONFIGURATION } from '@app-de/core/livechat/livechat.configuration';
import { LivechatConfigurationInterface } from '@app-de/core/livechat/livechat.interfaces';

@NgModule()
export class LivechatModule {
    public static forRoot(livechatConfiguration?: LivechatConfigurationInterface): ModuleWithProviders<LivechatModule> {
        return {
            ngModule: LivechatModule,
            providers: [{ provide: LIVECHAT_CONFIGURATION, useValue: livechatConfiguration }],
        };
    }
}
