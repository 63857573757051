<ins-smart-tips-card [smartTipsCarousel]="smartTipsCarousel">
    <a class="card__link" [href]="url">
        <ins-card-image>
            <ins-banner
                [image]="smartTipsImageEnum.AutoReisezeit"
                imageAlt="Vier Freunde machen einen Ausflug mit dem Auto."
                imageTitle="Lesen Sie, was Sie bei Autoreisen ins Ausland beachten sollten - egal ob kurzer Ausflug oder langer Urlaub mit der Familie!"
                size="small"
            ></ins-banner>
        </ins-card-image>
        <ins-card-head>
            <h3>Ab in den Süden: Tipps für Auslandsreisen mit dem Auto</h3>
        </ins-card-head>
        <ins-card-content>
            <p class="card__content-text">
                Urlaubszeit ist Reisezeit. Knapp 60 Prozent der Deutschen waren schon mal mit dem Auto im EU-Ausland*.
                Und wen wundert‘s? Wo sich doch viele beliebte Feriengebiete und -orte mit dem Auto praktisch um die
                Ecke befinden. Damit die gute Laune nicht der schlechten Organisation zum Opfer fällt, gilt es einiges
                zu beachten. Mit unseren Tipps kommen Sie sicher und entspannt am Reiseziel an.
            </p>
        </ins-card-content>
        <ins-card-footer>
            <ins-read-article [smartTip]="url"></ins-read-article>
        </ins-card-footer>
    </a>
</ins-smart-tips-card>
