import { Component, Input } from '@angular/core';
import { AppRoutesEnum } from '@app-de/routes';
import { SmartTipsImageEnum } from '../../smart-tips-image.enum';
import { SmartTipsEnum } from '../../smart-tips.enum';

@Component({
    selector: 'ins-grosse-inspektion-summary',
    templateUrl: './grosse-inspektion-summary.component.html',
})
export class GrosseInspektionSummaryComponent {
    @Input() public smartTipsCarousel: boolean = false;
    @Input() public horizontal: boolean = false;

    public smartTipsEnum = SmartTipsEnum;
    public appRoutesEnum = AppRoutesEnum;
    public smartTipsImageEnum = SmartTipsImageEnum;
    public url = `/${this.appRoutesEnum.CarInsurance}/${this.smartTipsEnum.Autopflege}/${this.smartTipsEnum.FragenZurGrossenInspektion}`;
}
