import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GeneralAdditionalCoverageInfoComponent } from './general-additional-coverage-info.component';
import { ListModule } from '@app-de/shared/lists/list.module';
import { TabsetModule } from '@app-de/shared/tabs/tabset.module';

@NgModule({
    declarations: [GeneralAdditionalCoverageInfoComponent],
    imports: [
        CommonModule,
        ListModule,
        TabsetModule,
    ],
    exports: [GeneralAdditionalCoverageInfoComponent],
})
export class GeneralCoverageInfoModule {}
