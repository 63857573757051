import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NewModalModule } from 'outshared-lib';
import { ResponseTimesModal } from './response-times.modal';
import { ModalComponentsModule } from '@app-de/components';

@NgModule({
    declarations: [ResponseTimesModal],
    imports: [
        CommonModule,
        RouterModule,
        NewModalModule,
        ModalComponentsModule,
    ],
    exports: [ResponseTimesModal],
})
export class ResponseTimesModalModule {}
