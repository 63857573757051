import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ProductScenariosEffects, productScenariosReducer, PRODUCT_SCENARIO_FEATURE } from './store';

@NgModule({
    declarations: [],
    imports: [
        StoreModule.forFeature(PRODUCT_SCENARIO_FEATURE, productScenariosReducer),
        EffectsModule.forFeature([ProductScenariosEffects]),
        CommonModule,
    ],
})
export class OutProductScenariosModule {}
