<ins-modal-close></ins-modal-close>

<ins-modal-title>Ihre Angaben eintragen</ins-modal-title>

<ins-modal-content>
    <p>Nach dem Durchlaufen der letzten Schritte sind Sie ab dem gewünschten Beginndatum versichert.</p>

    <form [formGroup]="form" role="form" (ngSubmit)="onSubmit()">
        <cyno-field>
            <cyno-label>Geburtsdatum</cyno-label>
            <cyno-birthdate
                formControlName="birthdate"
                id="modal-birthdate"
                placeholder="TT.MM.JJJJ"
                separator="."
            ></cyno-birthdate>
        </cyno-field>

        <cyno-field>
            <cyno-label>Postleitzahl</cyno-label>
            <input
                cynoInput
                type="text"
                formControlName="postalCode"
                id="modal-postal-code"
                (keydown.enter)="onSubmit()"
            />
        </cyno-field>
    </form>

    <ins-alert type="validation" *ngIf="errors.length > 0" [errors]="errors"></ins-alert>
</ins-modal-content>

<ins-modal-actions alignment="end">
    <button class="btn btn--primary" [disabled]="submitting || form.invalid" (click)="onSubmit()">Weiter</button>
</ins-modal-actions>
