import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { BreadCrumbInterface } from '@app-de/core/interfaces/bread-crumb.interface';
import { LayoutSelectorService } from '@app-de/store/layout/layout-selector.service';

@Component({
    selector: 'ins-breadcrumb-container',
    templateUrl: './breadcrumb.container.html',
})
export class BreadcrumbContainer implements OnInit, OnDestroy {
    public breadcrumbs!: BreadCrumbInterface[];

    private subscriptions: Subscription = new Subscription();

    constructor(private layoutSelectorService: LayoutSelectorService, private changeDetectorRef: ChangeDetectorRef) {}

    public ngOnInit(): void {
        this.subscriptions.add(
            this.layoutSelectorService.breadcrumb$().subscribe((breadcrumbs: BreadCrumbInterface[]) => {
                this.breadcrumbs = breadcrumbs;
                this.changeDetectorRef.detectChanges();
            }),
        );
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
