import { Component, Input } from '@angular/core';

@Component({
    selector: 'ins-slogan',
    templateUrl: './slogan.component.html',
    styleUrls: ['./slogan.component.scss'],
})
export class SloganComponent {
    @Input() public position: string = 'right';
    @Input() public line: boolean = false;
}
