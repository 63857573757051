import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DetailsSubmitButtonComponent } from './details-submit-button.component';

@NgModule({
    declarations: [DetailsSubmitButtonComponent],
    exports: [DetailsSubmitButtonComponent],
    imports: [CommonModule],
})
export class DetailsSubmitButtonModule { }
