import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EmailAddressDataComponent } from './email-address-data';
import { EmailAddressFormComponent } from './email-address-form-component';
import { EmailAddressFormContainer } from './email-address-form-container';
import { DetailsSubmitButtonModule } from '@app-de/my-zone/shared/details-submit-button';
import { DetailsTableModule } from '@app-de/my-zone/shared/details-table';
import { CynoFormModule } from '@app-de/shared/cyno-form/cyno-form.module';

@NgModule({
    declarations: [EmailAddressDataComponent, EmailAddressFormComponent, EmailAddressFormContainer],
    exports: [EmailAddressDataComponent, EmailAddressFormContainer],
    imports: [CommonModule, CynoFormModule, DetailsTableModule, DetailsSubmitButtonModule],
})
export class EmailAddressModule {}
