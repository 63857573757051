export enum DetailsSectionEnum {
    PersonalDetailsForm = 'personal-details-form',
    EmailPanel = 'email-panel',
    NewsletterPanel = 'newsletter-panel',
    PasswordPanel = 'password-panel',
    AddressPanel = 'address-panel',
    CorrespondencePanel = 'correspondence-panel',
    PaymentDetailsPanel = 'payment-details-panel',
    FamilyPanel = 'family-panel',
    RecoveryPanel = 'recovery-panel',
}
