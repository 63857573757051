import { Component, HostBinding } from '@angular/core';

@Component({
    selector: 'ins-mobile-scroll',
    templateUrl: 'mobile-scroll.component.html',
    styleUrls: ['mobile-scroll.component.scss'],
})
export class MobileScrollComponent {
    @HostBinding('class.mobile-scroll') public hostClass = true;
}
