import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DetailsDeleteButtonModule } from '../../details-delete-button/details-delete-button.module';
import { DetailsSubmitButtonModule } from '../../details-submit-button/details-submit-button.module';
import { DetailsTableModule } from '../../details-table/details-table.module';
import { CorrespondenceAddressFutureComponent } from './correspondence-address-future.component';
import { CorrespondenceAddressFutureContainer } from './correspondence-address-future.container';
import { CancelCorrespondenceAddressModalModule } from '@app-de/my-zone/shared/modals/cancel-correspondence-address-modal/cancel-correspondence-address-modal.module';
import { DateFormatPipeModule } from '@app-de/shared/pipes/date-format/date-format.module';

@NgModule({
    declarations: [
        CorrespondenceAddressFutureComponent,
        CorrespondenceAddressFutureContainer,
    ],
    exports: [CorrespondenceAddressFutureContainer],
    imports: [
        CommonModule,
        DateFormatPipeModule,
        DetailsSubmitButtonModule,
        DetailsTableModule,
        DetailsDeleteButtonModule,
        CancelCorrespondenceAddressModalModule,
    ],
})
export class CorrespondenceAddressFutureModule {
}
