import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ErrorInterface } from '../../core';

@Injectable({
    providedIn: 'root',
})
export class InsuranceInfoCheckEventService {
    private _getInsuranceInfoCheck$: Subject<void>;
    private _saveInsuranceInfoCheck$: Subject<void>;

    public getInsuranceInfoCheck$(): Observable<void> {
        this._getInsuranceInfoCheck$ = new Subject();
        return this._getInsuranceInfoCheck$.asObservable();
    }

    public onGetInsuranceInfoCheckSuccess(): void {
        this._getInsuranceInfoCheck$.next();
        this._getInsuranceInfoCheck$.complete();
    }

    public onGetInsuranceInfoCheckError(errors: ErrorInterface[]): void {
        if (this._getInsuranceInfoCheck$) {
            this._getInsuranceInfoCheck$.error(errors);
        }
    }

    public saveInsuranceInfoCheck$(): Observable<void> {
        this._saveInsuranceInfoCheck$ = new Subject();
        return this._saveInsuranceInfoCheck$.asObservable();
    }

    public onSaveInsuranceInfoCheckSuccess(): void {
        this._saveInsuranceInfoCheck$.next();
        this._saveInsuranceInfoCheck$.complete();
    }

    public onSaveInsuranceInfoCheckError(errors: ErrorInterface[]): void {
        if (this._saveInsuranceInfoCheck$) {
            this._saveInsuranceInfoCheck$.error(errors);
        }
    }
}
