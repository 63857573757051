import { Coverage, CoveragePremium } from 'outshared-lib';

/**
 * Return modules matching to the array of module IDs
 * @param modules
 */
export const moduleReducer = (modules: Coverage[]) => {
    return (accumulator: Coverage[], currentValue: string): Coverage[] => {
        const foundModule = modules.find((am) => am.coverage_id === currentValue);
        if (foundModule) {
            return [...accumulator, foundModule];
        }
        return [...accumulator];
    };
};

/**
 * Accumulate prices for an array of module IDs
 * @param groups
 * @param properties
 */
export const priceReducer = (groups: string[], properties: string[]) => {
    return (
        accumulator: { [key: string]: CoveragePremium },
        currentValue: Coverage,
    ): { [key: string]: CoveragePremium } => {
        groups.forEach((group) => {
            if (accumulator.hasOwnProperty(group) && accumulator[group] !== null) {
                properties.forEach((property) => {
                    if (
                        currentValue.hasOwnProperty(group) &&
                        currentValue[group] &&
                        currentValue[group].hasOwnProperty(property)
                    ) {
                        const value: number = accumulator[group][property] + currentValue[group][property];
                        accumulator[group][property] = parseFloat(value.toString()).toFixed(2);
                    }
                });
            } else {
                accumulator[group] = currentValue[group] ? { ...currentValue[group] } : null;
            }
        });

        return accumulator;
    };
};

/**
 * Retrieve prices for the current deviation
 * @param current
 * @param modules
 */
export const getPricesForDeviation = (current: string[], modules: Coverage[]): any => {
    const groups = [
        'premium',
        'discount',
        'collectivity_discount',
        'stack_discount',
        'premium_including_discount',
        'premium_including_stack_discount',
    ];

    const properties = ['basic_premium', 'premium', 'premium_tax', 'premium_including_premium_tax'];

    const subs = current.reduce(moduleReducer(modules), []);
    const prices = subs.reduce(priceReducer(groups, properties), {});

    return prices;
};

/**
 * Compares all available modules to given deviations, and returns the correct prices for all of them.
 * @param modules
 * @param deviations
 */
export const productReducer = (
    modules: Coverage[],
    deviations: { [key: string]: { [key: string]: string[] } },
): any => {
    const deviationReducer = (
        accumulator: { [key: string]: Coverage },
        coverage: Coverage,
    ): { [key: string]: Coverage } => {
        const coverageId = coverage.coverage_id;

        // Deviations are divided into categories (form groups), we need to loop through all of those
        for (const deviationCategory in deviations) {
            if (deviations.hasOwnProperty(deviationCategory)) {
                // every category has a collection of modules
                const deviationCollection = deviations[deviationCategory];

                // if we can match the coverage to this collection we need to merge the prices for the modules in this coverage
                // for example: WA + CA
                if (deviationCollection.hasOwnProperty(coverageId)) {
                    return {
                        ...accumulator,
                        [coverageId]: {
                            ...coverage,
                            ...getPricesForDeviation(deviationCollection[coverageId], modules),
                        },
                    };
                }
            }
        }

        // if no deviation is found we just return the coverage itself
        return {
            ...accumulator,
            [coverageId]: { ...coverage },
        };
    };
    return modules.reduce(deviationReducer, {});
};
