import {
    ApiGetInsuranceInfoCheckResponse,
    GetInsuranceInfoCheckRequestInterface,
    ApiGetInsuranceInfoCheckRequest,
    GetInsuranceInfoCheckResponseInterface,
} from '../interfaces';

export function mapGetInsuranceInfoCheckRequest(
    request: GetInsuranceInfoCheckRequestInterface
): ApiGetInsuranceInfoCheckRequest {
    return {
        purchased_product_sequence_number: request.productSequenceNumber,
    };
}

export function mapGetInsuranceInfoCheckResponse(
    response: ApiGetInsuranceInfoCheckResponse
): GetInsuranceInfoCheckResponseInterface {
    return {
        firstName: response.first_name,
        previousInsuranceCompanyExternalReferenceId: response.previous_insurance_company_external_reference_id,
        previousInsuranceCompanyId: response.previous_insurance_company_id,
        surname: response.surname,
    };
}
