<ins-modal-close></ins-modal-close>
<ins-modal-title>Möchten Sie sich wirklich ausloggen?</ins-modal-title>

<ins-modal-content>
    <p>Eingeloggt bleiben bringt Vorteile:</p>
    <ins-list-flat [svg]="true" icon="check">
        <ins-list-flat-item>Direkt Einblick in Ihre Versicherungen</ins-list-flat-item>
        <ins-list-flat-item>Einfach Zusatzleistungen hinzufügen</ins-list-flat-item>
    </ins-list-flat>
</ins-modal-content>

<ins-modal-actions>
    <button (click)="cancel()" class="btn btn--link">Nein, eingeloggt bleiben</button>
    <button (click)="logout()" class="btn btn--primary" e2e="modal_submit">Ausloggen</button>
</ins-modal-actions>
