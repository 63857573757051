import { Component, inject, Input } from '@angular/core';
import {
    WucBalloonModule,
    WucIconUiModule,
    WucLinkModule,
    WucListModule,
    WucModalModule,
} from '@inshared/website-ui-components';
import { AsyncPipe, NgIf } from '@angular/common';
import { CookieSettingsComponent } from '../../cookie-settings';
import { NativeRouterService } from '@inshared/shared/util';
import { CookieConsent } from '@inshared/shared/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { map } from 'rxjs';
import { CookieSettingsButtonComponent } from '../../cookie-settings-buttons/cookie-settings-buttons.component';

@Component({
    standalone: true,
    selector: 'ins-cookie-settings-de',
    templateUrl: 'cookie-settings-de.component.html',
    styleUrl: '../../cookie-settings-modal/cookie-settings-modal.component.scss',
    imports: [
        WucListModule,
        NgIf,
        CookieSettingsComponent,
        WucModalModule,
        AsyncPipe,
        WucBalloonModule,
        WucIconUiModule,
        WucLinkModule,
        CookieSettingsButtonComponent,
    ],
})
export class CookieSettingsDEComponent {
    @Input() hideClose = false;

    public baseUrl = inject(NativeRouterService).baseUrl;
    protected readonly cookieConsent = CookieConsent;

    public isMobile$ = inject(BreakpointObserver)
        .observe(`(min-width: 576px)`)
        .pipe(map((state) => !state.matches));

    public moreInfoOpened?: CookieConsent;

    public onMoreInfoClick(value: CookieConsent) {
        this.moreInfoOpened = this.moreInfoOpened === value ? undefined : value;
    }
}
