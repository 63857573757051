import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReadMoreDirective } from './read-more.directive';
import { ReadMoreToggleButtonComponent } from '@app-de/shared/directives/read-more/read-more-toggle-button.component';

@NgModule({
    declarations: [
        ReadMoreDirective,
        ReadMoreToggleButtonComponent,
    ],
    imports: [CommonModule],
    exports: [
        ReadMoreDirective,
        ReadMoreToggleButtonComponent,
    ],
})
export class ReadMoreModule {}
