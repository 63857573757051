import { Component, inject, OnDestroy, PLATFORM_ID } from '@angular/core';
import { WucButtonModule, WucModalModule } from '@inshared/website-ui-components';
import { CookieSettingsComponent } from '../cookie-settings';
import { CookieConsent } from '@inshared/shared/core';
import { StorageService } from '@inshared/shared/util-storage';
import { Event, NavigationEnd, Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { filter, map, Subscription } from 'rxjs';
import { BlueConicService } from '@inshared/shared/util-analytics';
import { CookieSettingsButtonComponent } from '../cookie-settings-buttons/cookie-settings-buttons.component';
import {CookieSettingsService} from "../cookie-settings-service";

@Component({
    standalone: true,
    selector: 'ins-cookie-settings-modal',
    templateUrl: 'cookie-settings-modal.component.html',
    styleUrl: 'cookie-settings-modal.component.scss',
    imports: [
        WucModalModule,
        CookieSettingsComponent,
        WucButtonModule,
        CookieSettingsButtonComponent,
        CookieSettingsButtonComponent,
    ],
})
export class CookieSettingsModalComponent implements OnDestroy {
    private blueConicService = inject(BlueConicService);
    private router = inject(Router);
    public cookieSettingsService = inject(CookieSettingsService);
    protected storage: Storage = inject(StorageService);

    private platform = inject(PLATFORM_ID);
    private subscriptions = new Subscription();


    constructor() {
        // First check if the environment is a browser
        if (isPlatformBrowser(this.platform)) {
            this.subscriptions.add(
                this.router.events
                    .pipe(
                        filter((event: Event) => event instanceof NavigationEnd),
                        map((event: Event) => event as NavigationEnd),
                    )
                    .subscribe(() => this.initCookiebar()),
            );
        }
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    private initCookiebar(): void {
        const cookieValue = this.storage.getItem('ls.cookie.setting');

        this.blueConicService.init();
        // FIXME: AppRoutesEnum is country specific, we need a solution for this
        const isSpecialUrl = ['/cookies', '/privacy', '/datenschutz'].includes(this.router.url);
        // Check if a cookie is set, or we are on a special page, before opening the cookiewall
        if (!isSpecialUrl && (!cookieValue || !this.cookieSettingsService.isValidCookieValue(cookieValue))) {
            this.cookieSettingsService.showCookieWall();
        }
    }
}
