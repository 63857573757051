import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { VehicleModelSearchRequestInterface, VehicleModelSearchResponseInterface } from '../interfaces';
import { VehicleModelSearchApiService } from './vehicle-model-search.api.service';

@Injectable({
    providedIn: 'root',
})
export class VehicleModelSearchActionService {
    constructor(private apiService: VehicleModelSearchApiService) {}

    public getVehicleModelSearchResults$(
        request: VehicleModelSearchRequestInterface
    ): Observable<VehicleModelSearchResponseInterface> {
        return this.apiService.vehicleModelSearch$(request);
    }
}
