<ins-premium-determining-factor
    [premiumDeterminingFactor]="premiumDeterminingFactor"
    [purchasedProductSequenceNumber]="product.purchased_product_sequence_number"
    [validityStatusCode]="product.validity_status_code"
    [options]="options$ | async"
    [errors]="errors$ | async"
    (panelOpened)="panelOpened($event)"
    (showModal)="showModal($event)"
    (updatePremiumDeterminingFactor)="updatePremiumDeterminingFactor($event)"
    (cancelPremiumDeterminingFactorFuture)="cancelPremiumDeterminingFactorFuture($event)"
></ins-premium-determining-factor>
