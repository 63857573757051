import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SidemenuComponent } from './sidemenu.component';
import { NavSidebarModule } from '@app-de/shared/layout/nav-sidebar/nav-sidebar.module';

@NgModule({
    imports: [
        CommonModule,
        NavSidebarModule,
        RouterModule,
    ],
    exports: [SidemenuComponent],
    declarations: [SidemenuComponent],
})
export class SidemenuModule {}
