import { ApiSaveInsuranceInfoCheckRequest, SaveInsuranceInfoCheckRequestInterface } from '../interfaces';

export function mapSaveInsuranceInfoCheckRequest(
    request: SaveInsuranceInfoCheckRequestInterface
): ApiSaveInsuranceInfoCheckRequest {
    return {
        first_name: request.firstName,
        previous_insurance_company_external_reference_id: request.previousInsuranceCompanyExternalReferenceId,
        previous_insurance_company_id: request.previousInsuranceCompanyId,
        purchased_product_sequence_number: request.purchasedProductSequenceNumber,
        surname: request.surname,
    };
}
