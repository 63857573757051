import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { getCLS, getFID, getLCP } from 'web-vitals';
import { AppConfigService } from '../configuration';

@Injectable({ providedIn: 'root' })
export class WebVitalsService {

    private cwv: boolean = false;

    constructor(
        private appConfigService: AppConfigService,
        @Inject(PLATFORM_ID) private platformId: Object
    ) {}

    public init(): void {
        this.cwv = this.appConfigService.getConfig().cwv;

        if (isPlatformServer(this.platformId) || !this.cwv) {
            return;
        }

        getCLS(this.sendToGoogleAnalytics);
        getFID(this.sendToGoogleAnalytics);
        getLCP(this.sendToGoogleAnalytics);
    }

    private sendToGoogleAnalytics({ name, delta, id }): void {
        if (typeof ga === 'undefined') {
            return;
        }

        ga('send', 'event', {
            eventCategory: 'Web Vitals',
            eventAction: name,
            // Google Analytics metrics must be integers, so the value is rounded.
            // For CLS the value is first multiplied by 1000 for greater precision
            // (note: increase the multiplier for greater precision if needed).
            eventValue: Math.round(name === 'CLS' ? delta * 1000 : delta),
            // The `id` value will be unique to the current page load. When sending
            // multiple values from the same page (e.g. for CLS), Google Analytics can
            // compute a total by grouping on this ID (note: requires `eventLabel` to
            // be a dimension in your report).
            eventLabel: id,
            // Use a non-interaction event to avoid affecting bounce rate.
            nonInteraction: true,
        });
    }
}
