import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import {
    ProductActionService,
    ProductBaseInterface,
    ProductSelectorService,
    ScenariosActionService,
} from 'outshared-lib';
import { Observable, of } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';
import { PolicyLoadingStateService } from '@app-de/my-zone/services/policy-loading-state.service';

@Injectable({
    providedIn: 'root',
})
export class ProductsResolver implements Resolve<boolean> {
    private products$: Observable<ProductBaseInterface[]>;

    constructor(
        private productSelectorService: ProductSelectorService,
        private productActionService: ProductActionService,
        private scenariosActionService: ScenariosActionService,
        private policyLoadingStateService: PolicyLoadingStateService,
        @Inject(PLATFORM_ID) private platformId: Object,
    ) {}

    public resolve(route: ActivatedRouteSnapshot): Observable<boolean> {
        if (isPlatformServer(this.platformId)) {
            return of(true);
        }
        this.products$ = this.productSelectorService.getProductCollection$;

        // If no products => load data
        this.products$
            .pipe(
                take(1),
                filter((products) => products.length < 1),
            )
            .subscribe(() => this.loadData());

        return of(true);
    }

    private loadData(): void {
        this.loadPolicy();
        this.loadScenarios();
    }

    private loadPolicy(): void {
        this.policyLoadingStateService.start();
        this.productActionService
            .loadPolicy()
            .pipe(
                take(1),
                tap(() => this.policyLoadingStateService.complete()),
            )
            .subscribe();
    }

    private loadScenarios(): void {
        this.scenariosActionService.load().pipe(take(1)).subscribe();
    }
}
