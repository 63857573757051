<!-- Default premium determining factor -->
<ng-container *ngIf="['TOEK', 'OSTP'].includes(validityStatusCode)">
    <div
        class="premium-determining-factor"
        *ngIf="
            premiumDeterminingFactor.modifiable_premium_determining_factor_future as modifiable_premium_determining_factor_future
        "
    >
        <div class="premium-determining-factor__title">
            {{ modifiable_premium_determining_factor_future.premium_determining_factor_description }}
        </div>
        <div class="premium-determining-factor__value">
            {{ modifiable_premium_determining_factor_future.premium_determining_factor_value_description }}
        </div>
    </div>
</ng-container>
<!-- // Default premium determining factor -->
<!-- Current premium determining factor -->
<ng-container *ngIf="!['TOEK', 'OSTP'].includes(validityStatusCode)">
    <!-- Current -->
    <div
        class="premium-determining-factor"
        [ngClass]="{
            'premium-determining-factor--has-future':
                premiumDeterminingFactor.modifiable_premium_determining_factor_future
        }"
    >
        <div class="premium-determining-factor__title">
            {{ premiumDeterminingFactor.premium_determining_factor_description }}
            <a
                href="#"
                title="Open {{ premiumDeterminingFactor.premium_determining_factor_description }}"
                *ngIf="premiumDeterminingFactor.premium_determining_factor_id === 'SVJR'"
                (click)="openModal('SVJR')"
            >
                <svg class="svg-icon svg-icon--xs"><use href="assets/icons-ui.svg#informatie"></use></svg>
            </a>
            <a
                href="#"
                title="Open {{ premiumDeterminingFactor.premium_determining_factor_description }}"
                *ngIf="premiumDeterminingFactor.premium_determining_factor_id === 'BMTR'"
                (click)="openModal('BMTR')"
            >
                <svg class="svg-icon svg-icon--xs"><use href="assets/icons-ui.svg#informatie"></use></svg>
            </a>
        </div>
        <div class="premium-determining-factor__value">
            {{
                premiumDeterminingFactor.modifiable_premium_determining_factor
                    ?.premium_determining_factor_value_description
            }}
        </div>
        <div class="premium-determining-factor__button">&nbsp;</div>
    </div>
    <!-- // Current -->
    <!-- Future -->
    <div
        class="premium-determining-factor premium-determining-factor--future"
        *ngIf="
            premiumDeterminingFactor.modifiable_premium_determining_factor_future as modifiable_premium_determining_factor_future
        "
    >
        <div class="premium-determining-factor__title">
            Toekomstig(e)
            {{ modifiable_premium_determining_factor_future.premium_determining_factor_description | lowercase }}
        </div>
        <div class="premium-determining-factor__value">
            {{ modifiable_premium_determining_factor_future.premium_determining_factor_value_description }}
            <small>(per {{ modifiable_premium_determining_factor_future.start_date | dateFormat }})</small>
        </div>
        <div class="premium-determining-factor__button" *ngIf="premiumDeterminingFactor.modifiable_by_code === 'K'">
            <a href="#" class="link-secondary link-secondary--icon" (click)="cancel()">
                <span>Annuleren</span>
                <svg class="svg-icon svg-icon--xs"><use href="assets/icons-ui.svg#cross-closed"></use></svg>
            </a>
        </div>
    </div>
    <!-- // Future -->
</ng-container>
<!-- // Current premium determining factor -->
