<ins-modal-close></ins-modal-close>
<ins-modal-title>Sorry!</ins-modal-title>
<ins-modal-content>
    <p>Diesen Versicherungsschutz können wir Ihnen leider nicht anbieten.</p>
</ins-modal-content>
<ins-modal-actions alignment="end">
    <a href="#" class="modal__close-bottom link-secondary" (click)="close()">
        <span>Schließen</span>
        <svg class="svg-icon svg-icon--xs"><use href="assets/icons-ui.svg#cross-closed"></use></svg>
    </a>
</ins-modal-actions>
