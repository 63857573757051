import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ProductBaseInterface, ProductIdEnum, ProductSelectorService } from 'outshared-lib';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MyZoneRoutes } from '@app-de/my-zone/routing/my-zone-routing.routes';
import { AppRoutesEnum } from '@app-de/routes';
import { LayoutService } from '@app-de/store/layout/layout.service';

@Component({
    selector: 'ins-terms-and-condition-container',
    template: ` <ins-terms-and-condition [userProductIdList]="userProductIdList$ | async" [product]="product$ | async">
    </ins-terms-and-condition>`,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TermsAndConditionContainer implements OnInit {
    public product$: Observable<ProductBaseInterface> = this.productSelectorService.getActiveProducts$.pipe(
        map((product) => product[0]),
    );

    public userProductIdList$: BehaviorSubject<ProductIdEnum[]> = new BehaviorSubject([ProductIdEnum.Car]);

    constructor(private layoutService: LayoutService, private productSelectorService: ProductSelectorService) {}

    public ngOnInit(): void {
        this.layoutService.setBreadcrumbs([
            { title: 'Home', href: '/' },
            { title: 'Meine Versicherungsmappe', routerLink: [AppRoutesEnum.MyZone, MyZoneRoutes.MyInsurances] },
            { title: 'Meine Dokumente' },
        ]);
    }
}
