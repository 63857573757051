import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CatTreadmillAnimationComponent } from './cat-treadmill-animation.component';

@NgModule({
    declarations: [CatTreadmillAnimationComponent],
    imports: [CommonModule],
    exports: [CatTreadmillAnimationComponent],
})
export class CatTreadmillAnimationModule {
}
