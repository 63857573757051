<div class="navigation">
    <wuc-navigation>
        <wuc-navigation-item name="Home" icon="house" route="/"></wuc-navigation-item>
        <wuc-navigation-item name="Versicherungen" width="large">
            <div class="navigation__dropdown--divided">
                <div class="navigation__dropdown--split">
                    <div class="navigation__dropdown-left">
                        <div class="h3 navigation__title">Unsere Versicherungen</div>
                        <div>
                            <p class="navigation__subtitle">Unterwegs</p>
                            <wuc-list [lined]="true">
                                <wuc-list-item>
                                    <wuc-link
                                        type="tertiary"
                                        [route]="'/' + appRoutesEnum.KfzVersicherung"
                                        linkTitle="Kfz-Versicherung"
                                        [attr.e2e]="'nav-' + appRoutesEnum.KfzVersicherung"
                                    >
                                        Kfz-Versicherung
                                    </wuc-link>
                                </wuc-list-item>
                                <wuc-list-item>
                                    <wuc-link
                                        type="tertiary"
                                        [route]="'/' + appRoutesEnum.CarInsurance"
                                        linkTitle="Autoversicherung"
                                        [attr.e2e]="'nav-' + appRoutesEnum.CarInsurance"
                                    >
                                        Autoversicherung
                                    </wuc-link>
                                </wuc-list-item>
                                <wuc-list-item>
                                    <wuc-link
                                        type="tertiary"
                                        [route]="
                                            '/' +
                                            appRoutesEnum.CarInsurance +
                                            '/' +
                                            autoversicherungRoutesEnum.EAutoversicherung
                                        "
                                        linkTitle="E-Auto-Versicherung"
                                        [route]="
                                            '/' +
                                            appRoutesEnum.CarInsurance +
                                            '/' +
                                            autoversicherungRoutesEnum.EAutoversicherung
                                        "
                                        [attr.e2e]="
                                            'nav-' +
                                            appRoutesEnum.CarInsurance +
                                            '-' +
                                            autoversicherungRoutesEnum.EAutoversicherung
                                        "
                                    >
                                        E-Auto-Versicherung
                                    </wuc-link>
                                </wuc-list-item>
                                <wuc-list-item>
                                    <wuc-link
                                        type="tertiary"
                                        [route]="
                                            '/' +
                                            appRoutesEnum.CarInsurance +
                                            '/' +
                                            autoversicherungRoutesEnum.Fahrerschutz
                                        "
                                        linkTitle="Fahrerschutz"
                                        [attr.e2e]="
                                            'nav-' +
                                            appRoutesEnum.CarInsurance +
                                            '-' +
                                            autoversicherungRoutesEnum.Fahrerschutz
                                        "
                                    >
                                        Fahrerschutz
                                    </wuc-link>
                                </wuc-list-item>
                                <wuc-list-item>
                                    <wuc-link
                                        type="tertiary"
                                        [route]="
                                            '/' +
                                            appRoutesEnum.CarInsurance +
                                            '/' +
                                            autoversicherungRoutesEnum.Pannenhilfe
                                        "
                                        linkTitle="Pannenhilfe"
                                        [attr.e2e]="
                                            'nav-' +
                                            appRoutesEnum.CarInsurance +
                                            '-' +
                                            autoversicherungRoutesEnum.Pannenhilfe
                                        "
                                    >
                                        Pannenhilfe
                                    </wuc-link>
                                </wuc-list-item>
                            </wuc-list>
                        </div>
                    </div>
                    <div class="navigation__dropdown-right">
                        <p class="navigation__subtitle">Direkt zu</p>
                        <wuc-list [lined]="true" lineColor="dark">
                            <wuc-list-item>
                                <wuc-link
                                    type="tertiary"
                                    [route]="'/' + appRoutesEnum.Insurances"
                                    linkTitle="Alle InShared-Versicherungen"
                                    [isRouterLinkActiveExact]="true"
                                >
                                    Alle InShared-Versicherungen
                                </wuc-link>
                            </wuc-list-item>
                            <wuc-list-item>
                                <wuc-link
                                    type="tertiary"
                                    [route]="'/' + appRoutesEnum.Insurances + '/' + appRoutesEnum.Cancel"
                                    linkTitle="Ändern &amp; kündigen"
                                >
                                    Ändern &amp; kündigen
                                </wuc-link>
                            </wuc-list-item>
                            <wuc-list-item>
                                <wuc-link
                                    type="tertiary"
                                    [route]="
                                        '/' +
                                        appRoutesEnum.About +
                                        '/' +
                                        appRoutesEnum.EverythingOnline +
                                        '/' +
                                        allesOnlineRoutes.Pay
                                    "
                                    linkTitle="Beitrag bezahlen"
                                >
                                    Beitrag bezahlen
                                </wuc-link>
                            </wuc-list-item>
                        </wuc-list>
                    </div>
                </div>
            </div>
        </wuc-navigation-item>
        <wuc-navigation-item name="Über InShared">
            <div class="navigation__dropdown">
                <wuc-list [lined]="true">
                    <wuc-list-item>
                        <wuc-link
                            type="tertiary"
                            [route]="'/' + appRoutesEnum.About"
                            linkTitle="Wer wir sind"
                            [isRouterLinkActiveExact]="true"
                        >
                            Wer wir sind
                        </wuc-link>
                    </wuc-list-item>
                    <wuc-list-item>
                        <wuc-link
                            type="tertiary"
                            [route]="'/' + appRoutesEnum.About + '/' + appRoutesEnum.EverythingOnline"
                            linkTitle="Alles einfach online regeln"
                            [isRouterLinkActiveExact]="true"
                        >
                            Alles einfach online regeln
                        </wuc-link>
                    </wuc-list-item>
                    <wuc-list-item>
                        <wuc-link
                            type="tertiary"
                            [route]="'/' + appRoutesEnum.CarInsurance + '/' + appRoutesEnum.Reviews"
                            linkTitle="Bewertungen"
                            [isRouterLinkActiveExact]="true"
                        >
                            Bewertungen
                        </wuc-link>
                    </wuc-list-item>
                    <wuc-list-item>
                        <wuc-link
                            type="tertiary"
                            [route]="'/' + appRoutesEnum.AnnualReward"
                            linkTitle="Was ist die Jahresbelohnung?"
                            [isRouterLinkActiveExact]="true"
                        >
                            Unsere Jahresbelohnung
                        </wuc-link>
                    </wuc-list-item>
                    <wuc-list-item>
                        <wuc-link
                            type="tertiary"
                            [route]="
                                '/' +
                                appRoutesEnum.AnnualReward +
                                '/' +
                                appRoutesEnum.AnnualReward +
                                '-' +
                                appRoutesEnum.TwentyThree
                            "
                            linkTitle="Jahresbelohnung 2023"
                            [isRouterLinkActiveExact]="true"
                        >
                            Jahresbelohnung 2023
                        </wuc-link>
                    </wuc-list-item>
                </wuc-list>
            </div>
        </wuc-navigation-item>
        <wuc-navigation-item name="Schäden" width="large">
            <div class="navigation__dropdown">
                <wuc-rack columnsDesktop="2" gridGap="large">
                    <div>
                        <p class="navigation__subtitle">Schaden, Notfall oder Panne?</p>
                        <wuc-list [lined]="true">
                            <wuc-list-item>
                                <p>
                                    Schnelle Hilfe bei Schaden, Autopanne oder Notfall (z. B. Autodiebstahl, Unfall oder
                                    Personenschaden).
                                </p>
                                <p>Rufen Sie uns an unter:</p>
                                <wuc-link
                                    type="tertiary"
                                    url="tel:+4922182779952"
                                    [openUrlInNewTab]="false"
                                    iconLeft="call"
                                    iconSize="xs"
                                    linkTitle="Anruf: + 49 221 8277 9952"
                                >
                                    <b>+ 49 221 8277 9952</b>
                                </wuc-link>
                            </wuc-list-item>
                        </wuc-list>
                    </div>
                    <div>
                        <p class="navigation__subtitle">Alles über Schäden</p>
                        <wuc-list [lined]="true">
                            <wuc-list-item>
                                <wuc-link
                                    type="tertiary"
                                    [route]="'/' + appRoutesEnum.Damage"
                                    linkTitle="Hilfe bei Schäden"
                                >
                                    Hilfe bei Schäden
                                </wuc-link>
                            </wuc-list-item>
                        </wuc-list>
                    </div>
                    <div>
                        <p class="navigation__subtitle">Schaden schon gemeldet?</p>
                        <wuc-list [lined]="true">
                            <wuc-list-item>
                                <p
                                    >Wenn Sie Fragen zum Stand Ihres Schadens haben, senden Sie uns bitte an
                                    schadenteam&#64;inshared.de eine E-Mail.</p
                                >
                                <p> Oder rufen Sie uns an, Montag-Freitag von 9:00-16:00 Uhr unter: </p>
                                <wuc-link
                                    type="tertiary"
                                    url="tel:+4922182779952"
                                    [openUrlInNewTab]="false"
                                    iconLeft="call"
                                    iconSize="xs"
                                    linkTitle="Anruf: + 49 221 8277 9952"
                                >
                                    <b>+ 49 221 8277 9952</b>
                                </wuc-link>
                            </wuc-list-item>
                        </wuc-list>
                    </div>
                </wuc-rack>
            </div>
        </wuc-navigation-item>
        <wuc-navigation-item name="Wertvolle Tipps" [url]="'/' + appRoutesEnum.Insurances + '/' + appRoutesEnum.SmartTips" />
        <wuc-navigation-item name="Kontakt" [route]="'/' + appRoutesEnum.Contact" />
    </wuc-navigation>
</div>
