<div
    class="international-motor-insurance-card-buttons"
    [ngClass]="{ 'international-motor-insurance-card-buttons--padding': padding }"
>
    <ng-container *ngIf="product.product_id === productIdEnum.Car">
        <h3 *ngIf="showTitle">Grüne Karte Auto</h3>
        <p>
            Hier finden Sie Ihre Grüne Karte (internationale Versicherungskarte) von InShared. In Deutschland ist sie
            nicht verpflichtend, aber praktisch. Sie ist ab jetzt 3 Jahre gültig.
        </p>
    </ng-container>

    <cyno-alert
        class="international-motor-insurance-card-buttons__notification"
        *ngIf="notifications"
        [notifications]="notifications"
        type="success"
    ></cyno-alert>
    <cyno-alert
        class="international-motor-insurance-card-buttons__notification"
        *ngIf="errors"
        [notifications]="errors"
        type="error"
    ></cyno-alert>

    <div class="international-motor-insurance-card-buttons__container">
        <ins-international-motor-insurance-card-button
            value="Download"
            icon="downloaden"
            [loading]="loading"
            (response)="downloadInternationalMotorCard()"
        ></ins-international-motor-insurance-card-button>
        <ins-international-motor-insurance-card-button
            value="E-Mail"
            icon="mail"
            [loading]="loading"
            (response)="emailInternationalMotorInsuranceCard()"
        ></ins-international-motor-insurance-card-button>
    </div>
</div>
