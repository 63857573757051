import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CoverageListComponent } from './coverage-list.component';
import { ListModule } from '@app-de/shared/lists/list.module';

@NgModule({
    imports: [
        CommonModule,
        ListModule,
    ],
    exports: [CoverageListComponent],
    declarations: [CoverageListComponent],
})
export class CoverageListModule {}
