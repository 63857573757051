import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { DamageFreeYearsImpactEffects, damageFreeYearsImpactReducer } from './store';
import { DAMAGE_FREE_YEARS_IMPACT_FEATURE } from './store/damage-free-years-impact.state';

@NgModule({
    imports: [
        StoreModule.forFeature(DAMAGE_FREE_YEARS_IMPACT_FEATURE, damageFreeYearsImpactReducer),
        EffectsModule.forFeature([DamageFreeYearsImpactEffects]),
    ],
})
export class OutDamageFreeYearsImpactModule {}
