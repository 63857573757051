<div [formGroup]="form" class="form-password">
    <input
        cynoInput
        [type]="type"
        class="form-control form-password__input"
        formControlName="password"
        [required]="required"
        [id]="id"
        (focus)="_onFocus()"
        (blur)="_onBlur()"
        insShowPassword="showPassword"
        (input)="onInputChange()"
        [autocomplete]="autocomplete"
        [placeholder]="placeholder"
    />
    <div class="form-password__icon" *ngIf="password.dirty">
        <a href="#" [ngClass]="type == 'password' ? 'icon-eye' : 'icon-eye-slash'" (click)="toggle()" tabindex="-1"></a>
    </div>

    <div class="form-password__popover" *ngIf="popoverZichtbaar">
        <p class="text text--strong">Besteht Ihr Passwort aus:</p>
        <ins-list-flat [svg]="true">
            <li>
                <svg *ngIf="password.errors?.characters" class="svg-icon svg-icon--xs svg-icon--secondary">
                    <use href="assets/icons-ui.svg#cross-closed"></use>
                </svg>
                <svg *ngIf="!password.errors?.characters" class="svg-icon svg-icon--xs">
                    <use href="assets/icons-ui.svg#check-closed"></use>
                </svg>
                mind. 2 Buchstaben
            </li>
            <li>
                <svg *ngIf="password.errors?.invalidNumbers" class="svg-icon svg-icon--xs svg-icon--secondary">
                    <use href="assets/icons-ui.svg#cross-closed"></use>
                </svg>
                <svg *ngIf="!password.errors?.invalidNumbers" class="svg-icon svg-icon--xs">
                    <use href="assets/icons-ui.svg#check-closed"></use>
                </svg>
                mind. 1 Zahl
            </li>
            <li>
                <svg *ngIf="password.errors?.currentLength" class="svg-icon svg-icon--xs svg-icon--secondary">
                    <use href="assets/icons-ui.svg#cross-closed"></use>
                </svg>
                <svg *ngIf="!password.errors?.currentLength" class="svg-icon svg-icon--xs">
                    <use href="assets/icons-ui.svg#check-closed"></use>
                </svg>
                8 oder mehr Zeichen (maximal 40)
            </li>
        </ins-list-flat>
        <ng-container>
            <p class="text text--strong">Für mehr Sicherheit wählen Sie 1 dieser Zeichen:</p>
            <ins-list-flat [svg]="true">
                <li class="conditie-optioneel">
                    <svg *ngIf="hasSpecialCharacter" class="svg-icon svg-icon--xs">
                        <use href="assets/icons-ui.svg#check-closed"></use>
                    </svg>
                    <svg *ngIf="!hasSpecialCharacter" class="svg-icon svg-icon--xs svg-icon--secondary">
                        <use href="assets/icons-ui.svg#check-closed"></use>
                    </svg>
                    &#64; . - ? + ! # & =
                </li>
            </ins-list-flat>
        </ng-container>

        <div class="progress">
            <div class="progress__bar" aria-valuemin="0" aria-valuemin="100" [attr.aria-valuenow]="progressValue"></div>
        </div>

        <!-- Als er een fout speciaal teken is ingevuld -->
        <ng-container *ngIf="password.errors?.invalidCharacters">
            <ins-list-flat icon="cross" [svg]="true">
                Eines der von Ihnen gewählten Sonderzeichen kann nicht genutzt werden. Neben Ziffern und Buchstaben sind
                folgende Zeichen möglich: &#64; . - ? + ! # & =
            </ins-list-flat>
        </ng-container>

        <!-- Als nog niks ingevuld is -->
        <ng-container
            *ngIf="
                password.errors?.characters &&
                password.errors?.invalidNumbers &&
                password.errors?.currentLength &&
                !password.errors?.invalidCharacters
            "
        >
            <p>
                <small>
                    Wählen Sie kein Passwort, das Sie auch für andere Websites verwenden oder das einfach zu erraten
                    ist.
                </small>
            </p>
        </ng-container>

        <!-- Als er geen foute invoer is, maar er nog 1 of 2 letters missen -->
        <ng-container
            *ngIf="
                password.errors?.characters &&
                !password.errors?.invalidNumbers &&
                !password.errors?.invalidCharacters &&
                !password.errors?.currentLength
            "
        >
            <p>
                <small>Fügen Sie dem Passwort bitte 2 Buchstaben hinzu.</small>
            </p>
        </ng-container>

        <!-- Als er geen foute invoer is, maar er nog 1 cijfer mist -->
        <ng-container
            *ngIf="
                password.errors?.invalidNumbers &&
                !password.errors?.characters &&
                !password.errors?.invalidCharacters &&
                !password.errors?.currentLength
            "
        >
            <p>
                <small>In Ihrem Passwort fehlt noch 1 Ziffer</small>
            </p>
        </ng-container>

        <!-- Als er geen errors zijn maar geen speciaal teken is ingevuld -->
        <ng-container *ngIf="!password.errors && !hasSpecialCharacter">
            <p>
                <small>Ihr Passwort wird stärker, indem Sie 1 dieser Zeichen zufügen: &#64; . - ? + ! # & =</small>
            </p>
        </ng-container>

        <!-- Als er geen errors zijn en er een speciaal teken is gebruikt -->
        <ng-container *ngIf="!password.errors && hasSpecialCharacter">
            <p>
                <small>Erledigt ☺️</small>
            </p>
        </ng-container>
    </div>
</div>
