import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {
    ApiErrorLevelEnum,
    ApiLegacyError,
    ApiRelationCodeEnum,
    ContentActionService,
    ContentDataService,
    ContentInterface,
    CreateNonRegularDriverRequestInterface as CreateRegularDriverRequestInterface,
    ErrorInterface,
    mapErrors,
    NewModal,
    ProductActionService,
    ProductBaseInterface,
    ProductEnGbInterface,
    ProductIdEnum,
    RelationActionService,
} from 'outshared-lib';
import { Observable, Subscription } from 'rxjs';
import { exhaustMap, map, switchMap } from 'rxjs/operators';
import { PremiumImpactModal } from '../../premium-impact-modal';
import { removeRegularDriverRequestInterface } from '../interfaces';
import { SelectedPanelEnum } from '@app-de/my-zone/enums';

const ELEMENT_NAME = 'relation_code';

@Component({
    selector: 'ins-insurance-regular-driver-form',
    templateUrl: './insurance-regular-driver-form.component.html',
    styleUrls: ['./insurance-regular-driver-form.component.scss'],
})
export class InsuranceRegularDriverFormComponent implements OnInit, OnDestroy {

    @Input() public product: ProductBaseInterface;

    public errors: ErrorInterface[];
    public isLoading: boolean;
    public form: UntypedFormGroup;
    public options$: Observable<ContentInterface[]>;
    public productIdEnum: typeof ProductIdEnum;
    // Change to RelationCodeEnum if insurance-policy store is implemented
    public apiRelationCodeEnum: typeof ApiRelationCodeEnum;

    private subscriptions: Subscription;

    constructor(
        private elementsActionService: ContentActionService,
        private elementsDataService: ContentDataService,
        private productActionService: ProductActionService,
        private relationActionService: RelationActionService,
        private changeDetectorRef: ChangeDetectorRef,
        private router: Router,
        private newModal: NewModal
    ) {
        this.productIdEnum = ProductIdEnum;
        this.apiRelationCodeEnum = ApiRelationCodeEnum;
        this.subscriptions = new Subscription();
        this.errors = [];
        this.isLoading = false;

        this.form = this.createForm();
        this.options$ = this.getRelationCodes$();
    }

    public get relationCode(): AbstractControl {
        return this.form.get('relationCode');
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    public ngOnInit(): void {
        if (this.product.non_regular_driver) {
            this.form.reset({ relationCode: this.product.non_regular_driver.relation_code });
        }
    }

    // TODO: WIDL-1503 replace with new insurance-policy store
    public onRemoveRegularDriver(request: removeRegularDriverRequestInterface): void {
        const terminateActiveNonRegularDriver = this.productActionService
            .terminateActiveNonRegularDriver$(request)
            .pipe(
                exhaustMap(() => this.relationActionService
                    .premiumModificationPurchasedProducts$({ referenceDate: request.end_date, sequenceNumber: this.product.purchased_product_sequence_number }))
            )
            .subscribe({
                next: (response) => this.onLoadPurchasedProduct(response),
                error: (errors: ApiLegacyError[]) => this.onError(errors),
            });

        this.onStart();
        this.subscriptions.add(terminateActiveNonRegularDriver);
    }

    // TODO: WIDL-1502 replace with new insurance-policy store
    public onCreateRegularDriver(request: CreateRegularDriverRequestInterface): void {
        const createNonRegularDriver = this.productActionService
            .createNonRegularDriver$(request)
            .pipe(
                exhaustMap(() => this.relationActionService
                    .premiumModificationPurchasedProducts$({ referenceDate: request.startDate, sequenceNumber: this.product.purchased_product_sequence_number }))
            )
            .subscribe({
                next: (response) => this.onLoadPurchasedProduct(response),
                error: (errors: ApiLegacyError[]) => this.onError(errors),
            });

        this.onStart();
        this.subscriptions.add(createNonRegularDriver);
    }

    // If insurance-policy store is added product is automatically updated
    private onLoadPurchasedProduct(response: ProductEnGbInterface[]): void {
        this.showModal(response);

        const loadPurchasedProduct = this.productActionService
            .loadPurchasedProduct$({
                product_id: this.product.product_id,
                purchased_product_sequence_number: this.product.purchased_product_sequence_number,
            })
            .subscribe({
                next: () => this.onSuccess(),
                error: (errors: ApiLegacyError[]) => this.onError(errors),
            });

        this.subscriptions.add(loadPurchasedProduct);

    }

    private showModal(products: ProductEnGbInterface[]): void {
        this.isLoading = false;
        const modalRef = this.newModal.open(PremiumImpactModal);
        modalRef.componentInstance.products = products;
    }

    private onStart(): void {
        this.isLoading = true;
        this.errors = [];
        this.changeDetectorRef.detectChanges();
    }

    private onSuccess(): void {
        this.isLoading = false;
        this.changeDetectorRef.detectChanges();
        this.closePanel();
    }

    private onError(errors: ApiLegacyError[]): void {
        this.isLoading = false;
        this.errors = this.mapErrors(errors);
        this.changeDetectorRef.detectChanges();
    }

    private closePanel(): void {
        const queryParams = {
            panel: SelectedPanelEnum.Close,
            purchased_product: this.product.purchased_product_sequence_number,
            scroll: true,
        };

        this.router.navigate([], {
            queryParams,
            queryParamsHandling: 'merge',
        });
    }

    // Some notification types are not recognized so we map everything as Error.
    private mapErrors(errors: ApiLegacyError[]): ErrorInterface[] {
        const _errors = errors.map((error) => ({
            ...error,
            notification_type: ApiErrorLevelEnum.Error,
        }));

        return mapErrors(_errors);
    }

    private createForm(): UntypedFormGroup {
        return new UntypedFormGroup({
            relationCode: new UntypedFormControl('', [Validators.required]),
        });
    }

    // Consider refactoring this function
    private getRelationCodes$(): Observable<ContentInterface[]> {
        return this.elementsActionService
            .requestContentElement$({ element_name: ELEMENT_NAME })
            .pipe(switchMap(() => this.elementsDataService
                .getElement$(ELEMENT_NAME)
                .pipe(map((content) => this.mapRelationCodes(content)))));
    }

    private mapRelationCodes(content: ContentInterface[]): ContentInterface[] {
        const hasRegularDriver = Boolean(this.product?.non_regular_driver);
        const policyHolder = [{ content_id: ApiRelationCodeEnum.Owner, content_description: 'Ich' }];
        const deviantDrivers = content
            .filter((element) => [
                ApiRelationCodeEnum.Child,
                ApiRelationCodeEnum.Partner,
            ].includes(element.content_id as ApiRelationCodeEnum));

        return hasRegularDriver ? [
            ...policyHolder,
            ...deviantDrivers,
        ] : deviantDrivers;
    }
}
