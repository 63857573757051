<ng-content select="ins-panel-header"></ng-content>
<div
    [class.panel]="panelClass"
    [class.panel--info]="border"
    [class.panel-hidden]="!visible"
    [class.panel--info-left]="isLeft"
    [class.panel--info-right]="isRight"
    [class.panel--info-direct-control]="isDirectControl"
>
    <ng-content></ng-content>
</div>
