import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { reducers } from './my-zone';

/**
 * @deprecated use OutInsurancePolicyModule
 */
@NgModule({
    imports: [StoreModule.forFeature('myZone', reducers)],
})
export class MyZoneStoreModule {}
