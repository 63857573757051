import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ElementValidateErrorsComponent } from './element-validate-errors.component';
import { ElementValidateErrorsContainer } from './element-validate-errors.container';
import { CynoFormModule } from '@app-de/shared/cyno-form/cyno-form.module';
import { SanitizePipeModule } from '@app-de/shared/pipes/sanitize/sanitize.module';

@NgModule({
    imports: [
        CommonModule,
        CynoFormModule,
        SanitizePipeModule,
        RouterModule,
    ],
    declarations: [
        ElementValidateErrorsComponent,
        ElementValidateErrorsContainer,
    ],
    exports: [ElementValidateErrorsContainer],
})
export class ElementValidateErrorsModule {
}
