/* eslint-disable @typescript-eslint/naming-convention */
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, QueryList, ViewChildren } from '@angular/core';
import { RelationInterface } from 'outshared-lib';
import { DetailsSectionEnum } from '@app-de/core/enums/detail-section.enum';
import { ScrollService } from '@app-de/core/services/scroll.service';
import { PanelExpandableComponent } from '@app-de/shared/panel-expandable/panel-expandable.component';

@Component({
    selector: 'ins-details',
    templateUrl: 'details.component.html',
    styleUrls: ['details.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetailsComponent {
    @Input() public relation: RelationInterface;
    @ViewChildren(PanelExpandableComponent) public children: QueryList<PanelExpandableComponent>;
    public detailsSectionEnum: typeof DetailsSectionEnum = DetailsSectionEnum;

    constructor(private scrollService: ScrollService, private changeDetectorRef: ChangeDetectorRef) {}

    public handleQueryParams(props: { section: DetailsSectionEnum; expand: boolean; scroll?: boolean }): void {
        const { section, expand, scroll } = props;

        this.closeAllPanels();
        this.expandPanel(section, expand);
        this.scrollTo(section, scroll);
    }

    public closeAllPanels(): void {
        this.children.forEach((child) => child.close());
        this.changeDetectorRef.detectChanges();
    }

    private expandPanel(section: DetailsSectionEnum, expand: boolean): void {
        const panel = this.children.find((child) => child.id === section);

        if (!panel) {
            return;
        }

        if (expand) {
            panel.open();
        }

        this.changeDetectorRef.detectChanges();
    }

    private scrollTo(section: DetailsSectionEnum, scroll?: boolean): void {
        if (!scroll) {
            return;
        }

        this.scrollService.scrollElementOffset(`#${section}`, 300);
    }
}
