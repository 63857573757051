import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CreateResidentialAddressInterface, DateService, ErrorInterface } from 'outshared-lib';

@Component({
    selector: 'ins-residential-address-form',
    templateUrl: './residential-address-form.component.html',
})
export class ResidentialAddressFormComponent implements OnInit {
    @Input() public errors: ErrorInterface[];
    @Input() public loading: boolean;

    @Output() public readonly submitted: EventEmitter<CreateResidentialAddressInterface> =
        new EventEmitter<CreateResidentialAddressInterface>();

    public form: UntypedFormGroup;
    public minDate: Date;

    constructor(private dateService: DateService) {}

    public ngOnInit(): void {
        this.form = this.createForm();
        this.minDate = new Date(this.dateService.add(1, 'day'));
    }

    public get address(): AbstractControl {
        return this.form.get('address');
    }

    public get startDate(): AbstractControl {
        return this.form.get('startDate');
    }

    public onSubmit(): void {
        if (!this.form.valid) {
            this.form.markAllAsTouched();
            return;
        }

        this.submitted.emit({
            internationalAddressId: this.address.value.address_id,
            startDate: this.startDate.value,
        });
    }

    private createForm(): UntypedFormGroup {
        return new UntypedFormGroup({
            address: new UntypedFormControl('', Validators.required),
            startDate: new UntypedFormControl('', Validators.required),
        });
    }
}
