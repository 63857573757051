import { CalculateCoveragePremiumInterface } from '../interfaces';

export function CalculateCoveragePremiumMapper(
    purchasedProductSequenceNumber: number
): CalculateCoveragePremiumInterface {
    return {
        purchased_product: {
            purchased_product_sequence_number: purchasedProductSequenceNumber,
        },
    };
}
