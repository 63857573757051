import { Component, OnInit } from '@angular/core';
import { AutoversicherungRoutesEnum } from '../car-insurance/autoversicherung';
import { AppRoutesEnum } from '@app-de/routes';
import { LayoutService } from '@app-de/store/layout/layout.service';
import { SalesfunnelRoutes } from '@app-de/salesfunnel/routing';

@Component({
    selector: 'ins-insurances',
    templateUrl: './insurances.component.html',
    styleUrls: ['./insurances.component.scss']
})
export class InsurancesComponent implements OnInit {
    public appRoutesEnum = AppRoutesEnum;
    public autoversicherungRoutesEnum = AutoversicherungRoutesEnum;
    public salesfunnelRoutesEnum = SalesfunnelRoutes;

    constructor(private layoutService: LayoutService) {}

    public ngOnInit(): void {
        this.layoutService.setBreadcrumbs([{ title: 'Home', routerLink: '/' }, { title: 'Versicherungen' }]);
    }
}
