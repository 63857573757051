import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CynoPasswordInputComponent } from './cyno-password.component';
import { ListModule } from '@app-de/shared/lists/list.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ListModule,
    ],
    declarations: [CynoPasswordInputComponent],
    exports: [CynoPasswordInputComponent],
})
export class CynoPasswordModule {}
