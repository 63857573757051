import { NgControl } from '@angular/forms';
import { Observable } from 'rxjs';

export abstract class CynoFieldControl<T> {
    public value: T | null;
    public readonly stateChanges: Observable<void>;
    public readonly id: string | number;
    public readonly placeholder: string;
    public readonly ngControl: NgControl | null;
    public readonly focused: boolean;
    public readonly required: boolean;
    public readonly errorState: boolean;
    public readonly disabled: boolean;
    public readonly controlType?: string;
    public abstract onContainerClick(event: MouseEvent): void;
}
