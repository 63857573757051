import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class NoChangesPossibleNotificationService {
    private showNotificationSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    public showNotification$: Observable<boolean> = this.showNotificationSubject$.asObservable();

    public showNotification(display: boolean): void {
        this.showNotificationSubject$.next(display);
    }
}
