import { Component, Input } from '@angular/core';
import { AppRoutesEnum, ModalRoutesEnum } from '@app-de/routes';
import { CynoErrorAbstractComponent } from '@app-de/shared/cyno-form/cyno-error/cyno-error.abstract';

@Component({
    selector: 'ins-element-validate-errors',
    templateUrl: 'element-validate-errors.component.html',
})
export class ElementValidateErrorsComponent extends CynoErrorAbstractComponent {
    @Input() public allowHtml: boolean = false;

    public appRoutesEnum = AppRoutesEnum;
    public modalRoutesEnum = ModalRoutesEnum;
}
