import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'ins-international-motor-insurance-card-button',
    templateUrl: './international-motor-insurance-card-button.component.html',
    styleUrls: ['./international-motor-insurance-card-button.component.scss'],
})
export class InternationalMotorInsuranceCardButtonComponent {
    @Input() public icon: string;
    @Input() public value: string;
    @Input() public disabled: boolean = false;
    @Input() public loading: boolean = false;

    @Output() public readonly response: EventEmitter<void> = new EventEmitter();

    public onClicked(): void {
        this.response.emit();
    }
}
