<form [formGroup]="form" (ngSubmit)="onSubmit()" role="form">
    <div class="form-group">
        <label for="email" class="control-label">E-Mail-Adresse</label>
        <div class="control-input">
            <input
                class="form-control"
                id="email"
                type="email"
                autocomplete="username"
                e2e="email"
                formControlName="email"
                (change)="emailChanged($event)"
            />
            <cyno-error *ngIf="email.touched && email.hasError('required')">
                Hoppla, da fehlt etwas. Bitte E-Mail-Adresse eingeben.
            </cyno-error>
            <cyno-error *ngIf="email.touched && email.hasError('email')">
                Sorry, das ist keine gültige E-Mail-Adresse.
            </cyno-error>
        </div>
    </div>
    <div class="form-group">
        <label for="password" class="control-label">Passwort</label>
        <div class="control-input">
            <input
                class="form-control"
                insShowPassword
                id="password"
                type="password"
                autocomplete="password"
                e2e="password"
                formControlName="password"
            />
            <cyno-error *ngIf="password.touched && password.hasError('required')">
                Hoppla, da fehlt etwas. Bitte Passwort eingeben.
            </cyno-error>
        </div>
    </div>
    <div class="form-group">
        <div class="control-input control-input--right">
            <cyno-checkbox formControlName="stayLoggedIn" id="stayLoggedIn" e2e="stayLoggedIn">
                Eingeloggt bleiben
            </cyno-checkbox>
        </div>
    </div>
    <div class="form-group">
        <div class="control-input control-input--right">
            <button type="submit" class="btn btn--primary btn--block" [disabled]="loading" e2e="form_submit">
                Einloggen
            </button>
        </div>
    </div>
    <div class="form-group">
        <div class="control-input control-input--right">
            <p>
                <a class="link-primary" [routerLink]="accountRecoveryModalLink">Passwort vergessen?</a>
            </p>
        </div>
    </div>
</form>
<div class="login-box__error">
    <div class="alert alert-error" *ngIf="errors.length > 0">
        <ul>
            <li *ngFor="let error of errors">
                <svg class="svg-icon svg-icon--xs svg-icon--dark">
                    <use href="assets/icons-ui.svg#cross-closed"></use>
                </svg>
                <span [innerHTML]="error.message"></span>
            </li>
        </ul>
    </div>
</div>
