import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ProductBaseInterface } from '../../interfaces';
import * as fromMyZone from '../../my-zone';
import {
    allProductsLoaded,
    getActiveProducts,
    getDetailLoaded,
    getProductCollection,
    getProductEntities,
    getProductIds,
    getSelectedProduct,
    getToCancelProducts,
    getTransferProducts,
    isProductLoaded,
} from './product.selectors';

/** @deprecated use InsurancePolicyDataService */
@Injectable({
    providedIn: 'root',
})
export class ProductSelectorService {
    /** @deprecated use InsurancePolicyDataService */
    public isProductLoaded$: Observable<boolean> = this.store$.select((isProductLoaded));
    /** @deprecated use InsurancePolicyDataService */
    public getSelectedProduct$: Observable<ProductBaseInterface> = this.store$.select((getSelectedProduct));
    /** @deprecated use InsurancePolicyDataService */
    public getProductEntities$: Observable<{ [id: string]: ProductBaseInterface }> = this.store$.
        select((getProductEntities)
        );

    /** @deprecated use InsurancePolicyDataService */
    public getDetailLoaded$: Observable<{ [id: string]: boolean }> = this.store$.select((getDetailLoaded));
    /** @deprecated use InsurancePolicyDataService */
    public getProductCollection$: Observable<ProductBaseInterface[]> = this.store$.select((getProductCollection));
    /** @deprecated use InsurancePolicyDataService */
    public getToCancelProducts$: Observable<ProductBaseInterface[]> = this.store$.select((getToCancelProducts));
    /** @deprecated use InsurancePolicyDataService */
    public getProductIds$: Observable<number[]> = this.store$.select((getProductIds));
    /** @deprecated use InsurancePolicyDataService */
    public getTransferProducts$: Observable<ProductBaseInterface[]> = this.store$.select((getTransferProducts));
    /** @deprecated use InsurancePolicyDataService */
    public getActiveProducts$: Observable<ProductBaseInterface[]> = this.store$.select((getActiveProducts));
    /** @deprecated use InsurancePolicyDataService */
    public allProductsLoaded$: Observable<boolean> = this.store$.select((allProductsLoaded));

    constructor(private store$: Store<fromMyZone.State>) {}
}
