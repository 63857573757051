<div *ngIf="breadcrumb?.length">
    <ol itemscope itemtype="https://schema.org/BreadcrumbList" class="breadcrumb breadcrumb--bordered">
        <li
            itemprop="itemListElement"
            itemscope
            itemtype="https://schema.org/ListItem"
            *ngFor="let item of breadcrumb; index as breadcrumbIndex"
        >
            <a itemprop="item" *ngIf="item.href" href="{{ item.href }}" title="{{ item.title }}">
                <span itemprop="name">{{ item.title }}</span>
            </a>
            <a itemprop="item" *ngIf="item.routerLink" [routerLink]="item.routerLink" title="{{ item.title }}">
                <span itemprop="name">{{ item.title }}</span>
            </a>
            <span itemprop="name" *ngIf="!item.routerLink && !item.href">{{ item.title }}</span>
            <meta itemprop="position" [content]="breadcrumbIndex" />
        </li>
    </ol>
</div>
