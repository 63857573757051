import { CanDisable } from './disabled';

type Constructor<T> = new (...args: any[]) => T;

export interface HasTabIndex {
    /** Tabindex of the component. */
    tabIndex: number;
}

/** Mixin to augment a directive with a `tabIndex` property. */
export function mixinTabIndex<T extends Constructor<CanDisable>>(
    base: T,
    defaultTabIndex = 0,
): Constructor<HasTabIndex> & T {
    return class extends base {
        private _tabIndex: number = defaultTabIndex;

        public get tabIndex(): number {
            return this.disabled ? -1 : this._tabIndex;
        }

        public set tabIndex(value: number) {
            // If the specified tabIndex value is null or undefined, fall back to the default value.
            this._tabIndex = value != null ? value : defaultTabIndex;
        }

        constructor(...args: any[]) {
            super(...args);
        }
    };
}
