<ins-rack>
    <ins-rack-row>
        <ins-rack-col>
            <h2>
                {{ invoiceDetails?.invoice.invoiceDescription | capitalize }} {{ invoiceDetails?.invoice.description }}
            </h2>
        </ins-rack-col>
        <ins-rack-col textAlign="right">
            Rechnungsnummer: {{ invoiceDetails?.invoice.invoiceNumber }}
            <br />
            Datum: {{ invoiceDetails?.invoice.invoiceDate | dateFormat: 'DD.MM.YYYY' }}
        </ins-rack-col>
    </ins-rack-row>
</ins-rack>

<ins-invoice-settling [invoiceDetails]="invoiceDetails"></ins-invoice-settling>
<ins-invoice-advance [invoiceDetails]="invoiceDetails"></ins-invoice-advance>
<ins-invoice-total [invoiceDetails]="invoiceDetails"></ins-invoice-total>

<ins-rack *ngIf="invoiceDetails">
    <ins-rack-row>
        <ins-rack-col>
            <a [routerLink]="['/', appRoutesEnum.MyZone, myZoneRoutes.Invoices]">
                <svg class="svg-icon svg-icon--xs"><use href="assets/icons-ui.svg#links-open"></use></svg>
                Zurück zur Rechnungsübersicht
            </a>
        </ins-rack-col>
        <ins-rack-col textAlign="right">
            <a href="#" (click)="downloadInvoice(invoiceDetails.invoice.invoiceId)">Download</a>
        </ins-rack-col>
    </ins-rack-row>
</ins-rack>
