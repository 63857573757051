import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {
    LoadProductScenarioResponseInterface,
    PolicyScenarioCodeEnum,
    ProductScenariosActionService,
    ProductScenariosDataService,
} from 'outshared-lib';
import { Observable, ReplaySubject, Subscription } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { MyZoneRoutes } from '@app-de/my-zone';
import { AppRoutesEnum } from '@app-de/routes';

@Component({
    selector: 'ins-incomplete-information-notification',
    templateUrl: './incomplete-information-notification.component.html',
})
export class IncompleteInformationNotificationComponent implements OnDestroy, OnInit {
    public subscriptions: Subscription = new Subscription();

    public scenario$: Observable<LoadProductScenarioResponseInterface[]>;

    public isNavigatedToInfoCheckPage$: ReplaySubject<boolean> = new ReplaySubject(1);

    private productScenariosDataService: ProductScenariosDataService = inject(ProductScenariosDataService);

    private productScenariosActionService: ProductScenariosActionService = inject(ProductScenariosActionService);

    private router: Router = inject(Router);

    public ngOnInit(): void {
        const routerNavigation = this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                map((event) => (<NavigationEnd>event).url.includes(MyZoneRoutes.InsuranceInfoCheck)),
            )
            .subscribe((value) => this.isNavigatedToInfoCheckPage$.next(value));

        this.isNavigatedToInfoCheckPage$.next(this.router.url.includes(MyZoneRoutes.InsuranceInfoCheck));

        this.scenario$ = this.productScenariosActionService
            .loadProductScenarios$()
            .pipe(
                switchMap(() =>
                    this.productScenariosDataService.getProductScenarioByCode$(PolicyScenarioCodeEnum.LineType40),
                ),
            );

        this.subscriptions.add(routerNavigation);
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
        this.productScenariosActionService.clearProductScenarios();
    }

    public redirectToInsuranceInfoCheck(productSequenceNumber: number): void {
        this.router.navigate([AppRoutesEnum.MyZone, MyZoneRoutes.InsuranceInfoCheck], {
            queryParams: {
                purchased_product: productSequenceNumber,
            },
        });
    }
}
