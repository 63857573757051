import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PolicyLoadingStateService {
    private loading$: BehaviorSubject<boolean>;

    constructor() {
        this.loading$ = new BehaviorSubject(false);
    }

    public getLoading$(): Observable<boolean> {
        return this.loading$.asObservable();
    }

    public start(): void {
        this.loading$.next(true);
    }

    public complete(): void {
        this.loading$.next(false);
    }
}
