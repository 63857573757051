import { Component, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { IconUiIcon } from '../../common';
import { NavigationMobileItemRoleType } from './navigation-mobile-item-role.type';

@Component({
    selector: 'wuc-navigation-mobile-item',
    templateUrl: 'navigation-mobile-item.component.html',
    styleUrls: ['navigation-mobile-item.component.scss'],
})
export class WucNavigationMobileItemComponent {
    @Input() public url?: string | null;
    @Input() public route?: string | null;
    @Input() public icon!: IconUiIcon;
    @Input() public role: NavigationMobileItemRoleType = 'link';
    @Input() public name!: string;

    @Output() public clicked = new EventEmitter<boolean>();

    @HostListener('document:click', ['$event.target'])
    public onDocumentClick(targetElement: HTMLElement): void {
        if (this.active && !this.elementRef.nativeElement.contains(targetElement)) {
            this.toggle();
        }
    }

    public active: boolean = false;

    constructor(private elementRef: ElementRef) {}

    public toggle(): void {
        if (this.role === 'menu') {
            this.active = !this.active;
            this.clicked.emit(this.active);
        }
    }

    public onClick(event: MouseEvent): void {
        event.preventDefault();
        this.toggle();
    }
}
