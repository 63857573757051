<form (ngSubmit)="onFormSubmit()" [formGroup]="form">
    <cyno-field e2e="currentPassword">
        <cyno-label>Aktuelles Passwort</cyno-label>
        <cyno-password formControlName="currentPassword"></cyno-password>
    </cyno-field>
    <cyno-alert *ngIf="errors" [notifications]="errors" type="error"></cyno-alert>
    <div class="form-group">
        <div class="control-label"></div>
        <div class="control-input">
            <a [routerLink]="['', { outlets: { modal: 'passwort-vergessen' } }]" class="btn btn--link">
                Passwort vergessen
            </a>
        </div>
    </div>

    <hr />

    <cyno-field e2e="newPassword">
        <cyno-label>Neues Passwort</cyno-label>
        <cyno-password [passwordRequirements]="true" formControlName="newPassword"></cyno-password>
    </cyno-field>

    <cyno-field e2e="repeatNewPassword">
        <cyno-label>Passwort wiederholen</cyno-label>
        <cyno-password formControlName="repeatPassword"></cyno-password>
        <cyno-error *ngIf="repeatPassword.errors?.notEqual">Ihre Passwörter stimmen nicht überein</cyno-error>
    </cyno-field>

    <ins-details-submit-button [disabled]="disabled && errors.length === 0">Speichern</ins-details-submit-button>
</form>
