<div class="passport" *ngIf="relation; else loading">
    <!-- Info -->
    <div class="passport__info">
        <!-- Avatar-->
        <div class="passport__avatar" [class.passport__avatar--single]="!isDetailsVisible">
            <div class="passport__icon">
                <svg class="svg-icon">
                    <use href="assets/icons-general.svg#profiel-pica"></use>
                </svg>
                <p>
                    <strong data-hj-suppress>{{ relation.first_name }} {{ relation.surname }}</strong>
                </p>
            </div>
            <div class="passport__button">
                <button class="btn btn--link" (click)="toggle()">
                    <svg class="svg-icon svg-icon--xs" *ngIf="!isDetailsVisible">
                        <use href="assets/icons-ui.svg#onder-open"></use>
                    </svg>
                    <svg class="svg-icon svg-icon--xs" *ngIf="isDetailsVisible">
                        <use href="assets/icons-ui.svg#boven-open"></use>
                    </svg>
                </button>
            </div>
        </div>
        <!-- // Avatar -->
        <!-- Details -->
        <div
            class="passport__details"
            [class.passport__details--show]="isDetailsVisible"
            [class.passport__details--hide]="!isDetailsVisible"
        >
            <div class="passport__content">
                <div class="passport__list">
                    <ins-list-flat [table]="true">
                        <ins-list-flat-item>
                            <!-- prettier-ignore -->
                            <strong>Adresse: </strong>
                            <span>{{ relation.residential_address?.address?.street_name }}</span>
                            <span>
                                {{ relation.residential_address?.civic_number
                                }}{{ relation.residential_address?.civic_number_suffix }},
                            </span>
                            <!-- prettier-ignore -->
                            <span>{{ relation.residential_address?.postal_code }} </span>
                            <span>{{ relation.residential_address?.address?.city | capitalize }}</span>
                        </ins-list-flat-item>
                        <ins-list-flat-item>
                            <span class="text--no-wrap">
                                <strong>Kundennummer:</strong>
                                {{ relation.relation_id }}
                            </span>
                        </ins-list-flat-item>
                        <ins-list-flat-item>
                            <span>
                                <strong>Familienzusammensetzung:</strong>
                                {{ relation.family_composition.family_composition_description }}
                            </span>
                        </ins-list-flat-item>
                    </ins-list-flat>
                </div>
                <div class="passport__edit">
                    <a class="link-secondary" [routerLink]="['/', appRoutes.MyZone, myZoneRoutes.Details]">
                        Angaben ändern
                    </a>
                </div>
            </div>
        </div>
        <!-- // Details -->
    </div>
    <!-- // Info -->
</div>

<ng-template #loading>
    <div class="passport">
        <div class="passport__info">
            <div class="passport__avatar passport__avatar--single">
                <div class="passport__icon">
                    <svg class="svg-icon">
                        <use href="assets/icons-general.svg#profiel-pica"></use>
                    </svg>
                </div>
            </div>
            <div class="passport__details"></div>
        </div>
    </div>
</ng-template>
