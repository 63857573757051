import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { WucIconUiModule, WucLinkModule } from '@inshared/website-ui-components';
import { DetailsToggleButtonComponent } from './details-toggle-button.component';

@NgModule({
    declarations: [DetailsToggleButtonComponent],
    exports: [DetailsToggleButtonComponent],
    imports: [CommonModule, RouterModule, WucLinkModule, WucIconUiModule],
})
export class DetailsToggleButtonModule {}
