import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { GetTestimonialsResponseInterface, TestimonialInterface } from '../interfaces';
import { TestimonialsSelectors } from '../store/testimonials.selectors';
import { Testimonials, TestimonialsState } from '../store/testimonials.state';

@Injectable({
    providedIn: 'root',
})
export class TestimonialsDataService {
    constructor(private store$: Store<Testimonials>) {}

    public getState$(): Observable<TestimonialsState> {
        return this.store$.select(TestimonialsSelectors.getState);
    }

    public getTestimonials$(): Observable<GetTestimonialsResponseInterface> {
        return this.store$.select(TestimonialsSelectors.getTestimonials);
    }

    public getTestimonialsById$(id: string): Observable<TestimonialInterface[]> {
        return this.store$.select(TestimonialsSelectors.getTestimonialsById(id));
    }
}
