<ins-modal-close></ins-modal-close>
<ins-modal-title>Unser aufrichtiges Beileid!</ins-modal-title>
<ins-modal-content>
    <p>
        Schicken Sie uns bitte eine E-Mail mit einer Kopie der Sterbeurkunde und einer Mitteilung, wie wir Sie am besten
        erreichen können (Telefonnummer und/oder E-Mail-Adresse). Wir nehmen dann so schnell wie möglich Kontakt mit
        Ihnen auf und helfen Ihnen gerne weiter.
    </p>
</ins-modal-content>
<ins-modal-actions alignment="right">
    <a [routerLink]="['/', appRoutesEnum.Contact, { outlets: { modal: modalRoutesEnum.Question } }]" class="btn btn--primary" title="E-Mail senden">
        E-Mail senden
    </a>
</ins-modal-actions>
