/* eslint-disable @typescript-eslint/naming-convention */
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {
    ApiGenderCodeEnum,
    ApiRelationCodeEnum,
    CreateNonRegularDriverRequestInterface as CreateRegularDriverRequestInterface,
    DateService,
    ElementValidator,
    ErrorInterface,
    ProductBaseInterface,
} from 'outshared-lib';
import { AgeValidator } from '@app-de/shared/validators/age.validator';
import { MinimumAgeValidator } from '@app-de/shared/validators/minimum-age.validator';

@Component({
    selector: 'ins-insurance-deviant-driver-form',
    templateUrl: './insurance-deviant-driver-form.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InsuranceDeviantDriverFormComponent implements OnInit {
    @Input() public errors: ErrorInterface[];
    @Input() public product: ProductBaseInterface;
    @Input() public isLoading: boolean;
    @Input() public relationCode: ApiRelationCodeEnum;

    @Output()
    public readonly createRegularDriver: EventEmitter<Omit<CreateRegularDriverRequestInterface, 'emailAddress'>> =
        new EventEmitter();

    public form: UntypedFormGroup;

    constructor(
        private dateService: DateService,
        private ageValidator: AgeValidator,
        private minimumAgeValidator: MinimumAgeValidator,
        private elementValidator: ElementValidator,
    ) {
        this.form = this.createForm();
    }

    public get genderCode(): AbstractControl {
        return this.form.get('genderCode');
    }

    public get firstName(): AbstractControl {
        return this.form.get('firstName');
    }

    public get surname(): AbstractControl {
        return this.form.get('surname');
    }

    public get birthdate(): AbstractControl {
        return this.form.get('birthdate');
    }

    public get drivingLicenseFirstDateOfIssue(): AbstractControl {
        return this.form.get('drivingLicenseFirstDateOfIssue');
    }

    public get prefixSurname(): AbstractControl {
        return this.form.get('prefixSurname');
    }

    public get startDate(): AbstractControl {
        return this.form.get('startDate');
    }

    public ngOnInit(): void {
        if (this.product.non_regular_driver) {
            this.resetForm(this.product);
        }

        this.drivingLicenseFirstDateOfIssue.addValidators(this.ageValidator.validateMinimumRange(this.birthdate, 17));
    }

    public onSubmit(): void {
        if (!this.form.valid) {
            this.form.markAllAsTouched();
            return;
        }

        this.createRegularDriver.emit({
            productSequenceNumber: this.product.purchased_product_sequence_number,
            firstName: this.firstName.value,
            surname: this.surname.value,
            prefixSurname: this.getPrefixSurname(this.genderCode.value),
            birthdate: this.birthdate.value,
            genderCode: this.genderCode.value,
            startDate: this.startDate.value,
            relationCode: this.relationCode as unknown as ApiRelationCodeEnum,
            drivingLicenseFirstDateOfIssue: this.drivingLicenseFirstDateOfIssue.value,
        });
    }

    private createForm(): UntypedFormGroup {
        const minimumAge = this.dateService.subtract(this.dateService.add(1, 'day'), 25, 'year', 'YYYY-MM-DD');

        return new UntypedFormGroup({
            firstName: new UntypedFormControl('', [Validators.required]),
            surname: new UntypedFormControl('', [Validators.required]),
            prefixSurname: new UntypedFormControl(''), // no control
            birthdate: new UntypedFormControl('', {
                validators: [Validators.required, this.minimumAgeValidator.validate(minimumAge)],
                asyncValidators: [
                    this.elementValidator.validate('birthdate', 'VERZ.PROD.VSLG.AFBT', 'birthdate') as AsyncValidatorFn,
                ],
                updateOn: 'blur',
            }),
            genderCode: new UntypedFormControl('', [Validators.required]),
            startDate: new UntypedFormControl('', [Validators.required]),
            drivingLicenseFirstDateOfIssue: new UntypedFormControl('', {
                validators: [Validators.required],
                updateOn: 'blur',
            }),
        });
    }

    private resetForm(product: ProductBaseInterface): void {
        const {
            surname,
            birthdate,
            gender_code,
            relation_code,
            middle_name_initials,
            driving_license_first_date_of_issue,
            first_name,
        } = product.non_regular_driver;
        const differentRelationCode = relation_code !== this.relationCode;

        if (differentRelationCode) {
            this.form.reset();
            return;
        }

        this.form.reset({
            firstName: first_name,
            surname: surname,
            birthdate: birthdate,
            genderCode: gender_code,
            startDate: '',
            prefixSurname: middle_name_initials,
            drivingLicenseFirstDateOfIssue: driving_license_first_date_of_issue,
        });
    }

    private getPrefixSurname(genderCode: ApiGenderCodeEnum): string {
        switch (genderCode) {
            case ApiGenderCodeEnum.Female:
                return 'Frau';
            case ApiGenderCodeEnum.Male:
                return 'Herr';
        }
        return 'Neutral';
    }
}
