import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { TestimonialsRequestInterface } from '../interfaces';
import { testimonialRequestMapper } from '../mappers/testimonial.request.mapper';
import { getTestimonialsAction } from '../store/testimonials.actions';
import { Testimonials } from '../store/testimonials.state';
import { TestimonialsEventService } from './testimonials.event.service';

@Injectable({
    providedIn: 'root',
})
export class TestimonialsActionService {
    constructor(private store$: Store<Testimonials>, private testimonialsEventService: TestimonialsEventService) {}

    public getTestimonials$(payload: TestimonialsRequestInterface): Observable<void> {
        this.store$.dispatch(getTestimonialsAction(testimonialRequestMapper(payload)));
        return this.testimonialsEventService.getTestimonials$();
    }
}
