import { Injectable } from '@angular/core';
import { ApiService } from 'outshared-lib';
import { Observable } from 'rxjs';
import { InsuranceCompanyInterface } from '../interfaces/insurance-company.interface';

const INSURANCE_COMPANIES_RESOURCE = 'en-gb/insurance_company-general/request/insurancecompanies';

@Injectable({
    providedIn: 'root',
})
export class InsuranceCompaniesService {

    constructor(private apiService: ApiService) {}

    public getInsuranceCompanies$(): Observable<InsuranceCompanyInterface[]> {
        return this.apiService.post$<InsuranceCompanyInterface[]>(INSURANCE_COMPANIES_RESOURCE, {});
    }
}
