import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { InsuranceDataComponent } from './insurance-data.component';
import { InsuranceDataContainer } from './insurance-data.container';
import { CapitalizeModule } from '@app-de/shared/pipes/capitalize/capitalize.module';
import { DateFormatPipeModule } from '@app-de/shared/pipes/date-format/date-format.module';
import { LicensePlatePipeModule } from '@app-de/shared/pipes/licence-plate/license-plate.module';
import { TooltipModule } from '@app-de/shared/tooltip/tooltip.module';

@NgModule({
    imports: [
        CommonModule,
        LicensePlatePipeModule,
        DateFormatPipeModule,
        RouterModule,
        TooltipModule,
        CapitalizeModule,
    ],
    exports: [InsuranceDataContainer],
    declarations: [
        InsuranceDataComponent,
        InsuranceDataContainer,
    ],
})
export class InsuranceDataModule {}
