import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WucBalloonModule, WucFormErrorModule, WucFormFieldLabelModule, WucFormFieldModule, WucInputRadioModule } from '@inshared/website-ui-components';
import { InsuranceDeviantDriverFormModule } from './insurance-deviant-driver-form';
import { InsurancePolicyHolderFormModule } from './insurance-policy-holder-form';
import { InsuranceRegularDriverFormComponent } from './insurance-regular-driver-form.component';
import { CynoFormModule } from '@app-de/shared/cyno-form';

@NgModule({
    declarations: [InsuranceRegularDriverFormComponent],
    imports: [
        CommonModule,
        CynoFormModule,
        InsurancePolicyHolderFormModule,
        InsuranceDeviantDriverFormModule,
        WucFormFieldModule,
        WucInputRadioModule,
        WucFormFieldLabelModule,
        WucFormErrorModule,
        WucBalloonModule,
    ],
    exports: [InsuranceRegularDriverFormComponent],
})
export class InsuranceRegularDriverFormModule {
}
