import { Inject, Injectable, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {
    AnalyticsActionService,
    CynolyticsPageviewInterface,
    CynolyticsTypeEnum,
    DatalayerInterface,
} from 'outshared-lib';
import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { LocalStorageService } from '@app-de/core/storage/universal.inject';

@Injectable({
    providedIn: 'root',
})
export class RouterService implements OnDestroy {
    private subscription: Subscription = new Subscription();
    constructor(
        private router: Router,
        private analyticsService: AnalyticsActionService,
        @Inject(LocalStorageService) private localStorage: Storage,
    ) {}

    public init(): void {
        this.onPageView();
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    private onPageView(): void {
        const router = this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                map((event: NavigationEnd) => event.urlAfterRedirects),
            )
            .subscribe((data) => {
                this.sendAnalytics(data);
            });
        this.subscription.add(router);
    }

    private sendAnalytics(path: string): void {
        const adata: DatalayerInterface = { event: 'pageview', page: { path } };
        const cdata: CynolyticsPageviewInterface = { type: CynolyticsTypeEnum.Pageview, pageTitle: '', urlPath: path };

        this.analyticsService.sendToDatalayer(adata);
        this.analyticsService.sendToCynolytics(cdata);
    }
}
