import { Component, Input } from '@angular/core';
import { InternationalRelationInterface } from 'outshared-lib';

@Component({
    selector: 'ins-payment-details-data',
    templateUrl: './payment-details-data.component.html',
})
export class PaymentDetailsDataComponent {
    @Input() public relation!: InternationalRelationInterface;
}
