import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RequestService } from '../../../core';
import { ScenarioInterface } from '../../interfaces';

/* @deprecated - Use new ScenarioActionService */
@Injectable({
    providedIn: 'root',
})
export class ScenariosService {
    constructor(private requestService: RequestService) {}

    public loadScenarios(): Observable<ScenarioInterface[]> {
        return this.requestService
            .post('en-gb/relation-general/request/scenarios')
            .pipe(map((response) => response.data.scenarios));
    }
}
