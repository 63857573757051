export const pickadayConfig = {
    germany: {
        format: 'DD.MM.YYYY',
        placeholder: 'TT.MM.JJJJ',
        i18n: {
            previousMonth: 'Letzten Monat zeigen',
            nextMonth: 'Nächsten Monat zeigen',
            months: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
            weekdays: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
            weekdaysShort: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
        },
    },
};
