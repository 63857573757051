import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CreateBankDetailsInterface, ElementValidator, ErrorInterface } from 'outshared-lib';
import { validateIban } from '@app-de/shared/validators/iban.validator';

@Component({
    selector: 'ins-payment-details-form-component',
    templateUrl: 'payment-details-form.component.html',
})
export class PaymentDetailsFormComponent implements OnInit {
    @Output() public readonly paymentDetailsSubmitted = new EventEmitter<CreateBankDetailsInterface>();

    @Input() public disabled: boolean;

    @Input() public errors: ErrorInterface[];

    /**
     * public for DOM
     * @type {FormGroup}
     */
    public paymentDetailsForm: UntypedFormGroup;

    constructor(private elementValidator: ElementValidator) {}

    public ngOnInit(): void {
        this.createForm();
    }

    public onFormSubmit(): void {
        if (this.paymentDetailsForm.invalid) {
            this.paymentDetailsForm.markAllAsTouched();
            return;
        }

        this.paymentDetailsSubmitted.emit({
            accountHolderName: this.paymentDetailsForm.value.accountHolderName,
            ibanAccountNumber: this.paymentDetailsForm.value.ibanAccountNumber,
            directDebitAgreementIndication: this.paymentDetailsForm.value.directDebitAgreementIndication,
        });
    }

    private createForm(): void {
        if (!this.paymentDetailsForm) {
            this.paymentDetailsForm = new UntypedFormGroup({
                ibanAccountNumber: new UntypedFormControl('', {
                    validators: [Validators.required, validateIban],
                    asyncValidators: [
                        this.elementValidator.validate(
                            // ibanAccountNumber
                            'iban_bank_account_number',
                            'VERZ.PROD.VSLG.NREL.PROD.AUTO',
                            'relation_payment_method',
                        ),
                    ],
                    updateOn: 'blur',
                }),
                accountHolderName: new UntypedFormControl('', [Validators.required]),
                directDebitAgreementIndication: new UntypedFormControl('', [Validators.required]),
            });
        }
    }
}
