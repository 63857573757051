import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { NewModalRef, NEW_MODAL_DATA } from 'outshared-lib';
import { AddressInterface } from 'outshared-lib/relation/interfaces/address.interface';

interface ModalData {
    mailingAddress: AddressInterface;
}

@Component({
    selector: 'ins-cancel-corresponding-address-modal',
    templateUrl: './cancel-correspondence-address.modal.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CancelCorrespondenceAddressModal {

    constructor(
        private activeModalRef: NewModalRef<CancelCorrespondenceAddressModal>,
        @Inject(NEW_MODAL_DATA) public data: ModalData
    ) {
    }

    public close(): void {
        this.activeModalRef.close(false);
    }

    public confirm(): void {
        this.activeModalRef.close(true);
    }
}
