import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CarouselModule } from 'outshared-lib';
import { NewsCarouselComponent } from '@app-de/shared/news-carousel/news-carousel.component';

@NgModule({
    imports: [
        CommonModule,
        CarouselModule,
    ],
    declarations: [NewsCarouselComponent],
    exports: [NewsCarouselComponent],
})
export class NewsCarouselModule { }
