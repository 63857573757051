import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class CynoBirthdateService {
    public separator: string = '-';

    /**
     * formatInitalValue function - formats initial date value to: MM-DD-YYYY
     * @param value string
     */
    public formatInitial(value: string): string {
        const fullFormat: RegExp = /^([0-2][0-9]|(3)[0-1])(\-)(((0)[0-9])|((1)[0-2]))(\-)\d{4}$/i;

        if (!value) {
            return value;
        }

        if (fullFormat.test(value)) {
            return value;
        }

        return value;
    }

    /**
     * formatInput function - recursively format characters based on rules
     * @param value string
     * @param separator string
     */
    public formatInput(value, separator?): string {
        const characters: string[] = value.replace(/[-./]/g, '').split('');

        if (separator) {
            this.separator = separator;
        }

        return characters.reduce((accumulator, currentValue) => {
            return this.format(accumulator + currentValue);
        }, '');
    }

    /**
     * formatBackspaceFunction - remove characters form value
     * @param value string
     */
    public formatBackspace(value: string): string {
        const lastTwoChars = value.slice(-2);

        if (lastTwoChars.endsWith(this.separator)) {
            return value.slice(0, -1);
        }

        return value;
    }

    /**
     * reverseDate function - reverses data string DD-MM-YYYY => YYYY-MM-DD
     * @param value string
     */
    public reverseDate(value: string): string {
        if (value.includes('-')) {
            return value.split('-').reverse().join('-');
        }
        return value.split(this.separator).reverse().join(this.separator);
    }

    /**
     * resetFormat function - formats string to DD-MM-YYYY
     * @param value string
     */
    public resetFormat(value: string): string {
        return value.replace(/[./]/g, '-');
    }

    /**
     * format function - formats the input (use with input change)
     * @param value string
     */
    private format(value: string): string {
        // Day
        if (value.length === 1) {
            return this.formatFirstChar(value);
        }

        if (value.length === 2) {
            return this.formatSecondChar(value);
        }

        // Month
        if (value.length === 3) {
            return this.formatThirdChar(value);
        }

        if (value.length === 4) {
            return this.formatFourthChar(value);
        }

        if (value.length === 5) {
            return this.formatFifthChar(value);
        }

        // Year
        if (value.length === 6) {
            return this.formatSixthChar(value);
        }

        if (value.length === 8) {
            return this.formatEighthChar(value);
        }

        if (value.length > 10) {
            return this.formatLastChar(value);
        }

        return value;
    }

    /**
     * formatFirstChar function - formats first number if higher then 3
     * @param value string
     */
    private formatFirstChar(value: string): string {
        if (parseInt(value, 10) > 3) {
            return `0${value}${this.separator}`;
        }

        return value;
    }

    /**
     * formatSecondChar function - formats second character if:
     * - number is larger than 31
     * - second character is dash
     * - if value lengt is equal to 2
     * @param value string
     */
    private formatSecondChar(value: string): string {
        const firstNumber = parseInt(value.substr(0, 1), 10);
        const secondChar = value.substr(1, 2);

        // checks if input is not higher then possible day 31
        if (parseInt(value, 10) > 31) {
            return value.slice(0, -1);
        }

        // checks if input is '-' after day 1, 2 or 3
        if ([1, 2, 3].includes(firstNumber) && secondChar === this.separator) {
            return `0${firstNumber}${this.separator}`;
        }

        if (value.length === 2) {
            return `${value}${this.separator}`;
        }

        return value;
    }

    /**
     * formatThirdChar function - formats third character
     * @param value string
     */
    private formatThirdChar(value: string): string {
        return this.validateLastDash(value);
    }

    /**
     * validateChar function - validates if last char needs to be dash
     * @param value string
     */
    private validateLastDash(value: string): string {
        const lastChar = value.charAt(value.length - 1);

        if (lastChar !== this.separator) {
            return `${value.slice(0, -1)}${this.separator}${lastChar}`;
        }

        return value;
    }

    /**
     * formatFourthChar function - format fourth char:
     * - if fourth char is larger than 1 prefix with 0.
     * @param value string
     */
    private formatFourthChar(value: string): string {
        const lastChar = value.charAt(value.length - 1);
        const firstChars = value.slice(0, -1);

        if (parseInt(lastChar, 10) > 1) {
            return `${firstChars}0${lastChar}`;
        }

        return value;
    }

    /**
     * formatFifthChar function - formats fifth char if:
     * - if fourth char is equal to 1 and fifth is greater than 2
     * - if fourth char is equal to 0 and fifth char is equal to 0
     * - if fourth char is equal to 1 and fifth char is equal to -
     * - if fourth char is equal to 0 and fifth char is equal to -
     * @param value value string
     */
    private formatFifthChar(value: string): string {
        const firstChar = value.substr(3, 1);
        const secondChar = value.substr(4, 1);

        if (parseInt(firstChar, 10) === 1 && parseInt(secondChar, 10) > 2) {
            return value.slice(0, -1);
        }

        if (parseInt(firstChar, 10) === 0 && parseInt(secondChar, 10) === 0) {
            return value.slice(0, -1);
        }

        if (parseInt(firstChar, 10) === 1 && secondChar === this.separator) {
            return value.slice(0, -2) + `0${firstChar}${this.separator}`;
        }

        if (parseInt(firstChar, 10) === 0 && secondChar === this.separator) {
            return value.slice(0, -1);
        }

        return `${value}${this.separator}`;
    }

    /**
     * formatSixthChar function
     * @param value string
     */
    private formatSixthChar(value: string): string {
        return this.validateLastDash(value);
    }

    /**
     * formatEighthChar function - formats if:
     * - first two are 18, 19 or 20
     * - first two are smaller than 18 and greather or equal to 10
     * - first two are smaller than 10
     * - first two are greather than 20
     * @param value value string
     */
    private formatEighthChar(value: string): string {
        const firstTwo = parseInt(value.substr(6, 2), 10);

        if ([18, 19, 20].includes(firstTwo)) {
            return value;
        }

        if (firstTwo < 18 && firstTwo >= 10) {
            return value.slice(0, -2) + `20${firstTwo}`;
        }

        if (firstTwo < 10) {
            return value.slice(0, -2) + `200${firstTwo}`;
        }

        if (firstTwo > 20) {
            return value.slice(0, -2) + `19${firstTwo}`;
        }

        return value;
    }

    /**
     * formatLastChar function - formats last char
     * @param value string
     */
    private formatLastChar(value: string): string {
        return value.slice(0, -1);
    }
}
