import { Component, Input } from '@angular/core';

@Component({
    selector: 'ins-modal-actions',
    templateUrl: 'modal-actions.component.html',
    styleUrls: ['modal-actions.component.scss'],
})
export class ModalActionsComponent {
    @Input() public alignment?: 'start' | 'center' | 'end' | 'between' | 'around' | 'evenly' = 'between';
}
