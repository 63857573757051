import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ClickTooltipComponent } from './click-tooltip.component';
import { ClickOutsideModule } from '@app-de/shared/directives/click-outside/click-outside.module';

@NgModule({
    imports: [
        CommonModule,
        ClickOutsideModule,
    ],
    declarations: [ClickTooltipComponent],
    exports: [ClickTooltipComponent],
})
export class ClickTooltipModule {}
