import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CancelInsuranceModal } from './cancel-insurance.modal';
import { ModalComponentsModule } from '@app-de/components';

@NgModule({
    imports: [CommonModule, ModalComponentsModule],
    exports: [CancelInsuranceModal],
    declarations: [CancelInsuranceModal],
})
export class CancelInsuranceModule {}
