import { Routes } from '@angular/router';
import { AccountRecoveryModal } from '@app-de/account-recovery/account-recovery-modal/account-recovery.modal';
import { CompleteGeneratedAccountModal } from '@app-de/account-recovery/complete-generated-account-modal';
import { ForgotEmailModal } from '@app-de/account-recovery/forgot-email-modal';
import { ForgotPasswordModal } from '@app-de/account-recovery/forgot-password-modal';
import { SendPasswordModal } from '@app-de/account-recovery/send-password-modal/send-password.modal';
import { TemporaryPasswordModal } from '@app-de/account-recovery/temporary-password-modal';
import { UnblockAccountModal } from '@app-de/account-recovery/unblock-account-modal';
import { ValidateGeneratedAccountModal } from '@app-de/account-recovery/validate-generated-account-modal';
import { RoutedModal } from '@app-de/components';
import { AccountRecoveryModalRoutesEnum } from '@app-de/routes';

const MODAL_OUTLET = 'modal';

export const accountRecoveryRoutes: Routes = [
    {
        path: AccountRecoveryModalRoutesEnum.AccountRecovery,
        component: RoutedModal,
        data: { component: AccountRecoveryModal },
        outlet: MODAL_OUTLET,
    }, {
        path: AccountRecoveryModalRoutesEnum.CompleteGeneratedAccount,
        component: RoutedModal,
        data: { component: CompleteGeneratedAccountModal },
        outlet: MODAL_OUTLET,
    }, {
        path: AccountRecoveryModalRoutesEnum.ForgotEmail,
        component: RoutedModal,
        data: { component: ForgotEmailModal },
        outlet: MODAL_OUTLET,
    }, {
        path: AccountRecoveryModalRoutesEnum.ForgotPassword,
        component: RoutedModal,
        data: { component: ForgotPasswordModal },
        outlet: MODAL_OUTLET,
    }, {
        path: AccountRecoveryModalRoutesEnum.SendPassword,
        component: RoutedModal,
        data: { component: SendPasswordModal },
        outlet: MODAL_OUTLET,
    }, {
        path: AccountRecoveryModalRoutesEnum.TemporaryPassword,
        component: RoutedModal,
        data: { component: TemporaryPasswordModal },
        outlet: MODAL_OUTLET,
    }, {
        path: AccountRecoveryModalRoutesEnum.UnblockAccount,
        component: RoutedModal,
        data: { component: UnblockAccountModal },
        outlet: MODAL_OUTLET,
    }, {
        path: AccountRecoveryModalRoutesEnum.ValidateGeneratedAccount,
        component: RoutedModal,
        data: { component: ValidateGeneratedAccountModal },
        outlet: MODAL_OUTLET,
    },
];
