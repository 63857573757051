import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CreateResidentialAddressInterface, DateService, ErrorInterface } from 'outshared-lib';
import { DetailsSectionEnum } from '@app-de/core/enums/detail-section.enum';
import { MyZoneRoutes } from '@app-de/my-zone/routing/my-zone-routing.routes';
import { AppRoutesEnum } from '@app-de/routes';

@Component({
    selector: 'ins-correspondence-address-form',
    templateUrl: 'correspondence-address-form.component.html',
    styleUrls: ['correspondence-address-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CorrespondenceAddressFormComponent implements OnInit {
    @Input() public errors!: ErrorInterface[];
    @Input() public loading!: boolean;

    @Output() public readonly submitted: EventEmitter<CreateResidentialAddressInterface> =
        new EventEmitter<CreateResidentialAddressInterface>();

    public form: UntypedFormGroup;
    public minDate: string;

    constructor(private dateService: DateService, private router: Router) {}

    public ngOnInit(): void {
        this.form = this.createForm();
        this.minDate = this.dateService.add(1, 'day');
    }

    public get address(): AbstractControl {
        return this.form.get('address');
    }

    public get startDate(): AbstractControl {
        return this.form.get('startDate');
    }

    public onSubmit(): void {
        if (!this.form.valid) {
            this.form.markAllAsTouched();
            return;
        }

        this.submitted.emit({
            internationalAddressId: this.address.value.address_id,
            startDate: this.startDate.value,
        });
    }

    public goToResidentialAddress(event: Event): void {
        event.preventDefault();
        this.router.navigate([AppRoutesEnum.MyZone, MyZoneRoutes.Details], {
            queryParams: {
                section: DetailsSectionEnum.AddressPanel,
                scroll: true,
                expand: false,
            },
        });
    }

    private createForm(): UntypedFormGroup {
        return new UntypedFormGroup({
            address: new UntypedFormControl('', Validators.required),
            startDate: new UntypedFormControl('', Validators.required),
        });
    }
}
