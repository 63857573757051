<div class="sidemenu" e2e="sidemenu">
    <ins-nav-sidebar>
        <li routerLinkActive="active">
            <a [routerLink]="myZoneRoutes.MyInsurances" e2e="versicherungen">Meine Versicherungen</a>
        </li>
        <li routerLinkActive="active">
            <a [routerLink]="myZoneRoutes.Details" e2e="personal_data">Meine Angaben</a>
        </li>
        <li routerLinkActive="active">
            <a [routerLink]="myZoneRoutes.TermsAndConditions" e2e="documents">Meine Dokumente</a>
        </li>
        <li routerLinkActive="active">
            <a [routerLink]="myZoneRoutes.Invoices" e2e="invoices">Meine Rechnungen</a>
        </li>
        <li routerLinkActive="active">
            <a [routerLink]="myZoneRoutes.AnnualReward" e2e="annual_reward">Jahresbelohnung</a>
        </li>
    </ins-nav-sidebar>
</div>
