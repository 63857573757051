<section class="section">
    <div class="container">
        <div class="wrapper">
            <div class="col-8">
                <h1>Die Versicherungen von InShared</h1>
                <p class="intro-text">Haftpflicht, Teil- oder Vollkasko, Fahrerschutz und Pannenhilfe: Bei InShared können Sie Ihr Auto ganz einfach und günstig online versichern. Wir sind immer für Sie da, auch im Schadensfall (von dem wir hoffen, dass er nicht eintritt).</p>
            </div>
        </div>
    </div>
</section>

<section class="section section--content section--border section--vertical-spacing">
    <div class="container">
        <div class="wrapper insurances__wrapper">
            <div class="col-7">
                <ins-chatbot-entry-container [hideMobile]="true"></ins-chatbot-entry-container>
            </div>
            <div class="col-5">
                <div class="btn-group btn-group--spread">
                    <a class="btn btn--primary" [routerLink]="'/'+  appRoutesEnum.CarInsurance + '/'+ salesfunnelRoutesEnum.CalculateCarInsurance" title="Beitrag berechnen">Beitrag berechnen</a>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="section section--content">
    <div class="container">
        <div class="wrapper">
            <div class="col-12">
                <h2 class="h1">Verkehr</h2>
                <ins-product-card [product]="{ type: 'auto', name: 'Auto&shy;versicherung' }">
                    <div product-card-content>
                        <p>Suchen Sie eine zuverlässige und günstige <a routerLink="/autoversicherung" title="Autoversicherung">Autoversicherung</a>? Dann sind Sie bei uns genau richtig! Mit einer <a routerLink="/autoversicherung/kfz-haftpflichtversicherung">Kfz-Haftpflicht</a>, <a routerLink="/autoversicherung/teilkasko">Teilkasko</a> oder <a routerLink="/autoversicherung/vollkasko">Vollkasko</a> von InShared haben Sie Ihr Auto top versichert – egal ob Sie einen Verbrenner oder ein <wuc-link  [route]="'/' + appRoutesEnum.CarInsurance +'/' + autoversicherungRoutesEnum.EAutoversicherung">E-Auto versichern</wuc-link> möchten.</p>
                        <p>Wir halten unsere Kosten (und somit unsere Beiträge) so niedrig wie möglich, damit Sie Geld sparen. Für noch mehr Sicherheit können Sie zusätzliche Deckungen wie <wuc-link [route]="'/' + appRoutesEnum.CarInsurance + '/' +  autoversicherungRoutesEnum.Fahrerschutz">Fahrerschutz</wuc-link> und <wuc-link [route]="'/' + appRoutesEnum.CarInsurance + '/' +  autoversicherungRoutesEnum.Pannenhilfe">Pannenhilfe</wuc-link> abschließen. So haben Sie die Gewissheit, dass auch Sie als Fahrer gut versichert sind oder bei einer Panne nicht im Regen stehen.</p>
                        <div class="btn-group btn-group--spread">
                            <a class="btn btn--primary" routerLink="/autoversicherung/autoversicherung-berechnen" title="Beitrag berechnen">Beitrag berechnen</a>
                            <a routerLink="/autoversicherung" class="product-card__link-more link-secondary link-secondary--more" title="Weitere Informationen"><svg class="svg-icon svg-icon--xs"><use href="assets/icons-ui.svg#rechts-open"></use></svg>Weitere Informationen</a>
                        </div>
                    </div>
                    <div product-card-additional>
                        <ins-card [borders]="true">
                            <ins-card-content>
                                <h2 class="h3">Noch mehr Sicherheit unterwegs:</h2>
                                <ins-list-flat [lined]="true">
                                    <ins-list-flat-item>
                                        <a routerLink="/autoversicherung/fahrerschutz" class="link-flat link-flat--readmore" title="Fahrerschutz">
                                            <h3 class="insurances__sicherheit--link-text">Fahrerschutz</h3><svg class="svg-icon svg-icon--xs">
                                                <use href="assets/icons-ui.svg#rechts-open"></use>
                                            </svg>
                                        </a>
                                    </ins-list-flat-item>
                                    <ins-list-flat-item>
                                        <a routerLink="/autoversicherung/pannenhilfe" class="link-flat link-flat--readmore" title="Pannenhilfe">
                                            <h3 class="insurances__sicherheit--link-text">Pannenhilfe</h3><svg class="svg-icon svg-icon--xs">
                                                <use href="assets/icons-ui.svg#rechts-open"></use>
                                            </svg>
                                        </a>
                                    </ins-list-flat-item>
                                </ins-list-flat>
                            </ins-card-content>
                        </ins-card>
                    </div>
                </ins-product-card>
            </div>
        </div>
    </div>
</section>
