import { DOCUMENT, isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class FeatureDetectionService {
    public renderer: Renderer2;

    constructor(
        @Inject(DOCUMENT) private document: Document,
        @Inject(PLATFORM_ID) private platformId: any,
        rendererFactory: RendererFactory2
    ) {
        this.renderer = rendererFactory.createRenderer(null, null);
    }

    public init(): void {
        if (isPlatformServer(this.platformId)) {
            return;
        }
        this.testWebP()
            .then((hasWebP: boolean) => {
                if (hasWebP) {
                    this.renderer.addClass(this.document.body, 'image-webp');
                } else {
                    this.renderer.addClass(this.document.body, 'image-no-webp');
                }
            })
            .catch(() => {
                this.renderer.addClass(this.document.body, 'image-no-webp');
            });
    }

    /* ignore from coverage calculations because we (Carl R. en Martijn D.) could not get the test to pass */
    /* istanbul ignore next */
    private testWebP(): Promise<boolean> {
        return new Promise((resolve) => {
            const webP = new Image();
            webP.src =
                // eslint-disable-next-line max-len
                'data:image/webp;base64,UklGRjoAAABXRUJQVlA4IC4AAACyAgCdASoCAAIALmk0mk0iIiIiIgBoSygABc6WWgAA/veff/0PP8bA//LwYAAA';
            webP.onload = webP.onerror = (): any => {
                resolve(webP.height === 2);
            };
        });
    }
}
