import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { SharedModule } from '../../shared/shared.module';
import { AutopanneVermeidenSummaryComponent } from './autopanne-vermeiden-summary.component';
import { CardModule } from '@app-de/shared/card/card.module';

@NgModule({
    declarations: [AutopanneVermeidenSummaryComponent],
    imports: [CommonModule, RouterModule, CardModule, LazyLoadImageModule, SharedModule],
    exports: [AutopanneVermeidenSummaryComponent],
})
export class AutopanneVermeidenSummaryModule {}
