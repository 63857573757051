import { Injectable } from '@angular/core';
import { CoveragePremiumInterface, CoveragePremiumCoverageInterface } from 'outshared-lib';
import { AdditionalCoveragePrices } from './change-coverage.interface';
import { CoverageIdEnum } from '@app-de/core/enums';
import { AnnualPaymentService } from '@app-de/my-zone/services/annual-payment.service';

@Injectable({
    providedIn: 'root',
})
export class ChangeCoverageService {
    constructor(private annualPaymentService: AnnualPaymentService) {}

    public calculateNewMonthlyPayment(
        calculation: CoveragePremiumInterface,
        additionalCoveragePrices: AdditionalCoveragePrices,
        baseCoverage: CoverageIdEnum,
        driverProtection: boolean,
        roadsideAssistance: boolean,
    ): { newMonthlyPayment: number; baseCoveragePrice: number } {
        const currentPeriod = this.annualPaymentService.getCurrentPeriod(calculation?.product);

        let totalPrice =
            currentPeriod.base_coverages.find((coverage) => coverage.coverage_id === CoverageIdEnum.KfzHaftpflicht)
                .premium_including_discount.premium_including_premium_tax || 0;

        // The price is calculated as base KFZ module + selected package (if selected package is not KFZ already)
        if (baseCoverage !== CoverageIdEnum.KfzHaftpflicht) {
            totalPrice += currentPeriod.base_coverages.find((coverage) => coverage.coverage_id === baseCoverage)
                .premium_including_discount.premium_including_premium_tax;
        }

        const baseCoveragePrice = totalPrice;

        // Add additional selected (optional) coverages
        if (driverProtection) {
            totalPrice += additionalCoveragePrices.driverProtection;
        }
        if (roadsideAssistance) {
            totalPrice += additionalCoveragePrices.roadsideAssistance;
        }

        return { newMonthlyPayment: Math.round((totalPrice + Number.EPSILON) * 100) / 100, baseCoveragePrice };
    }

    public calculatePrice(baseCoverages: CoveragePremiumCoverageInterface[], premiumIncludingTax: number): number {
        const basePrice = baseCoverages.find((coverage) => coverage.coverage_id === CoverageIdEnum.KfzHaftpflicht);

        return (
            Math.round(
                (basePrice.premium_including_discount.premium_including_premium_tax +
                    premiumIncludingTax +
                    Number.EPSILON) *
                    100,
            ) / 100
        );
    }
}
