<div class="insurance-detail__coverage">
    <ins-coverage-detail-container [hasCancelButton]="true"></ins-coverage-detail-container>
</div>

<ins-panel-body class="insurance-detail" *ngIf="product">
    <div class="insurance-detail__data">
        <ins-policy-details-container />
        <ins-insurance-data-container />
        <ins-insurance-regular-driver-container />
        <ins-insurance-additional-driver />
        <ins-premium-determining-factors-container />
        <!-- TODO: [WIDL-415] My zone - direct control -->
        <ng-container *ngIf="hasInternationalMotorInsuranceCard(product)">
            <hr />
            <h3>Grüne Karte</h3>
            <ins-international-motor-insurance-card [product]="product" />
        </ng-container>
    </div>
</ins-panel-body>
