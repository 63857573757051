<form [formGroup]="form" (ngSubmit)="onSubmit()">
    <ins-address-autocomplete-field [control]="address">
        <ins-address-autocomplete-label for="address">Adresse</ins-address-autocomplete-label>
        <ins-address-autocomplete-input>
            <ins-address-autocomplete-container
                id="address"
                formControlName="address"
            ></ins-address-autocomplete-container>
            <cyno-error *ngIf="address.touched && address.hasError('required')">
                Hoppla, diese Adresse ist nicht vollständig.
            </cyno-error>
        </ins-address-autocomplete-input>
    </ins-address-autocomplete-field>
    <cyno-field>
        <cyno-label for="startDate" e2e="start-date-residential-address">Datum</cyno-label>
                <cyno-info>
                    <wuc-balloon arrowPosition="topLeft">
                        Ein Datum in der Vergangenheit ist nicht möglich. Falls sich Ihre
                        Wohnadresse bereits geändert hat, wählen Sie bitte das Datum von morgen.
                    </wuc-balloon>
                </cyno-info>
        <ins-datepicker formControlName="startDate" id="startDate" separator="." [minDate]="minDate"></ins-datepicker>
    </cyno-field>
    <cyno-alert *ngIf="errors" [notifications]="errors" type="error" />
    <ins-details-submit-button [disabled]="loading">Speichern</ins-details-submit-button>
</form>
