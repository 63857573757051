import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RelationDataService, RelationInterface } from 'outshared-lib';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { filter, first, map, skipWhile, tap } from 'rxjs/operators';
import { DetailsSectionEnum } from '@app-de/core/enums/detail-section.enum';
import { DetailsComponent } from '@app-de/my-zone/pages/details/details.component';
import { MyZoneRoutes } from '@app-de/my-zone/routing/my-zone-routing.routes';
import { AppRoutesEnum } from '@app-de/routes';
import { LayoutService } from '@app-de/store/layout/layout.service';

@Component({
    selector: 'ins-details-container',
    template: ` <ins-details [relation]="relation$ | async"></ins-details> `,
})
export class DetailsContainer implements OnInit, OnDestroy, AfterViewInit {
    public relation$: Observable<RelationInterface>;
    private subscriptions = new Subscription();
    @ViewChild(DetailsComponent) private detailsComponent: DetailsComponent;
    private queryParams$: Observable<{ section: DetailsSectionEnum; expand: boolean; scroll?: boolean }> =
        this.route.queryParams.pipe(
            filter((params) => Boolean(params)),
            tap((params) => {
                if (!params.hasOwnProperty('expand')) {
                    this.detailsComponent.closeAllPanels();
                }
            }),
            filter((params) => params.hasOwnProperty('section')),
            map((params) => ({
                section: params.section,
                expand: Boolean(params?.expand?.toLowerCase() === 'true'),
                scroll: params.scroll,
            })),
        );

    constructor(
        private route: ActivatedRoute,
        private layoutService: LayoutService,
        private relationDataService: RelationDataService,
    ) {}

    public ngOnInit(): void {
        this.relation$ = this.relationDataService.getRelation$();
        this.layoutService.setBreadcrumbs([
            { title: 'Home', href: '/' },
            { title: 'Meine Versicherungsmappe', routerLink: [AppRoutesEnum.MyZone, MyZoneRoutes.MyInsurances] },
            { title: 'Meine Angaben' },
        ]);
    }

    public ngAfterViewInit(): void {
        const queryParamsSubscription = combineLatest([
            this.queryParams$,
            this.relation$.pipe(
                filter((relation) => !!relation),
                first(),
            ),
        ])
            .pipe(skipWhile((params) => !!!params[1]))
            .subscribe((params) => this.detailsComponent.handleQueryParams(params[0]));

        this.subscriptions.add(queryParamsSubscription);
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
