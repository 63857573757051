import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ErrorInterface, ValidationDataService } from 'outshared-lib';
import { Observable } from 'rxjs';

@Component({
    selector: 'ins-element-validate-errors-container',
    template: `
        <ins-element-validate-errors [errors]="errors$ | async" [allowHtml]="allowHtml"></ins-element-validate-errors>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class ElementValidateErrorsContainer implements OnInit {
    @Input() public name: string;
    @Input() public allowHtml: boolean = false;

    public errors$: Observable<ErrorInterface[]>;

    constructor(private validationDataService: ValidationDataService) {}

    public ngOnInit(): void {
        this.errors$ = this.validationDataService.getErrors$(this.name);
    }
}
