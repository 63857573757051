import { PolicyProductIdEnum } from 'outshared-lib';

export const genericProductName: { [key in PolicyProductIdEnum]: string } = {
    [PolicyProductIdEnum.Liability]: $localize`:@@generic.product-name.liability:Liability`,
    [PolicyProductIdEnum.Car]: $localize`:@@generic.product-name.car:Car`,
    [PolicyProductIdEnum.Moped]: $localize`:@@generic.product-name.moped:Moped`,
    [PolicyProductIdEnum.Scooter]: $localize`:@@generic.product-name.scooter:Scooter`,
    [PolicyProductIdEnum.Caravan]: $localize`:@@generic.product-name.caravan:Caravan`,
    [PolicyProductIdEnum.Bicycle]: $localize`:@@generic.product-name.bicycle:Bicycle`,
    [PolicyProductIdEnum.Pet]: $localize`:@@generic.product-name.pet:Pet`,
    [PolicyProductIdEnum.Motorcycle]: $localize`:@@generic.product-name.motorcycle:Motorcycle`,
    [PolicyProductIdEnum.Accidents]: $localize`:@@generic.product-name.accidents:Accidents`,
    [PolicyProductIdEnum.LegalAssistance]: $localize`:@@generic.product-name.legalassistance:LegalAssistance`,
    [PolicyProductIdEnum.Travel]: $localize`:@@generic.product-name.travel:Travel`,
    [PolicyProductIdEnum.Home]: $localize`:@@generic.product-name.home:Home`,
    [PolicyProductIdEnum.Furniture]: $localize`:@@generic.product-name.furniture:Furniture`,
    [PolicyProductIdEnum.Building]: $localize`:@@generic.product-name.building:Building`,
};
