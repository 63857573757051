<ins-details-table [display]="detailsTableDisplayEnum.Future">
    <ins-details-delete-button
        title="Löschen"
        e2e="familiy-composition-delete"
        (click)="onCancelFutureFamilyComposition($event)"
        [isDisabled]="loading"
    >
        Löschen
    </ins-details-delete-button>
    <ins-alert *ngIf="errors.length > 0" [errors]="errors" type="validation"></ins-alert>
    <tr>
        <td>Zukünftige Familienzusammensetzung</td>
        <td e2e="future-familiy-composition">
            {{ relation.family_composition_future.family_composition_description }}
        </td>
    </tr>
    <tr>
        <td>Beginndatum</td>
        <td e2e="future-familiy-composition-date">{{ relation.family_composition_future.start_date | dateFormat }}</td>
    </tr>
</ins-details-table>
