import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {
    WucCompareTableCoverageDataColumnInterface,
    WucCompareTableCoverageDataInterface,
    WucCompareTableCoverageDataExtraInfoInterface,
} from '@inshared/website-ui-components';
import { NewModal, NotificationInterface, ProductBaseInterface } from 'outshared-lib';
import { Subscription } from 'rxjs';
import { YearRewardModal } from '../../year-reward-modal/year-reward.modal';
import { SelectedBaseCoverage } from '../coverage-detail.model';
import {
    AdditionalCoveragePrices,
    AdditionalCoverages,
    ChangeCoverageValueChanges,
    Purchase,
} from './change-coverage.interface';
import { CoverageIdEnum } from '@app-de/core/enums';
import { ScrollService } from '@app-de/core/services/scroll.service';
import { CompareCoverages } from '@app-de/shared/interfaces/compare-coverages';
import { AdditionalCoverageInfoModal } from '@app-de/shared/modals/coverage-modals/additional-coverage-info.modal';
import { BaseCoverageInfoModal } from '@app-de/shared/modals/coverage-modals/base-coverage-info.modal';
import { NoCoverageModal } from '@app-de/shared/modals/coverage-modals/no-coverage.modal';

@Component({
    selector: 'ins-change-coverage',
    templateUrl: './change-coverage.component.html',
    styleUrls: ['./change-coverage.component.scss'],
})
export class ChangeCoverageComponent implements OnInit, OnDestroy, OnChanges {
    public selectedColumnKey: string = '';
    public data: WucCompareTableCoverageDataInterface = {
        labels: [],
        columns: [],
        extraInfo: null,
    };

    public get startDate(): AbstractControl {
        return this.form.get('startDate');
    }

    public get roadsideAssistance(): AbstractControl {
        return this.form.get('roadsideAssistance');
    }

    public get driverProtection(): AbstractControl {
        return this.form.get('driverProtection');
    }

    public get baseCoverage(): AbstractControl {
        return this.form.get('baseCoverage');
    }

    @Input() public isScreenSmall: boolean = false;
    @Input() public hasCancelButton: boolean;
    @Input() public product: ProductBaseInterface;
    @Input() public notifications: NotificationInterface[] = [];
    @Input() public compareTableColumns: WucCompareTableCoverageDataColumnInterface[] = [];
    @Input() public compareTableCoverages: CompareCoverages;
    @Input() public additionalCoveragePrices: AdditionalCoveragePrices;
    @Input() public additionalCoverages: AdditionalCoverages;
    @Input() public payment: number;
    @Input() public currentlyPurchasedCoverage: CoverageIdEnum;
    @Input() public purchasedPeriod: number;
    @Input() public isSelectedCoveragePanelOpen: boolean;
    @Input() public selectedBaseCoverage: SelectedBaseCoverage = { price: 0, coverageName: '' };
    @Input() public extraInfo: WucCompareTableCoverageDataExtraInfoInterface;

    @Output() public readonly exit: EventEmitter<void> = new EventEmitter();
    @Output() public readonly purchase: EventEmitter<Purchase> = new EventEmitter();
    @Output() public readonly formValueChanges: EventEmitter<ChangeCoverageValueChanges> = new EventEmitter();
    @Output() public readonly toggleSelectedCoveragePanel: EventEmitter<boolean> = new EventEmitter();

    public form: UntypedFormGroup;
    public coverageIdEnum = CoverageIdEnum;
    public isLoading: boolean = false;

    private subscriptions = new Subscription();

    constructor(private scrollService: ScrollService, private modalService: NewModal) {}

    public ngOnInit(): void {
        // Initialize form
        this.form = new UntypedFormGroup({
            startDate: new UntypedFormControl('', [Validators.required]),
            driverProtection: new UntypedFormControl(this.additionalCoverages.driverProtection),
            roadsideAssistance: new UntypedFormControl(this.additionalCoverages.roadsideAssistance),
            baseCoverage: new UntypedFormControl(this.currentlyPurchasedCoverage),
        });

        // Fill compareTable data
        const priceLabel = this.purchasedPeriod === 12 ? 'Jahresbeitrag' : 'Monatsbeitrag';
        this.data.labels = [priceLabel, ...(this.compareTableCoverages?.labels || [''])];
        this.data.columns = this.compareTableColumns;
        this.data.extraInfo = this.extraInfo;

        // Set selected column key
        this.selectedColumnKey = this.currentlyPurchasedCoverage;
        this.updateData();

        // Emit on all form changes
        this.subscriptions.add(
            this.form.valueChanges.subscribe((valueChanges) => {
                this.emitFormValueChanges(valueChanges);
            }),
        );
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.selectedBaseCoverage) {
            this.updateData();
        }
    }

    public onSelectColumnKey(columnKey: string): void {
        if (!columnKey) {
            return;
        }

        const selectedColumn = this.data?.columns.find((column) => column.key === columnKey);

        if (selectedColumn?.disabled) {
            this.openNoCoverageModal();
            return;
        }

        this.selectedColumnKey = columnKey;
        this.baseCoverage.setValue(columnKey);
    }

    public openModal(coverageId: string, coverageTitle: string): boolean {
        this.modalService.open<BaseCoverageInfoModal>(BaseCoverageInfoModal, {
            data: {
                coverage_id: coverageId,
                coverage_title: coverageTitle,
            },
        });

        return false;
    }

    public openAdditionalCoverageModal(coverageId: string): void {
        this.modalService.open<AdditionalCoverageInfoModal>(AdditionalCoverageInfoModal, {
            data: {
                coverage_id: coverageId,
                coverage_title: coverageId,
            },
        });
    }

    public onSubmit(): void {
        if (!this.form.valid) {
            this.form.markAllAsTouched();
            return;
        }

        this.isLoading = true;

        const { purchased_product_sequence_number } = this.product;
        const startDate = this.startDate.value;

        this.purchase.emit({
            purchasedProductSequenceNumber: purchased_product_sequence_number,
            startDate,
            baseCoverage: this.baseCoverage.value,
            roadsideAssistance: this.roadsideAssistance.value,
            driverProtection: this.driverProtection.value,
            scrollId: this.product.purchased_product_sequence_number.toString(),
        });
    }

    public setCoverage(columnIndex: number): void {
        if (columnIndex === -1) {
            return;
        }

        this.baseCoverage.setValue(columnIndex);
    }

    public onClose(): void {
        this.scrollService.scrollElementOffset(`#product_${this.product.purchased_product_sequence_number}`);
        this.exit.emit();
    }

    public getInfo(): boolean {
        this.modalService.open(YearRewardModal);
        return false;
    }

    public getButtonText(coverageId: string, isDisabled: boolean): string {
        if (isDisabled) {
            return 'n. a.';
        }

        return coverageId === this.selectedColumnKey ? 'Gewählt' : 'Wählen';
    }

    public isActive(coverageId: string): boolean {
        return this.selectedColumnKey === coverageId;
    }

    public onSelectedCoverageToggle(): void {
        this.toggleSelectedCoveragePanel.emit(!this.isSelectedCoveragePanelOpen);
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    private emitFormValueChanges(formValues): void {
        this.formValueChanges.emit({
            baseCoverage: formValues.baseCoverage,
            roadsideAssistance: formValues.roadsideAssistance,
            driverProtection: formValues.driverProtection,
        });
    }

    private openNoCoverageModal(): void {
        this.modalService.open<NoCoverageModal>(NoCoverageModal);
    }

    private updateData(): void {
        this.data?.columns?.forEach((column) => {
            column.actions[0].isPrimary = this.isActive(column.key);
            column.actions[0].label = this.getButtonText(column.key, column.actions[0].isDisabled);
        });
    }
}
