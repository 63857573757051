import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {
    AccountRecoveryActionService,
    ErrorInterface,
    LoginRequestInterface,
    NewModalRef,
    NEW_MODAL_DATA,
    UnblockAccountRequestInterface,
} from 'outshared-lib';

interface ModalData {
    credentials: LoginRequestInterface;
}

@Component({
    selector: 'ins-unblock-account-modal',
    templateUrl: './unblock-account.modal.html',
})
export class UnblockAccountModal implements OnInit {
    public submitting: boolean = false;
    public success: boolean = false;
    public errors: ErrorInterface[] = [];
    public notifications: ErrorInterface[] = []; // TODO : Add in outshared-lib
    public form: UntypedFormGroup;

    public get birthdate(): AbstractControl {
        return this.form.get('birthdate');
    }

    public get email(): AbstractControl {
        return this.form.get('email');
    }

    constructor(
        private actionService: AccountRecoveryActionService,
        private modalRef: NewModalRef<UnblockAccountModal>,
        @Inject(NEW_MODAL_DATA) public data: ModalData,
    ) {}

    public ngOnInit(): void {
        this.form = new UntypedFormGroup({
            birthdate: new UntypedFormControl('', [Validators.required]),
            email: new UntypedFormControl(this.data.credentials.email, [Validators.email, Validators.required]),
        });
    }

    public onSubmit(): void {
        if (!this.form.valid) {
            this.form.markAllAsTouched();
            return;
        }
        this.submitting = true;
        this.errors = [];
        const request: UnblockAccountRequestInterface = {
            birthdate: this.birthdate.value,
            email: this.email.value,
        };
        this.actionService.unblockAccount$(request).subscribe(
            (notifications: ErrorInterface[]) => {
                this.success = true;
                this.submitting = false;
                this.notifications = notifications;
            },
            (errors: ErrorInterface[]) => {
                this.errors = errors;
                this.submitting = false;
            },
        );
    }

    public onCloseAfterSuccess(): void {
        this.modalRef.close(true);
    }
}
