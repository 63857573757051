<ins-details-table>
    <tr *ngIf="!relation.residential_address.address">
        <td colspan="3"><u>Sie haben keine aktuelle Wohnadresse.</u></td>
    </tr>
    <tr *ngIf="relation.residential_address.address">
        <td>Wohnadresse</td>
        <td>
            <span>{{ relation.residential_address.address.street_name }}</span>
            <span>
                {{ relation.residential_address.civic_number }}{{ relation.residential_address.civic_number_suffix }},
            </span>
            <!-- prettier-ignore -->
            <span>{{ relation.residential_address.postal_code }} </span>
            <span>{{ relation.residential_address.address.city }}</span>
        </td>
    </tr>
</ins-details-table>
