import { DOCUMENT } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, Input, OnDestroy } from '@angular/core';
import {
    ErrorInterface,
    InternationalMotorInsuranceCardActionService,
    InternationalMotorInsuranceCardDataService,
    NewModal,
    NewModalRef,
    PdfInternationalMotorInsuranceCardInterface,
    ProductBaseInterface,
    ProductIdEnum,
} from 'outshared-lib';
import { Subscription } from 'rxjs';
import { exhaustMap, filter, take } from 'rxjs/operators';
import { DatesEnum } from '@app-de/core/enums/dates.enum';
import { ConfirmationModal } from '@app-de/shared/modals/confirmation-modal/confirmation.modal';
import { DownloadingModal } from '@app-de/shared/modals/downloading-modal/downloading.modal';
import { PdfDownloadUtilities } from '@app-de/shared/utilities/pdf-download-utilities';

@Component({
    selector: 'ins-international-motor-insurance-card-buttons',
    templateUrl: './international-motor-insurance-card-buttons.component.html',
    styleUrls: ['./international-motor-insurance-card-buttons.component.scss'],
})
export class InternationalMotorInsuranceCardButtonsComponent implements OnDestroy {
    @Input() public product: ProductBaseInterface;
    @Input() public showTitle: boolean = true;
    @Input() public showContent: boolean = true;
    @Input() public showModalResponse: boolean;
    @Input() public padding?: boolean;

    public productIdEnum: typeof ProductIdEnum = ProductIdEnum;
    public notifications: string[] = [];
    public errors: (ErrorInterface | string)[] = [];
    public loading: boolean;

    private createDocumentModal: NewModalRef<DownloadingModal>;

    private subscriptions: Subscription = new Subscription();

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private internationalMotorInsuranceCardActionService: InternationalMotorInsuranceCardActionService,
        private internationalMotorInsuranceCardDataService: InternationalMotorInsuranceCardDataService,
        private newModal: NewModal,
        @Inject(DOCUMENT) private document: Document,
    ) {}

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    public downloadInternationalMotorCard(): void {
        if (this.product.start_date === DatesEnum.NoDate) {
            return;
        }

        this.loading = true;
        this.resetState();

        const downloadSubscription = this.internationalMotorInsuranceCardActionService
            .loadPdfInternationalMotorInsuranceCard$(this.product.purchased_product_sequence_number)
            .pipe(
                exhaustMap(() => this.internationalMotorInsuranceCardDataService.getPdf$().pipe(take(1))),
                filter((pdf) => !!pdf),
            )
            .subscribe(
                (pdf: PdfInternationalMotorInsuranceCardInterface) => this.onPdfReceived(pdf),
                (error) => this.errors.push(error),
            );

        this.subscriptions.add(downloadSubscription);

        this.createDocumentModal = this.newModal.open(DownloadingModal);
    }

    public emailInternationalMotorInsuranceCard(): void {
        if (this.product.start_date === DatesEnum.NoDate) {
            return;
        }

        this.loading = true;
        this.resetState();

        const emailSubscription = this.internationalMotorInsuranceCardActionService
            .loadEmailInternationalMotorInsuranceCard$(this.product.purchased_product_sequence_number)
            .pipe(filter((mail) => !!mail))
            .subscribe(
                (result: string) => {
                    this.afterMailSend(result);
                    this.createDocumentModal.close();
                    this.loading = false;
                    this.changeDetectorRef.detectChanges();
                },
                (error) => {
                    this.errors.push(error);
                },
            );

        this.subscriptions.add(emailSubscription);

        this.createDocumentModal = this.newModal.open(DownloadingModal);
    }

    private onPdfReceived(result: PdfInternationalMotorInsuranceCardInterface): void {
        PdfDownloadUtilities.DownloadDocument(result.pdf, result.identifyingName, this.document);
        this.createDocumentModal.close();
        this.loading = false;
        this.changeDetectorRef.detectChanges();
    }

    private afterMailSend(result: string): void {
        if (!result) {
            this.errors.push('Da ist etwas schiefgegangen. Bitte probieren Sie es später noch einmal.');
            return;
        }

        if (this.showModalResponse) {
            this.newModal.open<ConfirmationModal>(ConfirmationModal, {
                data: {
                    title: 'Mail erfolgreich versendet',
                    body: 'Die Grüne Karte wurde Ihnen zugemailt.',
                    confirm: 'OK',
                    cancel: null,
                },
            });
            return;
        }

        this.notifications.push('Die Grüne Karte wurde Ihnen zugemailt.');
        this.changeDetectorRef.detectChanges();
        return;
    }

    private resetState(): void {
        this.notifications = [];
        this.errors = [];
    }
}
