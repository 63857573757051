<wuc-rack class="details">
    <h2>Meine Angaben</h2>
    <!--Personal Details Section-->
    <wuc-page-block
        class="details__page-block"
        blockType="open"
        icon="personal-data"
        [attr.id]="detailsSectionEnum.PersonalDetailsForm"
    >
        <ng-container slot="header">Persönliche Angaben</ng-container>
        <ins-personal-details-data *ngIf="relation" [relation]="relation"></ins-personal-details-data>
        <ins-details-toggle-button
            *ngIf="relation"
            [expand]="panelPersonalDetails.visible"
            [section]="detailsSectionEnum.PersonalDetailsForm"
        ></ins-details-toggle-button>
        <ins-panel-expandable #panelPersonalDetails [id]="detailsSectionEnum.PersonalDetailsForm">
            <ins-panel-body *ngIf="panelPersonalDetails.visible">
                <ins-personal-details-form-container></ins-personal-details-form-container>
            </ins-panel-body>
        </ins-panel-expandable>
        <wuc-skeleton *ngIf="!relation" height="20.4rem"></wuc-skeleton>
    </wuc-page-block>
    <!--Email Section-->
    <wuc-page-block class="details__page-block" blockType="open" icon="mail" [attr.id]="detailsSectionEnum.EmailPanel">
        <ng-container slot="header">E-Mail-Adresse</ng-container>
        <ins-email-address-data *ngIf="relation" [relation]="relation"></ins-email-address-data>
        <ins-details-toggle-button
            e2e="email-detail-button"
            [expand]="emailPanel.visible"
            [section]="detailsSectionEnum.EmailPanel"
            *ngIf="relation"
        ></ins-details-toggle-button>
        <ins-panel-expandable #emailPanel [id]="detailsSectionEnum.EmailPanel">
            <ins-panel-body *ngIf="emailPanel.visible">
                <ins-email-address-form-container></ins-email-address-form-container>
            </ins-panel-body>
        </ins-panel-expandable>
        <wuc-skeleton *ngIf="!relation" height="6.81rem"></wuc-skeleton>
    </wuc-page-block>
    <!--Opt Out Section-->
    <wuc-page-block
        class="details__page-block"
        blockType="open"
        icon="mailing-address"
        [attr.id]="detailsSectionEnum.NewsletterPanel"
    >
        <ng-container slot="header">Newsletter</ng-container>
        <ins-opt-out-data *ngIf="relation" [relation]="relation"></ins-opt-out-data>
        <ins-details-toggle-button
            e2e="newsletter-detail-button"
            [expand]="newsletterPanel.visible"
            [section]="detailsSectionEnum.NewsletterPanel"
            *ngIf="relation"
        ></ins-details-toggle-button>
        <ins-panel-expandable #newsletterPanel [id]="detailsSectionEnum.NewsletterPanel">
            <ins-panel-body *ngIf="newsletterPanel.visible">
                <ins-opt-out-form-container></ins-opt-out-form-container>
            </ins-panel-body>
        </ins-panel-expandable>
        <wuc-skeleton *ngIf="!relation" height="9.22rem"></wuc-skeleton>
    </wuc-page-block>
    <!--Password Section-->
    <wuc-page-block
        class="details__page-block"
        blockType="open"
        icon="change-login"
        [attr.id]="detailsSectionEnum.AddressPanel"
    >
        <ng-container slot="header">Passwort</ng-container>
        <ins-password-data></ins-password-data>
        <ins-details-toggle-button
            [expand]="passwordPanel.visible"
            [section]="detailsSectionEnum.PasswordPanel"
        ></ins-details-toggle-button>
        <ins-panel-expandable #passwordPanel direction="left" [id]="detailsSectionEnum.PasswordPanel">
            <ins-panel-body *ngIf="passwordPanel.visible">
                <ins-password-form-container></ins-password-form-container>
            </ins-panel-body>
        </ins-panel-expandable>
    </wuc-page-block>
    <!--Residential Address Section-->
    <wuc-page-block
        class="details__page-block"
        blockType="open"
        icon="house"
        [attr.id]="detailsSectionEnum.AddressPanel"
    >
        <ng-container slot="header">Wohnadresse</ng-container>
        <ins-residential-address-data *ngIf="relation" [relation]="relation"></ins-residential-address-data>
        <ins-residential-address-future-container
            *ngIf="relation?.residential_address_future?.address"
        ></ins-residential-address-future-container>
        <ins-details-toggle-button
            [expand]="addressPanel.visible"
            [section]="detailsSectionEnum.AddressPanel"
            e2e="residential-address-detail"
            *ngIf="!relation?.residential_address_future?.address"
        ></ins-details-toggle-button>
        <ins-panel-expandable #addressPanel direction="left" [id]="detailsSectionEnum.AddressPanel">
            <ins-panel-body *ngIf="addressPanel.visible">
                <ins-residential-address-form-container></ins-residential-address-form-container>
            </ins-panel-body>
        </ins-panel-expandable>
        <wuc-skeleton *ngIf="!relation" height="6.81rem"></wuc-skeleton>
    </wuc-page-block>
    <!--Correspondence Address Section-->
    <wuc-page-block
        class="details__page-block"
        blockType="open"
        icon="mailing-address"
        [attr.id]="detailsSectionEnum.CorrespondencePanel"
    >
        <ng-container slot="header">Postadresse</ng-container>
        <ins-correspondence-address-data-container></ins-correspondence-address-data-container>
        <ins-correspondence-address-future-container
            *ngIf="relation?.mailing_address_future?.address"
        ></ins-correspondence-address-future-container>
        <ins-details-toggle-button
            [expand]="correspondencePanel.visible"
            [section]="detailsSectionEnum.CorrespondencePanel"
            e2e="correspondence-detail-button"
            *ngIf="!relation?.mailing_address_future"
        ></ins-details-toggle-button>
        <ins-panel-expandable #correspondencePanel direction="left" id="correspondence-panel">
            <ins-panel-body *ngIf="correspondencePanel.visible">
                <ins-correspondence-address-form-container></ins-correspondence-address-form-container>
            </ins-panel-body>
        </ins-panel-expandable>
    </wuc-page-block>
    <!--Payment Details Section-->
    <wuc-page-block
        class="details__page-block"
        blockType="open"
        icon="payment-details"
        [attr.id]="detailsSectionEnum.PaymentDetailsPanel"
    >
        <ng-container slot="header">Zahlungsdetails</ng-container>
        <ins-payment-details-data *ngIf="relation" [relation]="relation"></ins-payment-details-data>
        <ins-details-toggle-button
            [expand]="paymentDetailsPanel.visible"
            [section]="detailsSectionEnum.PaymentDetailsPanel"
            *ngIf="relation"
        ></ins-details-toggle-button>
        <ins-panel-expandable #paymentDetailsPanel direction="left" [id]="detailsSectionEnum.PaymentDetailsPanel">
            <ins-panel-body *ngIf="paymentDetailsPanel.visible">
                <ins-payment-details-form-container></ins-payment-details-form-container>
            </ins-panel-body>
        </ins-panel-expandable>
        <wuc-skeleton *ngIf="!relation" height="10.02rem"></wuc-skeleton>
    </wuc-page-block>
    <!--Family Composition Section-->
    <wuc-page-block
        class="details__page-block"
        blockType="open"
        icon="family-composition"
        [attr.id]="detailsSectionEnum.FamilyPanel"
    >
        <ng-container slot="header">Familienzusammensetzung</ng-container>
        <ins-family-composition-data *ngIf="relation" [relation]="relation"></ins-family-composition-data>
        <ins-future-family-composition-container
            *ngIf="!!relation?.family_composition_future"
        ></ins-future-family-composition-container>
        <ins-details-toggle-button
            e2e="family-detail-button"
            [expand]="familyPanel.visible"
            [section]="detailsSectionEnum.FamilyPanel"
            *ngIf="!(relation && relation.family_composition_future)"
        ></ins-details-toggle-button>
        <ins-panel-expandable #familyPanel direction="left" [id]="detailsSectionEnum.FamilyPanel">
            <ins-panel-body *ngIf="familyPanel.visible">
                <ins-family-composition-form-container></ins-family-composition-form-container>
            </ins-panel-body>
        </ins-panel-expandable>
        <wuc-skeleton *ngIf="!relation" height="6.81"></wuc-skeleton>
    </wuc-page-block>
    <!--Recovery Settings Section-->
    <wuc-page-block
        class="details__page-block"
        blockType="open"
        icon="recovery-data"
        [attr.id]="detailsSectionEnum.RecoveryPanel"
    >
        <ng-container slot="header">Daten zur Wiederherstellung</ng-container>
        <ins-recovery-settings-data *ngIf="relation" [relation]="relation"></ins-recovery-settings-data>
        <ins-details-toggle-button
            class="hidden"
            [expand]="recoveryPanel.visible"
            [section]="detailsSectionEnum.RecoveryPanel"
            *ngIf="relation"
        ></ins-details-toggle-button>
        <ins-panel-expandable #recoveryPanel direction="left" [id]="detailsSectionEnum.RecoveryPanel">
            <ins-panel-body *ngIf="recoveryPanel.visible">
                <ins-recovery-settings-form-container></ins-recovery-settings-form-container>
            </ins-panel-body>
        </ins-panel-expandable>
        <wuc-skeleton *ngIf="!relation" height="6.81"></wuc-skeleton>
    </wuc-page-block>
</wuc-rack>
