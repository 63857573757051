import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
    WucCompareTableCoverageDataActionInterface,
    WucCompareTableCoverageDataColumnInterface,
    WucCompareTableCoverageDataExtraInfoInterface,
} from '@inshared/website-ui-components';
import {
    CoveragePremiumActionService,
    CoveragePremiumCoverageInterface,
    CoveragePremiumInterface,
    NotificationInterface,
    ProductBaseInterface,
} from 'outshared-lib';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { SelectedBaseCoverage } from '../coverage-detail.model';
import { CoverageDetailService } from '../coverage-detail.service';
import { ChangeCoverageComponent } from './change-coverage.component';
import {
    AdditionalCoveragePrices,
    AdditionalCoverages,
    ChangeCoverageValueChanges,
    Purchase,
    WucCompareTableRowInterface,
} from './change-coverage.interface';
import { ChangeCoverageService } from './change-coverage.service';
import { CoverageIdEnum } from '@app-de/core/enums';
import { IntersectionObserverService } from '@app-de/core/services/intersection-observer.service';
import { ScrollService } from '@app-de/core/services/scroll.service';
import { AnnualPaymentService } from '@app-de/my-zone/services/annual-payment.service';
import { CalculatePremiumService } from '@app-de/salesfunnel/pages/car/calculate-premium/calculate-premium.service';
import { CompareCoverages } from '@app-de/shared/interfaces/compare-coverages';
import * as coverages from '@app-de/shared/json/car-json/car-compare.coverages.json';

@Component({
    selector: 'ins-change-coverage-container',
    templateUrl: './change-coverage.container.html',
})
export class ChangeCoverageContainer implements OnDestroy, OnInit {
    @ViewChild(ChangeCoverageComponent, { static: true }) public changeCoverageComponent: ChangeCoverageComponent;

    @Input() public hasCancelButton: boolean = true;

    public product$: Observable<ProductBaseInterface> = this.coverageDetailService.selectedProduct$;
    public calculation$: Observable<CoveragePremiumInterface> = this.coverageDetailService.calculation$;
    public notifications$: Observable<NotificationInterface[]> = this.coverageDetailService.notifications$;
    public isScreenSmall$: Observable<boolean> = this.intersectionObserverService.isScreenSmall$;
    public compareTableColumns$: BehaviorSubject<WucCompareTableCoverageDataColumnInterface[]> = new BehaviorSubject(
        [],
    );

    public compareTableCoverages: CompareCoverages;
    public calculation: CoveragePremiumInterface;
    public additionalCoveragePrices: AdditionalCoveragePrices = {
        roadsideAssistance: 0,
        driverProtection: 0,
    };

    public additionalCoverages: AdditionalCoverages = {
        roadsideAssistance: false,
        driverProtection: false,
    };

    public payment: number = 0;
    public currentlyPurchasedCoverage: CoverageIdEnum = CoverageIdEnum.KfzHaftpflicht;
    public isSelectedCoveragePanelOpen: boolean = false;
    public selectedBaseCoverage: SelectedBaseCoverage;
    public extraInfo: WucCompareTableCoverageDataExtraInfoInterface;
    private subscriptions = new Subscription();

    constructor(
        private coverageDetailService: CoverageDetailService,
        private coveragePremiumActionService: CoveragePremiumActionService,
        private intersectionObserverService: IntersectionObserverService,
        private changeCoverageService: ChangeCoverageService,
        private scrollService: ScrollService,
        private annualPaymentService: AnnualPaymentService,
        // service is used out of salesfunnel scope
        private calculatePremiumService: CalculatePremiumService,
    ) {}

    public ngOnInit(): void {
        this.getCompareTableCoverages();
        this.createColumns();
        this.getMostPopular();

        this.subscriptions.add(
            this.calculation$.subscribe((calculation) => {
                this.calculation = calculation;

                if (
                    !this.additionalCoveragePrices.driverProtection ||
                    !this.additionalCoveragePrices.roadsideAssistance
                ) {
                    this.getAdditionalCoveragePrices();
                }

                this.getCurrentlyPurchasedCoverage();
                this.getCurrentlyPurchasedAdditionalCoverages();

                const { newMonthlyPayment, baseCoveragePrice } = this.changeCoverageService.calculateNewMonthlyPayment(
                    this.calculation,
                    this.additionalCoveragePrices,
                    this.currentlyPurchasedCoverage,
                    this.additionalCoverages.driverProtection,
                    this.additionalCoverages.roadsideAssistance,
                );
                this.payment = newMonthlyPayment;
                this.selectedBaseCoverage = {
                    price: baseCoveragePrice,
                    coverageName: this.getCoverageName(this.currentlyPurchasedCoverage),
                };
            }),
        );
    }

    public onFormValueChanges(valueChanges: ChangeCoverageValueChanges): void {
        const { newMonthlyPayment, baseCoveragePrice } = this.changeCoverageService.calculateNewMonthlyPayment(
            this.calculation,
            this.additionalCoveragePrices,
            valueChanges.baseCoverage,
            valueChanges.driverProtection,
            valueChanges.roadsideAssistance,
        );

        this.payment = newMonthlyPayment;
        this.selectedBaseCoverage = {
            price: baseCoveragePrice,
            coverageName: this.getCoverageName(valueChanges.baseCoverage),
        };
    }

    public onPurchase({
        purchasedProductSequenceNumber,
        startDate,
        baseCoverage,
        roadsideAssistance,
        driverProtection,
        scrollId,
    }: Purchase): void {
        const coverageIds = this.getCoverages(baseCoverage, roadsideAssistance, driverProtection);

        // Reset notifications
        this.coverageDetailService.notifications$.next([]);

        this.subscriptions.add(
            this.coveragePremiumActionService
                .changeGenericPurchasedCoverages({
                    purchased_product_sequence_number: purchasedProductSequenceNumber,
                    start_date: startDate,
                    coverage_ids: coverageIds,
                })
                .subscribe({
                    error: (error: NotificationInterface[]) => {
                        this.coverageDetailService.notifications$.next(error);
                    },
                    next: (): void => {
                        this.scrollService.scrollElementOffset(`#product_${scrollId}`);
                    },
                }),
        );
    }

    public toggle(): void {
        this.coverageDetailService.notifications$.next([]);
        this.coverageDetailService.toggleChangeCoverage();
    }

    public toggleSelectedCoveragePanel(): void {
        this.isSelectedCoveragePanelOpen = !this.isSelectedCoveragePanelOpen;
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    private getAdditionalCoveragePrices(): void {
        const currentPeriod = this.annualPaymentService.getCurrentPeriod(this.calculation?.product);

        const driverProtection = currentPeriod.additional_coverage.find(
            (coverage) => coverage.coverage_id === CoverageIdEnum.Fahrerschutz,
        ).premium_including_discount.premium_including_premium_tax;

        const roadsideAssistance = currentPeriod.additional_coverage.find(
            (coverage) => coverage.coverage_id === CoverageIdEnum.Pannenhilfe,
        ).premium_including_discount.premium_including_premium_tax;

        this.additionalCoveragePrices = {
            driverProtection,
            roadsideAssistance,
        };
    }

    private getCoverages(
        baseCoverage: CoverageIdEnum,
        roadsideAssistance: boolean,
        driverProtection: boolean,
    ): { coverage_id: string }[] {
        const coverageIds = [{ coverage_id: baseCoverage }, { coverage_id: CoverageIdEnum.MallorcaPolice }];

        // KFZ always needs to be included in the array. The backend treats this as a seperate module.
        if (baseCoverage !== CoverageIdEnum.KfzHaftpflicht) {
            coverageIds.push({ coverage_id: CoverageIdEnum.KfzHaftpflicht });
        }

        if (driverProtection) {
            coverageIds.push({ coverage_id: CoverageIdEnum.Fahrerschutz });
        }

        if (roadsideAssistance) {
            coverageIds.push({ coverage_id: CoverageIdEnum.Pannenhilfe });
        }

        return coverageIds;
    }

    private getCurrentlyPurchasedAdditionalCoverages(): void {
        this.calculation.product.additional_coverage.forEach((additionalCoverage) => {
            if (!additionalCoverage.purchased_coverage) {
                return;
            }

            if (additionalCoverage.coverage_id === CoverageIdEnum.Pannenhilfe) {
                this.additionalCoverages.roadsideAssistance = true;
            }

            if (additionalCoverage.coverage_id === CoverageIdEnum.Fahrerschutz) {
                this.additionalCoverages.driverProtection = true;
            }
        });
    }

    private getCurrentlyPurchasedCoverage(): void {
        const coverages = this.calculation?.product?.base_coverages.filter(
            (coverage) =>
                coverage.coverage_id === CoverageIdEnum.KfzHaftpflicht ||
                coverage.coverage_id === CoverageIdEnum.Teilkasko ||
                coverage.coverage_id === CoverageIdEnum.Vollkasko,
        );

        const additionalBaseCoverage = coverages.find(
            (coverage) => coverage.purchased_coverage && coverage.coverage_id !== CoverageIdEnum.KfzHaftpflicht,
        );

        this.currentlyPurchasedCoverage = additionalBaseCoverage
            ? (additionalBaseCoverage.coverage_id as CoverageIdEnum)
            : CoverageIdEnum.KfzHaftpflicht;
    }

    private getCompareTableCoverages(): void {
        if (coverages['default']) {
            this.compareTableCoverages = coverages['default'];
        }
    }

    private createCoverageItems(id: string): WucCompareTableRowInterface[] {
        const coverage = this.compareTableCoverages.items.find((item) => item.id === id);
        const mappedItems: WucCompareTableRowInterface[] = [];

        coverage.options.forEach((option) => {
            mappedItems.push({
                verticalAlignment: 'center',
                items: [
                    {
                        type: this.mapType(option.type),
                        value: option.value,
                    },
                ],
            });
        });

        return mappedItems;
    }

    private mapType(type: string): 'boolean' | 'text' | 'text-large' | 'price' {
        switch (type) {
            case 'boolean':
                return 'boolean';
            case 'string':
                return 'text';
        }

        return 'text';
    }

    private createColumns(): void {
        const columns: WucCompareTableCoverageDataColumnInterface[] = [];
        const columnTypes: string[] = [
            CoverageIdEnum.KfzHaftpflicht,
            CoverageIdEnum.Teilkasko,
            CoverageIdEnum.Vollkasko,
        ];

        const calculation$ = this.calculation$.subscribe((calculation) => {
            const currentPeriod = this.annualPaymentService.getCurrentPeriod(calculation?.product);

            currentPeriod.base_coverages.forEach((baseCoverage) => {
                if (columnTypes.includes(baseCoverage.coverage_id)) {
                    columns.push({
                        key: baseCoverage.coverage_id,
                        title: baseCoverage.coverage_description,
                        rows: this.createRows(baseCoverage, currentPeriod.base_coverages),
                        actions: this.createActions(baseCoverage),
                        disabled: Boolean(baseCoverage.eligibility?.accepted_indication === 'N'),
                    });
                }
            });

            columns.sort((a, b) => {
                return columnTypes.indexOf(a.key) - columnTypes.indexOf(b.key);
            });

            this.compareTableColumns$.next(columns);
        });

        this.subscriptions.add(calculation$);
    }

    private createRows(
        baseCoverage: CoveragePremiumCoverageInterface,
        baseCoverages: CoveragePremiumCoverageInterface[],
    ): WucCompareTableRowInterface[] {
        const rows: WucCompareTableRowInterface[] = [];
        const value =
            baseCoverage.coverage_id !== CoverageIdEnum.KfzHaftpflicht
                ? this.changeCoverageService.calculatePrice(
                      baseCoverages,
                      baseCoverage.premium_including_discount.premium_including_premium_tax,
                  )
                : baseCoverage.premium_including_discount.premium_including_premium_tax;
        const price = {
            type: 'price' as 'price',
            value: value,
        };
        const disabledPrice = {
            type: 'text' as 'text',
            value: 'n. a.',
        };

        rows.push({
            verticalAlignment: 'center',
            items: [Boolean(baseCoverage.eligibility?.accepted_indication === 'J') ? price : disabledPrice],
        });

        rows.push(...this.createCoverageItems(baseCoverage.coverage_id));

        return rows;
    }

    private createActions(
        baseCoverage: CoveragePremiumCoverageInterface,
    ): WucCompareTableCoverageDataActionInterface[] {
        const isDisabled = Boolean(baseCoverage.eligibility?.accepted_indication === 'N');

        return [
            {
                type: 'button',
                isPrimary: this.changeCoverageComponent.isActive(baseCoverage.coverage_id),
                isDisabled: isDisabled,
                label: this.changeCoverageComponent.getButtonText(baseCoverage.coverage_id, isDisabled),
                callback: () =>
                    isDisabled ? null : this.changeCoverageComponent.onSelectColumnKey(baseCoverage.coverage_id),
            },
            {
                type: 'link',
                isPrimary: false,
                label: 'Mehr Info',
                callback: () =>
                    this.changeCoverageComponent.openModal(baseCoverage.coverage_id, baseCoverage.coverage_description),
            },
        ];
    }

    private getCoverageName(coverageId: CoverageIdEnum): string {
        let coverageName: string;

        switch (coverageId) {
            case CoverageIdEnum.Vollkasko:
                coverageName = 'Vollkasko';
                break;
            case CoverageIdEnum.Teilkasko:
                coverageName = 'Teilkasko';
                break;
            case CoverageIdEnum.KfzHaftpflicht:
                coverageName = 'Kfz-Haftpflicht';
                break;
            default:
                console.error('Invalid CoverageIdEnum passed');
                break;
        }

        return coverageName;
    }

    private getMostPopular(): void {
        this.product$
            .pipe(
                filter((product) => Boolean(product)),
                take(1),
            )
            .subscribe((product) => {
                const buildDate: string = product['auto']['build_date'] ?? '';
                const buildYear: number = new Date(buildDate).getFullYear();
                const mostPopularId = this.calculatePremiumService.getMostPopular(buildYear);
                const mostPopularName = this.getCoverageName(this.mapMostPopularId(mostPopularId));

                this.extraInfo = {
                    columnKey: this.mapMostPopularId(mostPopularId),
                    title: `Meistgewählt für einen ${product.auto.motor_vehicle_make} ${product.auto.motor_vehicle_model}`,
                    subTitle: `Andere InShared-Kunden mit ähnlichen Fahrzeugangaben entschieden sich für ${mostPopularName}.`,
                };
            });
    }

    private mapMostPopularId(id: string): CoverageIdEnum {
        switch (id) {
            case 'KFZH':
                return CoverageIdEnum.KfzHaftpflicht;
            case 'TLKK':
                return CoverageIdEnum.Teilkasko;
            case 'VLKK':
                return CoverageIdEnum.Vollkasko;
            case 'MALL':
                return CoverageIdEnum.MallorcaPolice;
            case 'FSVS':
                return CoverageIdEnum.Fahrerschutz;
            case 'PHEU':
                return CoverageIdEnum.Pannenhilfe;
        }
    }
}
