<ins-details-table [display]="detailsTableDisplayEnum.Future">
    <ins-details-delete-button
        e2e="delete-new-address"
        title="Löschen"
        (click)="onCancelFutureResidentialAddress()"
        [isDisabled]="loading"
    >
        Löschen
    </ins-details-delete-button>
    <ins-alert *ngIf="errors.length > 0" [errors]="errors" type="validation"></ins-alert>
    <tr>
        <td>Zukünftige Wohnadresse</td>
        <!-- prettier-ignore -->
        <td e2e="future-address">
            <span>{{ relation.residential_address_future.address.street_name }}</span>
            <span>
                {{ relation.residential_address_future.civic_number }}{{ relation.residential_address_future.civic_number_suffix }},
            </span>
            <!-- prettier-ignore -->
            <span>{{ relation.residential_address_future.postal_code }} </span>
            <span>{{ relation.residential_address_future.address.city }}</span>
        </td>
    </tr>
    <tr>
        <td e2e="future-address-text">Datum</td>
        <td e2e="future-residential-date">{{ relation.residential_address_future.start_date | dateFormat }}</td>
    </tr>
</ins-details-table>
