import { NgModule } from '@angular/core';
import { SanitizePipe } from './sanitize.pipe';

@NgModule({
    imports: [],
    exports: [SanitizePipe],
    declarations: [SanitizePipe],
})

export class SanitizePipeModule {}
