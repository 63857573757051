import { UrlMatchResult, UrlSegment } from '@angular/router';
import { AccountRecoveryModalRoutesEnum } from '@app-de/routes';

const ALL_ROUTES: string[] = [
    AccountRecoveryModalRoutesEnum.AccountRecovery,
    AccountRecoveryModalRoutesEnum.CompleteGeneratedAccount,
    AccountRecoveryModalRoutesEnum.ForgotEmail,
    AccountRecoveryModalRoutesEnum.ForgotPassword,
    AccountRecoveryModalRoutesEnum.SendPassword,
    AccountRecoveryModalRoutesEnum.TemporaryPassword,
    AccountRecoveryModalRoutesEnum.UnblockAccount,
    AccountRecoveryModalRoutesEnum.ValidateGeneratedAccount,
];

function isAccountRecoveryModalRoute(path: string): boolean {
    return ALL_ROUTES.includes(path);
}

export function accountRecoveryMatcher(segments: UrlSegment[]): UrlMatchResult {
    if (segments && segments.length > 0 && isAccountRecoveryModalRoute(segments[0].path)) {
        return {
            consumed: segments,
        };
    }
    return null;
}
