<form (ngSubmit)="onFormSubmit()" [formGroup]="form">
    <cyno-field>
        <cyno-label>Name</cyno-label>
        <input cynoInput formControlName="name" id="personal-details-initials" type="text" />
    </cyno-field>

    <cyno-field>
        <cyno-label>Geburtsdatum</cyno-label>
        <cyno-birthdate formControlName="birthdate" placeholder="TT.MM.JJJJ" separator="."></cyno-birthdate>
    </cyno-field>

    <p>
        <small>
            Sollten die Angaben nicht richtig sein, setzen Sie sich bitte über das
            <a [routerLink]="['/', appRoutesEnum.Contact, { outlets: { modal: [modalRoutesEnum.Question, 'ACNT', 'GWYZ'] } }]">Kontaktformular</a>
            mit uns in Verbindung.
        </small>
    </p>

    <hr />

    <cyno-field [inline]="true">
        <cyno-label>Geschlecht</cyno-label>
        <cyno-radio [value]="genderCodes.MALE" formControlName="genderCode">Mann</cyno-radio>
        <cyno-radio [value]="genderCodes.FEMALE" formControlName="genderCode">Frau</cyno-radio>
        <cyno-radio [value]="genderCodes.NEUTRAL" formControlName="genderCode">Neutral</cyno-radio>
    </cyno-field>

    <cyno-field formGroupName="mobilePhoneNumber">
        <cyno-label>Handynummer</cyno-label>
        <input
            autocomplete="tel"
            cynoInput
            cynoPhone
            e2e="telefoonnummer"
            formControlName="telephoneNumber"
            id="personal-details-mobile_phone-number"
            inputmode="tel"
            name="telephonenumber"
            type="tel"
            placeholder="017123456789"
        />
        <cyno-error *ngIf="phoneNumber.touched && phoneNumber.hasError('required')">
            Hoppla! Könnten Sie bitte Ihre Telefonnummer eingeben?
        </cyno-error>
        <cyno-error *ngIf="phoneNumber.touched && phoneNumber.hasError('phoneInvalid')">
            Dies ist leider keine gültige Telefonnummer
        </cyno-error>
    </cyno-field>

    <cyno-alert
        type="error"
        *ngIf="errors?.length"
        [notifications]="errors"
    ></cyno-alert>

    <ins-details-submit-button>Speichern</ins-details-submit-button>
</form>
