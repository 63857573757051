import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from '../../core';
import {
    ApiGetPaymentProcessPayloadRequestInterface,
    GetPaymentProcessPayloadRequestInterface,
    GetPaymentProcessResponseInterface,
} from '../interfaces';
import { mapPaymentProcessRequest, mapPaymentProcessResponse } from '../mappers';

@Injectable({
    providedIn: 'root',
})
export class PaymentProcessApiService {
    public constructor(public apiService: ApiService) {}

    public collectOpenInvoice$(
        appData: GetPaymentProcessPayloadRequestInterface
    ): Observable<GetPaymentProcessResponseInterface> {
        const payload: ApiGetPaymentProcessPayloadRequestInterface = mapPaymentProcessRequest(appData);

        return this.apiService
            .post$('en-gb/relation-financial/incasseren/openstaande-facturen', payload)
            .pipe(map(mapPaymentProcessResponse));
    }
}
