import { NgModule } from '@angular/core';
import { LicensePlatePipe } from './license-plate.pipe';

@NgModule({
    imports: [],
    exports: [LicensePlatePipe],
    declarations: [LicensePlatePipe],
})

export class LicensePlatePipeModule {}
