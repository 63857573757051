import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { OutInternationalMotorInsuranceCardModule } from 'outshared-lib';
import {
    InternationalMotorInsuranceCardButtonComponent,
} from './international-motor-insurance-card-buttons/international-motor-insurance-card-button/international-motor-insurance-card-button.component';
import { InternationalMotorInsuranceCardButtonsComponent } from './international-motor-insurance-card-buttons/international-motor-insurance-card-buttons.component';
import { InternationalMotorInsuranceCardComponent } from './international-motor-insurance-card.component';
import { AlertModule } from '@app-de/components/alert/alert.module';
import { CynoFormModule } from '@app-de/shared/cyno-form/cyno-form.module';
import { ConfirmationModalModule } from '@app-de/shared/modals/confirmation-modal/confirmation.modal.module';
import { DownloadingModalModule } from '@app-de/shared/modals/downloading-modal/downloading.modal.module';
import { PanelExpandableModule } from '@app-de/shared/panel-expandable/panel-expandable.module';
import { CapitalizeModule } from '@app-de/shared/pipes/capitalize/capitalize.module';
import { LicensePlatePipeModule } from '@app-de/shared/pipes/licence-plate/license-plate.module';

@NgModule({
    imports: [
        CommonModule,
        CynoFormModule,
        PanelExpandableModule,
        LicensePlatePipeModule,
        CapitalizeModule,
        ConfirmationModalModule,
        AlertModule,
        OutInternationalMotorInsuranceCardModule,
        DownloadingModalModule,
    ],
    declarations: [
        InternationalMotorInsuranceCardButtonsComponent,
        InternationalMotorInsuranceCardComponent,
        InternationalMotorInsuranceCardButtonComponent,

    ],
    exports: [
        InternationalMotorInsuranceCardButtonsComponent,
        InternationalMotorInsuranceCardComponent,
        InternationalMotorInsuranceCardButtonComponent,
    ],
})
export class InternationalMotorInsuranceCardModule {
}
