import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from '../../core';
import {
    ApiGetInsuranceInfoCheckRequest,
    ApiGetInsuranceInfoCheckResponse,
    GetInsuranceInfoCheckRequestInterface,
    GetInsuranceInfoCheckResponseInterface,
    SaveInsuranceInfoCheckRequestInterface,
} from '../interfaces';
import {
    mapGetInsuranceInfoCheckRequest,
    mapGetInsuranceInfoCheckResponse,
    mapSaveInsuranceInfoCheckRequest,
} from '../mappers';

const RESOURCES = {
    GET_INSURANCE_INFO_CHECK: 'en-gb/insurance-product/request/vwbdata-basicdata',
    SAVE_INSURANCE_INFO_CHECK: 'en-gb/insurance-product/create/vwbdata-basicdata',
};

@Injectable({
    providedIn: 'root',
})
export class InsuranceInfoCheckApiService {
    constructor(private apiService: ApiService) {}

    public getInsuranceInfoCheck$(
        payload: GetInsuranceInfoCheckRequestInterface
    ): Observable<GetInsuranceInfoCheckResponseInterface> {
        const _payload: ApiGetInsuranceInfoCheckRequest = mapGetInsuranceInfoCheckRequest(payload);
        return this.apiService
            .post$<ApiGetInsuranceInfoCheckResponse>(RESOURCES.GET_INSURANCE_INFO_CHECK, _payload)
            .pipe(map(mapGetInsuranceInfoCheckResponse));
    }

    public saveInsuranceInfoCheck$(payload: SaveInsuranceInfoCheckRequestInterface): Observable<void> {
        const _payload: ApiGetInsuranceInfoCheckRequest = mapSaveInsuranceInfoCheckRequest(payload);
        return this.apiService.post$<void>(RESOURCES.SAVE_INSURANCE_INFO_CHECK, _payload);
    }
}
