import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WucAlertModule, WucButtonModule } from '@inshared/website-ui-components';
import { NoChangesPossibleNotificationComponent } from './no-changes-possible-notification';

@NgModule({
    declarations: [NoChangesPossibleNotificationComponent],
    exports: [NoChangesPossibleNotificationComponent],
    imports: [CommonModule, WucAlertModule, WucButtonModule],
})
export class MyZoneSideNotificationsModule {}
