import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WucButtonModule } from '@inshared/website-ui-components';
import { NewModalModule } from 'outshared-lib';
import { InsuranceRegularDriverModal } from './insurance-regular-driver.modal';
import { ModalComponentsModule } from '@app-de/components';

@NgModule({
    declarations: [InsuranceRegularDriverModal],
    imports: [
        CommonModule,
        NewModalModule,
        ModalComponentsModule,
        WucButtonModule,
    ],
})
export class InsuranceRegularDriverModalModule {}
