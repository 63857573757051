/* eslint-disable @typescript-eslint/naming-convention */
export enum PremiumDeterminingFactorElementNameEnum {
    mileage_auto = 'kilometrage_auto',
    no_claims_years_amount = 'schadevrije_jaren_aantal',
    trede = 'trede',
    fuel_type_code = 'brandstof_code',
    mileage_motorcycle = 'kilometrage_motor',
    content_category = 'inhoud_categorie',
    straw_roofing_indication = 'rietendak_indicatie',
    monument_type_code = 'type_monument_code',
    choice_deductible_excess_amount = 'keuze_eigen_risico_bedrag',
    property_type = 'woonhuis_code',
    gross_floor_area_category = 'gebruiksoppervlakte_categorie',
    security_type_code = 'type_beveiliging_code',
    build_year = 'bouwjaar',
    hail_roof_indication = 'hageldak_indicatie',
}
