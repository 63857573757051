import { animate, style, transition, trigger } from '@angular/animations';
import { Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'ins-tooltip',
    templateUrl: './tooltip.component.html',
    styleUrls: ['./tooltip.component.scss'],
    animations: [
        trigger('tooltipAnimation', [
            transition(':enter', [
                style({
                    opacity: 0,
                    transform: 'scale(.7)',
                }),
                animate(
                    '150ms cubic-bezier(0.215, 0.61, 0.355, 1)',
                    style({
                        opacity: 1,
                        transform: 'scale(1)',
                    }),
                ),
            ]),
            transition(':leave', [
                style({
                    opacity: 1,
                    transform: 'scale(1)',
                }),
                animate(
                    '75ms cubic-bezier(0.55, 0.055, 0.675, 0.19)',
                    style({
                        opacity: 0,
                        transform: 'scale(.7)',
                    }),
                ),
            ]),
        ]),
    ],
})
export class TooltipComponent {
    public visible$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public content: string;
    public orientation: 'left' | 'right' | 'bottom';
}
