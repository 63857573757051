import { ApiDownloadInvoiceRequest, ApiDownloadInvoiceResponse, DownloadInvoiceInterface } from '../interfaces';

export function downloadInvoiceRequestMapper(invoiceId: string): ApiDownloadInvoiceRequest {
    return {
        invoice_id: invoiceId,
    };
}

export function downloadInvoiceResponseMapper(response: ApiDownloadInvoiceResponse): DownloadInvoiceInterface {
    return {
        base64: response.pdf,
        filename: response.identifying_name,
        mimeType: response.type,
    };
}
