import { NgModule } from '@angular/core';
import { WucBalloonModule, WucDropTriggerModule } from '@inshared/website-ui-components';
import { ImmerGemeintSindComponent } from './immer-gemeint-sind.component';

@NgModule({
    imports: [
        WucDropTriggerModule,
        WucBalloonModule,
    ],
    declarations: [ImmerGemeintSindComponent],
    exports: [ImmerGemeintSindComponent],
})
export class ImmerGemeintSindModule {
}
