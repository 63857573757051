export class ModalControlAction {
    public modalComponent: any;
    public confirmation: string;

    constructor(data?: ModalControlAction) {
        if (data) {
            this.modalComponent = data.modalComponent;
            this.confirmation = data.confirmation;
        }
    }
}
