<ins-panel-body>
    <h3>Anfangsdatum ändern</h3>
    <p>Tragen Sie hier das Datum ein, an dem Ihre Versicherung beginnen soll.</p>
    <form [formGroup]="form" (submit)="onSubmit()">
        <cyno-field>
            <cyno-label>Anfangsdatum</cyno-label>
            <ins-datepicker
                [minDate]="minDate"
                separator="."
                e2e="start_date"
                formControlName="startDate"
                id="start_date"
            ></ins-datepicker>
            <cyno-error *ngIf="startDate.touched && startDate.hasError('required')">
                Hoppla, da fehlt noch das Anfangsdatum.
            </cyno-error>
        </cyno-field>
        <cyno-error *ngIf="errors" [errors]="errors"></cyno-error>
        <ins-details-submit-button>Ändern</ins-details-submit-button>
    </form>
</ins-panel-body>
