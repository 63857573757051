<ins-modal-close></ins-modal-close>

<ins-modal-title>E-Mail-Adresse vergessen?</ins-modal-title>

<ins-modal-content *ngIf="!success">
    <p>Einfach die folgenden Felder ausfüllen. Und die bei uns registrierte E-Mail-Adresse kommt per SMS.</p>
    <form [formGroup]="form" role="form" (ngSubmit)="onSubmit()">
        <cyno-field *ngIf="method.value">
            <cyno-label>Geburtsdatum</cyno-label>
            <cyno-birthdate formControlName="birthdate" placeholder="TT.MM.JJJJ" separator="."></cyno-birthdate>
        </cyno-field>

        <cyno-field *ngIf="method.value === recoveryMethod.Phone">
            <cyno-label>Handynummer</cyno-label>
            <input cynoInput type="tel" formControlName="phone" (keydown.enter)="onSubmit()" />
        </cyno-field>

        <ins-alert *ngIf="errors.length > 0" [errors]="errors" type="validation"></ins-alert>
    </form>
</ins-modal-content>

<ins-modal-content *ngIf="success">
    <p *ngFor="let notification of notifications">
        {{ notification.message }}
    </p>
</ins-modal-content>

<ins-modal-actions *ngIf="!success">
    <ins-modal-actions-previous [link]="backLink"></ins-modal-actions-previous>
    <button class="btn btn--primary" [disabled]="submitting || form.invalid" (click)="onSubmit()">Weiter</button>
</ins-modal-actions>

<ins-modal-actions alignment="end" *ngIf="success">
    <button class="btn btn--primary" (click)="onCloseAfterSuccess()">Schließen</button>
</ins-modal-actions>
