import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DropdownDirective, DropdownMenuDirective, DropdownToggleDirective } from './dropdown.directive';

@NgModule({
    declarations: [
        DropdownDirective,
        DropdownMenuDirective,
        DropdownToggleDirective,
    ],
    imports: [CommonModule],
    exports: [
        DropdownDirective,
        DropdownMenuDirective,
        DropdownToggleDirective,
    ],
})
export class DropdownToggleModule {}
