import { CynolyticsTypeEnum } from '../enums';
import {
    ApiCynolyticsEventInterface,
    ApiCynolyticsNotificationInterface,
    ApiCynolyticsPageviewInterface,
    CynolyticsEventInterface,
    CynolyticsNotificationInterface,
    CynolyticsPageviewInterface,
    DatalayerInterface,
} from '../interfaces';

// TODO Check existing mappings none of the other values are currently mapped
export function dataLayerToCynolyticsEventMapper(request: DatalayerInterface): CynolyticsEventInterface {
    return {
        type: CynolyticsTypeEnum.Event,
        eventName: request.event,
        eventCategory: null,
        eventValue: null,
        productId: null,
        uiElementType: null,
        uiAction: null,
        uiElementId: null,
    };
}

export function cynolyticsEventRequestMapper(request: CynolyticsEventInterface): ApiCynolyticsEventInterface {
    return {
        type: request.type,
        event_naam: request.eventName,
        event_categorie: request.eventCategory,
        event_waarde: request.eventValue,
        product_id: request.productId,
        ui_element_type: request.uiElementType,
        ui_actie: request.uiAction,
        ui_element_id: request.uiElementId,
    };
}

export function cynolyticsNotificationMapper(
    request: CynolyticsNotificationInterface
): ApiCynolyticsNotificationInterface {
    return {
        type: request.type,
        melding_categorie: request.notificationCategory,
        boodschap_code: request.notificationCode,
        boodschap_inhoud: request.notificationContent,
        product_id: request.productId,
        event_naam: request.eventName,
    };
}

export function cynolyticsPageviewMapper(request: CynolyticsPageviewInterface): ApiCynolyticsPageviewInterface {
    return {
        type: request.type,
        url_pad: request.urlPath,
        pagina_titel: request.pageTitle,
    };
}
