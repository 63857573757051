import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RelationDataService, RelationInterface } from 'outshared-lib';
import { Observable } from 'rxjs';

@Component({
    selector: 'ins-chatbot-entry-container',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <ins-chatbot-entry
            [relation]="relation$ | async"
            [isOnDarkBackground]="isOnDarkBackground"
            [isSecondary]="isSecondary"
            [hideMobile]="hideMobile"
        >
        </ins-chatbot-entry>
    `,
})
export class ChatbotEntryContainer {
    @Input() public isOnDarkBackground: boolean = false;
    @Input() public isSecondary: boolean = false;
    @Input() public hideMobile: boolean;

    public readonly relation$: Observable<RelationInterface> = this.relationDataService.getRelation$();

    constructor(private relationDataService: RelationDataService) {}
}
