import { InjectionToken } from '@angular/core';
import { ValueOfProductId } from '../constants';
import { WebServicesSalesfunnelEnum } from '../enums';

type WebServiceIdValues = WebServicesSalesfunnelEnum;

export interface EnvironmentSettings {
    production: boolean;
    productId: ValueOfProductId;
    config: {
        webServiceId: WebServiceIdValues;
        webServiceUrl: string;
    };
}

export const ENVIRONMENT_SETTINGS = new InjectionToken<EnvironmentSettings>('ENVIRONMENT_SETTINGS');
