<div
    class="insurance-list"
    id="insurance-list-{{ product.purchased_product_sequence_number }}"
    [ngClass]="{
        'insurance-list--future': product.validity_status_code === 'TOEK' || product.validity_status_code === 'OSTP'
    }"
>
    <!-- Icon -->
    <div class="insurance-list__icon" [ngSwitch]="product.product_id">
        <svg class="svg-icon svg-icon--l">
            <use attr.href="assets/icons-general.svg#{{ productNameEnum[product.product_id] }}"></use>
        </svg>
    </div>
    <!-- // Icon -->
    <!-- Mobile toggle -->
    <div class="insurance-list__mobile-toggle" *ngIf="calculation">
        <a
            class="btn btn--more"
            href="#"
            *ngIf="selectedPanel === selectedPanelEnum.Close || queryParams?.purchased_product !== product.purchased_product_sequence_number"
            [routerLink]="['/', AppRoutesEnum.MyZone, myZoneRoutes.MyInsurances]"
            [queryParams]="{
                purchased_product: product.purchased_product_sequence_number,
                panel: selectedPanelEnum.Detail,
                scroll: true
            }"
        >
            <svg class="svg-icon svg-icon--xs"><use href="assets/icons-ui.svg#pijl-down-bold"></use></svg>
        </a>
        <a
            class="btn btn--more"
            href="#"
            *ngIf="selectedPanel === selectedPanelEnum.Detail && queryParams?.purchased_product === product.purchased_product_sequence_number"
            [routerLink]="['/', AppRoutesEnum.MyZone, myZoneRoutes.MyInsurances]"
            [queryParams]="{
                purchased_product: product.purchased_product_sequence_number,
                panel: selectedPanelEnum.Close
            }"
        >
            <svg class="svg-icon svg-icon--xs"><use href="assets/icons-ui.svg#pijl-up-bold"></use></svg>
        </a>
    </div>
    <!-- // Mobile toggle -->
    <div class="insurance-list__title">
        <!-- Title -->
        <h3 [ngSwitch]="product.product_id">
            {{ product.product_description | capitalize }}
            <ng-container *ngSwitchCase="'AUTO'">
                <span class="insurance-list__subtitle">
                    {{ product.auto.motor_vehicle_make | capitalize }}
                    {{ product.auto.motor_vehicle_model | capitalize }}
                    {{ product.auto.license_plate }}
                </span>
            </ng-container>
        </h3>
        <!-- // Title -->
        <!-- Notice -->
        <div class="insurance-list__notice" *ngIf="product.validity_status_code === 'TOEK'">
            Ab {{ product.start_date | dateFormat }} -
            <span class="text--secondary">Bitte beachten: Diese Versicherung ist noch nicht in Kraft getreten.</span>
        </div>
        <div class="insurance-list__notice" *ngIf="product.validity_status_code === 'OSTP'">
            Versicherungsbeginn unbekannt.
            <span class="text--secondary">Bitte beachten: Diese Versicherung ist noch nicht in Kraft getreten.</span>
        </div>
        <div
            class="insurance-list__notice"
            *ngIf="['ACEI', 'EIND', 'NEVT'].includes(product.validity_status_code) && product.end_date"
        >
            bis {{ product.end_date | dateFormat }}
        </div>
        <!-- // Notice -->
    </div>

    <!-- Info -->
    <div class="insurance-list__info">
        <!-- List of modules -->
        <div class="insurance-list__module-lists">
            <ins-coverage-list
                *ngIf="isCurrent"
                display="current"
                [productId]="product.product_id"
                [permittedCoverages]="product.permitted_coverage"
            ></ins-coverage-list>
            <ins-coverage-list
                *ngIf="isFuture"
                display="future"
                [productId]="product.product_id"
                [permittedCoverages]="product.permitted_coverage"
            ></ins-coverage-list>
        </div>
        <!-- // List of modules -->
        <!-- List of module changes -->

        <div class="insurance-list__module-changes">
            <wuc-balloon
                *ngIf="
                    calculation &&
                    (calculation.product.coverages_adjustment_start_date || product.non_regular_driver_future) &&
                    !['TOEK', 'OSTP', 'EIND'].includes(product.validity_status_code)
                "
                [arrowPosition]="isScreenSmall ? 'topLeft' : 'leftTop'"
                [hideShadow]="true"
                backgroundStyle="secondary"
                padding="large"
            >
                <!-- Changes -->
                <div>
                    <p *ngIf="calculation.product.coverages_adjustment_start_date">
                        Versicherungsschutz ab {{ calculation.product.coverages_adjustment_start_date | dateFormat }}:
                        <br />
                        <ins-coverage-list
                            display="future"
                            [productId]="product.product_id"
                            [permittedCoverages]="product.permitted_coverage"
                        ></ins-coverage-list>
                    </p>
                    <p *ngIf="product.non_regular_driver_future">
                        <span>
                            Am {{ product.non_regular_driver_future.start_date | dateFormat }} wechselt der Hauptfahrer
                            Ihrer Versicherung.
                        </span>
                    </p>
                </div>
                <!-- // Changes -->
            </wuc-balloon>
        </div>

        <!-- // List of module changes -->
    </div>
    <!-- Info -->
    <!-- Details -->
    <div class="insurance-list__detail">
        <!-- Button -->
        <div class="insurance-list__buttons" [ngSwitch]="selectedPanel">
            <ng-container *ngIf="selectedPanel !== selectedPanelEnum.Detail
            || queryParams?.purchased_product !== product.purchased_product_sequence_number">
                <a
                    class="btn btn--more"
                    href="#"
                    *ngIf="calculation"
                    [routerLink]="['/', AppRoutesEnum.MyZone, myZoneRoutes.MyInsurances]"
                    [queryParams]="{
                        purchased_product: product.purchased_product_sequence_number,
                        panel: selectedPanelEnum.Detail,
                        scroll: true
                    }"
                    e2e="insurance-detail-button"
                >
                    <span>Details</span>
                    <svg class="svg-icon svg-icon--xs"><use href="assets/icons-ui.svg#pijl-down-bold"></use></svg>
                </a>
                <a class="btn btn--more" *ngIf="!calculation" [class.disabled]="!calculation">
                    <span>Details</span>
                    <svg class="svg-icon svg-icon--xs"><use href="assets/icons-ui.svg#pijl-down-bold"></use></svg>
                </a>
            </ng-container>
            <ng-container *ngIf="selectedPanel === selectedPanelEnum.Detail
            && queryParams?.purchased_product === product.purchased_product_sequence_number">
                <a
                    class="btn btn--more"
                    href="#"
                    [routerLink]="['/', AppRoutesEnum.MyZone, myZoneRoutes.MyInsurances]"
                    [queryParams]="{
                        purchased_product: product.purchased_product_sequence_number,
                        panel: selectedPanelEnum.Close
                    }"
                    e2e="insurance-detail-button"
                >
                    <span>Schließen</span>
                    <svg class="svg-icon svg-icon--xs"><use href="assets/icons-ui.svg#pijl-up-bold"></use></svg>
                </a>
            </ng-container>
        </div>
        <!--// Button -->
        <!-- Direct control -->
        <div class="insurance-list__direct-control">
            <ins-direct-control-buttons-container [product]="product"></ins-direct-control-buttons-container>
        </div>
        <!-- // Direct control -->
        <!-- Total -->
        <div class="insurance-list__total">
            <div class="insurance-list__price" *ngIf="currentPeriod">
                <wuc-price
                    *ngIf="currentPeriod?.total_premium_including_discount"
                    [price]="currentPeriod?.total_premium_including_discount.premium_including_premium_tax"
                    locale="de"
                    size="xl"
                ></wuc-price>
                <wuc-price *ngIf="!currentPeriod?.total_premium_including_discount" [price]="0" locale="de"></wuc-price>
            </div>
            <wuc-text-highlight type="secondary">
                {{
                    calculation?.product.purchased_invoice_period_months_number === 12
                        ? 'Ihr Jahresbeitrag'
                        : 'Ihr Monatsbeitrag'
                }}
            </wuc-text-highlight>
        </div>
        <!-- // Price -->
    </div>
    <!-- // Buttons -->
    <!-- Panel -->
    <div class="insurance-list__panel">
        <!-- #TODO - Add dynamic direction for action buttons vs details button -->
        <ins-panel-expandable
            class="panel--info"
            [id]="'product_' + product.purchased_product_sequence_number + '_panel'"
            #detail="panel"
            [direction]="
                selectedPanel === selectedPanelEnum.Detail
                    ? panelExpandableDirectionEnum.Left
                    : panelExpandableDirectionEnum.DirectControl
            "
        >
            <ng-container [ngSwitch]="selectedPanel">
                <ins-insurance-detail-container
                    *ngSwitchCase="selectedPanelEnum.Detail"
                ></ins-insurance-detail-container>
                <ins-coverage-detail-container
                    [hasCancelButton]="true"
                    *ngSwitchCase="selectedPanelEnum.ChangeCoverage"
                ></ins-coverage-detail-container>
                <div
                    class="insurance-list__panel-wrapper"
                    *ngSwitchCase="selectedPanelEnum.InternationalMotorInsuranceCard"
                >
                    <ins-international-motor-insurance-card-buttons
                        [product]="product"
                    ></ins-international-motor-insurance-card-buttons>
                </div>
                <ins-terminate-insurance-container
                    *ngSwitchCase="selectedPanelEnum.TerminateInsurance"
                ></ins-terminate-insurance-container>
                <ins-insurance-regular-driver-form
                    *ngSwitchCase="selectedPanelEnum.ChangeDriver"
                    [product]="product"
                ></ins-insurance-regular-driver-form>
            </ng-container>
        </ins-panel-expandable>
    </div>
    <!-- // Panel -->
</div>
