import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { SharedModule } from '../../shared/shared.module';
import { VerhaltenAutopanneSummaryComponent } from './verhalten-autopanne-summary.component';
import { CardModule } from '@app-de/shared/card/card.module';

@NgModule({
    declarations: [VerhaltenAutopanneSummaryComponent],
    imports: [CommonModule, RouterModule, CardModule, LazyLoadImageModule, SharedModule],
    exports: [VerhaltenAutopanneSummaryComponent],
})
export class VerhaltenAutopanneSummaryModule {}
