import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StickyBarFirstComponent } from './sticky-bar-first.component';
import { StickyBarSecondComponent } from './sticky-bar-second.component';
import { StickyBarComponent } from './sticky-bar.component';

@NgModule({
    imports: [CommonModule],
    declarations: [
        StickyBarComponent,
        StickyBarFirstComponent,
        StickyBarSecondComponent,
    ],
    exports: [
        StickyBarComponent,
        StickyBarFirstComponent,
        StickyBarSecondComponent,
    ],
})
export class StickyBarModule {}
