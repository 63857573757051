import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WucFormErrorModule } from '@inshared/website-ui-components';
import { CancelInsuranceModule } from '@app-de/modals/cancel-insurance/cancel-insurance.modal.module';
import { ChangeStartDateInsuranceModule } from '@app-de/my-zone/shared/change-start-date-insurance/change-start-date-insurance.module';
import { PolicyDetailsComponent } from '@app-de/my-zone/shared/policy-details/policy-details.component';
import { PolicyDetailsContainer } from '@app-de/my-zone/shared/policy-details/policy-details.container';
import { TerminateInsuranceModule } from '@app-de/my-zone/shared/terminate-insurance/terminate-insurance.module';
import { PanelExpandableModule } from '@app-de/shared/panel-expandable/panel-expandable.module';
import { DateFormatPipeModule } from '@app-de/shared/pipes/date-format/date-format.module';
import { TooltipModule } from '@app-de/shared/tooltip/tooltip.module';

@NgModule({
    imports: [
        CommonModule,
        DateFormatPipeModule,
        TooltipModule,
        PanelExpandableModule,
        TerminateInsuranceModule,
        CancelInsuranceModule,
        ChangeStartDateInsuranceModule,
        WucFormErrorModule,
    ],
    exports: [PolicyDetailsContainer],
    declarations: [
        PolicyDetailsComponent,
        PolicyDetailsContainer,
    ],
})
export class PolicyDetailsModule {}
