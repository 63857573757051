import { Component } from '@angular/core';
import {
    ProductBaseInterface,
    ProductBasePremiumDeterminingFactorInterface,
    ProductSelectorService,
} from 'outshared-lib';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ModifiableByEnum } from './modifiable-by.enum';

@Component({
    selector: 'ins-premium-determining-factors-container',
    template: `
        <ins-premium-determining-factor-container
            *ngFor="let premiumDeterminingFactor of premiumDeterminingFactor$ | async"
            [premiumDeterminingFactor]="premiumDeterminingFactor"
            [product]="product$ | async"
        >
        </ins-premium-determining-factor-container>
    `,
})
export class PremiumDeterminingFactorsContainer {
    public product$: Observable<ProductBaseInterface> = this.productSelectorService.getSelectedProduct$;

    public premiumDeterminingFactor$: Observable<any[]> = this.product$.pipe(
        filter((product) => product.premium_determining_factor),
        map((product) => product.premium_determining_factor),
        map((factors: ProductBasePremiumDeterminingFactorInterface[]) =>
            factors.filter((factor) => factor.modifiable_by_code === ModifiableByEnum.Customer),
        ),
    );

    constructor(private productSelectorService: ProductSelectorService) {}
}
