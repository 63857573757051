import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RelationActionService, RelationDataService, RelationInterface } from 'outshared-lib';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { DetailsFormClass } from '@app-de/my-zone/classes';

interface UpdatePrimaryEmailRequestInterface {
    email: string;
    password: string;
}
@Component({
    selector: 'ins-email-address-form-container',
    templateUrl: './email-address-form.container.html',
})
export class EmailAddressFormContainer extends DetailsFormClass implements OnInit, OnDestroy {
    public relation$: Observable<RelationInterface>;
    private subscriptions: Subscription;

    constructor(
        router: Router,
        private relationActionService: RelationActionService,
        private relationDataService: RelationDataService,
        changeDetectorRef: ChangeDetectorRef,
    ) {
        super(changeDetectorRef, router);
    }

    public ngOnInit(): void {
        this.subscriptions = new Subscription();
        this.loadRelation();
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    public onSubmit(payload: UpdatePrimaryEmailRequestInterface): void {
        this.subscriptions.add(
            this.relationActionService
                .updatePrimaryEmail$({
                    currentPassword: payload.password,
                    email: payload.email,
                })
                .subscribe({
                    next: () => this.closePanel(),
                    error: (error) => this.handleErrors(error),
                }),
        );
    }

    private loadRelation(): void {
        this.relation$ = this.relationDataService
            .getRelation$()
            .pipe(filter((relation: RelationInterface) => !!relation));
    }
}
