<ins-modal-close></ins-modal-close>
<ins-modal-title>Kündigung widerrufen</ins-modal-title>
<ins-modal-content>
    <p>Möchten Sie bei uns versichert bleiben? Das freut uns!</p>
</ins-modal-content>
<ins-modal-actions alignment="between">
    <button class="btn btn--link" (click)="close()">Nein, doch nicht.</button>
    <button class="btn btn--primary" ins_debounceClick (click)="submit()" e2e="cancel-terminate-insurance-button">
        Ja, Kündigung widerrufen
    </button>
</ins-modal-actions>
