export enum MyZoneRoutesEnum {
    AnnualReward = 'jaarbeloning',
    Insurances = 'verzekeringen',
    MyClaims = 'mijn-schades',
    MyData = 'mijn-gegevens',
    MyInsurances = 'mijn-verzekeringen',
    MyInvoices = 'mijn-facturen',
    MyTerms = 'mijn-voorwaarden',
    SelfService = 'zelf-regelen',
    Terms = 'voorwaarden',
    Address = 'woonadres',
    Edit = 'wijzigen',
    Cancel = 'opzeggen',
}
