import { AfterContentInit, Component, ContentChildren, HostBinding, Input, QueryList } from '@angular/core';
import { TabComponent } from './tab.component';

@Component({
    selector: 'ins-tabset',
    templateUrl: './tabset.component.html',
    styleUrls: ['tabset.component.scss'],
})
export class TabsetComponent implements AfterContentInit {
    @HostBinding('class') public class: string = 'tabset';
    @ContentChildren(TabComponent) public tabs: QueryList<TabComponent>;
    @Input() public customClass: string = 'tabset tabset--arrow-down';

    public ngAfterContentInit(): void {
        this.class = this.customClass;
    }

    public selectTab(tab: TabComponent): boolean {
        // deactivate all tabs
        const tabs = this.tabs.toArray();
        tabs.forEach((tabComponent: TabComponent) => (tabComponent.active = false));

        // activate the tab the user has clicked on.
        tab.active = true;

        return false;
    }
}
