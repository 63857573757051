import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TermsAndConditionContainer } from '@app-de/my-zone/pages/terms-and-condition/terms-and-condition.container';
import { ProductsResolver } from '@app-de/my-zone/resolvers/products.resolver';

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        component: TermsAndConditionContainer,
        resolve: {
            products: ProductsResolver,
        },
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class TermsAndConditionRoutingModule {}
