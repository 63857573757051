import { Component, HostBinding, Input } from '@angular/core';

@Component({
    selector: 'ins-rack-row',
    templateUrl: 'rack-row.component.html',
    styleUrls: ['rack-row.component.scss'],
})
export class RackRowComponent {
    @HostBinding('class.rack-row') public hostClass = true;
    @HostBinding('class.rack-row--highlight') @Input() public highlight: boolean;
}
