import { Component } from '@angular/core';
import { NewModalRef } from 'outshared-lib';

@Component({
    selector: 'ins-downloading-modal',
    templateUrl: './downloading.modal.html',
})
export class DownloadingModal {
    constructor(
        public modalRef: NewModalRef<DownloadingModal>
    ) {}
}
