// Change to ScenarioCodeEnum when MyZoneStoreModule is gone
export enum PolicyScenarioCodeEnum {
    // 'SJVG', // Schadevrije jaren vragen
    DamageFreeYears = 'Damage free years',
    // 'VEVO', // Vervangen
    VehicleReplacement = 'Vehicle replacement',
    // 'VZWA', // Vergelijken opgegeven adresgegevens met het woonadres die bij ons bekend is.
    CompareAddressDetails = 'Address comparison details',
    // 'VZID', // Vergelijken opgegeven ingangsdatum met de ingangsdatum van het toekomstig woonadres
    // die bij ons bekend is of in de ingangsdatum van het woonadres in de input.
    CompareAddressStartDate = 'Address comparison start date',
    // 'LT40', // Heeft Line type 40 bericht
    LineType40 = 'Line type 40',
}
