<input
    #phone_number
    class="form-control"
    type="tel"
    [id]="id"
    [disabled]="disabled"
    [required]="required"
    [placeholder]="placeholder"
    (keyup)="onKeyup()"
    (blur)="onBlur()"
/>
