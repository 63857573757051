import { Component, Input } from '@angular/core';
import { NewModal, RelationInterface } from 'outshared-lib';
import { ChatbotModalComponent } from '@app-de/shared/customer-service/chatbot';

@Component({
    selector: 'ins-chatbot-entry',
    templateUrl: './chatbot-entry.component.html',
    styleUrls: ['./chatbot-entry.component.scss'],
})
export class ChatbotEntryComponent {
    @Input() public relation!: RelationInterface;
    @Input() public isOnDarkBackground: boolean = false;
    @Input() public isSecondary: boolean = false;
    @Input() public hideMobile: boolean;

    constructor(private newModal: NewModal) {}

    public onQuestion(question: string): void {
        this.newModal.open<ChatbotModalComponent>(ChatbotModalComponent, { data: question });
    }

    public onOpen(): void {
        this.newModal.open<ChatbotModalComponent>(ChatbotModalComponent);
    }
}
