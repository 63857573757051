<ins-smart-tips-card [smartTipsCarousel]="smartTipsCarousel">
    <a class="card__link" [href]="url">
        <ins-card-image>
            <ins-banner
                [image]="smartTipsImageEnum.TankenFehler"
                imageAlt="Eine Zapfpistole verspritzt Kraftstoff neben den Tank."
                imageTitle="Machen keine Fehler beim Tanken mehr. Es ist leichter als Sie denken!"
                size="small"
            ></ins-banner>
        </ins-card-image>
        <ins-card-head>
            <h3>Die 7 häufigsten Fehler beim Tanken</h3>
        </ins-card-head>
        <ins-card-content>
            <p class="card__content-text">
                Falschen Kraftstoff in den Tank gefüllt, ein paar Spritzer Diesel aufs Auto getropft oder mit leerem
                Tank stehengeblieben? Wie Sie die gängigsten Fehler rund ums Tanken vermeiden, verraten wir Ihnen im
                folgenden Artikel.
            </p>
        </ins-card-content>
        <ins-card-footer>
            <ins-read-article [smartTip]="url"></ins-read-article>
        </ins-card-footer>
    </a>
</ins-smart-tips-card>
