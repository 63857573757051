import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { DamageFreeYearsImpactResponseInterface } from '../interfaces';
import { DamageFreeYearsImpactSelectors, DamageFreeYearsImpactState } from '../store';

@Injectable({
    providedIn: 'root',
})
export class DamageFreeYearsImpactDataService {
    constructor(private store$: Store<DamageFreeYearsImpactState>) {}

    public getState$(): Observable<DamageFreeYearsImpactState> {
        return this.store$.select(DamageFreeYearsImpactSelectors.getState);
    }

    public getDamageFreeYearsImpact$(): Observable<DamageFreeYearsImpactResponseInterface> {
        return this.store$.select(DamageFreeYearsImpactSelectors.getDamageFreeYearsImpact);
    }
}
