import { ChangeDetectorRef, Component, ElementRef, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { EventListenerService } from '@app-de/core/services/event-listener.service';
import { WINDOW } from '@inshared/shared/util';

export type ClickTooltipPositionType = 'bottomAuto' | 'bottomLeft' | 'bottomRight';

@Component({
    selector: 'ins-click-tooltip',
    templateUrl: 'click-tooltip.component.html',
    styleUrls: ['click-tooltip.component.scss'],
    exportAs: 'click-tooltip',
})
export class ClickTooltipComponent implements OnInit, OnDestroy {
    @Input() public title?: string = null;
    @Input() public customClass?: string = null;
    @Input() public image?: boolean = false;
    @Input() public position: ClickTooltipPositionType = 'bottomAuto';

    public bottomLeft: boolean = false;
    public bottomRight: boolean = false;
    public visible: boolean = false;

    private subscriptions: Subscription = new Subscription();
    private resize$ = this.eventListenerService.windowEvent<Event>('resize').pipe(debounceTime(0));

    constructor(
        private elRef: ElementRef,
        private eventListenerService: EventListenerService,
        @Inject(WINDOW) private window: Window,
        private changeDetectorRef: ChangeDetectorRef,
    ) {}

    public ngOnInit(): void {
        this.subscriptions.add(this.resize$.subscribe(() => this.onResize()));
        this.bottomRight = this.position === 'bottomRight';
        this.bottomLeft = this.position === 'bottomLeft';
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    public toggle(): void {
        this.visible = !this.visible;
        this.reposition();
    }

    public close(): void {
        this.visible = false;
    }

    private onResize(): void {
        this.reposition();
        this.changeDetectorRef.markForCheck();
    }

    private reposition(): void {
        if (this.visible && this.position === 'bottomAuto') {
            this.bottomLeft = false;
            this.bottomRight = false;
            const rect = this.elRef.nativeElement.getBoundingClientRect();
            const posX = rect.left + (rect.right - rect.left) / 2;
            this.bottomRight = posX < this.window.innerWidth / 2;
            this.bottomLeft = !this.bottomRight;
        }
    }
}
