import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DateService, DateStruct, NotificationInterface } from 'outshared-lib';
import { Subscription } from 'rxjs';
import { PremiumDeterminingFactorElementNameEnum } from './premium-determining-factor-element-name.enum';
import { PanelExpandableComponent } from '@app-de/shared/panel-expandable/panel-expandable.component';

@Component({
    selector: 'ins-premium-determining-factor',
    templateUrl: './premium-determining-factor.component.html',
    styleUrls: ['./premium-determining-factor.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PremiumDeterminingFactorComponent implements OnDestroy, OnInit {
    @Input() public premiumDeterminingFactor: any; /* PurchasedProductPremiumDeterminingFactorInterface; */
    @Input() public purchasedProductSequenceNumber: number;
    @Input() public validityStatusCode: string;
    @Input() public options: any[] /* ElementInhoudInterface[] */;
    @Input() public errors: NotificationInterface[];

    @Output() public readonly showModal: EventEmitter<string> = new EventEmitter();
    @Output() public readonly panelOpened: EventEmitter<string> = new EventEmitter();
    // TODO: add interace UpdateModifiablePremiumFactorPropsInterface
    @Output() public readonly updatePremiumDeterminingFactor: EventEmitter<any> = new EventEmitter();
    // TODO: add interface CancelFutureModifiablePremiumDeterminingFactorPropsInterface
    @Output() public readonly cancelPremiumDeterminingFactorFuture: EventEmitter<any> = new EventEmitter();

    @ViewChild(PanelExpandableComponent) public panel: PanelExpandableComponent;

    public form: UntypedFormGroup;
    public minDate: DateStruct = null;

    private subscriptions = new Subscription();

    constructor(private readonly dateService: DateService) {}

    public ngOnInit(): void {
        this.createForm(this.premiumDeterminingFactor.format_code);
        this.setMinimumDate();
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    public cancel(): boolean {
        this.cancelPremiumDeterminingFactorFuture.emit({
            purchased_product_sequence_number: this.purchasedProductSequenceNumber,
            premium_determining_factor_id: this.premiumDeterminingFactor.premium_determining_factor_id,
        });

        return false;
    }

    public opened(): void {
        const element = this.premiumDeterminingFactor.element_name;
        const premiumDeterminingFactorElementName = PremiumDeterminingFactorElementNameEnum[element];
        const elementName = premiumDeterminingFactorElementName
            ? premiumDeterminingFactorElementName
            : this.premiumDeterminingFactor.element_name;

        this.panelOpened.emit(elementName);
    }

    public onSubmit(): void {
        if (!this.form.valid) {
            return;
        }

        const premiumDeterminingFactorValueDescription = this.findPremiumDeterminingFactorValueDescription(
            this.premiumDeterminingFactorValue.value,
        );

        this.updatePremiumDeterminingFactor.emit({
            start_date: this.startDate.value,
            format_code: this.formatCode.value,
            premium_determining_factor_id: this.premiumDeterminingFactor.premium_determining_factor_id,
            premium_determining_factor_value: this.premiumDeterminingFactorValue.value,
            premium_determining_factor_value_description: premiumDeterminingFactorValueDescription,
        });
    }

    public openModal(id: string): boolean {
        this.showModal.emit(id);
        return false;
    }

    public resetForm(): void {
        this.form.reset();
    }

    private findPremiumDeterminingFactorValueDescription(premiumDeterminingFactorValue: string): string {
        const option = this.options.find((opt) => opt.inhoud_id === premiumDeterminingFactorValue);
        return option ? option.inhoud_omschrijving : '';
    }

    private createForm(formatCode: string): void {
        this.form = new UntypedFormGroup({
            premium_determining_factor_value: new UntypedFormControl('', [Validators.required]),
            start_date: new UntypedFormControl('', [Validators.required]),
            format_code: new UntypedFormControl(formatCode),
        });
    }

    private setMinimumDate(): void {
        this.minDate = this.dateService.toNgbDateStruct(this.dateService.add(1, 'day'));
    }

    public get startDate(): AbstractControl {
        return this.form.get('start_date');
    }

    public get premiumDeterminingFactorValue(): AbstractControl {
        return this.form.get('premium_determining_factor_value');
    }

    public get formatCode(): AbstractControl {
        return this.form.get('format_code');
    }
}
