import { Component } from '@angular/core';
import { AppRoutesEnum } from '@app-de/routes';

@Component({
    selector: 'ins-navigation-mobile',
    templateUrl: './navigation-mobile.component.html',
    styleUrls: ['./navigation-mobile.component.scss'],
})
export class NavigationMobileComponent {
    public active: boolean;
    public appRoutesEnum = AppRoutesEnum;

    public toggle(): boolean {
        this.active = !this.active;
        return false;
    }

    public close(): void {
        this.active = false;
    }
}
