import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WucSkeletonModule } from '../skeleton';
import { WucCircleIndicatorComponent } from './circle-indicator.component';

@NgModule({
    declarations: [WucCircleIndicatorComponent],
    imports: [CommonModule, WucSkeletonModule],
    exports: [WucCircleIndicatorComponent],
})
export class WucCircleIndicatorModule {}
