<h2>Meine Dokumente</h2>

<ins-list-flat [lined]="true">
    <li *ngIf="product?.validity_status_code === validityStatusCodeEnum.Active">
        <a class="link-flat link-flat--icon" (click)="getPolicy()" href="#" target="_blank">
            <svg class="svg-icon svg-icon--s">
                <use href="assets/icons-ui.svg#document"></use>
            </svg>
            Versicherungsschein
        </a>
    </li>
    <li>
        <a
            class="link-flat link-flat--icon"
            href="{{ documentsEnum.AllgemeineBedingungenAutoversicherung }}"
            target="_blank"
        >
            <svg class="svg-icon svg-icon--s">
                <use href="assets/icons-ui.svg#document"></use>
            </svg>
            Allgemeine Bedingungen zur Kraftfahrzeugversicherung (bis 31.12.2024)
        </a>
    </li>
    <li>
        <a
            class="link-flat link-flat--icon"
            href="{{ documentsEnum.AllgemeineBedingungenAutoversicherung_01_01_2025 }}"
            target="_blank"
        >
            <svg class="svg-icon svg-icon--s">
                <use href="assets/icons-ui.svg#document"></use>
            </svg>
            Allgemeine Bedingungen zur Kraftfahrzeugversicherung (ab 01.01.2025)
        </a>
    </li>
    <li>
        <a
            class="link-flat link-flat--icon"
            href="{{ documentsEnum.InformationsblattZuVersicherungsprodukten }}"
            target="_blank"
        >
            <svg class="svg-icon svg-icon--s">
                <use href="assets/icons-ui.svg#document"></use>
            </svg>
            Informationsblatt InShared-Versicherungsprodukte
        </a>
    </li>
    <li>
        <a class="link-flat link-flat--icon" href="{{ documentsEnum.SFClassesNoClaimDiscount }}" target="_blank">
            <svg class="svg-icon svg-icon--s">
                <use href="assets/icons-ui.svg#document"></use>
            </svg>
            SF-Klassen-Tabelle mit Schadenfreiheitsrabatt
        </a>
    </li>
    <li>
        <a class="link-flat link-flat--icon" href="{{ documentsEnum.SFClassesMotorVehicleLiability }}" target="_blank">
            <svg class="svg-icon svg-icon--s">
                <use href="assets/icons-ui.svg#document"></use>
            </svg>
            Rückstufungstabelle SF-Klassen Kfz-Haftpflicht
        </a>
    </li>
    <li>
        <a class="link-flat link-flat--icon" href="{{ documentsEnum.SFClassesFullyComprehensive }}" target="_blank">
            <svg class="svg-icon svg-icon--s">
                <use href="assets/icons-ui.svg#document"></use>
            </svg>
            Rückstufungstabelle SF-Klassen Vollkasko
        </a>
    </li>
    <li>
        <a class="link-flat link-flat--icon" href="{{ documentsEnum.VorvertraglicheAnzeigepflicht }}" target="_blank">
            <svg class="svg-icon svg-icon--s">
                <use href="assets/icons-ui.svg#document"></use>
            </svg>
            Vorvertragliche Anzeigepflicht
        </a>
    </li>
</ins-list-flat>
