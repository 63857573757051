<ins-details-table>
    <tr>
        <td>Name</td>
        <td data-hj-suppress>{{ relation.first_name }} {{ relation.surname }}</td>
    </tr>
    <tr>
        <td>Versicherungs- /Kundennummer</td>
        <td>{{ relation.relation_id }}</td>
    </tr>
    <tr *ngIf="relation.mobile_telephone_number?.telephone_number">
        <td>Handynummer</td>
        <td>{{ relation.mobile_telephone_number?.telephone_number }}</td>
    </tr>
    <!-- FIXME: [WIDL-1470] -->
    <tr class="hidden">
        <td>Festnetznummer</td>
        <td>{{ relation.landline_telephone_number?.telephone_number }}</td>
    </tr>
    <tr>
        <td>Geburtsdatum</td>
        <td>{{ relation.birthdate | dateFormat }}</td>
    </tr>
    <tr *ngIf="relation.driving_license_first_date_of_issue">
        <td>Datum des Führenscheiner werbs</td>
        <td>{{ relation.driving_license_first_date_of_issue | dateFormat }}</td>
    </tr>
</ins-details-table>
