import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DropdownToggleModule } from '../directives/dropdown/dropdown.module';
import { LanguageDropdownComponent } from './language-dropdown.component';

@NgModule({
    declarations: [LanguageDropdownComponent],
    imports: [
        CommonModule,
        DropdownToggleModule,
    ],
    exports: [LanguageDropdownComponent],
})
export class LanguageDropdownModule {}
