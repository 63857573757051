import { Action } from '@ngrx/store';
import { BreadCrumbInterface } from '@app-de/core/interfaces/bread-crumb.interface';

export const SET_BREADCRUMB = '[Breadbrumb] Set';
export const RESET_PANEL = '[Panel] Reset';
export const HIDE_MOBILE_MENU = '[Mobile menu] Hide';
export const SHOW_MOBILE_MENU = '[Mobile menu] Show';
export const HIDE_MAIN_MENU = '[Main menu] Hide';
export const SHOW_MAIN_MENU = '[Main menu] Show';

export class SetBreadcrumb implements Action {
    public readonly type = SET_BREADCRUMB;
    constructor(public payload: BreadCrumbInterface[]) {}
}

export class HideMobileMenu implements Action {
    public readonly type = HIDE_MOBILE_MENU;
}

export class ShowMobileMenu implements Action {
    public readonly type = SHOW_MOBILE_MENU;
}

export class HideMainMenu implements Action {
    public readonly type = HIDE_MAIN_MENU;
}

export class ShowMainMenu implements Action {
    public readonly type = SHOW_MAIN_MENU;
}

export type ActionTypes = SetBreadcrumb | HideMobileMenu | ShowMobileMenu | HideMainMenu | ShowMainMenu;
