import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import {
    ContentInterface,
    ContentService,
    ErrorInterface,
    ModifyFamilyCompositionInterface,
    RelationActionService,
    RelationDataService,
    RelationInterface,
} from 'outshared-lib';
import { Observable, Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';

const FAMILY_COMPOSITION_CONTENT_NAME = 'gezinssamenstelling_code';

@Component({
    selector: 'ins-family-composition-form-container',
    template: `
        <ins-family-composition-form
            [relation]="relation$ | async"
            [familyCompositions]="familyCompositions$ | async"
            [errors]="errors"
            [loading]="loading"
            (submitted)="onSubmit($event)"
        ></ins-family-composition-form>
    `,
})
export class FamilyCompositionFormContainer implements OnDestroy {
    public relation$: Observable<RelationInterface>;
    public familyCompositions$: Observable<ContentInterface[]>;
    public loading: boolean;
    public errors: ErrorInterface[];

    private subscriptions: Subscription = new Subscription();

    constructor(
        private router: Router,
        private relationActionService: RelationActionService,
        private relationDataService: RelationDataService,
        private contentService: ContentService,
    ) {
        this.relation$ = this.relationDataService.getRelation$().pipe(
            filter((relation: RelationInterface) => !!relation),
            take(1),
        );

        this.familyCompositions$ = this.contentService.getContent$(FAMILY_COMPOSITION_CONTENT_NAME);

        this.loading = false;
        this.errors = [];
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    public onSubmit(request: ModifyFamilyCompositionInterface): void {
        const modifyFamilyComposition = this.relationActionService.modifyFamilyComposition$(request).subscribe({
            next: () => this.onModifyFamilyCompositionSuccess(),
            error: (errors) => this.onModifyFamilyCompositionError(errors),
        });

        this.loading = true;
        this.errors = [];
        this.subscriptions.add(modifyFamilyComposition);
    }

    private onModifyFamilyCompositionSuccess(): void {
        this.errors = [];
        this.loading = false;
        this.router.navigate([], {
            queryParams: {
                panel: null,
                scrollTo: null,
                expand: null,
            },
            queryParamsHandling: 'merge',
        });
    }

    private onModifyFamilyCompositionError(errors: ErrorInterface[]): void {
        this.loading = false;
        this.errors = errors;
    }
}
