export enum CarInsuranceRoutesEnum {
    AllRisk = 'all-risk-autoverzekering',
    BestCarInsurance = 'beste-autoverzekering',
    Brands = 'automerken',
    Calculate = 'berekenen',
    CalculateCarInsurance = 'berekenen-autoverzekering',
    CompareAllRisk = 'vergelijken-allrisk-autoverzekering',
    CompareCarInsurance = 'vergelijken-autoverzekering',
    CompareLiabilityCarInsurance = 'vergelijken-wa-autoverzekering',
    EVInsurance = 'elektrische-auto-verzekeren',
    Home = '',
    Liability = 'wa-autoverzekering',
    LicensePlate = 'kentekencard',
    LimitedCasco = 'beperkt-casco-autoverzekering',
    LimitedCascoExtra = 'beperkt-casco-extra-autoverzekering',
    NoClaimYears = 'schadevrije-jaren-autoverzekering',
    Passengers = 'inzittendenverzekering',
    ProveNoClaimYears = 'aantonen-schadevrije-jaren',
    Reviews = 'reviews',
    RoadsideAssistance = 'pechhulp',
}
