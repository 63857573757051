<ul class="list-flat" [ngClass]="{
    'list-flat--svg' : svg,
    'list-flat--usps' : usp,
    'list-flat--expanded' : expanded,
    'list-flat--numbered' : numbered,
    'list-flat--lined' : lined,
    'list-flat--table' : table
    }">
    <ng-content></ng-content>
</ul>
