<div class="insurance-driver-future">
    <table
        class="table-data"
        *ngIf="
            product?.non_regular_driver_future &&
            product?.validity_status_code !== apiValidityStatusCodeEnum.Transfer &&
            product?.validity_status_code !== apiValidityStatusCodeEnum.Future
        "
    >
        <colgroup>
            <col width="33%" />
            <col width="33%" />
            <col width="33%" />
        </colgroup>
        <tbody>
            <tr>
                <td>Künftiger Hauptfahrer</td>
                <td>
                    <span>
                        <span *ngIf="product?.non_regular_driver?.gender_code !== apiGenderCodeEnum.Neutral">
                            {{ product?.non_regular_driver?.gender_description }}
                        </span>
                        {{ product?.non_regular_driver_future?.first_name }}
                        {{ product?.non_regular_driver_future?.surname }}
                        <br />
                    </span>
                    <small *ngIf="product?.non_regular_driver_future?.start_date">
                        (ab {{ product?.non_regular_driver_future?.start_date | dateFormat }})
                        <br />
                    </small>
                </td>
                <td class="insurance-driver-future__button">
                    <wuc-link
                        *ngIf="isProductActive"
                        type="secondary"
                        iconRight="cross-closed"
                        iconSize="xs"
                        (click)="cancelDriver($event)"
                    >
                        Löschen
                    </wuc-link>
                </td>
            </tr>
        </tbody>
    </table>
    <wuc-form-error *ngIf="errors && errors.length" [errorMessages]="errors"></wuc-form-error>
</div>
