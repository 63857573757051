import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TabComponent } from './tab.component';
import { TabsetComponent } from './tabset.component';

@NgModule({
    declarations: [
        TabsetComponent,
        TabComponent,
    ],
    imports: [CommonModule],
    exports: [
        TabsetComponent,
        TabComponent,
    ],
})
export class TabsetModule {}
