import { UrlSegment } from '@angular/router';
import { SalesfunnelRoutes } from '@app-de/salesfunnel/routing/salesfunnel-routing.routes';

export function salesfunnelUrlMatcher(url: UrlSegment[]): null | { consumed: UrlSegment[] } {
    if (url.length > 0 && url[0].path === SalesfunnelRoutes.Activate) {
        return {
            consumed: [],
        };
    }

    if (url.length > 0 && url[0].path === SalesfunnelRoutes.Thanks) {
        return {
            consumed: [],
        };
    }

    if (url.length < 2) {
        return null;
    }

    const regexA = new RegExp(`berechnen-${url[0]}`, 'g');
    const regexB = new RegExp(`${url[0]}-berechnen`, 'g');

    if (url[1].path.match(regexA) || url[1].path.match(regexB)) {
        return {
            consumed: [url[0]],
        };
    }

    return null;
}
