import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DateService, ErrorInterface, ProductBaseInterface } from 'outshared-lib';

@Component({
    selector: 'ins-change-start-date-insurance',
    templateUrl: './change-start-date-insurance.component.html',
})
export class ChangeStartDateInsuranceComponent {
    @Output() public readonly destroyed: EventEmitter<void> = new EventEmitter();
    @Output() public readonly submitted: EventEmitter<{
        startDate: string;
        productSequenceNumber: number;
    }> = new EventEmitter();

    @Input() public errors: ErrorInterface[];
    @Input() public product: ProductBaseInterface;

    public form: UntypedFormGroup;
    public minDate: string;

    constructor(private readonly dateService: DateService) {
        this.createForm();
        this.setMinimumDate();
    }

    public get startDate(): AbstractControl {
        return this.form.get('startDate');
    }

    public onSubmit(): void {
        if (!this.form.valid) {
            return;
        }

        this.submitted.emit({
            productSequenceNumber: this.product.purchased_product_sequence_number,
            startDate: this.form.value.startDate,
        });
    }

    private createForm(): void {
        this.form = new UntypedFormGroup({
            startDate: new UntypedFormControl('', [Validators.required]),
        });
    }

    private setMinimumDate(): void {
        this.minDate = this.dateService.add(1, 'day');
    }
}
