<div class="alert" [ngClass]="['alert-' + type, customClass]" *ngIf="notifications.length > 0">
    <ng-container
        [ngTemplateOutlet]="type === 'success' ? notificationTemplate : multipleNotificationsTemplate"
        [ngTemplateOutletContext]="{ notification: notifications[0] }"
    ></ng-container>
</div>

<ng-template #notificationTemplate let-notification="notification">
    <svg *ngIf="iconMap.get(type)" class="svg-icon svg-icon--xs" [class.svg-icon--dark]="type !== 'success'">
        <use [attr.href]="'assets/icons-ui.svg#' + iconMap.get(type)"></use>
    </svg>
    <span [innerHTML]="isString(notification) ? notification : notification.message"></span>
</ng-template>

<ng-template #multipleNotificationsTemplate>
    <ul>
        <li *ngFor="let notification of notifications">
            <ng-container
                [ngTemplateOutlet]="notificationTemplate"
                [ngTemplateOutletContext]="{ notification: notification }"
            ></ng-container>
        </li>
    </ul>
</ng-template>
