import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
    ChangeStartDateInsuranceComponent,
    ChangeStartDateInsuranceContainer,
} from '@app-de/my-zone/shared/change-start-date-insurance';
import { DetailsSubmitButtonModule } from '@app-de/my-zone/shared/details-submit-button/details-submit-button.module';
import { CynoFormModule } from '@app-de/shared/cyno-form';
import { DatepickerModule } from '@app-de/shared/datepicker/datepicker.module';
import { PanelExpandableModule } from '@app-de/shared/panel-expandable/panel-expandable.module';

@NgModule({
    declarations: [ChangeStartDateInsuranceContainer, ChangeStartDateInsuranceComponent],
    exports: [ChangeStartDateInsuranceContainer, ChangeStartDateInsuranceComponent],
    imports: [CommonModule, CynoFormModule, DatepickerModule, DetailsSubmitButtonModule, PanelExpandableModule],
})
export class ChangeStartDateInsuranceModule {}
