<div
    class="ins-chatbot-entry"
    [class.ins-chatbot-entry--secondary]="isSecondary"
    [class.chatbot-entry--hide-mobile]="hideMobile"
>
    <wuc-chatbot-mini
        buttonText="Senden"
        placeholder="Stellen Sie hier Ihre Frage..."
        [isOnDarkBackground]="isOnDarkBackground"
        [maxNumberOfCharacters]="150"
        maxNumberOfCharactersText="(max. 150 Zeichen)"
        [onlyEmitOpen]="isSecondary"
        (question)="onQuestion($event)"
        (userOpenClick)="onOpen()"
    >
        <div
            slot="intro"
            class="ins-chatbot-entry__intro"
        >
            <ng-container *ngIf="!relation">Hallo, ich bin der Chatbot von InShared und weiß (fast) alles. Ihre Frage?</ng-container>

            <ng-container *ngIf="relation">
                Hallo,
                <ng-container [ngSwitch]="relation.gender_code">
                    <ng-container *ngSwitchCase="'M'">Herr&#32;</ng-container>
                    <ng-container *ngSwitchCase="'V'">Frau&#32;</ng-container>
                </ng-container>
                <span data-hj-suppress>{{ relation.middle_name_initials }} {{ relation.surname }}.</span>
                ich bin der Chatbot von InShared und weiß (fast) alles. Ihre Frage?
            </ng-container>
        </div>
    </wuc-chatbot-mini>
</div>
