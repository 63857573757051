<form (ngSubmit)="onFormSubmit()" [formGroup]="form">
    <cyno-field [inline]="true">
        <cyno-label>Dürfen wir Ihnen weitere Information und Angebote schicken?</cyno-label>
        <cyno-radio [value]="indication.No" formControlName="optOutIndication" e2e="opt-in-yes">Ja</cyno-radio>
        <cyno-radio [value]="indication.Yes" formControlName="optOutIndication" e2e="opt-out-no">Nein</cyno-radio>
    </cyno-field>

    <cyno-alert *ngIf="errors" [notifications]="errors" type="error"></cyno-alert>
    <ins-details-submit-button [disabled]="disabled">Speichern</ins-details-submit-button>
</form>
