<!-- Active product with: deviant driver as current regular driver -->
<table class="table-data" *ngIf="isActiveProduct && hasRegularDriver">
    <colgroup>
        <col width="33%" />
        <col width="33%" />
        <col width="33%" />
    </colgroup>
    <tbody>
        <tr>
            <td>Hauptfahrer</td>
            <td>{{ regularDriverName }}</td>
            <td *ngIf="canAddRegularDriver" class="text-right">
                <!-- TODO: WIDL-1793 - Enable: remove deviant driver and set policy holder as regular driver -->
                <ins-panel-button
                    class="hidden"
                    [target]="panel"
                    openText="Ändern"
                    closeText="Schließen"
                ></ins-panel-button>
            </td>
        </tr>
    </tbody>
</table>
<!-- Active product with: policy holder as current regular driver -->
<table class="table-data" *ngIf="isActiveProduct && isPolicyHolder">
    <colgroup>
        <col width="33%" />
        <col width="33%" />
        <col width="33%" />
    </colgroup>
    <tbody>
        <tr>
            <td>Hauptfahrer</td>
            <td>Ich</td>
            <!-- Disabled for WIDL-2081 -->
            <!-- <td *ngIf="canAddRegularDriver" class="text-right"> -->
            <td *ngIf="false" class="text-right">
                <ins-panel-button [target]="panel" openText="Ändern" closeText="Schließen"></ins-panel-button>
            </td>
        </tr>
    </tbody>
</table>
<!-- Future product with: deviant driver as current regular driver -->
<table class="table-data" *ngIf="isFutureProduct && hasRegularDriverFuture">
    <colgroup>
        <col width="33%" />
        <col width="33%" />
        <col width="33%" />
    </colgroup>
    <tbody>
        <tr>
            <td>Hauptfahrer</td>
            <td>{{ regularDriverFutureName }}</td>
        </tr>
    </tbody>
</table>
<!-- Future product with: policy holder as current regular driver -->
<table class="table-data" *ngIf="isFutureProduct && isPolicyHolder">
    <colgroup>
        <col width="33%" />
        <col width="33%" />
        <col width="33%" />
    </colgroup>
    <tbody>
        <tr>
            <td>Hauptfahrer</td>
            <td>Ich</td>
        </tr>
    </tbody>
</table>
