import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NewModalModule } from 'outshared-lib';
import { PassportComponent } from './passport.component';
import { PassportContainer } from './passport.container';
import { ListModule } from '@app-de/shared/lists/list.module';
import { CapitalizeModule } from '@app-de/shared/pipes/capitalize/capitalize.module';

@NgModule({
    declarations: [
        PassportComponent,
        PassportContainer,
    ],
    imports: [
        CommonModule,
        RouterModule,
        ListModule,
        NewModalModule,
        CapitalizeModule,
    ],
    exports: [PassportContainer],
})
export class PassportModule {}
