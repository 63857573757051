import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { animationFrameScheduler, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, pairwise, startWith, throttleTime } from 'rxjs/operators';
import { BreakPointsEnum } from '../enums/breakpoints.enum';
import { EventListenerService } from './event-listener.service';
import { IScrollData, ScrollDirection } from '@app-de/core/services/scroll-data';
import { WINDOW } from '@inshared/shared/util';

@Injectable({
    providedIn: 'root',
})
export class IntersectionObserverService {
    // TODO: use intersection observer api - https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
    public screenSizeChanged$: Observable<boolean> = this.eventListenerService.windowEvent<Event>('resize').pipe(
        debounceTime(500),
        map(() => this.document.body.offsetWidth < BreakPointsEnum.ScreenMdMin),
    );

    public isScreenSmall$!: Observable<boolean>;
    public isScreenMedium$: Observable<boolean>;
    public isScreenLarge$: Observable<boolean>;

    public isScrolledUp$: Observable<boolean> = this.eventListenerService.windowEvent<Event>('scroll').pipe(
        throttleTime(0, animationFrameScheduler),
        map(() => this.window.pageYOffset),
        pairwise(),
        map(([y1, y2]) => y2 < y1),
        distinctUntilChanged(),
    );

    public size$: Observable<string> = this.eventListenerService.windowEvent<Event>('resize').pipe(
        debounceTime(500),
        startWith(this.document.body.offsetWidth),
        map(() => (this.document.body.offsetWidth < BreakPointsEnum.ScreenMdMin ? 'small' : 'large')),
    );

    public currentBreakPoint$: Observable<BreakPointsEnum> = this.eventListenerService
        .windowEvent<Event>('resize')
        .pipe(
            debounceTime(200),
            startWith(this.document.body.offsetWidth),
            map(() => {
                const width = this.document.body.offsetWidth;
                if (width >= BreakPointsEnum.ScreenXlMin) {
                    return BreakPointsEnum.ScreenXlMin;
                }
                if (width >= BreakPointsEnum.ScreenLgMin) {
                    return BreakPointsEnum.ScreenLgMin;
                }
                if (width >= BreakPointsEnum.ScreenMdMin) {
                    return BreakPointsEnum.ScreenMdMin;
                }
                return BreakPointsEnum.ScreenSmMin;
            }),
        );

    public onScroll$: Observable<IScrollData> = this.eventListenerService.windowEvent<Event>('scroll').pipe(
        throttleTime(0, animationFrameScheduler),
        map(() => this.window.pageYOffset),
        pairwise(),
        map(([y1, y2]) => (y2 < y1 ? ScrollDirection.Up : ScrollDirection.Down)),
        map((direction) => ({
            direction,
            percentage: Math.round(
                (this.window.pageYOffset / (this.document.body.offsetHeight - this.window.innerHeight)) * 100,
            ),
        })),
        distinctUntilChanged(),
        debounceTime(100),
    );

    public constructor(
        @Inject(WINDOW) private window: Window,
        @Inject(DOCUMENT) private document: Document,
        private eventListenerService: EventListenerService,
    ) {
        this.isScreenSmall$ = this.screenSizeChanged$.pipe(
            startWith(this.document.body.offsetWidth < BreakPointsEnum.ScreenMdMin),
        );
        this.isScreenMedium$ = this.screenSizeChanged$.pipe(
            startWith(this.document.body.offsetWidth <= BreakPointsEnum.ScreenTabletMin),
            map(() => this.document.body.offsetWidth <= BreakPointsEnum.ScreenTabletMin),
        );
        this.isScreenLarge$ = this.isScreenSmall$.pipe(map((small) => !small));
    }
}
