import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CynoSelect } from './cyno-select.component';

@NgModule({
    declarations: [CynoSelect],
    imports: [CommonModule],
    exports: [CynoSelect],
})
export class CynoSelectModule {}
