import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CardContentComponent } from './card-content.component';
import { CardHeadComponent } from './card-head.component';
import { CardImageComponent } from './card-image.component';
import { CardRackComponent } from './card-rack.component';
import { CardComponent } from './card.component';
import { CardFooterComponent } from '@app-de/shared/card/card-footer.component';

@NgModule({
    imports: [CommonModule],
    declarations: [
        CardComponent,
        CardHeadComponent,
        CardContentComponent,
        CardImageComponent,
        CardFooterComponent,
        CardRackComponent,
    ],
    exports: [
        CardComponent,
        CardHeadComponent,
        CardContentComponent,
        CardFooterComponent,
        CardImageComponent,
        CardRackComponent,
    ],
})
export class CardModule {}
