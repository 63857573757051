import { Component, Input } from '@angular/core';
import {
    WucButtonModule,
    WucIconUiModule,
    WucModalModule
} from '@inshared/website-ui-components';
import {  NgIf } from '@angular/common';
import { NewModalModule } from 'outshared-lib';

@Component({
    standalone: true,
    selector: 'ins-cookie-settings',
    templateUrl: 'cookie-settings.component.html',
    styleUrls: ['./cookie-settings.component.scss'],
    imports: [
        WucButtonModule,
        WucModalModule,
        NgIf,
        WucIconUiModule,
        NewModalModule,
    ]
})
export class CookieSettingsComponent {
    @Input() hideClose = false;

}
