import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AlertComponent } from '@app-de/components/alert/alert.component';
import { SanitizePipeModule } from '@app-de/shared/pipes/sanitize/sanitize.module';

@NgModule({
    imports: [CommonModule, SanitizePipeModule],
    declarations: [AlertComponent],
    exports: [AlertComponent],
})
export class AlertModule {}
