<div class="datepicker">
    <input
        class="form-control datepicker__form-control"
        placeholder="{{ pickadayConfig.placeholder }}"
        type="text"
        e2e="address-date"
        #datepicker
        cynoInput
        autocomplete="off"
        readonly
    />
    <div class="datepicker__icon">
        <svg class="svg-icon svg-icon--s"><use href="assets/icons-ui.svg#kalender"></use></svg>
    </div>
</div>
