<ins-details-table>
    <tr *ngIf="!relation?.mailing_address">
        <td colspan="2">Die Postadresse stimmt mit der Wohnadresse überein.</td>
    </tr>
    <tr *ngIf="relation?.mailing_address">
        <td>Postadresse</td>
        <td>
            <span>{{ relation.mailing_address.address.street_name }}</span>
            <span>{{ relation.mailing_address.civic_number }}{{ relation.mailing_address.civic_number_suffix }},</span>
            <!-- prettier-ignore -->
            <span>{{ relation.mailing_address.postal_code }} </span>
            <span>{{ relation.mailing_address.address.city }}</span>
        </td>
    </tr>
</ins-details-table>
