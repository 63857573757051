<li class="insurance-control-item">
    <button
        class="btn btn--secondary insurance-control-item__btn"
        (click)="clickButton(button)"
        [disabled]="disabled"
        [id]="'zelf_regelen-' + button?.id"
    >
        <span class="btn__icon">
            <svg class="svg-icon svg-icon--xl">
                <use [attr.href]="'assets/icons-general.svg#' + button?.icon"></use>
            </svg>
        </span>
        <span class="btn__text insurance-control-item__btn-text"><ng-content></ng-content></span>
    </button>
</li>
