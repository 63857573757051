<wuc-card borderWidth="none" padding="large">
    <h3>Versicherung kündigen</h3>
    <p>Warum möchten Sie kündigen?</p>
    <form [formGroup]="form" *ngIf="product">
        <wuc-input-radio formControlName="reasonCode" [value]="terminateReasonEnum.Deregistration">
            Abmeldung
        </wuc-input-radio>
        <wuc-card
            background="dark"
            borderWidth="none"
            padding="medium"
            *ngIf="reasonCode.value === terminateReasonEnum.Deregistration"
        >
            <p>
                Wählen Sie diese Option, wenn das Fahrzeug nicht mehr auf Ihren Namen zugelassen ist. Oder wenn es -
                beispielsweise nach Verkauf - einen neuen Fahrzeughalter gibt.
            </p>
        </wuc-card>
        <wuc-input-radio formControlName="reasonCode" [value]="terminateReasonEnum.ChangeOfInsurer">
            Versicherungswechsel
        </wuc-input-radio>
        <wuc-card
            background="dark"
            borderWidth="none"
            padding="medium"
            *ngIf="reasonCode.value === terminateReasonEnum.ChangeOfInsurer"
        >
            <p *ngIf="reasonCode.value === terminateReasonEnum.ChangeOfInsurer">
                Wählen Sie diese Option, wenn Sie die Versicherung wechseln möchten. Da Sie bei uns täglich kündigen
                können, benötigen wir auch direkt die Angabe Ihres zukünftigen Versicherungsunternehmens sowie der neuen
                Versicherungsnummer.
            </p>
        </wuc-card>
        <wuc-input-radio formControlName="reasonCode" [value]="terminateReasonEnum.AfterAClaim">
            Schadensfall
        </wuc-input-radio>
        <wuc-card
            background="dark"
            borderWidth="none"
            padding="medium"
            *ngIf="reasonCode.value === terminateReasonEnum.AfterAClaim"
        >
            <p *ngIf="reasonCode.value === terminateReasonEnum.AfterAClaim">
                Wählen Sie diese Option, wenn Sie Ihre InShared-Versicherung nach einem Schadensfall kündigen möchten.
                Da Sie bei uns täglich kündigen können, benötigen wir auch direkt die Angabe Ihres zukünftigen
                Versicherungsunternehmens sowie der neuen Versicherungsnummer.
            </p>
        </wuc-card>
        <wuc-input-radio formControlName="reasonCode" [value]="terminateReasonEnum.DecommissioningWithSuspension">
            Außerbetriebsetzung mit Ruheversicherung
        </wuc-input-radio>
        <wuc-card
            background="dark"
            borderWidth="none"
            padding="medium"
            *ngIf="reasonCode.value === terminateReasonEnum.DecommissioningWithSuspension"
        >
            <p>
                Wählen Sie diese Option, wenn Sie bei der Zulassungsstelle eine Außerbetriebsetzung beantragt haben. Für
                die Wiederzulassung brauchen Sie eine neue eVB-Nummer.
            </p>
        </wuc-card>
        <wuc-input-radio formControlName="reasonCode" [value]="terminateReasonEnum.PremiumIncrease">
            Beitragserhöhung
        </wuc-input-radio>
        <wuc-card
            background="dark"
            borderWidth="none"
            padding="medium"
            *ngIf="reasonCode.value === terminateReasonEnum.PremiumIncrease"
        >
            <p>Wählen Sie diese Option, wenn Sie mit der Beitragsanpassung nicht einverstanden sind.</p>
        </wuc-card>
        <wuc-input-radio formControlName="reasonCode" [value]="terminateReasonEnum.ChangeOfTermsAndConditions">
            Änderung der Versicherungsbedingungen
        </wuc-input-radio>
        <wuc-card
            background="dark"
            borderWidth="none"
            padding="medium"
            *ngIf="reasonCode.value === terminateReasonEnum.ChangeOfTermsAndConditions"
        >
            <p>
                Wählen Sie diese Option, wenn Sie Ihre Versicherung wegen der geänderten Versicherungsbedingungen
                kündigen möchten. Jedes Jahr überprüfen wir unsere Produkte und verbessern sie, wenn möglich. Manchmal
                müssen wir die Deckung etwas anpassen, um auch in Zukunft genügend Reserve für Schadensfälle zu haben.
            </p>
        </wuc-card>
        <wuc-input-radio formControlName="reasonCode" [value]="terminateReasonEnum.InsureSameCarAgain">
            Neue InShared-Versicherung für dasselbe Auto
        </wuc-input-radio>
        <wuc-card
            background="dark"
            borderWidth="none"
            padding="medium"
            *ngIf="reasonCode.value === terminateReasonEnum.InsureSameCarAgain"
        >
            <p>
                Wählen Sie diese Option, wenn Sie die Versicherung für dasselbe Auto erneut bei InShared beantragen
                möchten oder diese bereits beantragt haben. Die neue Versicherung soll die alte ersetzen.
            </p>
        </wuc-card>
        <!-- WIDL-2022: added feature toggle on option 'Other' -->
        <div class="hidden">
            <wuc-input-radio formControlName="reasonCode" [value]="terminateReasonEnum.Other">
                Anderer Grund
            </wuc-input-radio>
            <wuc-card
                background="dark"
                borderWidth="none"
                padding="medium"
                *ngIf="reasonCode.value === terminateReasonEnum.Other"
            >
                <!-- prettier-ignore -->
                <p>
                    Ihr Kündigungsgrund ist nicht dabei? Dann senden Sie eine E-Mail an
                    <wuc-link url="mailto:kontakt&#64;inshared.de">kontakt&#64;inshared.de</wuc-link>.
                    Bitte nennen Sie uns Ihren Kündigungsgrund und den letzten Tag, an dem Sie versichert sein möchten.
                    Wir werden Ihre Kündigung prüfen und uns wieder bei Ihnen melden.
                </p>
            </wuc-card>
        </div>
        <hr />
        <p>
            Solange das Auto unter diesem Kennzeichen auf Sie zugelassen ist, ist der Abschluss einer Kfz-Haftpflicht
            gesetzlich verpflichtend. Achten Sie bitte darauf, dass Sie nicht unversichert fahren.
        </p>
        <wuc-form-field *ngIf="reasonCode.value !== terminateReasonEnum.Other" [hasNoState]="true">
            <wuc-input-checkbox name="checkbox" formControlName="confirmation">
                Klar, ich werde daran denken.
            </wuc-input-checkbox>
        </wuc-form-field>
        <wuc-form-field *ngIf="canTerminate">
            <wuc-form-field-label>Wann soll der letzte Versicherungstag sein?</wuc-form-field-label>
            <!-- No Fixed enddate -->
            <wuc-input-date-picker
                *ngIf="!isFixedEndDate"
                [minDate]="today"
                formControlName="endDate"
            ></wuc-input-date-picker>
            <!-- Fixed enddate -->
            <wuc-input-read-only *ngIf="isFixedEndDate">
                {{ endDate.value | dateFormat : 'DD.MM.YYYY' }}
            </wuc-input-read-only>
        </wuc-form-field>
        <wuc-form-field *ngIf="canTerminate && hasExtraInfo">
            <wuc-form-field-label>Zukünftige Versicherung</wuc-form-field-label>
            <wuc-select formControlName="nextInsuranceCompanyId" placeholder="Vorversicherer wählen">
                <wuc-select-option *ngFor="let company of companies$ | async" [value]="company.insurance_company_id">
                    {{ company.name }}
                </wuc-select-option>
            </wuc-select>
        </wuc-form-field>
        <wuc-form-field *ngIf="canTerminate && hasExtraInfo">
            <wuc-form-field-label>Neue Versicherungsnummer</wuc-form-field-label>
            <wuc-input-text formControlName="nextInsuranceCompanyExternalReferenceId"></wuc-input-text>
        </wuc-form-field>
        <div class="terminate-insurance-error" *ngIf="canTerminate && errorMessages?.length">
            <wuc-form-error [errorMessages]="errorMessages"></wuc-form-error>
        </div>
        <div class="terminate-insurance-button" *ngIf="canTerminate">
            <wuc-button (click)="onSubmit()" [disabled]="loading" [isFullWidth]="isScreenSmall$ | async">
                Kündigen
            </wuc-button>
        </div>
    </form>
</wuc-card>
