<div
    class="alert"
    [ngClass]="'alert--' + type"
    [class.alert--center]="position === 'center'"
    [class.alert--right]="position === 'right'"
    [class.alert--no-arrow]="position === 'none'"
>
    <ng-content select="[slot=top]"></ng-content>
    <ng-content></ng-content>
    <ul>
        <li *ngFor="let error of errors">
            <svg class="alert__icon svg-icon svg-icon--xs">
                <use href="assets/icons-ui.svg#cross-closed"></use>
            </svg>
            <span [innerHTML]="error.message | sanitize"></span>
        </li>
    </ul>
    <ng-content select="[slot=bottom]"></ng-content>
</div>
