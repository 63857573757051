import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { DateService } from 'outshared-lib';
import { invalidPipeArgumentError } from './invalid_pipe_argument_error';
import { DatesEnum } from '@app-de/core/enums/dates.enum';

@Pipe({
    name: 'dateFormat',
    pure: true,
})
export class DateFormatPipe implements PipeTransform {
    constructor(@Inject(LOCALE_ID) private locale: string, private readonly dateService: DateService) {}

    public transform(value: any, format: string = 'DD.MM.YYYY', text: string = 'unbekannt'): string | null {
        if (value == null || value === '' || value !== value) {
            return null;
        }

        if (value === DatesEnum.NoDate) {
            value = text;
            return value;
        }

        try {
            return this.dateService.format(value, format, this.locale);
        } catch (error) {
            throw invalidPipeArgumentError(DateFormatPipe, error.message);
        }
    }
}
