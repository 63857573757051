import {Component, inject, OnInit} from '@angular/core';
import {ProductBaseInterface, ProductSelectorService} from "outshared-lib";
import {take} from "rxjs/operators";
import {Observable} from "rxjs";
import {AsyncPipe, NgIf} from "@angular/common";
import {PanelExpandableModule} from "@app-de/shared/panel-expandable/panel-expandable.module";
import {DateFormatPipeModule} from "@app-de/shared/pipes/date-format/date-format.module";

@Component({
    standalone: true,
    selector: 'ins-insurance-additional-driver',
    templateUrl: './insurance-additional-driver.component.html',
    imports: [
        AsyncPipe,
        NgIf,
        PanelExpandableModule,
        DateFormatPipeModule
    ]
})
export class InsuranceAdditionalDriverComponent implements OnInit {

    private productSelectorService: ProductSelectorService = inject(ProductSelectorService);
    public selectedProduct: Observable<ProductBaseInterface>


    public  ngOnInit() {
        this.selectedProduct =  this.productSelectorService.getSelectedProduct$
            .pipe(take(1));
    }

}
