<ins-list-group *ngIf="modulesFuture">
    <li class="coverage-overview" *ngFor="let module of modulesFuture">
        <div class="coverage-overview__wrapper">
            <div class="coverage-overview__row">
                <span class="coverage-overview__description">{{ module.coverage_description }}</span>
                <span class="coverage-overview__info">
                    <span [ngSwitch]="!panel.visible" *ngIf="module?.body || module?.tabs">
                        <span *ngSwitchCase="false">
                            <a href="#" (click)="panel.close()">
                                <svg class="svg-icon svg-icon--xs">
                                    <use href="assets/icons-ui.svg#informatie"></use>
                                </svg>
                                inklappen
                            </a>
                        </span>
                        <span *ngSwitchDefault>
                            <a href="#" (click)="panel.open()">
                                <svg class="svg-icon svg-icon--xs">
                                    <use href="assets/icons-ui.svg#informatie"></use>
                                </svg>
                                meer info
                            </a>
                        </span>
                    </span>
                </span>
                <ng-container *ngIf="module.premium">
                    <span class="coverage-overview__price" *ngIf="module.premium.premium_including_premium_tax">
                        {{ module.premium.premium_including_premium_tax | number : '1.2-2' }}
                    </span>
                    <span class="coverage-overview__price" *ngIf="!module.premium.premium_including_premium_tax">
                        Inbegriffen
                    </span>
                </ng-container>
            </div>
            <ins-panel-expandable [border]="false" direction="left" #panel="panel">
                <div *ngIf="module.body" [innerHTML]="module.body"></div>
                <ng-container *ngIf="module.tabs">
                    <ins-tabset>
                        <ins-tab
                            *ngFor="let tab of module.tabs; first as isFirst"
                            [title]="tab.title"
                            [active]="isFirst"
                        >
                            <div [innerHTML]="tab.body"></div>
                        </ins-tab>
                    </ins-tabset>
                </ng-container>
            </ins-panel-expandable>
        </div>
    </li>
</ins-list-group>
<ins-list-flat class="coverage-overview-summary">
    <ng-container *ngIf="totalFutureDiscount">
        <li class="coverage-overview">
            <span class="coverage-overview__description">Subtotaal</span>
            <span class="coverage-overview__price">{{ totalFuture | number : '1.2-2' }}</span>
        </li>
        <li class="coverage-overview" *ngIf="calculation.product.offer">
            <span class="coverage-overview__description">{{ calculation.product.offer.description }}</span>
            <span class="coverage-overview__price">{{ -totalFutureDiscount | number : '1.2-2' }}</span>
        </li>
    </ng-container>
    <li class="coverage-overview coverage-overview-summary__total">
        <span class="coverage-overview__description">
            {{ isYearlyPayment ? 'Ihr Jahresbeitrag' : 'Ihr Monatsbeitrag' }}</span
        >

        <span class="coverage-overview__price" e2e="total-premium">
            {{ totalFuture | number : '1.2-2' }}
        </span>
    </li>
</ins-list-flat>
