import { Component, Input } from '@angular/core';
import { CoverageIdEnum } from '@app-de/core/enums/coverage-id.enum';
import { DocumentsEnum } from '@app-de/core/enums/documents.enum';

@Component({
    selector: 'ins-car-additional-coverage-info',
    templateUrl: './car-additional-coverage-info.component.html',
})
export class CarAdditionalCoverageInfoComponent {
    @Input() public coverageId: string;

    public documentsEnum = DocumentsEnum;
    public CoverageIdEnum = CoverageIdEnum;
}
