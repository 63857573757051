export const genericButtonTexts = {
    cancel: $localize`:@@generic.button-text.cancel:Cancel`,
    cancelChange: $localize`:@@generic.button-text.cancel-change:Cancel change`,
    changeCoverage: $localize`:@@generic.button-text.change-coverage:Change coverage`,
    close: $localize`:@@generic.button-text.close:Close`,
    continue: $localize`:@@generic.button-text.continue:Continue`,
    download: $localize`:@@generic.button-text.download:Download`,
    edit: $localize`:@@generic.button-text.edit:Edit`,
    email: $localize`:@@generic.button-text.email:E-mail`,
    getDownload: $localize`:@@generic.button-text.get-download:Get download`,
    moreInformation: $localize`:@@generic.button-text.more-information:More info`,
    noRatherNot: $localize`:@@generic.button-text.no-rather-not:No, not`,
    remove: $localize`:@@generic.button-text.remove:Remove`,
    save: $localize`:@@generic.button-text.save:Save`,
    login: $localize`:@@generic.button-text.login:Login`,
    logout: $localize`:@@generic.button-text.logout:Logout`,
};
