<div class="product-card">
    <svg class="product-card__icon svg-icon svg-icon--l"><use attr.href="assets/icons-general.svg#{{ product.type }}"></use></svg>
    <h2>{{ product.name }}</h2>
    <div class="product-card__container">
        <div class="product-card__content">
            <ng-content select="[product-card-content]"></ng-content>
        </div>
        <a class="product-card__toggle-additional link-secondary link-secondary--more" (click)="toggleAdditional()">
            <svg *ngIf="!toggled" class="svg-icon svg-icon--xs"><use href="assets/icons-ui.svg#pijl-down-bold"></use></svg>
            <svg *ngIf="toggled" class="svg-icon svg-icon--xs"><use href="assets/icons-ui.svg#pijl-up-bold"></use></svg>
            Weitere Informationen
        </a>
        <div class="product-card__additional" [ngClass]="{'product-card__additional--hidden': !toggled}">
            <ng-content select="[product-card-additional]"></ng-content>
        </div>
    </div>
</div>
