<ins-card [white]="true" [borders]="true">
    <h3>{{ invoiceDetails?.settling.description }}</h3>
    <ins-mobile-scroll>
        <ins-rack>
            <ins-rack-head>
                <ins-rack-row>
                    <ins-rack-col [flex]="2">Beschreibung</ins-rack-col>
                    <ins-rack-col>Zeitraum</ins-rack-col>
                    <ins-rack-col textAlign="right">Betrag</ins-rack-col>
                </ins-rack-row>
            </ins-rack-head>
            <ins-rack-body>
                <ins-rack-row *ngFor="let product of invoiceDetails?.settling.product">
                    <ins-rack-col [flex]="2">{{ product.productDescription }} {{ product.reference }}</ins-rack-col>
                    <ins-rack-col>{{ product.period }}</ins-rack-col>
                    <ins-rack-col textAlign="right">
                        <ins-invoice-price
                            [debitCredit]="product.debitCredit"
                            [price]="product.total"
                        ></ins-invoice-price>
                    </ins-rack-col>
                </ins-rack-row>
            </ins-rack-body>
        </ins-rack>
        <ins-rack *ngIf="invoiceDetails?.settling.purchased">
            <ins-rack-head>
                <ins-rack-row [highlight]="true">
                    <ins-rack-col [flex]="2">Gesamt</ins-rack-col>
                    <ins-rack-col [flex]="1" textAlign="right">
                        <ins-invoice-price
                            [debitCredit]="invoiceDetails?.settling.purchased.debitCredit"
                            [price]="invoiceDetails?.settling.purchased.total"
                        ></ins-invoice-price>
                    </ins-rack-col>
                </ins-rack-row>
            </ins-rack-head>
        </ins-rack>
        <ins-rack *ngIf="invoiceDetails?.settling.charged && invoiceDetails?.settling?.charged?.total > 0">
            <ins-rack-head>
                <ins-rack-row>
                    <ins-rack-col [flex]="2">Bereits in Rechnung gestellt</ins-rack-col>
                    <ins-rack-col [flex]="1" textAlign="right">
                        <ins-invoice-price
                            [debitCredit]="invoiceDetails?.settling.charged.debitCredit"
                            [price]="invoiceDetails?.settling.charged.total"
                        ></ins-invoice-price>
                    </ins-rack-col>
                </ins-rack-row>
            </ins-rack-head>
        </ins-rack>
        <ins-rack>
            <ins-rack-head>
                <ins-rack-row [highlight]="true">
                    <ins-rack-col [flex]="2">
                        <ng-container *ngIf="invoiceDetails?.settling.debitCredit === debitCreditEnum.Credit">
                            Gutschrift
                        </ng-container>
                        <ng-container *ngIf="invoiceDetails?.settling.debitCredit === debitCreditEnum.Debit">
                            Zu zahlender Gesamtbetrag
                        </ng-container>
                    </ins-rack-col>
                    <ins-rack-col [flex]="1" textAlign="right">
                        <ins-invoice-price
                            [highlight]="true"
                            [debitCredit]="debitCreditEnum.Debit"
                            [price]="invoiceDetails?.settling.total"
                        ></ins-invoice-price>
                    </ins-rack-col>
                </ins-rack-row>
            </ins-rack-head>
        </ins-rack>
    </ins-mobile-scroll>
</ins-card>
