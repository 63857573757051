import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MyZoneStoreModule, OutDamageFreeYearsImpactModule, OutInsuranceInfoCheckModule } from 'outshared-lib';
import { MyZoneComponent } from './my-zone.component';
import { MyZoneContainer } from './my-zone.container';
import { MyZoneRoutingModule } from './routing/my-zone-routing.module';
import { InternationalMotorInsuranceCardModule } from './shared/international-motor-insurance-card/international-motor-insurance-card.module';
import { MobileMenuMyZoneModule } from './shared/mobile-menu-my-zone/mobile-menu-my-zone.module';
import { SidemenuModule } from './shared/sidemenu/sidemenu.module';
import { InsuranceControlModule } from '@app-de/my-zone/shared/insurance-control/insurance-control.module';
import { DeceaseModalModule } from '@app-de/my-zone/shared/modals/decease-modal/decease-modal.module';
import { MyZoneNotificationsModule } from '@app-de/my-zone/shared/my-zone-notifications/my-zone-notifications.module';
import { MyZoneSideNotificationsModule } from '@app-de/my-zone/shared/my-zone-side-notifications/my-zone-side-notifications.module';
import { AutoReisezeitSummaryModule } from '@app-de/pages/smart-tips/auto/auto-reisezeit-summary/auto-reisezeit-summary.module';
import { AutopanneVermeidenSummaryModule } from '@app-de/pages/smart-tips/auto/autopanne-vermeiden-summary/autopanne-vermeiden-summary.module';
import { GrosseInspektionSummaryModule } from '@app-de/pages/smart-tips/auto/grosse-inspektion-summary/grosse-inspektion-summary.module';
import { HoechstenBussgelderSummaryModule } from '@app-de/pages/smart-tips/auto/hoechsten-bussgelder-summary/hoechsten-bussgelder-summary.module';
import { TankenFehlerSummaryModule } from '@app-de/pages/smart-tips/auto/tanken-fehler-summary/tanken-fehler-summary.module';
import { VerhaltenAutopanneSummaryModule } from '@app-de/pages/smart-tips/auto/verhalten-autopanne-summary/verhalten-autopanne-summary.module';
import { ChatbotEntryModule } from '@app-de/shared/customer-service/chatbot/chatbot-entry/chatbot-entry.module';
import { NewsCarouselModule } from '@app-de/shared/news-carousel/news-carousel.module';
import { StickyBarModule } from '@app-de/shared/sticky-bar/sticky-bar.module';
import { WucLinkModule } from '@inshared/website-ui-components';

@NgModule({
    imports: [
    CommonModule,
    MyZoneStoreModule,
    MyZoneRoutingModule,
    MyZoneNotificationsModule,
    MyZoneSideNotificationsModule,
    OutDamageFreeYearsImpactModule,
    OutInsuranceInfoCheckModule,
    InternationalMotorInsuranceCardModule,
    StickyBarModule,
    MobileMenuMyZoneModule,
    DeceaseModalModule,
    InsuranceControlModule,
    SidemenuModule,
    ChatbotEntryModule,
    NewsCarouselModule,
    AutopanneVermeidenSummaryModule,
    AutoReisezeitSummaryModule,
    GrosseInspektionSummaryModule,
    HoechstenBussgelderSummaryModule,
    TankenFehlerSummaryModule,
    VerhaltenAutopanneSummaryModule,
    WucLinkModule
],
    declarations: [MyZoneContainer, MyZoneComponent],
})
export class MyZoneModule {}
