import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RecoverySettingsDataComponent } from './recovery-settings-data';
import { RecoverySettingsFormComponent, RecoverySettingsFormContainer } from './recovery-settings-form';
import { DetailsSubmitButtonModule } from '@app-de/my-zone/shared/details-submit-button';
import { DetailsTableModule } from '@app-de/my-zone/shared/details-table';
import { CynoFormModule } from '@app-de/shared/cyno-form/cyno-form.module';

@NgModule({
    declarations: [RecoverySettingsDataComponent, RecoverySettingsFormComponent, RecoverySettingsFormContainer],
    exports: [RecoverySettingsDataComponent, RecoverySettingsFormContainer],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        CynoFormModule,
        DetailsTableModule,
        DetailsSubmitButtonModule,
    ],
})
export class RecoverySettingsModule {}
