import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { BreadCrumbInterface } from '@app-de/core/interfaces/bread-crumb.interface';
import * as fromRoot from '@app-de/store/app.reducer';
import * as layoutActions from '@app-de/store/layout/layout.actions';

@Injectable({
    providedIn: 'root',
})
export class LayoutService {

    constructor(
        private router: Router,
        private store$: Store<fromRoot.State>
    ) {}

    public matchUrl$(part: string): Observable<boolean> {
        return this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd),
            map((event: NavigationEnd) => event.url.includes(part))
        );
    }

    public setBreadcrumbs(payload: BreadCrumbInterface[]): void {
        this.store$.dispatch(new layoutActions.SetBreadcrumb(payload));
    }

    public showMobileMenu(): void {
        this.store$.dispatch(new layoutActions.ShowMobileMenu());
    }

    public hideMobileMenu(): void {
        this.store$.dispatch(new layoutActions.HideMobileMenu());
    }

    public showMainMenu(): void {
        this.store$.dispatch(new layoutActions.ShowMainMenu());
    }

    public hideMainMenu(): void {
        this.store$.dispatch(new layoutActions.HideMainMenu());
    }
}
