import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { EditAccountCredentialsInterface, ErrorInterface, RelationInterface } from 'outshared-lib';
import { Subscription } from 'rxjs';
import { PasswordStrengthValidator } from '@app-de/shared/cyno-form/cyno-password/cyno-password.component';

function equalValidator(comparison: AbstractControl): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        if (!comparison?.value) {
            return;
        }

        const isEqual = control.value === comparison.value;

        if (!isEqual) {
            return {
                notEqual: {
                    value: control.value,
                },
            };
        }
        return null;
    };
}

@Component({
    selector: 'ins-password-form',
    templateUrl: 'password-form.component.html',
})
export class PasswordFormComponent implements OnDestroy {
    @Output() public readonly submitted = new EventEmitter<EditAccountCredentialsInterface>();
    @Input() public relation: RelationInterface;
    @Input() public errors: ErrorInterface[];

    public form: UntypedFormGroup = new UntypedFormGroup({
        currentPassword: new UntypedFormControl('', [Validators.required]),
        newPassword: new UntypedFormControl('', [
            PasswordStrengthValidator.hasNoWordCharacters(),
            PasswordStrengthValidator.hasNoNumbers(),
            PasswordStrengthValidator.isBetween(8, 40),
            PasswordStrengthValidator.hasInvalidCharacters(),
        ]),
        repeatPassword: new UntypedFormControl(''),
    });

    public disabled: boolean = false;

    private subscriptions = new Subscription();

    constructor() {
        this.createForm();
    }

    public get currentPassword(): AbstractControl {
        return this.form.get('currentPassword');
    }

    public get newPassword(): AbstractControl {
        return this.form.get('newPassword');
    }

    public get repeatPassword(): AbstractControl {
        return this.form.get('repeatPassword');
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    public onFormSubmit(): boolean {
        if (!this.form.valid) {
            return false;
        }

        this.disabled = true;

        this.submitted.emit({
            currentPassword: this.currentPassword.value,
            newPassword: this.repeatPassword.value,
            emailAddress: this.relation.primary_email_address.email_address,
        });
    }

    private createForm(): void {
        this.repeatPassword.setValidators([Validators.required, equalValidator(this.newPassword)]);
    }
}
