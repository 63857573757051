import { AfterViewInit, Component, Input, QueryList, ViewChildren } from '@angular/core';
import { ProductBaseInterface, ProductIdEnum } from 'outshared-lib';
import { PanelExpandableComponent } from '@app-de/shared/panel-expandable/panel-expandable.component';

@Component({
    selector: 'ins-international-motor-insurance-card',
    templateUrl: './international-motor-insurance-card.component.html',
    styleUrls: ['./international-motor-insurance-card.component.scss'],
})
export class InternationalMotorInsuranceCardComponent implements AfterViewInit {
    @ViewChildren(PanelExpandableComponent) public panels: QueryList<PanelExpandableComponent>;

    public productIdEnum: typeof ProductIdEnum = ProductIdEnum;

    @Input() public product: ProductBaseInterface;
    @Input() public open: boolean = false;

    public ngAfterViewInit(): void {
        if (this.open) {
            this.panels.first.open();
        }
    }

    public isCar(productId: ProductIdEnum): boolean {
        return ProductIdEnum.Car === productId;
    }
}
