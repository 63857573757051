import { isPlatformServer } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    DoCheck,
    ElementRef,
    Inject,
    Input,
    Optional,
    PLATFORM_ID,
    Self,
    ViewChild,
} from '@angular/core';
import { ControlValueAccessor, FormGroupDirective, NgControl, NgForm } from '@angular/forms';
import { DateService } from 'outshared-lib';
import Pikaday from 'pikaday';
import { pickadayConfig } from '@app-de/core/services/pickaday.config';
import { PikadayService } from '@app-de/core/services/pikaday.service';
import { CynoFieldControl } from '@app-de/shared/cyno-form/core/cyno-field-control';
import { ErrorStateMatcher } from '@app-de/shared/cyno-form/core/error-options';
import { mixinErrorState, CanUpdateErrorState } from '@app-de/shared/cyno-form/core/error-state';

class DatepickerBase {
    constructor(
        public _defaultErrorStateMatcher: ErrorStateMatcher,
        public _parentForm: NgForm,
        public _parentFormGroup: FormGroupDirective,
        public ngControl: NgControl,
    ) {}
}

const datepickerMixinBase = mixinErrorState(DatepickerBase);

class Datepicker {}
@Component({
    selector: 'ins-datepicker',
    templateUrl: 'datepicker.component.html',
    styleUrls: ['./datepicker.component.scss'],
    providers: [{ provide: CynoFieldControl, useExisting: DatepickerComponent }],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatepickerComponent
    extends datepickerMixinBase
    implements AfterViewInit, ControlValueAccessor, CynoFieldControl<Datepicker>, CanUpdateErrorState, DoCheck
{
    @Input()
    public get id(): string {
        return this._id ? this._id : `datepicker-${DatepickerComponent.nextId++}`;
    }

    public set id(id) {
        this._id = id;
        this.stateChanges.next();
    }

    @Input()
    public get placeholder(): string {
        return this._placeholder;
    }

    public set placeholder(plh: string) {
        this._placeholder = plh;
        this.stateChanges.next();
    }

    @Input()
    public get required(): boolean {
        return this._required;
    }

    public set required(req) {
        this._required = req;
        this.stateChanges.next();
    }

    @Input()
    public get disabled(): boolean {
        return this._disabled;
    }

    public set disabled(value: boolean) {
        this._disabled = value;
        this.stateChanges.next();
    }

    protected _disabled: boolean = false;

    private static nextId = 0;
    @Input() public selectPastDates: boolean = false;
    @Input() public selectPastDatesWithToday: boolean = false;
    @Input() public selectFromToday: boolean = false;
    @Input() public minDate: Date;
    @Input() public maxDate: Date;
    @Input() public pickadayConfig = pickadayConfig.germany;

    @ViewChild('datepicker', { static: true }) public datepicker: ElementRef;

    public value: string;
    public focused: boolean = false;
    public yesterday: Date = new Date(Date.now() - 3600 * 1000 * 24);
    public today: Date = new Date();
    public _onChange: any;
    public _onTouched: any;
    public onContainerClick: any;

    private _placeholder: string;
    private _required = false;
    private _id: string;

    constructor(
        public _defaultErrorStateMatcher: ErrorStateMatcher,
        private dateService: DateService,
        private pikadayService: PikadayService,
        @Optional() public _parentForm: NgForm,
        @Optional() public _parentFormGroup: FormGroupDirective,
        @Optional() @Self() public ngControl: NgControl,
        @Inject(PLATFORM_ID) private platformId: Object,
    ) {
        super(_defaultErrorStateMatcher, _parentForm, _parentFormGroup, ngControl);

        if (this.ngControl) {
            this.ngControl.valueAccessor = this;
        }
    }

    public ngAfterViewInit(): void {
        if (isPlatformServer(this.platformId)) {
            return;
        }

        this.checkSelectPastDates();

        this.minDate = new Date(this.minDate);
        this.maxDate = new Date(this.maxDate);

        const field = this.datepicker.nativeElement;

        this.pikadayService
            .getInstance({
                field: field,
                minDate: this.minDate,
                maxDate: this.maxDate,
                onSelect: this._onChange,
            })
            .then((pikadayInstance: Pikaday) =>
                pikadayInstance.config({
                    onOpen: (): void => {
                        if (
                            !pikadayInstance.getDate() &&
                            this.dateService.isBefore(this.dateService.now(), this.minDate)
                        ) {
                            pikadayInstance.gotoDate(this.minDate);
                        }
                    },
                }),
            );
    }

    public ngDoCheck(): void {
        if (this.ngControl) {
            this.updateErrorState();
        }
    }

    public writeValue(value: string): void {
        if (value) {
            this.datepicker.nativeElement.value = this.dateService.format(value, 'DD-MM-YYYY');
        }
    }

    public registerOnChange(fn: (value: any) => void): void {
        this._onChange = (value: any): void => {
            fn(this._transform(value));
        };
    }

    public registerOnTouched(fn: () => {}): void {
        this._onTouched = fn;
    }

    public onFocus(): void {
        if (this.datepicker.nativeElement.value) {
            this._onTouched();
        }
    }

    public onBlur(): void {
        const value = this._transform(this.datepicker.nativeElement.value);

        this.datepicker.nativeElement.value = value;

        this._onChange(value);
        this._onTouched();
    }

    private _transform(value: string): string {
        return this.dateService.format(value, 'YYYY-MM-DD');
    }

    private checkSelectPastDates(): void {
        if (this.selectPastDates) {
            this.maxDate = this.yesterday;
        }
        if (this.selectPastDatesWithToday) {
            this.maxDate = this.today;
        }
    }
}
