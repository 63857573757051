export enum ControlButtonIdsEnum {
    ChangeLoginInformation = 'IGWZ',
    ChangePersonalDetails = 'PGWZ',
    LiveTogether = 'SMWN',
    AddKidsToInsurance = 'KDMV',
    Marriage = 'TRWN',
    Breakup = 'ULKR',
    Deceased = 'OVLD',
    TermsAndConditions = 'VWIZ',
}
