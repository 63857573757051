import { Component, Input } from '@angular/core';

@Component({
    selector: 'ins-list-group',
    templateUrl: './list-group.component.html',
    styleUrls: ['./list-group.component.scss', './list-flat.component.scss'],
})
export class ListGroupComponent {
    @Input() public icon: boolean = false;
    @Input() public dropdown: boolean = false;
    @Input() public flat: boolean = false;
    @Input() public flatIcon: boolean = false;
    @Input() public lined: boolean = false;
}
