export const genericErrorMessages = {
    coverageNotAvailable: $localize`:@@generic.error-message.coverage-not-available:This coverage is not possible in your situation`,
    directDebitAgreementRequired: $localize`:@@generic.error-message.direct-debit-agreement-required:Sorry! If you do not agree to the direct debit, we unfortunately cannot change the data.`,
    emailInvalid: $localize`:@@generic.error-message.email-invalid:Unfortunately, this is not a valid email address`,
    emailRequired: $localize`:@@generic.error-message.email-required:Could you please fill in your email address?`,
    endDateRequired: $localize`:@@generic.error-message.end-date-required:Enter the end date.`,
    lastNameRequired: $localize`:@@generic.error-message.last-name-required:Enter your last name.`,
    passwordsNotEqual: $localize`:@@generic.error-message.passwords-not-equal:Oops! The passwords don't match.`,
    passwordRequired: $localize`:@@generic.error-message.password-required:Oops! Could you please enter your password again?`,
    phoneInvalid: $localize`:@@generic.error-message.phone-invalid:Unfortunately, this is not a valid telephone number`,
    phoneRequired: $localize`:@@generic.error-message.phone-required:Oops! Could you please fill in your telephone number?`,
    postalcodeCivicNumberMismatch: $localize`:@@generic.error-message.postalcode-civic-number-mismatch:Unfortunately! This combination of zip code and house number does not exist.`,
    somethingWentWrongTryAgainLater: $localize`:@@generic.error-message.something-went-wrong-try-again-later:Something went wrong. Please try again later.`,
    startDateRequired: $localize`:@@generic.error-message.start-date-required:Enter the start date.`,
};
