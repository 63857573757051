import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
    WucButtonModule,
    WucCardModule,
    WucCompareTableCoverageModule,
    WucDisplayFieldModule,
    WucExplanationArrowModule,
    WucFormErrorModule,
    WucFormFieldLabelModule,
    WucFormFieldModule,
    WucIconUiModule,
    WucInputCheckboxModule,
    WucInputDateModule,
    WucInputDatePickerModule,
    WucLinkModule,
    WucPageBlockModule,
    WucPriceModule,
    WucScribbleCircleModule,
} from '@inshared/website-ui-components';
import { NewModalModule } from 'outshared-lib';
import { YearRewardModalModule } from '../year-reward-modal/year-reward-modal.module';
import { ChangeCoverageComponent } from './change-coverage/change-coverage.component';
import { ChangeCoverageContainer } from './change-coverage/change-coverage.container';
import { CoverageDetailComponent } from './coverage-detail.component';
import { CoverageDetailContainer } from './coverage-detail.container';
import { CoverageDetailService } from './coverage-detail.service';
import { CoverageOverviewCurrentComponent } from './coverage-overview/coverage-overview-current/coverage-overview-current.component';
import { CoverageOverviewFutureComponent } from './coverage-overview/coverage-overview-future/coverage-overview-future.component';
import { CoverageOverviewComponent } from './coverage-overview/coverage-overview.component';
import { CoverageOverviewContainer } from './coverage-overview/coverage-overview.container';
import { LabelModule } from '@app-de/salesfunnel/shared/label/label.module';
import { CynoFormModule } from '@app-de/shared/cyno-form/cyno-form.module';
import { ListModule } from '@app-de/shared/lists/list.module';
import { CoverageModalsModule } from '@app-de/shared/modals/coverage-modals/coverage-modals.module';
import { PanelExpandableModule } from '@app-de/shared/panel-expandable/panel-expandable.module';
import { DateFormatPipeModule } from '@app-de/shared/pipes/date-format/date-format.module';
import { TabsetModule } from '@app-de/shared/tabs/tabset.module';

@NgModule({
    imports: [
        CommonModule,
        CynoFormModule,
        NewModalModule,
        TabsetModule,
        DateFormatPipeModule,
        PanelExpandableModule,
        ListModule,
        YearRewardModalModule,
        WucLinkModule,
        WucButtonModule,
        WucPriceModule,
        WucInputCheckboxModule,
        WucPageBlockModule,
        WucInputDatePickerModule,
        WucFormFieldModule,
        WucFormFieldLabelModule,
        WucFormErrorModule,
        WucDisplayFieldModule,
        WucPriceModule,
        WucInputDateModule,
        CoverageModalsModule,
        WucExplanationArrowModule,
        LabelModule,
        WucIconUiModule,
        WucCardModule,
        WucScribbleCircleModule,
        WucCompareTableCoverageModule,
    ],
    declarations: [
        CoverageDetailContainer,
        CoverageDetailComponent,
        CoverageOverviewContainer,
        CoverageOverviewComponent,
        ChangeCoverageContainer,
        ChangeCoverageComponent,
        CoverageOverviewFutureComponent,
        CoverageOverviewCurrentComponent,
    ],
    exports: [CoverageDetailContainer],
    providers: [CoverageDetailService],
})
export class CoverageDetailModule {}
