<ng-container *ngIf="loading; else loaded">
    <wuc-skeleton height="15rem"></wuc-skeleton>
    <wuc-skeleton></wuc-skeleton>
    <wuc-page-block blockType="open" icon="car" [skeleton]="true"></wuc-page-block>
</ng-container>

<ng-template #loaded>
    <section class="section my-insurances__section">
        <ins-passport-container></ins-passport-container>
    </section>
    <h2>Meine Versicherungen</h2>
    <wuc-link type="button-secondary" class="text--center my-insurances__cta-btn--mobile" [isFullWidth]="true" (clicked)="onAddSecondVehicleClick()">
        Beitrag für Ihren Zweitwagen berechnen
    </wuc-link>
    <section class="section">
        <ng-container *ngIf="products.length === 0">
            <p>
                Hoppla, alles weiß! Das liegt daran, weil Sie im Moment keine Versicherung(en) bei uns haben. Aber das
                kann sich natürlich schnell ändern.
                <a [routerLink]="['', salesfunnelRoutesEnum.CarInsurance, salesfunnelRoutesEnum.CalculateCarInsurance]">
                    Berechnen Sie einfach unverbindlich Ihren Beitrag
                </a>
                … und wer weiß, vielleicht steht hier bald wieder etwas!
            </p>
        </ng-container>
        <ins-insurance-list-container
            class="insurance"
            *ngFor="let product of products"
            [id]="'product_' + product.purchased_product_sequence_number"
            [product]="product"
        ></ins-insurance-list-container>
    </section>
</ng-template>
