import {
    Component,
    Inject,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    QueryList,
    SimpleChanges,
    ViewChildren,
} from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import {
    NewModal,
    ProductSelectorService,
    RelationInterface,
    ScenarioCodeEnum,
    ScenarioInterface,
    ScenariosSelectorService,
} from 'outshared-lib';
import { ProductBaseInterface } from 'outshared-lib/my-zone/interfaces';
import { Observable, of, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { FamilyCompositionCodeEnum } from '@app-de/core/enums/family-composition-code.enum';
import { controlButtons } from '@app-de/my-zone/shared/insurance-control/shared/config/control-buttons';
import {
    ControlButtonIdsEnum,
    ControlCategoryEnum,
    ControlDownloadTypesEnum,
} from '@app-de/my-zone/shared/insurance-control/shared/enums';
import {
    ControlButtonInterface,
    ProductInformationInterface,
} from '@app-de/my-zone/shared/insurance-control/shared/interfaces';
import {
    DownloadControlAction,
    ModalControlAction,
    NavigateControlAction,
} from '@app-de/my-zone/shared/insurance-control/shared/models';
import { DownloadingModal } from '@app-de/shared/modals/downloading-modal/downloading.modal';
import { PanelExpandableComponent } from '@app-de/shared/panel-expandable/panel-expandable.component';
import { WINDOW } from '@inshared/shared/util';

@Component({
    selector: 'ins-insurance-control',
    templateUrl: './insurance-control.component.html',
    styleUrls: ['./insurance-control.component.scss'],
})
export class InsuranceControlComponent implements OnInit, OnChanges, OnDestroy {
    @ViewChildren(PanelExpandableComponent) public children: QueryList<PanelExpandableComponent>;
    @Input() public isLoggedIn: boolean;
    @Input() public relation: RelationInterface;

    public products$: Observable<ProductBaseInterface[]> = this.productSelectorService.getProductCollection$;
    public allProductsLoaded$: Observable<boolean> = this.productSelectorService.allProductsLoaded$;
    public scenarios$: Observable<ScenarioInterface[]> = this.scenariosSelectorService.getAllScenarios$;

    public productCategories: typeof ControlCategoryEnum = ControlCategoryEnum;

    public productInformation: ProductInformationInterface[];
    public buttons: ControlButtonInterface[] = [];
    private modalRef: any = null;
    private isSJVG: boolean = false;
    private canBeReplaced: boolean = false;
    private subscriptions = new Subscription();

    public get familyCompositionCode(): FamilyCompositionCodeEnum {
        return this.relation?.family_composition?.family_composition_code as FamilyCompositionCodeEnum;
    }

    constructor(
        private newModal: NewModal,
        private productSelectorService: ProductSelectorService,
        private scenariosSelectorService: ScenariosSelectorService,
        private router: Router,
        @Inject(WINDOW) private window: Window,
    ) {}

    public ngOnInit(): void {
        this.subscriptions.add(this.getPolis());
        this.subscriptions.add(this.getScenarios());
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.user?.currentValue || changes.relation?.currentValue) {
            this.buttons = this.filterButtons(controlButtons);
        }
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    public clickAction(button: ControlButtonInterface): void {
        if (button.action instanceof NavigateControlAction) {
            this.navigateAction(button.action);
        }

        if (button.action instanceof DownloadControlAction) {
            this.subscriptions.add(this.downloadAction(this.getDownloadSource(button.action)));
        }

        if (button.action instanceof ModalControlAction) {
            this.subscriptions.add(this.modalAction(button.action, button));
        }
    }

    private downloadAction(downloadSource: Observable<string>): Subscription {
        const modalRef = this.newModal.open(DownloadingModal);

        return downloadSource.subscribe(
            (file) => {
                modalRef.close();
                if (file) {
                    this.window.location.href = `/documenten/download/${file}`;
                } else {
                    console.warn('No file was found');
                }
            },
            () => modalRef.close(),
        );
    }

    private modalAction(action: ModalControlAction, { name }: Partial<ControlButtonInterface>): Subscription {
        this.modalRef = this.newModal.open(action.modalComponent);
        this.modalRef.componentInstance.model = {
            title: name,
            confirmation: action.confirmation,
            cancelled: 'Annullieren',
        };

        return this.modalRef.afterClosed$().subscribe((response) => {
            this.AfterModalAction(response, action.modalComponent);
        });
    }

    private navigateAction(action: NavigateControlAction): void {
        const navigationExtras: NavigationExtras = action.params
            ? {
                  queryParams: {
                      section: action.hashParam,
                      expand: action.expand,
                      scroll: action.scroll,
                  },
              }
            : {
                  queryParams: {
                      panel: action.hashParam,
                      scrollTo: action.hashParam,
                  },
                  queryParamsHandling: 'merge',
              };

        this.router.navigate(action.uri, navigationExtras);
    }

    private getPolis(): Subscription {
        return this.products$.subscribe((polisProduct: ProductBaseInterface[]) => {
            this.productInformation = polisProduct
                .filter((product) => product.validity_status_code !== 'EIND')
                .map((product) => ({
                    polisId: product.product_id,
                    startDate: product.start_date,
                    validityStatusCode: product.validity_status_code,
                }));
            this.buttons = this.filterButtons(controlButtons);
        });
    }

    private getScenarios(): Subscription {
        return this.scenarios$
            .pipe(
                map((scenarios) =>
                    scenarios.filter(
                        (scenario) =>
                            scenario.scenario_code === ScenarioCodeEnum.DamageFreeYears ||
                            scenario.scenario_code === ScenarioCodeEnum.VehicleReplacement,
                    ),
                ),
            )
            .subscribe((scenarios) => {
                if (
                    scenarios &&
                    scenarios.length > 0 &&
                    scenarios[0].scenario_code === ScenarioCodeEnum.DamageFreeYears
                ) {
                    this.isSJVG = true;
                }

                if (
                    scenarios &&
                    scenarios.length > 0 &&
                    scenarios[0].scenario_code === ScenarioCodeEnum.VehicleReplacement
                ) {
                    this.canBeReplaced = true;
                }
                this.buttons = this.filterButtons(controlButtons);
            });
    }

    private filterButtons(buttons: ControlButtonInterface[]): ControlButtonInterface[] {
        return buttons.filter((button) => {
            let shouldFilter: boolean;
            switch (button.id) {
                case ControlButtonIdsEnum.Breakup:
                    shouldFilter = [
                        FamilyCompositionCodeEnum.TwoPersonsWithChildren,
                        FamilyCompositionCodeEnum.TwoPersons,
                    ].includes(this.familyCompositionCode);
                    break;
                case ControlButtonIdsEnum.AddKidsToInsurance:
                    shouldFilter = [FamilyCompositionCodeEnum.OnePerson, FamilyCompositionCodeEnum.TwoPersons].includes(
                        this.familyCompositionCode,
                    );
                    break;
                default:
                    shouldFilter = true;
                    break;
            }
            return shouldFilter;
        });
    }

    private getDownloadSource(action: DownloadControlAction): Observable<string> {
        if (action.type === ControlDownloadTypesEnum.Polis) {
            // TODO WIDL-1025: The download implementation can go here, observable needs to emit the source of the file as a string
            return of(null);
        }
    }

    private AfterModalAction(response: boolean, link: string): void {
        if (response) {
            this[link]();
        }
    }
}
