import { Component, Inject, Input } from '@angular/core';
import {
    ErrorInterface,
    IndicationEnum,
    NewModal,
    ProductActionService,
    ProductBaseInterface,
    ProductIdEnum,
    ValidityStatusCodeEnum,
} from 'outshared-lib';
import { DownloadFileInterface } from 'outshared-lib/my-zone/interfaces/product/dowload-file.interface';
import { Subscription } from 'rxjs';
import { DocumentsEnum } from '@app-de/core/enums/documents.enum';
import { ConfirmationModal } from '@app-de/shared/modals/confirmation-modal/confirmation.modal';
import { DownloadingModal } from '@app-de/shared/modals/downloading-modal/downloading.modal';
import { PdfDownloadUtilities } from '@app-de/shared/utilities/pdf-download-utilities';
import { WINDOW } from '@inshared/shared/util';

@Component({
    selector: 'ins-terms-and-condition',
    templateUrl: 'terms-and-condition.component.html',
})
export class TermsAndConditionComponent {
    @Input() public userProductIdList: ProductIdEnum[] = [];
    @Input() public product: ProductBaseInterface;

    public documentsEnum: typeof DocumentsEnum = DocumentsEnum;
    public validityStatusCodeEnum: typeof ValidityStatusCodeEnum = ValidityStatusCodeEnum;

    private subscriptions: Subscription = new Subscription();

    constructor(
        private newModal: NewModal,
        @Inject(WINDOW) private window: Window,
        private productActionService: ProductActionService,
    ) {}

    public getPolicy(): boolean {
        const modalRef = this.newModal.open(DownloadingModal);
        const policyDownload = this.productActionService.downloadPolicy(IndicationEnum.Yes).subscribe(
            (pdf: DownloadFileInterface) => {
                this.openPdfModal(pdf);
                modalRef.close();
            },
            (errors: ErrorInterface[]) => {
                modalRef.close();
                this.newModal.open<ConfirmationModal>(ConfirmationModal, {
                    data: {
                        alignment: 'end',
                        body: errors[0].message,
                        confirm: 'OK',
                    },
                });
            },
        );
        this.subscriptions.add(policyDownload);
        return false;
    }

    private openPdfModal(attachment: DownloadFileInterface): void {
        PdfDownloadUtilities.OpenPdfBase64TargetBlank(attachment.pdf, attachment.type, this.window);
    }
}
