import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { WucBalloonModule, WucPriceModule, WucTextHighlightModule } from '@inshared/website-ui-components';
import { CoverageDetailModule } from '../coverage-detail';
import { CoverageListModule } from '../coverage-list/coverage-list.module';
import { DirectControlButtonsModule } from '../direct-control-buttons/direct-control-buttons.module';
import { InsuranceDetailModule } from '../insurance-detail/insurance-detail.module';
import { InsuranceRegularDriverFormModule } from '../insurance-regular-driver';
import { InternationalMotorInsuranceCardModule } from '../international-motor-insurance-card';
import { InsuranceListComponent } from './insurance-list.component';
import { InsuranceListContainer } from './insurance-list.container';
import { TerminateInsuranceModule } from '@app-de/my-zone/shared/terminate-insurance/terminate-insurance.module';
import { DecimalizeModule } from '@app-de/shared/directives/decimalize/decimalize.module';
import { PanelExpandableModule } from '@app-de/shared/panel-expandable/panel-expandable.module';
import { CapitalizeModule } from '@app-de/shared/pipes/capitalize/capitalize.module';
import { DateFormatPipeModule } from '@app-de/shared/pipes/date-format/date-format.module';
import { LicensePlatePipeModule } from '@app-de/shared/pipes/licence-plate/license-plate.module';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        PanelExpandableModule,
        CapitalizeModule,
        LicensePlatePipeModule,
        DateFormatPipeModule,
        CoverageListModule,
        DecimalizeModule,
        InsuranceDetailModule,
        TerminateInsuranceModule,
        DirectControlButtonsModule,
        CoverageDetailModule,
        InternationalMotorInsuranceCardModule,
        WucBalloonModule,
        InsuranceRegularDriverFormModule,
        WucTextHighlightModule,
        WucPriceModule,
    ],
    exports: [InsuranceListContainer],
    declarations: [InsuranceListComponent, InsuranceListContainer],
})
export class InsuranceListModule {}
