import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MyInsurancesContainer } from './my-insurances.container';
import { CoveragePremiumResolver } from '@app-de/my-zone/resolvers/coverage-premium.resolver';
import { ProductResolver } from '@app-de/my-zone/resolvers/product.resolver';
import { ProductsResolver } from '@app-de/my-zone/resolvers/products.resolver';

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        component: MyInsurancesContainer,
        data: {
            containsPanels: true,
        },
        resolve: {
            products: ProductsResolver,
            coverages: CoveragePremiumResolver,
            product: ProductResolver,
        },
        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class MyInsurancesRoutingModule {}
