import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { DetailsSubmitButtonModule } from '../details-submit-button/details-submit-button.module';
import { DetailsTableModule } from '../details-table/details-table.module';
import { PersonalDetailsDataComponent } from './personal-details-data/personal-details-data.component';
import { PersonalDetailsFormComponent } from './personal-details-form/personal-details-form.component';
import { PersonalDetailsFormContainer } from '@app-de/my-zone/shared/personal-details/personal-details-form/personal-details-form.container';
import { CynoFormModule } from '@app-de/shared/cyno-form/cyno-form.module';
import { DateFormatPipeModule } from '@app-de/shared/pipes/date-format/date-format.module';

@NgModule({
    imports: [
        CommonModule,
        DateFormatPipeModule,
        CynoFormModule,
        RouterModule,
        DetailsTableModule,
        DetailsSubmitButtonModule,
    ],
    providers: [DatePipe],
    exports: [PersonalDetailsDataComponent, PersonalDetailsFormContainer],
    declarations: [PersonalDetailsDataComponent, PersonalDetailsFormContainer, PersonalDetailsFormComponent],
})
export class PersonalDetailsModule {}
