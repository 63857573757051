import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { GetInsuranceInfoCheckRequestInterface, SaveInsuranceInfoCheckRequestInterface } from '../interfaces';
import { InsuranceInfoCheckState } from '../store';
import * as InsuranceInfoCheckActions from '../store/insurance-info-check.actions';
import { InsuranceInfoCheckEventService } from './insurance-info-check.event.service';

@Injectable({
    providedIn: 'root',
})
export class InsuranceInfoCheckActionService {
    constructor(
        private store$: Store<InsuranceInfoCheckState>,
        private insuranceInfoCheckEventService: InsuranceInfoCheckEventService
    ) {}

    public getInsuranceInfoCheck$(request: GetInsuranceInfoCheckRequestInterface): Observable<void> {
        this.store$.dispatch(InsuranceInfoCheckActions.getInsuranceInfoCheck({ request }));
        return this.insuranceInfoCheckEventService.getInsuranceInfoCheck$();
    }

    public saveInsuranceInfoCheck$(request: SaveInsuranceInfoCheckRequestInterface): Observable<void> {
        this.store$.dispatch(InsuranceInfoCheckActions.saveInsuranceInfoCheckInfo({ request }));
        return this.insuranceInfoCheckEventService.saveInsuranceInfoCheck$();
    }

    public clearInsuranceInfoCheck(): void {
        this.store$.dispatch(InsuranceInfoCheckActions.clearInsuranceInfoCheckInfo());
    }
}
