import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { DisclaimerModal } from './disclaimer.modal';
import { ModalComponentsModule } from '@app-de/components';
import { ListModule } from '@app-de/shared/lists/list.module';

@NgModule({
    declarations: [DisclaimerModal],
    imports: [
        CommonModule,
        RouterModule,
        ListModule,
        ModalComponentsModule,
    ],
    exports: [DisclaimerModal],
})
export class DisclaimerModalModule {}
