import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { NewModalModule } from 'outshared-lib';
import { YearRewardModal } from './year-reward.modal';
import { ModalComponentsModule } from '@app-de/components';

@NgModule({
    declarations: [YearRewardModal],
    exports: [YearRewardModal],
    imports: [
        CommonModule,
        RouterModule,
        NewModalModule,
        LazyLoadImageModule,
        ModalComponentsModule,
    ],
})
export class YearRewardModalModule {
}
