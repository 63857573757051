import { ApiProductScenarioCodeEnum, PolicyScenarioCodeEnum } from '../../insurance-policy';

export function mapProductScenarioCodeResponse(response: ApiProductScenarioCodeEnum): PolicyScenarioCodeEnum {
    if (!response) {
        return null;
    }

    switch (response) {
        case ApiProductScenarioCodeEnum.CompareAddressDetails:
            return PolicyScenarioCodeEnum.CompareAddressDetails;
        case ApiProductScenarioCodeEnum.CompareAddressStartDate:
            return PolicyScenarioCodeEnum.CompareAddressStartDate;
        case ApiProductScenarioCodeEnum.DamageFreeYears:
            return PolicyScenarioCodeEnum.DamageFreeYears;
        case ApiProductScenarioCodeEnum.VehicleReplacement:
            return PolicyScenarioCodeEnum.VehicleReplacement;
        case ApiProductScenarioCodeEnum.LineType40:
            return PolicyScenarioCodeEnum.LineType40;
    }
}
