import { Component } from '@angular/core';
import { ProductBaseInterface, ProductIdEnum, ProductSelectorService } from 'outshared-lib';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DatesEnum } from '@app-de/core/enums/dates.enum';

@Component({
    selector: 'ins-no-entry-date-notification-container',
    template: `
        <ng-container *ngFor="let product of productsNoEntryDates$ | async">
            <ins-no-entry-date-notification [product]="product"></ins-no-entry-date-notification>
        </ng-container>
    `,
})
export class NoEntryDateNotificationContainer {
    public productsNoEntryDates$: Observable<ProductBaseInterface[]> =
        this.productSelectorService.getActiveProducts$.pipe(
            map((products) => {
                const result = products.find(
                    (product) => product.start_date === DatesEnum.NoDate && product.product_id === ProductIdEnum.Car,
                );
                return result ? [result] : [];
            }),
        );

    constructor(public productSelectorService: ProductSelectorService) {}
}
