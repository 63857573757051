import { isPlatformServer } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { AppConfigInterface } from './app-config.interface';

@Injectable()
export class AppConfigService {
    public config: AppConfigInterface;

    constructor(private httpClient: HttpClient, @Inject(PLATFORM_ID) private platformId: object) {}

    public async init$(): Promise<AppConfigInterface> {
        if (isPlatformServer(this.platformId)) {
            return null;
        }
        const response = await fetch('/assets/data/config.json');
        const config = await response.json();
        return (this.config = config);
    }

    public getConfig(): AppConfigInterface {
        return this.config;
    }
}
