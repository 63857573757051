import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ErrorInterface } from '../../core';
import { DownloadInvoiceInterface } from '../interfaces';

@Injectable({
    providedIn: 'root',
})
export class InvoiceEventService {
    private _getInvoices$: Subject<void>;
    private _getInvoiceDetails$: Subject<void>;
    private _downloadInvoice$: Subject<DownloadInvoiceInterface>;

    public getInvoices$(): Observable<void> {
        this._getInvoices$ = new Subject();
        return this._getInvoices$.asObservable();
    }

    public onGetInvoicesSuccess(): void {
        this._getInvoices$.next();
        this._getInvoices$.complete();
    }

    public onGetInvoicesError(errors: ErrorInterface[]): void {
        if (this._getInvoices$) {
            this._getInvoices$.error(errors);
        }
    }

    public getInvoiceDetails$(): Observable<void> {
        this._getInvoiceDetails$ = new Subject();
        return this._getInvoiceDetails$.asObservable();
    }

    public onGetInvoiceDetailsSuccess(): void {
        this._getInvoiceDetails$.next();
        this._getInvoiceDetails$.complete();
    }

    public onGetInvoiceDetailsError(errors: ErrorInterface[]): void {
        this._getInvoiceDetails$.error(errors);
    }

    public downloadInvoice$(): Observable<DownloadInvoiceInterface> {
        this._downloadInvoice$ = new Subject();
        return this._downloadInvoice$.asObservable();
    }

    public onDownloadInvoiceSuccess(download: DownloadInvoiceInterface): void {
        this._downloadInvoice$.next(download);
        this._downloadInvoice$.complete();
    }

    public onDownloadInvoiceError(errors: ErrorInterface[]): void {
        this._downloadInvoice$.error(errors);
    }
}
