import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import { DamageFreeYearsImpactApiService } from '../services/damage-free-years-impact.api.service';
import { DamageFreeYearsImpactEventService } from '../services/damage-free-years-impact.event.service';
import * as DamageFreeYearsImpactActions from '../store/damage-free-years-impact.actions';

@Injectable()
export class DamageFreeYearsImpactEffects {
    public damageFreeYearsImpact$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(DamageFreeYearsImpactActions.damageFreeYearsImpact),
            exhaustMap(({ request }) =>
                this.damageFreeYearsImpactApiService.damageFreeYearsImpact$(request).pipe(
                    map((response) => DamageFreeYearsImpactActions.damageFreeYearsImpactSuccess({ response })),
                    catchError((errors) => of(DamageFreeYearsImpactActions.damageFreeYearsImpactFailure({ errors })))
                )
            )
        );
    });

    public damageFreeYearsImpactSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(DamageFreeYearsImpactActions.damageFreeYearsImpactSuccess),
                tap(() => this.damageFreeYearsImpactEventService.onDamageFreeYearsImpactSuccess())
            );
        },
        { dispatch: false }
    );

    public damageFreeYearsImpactError$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(DamageFreeYearsImpactActions.damageFreeYearsImpactFailure),
                tap(({ errors }) => this.damageFreeYearsImpactEventService.onDamageFreeYearsImpactError(errors))
            );
        },
        { dispatch: false }
    );

    constructor(
        private actions$: Actions,
        private damageFreeYearsImpactApiService: DamageFreeYearsImpactApiService,
        private damageFreeYearsImpactEventService: DamageFreeYearsImpactEventService
    ) {}
}
