import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { WucDropTriggerComponent } from '@inshared/website-ui-components';
import { CoveragePremiumInterface } from 'outshared-lib';
import { DirectControlOptions } from './direct-control-buttons-config';
import { DirectControlButtonActionEnum } from '@app-de/my-zone/enums';
import { InsuranceListQueryParams } from '@app-de/my-zone/interfaces';

@Component({
    selector: 'ins-direct-control-buttons',
    templateUrl: './direct-control-buttons.component.html',
    styleUrls: ['./direct-control-buttons.component.scss'],
})
export class DirectControlButtonsComponent {
    @Input() public loadedProduct: CoveragePremiumInterface;
    @Input() public buttons: DirectControlOptions[];
    @Input() public showCloseButton: boolean = false;
    @Input() public queryParams: InsuranceListQueryParams;
    @Input() public hasOpenPanel: boolean;

    @Output() public readonly clicked: EventEmitter<DirectControlOptions> = new EventEmitter<DirectControlOptions>();

    @ViewChild('dropTrigger') public dropTriggerComponent!: WucDropTriggerComponent;

    public clickAction(options: DirectControlOptions): void {
        this.clicked.emit(options);
    }

    public closeAction(): void {
        this.clicked.emit({ action: DirectControlButtonActionEnum.Close } as DirectControlOptions);
    }
}
