import { ControlDownloadTypesEnum } from '@app-de/my-zone/shared/insurance-control/shared/enums/control-download-types.enum';

export class DownloadControlAction {
    public fileName: string;
    public type: ControlDownloadTypesEnum;

    constructor(data?: DownloadControlAction) {
        if (data) {
            this.fileName = data.fileName;
            this.type = data.type;
        }
    }
}
