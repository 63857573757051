import { Component, Input } from '@angular/core';
import { SmartTipsEnum } from '@app-de/pages/smart-tips/smart-tips.enum';
import { AppRoutesEnum } from '@app-de/routes';

@Component({
    selector: 'ins-read-article',
    templateUrl: './read-article.component.html',
})
export class ReadArticleComponent {
    @Input() public smartTip: SmartTipsEnum;

    public appRoutesEnum = AppRoutesEnum;
}
