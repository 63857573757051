import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WucCardModule, WucIconUiModule } from '../../common';
import { WucCarouselComponent } from './carousel.component';

@NgModule({
    declarations: [WucCarouselComponent],
    exports: [WucCarouselComponent],
    imports: [WucCardModule, CommonModule, WucIconUiModule],
})
export class WucCarouselModule {}
