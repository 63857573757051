import { DOCUMENT, isPlatformServer } from '@angular/common';
import { EventEmitter, Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PageScrollOptions, PageScrollService } from 'ngx-page-scroll-core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class ScrollService {
    constructor(
        private pageScrollService: PageScrollService,
        private route: ActivatedRoute,
        @Inject(PLATFORM_ID) private platformId: string,
        @Inject(DOCUMENT) private document: Document,
    ) {}

    public scrollToFragment(delay: number = 0, offset: number = 0): Observable<void> {
        return this.route.fragment.pipe(
            map((fragment) => {
                if (fragment) {
                    this.scrollElementOffset(`#${fragment}`, delay, offset);
                }
            }),
        );
    }

    public scrollElementOffset(element: string, delay: number = 0, offset: number = 0): EventEmitter<boolean> {
        if (isPlatformServer(this.platformId)) {
            return;
        }

        if (offset === 0) {
            offset = this.getOffset();
        }

        const eventEmitter = new EventEmitter<boolean>();

        const pageScrollTimeout = setTimeout(() => {
            this.pageScrollService.scroll({
                document: this.document,
                scrollTarget: element,
                scrollOffset: offset,
                scrollFinishListener: eventEmitter,
            });
            clearTimeout(pageScrollTimeout);
        }, delay);

        return eventEmitter;
    }

    public scrollToElement(element: string | HTMLElement, delay: number = 0, scrollingView: HTMLElement = null): void {
        if (isPlatformServer(this.platformId)) {
            return;
        }

        if (!scrollingView) {
            scrollingView = this.getScrollParent(element);
        }

        const options: PageScrollOptions = {
            document: this.document,
            scrollTarget: element,
        };

        if (scrollingView) {
            options.scrollViews = [scrollingView];
            options.advancedInlineOffsetCalculation = true;
        }

        const pageScrollTimeout = setTimeout(() => {
            this.pageScrollService.scroll(options);
            clearTimeout(pageScrollTimeout);
        }, delay);
    }

    public scrollToTop(delay: number = 0): void {
        if (isPlatformServer(this.platformId)) {
            return;
        }

        const pageScrollTimeout = setTimeout(() => {
            this.pageScrollService.scroll({
                document: this.document,
                scrollTarget: 'body',
            });
            clearTimeout(pageScrollTimeout);
        }, delay);
    }

    public goToHeadingInContainer(nativeElement: any): void {
        if (isPlatformServer(this.platformId)) {
            return;
        }

        const pageScrollTimeout = setTimeout(() => {
            this.pageScrollService.scroll({
                document: this.document,
                scrollTarget: '.panel__mutatie.scroll',
                scrollViews: [nativeElement],
            });
            clearTimeout(pageScrollTimeout);
        }, 2000);
    }

    public goToClass(className: string): void {
        if (isPlatformServer(this.platformId)) {
            return;
        }

        const pageScrollTimeout = setTimeout(() => {
            this.pageScrollService.scroll({
                document: this.document,
                scrollTarget: className,
            });
            clearTimeout(pageScrollTimeout);
        }, 0);
    }

    private getOffset(): number {
        const stickybar = this.document.querySelector('.section--sticky');
        if (!stickybar) {
            return 0;
        }
        return stickybar.clientHeight;
    }

    private getScrollParent(element: string | HTMLElement): HTMLElement {
        if (!(element instanceof HTMLElement)) {
            element = this.document.querySelector(element) as HTMLElement;
        }

        if (!element) {
            return null;
        }

        if (element.scrollHeight > element.clientHeight) {
            return element;
        } else {
            return this.getScrollParent(element.parentElement);
        }
    }
}
