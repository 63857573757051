export enum SalesfunnelRoutes {
    CarInsurance = 'autoversicherung', // Deze misschien ooit verplaatsen naar de routes van de producten
    MopedInsurance = 'brommerverzekering',
    Calculate = 'berechnen',
    CalculateCarInsurance = 'autoversicherung-berechnen',
    CalculateCarInsuranceVariant = 'berechnen-autoversicherung',
    CalculateMopedInsurance = 'brommerverzekering-berekenen',
    Thanks = 'danke',
    Activate = 'aktivieren',
    InsuranceCoverage = 'versicherungsschutz',
    PersonalData = 'personlichen-daten',
    Insure = 'versichern',
    Summary = 'abrundung',
}
