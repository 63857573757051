import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DetailsTableModule } from '../../details-table/details-table.module';
import { CorrespondenceAddressDataComponent } from './correspondence-address-data.component';
import { CorrespondenceAddressDataContainer } from '@app-de/my-zone/shared/correspondence-address/correspondence-address-data/correspondence-address-data.container';

@NgModule({
    imports: [CommonModule, DetailsTableModule],
    exports: [CorrespondenceAddressDataContainer],
    declarations: [
        CorrespondenceAddressDataComponent,
        CorrespondenceAddressDataContainer,
    ],
})
export class CorrespondenceAddressDataModule {
}
