import { inject, Injectable } from '@angular/core';
import { CookieConsent } from '@inshared/shared/core';
import { COOKIE_SETTING_KEY } from 'outshared-lib/core/constants';
import { CookieStorage } from '@inshared/shared/util-storage';

@Injectable({
    providedIn: 'root',
})
export class CookieSettingsService {
    private isCookieSettingsWallModalVisible = false;
    public cookieConsent = CookieConsent;

    private cookieStorage = inject(CookieStorage);

    public isCookieWallVisible = () => this.isCookieSettingsWallModalVisible;

    public isValidCookieValue = (cookieValue: string): boolean =>
        [this.cookieConsent.All, this.cookieConsent.Basic].includes(cookieValue as CookieConsent);

    public saveSettings(cookieSettings: CookieConsent): void {
        this.cookieStorage.setItem(COOKIE_SETTING_KEY, cookieSettings, {
            path: '/',
        });
    }

    public showCookieWall() {
        this.isCookieSettingsWallModalVisible = true;
    }
    public hideCookieWall() {
        this.isCookieSettingsWallModalVisible = false;
    }
}
