import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AccountRecoveryModalRoutesEnum } from '@app-de/routes';

const enum ForgotTypes {
    Password = 'password',
    Email = 'email',
}

@Component({
    selector: 'ins-account-recovery-modal',
    templateUrl: 'account-recovery.modal.html',
})
export class AccountRecoveryModal implements OnInit {
    public form!: UntypedFormGroup;

    public get type(): AbstractControl {
        return this.form.get('type');
    }

    constructor(private router: Router) {}

    public ngOnInit(): void {
        this.form = new UntypedFormGroup({
            type: new UntypedFormControl(ForgotTypes.Password, [Validators.required]),
        });
    }

    public onSubmit(): void {
        if (!this.form.valid) {
            return;
        }
        switch (this.type.value) {
            case ForgotTypes.Email:
                this.openModal(AccountRecoveryModalRoutesEnum.ForgotEmail);
                break;
            case ForgotTypes.Password:
                this.openModal(AccountRecoveryModalRoutesEnum.ForgotPassword);
                break;
        }
    }

    private openModal(modal: AccountRecoveryModalRoutesEnum): void {
        this.router.navigate(['', { outlets: { modal: modal } }]);
    }
}
