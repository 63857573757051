import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { PhoneConfigInterface } from '@app-de/shared/cyno-form/cyno-phone/phone-config.interface';
import { PhoneCountryInterface } from '@app-de/shared/cyno-form/cyno-phone/phone-country.interface';
import { PhoneFormatResultInterface } from '@app-de/shared/cyno-form/cyno-phone/phone-format-result.interface';
import { PhoneNumberFormatInterface } from '@app-de/shared/cyno-form/cyno-phone/phone-number-format.interface';
import { PhonePrefixTypeEnum } from '@app-de/shared/cyno-form/cyno-phone/phone-prefix-type.enum';

@Injectable({
    providedIn: 'root',
})
export class PhoneService {
    // service based on https://catamphetamine.gitlab.io/libphonenumber-js/
    public static config: PhoneConfigInterface = {
        // Netherlands (NL)
        // http://en.wikipedia.org/wiki/%2B31
        // http://wetten.overheid.nl/BWBR0010198
        NL: {
            countryCode: '31',
            internationalPrefix: '00',
            nationalPrefix: '0',
            nationalNumberPattern: '(?:[124-7]\\d\\d|3(?:[02-9]\\d|1[0-8]))\\d{6}|[89]\\d{6,9}|1\\d{4,5}',
            formats: [
                {
                    description: 'Fixed line 3 digit area codes',
                    pattern: '(\\d{3})(\\d{2})(\\d{2})(\\d{2})',
                    format: '$1 - $2 $3 $4',
                    testPattern: ['1[16-8]|2[259]|3[124]|4[17-9]|5[124679]'],
                },
                {
                    description: 'Fixed line 2 digit area codes',
                    pattern: '(\\d{2})(\\d{3})(\\d{2})(\\d{2})',
                    format: '$1 - $2 $3 $4',
                    testPattern: ['[1-57-9]'],
                },
                {
                    description: 'Mobile',
                    pattern: '(\\d)(\\d{2})(\\d{2})(\\d{2})(\\d{2})',
                    format: '$1 - $2 $3 $4 $5',
                    testPattern: ['6[1-58]'],
                },
            ],
            invalidNumbers: ['[8|9]00'],
        },
        // Germany (DE)
        // http://www.itu.int/oth/T0202000051/en
        // http://en.wikipedia.org/wiki/Telephone_numbers_in_germany
        // Due to the high complexity of ranges in the German numbering scheme, the regular
        // expressions here have been automatically simplified to reduce size. This means that
        // in some cases there may be false positives (especially in fixed line ranges), but since
        // German ranges differ so much by length anyway, false positives are already common.
        DE: {
            countryCode: '49',
            internationalPrefix: '00',
            nationalPrefix: '0',
            nationalNumberPattern:
                '[2579]\\d{5,14}|49(?:[34]0|69|8\\d)\\d\\d?|49(?:37|49|60|7[089]|9\\d)\\d{1,3}|49(?:[12]\\d|3[2-689]|7[1-7])\\d{1,8}|(?:1|[368]\\d|4[0-8])\\d{3,13}|49(?:[05]\\d|31|[46][1-8])\\d{1,9}',
            formats: [
                {
                    description: 'Fixed line, 2 digit area codes',
                    pattern: '(\\d{2})(\\d{3,13})',
                    format: '$1 $2',
                    testPattern: ['3[02]|40|[68]9'],
                },
                {
                    description: 'Fixed line, 3 digit area codes',
                    pattern: '(\\d{3})(\\d{3,12})',
                    format: '$1 $2',
                    testPattern: [
                        '2(?:0[1-389]|1[124]|2[18]|3[14])|3(?:[35-9][15]|4[015])|906|(?:2[4-9]|4[2-9]|[579][1-9]|[68][1-8])1',
                        '2(?:0[1-389]|12[0-8])|3(?:[35-9][15]|4[015])|906|2(?:[13][14]|2[18])|(?:2[4-9]|4[2-9]|[579][1-9]|[68][1-8])1',
                    ],
                },
                {
                    description: 'Fixed line, 4 digit area codes. Only area code 5361 has two digit subscriber numbers',
                    pattern: '(\\d{4})(\\d{2,11})',
                    format: '$1 $2',
                    testPattern: [
                        '[24-6]|3(?:[3569][02-46-9]|4[2-4679]|7[2-467]|8[2-46-8])|70[2-8]|8(?:0[2-9]|[1-8])|90[7-9]|[79][1-9]',
                        '[24-6]|3(?:3(?:0[1-467]|2[127-9]|3[124578]|7[1257-9]|8[1256]|9[145])|4(?:2[135]|4[13578]|9[1346])|5(?:0[14]|2[1-3589]|6[1-4]|7[13468]|8[13568])|6(?:2[1-489]|3[124-6]|6[13]|7[12579]|8[1-356]|9[135])|7(?:2[1-7]|4[145]|6[1-5]|7[1-4])|8(?:21|3[1468]|6|7[1467]|8[136])|9(?:0[12479]|2[1358]|4[134679]|6[1-9]|7[136]|8[147]|9[1468]))|70[2-8]|8(?:0[2-9]|[1-8])|90[7-9]|[79][1-9]|3[68]4[1347]|3(?:47|60)[1356]|3(?:3[46]|46|5[49])[1246]|3[4579]3[1357]',
                    ],
                },
                {
                    description: 'Short shared cost numbers',
                    pattern: '(\\d{3})(\\d{4})',
                    format: '$1 $2',
                    testPattern: ['138'],
                },
                {
                    description: 'Fixed line, 5 digit area codes',
                    pattern: '(\\d{5})(\\d{2,10})',
                    format: '$1 $2',
                    testPattern: ['3'],
                },
                {
                    description: 'UAN (181) numbers',
                    pattern: '(\\d{3})(\\d{5,11})',
                    format: '$1 $2',
                    testPattern: ['181'],
                },
                {
                    description: 'Mobile/pager format (from ITU document). Actual usage varies',
                    pattern: '(\\d{3})(\\d{7,8})',
                    format: '$1 $2',
                    testPattern: ['1[67]'],
                },
                {
                    description:
                        'Where we have seen prefixes in use for the IVPN/User Group numbers, we format it the way it is generally written. For other prefixes, we fall back to using a three-digit prefix since we have currently no more information to allow us to format these more precisely',
                    pattern: '(\\d{5})(\\d{6})',
                    format: '$1 $2',
                    testPattern: ['1850{0,2}'],
                },
                {
                    description: 'Personal numbers',
                    pattern: '(\\d{3})(\\d{4})(\\d{4})',
                    format: '$1 $2 $3',
                    testPattern: ['7'],
                },
                {
                    description: 'Various UAN numbers',
                    pattern: '(\\d{4})(\\d{7})',
                    format: '$1 $2',
                    testPattern: ['18[68]'],
                },
                {
                    description:
                        'Some mobile numbers (carrier services etc..) have a 6 digit NSN and need to be formatted with 5 leading digits (as opposed to the 4.7 format). These are all in the ranges 150, 155, 156 and 158.',
                    pattern: '(\\d{5})(\\d{6})',
                    format: '$1 $2',
                    testPattern: ['15[0568]'],
                },
                {
                    description: 'Mobile format for 15x mobile numbers',
                    pattern: '(\\d{4})(\\d{7})',
                    format: '$1 $2',
                    testPattern: ['15[1279]'],
                },
                {
                    description: 'Various UAN numbers',
                    pattern: '(\\d{3})(\\d{8})',
                    format: '$1 $2',
                    testPattern: ['18'],
                },
            ],
            invalidNumbers: ['180|137'],
        },
    };

    private countryPhonenumberConfig: PhoneCountryInterface;

    constructor(@Inject(LOCALE_ID) locale: string) {
        this.countryPhonenumberConfig = PhoneService.getConfigFor(locale);
    }

    /**
     * Formats the given phonenumer
     * @param phonenumber - User phonenumber input, possibly containing dashes, spaces, etc.
     * @return The formatted phonenumber
     */
    public format(phonenumber: string): PhoneFormatResultInterface | null {
        if (!phonenumber) {
            return null;
        }

        const sanitized = this.sanitize(phonenumber);
        const usedCountryCode = this.getPhonenumberCountryCode(sanitized);

        if (usedCountryCode && usedCountryCode !== this.countryPhonenumberConfig.countryCode) {
            return null;
        }

        const usedPrefix = this.getPhonenumberPrefixType(sanitized);
        const dePrefixed = this.removePrefix(sanitized);
        const matchingNumberFormat = this.getMatchingNumberFormat(dePrefixed);
        const formatted = matchingNumberFormat
            ? this.applyFormat(dePrefixed, matchingNumberFormat.pattern, matchingNumberFormat.format)
            : dePrefixed;

        return {
            formatted: this.applyPrefix(formatted, usedPrefix, true),
            cleaned: this.applyPrefix(dePrefixed, usedPrefix, false),
        };
    }

    /**
     * Removes al non-numeric characters from the phonenumber except a leading '+'
     * @param phonenumber - User phonenumber input, possibly containing dashes, spaces, etc.
     * @return The input without non-numeric characters except a leading '+'
     */
    private sanitize(phonenumber: string): string {
        return phonenumber?.replace(/(?!^\+)[^\d]/g, '') || '';
    }

    /**
     * Determines the country code for the given number
     * @param phonenumber - Sanitized phonenumber, may only contain numbers and a leading '+'
     * @return The used country code or null
     */
    private getPhonenumberCountryCode(phonenumber: string): string | null {
        return phonenumber?.match(/^(\+|00)(?<countryCode>\d{2})/)?.groups.countryCode || null;
    }

    /**
     * Determines the way the number is entered, national or international with leading zeros or the + sign
     * @param phonenumber - Sanitized phonenumber, may only contain numbers and a leading '+'
     * @return The detected prefix type
     */
    private getPhonenumberPrefixType(phonenumber: string): PhonePrefixTypeEnum | null {
        if (!phonenumber) {
            return null;
        }
        if (phonenumber.startsWith('+')) {
            return PhonePrefixTypeEnum.InternationalPlus;
        } else if (phonenumber.startsWith(this.countryPhonenumberConfig.internationalPrefix)) {
            return PhonePrefixTypeEnum.InternationalZeros;
        }
        return PhonePrefixTypeEnum.National;
    }

    /**
     * Removes the prefix leaving the bare national number (without leading 0)
     * e.g. +316123456789, +3106123456789, 00316123456789, 003106123456789 or 06123456789 --> 6123456789
     * @param phonenumber - Sanitized phonenumber, may only contain numbers and optional a leading '+'
     * @return A phonenumber without leading 0's of '+' sign
     */
    private removePrefix(phonenumber: string): string {
        return phonenumber?.replace(/^(\+\d{2}0?|00\d{2}0?|0)/, '') || '';
    }

    /**
     * Returns the matching number format
     * @param phonenumber - Sanitized phonenumber whithout prefix
     * @return A mathing number format
     */
    private getMatchingNumberFormat(phonenumber: string): PhoneNumberFormatInterface {
        return this.countryPhonenumberConfig.formats.find((item: PhoneNumberFormatInterface) =>
            item.testPattern.some((pattern: string) => phonenumber.match(new RegExp(`^(${pattern})`))),
        );
    }

    /**
     * Formats phonenumber
     * @param phonenumber - Sanitized phonenumber whithout prefix
     * @param pattern - regular expression for grouping digits (e.g. '(\\d{2})(\\d{3})(\\d{2})(\\d{2})')
     * @param format - string containing format structure (e.g. '$1 - $2 $3 $4')
     * @return A formatted phonenumber without prefix
     */
    private applyFormat(phonenumber: string, pattern: string, format: string): string {
        if (!phonenumber || !pattern || !format) {
            return phonenumber || '';
        }
        return phonenumber.replace(new RegExp(pattern), format);
    }

    /**
     * Adds prefix according to a previously determined prefix type
     * @param phonenumber - phonenumber whithout prefix
     * @param prefixType - type of prefix to prepend
     * @param useSpacing - adds a space between prefix and phonenumber
     * @return The given phonenumber with prefix added
     */
    private applyPrefix(phonenumber: string, prefixType: PhonePrefixTypeEnum, useSpacing: boolean = true): string {
        const space = useSpacing ? ' ' : '';
        switch (prefixType) {
            case PhonePrefixTypeEnum.National:
                return `${this.countryPhonenumberConfig.nationalPrefix}${phonenumber}`;
            case PhonePrefixTypeEnum.InternationalZeros:
                return `${this.countryPhonenumberConfig.internationalPrefix}${this.countryPhonenumberConfig.countryCode}${space}${phonenumber}`;
            case PhonePrefixTypeEnum.InternationalPlus:
                return `+${this.countryPhonenumberConfig.countryCode}${space}${phonenumber}`;
            default:
                return phonenumber;
        }
    }

    /**
     * Gets the section of the config for given locale
     * @param locale - the locale
     * @return The section of the config for given locale
     */
    private static getConfigFor(locale: string): PhoneCountryInterface | null {
        return PhoneService.config[locale.toUpperCase()] || null;
    }
}
