import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RequestResponse, RequestService } from '../../../core';
import { CoveragePremiumInterface } from '../../interfaces';
import { CalculateCoveragePremiumMapper } from '../../mappers';

/** @deprecated use InsurancePolicyActionService */
const RESOURCES = {
    CALCULATE_COVERAGE_PREMIUM: 'en-gb/insurance-coverage/calculate/coverage-premium-purchased_product',
};

@Injectable({
    providedIn: 'root',
})
export class CoveragePremiumService {
    constructor(private requestService: RequestService) {}

    /** @deprecated use InsurancePolicyActionService */
    public calculateCoveragePremiumPurchasedProduct$(props: {
        purchased_product_sequence_number: number;
    }): Observable<CoveragePremiumInterface> {
        const { purchased_product_sequence_number } = props;

        return this.requestService
            .post(
                RESOURCES.CALCULATE_COVERAGE_PREMIUM,
                CalculateCoveragePremiumMapper(purchased_product_sequence_number)
            )
            .pipe(
                map((result) => result.data),
                map((coverage) => this.patchDefaultCoverageValues(coverage)),
                map((coverage) => this.addPremiumTax(coverage))
            );
    }

    /** @deprecated use InsurancePolicyActionService */
    public changeGenericPurchasedCoverages(props: {
        purchased_product_sequence_number: number;
        start_date: string;
        coverage_ids: {
            coverage_id: string;
        }[];
    }): Observable<RequestResponse> {
        const { purchased_product_sequence_number, start_date, coverage_ids } = props;
        const payload = {
            purchased_product: {
                purchased_product_sequence_number,
            },
            claim_coverage: {
                start_date,
                coverages: coverage_ids,
            },
            premium_factor: {
                campaign_code: null,
            },
        };

        return this.requestService.post('en-gb/insurance-coverage/change_generic/purchasedcoverages', payload);
    }

    /** @deprecated use InsurancePolicyActionService */
    public cancelFuturePurchasedCoverage(props: {
        purchased_product_sequence_number: number;
    }): Observable<RequestResponse> {
        const { purchased_product_sequence_number } = props;
        const payload = {
            purchased_product: {
                purchased_product_sequence_number,
            },
        };

        return this.requestService.post('en-gb/insurance-coverage/cancel/future-purchased_coverage', payload);
    }

    /** @deprecated use InsurancePolicyActionService */
    private patchDefaultCoverageValues(coverage: CoveragePremiumInterface): CoveragePremiumInterface {
        const keys = ['total_premium', 'total_discount', 'total_premium_including_discount'];

        keys.forEach((key) => {
            coverage.product[key] = {
                basic_premium: 0,
                premium: 0,
                premium_including_premium_tax: 0,
                premium_tax: 0,
            };
        });

        return coverage;
    }

    /** @deprecated use InsurancePolicyActionService */
    private addPremiumTax(coveragePremium: CoveragePremiumInterface): CoveragePremiumInterface {
        const { product } = coveragePremium;
        const baseCoverages = product.base_coverages ? product.base_coverages : [];
        const additionalCoverage = product.additional_coverage ? product.additional_coverage : [];
        const coverages = [...baseCoverages, ...additionalCoverage];

        coverages.forEach((coverage) => {
            if (!coverage.purchased_coverage) {
                return;
            }

            if (coverage.premium) {
                product.total_premium.premium_including_premium_tax += coverage.premium.premium_including_premium_tax;
            }

            if (coverage.discount) {
                product.total_discount.premium_including_premium_tax += coverage.discount.premium_including_premium_tax;
            }

            if (coverage.premium_including_discount) {
                product.total_premium_including_discount.premium_including_premium_tax +=
                    coverage.premium_including_discount.premium_including_premium_tax;
            }
        });

        return Object.assign({}, coveragePremium, { product });
    }
}
