export enum AutoversicherungRoutesEnum {
    Autoversicherung = '',
    EAutoversicherung = 'e-auto-versicherung',
    KfzHaftpflichtversicherung = 'kfz-haftpflichtversicherung',
    AutoversicherungVergleichen = 'autoversicherung-vergleichen',
    Teilkasko = 'teilkasko',
    Vollkasko = 'vollkasko',
    Fahrerschutz = 'fahrerschutz',
    Pannenhilfe = 'pannenhilfe',
    Reviews = 'bewertungen',
    CarBrands = 'automarken',
    RatgeberZulassungsstelle = 'ratgeber-zulassungsstelle',
    AutoSicherheitRatgeber = 'auto-sicherheit-ratgeber',
    Fahrerassistenzsysteme = 'fahrerassistenzsysteme',
    Notbremsassistent = 'notbremsassistent',
    AdaptiveGeschwindigkeitsregelanlage = 'adaptive-geschwindigkeitsregelanlage',
    Fernlichtassistent = 'fernlichtassistent',
    Verkehrszeichenerkennung = 'verkehrszeichenerkennung',
    Spurwechselassistent = 'spurwechselassistent',
    FahrzeugscheinAufbau = 'fahrzeugschein-aufbau',
    IKfzOnlineAutozulassung = 'i-kfz-online-autozulassung',
    HsnTsnFin = 'hsn-tsn-fin',
    AutounfallPannenSchaden = 'autounfall-pannen-schaden',
    Wildunfall = 'wildunfall',
    Steinschlag = 'steinschlag',
    Lichtautomatik = 'lichtautomatik',
    SchadenfreiheitsKlasse = 'schadenfreiheitsklasse',
    SchadenfreiheitsRabatt = 'schadenfreiheitsrabatt',
    AutonomesFahren = 'autonomes-fahren',
    Abstandsregeltempomat = 'abstandsregeltempomat',
    Spurhalteassistent = 'spurhalteassistent',
    AutoRegionalklassenTypklassen = 'auto-regionalklassen-typklassen',
}

export enum AutoversicherungCitiesRoutesEnum {
    Koeln = 'koeln',
    Bonn = 'bonn',
    Berlin = 'berlin',
    Muenchen = 'muenchen',
    Hamburg = 'hamburg',
}
