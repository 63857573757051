import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CoveragePremiumInterface, CoveragePremiumSelectorService, ProductBaseInterface } from 'outshared-lib';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IntersectionObserverService } from '@app-de/core/services/intersection-observer.service';
import { SelectedPanelEnum } from '@app-de/my-zone/enums';
import { InsuranceListQueryParams } from '@app-de/my-zone/interfaces';

@Component({
    selector: 'ins-insurance-list-container',
    template: `
        <ins-insurance-list
            [product]="product"
            [queryParams]="queryParams$ | async"
            [calculation]="selectedCalculation$ | async"
            [isScreenSmall]="isScreenSmall$ | async"
        ></ins-insurance-list>
    `,
})
export class InsuranceListContainer {
    @Input() public product: ProductBaseInterface;

    public isScreenSmall$: Observable<boolean> = this.intersectionObserverService.isScreenSmall$;
    public selectedCalculation$: Observable<CoveragePremiumInterface> =
        this.coveragePremiumSelectorService.getAllCoverages$.pipe(
            map((coverages) => this.findCoverage(coverages, this.product.purchased_product_sequence_number)),
        );

    public queryParams$: Observable<InsuranceListQueryParams> = this.route.queryParams.pipe(
        map((params) => {
            const panel = params.panel ?? SelectedPanelEnum.Close;
            const purchasedProduct = Number(params.purchased_product) ?? null;
            const hasScroll = Boolean(params.scroll === 'true') ?? null;

            return {
                panel: panel,
                purchased_product: purchasedProduct,
                scroll: hasScroll,
            };
        }),
    );

    constructor(
        private route: ActivatedRoute,
        private coveragePremiumSelectorService: CoveragePremiumSelectorService,
        private intersectionObserverService: IntersectionObserverService,
    ) {}

    private findCoverage(
        coverages: CoveragePremiumInterface[],
        purchasedProductSequenceNumber: number,
    ): CoveragePremiumInterface {
        return coverages.find(
            (coverage) => coverage.purchased_product_sequence_number === purchasedProductSequenceNumber,
        );
    }
}
