import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { DamageFreeYearsImpactRequestInterface } from '../interfaces';
import { DamageFreeYearsImpactState } from '../store';
import * as DamageFreeYearsImpactActions from '../store/damage-free-years-impact.actions';
import { DamageFreeYearsImpactEventService } from './damage-free-years-impact.event.service';

@Injectable({
    providedIn: 'root',
})
export class DamageFreeYearsImpactActionService {
    constructor(
        private store$: Store<DamageFreeYearsImpactState>,
        private damageFreeYearsImpactEventService: DamageFreeYearsImpactEventService
    ) {}

    public damageFreeYearsImpact$(request: DamageFreeYearsImpactRequestInterface): Observable<void> {
        this.store$.dispatch(DamageFreeYearsImpactActions.damageFreeYearsImpact({ request }));
        return this.damageFreeYearsImpactEventService.damageFreeYearsImpact$();
    }
}
