<ng-container *ngIf="errors && errors.length">
    <cyno-error>
        <ng-container *ngFor="let error of errors">
            <ng-container [ngSwitch]="error.id">
                <span *ngSwitchCase="'15138-1'">
                    Tragen Sie bitte eine korrekte HSN und TSN (Herstellerschlüssel- und Typschlüsselnummer) ein. Sind
                    alle Infos richtig? Dann nehmen Sie bitte
                    <a [routerLink]="['/', appRoutesEnum.Contact, { outlets: { modal: modalRoutesEnum.Question } }]">Kontakt mit uns</a>
                    auf.
                </span>
                <ng-container *ngSwitchDefault>
                    <span
                        *ngIf="allowHtml"
                        [innerHTML]="(error.message_content ? error.message_content : error.message) | sanitize"
                    ></span>
                    <span *ngIf="!allowHtml">
                        {{ error.message_content ? error.message_content : error.message }}
                    </span>
                </ng-container>
            </ng-container>
        </ng-container>
    </cyno-error>
</ng-container>
