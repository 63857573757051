import { Routes } from '@angular/router';
import { KnowledgebaseRoutesEnum } from '@app-de/pages/car-insurance/knowledgebase';
import { AutoversicherungRoutesEnum } from './../../autoversicherung/autoversicherung-routes.enum';

export const kostenRoutes: Routes = [
    {
        path: `${KnowledgebaseRoutesEnum.Costs}`,
        loadChildren: () => import('./kosten/kosten.module').then((m) => m.KostenModule),
    },
    {
        path: `${KnowledgebaseRoutesEnum.Costs}/${AutoversicherungRoutesEnum.HsnTsnFin}`,
        loadChildren: () => import('./hsn-tsn-fin/hsn-tsn-fin.module').then((m) => m.HsnTsnFinModule),
    },
    {
        path: `${KnowledgebaseRoutesEnum.Costs}/${KnowledgebaseRoutesEnum.Schadenfreiheitsrabatt}`,
        pathMatch: 'full',
        loadChildren: () =>
            import('./bonus-malus/bonus-malus.module').then((m) => m.BonusMalusModule),
    },
    {
        path: `${KnowledgebaseRoutesEnum.Costs}/${AutoversicherungRoutesEnum.AutoRegionalklassenTypklassen}`,
        pathMatch: 'full',
        loadChildren: () =>
            import('./auto-regionalklassen-typklassen/auto-regionalklassen-typklassen.module').then((m) => m.AutoRegionalklassenTypklassenModule),
    },
    {
        path: `${KnowledgebaseRoutesEnum.Costs}/${KnowledgebaseRoutesEnum.Schadenfreiheitsklasse}`,
        loadChildren: () =>
            import('./schadenfreiheitsklasse/schadenfreiheitsklasse.module').then(
                (m) => m.SchadenfreiheitsklasseModule,
            ),
    },
];
