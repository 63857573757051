<ins-modal-close></ins-modal-close>
<ins-modal-title>Versicherung kündigen</ins-modal-title>

<ins-modal-content>
    <p>Sind Sie sicher, dass Sie Ihre Versicherung kündigen möchten?</p>
</ins-modal-content>

<ins-modal-actions>
    <button (click)="onCancel()" class="btn btn--link">Nein, doch nicht</button>
    <button (click)="onConfirm()" class="btn btn--primary" e2e="modal_submit">Ja, Versicherung kündigen</button>
</ins-modal-actions>
