<div class="change-driver">
    <div class="change-driver__heading">
        <h3>Hauptfahrer ändern</h3>
    </div>
    <form [formGroup]="form" *ngIf="options$ | async as options">
        <wuc-form-field name="relationCode">
            <wuc-form-field-label>Wer fährt am häufigsten mit Ihrem Auto?</wuc-form-field-label>
            <ng-container *ngFor="let option of options">
                <wuc-input-radio
                    name="relationCode"
                    id="driver-{{ option.content_description }}"
                    formControlName="relationCode"
                    [value]="option.content_id"
                >
                    {{ option.content_description }}
                </wuc-input-radio>
            </ng-container>
        </wuc-form-field>
    </form>
    <div class="change-driver__content" *ngIf="relationCode.value === apiRelationCodeEnum.Child">
        <wuc-balloon [hideShadow]="true">
            Bitte beachten: Dies ist nur möglich, wenn das Auto auf Sie zugelassen ist und nicht auf Ihr Kind. Ihr Kind
            muss zudem an derselben Adresse wohnen.
        </wuc-balloon>
    </div>
    <div class="change-driver__content" *ngIf="relationCode.value === apiRelationCodeEnum.Partner">
        <wuc-balloon [hideShadow]="true">
            Bitte beachten: Ihr Partner muss an derselben Adresse wie Sie wohnen.
        </wuc-balloon>
    </div>
    <ng-container
        *ngIf="
            [apiRelationCodeEnum.Owner, apiRelationCodeEnum.Partner, apiRelationCodeEnum.Child].includes(
                relationCode.value
            )
        "
        [ngSwitch]="relationCode.value"
    >
        <ng-container *ngSwitchDefault>
            <ins-insurance-policy-holder-form
                [errors]="errors"
                [product]="product"
                [isLoading]="isLoading"
                (removeRegularDriver)="onRemoveRegularDriver($event)"
            ></ins-insurance-policy-holder-form>
        </ng-container>
        <ng-container *ngSwitchCase="apiRelationCodeEnum.Partner">
            <ins-insurance-deviant-driver-form
                [errors]="errors"
                [product]="product"
                [isLoading]="isLoading"
                [relationCode]="apiRelationCodeEnum.Partner"
                (createRegularDriver)="onCreateRegularDriver($event)"
            ></ins-insurance-deviant-driver-form>
        </ng-container>
        <ng-container *ngSwitchCase="apiRelationCodeEnum.Child">
            <ins-insurance-deviant-driver-form
                [errors]="errors"
                [product]="product"
                [isLoading]="isLoading"
                [relationCode]="apiRelationCodeEnum.Child"
                (createRegularDriver)="onCreateRegularDriver($event)"
            ></ins-insurance-deviant-driver-form>
        </ng-container>
    </ng-container>
</div>
