import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { Router } from '@angular/router';
import { CreateBankDetailsInterface, RelationActionService } from 'outshared-lib';
import { DetailsFormClass } from '@app-de/my-zone/classes';

@Component({
    selector: 'ins-payment-details-form-container',
    template: `
        <ins-payment-details-form-component
            [disabled]="disabled"
            [errors]="errors"
            (paymentDetailsSubmitted)="onSubmit($event)"
        ></ins-payment-details-form-component>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentDetailsFormContainer extends DetailsFormClass {
    constructor(
        private relationActionService: RelationActionService,
        router: Router,
        changeDetectorRef: ChangeDetectorRef,
    ) {
        super(changeDetectorRef, router);
    }

    public onSubmit(payload: CreateBankDetailsInterface): void {
        this.beforeOnSubmit();
        this.relationActionService.createBankDetails$(payload).subscribe({
            next: () => this.handleSuccess(),
            error: (error) => this.handleErrors(error),
        });
    }
}
