import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { WucIconModule, WucIconUiModule, WucSkeletonModule } from '@inshared/website-ui-components';
import { AuthenticationMenuComponent } from './authentication-menu.component';
import { LogoutModalModule } from '@app-de/modals/logout-modal/logout.modal.module';
import { DropdownToggleModule } from '@app-de/shared/directives/dropdown/dropdown.module';

@NgModule({
    declarations: [AuthenticationMenuComponent],
    imports: [
        CommonModule,
        RouterModule,
        DropdownToggleModule,
        LogoutModalModule,
        WucIconUiModule,
        WucIconModule,
        WucSkeletonModule,
    ],
    exports: [AuthenticationMenuComponent],
})
export class AuthenticationMenuModule {}
