import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
    CreateResidentialAddressInterface,
    CreateResidentialAddressResponseInterface,
    ErrorInterface,
    NewModal,
    ProductEnGbInterface,
    RelationActionService,
    RelationDataService,
    RelationInterface,
} from 'outshared-lib';
import { combineLatest, Observable, of, Subscription, switchMap } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { DetailsSectionEnum } from '@app-de/core/enums';
import { MyZoneRoutes } from '@app-de/my-zone/routing';
import { PremiumImpactModal } from '@app-de/my-zone/shared/premium-impact-modal';
import { PremiumImpactInterface } from '@app-de/my-zone/shared/premium-impact-modal/premium-impact.interface';
import { AppRoutesEnum } from '@app-de/routes';

@Component({
    selector: 'ins-residential-address-form-container',
    template: `
        <ins-residential-address-form [errors]="errors" [loading]="loading" (submitted)="onSubmit($event)">
        </ins-residential-address-form>`,
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class ResidentialAddressFormContainer implements OnDestroy, OnInit {
    public relation$: Observable<RelationInterface>;
    public errors: ErrorInterface[];
    public loading: boolean;

    private subscription: Subscription;

    constructor(
        private router: Router,
        private relationActionService: RelationActionService,
        private relationDataService: RelationDataService,
        private newModal: NewModal,
        private changeDetectorRef: ChangeDetectorRef,
    ) {
        this.errors = [];
        this.loading = false;
        this.subscription = new Subscription();
    }

    public ngOnInit(): void {
        this.relation$ = this.relationDataService.getRelation$().pipe(
            filter((relation: RelationInterface) => !!relation),
            take(1),
        );
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public onSubmit(payload: CreateResidentialAddressInterface): void {
        const referenceDate = payload.startDate;
        const createResidentialAddress = this.relationActionService
            .createResidentialAddress$(payload)
            .pipe(
                take(1),
                switchMap((response) =>
                    combineLatest([of(response), this.relationActionService.premiumModificationPurchasedProducts$({
                        referenceDate,
                    })]),
                ),
            )
            .subscribe({
                next: ([addressResponse, premiumResponse]) =>
                    this.onCreateResidentialAddressSuccess(addressResponse, premiumResponse),
                error: (errors: ErrorInterface[]) => this.onCreateResidentialAddressError(errors),
            });

        this.startLoading();
        this.subscription.add(createResidentialAddress);
    }

    private startLoading(): void {
        this.loading = true;
        this.errors = [];
    }

    private stopLoading(): void {
        this.loading = false;
        this.router.navigate([AppRoutesEnum.MyZone, MyZoneRoutes.Details], {
            queryParams: {
                section: DetailsSectionEnum.AddressPanel,
                expand: false,
            },
        });
    }

    private onCreateResidentialAddressSuccess(
        addressResponse: CreateResidentialAddressResponseInterface,
        premiumResponse: ProductEnGbInterface[],
    ): void {
        this.stopLoading();

        const sequenceNumbers: number[] = [
            ...new Set([
                ...addressResponse.purchasedProducts.map((product) => product.purchasedProductSequenceNumber),
                ...premiumResponse.map((product) => product.purchasedProductSequenceNumber),
            ]),
        ];

        const items: PremiumImpactInterface[] = sequenceNumbers.map(
            (sequenceNumber: number): PremiumImpactInterface => {
                const addressItem = addressResponse.purchasedProducts.find(
                    (item) => item.purchasedProductSequenceNumber === sequenceNumber,
                );
                const premiumItem = premiumResponse.find(
                    (item) => item.purchasedProductSequenceNumber === sequenceNumber,
                );
                const reference = addressItem
                    ? `${addressItem.motorVehicleMake} ${addressItem.motorVehicleType}${addressItem.VIN ? ' mit FIN ' + addressItem.VIN : ''}`
                    : premiumItem?.reference;

                return {
                    description: premiumItem?.productDescription || addressItem?.productDescription,
                    reference: reference,
                    evb: addressItem?.evbNumber || null,
                    current: premiumItem?.premiumCurrent || null,
                    future: premiumItem?.premiumReferenceDate || null,
                };
            },
        );

        this.showModal(items);
    }

    private showModal(items: PremiumImpactInterface[]): void {
        const modalRef = this.newModal.open(PremiumImpactModal);
        modalRef.componentInstance.items = items;
        this.loading = false;
    }

    private onCreateResidentialAddressError(errors: ErrorInterface[]): void {
        this.loading = false;
        this.errors = errors;
        this.changeDetectorRef.detectChanges();
    }
}
