import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ShowPasswordDirective } from './show-password.directive';

@NgModule({
    imports: [CommonModule],
    declarations: [ShowPasswordDirective],
    exports: [ShowPasswordDirective],
})
export class ShowPasswordModule {}
