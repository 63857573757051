import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WucLinkModule, WucPageBlockModule, WucSkeletonModule } from '@inshared/website-ui-components';
import { MyInsurancesRoutingModule } from './my-insurances-routing.module';
import { MyInsurancesComponent } from './my-insurances.component';
import { MyInsurancesContainer } from './my-insurances.container';
import { InsuranceListModule } from '@app-de/my-zone/shared/insurance-list/insurance-list.module';
import { PassportModule } from '@app-de/my-zone/shared/passport/passport.module';
import { DecimalizeModule } from '@app-de/shared/directives/decimalize/decimalize.module';

@NgModule({
    imports: [
        CommonModule,
        MyInsurancesRoutingModule,
        InsuranceListModule,
        DecimalizeModule,
        PassportModule,
        WucPageBlockModule,
        WucSkeletonModule,
        WucLinkModule
    ],
    declarations: [MyInsurancesContainer, MyInsurancesComponent],
})
export class MyInsurancesModule {}
