import { Component, Inject } from '@angular/core';
import { NewModalRef, NEW_MODAL_DATA as MODAL_DATA } from 'outshared-lib';
import { ConfirmationModalInterface } from '@app-de/shared/modals/confirmation-modal/confirmation-modal.interface';

@Component({
    selector: 'ins-confirmation-modal',
    templateUrl: './confirmation.modal.html',
})
export class ConfirmationModal {
    public model: ConfirmationModalInterface;

    constructor(
        private activeModal: NewModalRef<ConfirmationModal>,
        @Inject(MODAL_DATA) data: ConfirmationModalInterface
    ) {
        this.model = data;
    }

    public close(): void {
        this.activeModal.close(true);
    }

    public dismiss(): void {
        this.activeModal.close();
    }
}
