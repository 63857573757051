<form (ngSubmit)="onFormSubmit()" [formGroup]="form">
    <!-- Password -->
    <cyno-field>
        <cyno-label>Aktuelles Passwort</cyno-label>
        <input
            type="password"
            insShowPassword
            cynoInput
            name="password"
            id="password"
            e2e="email-password"
            formControlName="password"
        />
    </cyno-field>
    <!-- E-mail -->
    <cyno-field>
        <cyno-label>E-Mail-Adresse</cyno-label>
        <input
            autocomplete="username"
            cynoInput
            e2e="email-address"
            formControlName="emailAddress"
            id="emailadres-emailadres"
            inputmode="email"
            name="emailadres"
            type="text"
        />
        <!-- E-mail | Errors -->
        <cyno-error *ngIf="emailAddress.touched && emailAddress.hasError('required')">
            Tragen Sie bitte noch Ihre E-Mail-Adresse ein.
        </cyno-error>
        <cyno-error *ngIf="emailAddress.touched && emailAddress.hasError('email')">
            Sorry, das ist keine gültige E-Mail-Adresse.
        </cyno-error>
    </cyno-field>

    <cyno-alert
        type="error"
        e2e="password-error-message"
        *ngIf="errors && errors.length"
        [notifications]="errors"
    ></cyno-alert>
    <ins-details-submit-button [disabled]="disabled">Speichern</ins-details-submit-button>
</form>
