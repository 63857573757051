import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ProductBaseInterface, ProductSelectorService } from 'outshared-lib';
import { Observable } from 'rxjs';

@Component({
    selector: 'ins-insurance-detail-container',
    template: ` <ins-insurance-detail [product]="product$ | async"> </ins-insurance-detail> `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InsuranceDetailContainer {
    public product$: Observable<ProductBaseInterface> = this.productSelectorService.getSelectedProduct$;

    constructor(private productSelectorService: ProductSelectorService) {}
}
