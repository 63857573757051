import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Inject,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import {
    DateService,
    DownloadFileInterface,
    ErrorInterface,
    IndicationEnum,
    NewModal,
    ProductActionService,
    ProductBaseInterface,
    ValidityStatusCodeEnum,
} from 'outshared-lib';
import { Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { DatesEnum } from '@app-de/core/enums/dates.enum';
import { DocumentsEnum } from '@app-de/core/enums/documents.enum';
import { CancelInsuranceModal } from '@app-de/modals/cancel-insurance/cancel-insurance.modal';
import { SelectedPanelEnum } from '@app-de/my-zone/enums';
import { CancelTerminateInsuranceModal } from '@app-de/my-zone/shared/modals';
import { ConfirmationModal } from '@app-de/shared/modals/confirmation-modal/confirmation.modal';
import { DownloadingModal } from '@app-de/shared/modals/downloading-modal/downloading.modal';
import { PdfDownloadUtilities } from '@app-de/shared/utilities/pdf-download-utilities';
import { WINDOW } from '@inshared/shared/util';

const START_DATE_UNKOWN = '9999-12-31';

@Component({
    selector: 'ins-policy-details',
    templateUrl: './policy-details.component.html',
    styles: ['.text-right { text-align: right; }'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PolicyDetailsComponent implements OnChanges, OnDestroy, OnInit {
    @Input() public product: ProductBaseInterface;
    @Input() public productTerms: string;

    public isLoading: boolean;
    public errors: ErrorInterface[];
    public canChangeStartDate: boolean = false;
    public canTerminate: boolean = false;
    public canChangeEndDate: boolean = false;
    public canCancel: boolean = false;
    public canCancelTermination: boolean = false;
    public canHideEndInsuranceButton: boolean = false;
    public documentsEnum: typeof DocumentsEnum = DocumentsEnum;
    public validityStatusCodeEnum: typeof ValidityStatusCodeEnum = ValidityStatusCodeEnum;
    public datesEnum: typeof DatesEnum = DatesEnum;

    private subscriptions: Subscription = new Subscription();

    constructor(
        private newModal: NewModal,
        private productActionService: ProductActionService,
        public dateService: DateService,
        @Inject(WINDOW) private window: Window,
        private changeDetectorRef: ChangeDetectorRef,
        private router: Router,
    ) {
        this.isLoading = false;
        this.errors = [];
    }

    public ngOnInit(): void {
        this.setHideEndInsuranceButton();
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.product) {
            this.setHideEndInsuranceButton();
        }

        if (changes.product && changes.product.currentValue) {
            const currentProduct = changes.product.currentValue;
            this.canTerminate = [ValidityStatusCodeEnum.Active, ValidityStatusCodeEnum.ActiveW].includes(
                currentProduct.validity_status_code,
            );
            this.canCancelTermination = currentProduct.validity_status_code === ValidityStatusCodeEnum.ActiveEnd;
            this.canChangeStartDate = false; //currentProduct.validity_status_code === ValidityStatusCodeEnum.Future; Disabled for now, until the backend is done
        }
    }

    public getPolicy(): boolean {
        const modalRef = this.newModal.open(DownloadingModal);
        const policyDownload = this.productActionService.downloadPolicy(IndicationEnum.Yes).subscribe(
            (pdf: DownloadFileInterface) => {
                this.openPdfModal(pdf);
                modalRef.close();
            },
            (errors: ErrorInterface[]) => {
                modalRef.close();
                this.newModal.open<ConfirmationModal>(ConfirmationModal, {
                    data: {
                        alignment: 'end',
                        body: errors[0].message,
                        confirm: 'OK',
                    },
                });
            },
        );
        this.subscriptions.add(policyDownload);
        return false;
    }

    public onCancel(): void {
        const cancelInsuranceModalRef = this.newModal
            .open(CancelInsuranceModal)
            .afterClosed$()
            .pipe(filter((result: boolean) => result))
            .subscribe({
                next: () => this.cancelInsurance(),
            });

        this.subscriptions.add(cancelInsuranceModalRef);
    }

    public cancelTermination(): void {
        const cancelTerminationModalRef = this.newModal
            .open(CancelTerminateInsuranceModal)
            .afterClosed$()
            .pipe(
                take(1),
                filter((result) => Boolean(result)),
            )
            .subscribe({
                next: () => this.reviveProduct(),
            });

        this.subscriptions.add(cancelTerminationModalRef);
    }

    public addLeadingZeros(num: number, totalLength: number): string {
        return String(num).padStart(totalLength, '0');
    }

    private reviveProduct(): void {
        const reviveTerminatedPurchasedProduct = this.productActionService
            .reviveTerminatedPurchasedProduct(this.product.purchased_product_sequence_number)
            .subscribe({
                next: () => this.onSuccess(),
                error: (errors) => this.onError(errors),
            });

        this.onStart();
        this.subscriptions.add(reviveTerminatedPurchasedProduct);
    }

    private cancelInsurance(): void {
        const cancelFuturePurchasedProduct = this.productActionService
            .cancelFuturePurchasedProduct({
                purchased_product_sequence_number: this.product.purchased_product_sequence_number,
            })
            .subscribe({
                next: () => this.onSuccess(),
                error: (errors) => this.onError(errors),
            });

        this.onStart();
        this.subscriptions.add(cancelFuturePurchasedProduct);
    }

    private onStart(): void {
        this.isLoading = true;
        this.errors = [];
        this.changeDetectorRef.detectChanges();
    }

    private onSuccess(): void {
        this.isLoading = false;
        this.closePanel();
        this.changeDetectorRef.detectChanges();
    }

    private onError(errors: ErrorInterface[]): void {
        this.isLoading = false;
        this.errors = errors;
        this.changeDetectorRef.detectChanges();
    }

    private setHideEndInsuranceButton(): void {
        this.canHideEndInsuranceButton =
            !this.dateService.isAfter(this.product.start_date, this.dateService.nowAsString()) ||
            this.product.start_date === START_DATE_UNKOWN;
    }

    private openPdfModal(attachment: DownloadFileInterface): void {
        PdfDownloadUtilities.OpenPdfBase64TargetBlank(attachment.pdf, attachment.type, this.window);
    }

    private closePanel(): void {
        const queryParams = {
            panel: SelectedPanelEnum.Close,
            purchased_product: this.product.purchased_product_sequence_number,
            scroll: true,
        };

        this.router.navigate([], {
            queryParams,
            queryParamsHandling: 'merge',
        });
    }
}
