import { Routes } from '@angular/router';
import { AutoversicherungRoutesEnum } from './../../autoversicherung/autoversicherung-routes.enum';

export const autounfallPannenSchadenRoutes: Routes = [
    {
        path: `${AutoversicherungRoutesEnum.AutounfallPannenSchaden}`,
        loadChildren: () =>
            import('./autounfall-pannen-schaden/autounfall-pannen-schaden.module').then(
                (m) => m.AutounfallPannenSchadenModule,
            ),
    },
    {
        path: `${AutoversicherungRoutesEnum.AutounfallPannenSchaden}/${AutoversicherungRoutesEnum.Wildunfall}`,
        loadChildren: () =>
            import('./wildunfall/wildunfall.module').then(
                (m) => m.WildunfallModule,
            ),
    },
    {
        path: `${AutoversicherungRoutesEnum.AutounfallPannenSchaden}/${AutoversicherungRoutesEnum.Steinschlag}`,
        loadChildren: () =>
            import('./steinschlag/steinschlag.module').then(
                (m) => m.SteinschlagModule,
            ),
    },
];
