export class NavigateControlAction {
    public uri: any[];
    public hashParam?: string;
    public params?: boolean;
    public expand?: boolean;
    public scroll?: boolean;

    constructor(data?: NavigateControlAction) {
        if (data) {
            this.uri = data.uri;
            this.hashParam = data?.hashParam;
            this.params = data?.params;
            this.expand = data?.expand;
            this.scroll = data?.scroll ?? true;
        }
    }
}
