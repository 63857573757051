import { Component, Input } from '@angular/core';
import { CynoErrorAbstractComponent } from './cyno-error.abstract';

let nextUniqueId = 0;

@Component({
    selector: 'cyno-error',
    host: {
        class: 'cyno-error',
        role: 'alert',
        '[attr.id]': 'id',
    },
    templateUrl: './cyno-error.component.html',
})
export class CynoErrorComponent extends CynoErrorAbstractComponent {
    @Input() public id = `cyno-error-${nextUniqueId++}`;
}
