import { mapSequenceNumber } from '../../core';
import { mapProductIdResponse } from '../../insurance-policy/mappers/product/product-id.mapper';
import { ApiLoadProductScenarioResponseInterface } from '../interfaces/api-load-product-scenario-response.interface';
import { LoadProductScenarioResponseInterface } from '../interfaces/load-product-scenario-response.interface';
import { mapProductScenarioCodeResponse } from './product-scenario-code.mapper';

export function mapLoadProductScenariosResponse(
    response: ApiLoadProductScenarioResponseInterface
): LoadProductScenarioResponseInterface[] {
    return response.scenarios.map((scenario) => ({
        amount: scenario.amount,
        productDescription: scenario.product_description,
        productId: mapProductIdResponse(scenario.product_id),
        scenarioCode: mapProductScenarioCodeResponse(scenario.scenario_code),
        productSequenceNumber: mapSequenceNumber(scenario.purchased_product_sequence_number),
        scenarioDescription: scenario.scenario_description,
    }));
}
