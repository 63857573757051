<div>
    <div class="control-input__birthdate">
        <input #birthdate type="tel" class="form-control" size="10" name="birthdate" [id]="id"
            [placeholder]="placeholder"
            (input)="onInput()"
            (keydown.enter)="onBlur()"
            (keyup.backspace)="onBackspace()"
            (keydown.delete)="preventDefault($event)"
            (focus)="onFocus()"
            (blur)="onBlur()"
            (paste)="onPaste()"
            [disabled]="disabled"
            autocomplete="bday">
    </div>
</div>