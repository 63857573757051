import { Component, EventEmitter, Input, Output } from '@angular/core';
import { InvoiceDetailsInterface } from 'outshared-lib';
import { MyZoneRoutes } from '@app-de/my-zone/routing/my-zone-routing.routes';
import { AppRoutesEnum } from '@app-de/routes';

@Component({
    selector: 'ins-invoice-details',
    templateUrl: 'invoice-details.component.html',
})
export class InvoiceDetailsComponent {
    public appRoutesEnum = AppRoutesEnum;
    public myZoneRoutes = MyZoneRoutes;
    @Input() public invoiceDetails: InvoiceDetailsInterface;
    @Output() private readonly download: EventEmitter<string> = new EventEmitter();

    public downloadInvoice(invoiceId: string): boolean {
        this.download.emit(invoiceId);
        return false;
    }
}
