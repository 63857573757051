import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { WucNavigationMobileItemModule, WucNavigationMobileModule } from '@inshared/website-ui-components';
import { NavigationMobileComponent } from './navigation-mobile.component';

@NgModule({
    declarations: [NavigationMobileComponent],
    imports: [
        CommonModule,
        RouterModule,
        WucNavigationMobileModule,
        WucNavigationMobileItemModule,
    ],
    exports: [NavigationMobileComponent],
})
export class NavigationMobileModule {}
