<ins-modal-close></ins-modal-close>

<div class="chatbot-modal" [class.chatbot-modal--fullscreen]="isFullScreen">
    <ins-chatbot-container
        [isMobile]="isFullScreen"
        [isDialog]="true"
        [question]="question"
        [maxHeight]="isFullScreen ? undefined : '90vh'"
        scrollTarget="list"
    ></ins-chatbot-container
></div>
