<label class="control-label" *ngIf="inline" [attr.for]="control?.id" #label>
    <div class="control-label__inner">
        <ng-content select="cyno-label"></ng-content>
        &nbsp;
        <a href="#" title="Informationen" ins-clickOutside (clickedOutside)="closeTooltip()" (click)="onTooltip($event)" *ngIf="hasIconInfo()" placement="top">
            <svg class="svg-icon svg-icon--xs">
                <use href="assets/icons-ui.svg#informatie"></use>
            </svg>
        </a>
    </div>
    <div class="control-label__info" *ngIf="showInfo">
        <ng-content select="cyno-info"></ng-content>
    </div>
</label>
<div class="control-input" [ngClass]="{ 'control-input--full': !inline, 'control-input--with-button': withButton }">
    <ng-content></ng-content>
</div>
