import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { ProductScenariosApiService } from '../services/product-scenarios.api.service';
import { ProductScenariosEventService } from '../services/product-scenarios.event.service';
import * as ProductScenariosActions from './product-scenarios.actions';

@Injectable()
export class ProductScenariosEffects {
    public loadProductScenarios$ = createEffect(() =>
    { return this.actions$.pipe(
        ofType(ProductScenariosActions.loadProductScenarios),
        switchMap(() =>
            this.productScenariosApiService.loadProductScenarios$().pipe(
                map((scenarios) => ProductScenariosActions.loadProductScenariosSuccess({ scenarios })),
                catchError((errors) => of(ProductScenariosActions.loadProductScenariosError({ errors })))
            )
        )
    ); }
    );

    public loadProductScenariosError$ = createEffect(
        () =>
        { return this.actions$.pipe(
            ofType(ProductScenariosActions.loadProductScenariosError),
            tap(({ errors }) => this.productScenariosEventService.onLoadProductScenariosError(errors))
        ); },
        { dispatch: false }
    );

    public loadProductScenariosSuccess$ = createEffect(
        () =>
        { return this.actions$.pipe(
            ofType(ProductScenariosActions.loadProductScenariosSuccess),
            tap(({ scenarios }) => this.productScenariosEventService.onLoadProductScenariosSuccess())
        ); },
        { dispatch: false }
    );

    constructor(
        private actions$: Actions,
        private productScenariosApiService: ProductScenariosApiService,
        private productScenariosEventService: ProductScenariosEventService
    ) {}
}
