import { Component, Input } from '@angular/core';
import { ErrorInterface } from 'outshared-lib';

@Component({
    selector: 'ins-alert',
    templateUrl: 'alert.component.html',
})
export class AlertComponent {
    @Input() public errors: ErrorInterface[] = [];
    @Input() public type: 'validation' | 'error' | 'information' | 'warning' = 'error';
    @Input() public position: 'left' | 'center' | 'right' | 'none' = 'left';
}
