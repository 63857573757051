<div class="international-motor-insurance-card-button">
    <ng-container *ngIf="!disabled">
        <button class="btn btn--secondary btn--block" (click)="onClicked()" [disabled]="loading">
            <svg *ngIf="!loading" class="svg-icon svg-icon--xs">
                <use [attr.href]="'assets/icons-ui.svg#' + icon"></use>
            </svg>
            <svg *ngIf="loading" class="svg-icon svg-icon--xs"><use href="assets/icons-ui.svg#loading"></use></svg>
            {{ value }}
        </button>
    </ng-container>

    <ng-container *ngIf="disabled">
        <button class="btn btn--secondary btn--block disabled">
            <svg class="svg-icon svg-icon--xs"><use [attr.href]="'assets/icons-ui.svg#' + icon"></use></svg>
            {{ value }}
        </button>
    </ng-container>
</div>
