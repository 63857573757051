import { DOCUMENT, isPlatformServer } from '@angular/common';
import { Directive, ElementRef, Input, OnChanges, OnInit, PLATFORM_ID, inject } from '@angular/core';

@Directive({
    selector: '[nativeRouterLinkActive]',
    exportAs: 'nativeRouterLinkActive',
})
export class NativeRouterLinkActiveDirective implements OnInit, OnChanges {
    private elementRef = inject<ElementRef<HTMLElement>>(ElementRef);
    private readonly urlPathRegex = /https?:\/\/[^\/]*(\/[^?]*)/;
    private platformId = inject(PLATFORM_ID);
    private document = inject(DOCUMENT);

    @Input() nativeRouterLinkActive: string = '';

    constructor() {}

    public ngOnInit(): void {
        this.validate();
    }

    public ngOnChanges(): void {
        this.validate();
    }

    public isActive(): boolean {
        const nativeElementHref = this.elementRef.nativeElement.attributes.getNamedItem('href')?.value;
        const match = nativeElementHref?.match(this.urlPathRegex);
        const nativeElementPath = match ? match[1] : '/';
        const currentUrl = this.document.location.pathname;
        return nativeElementPath === currentUrl;
    }

    private validate(): void {
        if (isPlatformServer(this.platformId)) {
            return;
        }

        if (this.isActive()) {
            this.elementRef.nativeElement.classList.add(this.nativeRouterLinkActive);
        } else {
            this.elementRef.nativeElement.classList.remove(this.nativeRouterLinkActive);
        }
    }
}
