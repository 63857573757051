import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import {
    CoveragePremiumActionService,
    ProductActionService,
    ProductBaseInterface,
    ProductIdEnum,
    ProductSelectorService,
} from 'outshared-lib';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { SelectedPanelEnum } from '@app-de/my-zone/enums';

@Injectable({
    providedIn: 'root',
})
export class ProductResolver implements Resolve<boolean> {
    private products$: Observable<{ [id: string]: ProductBaseInterface }>;
    private detailLoaded$: Observable<{ [id: string]: boolean }>;

    constructor(
        private productSelectorService: ProductSelectorService,
        private productActionService: ProductActionService,
        private coveragePremiumActionService: CoveragePremiumActionService,
        @Inject(PLATFORM_ID) private platformId: Object,
    ) {}

    public resolve(route: ActivatedRouteSnapshot): Observable<boolean> {
        const hasPanels: boolean = route.data.containsPanels;
        const selectedId = Number(route.queryParams.purchased_product);
        const selectedPanel: SelectedPanelEnum = route.queryParams.panel;

        if (
            isPlatformServer(this.platformId) ||
            (hasPanels && (!selectedPanel || selectedPanel === SelectedPanelEnum.Close))
        ) {
            return of(true);
        }

        this.products$ = this.productSelectorService.getProductEntities$;
        this.detailLoaded$ = this.productSelectorService.getDetailLoaded$;

        // Select product
        this.setSelectedId(selectedId);

        // Load product details
        combineLatest([this.products$, this.detailLoaded$])
            .pipe(
                filter(([products]) => Object.keys(products).length > 0),
                take(1),
            )
            .subscribe(([products, detailLoaded]) => this.validateData(products, detailLoaded, selectedId));

        return of(true);
    }

    private validateData(
        products: { [id: string]: ProductBaseInterface },
        detailLoaded: { [id: string]: boolean },
        selectedId: number,
    ): void {
        const isLoaded = Boolean(detailLoaded[selectedId]);
        const productId = products[selectedId] ? products[selectedId].product_id : null;

        if (!isLoaded) {
            this.loadProductDetails(productId, selectedId);
        }
    }

    private setSelectedId(selectedId: number): void {
        if (!selectedId) {
            return;
        }
        this.productActionService.select(selectedId);
        this.coveragePremiumActionService.select(selectedId);
    }

    private loadProductDetails(productId: ProductIdEnum, selectedId: number): void {
        this.productActionService
            .loadPurchasedProduct$({ product_id: productId, purchased_product_sequence_number: selectedId })
            .pipe(take(1))
            .subscribe();
    }
}
