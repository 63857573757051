import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ElementValidator, ErrorInterface, RelationInterface } from 'outshared-lib';
import { FormControlUpdateOnEnum } from '@app-de/my-zone/enums';

const VALIDATOR_NAME = 'telephone_number';
// TODO: WIDL-1272 Regular id 'RELA.ALGM.WIJZ.RELG' not working. Temporary use:
const WEBSERVICE_ID = 'VERZ.PROD.VSLG.NREL.PROD.AUTO';
const CONTEXT = 'landline_or_mobile_telephone_number';

@Component({
    selector: 'ins-recovery-settings-form',
    templateUrl: './recovery-settings-form.component.html',
})
export class RecoverySettingsFormComponent implements OnInit {
    @Output() public readonly submitted = new EventEmitter<{ mobilePhoneNumber: string }>();

    @Input('relation') public set setRelation(relation: RelationInterface) {
        if (relation && this.form) {
            this.resetForm(relation);
        }
    }

    @Input() public disabled: boolean;
    @Input() public errors: ErrorInterface[];

    public form: UntypedFormGroup;

    constructor(private elementValidator: ElementValidator) {}

    public ngOnInit(): void {
        this.form = this.createForm();
    }

    public get telephoneNumber(): AbstractControl {
        return this.form.get('mobileTelephoneNumber.telephoneNumber');
    }

    public onFormSubmit(): void {
        if (!this.form.valid) {
            this.form.markAllAsTouched();
            return;
        }

        this.submitted.emit({
            mobilePhoneNumber: this.telephoneNumber.value,
        });
    }

    private createForm(): UntypedFormGroup {
        const elementValidator = this.elementValidator.validate(VALIDATOR_NAME, WEBSERVICE_ID, CONTEXT);
        const telephoneNumber = {
            telephoneNumber: new UntypedFormControl('', {
                validators: [Validators.required],
                asyncValidators: [elementValidator],
                updateOn: FormControlUpdateOnEnum.Blur,
            }),
        };

        return new UntypedFormGroup({
            mobileTelephoneNumber: new UntypedFormGroup(telephoneNumber),
        });
    }

    private resetForm(relation: RelationInterface): void {
        this.form.reset({
            mobileTelephoneNumber: {
                telephoneNumber: relation?.mobile_telephone_number?.telephone_number,
            },
        });
    }
}
