import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NewModalModule } from 'outshared-lib';
import { ModalActionsComponent } from '@app-de/components/modal/modal-actions';
import { ModalActionsPreviousComponent } from '@app-de/components/modal/modal-actions-previous';
import { ModalCloseComponent } from '@app-de/components/modal/modal-close';
import { ModalContentComponent } from '@app-de/components/modal/modal-content';
import { ModalSubTitleComponent } from '@app-de/components/modal/modal-sub-title';
import { ModalTitleComponent } from '@app-de/components/modal/modal-title';
import { RoutedModal } from '@app-de/components/modal/routed-modal';

const COMPONENTS = [
    ModalActionsComponent,
    ModalActionsPreviousComponent,
    ModalCloseComponent,
    ModalContentComponent,
    ModalSubTitleComponent,
    ModalTitleComponent,
    RoutedModal,
];

@NgModule({
    declarations: COMPONENTS,
    exports: COMPONENTS,
    imports: [CommonModule, NewModalModule, RouterModule],
})
export class ModalComponentsModule {}
