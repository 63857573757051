import { AfterContentInit, Component, ContentChildren, Input, QueryList } from '@angular/core';
import { WucNavigationMobileItemComponent } from '../navigation-mobile-item';

@Component({
    selector: 'wuc-navigation-mobile',
    templateUrl: 'navigation-mobile.component.html',
    styleUrls: ['navigation-mobile.component.scss'],
})
export class WucNavigationMobileComponent implements AfterContentInit {
    @ContentChildren(WucNavigationMobileItemComponent)
    private links!: QueryList<WucNavigationMobileItemComponent>;

    @Input() public itemsPerRow: number = 3;

    public active: boolean = false;
    public rows!: number;

    public get bottomPosition(): string {
        if (this.active || !this.rows) {
            return '0';
        }
        return `${-((this.rows - 1) * 7.5)}rem`;
    }

    public ngAfterContentInit(): void {
        const menuLink = this.links.find((link: WucNavigationMobileItemComponent) => link.role === 'menu');
        if (menuLink) {
            menuLink.clicked.subscribe((active: boolean) => (this.active = active));
        }
        this.rows = Math.ceil(this.links.length / this.itemsPerRow);
    }
}
