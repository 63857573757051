import { Component, Input, ViewChild } from '@angular/core';
import { ProductBaseInterface } from 'outshared-lib';
import { PanelExpandableComponent } from '@app-de/shared/panel-expandable/panel-expandable.component';

@Component({
    selector: 'ins-insurance-regular-driver',
    templateUrl: './insurance-regular-driver.component.html',
})
export class InsuranceRegularDriverComponent {
    @Input() public product: ProductBaseInterface;
    @ViewChild(PanelExpandableComponent, { static: true }) public insuranceDriverForm: PanelExpandableComponent;
}
