<wuc-chatbot
    [newQuestion$]="newQuestion$"
    [newAnswer$]="newAnswer$"
    buttonText="Senden"
    busyText="Der Chatbot tippt..."
    placeholder="Stellen Sie hier Ihre Frage..."
    [maxNumberOfCharacters]="150"
    maxNumberOfCharactersText="(max. 150 Zeichen)"
    (question)="onQuestion($event)"
    (dialogOption)="onDialogOption($event)"
    (feedback)="onFeedback($event)"
    [escalation]="escalation"
    [isMobile]="isMobile"
    [isTablet]="isTablet"
    [isDialog]="isDialog"
    [maxHeight]="maxHeight"
>
    <div
        slot="intro"
        class="ins-chatbot-entry__intro"
    >
        <ng-container *ngIf="!relation">Hallo, ich bin der Chatbot von InShared und weiß (fast) alles. Ihre Frage?</ng-container>

        <ng-container *ngIf="relation">
            Hallo,
            <ng-container [ngSwitch]="relation.gender_code">
                <ng-container *ngSwitchCase="'M'">Herr&#32;</ng-container>
                <ng-container *ngSwitchCase="'V'">Frau&#32;</ng-container>
            </ng-container>
            <span data-hj-suppress>{{ relation.middle_name_initials }} {{ relation.surname }}.</span>
            ich bin der Chatbot von InShared und weiß (fast) alles. Ihre Frage?
        </ng-container>
    </div>
    <div slot="footer">
        <!-- prettier-ignore -->
        <div class="text text--center small">
            <wuc-text-highlight type="primary">Tipp:</wuc-text-highlight>
            Wussten Sie, dass Sie bei uns alles selbst erledigen können? Einfach und bequem in Ihrer
            <wuc-link (clicked)="onNavigateAway()" [route]="'/' + appRoutesEnum.MyZone"
            >Online-Versicherungsmappe</wuc-link> !
        </div>
    </div>
</wuc-chatbot>
