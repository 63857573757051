// disable next line eslint because our buddy eslint is drunk and thinks there are 178 characters in the next line.
// eslint-disable-next-line max-len
import { mapFromSuccessIndication } from '../../core';
import {
    ApiDamageFreeYearsImpactResponse,
    DamageFreeYearsImpactResponseInterface,
    DamageFreeYearsImpactRequestInterface,
    ApiDamageFreeYearsImpactRequest,
} from '../interfaces';

export function mapDamageFreeYearsImpactRequest(
    request: DamageFreeYearsImpactRequestInterface
): ApiDamageFreeYearsImpactRequest {
    return {
        purchased_product_sequence_number: request.productSequenceNumber,
    };
}

export function mapDamageFreeYearsImpactResponse(
    response: ApiDamageFreeYearsImpactResponse
): DamageFreeYearsImpactResponseInterface {
    return {
        discountPercentageWaCurrent: response.korting_percentage_wa_huidig,
        discountPercentageWaFuture: response.korting_percentage_wa_toekomstig,
        discountPercentageCascoCurrent: response.korting_percentage_casco_huidig,
        discountPercentageCascoFuture: response.korting_percentage_casco_toekomstig,
        damageFreeYearsWaCurrentCode: response.schadevrije_jaren_wa_huidig_code,
        damageFreeYearsWaFutureCode: response.schadevrije_jaren_wa_toekomstig_code,
        damageFreeYearsCascoCurrentCode: response.schadevrije_jaren_casco_huidig_code,
        damageFreeYearsCascoFutureCode: response.schadevrije_jaren_casco_toekomstig_code,
        insuranceTaxCurrentAmount: response.huidig_assurantiebelasting_bedrag,
        modulePremiumCurrentAmount: response.huidig_modulepremie_incl_assurantiebelasting_bedrag,
        modulePremiumIncludingInsuranceTaxCurrentAmount: response.huidig_modulepremie_incl_assurantiebelasting_bedrag,
        insuranceTaxFutureAmount: response.toekomstig_assurantiebelasting_bedrag,
        modulePremiumFutureAmount: response.toekomstig_modulepremie_bedrag,
        modulePremiumIncludingInsuranceTaxFutureAmount:
            response.toekomstig_modulepremie_incl_assurantiebelasting_bedrag,
        premiumStartDateFuture: response.toekomstig_premie_ingangsdatum,
        damageFreeYearsImpactWaTitle: response.schadevrije_jaren_impact_wa_titel,
        damageFreeYearsImpactWaDescription: response.schadevrije_jaren_impact_wa_omschrijving,
        damageFreeYearsImpactCascoTitle: response.schadevrije_jaren_impact_casco_titel,
        damageFreeYearsImpactCascoDescription: response.schadevrije_jaren_impact_casco_omschrijving,
        cascoCoverageIndication: mapFromSuccessIndication(response.vollkasko_dekking_indicatie),
    };
}
