import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NavigationModule } from '../navigation/navigation.module';
import { NavigationMobileModule } from '../navigation-mobile/navigation-mobile.module';
import { TopbarModule } from '../topbar/topbar.module';
import { HeaderComponent } from './header.component';

@NgModule({
    declarations: [HeaderComponent],
    imports: [
        CommonModule,
        TopbarModule,
        NavigationModule,
        NavigationMobileModule,
    ],
    exports: [HeaderComponent],
})
export class HeaderModule {}
