<form [formGroup]="form">
    <wuc-form-field name="genderCode">
        <wuc-form-field-label>Anrede</wuc-form-field-label>
        <wuc-input-radio name="genderCode" formControlName="genderCode" value="M">Herr</wuc-input-radio>
        <wuc-input-radio name="genderCode" formControlName="genderCode" value="V">Frau</wuc-input-radio>
        <wuc-input-radio name="genderCode" formControlName="genderCode" value="N">Neutral</wuc-input-radio>
    </wuc-form-field>
    <wuc-form-field name="firstName">
        <wuc-form-field-label>Vorname</wuc-form-field-label>
        <wuc-input-text formControlName="firstName" placeholder="Alex"></wuc-input-text>
        <wuc-form-error *ngIf="firstName.touched && firstName.invalid">
            Hoppla, da fehlt noch Ihr Vorname.
        </wuc-form-error>
    </wuc-form-field>
    <wuc-form-field name="surname">
        <wuc-form-field-label>Nachname</wuc-form-field-label>
        <wuc-input-text formControlName="surname" placeholder="Muster"></wuc-input-text>
        <wuc-form-error *ngIf="surname.touched && surname.invalid">Hoppla, da fehlt noch Ihr Nachname.</wuc-form-error>
    </wuc-form-field>
    <wuc-form-field name="birthdate">
        <wuc-form-field-label>Geburtsdatum</wuc-form-field-label>
        <wuc-input-date formControlName="birthdate" placeholder="TT.MM.JJJJ"></wuc-input-date>
        <wuc-form-error *ngIf="birthdate.touched && birthdate.hasError('required')">
            Hoppla, da fehlt noch Ihr Geburtsdatum.
        </wuc-form-error>
        <wuc-form-error *ngIf="birthdate.touched && birthdate.hasError('date-minimum')">
            Sorry, der Hauptfahrer muss mindestens 25 Jahre alt sein.
        </wuc-form-error>
    </wuc-form-field>
    <wuc-form-field name="drivingLicenseFirstDateOfIssue">
        <wuc-form-field-label>Datum des Führerscheinerwerbs</wuc-form-field-label>
        <wuc-input-date formControlName="drivingLicenseFirstDateOfIssue" placeholder="TT.MM.JJJJ"></wuc-input-date>
        <wuc-form-error
            *ngIf="drivingLicenseFirstDateOfIssue.touched && drivingLicenseFirstDateOfIssue.hasError('required')"
        >
            Hoppla, da fehlt noch Ihr Datum des Führerscheinerwerbs.
        </wuc-form-error>
        <wuc-form-error
            *ngIf="
                drivingLicenseFirstDateOfIssue.touched && drivingLicenseFirstDateOfIssue.hasError('invalidMinimumRange')
            "
        >
            Sorry, der Hauptfahrer muss mindestens 17 Jahre alt beim Er werb des Führerscheins gewesen sein. Bitte
            überprüfen Sie Ihre Eingabe.
        </wuc-form-error>
    </wuc-form-field>
    <wuc-form-field name="startDate">
        <wuc-form-field-label>Beginndatum</wuc-form-field-label>
        <wuc-input-date-picker
            formControlName="startDate"
            placeholder="TT.MM.JJJJ"
            [selectPastDates]="false"
            [selectToday]="false"
        ></wuc-input-date-picker>
        <wuc-form-error *ngIf="startDate.touched && startDate.hasError('required')">
            Hoppla, da fehlt noch das Beginndatum.
        </wuc-form-error>
    </wuc-form-field>
    <wuc-form-field name="error" *ngIf="errors && errors.length">
        <wuc-form-error>
            <p *ngFor="let error of errors">{{ error.message }}</p>
        </wuc-form-error>
    </wuc-form-field>
    <wuc-form-field>
        <wuc-form-field-label>&nbsp;</wuc-form-field-label>
        <wuc-button [disabled]="isLoading" type="primary" [isFullWidth]="true" (click)="onSubmit()">Ändern</wuc-button>
    </wuc-form-field>
</form>
