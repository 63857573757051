import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { AuthenticationDataService, ResetCalculateErrors } from 'outshared-lib';
import { filter, map, switchMap } from 'rxjs/operators';
import { AppRoutesEnum } from '@app-de/routes';
import { SalesfunnelRoutes } from '@app-de/salesfunnel/routing';
import { SalesfunnelService } from '@app-de/salesfunnel/salesfunnel.service';

@Injectable({
    providedIn: 'root',
})
export class AuthenticationRedirectService {
    private redirectUrl: string;

    constructor(
        private authenticationDataService: AuthenticationDataService,
        private salesfunnelService: SalesfunnelService,
        private router: Router,
        private store$: Store,
    ) {}

    public init(): void {
        this.authenticationDataService
            .initializationPending$()
            .pipe(
                filter((pending: boolean) => !pending),
                switchMap(() =>
                    this.authenticationDataService.sessionExpired$().pipe(
                        map((expired: boolean) => {
                            return expired
                                ? this.router.navigateByUrl(this.redirectThroughLogin(this.router.url)).then(() => {
                                      this.store$.dispatch(new ResetCalculateErrors());
                                  })
                                : null;
                        }),
                    ),
                ),
            )
            .subscribe();
    }

    public redirectThroughLogin(url?: string): UrlTree {
        this.redirectUrl = url;

        // Redirect to salesfunnel login page when session is expired or logout
        const fragments = url.split('/');

        const lastFragment = fragments[fragments.length - 1];
        if (!this.salesfunnelService.isSalesfunnel(url)) return this.router.createUrlTree(['/', AppRoutesEnum.Login]);

        switch (lastFragment) {
            case SalesfunnelRoutes.Summary:
            case SalesfunnelRoutes.PersonalData:
            case SalesfunnelRoutes.Insure: {
                fragments[fragments.length - 1] = SalesfunnelRoutes.PersonalData;
                return this.router.createUrlTree([fragments.join('/')]);
            }
            case SalesfunnelRoutes.CalculateCarInsurance: {
                return this.router.createUrlTree([fragments.join('/')]);
            }
            default: {
                return this.router.createUrlTree(['/', AppRoutesEnum.Login]);
            }
        }
    }

    public redirectOrPassThrough(): boolean | UrlTree {
        if (this.hasValidRedirectUrl()) {
            const url = this.redirectUrl;
            this.redirectUrl = null;
            return this.router.parseUrl(url);
        }
        return true;
    }

    /* Check if redirect url is valid,
     * if url is equal to login the login
     * page tries to redirect to itself.
     * Loginception :)
     */
    private hasValidRedirectUrl(): boolean {
        const url = this.redirectUrl ? this.redirectUrl.replace('/', '') : '';

        if (url.length < 1) {
            return false;
        }

        // url can't be login
        return Boolean(url !== AppRoutesEnum.Login);
    }
}
