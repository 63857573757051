import { Component } from '@angular/core';
import {
    ProductBaseInterface,
    ProductSelectorService,
    ScenarioCodeEnum,
    ScenarioInterface,
    ScenariosSelectorService,
} from 'outshared-lib';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'ins-insurance-data-container',
    template: `
        <ins-insurance-data [canBeReplaced]="canBeReplaced$ | async" [product]="product$ | async"> </ins-insurance-data>
    `,
})
export class InsuranceDataContainer {
    private scenarios$: Observable<ScenarioInterface[]> = this.scenariosSelectorService.getAllScenarios$;

    /* tslint:disable */
    public product$: Observable<ProductBaseInterface> = this.productSelectorService.getSelectedProduct$;
    public canBeReplaced$: Observable<boolean> = this.scenarios$.pipe(
        map((scenarios) =>
            scenarios.filter((scenario) => scenario.scenario_code === ScenarioCodeEnum.VehicleReplacement),
        ),
        map((scenarios) => Boolean(scenarios.length > 0)),
    );
    /* tslint:enable */

    constructor(
        private productSelectorService: ProductSelectorService,
        private scenariosSelectorService: ScenariosSelectorService,
    ) {}
}
