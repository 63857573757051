import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { WucPictureModule } from '@inshared/website-ui-components';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { FooterComponent } from './footer.component';
import { CountrySelectorModule } from '@app-de/shared/country-selector/component/country-selector.module';
import { LanguageDropdownModule } from '@app-de/shared/language-dropdown/language-dropdown.module';
import { TooltipModule } from '@app-de/shared/tooltip/tooltip.module';

@NgModule({
    declarations: [FooterComponent],
    imports: [
        CommonModule,
        RouterModule,
        LazyLoadImageModule,
        LanguageDropdownModule,
        TooltipModule,
        CountrySelectorModule,
        WucPictureModule,
    ],
    exports: [FooterComponent],
})
export class FooterModule {}
