import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PanelBodyComponent } from './panel-body/panel-body.component';
import { PanelExpandableComponent } from './panel-expandable.component';
import { PanelHeaderComponent } from './panel-header/panel-header.component';
import { PanelLabelComponent } from './panel-label/panel-label.component';
import { PanelButtonComponent } from '@app-de/shared/panel-expandable/panel-button/panel-button.component';

@NgModule({
    imports: [CommonModule],
    declarations: [
        PanelExpandableComponent,
        PanelBodyComponent,
        PanelButtonComponent,
        PanelHeaderComponent,
        PanelLabelComponent,
    ],
    exports: [
        PanelExpandableComponent,
        PanelBodyComponent,
        PanelButtonComponent,
        PanelHeaderComponent,
        PanelLabelComponent,
    ],
})
export class PanelExpandableModule {
}
