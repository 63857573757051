import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { InsurancesComponent } from './insurances.component';

const routes: Routes = [
    {
        path: '',
        children: [
            {
                path: '',
                component: InsurancesComponent,
                data: {
                    title: 'Unsere Versicherungen - InShared',
                    description: 'Bei InShared ist Ihr Auto gut und günstig versichert. Haftpflicht, Teil- oder Vollkasko, Fahrerschutz und Pannenhilfe: einfach online abschließen.',
                    robots: 'index, follow',
                },
            },
            {
                path: 'kundigen',
                loadChildren: () => import('./kundigen/kundigen.module').then((m) => m.KundigenModule),
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class InsurancesRoutingModule {}
