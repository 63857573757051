import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DetailsDeleteButtonComponent } from './details-delete-button.component';

@NgModule({
    declarations: [DetailsDeleteButtonComponent],
    exports: [DetailsDeleteButtonComponent],
    imports: [CommonModule],
})
export class DetailsDeleteButtonModule { }
