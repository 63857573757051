import { Component } from '@angular/core';
import { MyZoneRoutes } from '@app-de/my-zone/routing/my-zone-routing.routes';

@Component({
    selector: 'ins-sidemenu',
    templateUrl: './sidemenu.component.html',
    styleUrls: ['./sidemenu.component.scss'],
})
export class SidemenuComponent {
    public myZoneRoutes: typeof MyZoneRoutes = MyZoneRoutes;
}
