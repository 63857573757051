export enum AppRoutesEnum {
    Home = '',
    Login = 'einloggen',
    Logout = 'ausloggen',
    MyZone = 'versicherungsmappe',
    Privacy = 'datenschutz',
    Payment = 'bezahlen',
    Cookies = 'cookies',
    Contact = 'kontakt',
    Damage = 'schaden',
    About = 'uber-inshared',
    AnnualReward = 'jahresbelohnung',
    TwentyThree = '2023',
    OptOut = 'optout',
    SmartTips = 'wertvolle-tipps',
    KfzVersicherung = 'kfz-versicherung',
    CarInsurance = 'autoversicherung',
    EverythingOnline = 'alles-online',
    Insurances = 'versicherungen',
    Cancel = 'kundigen',
    Library = 'library',
    FrequentlyAskedQuestions = 'faq',
    PayHere = 'beitrag-bezahlen',
    Reviews = 'bewertungen',
    NotFound = '404',
}
