import { Component } from '@angular/core';
import { NewModalRef as ModalRef } from 'outshared-lib';

@Component({
    selector: 'ins-logout-modal',
    templateUrl: 'logout.modal.html',
})
export class LogoutModal {

    constructor(private modal: ModalRef<LogoutModal>) {}

    public cancel(): void {
        this.modal.close();
    }

    public logout(): void {
        this.modal.close(true);
    }
}
