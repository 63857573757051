import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'ins-product-card',
    templateUrl: 'product-card.component.html',
    styleUrls: ['product-card.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ProductCardComponent {
    @Input() public product: { type: string; name: string };
    public toggled: boolean = false;

    constructor() {}

    public toggleAdditional(): void {
        this.toggled = !this.toggled;
    }
}
