import { createSelector } from '@ngrx/store';
import { State } from '../app.reducer';
import * as fromLayout from './layout.reducer';

export const getLayoutState = (state: State): fromLayout.State => state.layout;
export const selectLayoutBreadcrumb = createSelector(getLayoutState, fromLayout.getBreadcrumb);
export const selectLayoutPage = createSelector(getLayoutState, fromLayout.getActivePage);
export const selectLayoutScrollTo = createSelector(getLayoutState, fromLayout.getScrollTo);
export const selectMobileMenuState = createSelector(getLayoutState, fromLayout.getMobileMenu);
export const selectMainMenuState = createSelector(getLayoutState, fromLayout.getMainMenu);
