import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { PolicyProductIdEnum, PolicyScenarioCodeEnum } from '../../insurance-policy';
import { LoadProductScenarioResponseInterface } from '../interfaces/load-product-scenario-response.interface';
import { ProductScenariosSelectors } from '../store/product-scenarios.selectors';
import { ProductScenariosState } from '../store/product-scenarios.state';

@Injectable({
    providedIn: 'root',
})
export class ProductScenariosDataService {
    constructor(private store$: Store<ProductScenariosState>) {}

    public getProductScenarios$(): Observable<LoadProductScenarioResponseInterface[]> {
        return this.store$.select((ProductScenariosSelectors.getProductScenarios));
    }

    public getProductScenarioCodes$(): Observable<PolicyScenarioCodeEnum[]> {
        return this.store$.select((ProductScenariosSelectors.getProductScenarioCodes));
    }

    public getProductScenarioByCode$(code: PolicyScenarioCodeEnum): Observable<LoadProductScenarioResponseInterface[]> {
        return this.store$.select((ProductScenariosSelectors.getProductScenarioByCode(code)));
    }

    public getProductScenarioByProductId$(
        productId: PolicyProductIdEnum
    ): Observable<LoadProductScenarioResponseInterface[]> {
        return this.store$.select((ProductScenariosSelectors.getProductScenarioByProductId(productId)));
    }
}
