import { Component, OnDestroy, OnInit } from '@angular/core';
import { ErrorInterface, NewModal, RelationActionService, RelationDataService, RelationInterface } from 'outshared-lib';
import { Observable, Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { FamilyCompositionModal } from '@app-de/my-zone/shared/family-composition/family-composition-modal/family-composition.modal';

@Component({
    selector: 'ins-future-family-composition-container',
    template: `
        <ins-future-family-composition
            [relation]="relation$ | async"
            [loading]="loading"
            [errors]="errors"
            (cancelFutureFamilyComposition)="showModal()"
        ></ins-future-family-composition>
    `,
})
export class FutureFamilyCompositionContainer implements OnDestroy, OnInit {
    public relation$: Observable<RelationInterface>;
    public loading: boolean;
    public errors: ErrorInterface[];

    private subscriptions = new Subscription();

    constructor(
        private newModal: NewModal,
        private relationActionService: RelationActionService,
        private relationDataService: RelationDataService,
    ) {
        this.loading = false;
        this.errors = [];
    }

    public ngOnInit(): void {
        this.relation$ = this.relationDataService.getRelation$().pipe(
            filter((relation: RelationInterface) => !!relation),
            take(1),
        );
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    public showModal(): void {
        const modalRef = this.newModal
            .open(FamilyCompositionModal)
            .afterClosed$()
            .pipe(filter((res) => res))
            .subscribe({
                next: () => this.onCancelFamilyComposition(),
            });

        this.loading = true;
        this.errors = [];
        this.subscriptions.add(modalRef);
    }

    private onCancelFamilyComposition(): void {
        const cancelFamilyComposition = this.relationActionService.cancelFamilyComposition$().subscribe({
            next: () => this.onCancelFamilyCompositionSuccess(),
            error: (errors) => this.onCancelFamilyCompositionError(errors),
        });

        this.subscriptions.add(cancelFamilyComposition);
    }

    private onCancelFamilyCompositionSuccess(): void {
        this.loading = false;
        this.errors = [];
    }

    private onCancelFamilyCompositionError(errors: ErrorInterface[]): void {
        this.errors = errors;
        this.loading = false;
    }
}
