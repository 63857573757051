<ng-template #conditionsCar>
    <p>
        Die genannten Punkte sind eine Zusammenfassung unserer Versicherungsleistungen. Weitere Details finden Sie in
        den
        <a
            [href]="documentsEnum.AllgemeineBedingungenAutoversicherung"
            rel="nofollow noopener"
            title="Versicherungsbedingungen"
            target="_blank"
        >
            Versicherungsbedingungen</a>.
    </p>
</ng-template>

<ng-container *ngIf="coverageId === CoverageIdEnum.Fahrerschutz">
    <ins-list-flat icon="dot" [svg]="true">
        <ins-list-flat-item>Beste Wahl</ins-list-flat-item>
        <ins-list-flat-item>Versichert Personenschäden des Fahrers bei einem Unfall</ins-list-flat-item>
        <ins-list-flat-item>Bei Anerkennung des Anspruchs: Ausbezahlung binnen 2 Wochen</ins-list-flat-item>
        <ins-list-flat-item>Maximale Versicherungssumme 1 Mio. €</ins-list-flat-item>
        <ins-list-flat-item>Entschädigung bei Verletzung oder Tod des Fahrers</ins-list-flat-item>
    </ins-list-flat>
    <p>Selbstbeteiligung: 0 €</p>
    <ng-container *ngTemplateOutlet="conditionsCar"></ng-container>
</ng-container>

<ng-container *ngIf="coverageId === CoverageIdEnum.Pannenhilfe">
    <ins-list-flat icon="dot" [svg]="true">
        <ins-list-flat-item>24/7 Notfallservice</ins-list-flat-item>
        <ins-list-flat-item>Pannenhilfe durch unseren zuverlässigen Partner ROLAND Assistance</ins-list-flat-item>
        <ins-list-flat-item>Pannenhilfe in ganz Europa</ins-list-flat-item>
        <ins-list-flat-item>Auch bei Schlüsselverlust oder leerem Tank</ins-list-flat-item>
    </ins-list-flat>
    <p>Selbstbeteiligung: 0 €</p>
    <ng-container *ngTemplateOutlet="conditionsCar"></ng-container>
</ng-container>
