import { Injectable } from '@angular/core';
import { CoveragePremiumCoverageInterface } from '../interfaces';

/** @deprecated use OutInsurancePolicyModule store */
@Injectable({
    providedIn: 'root',
})
export class CoverageTransformService {
    /** @deprecated use OutInsurancePolicyModule store */
    public mapCoveragesToFutureState(props: {
        purchased_coverages: CoveragePremiumCoverageInterface[];
        start_date: string;
        coverage_ids: {
            coverage_id: string;
        }[];
    }): CoveragePremiumCoverageInterface[] {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { purchased_coverages, coverage_ids, start_date } = props;
        const coverages: CoveragePremiumCoverageInterface[] = [];

        purchased_coverages.forEach((coverage) => {
            if (coverage.purchased_coverage) {
                coverages.push(this.mapPurchasedToFutureState(coverage_ids, coverage, start_date));
            } else {
                coverages.push(this.mapFuturePurchasedToFutureState(coverage_ids, coverage, start_date));
            }
        });

        return coverages;
    }

    /** @deprecated use OutInsurancePolicyModule store */
    public mapCoveragesToCurrentState(
        purchasedCoverages: CoveragePremiumCoverageInterface[]
    ): CoveragePremiumCoverageInterface[] {
        const coverages: CoveragePremiumCoverageInterface[] = [];

        purchasedCoverages.forEach((coverage: CoveragePremiumCoverageInterface) =>
            coverages.push(this.mapToCurrentState(coverage))
        );

        return purchasedCoverages;
    }

    /** @deprecated use OutInsurancePolicyModule store */
    private mapPurchasedToFutureState(
        coverageIds: { coverage_id: string }[],
        coverage: CoveragePremiumCoverageInterface,
        startDate: string
    ): CoveragePremiumCoverageInterface {
        const isPurchased: boolean = this.isPurchased(coverageIds, coverage.coverage_id);

        if (isPurchased) {
            return {
                ...coverage,
                purchased_coverage: {
                    ...coverage.purchased_coverage,
                    validity_status_code: 'ACTI',
                    end_date: null,
                },
                purchased_coverage_future: null,
            };
        } else {
            return {
                ...coverage,
                purchased_coverage: {
                    ...coverage.purchased_coverage,
                    validity_status_code: 'ACEI',
                    end_date: startDate,
                },
                purchased_coverage_future: null,
            };
        }
    }

    /** @deprecated use OutInsurancePolicyModule store */
    private mapFuturePurchasedToFutureState(
        coverageIdList: { coverage_id: string }[],
        coverage: CoveragePremiumCoverageInterface,
        startDate: string
    ): CoveragePremiumCoverageInterface {
        const isPurchased: boolean = this.isPurchased(coverageIdList, coverage.coverage_id);

        if (isPurchased) {
            return {
                ...coverage,
                purchased_coverage: null,
                purchased_coverage_future: {
                    ...coverage.purchased_coverage_future,
                    validity_status_code: 'TOEK',
                    start_date: startDate,
                    end_date: null,
                },
            };
        } else {
            return {
                ...coverage,
                purchased_coverage: null,
                purchased_coverage_future: null,
            };
        }
    }

    /** @deprecated use OutInsurancePolicyModule store */
    private mapToCurrentState(premiumCoverage: CoveragePremiumCoverageInterface): CoveragePremiumCoverageInterface {
        const coverage = { ...premiumCoverage };

        if (coverage.purchased_coverage && coverage.purchased_coverage.end_date) {
            const purchasedCoverage = { ...coverage.purchased_coverage };

            purchasedCoverage.end_date = null;
            purchasedCoverage.validity_status_code = 'ACTI';

            coverage.purchased_coverage = purchasedCoverage;
        }

        if (coverage.purchased_coverage_future) {
            coverage.purchased_coverage_future = null;
        }

        return coverage;
    }

    /** @deprecated use OutInsurancePolicyModule store */
    private isPurchased(coverageIdList: { coverage_id: string }[], coverageId: string): boolean {
        const foundCoverage = coverageIdList.find((coverage) => coverage.coverage_id === coverageId);
        return Boolean(foundCoverage);
    }
}
