import { Component } from '@angular/core';
import { DateService } from 'outshared-lib';
import { AppRoutesEnum } from '@app-de/routes/app-routes.enum';

@Component({
    selector: 'ins-topbar',
    templateUrl: './topbar.component.html',
    styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent {
    public companyId: string;
    public loginRoute = [AppRoutesEnum.Login];

    private day: number = this.dateService.day();
    private month: number = this.dateService.month();

    public get isValentinesDay(): boolean {
        return this.day === 14 && this.month === 2;
    }

    constructor(private dateService: DateService) {}
}
