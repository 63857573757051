import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, OnDestroy, Renderer2, RendererFactory2 } from '@angular/core';
import { RelationDataService, RelationInterface } from 'outshared-lib';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { LIVECHAT_CONFIGURATION } from '@app-de/core/livechat/livechat.configuration';
import {
    ChatbotHistoryInterface,
    ChatContextInterface,
    LivechatConfigurationInterface,
} from '@app-de/core/livechat/livechat.interfaces';

@Injectable({
    providedIn: 'root',
})
export class LivechatService implements OnDestroy {
    constructor(
        private rendererFactory: RendererFactory2,
        private relationDataService: RelationDataService,
        @Inject(DOCUMENT) private document: Document,
        @Inject(LIVECHAT_CONFIGURATION) private liveChatConfiguration: LivechatConfigurationInterface,
    ) {}

    private readonly chatWindowId = 'Microsoft_Omnichannel_LCWidget';
    private readonly chatFrameWindowId = 'Microsoft_Omnichannel_LCWidget_Chat_Iframe_Window';
    private readonly LCW_READY_EVENT = 'lcw:ready';
    private readonly LCW_ON_CLOSE_EVENT = 'lcw:onClose';

    private renderer: Renderer2;
    private isInitialized = false;
    private destroy$ = new Subject<void>();

    private relation$: Observable<RelationInterface> = this.relationDataService.getRelation$();
    private chatBotHistory: ChatbotHistoryInterface[] = [];
    private _chatContext: ChatContextInterface = {};

    private setChatContext(value: ChatContextInterface): void {
        this._chatContext = { ...this._chatContext, ...value };
    }

    private contextProvider = (): ChatContextInterface => this._chatContext;

    private handleLivechatReadyEvent = (): void => {
        this.openChat();
    };

    private setUserContext$(): Observable<RelationInterface> {
        return this.relation$.pipe(
            filter(Boolean),
            tap((relation: RelationInterface) => {
                this.setChatContext({
                    cyno_relationnumber: { value: relation.relation_id.toString(), isDisplayable: true },
                    cyno_emailaddress: { value: relation.primary_email_address.email_address, isDisplayable: true },
                });
            }),
        );
    }

    private openChat(): void {
        Microsoft.Omnichannel.LiveChatWidget.SDK.setContextProvider(this.contextProvider);
        setTimeout(() => {
            Microsoft.Omnichannel.LiveChatWidget.SDK.startChat({ inNewWindow: false });
            this.toggleIframeVisibility(true);
        }, 400)

    }

    private handleLivechatClosedEvent = (): void => {
        this.toggleIframeVisibility(false);
        this.removeChatbotHistory();
        this.destroy$.next();
    };

    private getIframe(): Element | null {
        return this.document.getElementById(this.chatFrameWindowId);
    }

    private toggleIframeVisibility(visible: boolean): void {
        const iframe = this.getIframe();
        if (iframe) {
            this.renderer.setStyle(iframe, 'visibility', visible ? 'visible' : 'hidden');
        }
    }

    private addScript(): void {
        this.renderer = this.rendererFactory.createRenderer(null, null);
        const script = this.renderer.createElement('script');

        const attributes = {
            v2: '',
            type: 'text/javascript',
            id: this.chatWindowId,
            src: this.liveChatConfiguration.scriptSrc,
            'data-color-override': '#F173AC',
            'data-app-id': this.liveChatConfiguration.dataAppId,
            'data-lcw-version': this.liveChatConfiguration.dataLcwVersion,
            'data-org-id': this.liveChatConfiguration.dataOrgId,
            'data-org-url': this.liveChatConfiguration.dataOrgUrl,
            async: true,
        };

        Object.entries(attributes).forEach(([key, value]) => this.renderer.setAttribute(script, key, value.toString()));

        this.document.head.appendChild(script);
    }

    public init(authenticated: boolean): void {
        if (!this.isInitialized) {
            this.addScript();
        }
        this.manageUserContextAndActions(authenticated);
    }

    private manageUserContextAndActions(authenticated: boolean): void {
        if (authenticated) {
            this.setUserContext$()
                .pipe(takeUntil(this.destroy$))
                .subscribe({
                    next: () => {
                        if (!this.isInitialized) {
                            this.addListeners();
                        } else {
                            this.openChat();
                        }
                        this.isInitialized = true;
                    },
                });
        } else {
            if (!this.isInitialized) {
                this.addListeners();
            } else {
                this.openChat();
            }
            this.isInitialized = true;
        }
    }

    public clearChatContext(): void {
        this._chatContext = {};
    }

    public removeChatbotHistory(): void {
        this.chatBotHistory = [];
    }

    public setChatbotHistory(value: ChatbotHistoryInterface): void {
        this.chatBotHistory = [...this.chatBotHistory, value];
        this.clearChatContext();
        this.chatBotHistory.forEach((item, index) =>
            this.setChatContext({ [`${index + 1} : ${item.question}`]: { value: item.answer, isDisplayable: true } }),
        );
    }

    public addListeners(): void {
        this.renderer.listen('window', this.LCW_READY_EVENT, this.handleLivechatReadyEvent);
        this.renderer.listen('window', this.LCW_ON_CLOSE_EVENT, this.handleLivechatClosedEvent);
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
