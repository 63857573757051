<ins-modal-close></ins-modal-close>

<ins-modal-title>Passwort vergessen?</ins-modal-title>

<ins-modal-content>
    <ng-container *ngIf="!success">
        <p>Wie möchten Sie Ihr vorübergehendes Passwort erhalten?</p>

        <form [formGroup]="form" role="form" (ngSubmit)="onSubmit()">
            <div class="form-group">
                <ng-container *ngFor="let option of options">
                    <cyno-radio [value]="option.value" formControlName="recoveryMethod">
                        {{ option.description }}
                    </cyno-radio>

                    <br />
                </ng-container>
            </div>

            <ins-alert type="validation" *ngIf="errors.length > 0" [errors]="errors"></ins-alert>
        </form>
    </ng-container>

    <ng-container *ngIf="success">
        <p *ngFor="let notification of notifications">
            {{ notification.message }}
        </p>
        <p>Bitte beachten: Ihr vorläufiges Passwort ist 24 Stunden gültig.</p>
    </ng-container>
</ins-modal-content>

<ng-container *ngIf="!success">
    <ins-modal-actions alignment="between">
        <ins-modal-actions-previous [link]="backLink"></ins-modal-actions-previous>

        <button [disabled]="sending" class="btn btn--primary" (click)="onSubmit()">Senden</button>
    </ins-modal-actions>
</ng-container>

<ng-container *ngIf="success">
    <ins-modal-actions alignment="end">
        <button class="btn btn--primary" (click)="onCloseAfterSuccess()">Schließen</button>
    </ins-modal-actions>
</ng-container>
