import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { CoveragePremiumInterface } from '../../interfaces';
import * as fromMyZone from '../../my-zone';
import { getAllCoverages, getSelected, getTotal } from './coverage-premium.selectors';

/** @deprecated use InsurancePolicyDataService */
@Injectable({
    providedIn: 'root',
})
export class CoveragePremiumSelectorService {
    /** @deprecated use InsurancePolicyDataService */
    public getTotal$: Observable<number> = this.store$.select((getTotal));
    /** @deprecated use InsurancePolicyDataService */
    public getAllCoverages$: Observable<CoveragePremiumInterface[]> = this.store$.select((getAllCoverages));
    /** @deprecated use InsurancePolicyDataService */
    public getSelected$: Observable<CoveragePremiumInterface> = this.store$.select((getSelected));

    constructor(private store$: Store<fromMyZone.State>) {}
}
