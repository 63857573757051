<ins-modal-close></ins-modal-close>

<ins-modal-title>Ihre Angaben eintragen</ins-modal-title>

<ins-modal-content>
    <p>Nach dem Durchlaufen der letzten Schritte sind Sie ab dem gewünschten Beginndatum versichert.</p>

    <form (ngSubmit)="onSubmit()" [formGroup]="form" class="complete-generated-account-form" role="form" tabindex="1">
        <cyno-field e2e="currentPassword">
            <cyno-label>
                Neues Passwort
                <ins-click-tooltip position="bottomRight">
                    Wählen Sie ein Passwort, das mindestens 8 Zeichen lang ist und mindestens 1 Ziffer enthält. Ihr
                    Nutzername darf darin nicht vorkommen. Bitte achten Sie auch auf die Groß- und Kleinschreibung.
                </ins-click-tooltip>
            </cyno-label>
            <cyno-password
                [passwordRequirements]="true"
                autocomplete="new-password"
                formControlName="newPassword"
                insShowPassword
            ></cyno-password>
            <cyno-error *ngIf="newPassword.touched && newPassword.hasError('required')">
                Hoppla, da fehlt etwas. Bitte Passwort eingeben.
            </cyno-error>
            <ins-element-validate-errors-container [name]="VALIDATOR_NAME_PASSWORD"></ins-element-validate-errors-container>
        </cyno-field>

        <cyno-field e2e="repeatPassword">
            <cyno-label>Passwort wiederholen</cyno-label>
            <cyno-password formControlName="repeatPassword"></cyno-password>
            <cyno-error *ngIf="repeatPassword.touched && repeatPassword.hasError('required')">
                Hoppla, da fehlt etwas. Bitte Passwort eingeben.
            </cyno-error>
            <cyno-error *ngIf="repeatPassword.touched && repeatPassword.hasError('mismatchedPassword')">
                Überprüfen Sie bitte, ob die Passwörter übereinstimmen.
            </cyno-error>
        </cyno-field>

        <cyno-field>
            <cyno-label>Dürfen wir Ihnen Angebote schicken?</cyno-label>
            <cyno-radio formControlName="optOutIndication" value="false">Ja</cyno-radio>
            <cyno-radio formControlName="optOutIndication" value="true">Nein</cyno-radio>
            <ins-alert *ngIf="optOutIndication.touched && optOutIndication.hasError('required')" type="validation">
                Geben Sie bitte noch an, ob Sie Angebote erhalten möchten.
            </ins-alert>
        </cyno-field>

        <cyno-field>
            <cyno-label>Handynummer</cyno-label>
            <cyno-phone
                e2e="telephone_number"
                formControlName="phone"
                id="phone"
                placeholder="017123456789"
            ></cyno-phone>
            <cyno-error *ngIf="phone.touched && phone.hasError('required')">
                Hoppla, die Telefonnummer fehlt noch
            </cyno-error>
            <cyno-error
                *ngIf="
                    !phone.pending && phone.touched && (phone.hasError('invalidAsync') || phone.hasError('minlength'))
                "
            >
                Sorry, das ist keine gültige Handynummer
            </cyno-error>
        </cyno-field>

        <ins-alert *ngIf="errors.length > 0" [errors]="errors" type="validation"></ins-alert>
    </form>
</ins-modal-content>

<ins-modal-actions alignment="end">
    <button (click)="onSubmit()" [disabled]="submitting || !form.valid" class="btn btn--primary">Weiter</button>
</ins-modal-actions>
