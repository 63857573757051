import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NewModalModule } from 'outshared-lib';
import { ModalComponentsModule } from '@app-de/components';
import { DeceaseModal } from '@app-de/my-zone/shared/modals/decease-modal/decease.modal';

@NgModule({
    declarations: [DeceaseModal],
    exports: [DeceaseModal],
    imports: [
        CommonModule,
        NewModalModule,
        RouterModule,
        ModalComponentsModule,
    ],
})
export class DeceaseModalModule {
}
