<div class="container" [class.breadcrumb-container]="breadcrumb?.length < 2">
    <ol class="breadcrumb" *ngIf="breadcrumb?.length >= 2">
        <li *ngFor="let item of breadcrumb">
            <a *ngIf="item.href" href="{{ item.href }}" title="{{ item.title }}">
                <span>{{ item.title }}</span>
            </a>
            <a *ngIf="item.routerLink" [routerLink]="item.routerLink" title="{{ item.title }}">
                <span>{{ item.title }}</span>
            </a>
            <span *ngIf="!item.routerLink && !item.href">{{ item.title }}</span>
        </li>
    </ol>
</div>
