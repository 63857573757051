import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NewModalModule as ModalModule } from 'outshared-lib';
import { TerminateInsuranceFeedbackModal } from './terminate-insurance-feedback.modal';
import { ModalComponentsModule } from '@app-de/components';
import { DateFormatPipeModule } from '@app-de/shared/pipes/date-format/date-format.module';

@NgModule({
    imports: [
        CommonModule,
        ModalModule,
        ModalComponentsModule,
        DateFormatPipeModule,
    ],
    declarations: [TerminateInsuranceFeedbackModal],
    providers: [],
})
export class TerminateInsuranceFeedbackModule {
}
