import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
    WucButtonModule,
    WucFormErrorModule,
    WucFormFieldLabelModule,
    WucFormFieldModule,
    WucInputDatePickerModule,
} from '@inshared/website-ui-components';
import { DetailsSubmitButtonModule } from '../../../details-submit-button/details-submit-button.module';
import { InsurancePolicyHolderFormComponent } from './insurance-policy-holder-form.component';
import { CynoFormModule } from '@app-de/shared/cyno-form/cyno-form.module';
import { DatepickerModule } from '@app-de/shared/datepicker/datepicker.module';

@NgModule({
    imports: [
        CommonModule,
        CynoFormModule,
        DetailsSubmitButtonModule,
        DatepickerModule,
        WucFormErrorModule,
        WucFormFieldModule,
        WucFormFieldLabelModule,
        WucInputDatePickerModule,
        WucFormErrorModule,
        WucButtonModule,
    ],
    exports: [InsurancePolicyHolderFormComponent],
    declarations: [InsurancePolicyHolderFormComponent],
})
export class InsurancePolicyHolderFormModule { }
