import { Injectable } from '@angular/core';
import { RequestResponse, RequestService } from 'outshared-lib';
import { Observable, of } from 'rxjs';
import { PremiumDeterminingFactorElementNameEnum } from './premium-determining-factor/premium-determining-factor-element-name.enum';
import { PremiumDeterminingFactorTypeEnum } from './premium-determining-factor/premium-determining-factor-type.enum';

@Injectable({
    providedIn: 'root',
})
export class PremiumDeterminingFactorsService {
    constructor(private requestService: RequestService) {}

    public getOptions$(elementName: string): Observable<any[] /* ElementInhoudInterface[] */> {
        const factorElementName = PremiumDeterminingFactorElementNameEnum[elementName];
        const element = factorElementName ? factorElementName : elementName;

        return of([]); // this.elementsService.getElementContent(element);
    }

    public updateModifiablePremiumDeterminingFactor$(
        purchasedProductSequenceNumber: number,
        props: any /* UpdateModifiablePremiumFactorPropsInterface */,
    ): Observable<RequestResponse> {
        // TODO: add interface: UpdateModifiablePremiumFactorPayloadInterface
        const payload: any = this.getPayload(purchasedProductSequenceNumber, props);

        return this.requestService.post(
            'en-gb/insurance-product/modify/active-modifiable_premium_determining_factor',
            payload,
        );
    }

    private getPayload(
        purchasedProductSequenceNumber: number,
        props: any /* UpdateModifiablePremiumFactorPropsInterface */,
    ): any /* UpdateModifiablePremiumFactorPropsInterface */ {
        const { premium_determining_factor_id, start_date, premium_determining_factor_value, format_code } = props;
        const premiumDeterminingFactorType = PremiumDeterminingFactorTypeEnum[format_code];
        const payload = {
            modifiable_premium_determining_factor: {
                purchased_product_sequence_number: purchasedProductSequenceNumber,
                premium_determining_factor_id,
                start_date,
                [premiumDeterminingFactorType]: premium_determining_factor_value,
            },
        };

        return payload;
    }
}
