import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { DateService } from 'outshared-lib';

@Injectable({ providedIn: 'root' })
export class AgeValidator {
    constructor(private dateService: DateService) {}

    /**
     * range validator function - validates if date is between min and max
     * @param min number
     * @param max number
     */
    public range(min: number, max: number): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const errors: string[] = [];
            const value = control.value;
            const isValidDate = this.isValidDate(value);

            if (isValidDate && this.isMinDate(min, value)) {
                errors.push('is min date error');
            }

            if (isValidDate && this.isMaxDate(max, value)) {
                errors.push('is max date error');
            }

            return errors.length > 0 ? { invalidAgeRange: true } : null;
        };
    }

    /**
     * minimum validator function - validates if date is below minium age
     * @param min number
     */
    public minimum(min: number): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const errors: string[] = [];
            const value = control.value;
            const isValidDate = this.isValidDate(value);

            if (isValidDate && this.isMinDate(min, value)) {
                errors.push('is min date error');
            }

            return errors.length > 0 ? { invalidAgeMinimum: true } : null;
        };
    }

    /**
     * validate minimum difference between years function - validates if date has enough time between compareDate
     * @param date date control
     * @param years number
     */
    public validateMinimumRange(date: AbstractControl, years: number): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (!date) {
                return null;
            }

            if (!this.dateService.isValid(control.value)) {
                return null;
            }

            const minimumDate = this.dateService.add(date.value, years, 'year');

            if (control.value < minimumDate) {
                return { invalidMinimumRange: true };
            }

            return null;
        };
    }

    /**
     * maximum validator function - validates if date is above maximum age
     * @param max number
     */
    public maximum(max: number): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const errors: string[] = [];
            const value = control.value;
            const isValidDate = this.isValidDate(value);

            if (isValidDate && this.isMaxDate(max, value)) {
                errors.push('is max date error');
            }

            return errors.length > 0 ? { invalidAgeMaximum: true } : null;
        };
    }

    /**
     * isValidDate - returns true if length is 10 and date is valid date format
     * @param value string
     */
    private isValidDate(value: string): boolean {
        if (!value) {
            return false;
        }

        if (value.length < 10) {
            return false;
        }

        return this.dateService.isValid(value);
    }

    /**
     * isMinDate - calculates age and checks if age is below given age
     * @param min number
     * @param value string
     */
    private isMinDate(min: number, value: string): boolean {
        return this.calculateAge(value) < min;
    }

    /**
     * isMaxDate - calculates age and checks if age is above given age
     * @param max number
     * @param value string
     */
    private isMaxDate(max: number, value: string): boolean {
        return this.calculateAge(value) > max;
    }

    /**
     * calculateAge - calculates age
     * date format needs to be: YYYY-MM-DD
     * @param value string - YYYY-MM-DD
     */
    private calculateAge(value: string): number {
        return this.dateService.calculateAge(this.createDateObject(value), this.createDateNowObject());
    }

    /**
     * createDateObject - creates object which is used for dateService.calculateAge
     * date format needs to be: YYYY-MM-DD
     * @param value string - YYYY-MM-DD
     */
    private createDateObject(value: string): { year: number; month: number; day: number } {
        const date = value.split('-');

        if (!date[0] || !date[1] || !date[2]) {
            return {
                year: null,
                month: null,
                day: null,
            };
        }

        return {
            year: parseInt(date[0], 10),
            month: parseInt(date[1], 10),
            day: parseInt(date[2], 10),
        };
    }

    /**
     * createDateNowObject - creates object with date now() which is used for dateService.calculateAge
     */
    private createDateNowObject(): { year: number; month: number; day: number } {
        return this.createDateObject(this.dateService.nowAsString());
    }
}
