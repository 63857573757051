import { Injectable } from '@angular/core';
import { CoveragePremiumCoverageInterface, CoveragePremiumPriceInterface } from 'outshared-lib';

@Injectable({
    providedIn: 'root',
})
export class CoveragePriceService {
    public mergePremium(
        coverage: CoveragePremiumCoverageInterface,
        baseCoverage: CoveragePremiumCoverageInterface,
    ): CoveragePremiumCoverageInterface {
        const newCoverage: CoveragePremiumCoverageInterface = Object.assign({}, coverage);

        newCoverage.premium = this.mergePriceObject(coverage.premium, baseCoverage.premium);

        newCoverage.premium_including_discount = this.mergePriceObject(
            coverage.premium_including_discount,
            baseCoverage.premium_including_discount,
        );

        if (coverage.collectivity_discount) {
            newCoverage.collectivity_discount = this.mergePriceObject(
                coverage.collectivity_discount,
                baseCoverage.collectivity_discount,
            );
        }

        return newCoverage;
    }

    private mergePriceObject(
        priceA: CoveragePremiumPriceInterface,
        priceB: CoveragePremiumPriceInterface,
    ): CoveragePremiumPriceInterface {
        const mergedPrice: CoveragePremiumPriceInterface = {
            basic_premium: 0,
            premium: 0,
            premium_including_premium_tax: 0,
            premium_tax: 0,
        };

        Object.keys(priceA).forEach((key) => {
            if (typeof priceA[key] === 'number') {
                mergedPrice[key] = priceA[key] + priceB[key];
            } else {
                mergedPrice[key] = priceA[key];
            }
        });

        return mergedPrice;
    }
}
