import { Component } from '@angular/core';
import { NoChangesPossibleNotificationService } from './no-changes-possible-notification.service';

@Component({
    selector: 'ins-no-changes-possible-notification',
    templateUrl: './no-changes-possible-notification.component.html',
})
export class NoChangesPossibleNotificationComponent {
    public showNotification$ = this.noChangesPossibleNotificationService.showNotification$;

    constructor(private noChangesPossibleNotificationService: NoChangesPossibleNotificationService) {}

    public mailTo(email: string): void {
        window.location.href = `mailto:${email}`;
    }
}
