import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { WucListModule, WucSafeHtmlModule } from '@inshared/website-ui-components';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { ImmerGemeintSindModule } from '../../../shared/immer-gemeint-sind/immer-gemeint-sind.module';
import { SmartTipComponent } from './smart-tip/smart-tip.component';
import { SmartTipSummaryComponent } from './smart-tip-summary/smart-tip-summary.component';
import { SmartTipsCardComponent } from './smart-tips-card/smart-tips-card.component';
import { BackComponent } from '@app-de/pages/smart-tips/shared/back/back.component';
import { BannerComponent } from '@app-de/pages/smart-tips/shared/banner/banner.component';
import { LastUpdateComponent } from '@app-de/pages/smart-tips/shared/last-update/last-update.component';
import { ReadArticleComponent } from '@app-de/pages/smart-tips/shared/read-article/read-article.component';
import { CardModule } from '@app-de/shared/card/card.module';
import { DateFormatPipeModule } from '@app-de/shared/pipes/date-format/date-format.module';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        LazyLoadImageModule,
        DateFormatPipeModule,
        CardModule,
        WucSafeHtmlModule,
        ImmerGemeintSindModule,
        WucListModule,
    ],
    declarations: [
        ReadArticleComponent,
        BackComponent,
        BannerComponent,
        LastUpdateComponent,
        SmartTipsCardComponent,
        SmartTipComponent,
        SmartTipSummaryComponent,
    ],
    exports: [
        ReadArticleComponent,
        BackComponent,
        BannerComponent,
        LastUpdateComponent,
        SmartTipsCardComponent,
        SmartTipComponent,
        SmartTipSummaryComponent,
    ],
})
export class SharedModule {
}
