import { Action } from '@ngrx/store';
import { ErrorInterface } from '../../core';
import {
    DownloadInvoiceInterface,
    GetInvoiceDetailsInterface,
    GetInvoiceDetailsPayloadInterface,
    GetInvoicesInterface,
    GetInvoicesPayloadInterface,
} from '../interfaces';

export enum InvoiceActions {
    GetInvoices = '[Invoice] Get Invoices',
    GetInvoicesSuccess = '[Invoice] Get Invoices Success',
    GetInvoicesError = '[Invoice] Get Invoices Error',
    GetInvoiceDetails = '[Invoice] Get Invoice Details',
    GetInvoiceDetailsSuccess = '[Invoice] Get Invoice Details Success',
    GetInvoiceDetailsError = '[Invoice] Get Invoice Details Error',
    DownloadInvoice = '[Invoice] Download Invoice',
    DownloadInvoiceSuccess = '[Invoice] Download Invoice Success',
    DownloadInvoiceError = '[Invoice] Download Invoice Error',
    ResetInvoices = '[Invoice] Reset',
}

export class GetInvoicesAction implements Action {
    public readonly type = InvoiceActions.GetInvoices;

    constructor(public payload: GetInvoicesPayloadInterface) {}
}

export class GetInvoicesSuccessAction implements Action {
    public readonly type = InvoiceActions.GetInvoicesSuccess;

    constructor(public payload: GetInvoicesInterface) {}
}

export class GetInvoicesErrorAction implements Action {
    public readonly type = InvoiceActions.GetInvoicesError;

    constructor(public payload: { errors: ErrorInterface[] }) {}
}

export class GetInvoiceDetailsAction implements Action {
    public readonly type = InvoiceActions.GetInvoiceDetails;

    constructor(public payload: GetInvoiceDetailsPayloadInterface) {}
}

export class GetInvoiceDetailsSuccessAction implements Action {
    public readonly type = InvoiceActions.GetInvoiceDetailsSuccess;

    constructor(public payload: GetInvoiceDetailsInterface) {}
}

export class GetInvoiceDetailsErrorAction implements Action {
    public readonly type = InvoiceActions.GetInvoiceDetailsError;

    constructor(public payload: { errors: ErrorInterface[] }) {}
}

export class DownloadInvoiceAction implements Action {
    public readonly type = InvoiceActions.DownloadInvoice;

    constructor(public payload: { invoiceId: string }) {}
}

export class DownloadInvoiceSuccessAction implements Action {
    public readonly type = InvoiceActions.DownloadInvoiceSuccess;

    constructor(public payload: DownloadInvoiceInterface) {}
}

export class DownloadInvoiceErrorAction implements Action {
    public readonly type = InvoiceActions.DownloadInvoiceError;

    constructor(public payload: { errors: ErrorInterface[] }) {}
}

export class ResetInvoicesAction implements Action {
    public readonly type = InvoiceActions.ResetInvoices;
}

export type InvoiceActionType =
    | GetInvoicesAction
    | GetInvoicesSuccessAction
    | GetInvoicesErrorAction
    | GetInvoiceDetailsAction
    | GetInvoiceDetailsSuccessAction
    | GetInvoiceDetailsErrorAction
    | DownloadInvoiceAction
    | DownloadInvoiceSuccessAction
    | DownloadInvoiceErrorAction
    | ResetInvoicesAction;
