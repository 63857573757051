import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnChanges,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { CoveragePremiumInterface, ProductBaseInterface, ProductNameEnum } from 'outshared-lib';
import { ScrollService } from '@app-de/core/services/scroll.service';
import { SelectedPanelEnum } from '@app-de/my-zone/enums';
import { InsuranceListQueryParams } from '@app-de/my-zone/interfaces';
import { MyZoneRoutes } from '@app-de/my-zone/routing/my-zone-routing.routes';
import { AnnualPaymentService } from '@app-de/my-zone/services/annual-payment.service';
import { AppRoutesEnum } from '@app-de/routes';
import { PanelExpandableComponent } from '@app-de/shared/panel-expandable/panel-expandable.component';
import { PanelExpandableDirectionEnum } from '@app-de/shared/panel-expandable/panel-expandable.enum';

const INSURANCE_LIST_ID = 'insurance-list-';

@Component({
    selector: 'ins-insurance-list',
    templateUrl: './insurance-list.component.html',
    styleUrls: ['./insurance-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class InsuranceListComponent implements OnChanges {
    @Input() public product: ProductBaseInterface;
    @Input() public queryParams: InsuranceListQueryParams;
    @Input() public calculation: CoveragePremiumInterface;
    @Input() public isScreenSmall: boolean = false;

    @ViewChild(PanelExpandableComponent, { static: true }) public panel: PanelExpandableComponent;

    public AppRoutesEnum = AppRoutesEnum;
    public myZoneRoutes: typeof MyZoneRoutes = MyZoneRoutes;
    public productNameEnum: typeof ProductNameEnum = ProductNameEnum;
    public selectedPanel: SelectedPanelEnum = SelectedPanelEnum.Close;
    public selectedPanelEnum: typeof SelectedPanelEnum = SelectedPanelEnum;
    public panelExpandableDirectionEnum = PanelExpandableDirectionEnum;
    public currentPeriod = null;

    constructor(private scrollService: ScrollService, private annualPaymentService: AnnualPaymentService) {}

    public get isCurrent(): boolean {
        return ['ACTI', 'ACEI', 'NEVT', 'EIND', 'ACWI'].includes(this.product.validity_status_code);
    }

    public get isFuture(): boolean {
        return ['OSTP', 'TOEK'].includes(this.product.validity_status_code);
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.queryParams) {
            this.onQueryParams(changes.queryParams.currentValue);
        }

        if (!this.calculation?.product) {
            return;
        }

        this.currentPeriod = this.annualPaymentService.getCurrentPeriod(this.calculation?.product);
    }

    private onQueryParams(queryParams: InsuranceListQueryParams): void {
        const productSequenceNumber: number = Number(queryParams.purchased_product);

        if (productSequenceNumber !== this.product.purchased_product_sequence_number) {
            this.panel.close();
            return;
        }

        this.togglePanel(queryParams);
        this.setSelectedPanel(queryParams);
        this.scrollToPanel(queryParams);
    }

    private setSelectedPanel(params: InsuranceListQueryParams): void {
        this.selectedPanel = params.panel;
    }

    private togglePanel(queryParams: InsuranceListQueryParams): void {
        if (queryParams.panel === SelectedPanelEnum.Close) {
            this.panel.close();
        } else {
            this.panel.open();
        }
    }

    private scrollToPanel(queryParams: InsuranceListQueryParams): void {
        const purchasedProduct = queryParams?.purchased_product ?? 0;
        const elementid = `#${INSURANCE_LIST_ID}${purchasedProduct}`;

        if (!queryParams.scroll) {
            return;
        }

        this.scrollService.scrollElementOffset(elementid, 0, 0);
    }
}
