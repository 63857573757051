import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LabelComponent } from './label.component';

@NgModule({
    imports: [CommonModule],
    exports: [LabelComponent],
    declarations: [LabelComponent],
})
export class LabelModule {}
