import {
    Component,
    Input,
    OnInit,
} from '@angular/core';
import {
    ApiValidityStatusCodeEnum,
    DateService,
    ProductBaseInterface,
    ValidityStatusCodeEnum,
} from 'outshared-lib';
import { DetailsTableDisplayEnum } from '../../details-table/details-table-display.enum';
import { PanelExpandableComponent } from '@app-de/shared/panel-expandable/panel-expandable.component';

@Component({
    selector: 'ins-insurance-regular-driver-data',
    styleUrls: ['./insurance-regular-driver-data.component.scss'],
    templateUrl: './insurance-regular-driver-data.component.html',
})
export class InsuranceDriverDataComponent implements OnInit {
    @Input() public panel: PanelExpandableComponent;
    @Input() public product: ProductBaseInterface;

    public validityStatusCodeEnum: typeof ValidityStatusCodeEnum;
    public detailsTableDisplayEnum: typeof DetailsTableDisplayEnum;
    public productStartDate: string;
    public currentDate: string;

    constructor(
        private readonly dateService: DateService
    ) {
        this.validityStatusCodeEnum = ValidityStatusCodeEnum;
        this.detailsTableDisplayEnum = DetailsTableDisplayEnum;
    }

    public get hasRegularDriver(): boolean {
        return Boolean(
            this.product.non_regular_driver &&
            this.product.non_regular_driver?.start_date);
    }

    /**
     * If the product is in future state and the driver is deviant
     * the regular driver is the future regular driver. If the product
     * is active the future regular driver will become the regular
     * driver. In this case we show the future regular driver as
     * regular driver.
     */
    public get hasRegularDriverFuture(): boolean {
        return Boolean(
            !this.product.non_regular_driver &&
            this.product.non_regular_driver_future);
    }

    public get isPolicyHolder(): boolean {
        return Boolean(!this.product.non_regular_driver);
    }

    public get canAddRegularDriver(): boolean {
        return Boolean(
            !this.product.non_regular_driver?.end_date &&
            !this.product.non_regular_driver_future);
    }

    public get regularDriverName(): string {
        return `
            ${this.product.non_regular_driver?.gender_description}
            ${this.product.non_regular_driver?.initials}
            ${this.product.non_regular_driver?.surname}
        `;
    }

    public get regularDriverFutureName(): string {
        return `
            ${this.product.non_regular_driver_future?.gender_description}
            ${this.product.non_regular_driver_future?.initials}
            ${this.product.non_regular_driver_future?.surname}
        `;
    }

    public get isActiveProduct(): boolean {
        return [
            ApiValidityStatusCodeEnum.Active,
            ApiValidityStatusCodeEnum.ActiveChange,
        ].includes(this.product.validity_status_code as unknown as ApiValidityStatusCodeEnum);
    }

    public get isFutureProduct(): boolean {
        return Boolean(this.product.validity_status_code === ApiValidityStatusCodeEnum.Future);
    }

    public ngOnInit(): void {
        this.setProductStartDate();
        this.setCurrentDate();
    }

    private setProductStartDate(): void {
        if (this.product.non_regular_driver) {
            this.productStartDate = this.dateService
                .format(this.product.non_regular_driver?.start_date, '');
        }
    }

    private setCurrentDate(): void {
        this.currentDate = this.dateService.format('', '');
    }
}
