import { PolicyProductIdEnum, PolicyValidityStatusCodeEnum } from 'outshared-lib';
import { DirectControlButtonActionEnum, SelectedPanelEnum } from '@app-de/my-zone/enums';
import { DirectControlButtonIdEnum } from '@app-de/my-zone/enums/direct-control-button-id.enum';
import { AppRoutesEnum } from '@app-de/routes/app-routes.enum';
import { SalesfunnelRoutes } from '@app-de/salesfunnel/routing';

export interface DirectControlOptions {
    id: DirectControlButtonIdEnum;
    order?: number;
    name: string;
    actionButton: string;
    action: string;
    actionLink: string;
    hash?: SelectedPanelEnum;
    icon: string;
    product?: PolicyProductIdEnum[];
    status?: PolicyValidityStatusCodeEnum[];
    serialNumber?: number;
    productId?: string;
    statusCode?: string;
    disabled?: boolean;
    actionHash?: string;
    category?: string;
}

export const regelenButtonsConfig: DirectControlOptions[] = [
    {
        id: DirectControlButtonIdEnum.InternationalMotorInsuranceCard,
        order: 30,
        name: 'Grüne Karte herunterladen',
        actionButton: 'N',
        action: DirectControlButtonActionEnum.TogglePanel,
        actionLink: 'groenekaart',
        hash: SelectedPanelEnum.InternationalMotorInsuranceCard,
        icon: null,
        product: [
            PolicyProductIdEnum.Car,
            PolicyProductIdEnum.Moped,
            PolicyProductIdEnum.Caravan,
            PolicyProductIdEnum.Motorcycle,
        ],
        status: [
            PolicyValidityStatusCodeEnum.Future,
            PolicyValidityStatusCodeEnum.Active,
            PolicyValidityStatusCodeEnum.ActiveChange,
            PolicyValidityStatusCodeEnum.ActiveEnded,
            PolicyValidityStatusCodeEnum.Disbarred,
            PolicyValidityStatusCodeEnum.Transfer,
        ],
    },
    // Disabled for WIDL-2081
    // {
    //     id: DirectControlButtonIdEnum.InsuranceRegularDriver,
    //     order: 40,
    //     name: 'Hauptfahrer ändern',
    //     actionButton: 'N',
    //     action: 'toggle',
    //     actionLink: 'BestuurderWijzigen',
    //     hash: SelectedPanelEnum.ChangeDriver,
    //     icon: null,
    //     product: [
    //         PolicyProductIdEnum.Car,
    //         PolicyProductIdEnum.Moped,
    //         PolicyProductIdEnum.Motorcycle,
    //         PolicyProductIdEnum.Bicycle,
    //     ],
    //     status: [
    //         PolicyValidityStatusCodeEnum.Active,
    //         PolicyValidityStatusCodeEnum.ActiveChange,
    //         PolicyValidityStatusCodeEnum.ActiveEnded,
    //         PolicyValidityStatusCodeEnum.Future,
    //         PolicyValidityStatusCodeEnum.Transfer,
    //     ],
    //     disabled: false,
    // },
    {
        id: DirectControlButtonIdEnum.ChangeCoverage,
        order: 50,
        name: 'Versicherungsschutz ändern',
        actionButton: 'J',
        action: DirectControlButtonActionEnum.TogglePanel,
        actionLink: 'DekkingWijzigen',
        hash: SelectedPanelEnum.ChangeCoverage,
        icon: 'wijzig',
        product: [
            PolicyProductIdEnum.Car,
            PolicyProductIdEnum.Moped,
            PolicyProductIdEnum.Caravan,
            PolicyProductIdEnum.Motorcycle,
            PolicyProductIdEnum.Accidents,
            PolicyProductIdEnum.LegalAssistance,
            PolicyProductIdEnum.Travel,
            PolicyProductIdEnum.Home,
            PolicyProductIdEnum.Bicycle,
        ],
        status: [
            PolicyValidityStatusCodeEnum.Active,
            PolicyValidityStatusCodeEnum.Future,
            PolicyValidityStatusCodeEnum.Transfer,
        ],
    },
    {
        id: DirectControlButtonIdEnum.ExtraCar,
        order: 60,
        name: 'Weitere Versicherung',
        actionButton: 'J',
        action: DirectControlButtonActionEnum.Link,
        actionLink: `/${AppRoutesEnum.CarInsurance}/${SalesfunnelRoutes.CalculateCarInsurance}`,
        icon: null,
        product: [PolicyProductIdEnum.Car],
        status: [
            PolicyValidityStatusCodeEnum.Active,
            PolicyValidityStatusCodeEnum.ActiveChange,
            PolicyValidityStatusCodeEnum.ActiveEnded,
        ],
    },
    {
        id: DirectControlButtonIdEnum.InsuranceEndProduct,
        order: 90,
        name: 'Versicherung kündigen',
        actionButton: 'J',
        action: DirectControlButtonActionEnum.TogglePanel,
        actionLink: 'opzeggen',
        hash: SelectedPanelEnum.TerminateInsurance,
        icon: null,
        product: [
            PolicyProductIdEnum.Liability,
            PolicyProductIdEnum.Car,
            PolicyProductIdEnum.Moped,
            PolicyProductIdEnum.Caravan,
            PolicyProductIdEnum.Pet,
            PolicyProductIdEnum.Motorcycle,
            PolicyProductIdEnum.Accidents,
            PolicyProductIdEnum.LegalAssistance,
            PolicyProductIdEnum.Travel,
            PolicyProductIdEnum.Home,
            PolicyProductIdEnum.Bicycle,
        ],
        status: [PolicyValidityStatusCodeEnum.Active, PolicyValidityStatusCodeEnum.ActiveChange],
    },
];
