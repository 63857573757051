import * as layoutActions from './layout.actions';
import { BreadCrumbInterface } from '@app-de/core/interfaces/bread-crumb.interface';

export interface State {
    layout: {
        breadcrumb: BreadCrumbInterface[];
        activePage: string;
        scrollTo?: string;
        mobileMenu: boolean;
        mainMenu: boolean;
    };
}

export const initialState: State = {
    layout: {
        breadcrumb: [],
        activePage: '',
        mobileMenu: true,
        mainMenu: true,
    },
};

export function reducer(state: State = initialState, action: layoutActions.ActionTypes): State {
    switch (action.type) {
        case layoutActions.SET_BREADCRUMB: {
            const breadcrumb: BreadCrumbInterface[] = [];

            action.payload.forEach((item) => {
                const newItem = { ...item };
                if (!newItem.hasOwnProperty('href')) {
                    newItem.href = '';
                }

                if (!newItem.hasOwnProperty('routerLink')) {
                    newItem.routerLink = '';
                }

                breadcrumb.push(newItem);
            });

            return Object.assign({}, state, {
                layout: {
                    ...state.layout,
                    breadcrumb,
                },
            });
        }

        case layoutActions.HIDE_MOBILE_MENU: {
            return {
                ...state,
                layout: {
                    ...state.layout,
                    mobileMenu: false,
                },
            };
        }

        case layoutActions.SHOW_MOBILE_MENU: {
            return {
                ...state,
                layout: {
                    ...state.layout,
                    mobileMenu: true,
                },
            };
        }

        case layoutActions.HIDE_MAIN_MENU: {
            return {
                ...state,
                layout: {
                    ...state.layout,
                    mainMenu: false,
                },
            };
        }

        case layoutActions.SHOW_MAIN_MENU: {
            return {
                ...state,
                layout: {
                    ...state.layout,
                    mainMenu: true,
                },
            };
        }

        default: {
            return state;
        }
    }
}

export const getBreadcrumb = (state: State): BreadCrumbInterface[] => state.layout.breadcrumb;
export const getActivePage = (state: State): string => state.layout.activePage;
export const getScrollTo = (state: State): string => state.layout.scrollTo;
export const getMobileMenu = (state: State): boolean => state.layout.mobileMenu;
export const getMainMenu = (state: State): boolean => state.layout.mainMenu;
