<form (ngSubmit)="onFormSubmit()" [formGroup]="form">
    <cyno-field formGroupName="mobileTelephoneNumber">
        <cyno-label>Handynummer</cyno-label>
        <input
            autocomplete="tel"
            cynoInput
            cynoPhone
            e2e="telephoneNumber"
            formControlName="telephoneNumber"
            id="recovery-settings_telephone"
            inputmode="tel"
            name="telephoneNumber"
            type="tel"
        />
        <cyno-error *ngIf="telephoneNumber.touched && telephoneNumber.hasError('required')">
            Hoppla, die Telefonnummer fehlt noch
        </cyno-error>
        <cyno-error *ngIf="telephoneNumber.touched && telephoneNumber.hasError('invalidAsync')">
            Sorry, das ist keine gültige Handynummer
        </cyno-error>
    </cyno-field>

    <cyno-alert type="error" *ngIf="errors && errors.length" [notifications]="errors"></cyno-alert>
    <ins-details-submit-button [disabled]="disabled">Speichern</ins-details-submit-button>
</form>
