import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ErrorInterface } from '../../core';

@Injectable({
    providedIn: 'root',
})
export class DamageFreeYearsImpactEventService {
    private _damageFreeYearsImpact$: Subject<void>;

    public damageFreeYearsImpact$(): Observable<void> {
        this._damageFreeYearsImpact$ = new Subject();
        return this._damageFreeYearsImpact$.asObservable();
    }

    public onDamageFreeYearsImpactSuccess(): void {
        this._damageFreeYearsImpact$.next();
        this._damageFreeYearsImpact$.complete();
    }

    public onDamageFreeYearsImpactError(errors: ErrorInterface[]): void {
        if (this._damageFreeYearsImpact$) {
            this._damageFreeYearsImpact$.error(errors);
        }
    }
}
