import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectLayoutBreadcrumb, selectMainMenuState, selectMobileMenuState } from './layout.selectors';
import { BreadCrumbInterface } from '@app-de/core/interfaces/bread-crumb.interface';

@Injectable({
    providedIn: 'root',
})
export class LayoutSelectorService {
    constructor(private store$: Store) {}

    public getMobileMenu$(): Observable<boolean> {
        return this.store$.select(selectMobileMenuState);
    }

    public getMainMenu$(): Observable<boolean> {
        return this.store$.select(selectMainMenuState);
    }

    public breadcrumb$(): Observable<BreadCrumbInterface[]> {
        return this.store$.select(selectLayoutBreadcrumb);
    }
}
