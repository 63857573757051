import { Component, Input } from '@angular/core';
import { BreadCrumbInterface } from '@app-de/core/interfaces/bread-crumb.interface';

@Component({
    selector: 'ins-breadcrumb-bordered',
    templateUrl: './breadcrumb-bordered.component.html',
    styleUrls: ['./breadcrumb.shared.scss'],
})
export class BreadcrumbBorderedComponent {
    @Input() public breadcrumb: BreadCrumbInterface[];
}
