import { Component, HostBinding, Input } from '@angular/core';

@Component({
    selector: 'ins-tab',
    host: {
        class: 'tabset__content',
    },
    template: '<ng-content></ng-content>',
    styleUrls: ['tab.component.scss'],
})
export class TabComponent {
    @Input() public title: string;
    @Input() public active: boolean = false;
    @Input() public icon?: string;
    @Input() public hidden?: boolean;
    @Input() public id?: string = '';

    @HostBinding('class.tabset__content--hidden') public get isHidden(): boolean {
        return !this.active;
    }
}
