import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CoveragePremiumCoverageInterface, CoveragePremiumInterface } from 'outshared-lib';
import { AnnualPaymentService } from '@app-de/my-zone/services/annual-payment.service';

@Component({
    selector: 'ins-coverage-overview-current',
    templateUrl: 'coverage-overview-current.component.html',
    styleUrls: ['../coverage-overview.shared.scss'],
})
export class CoverageOverviewCurrentComponent implements OnChanges {
    @Input() public modulesCurrent: CoveragePremiumCoverageInterface[];
    @Input() public calculation: CoveragePremiumInterface;
    public currentPeriod = null;

    constructor(private annualPaymentService: AnnualPaymentService) {}

    public ngOnChanges(changes: SimpleChanges): void {
        this.currentPeriod = this.annualPaymentService.getCurrentPeriod(this.calculation?.product);
    }
}
