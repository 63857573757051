<ins-modal-close></ins-modal-close>

<ins-modal-title>Passwort vergessen?</ins-modal-title>

<ins-modal-content>
    <p>
        Tragen Sie hier die E-Mail-Adresse ein, mit der Sie sich bei uns angemeldet haben. Haben Sie diese vergessen?
        Fragen Sie dann erst Ihre E-Mail-Adresse ab und damit anschließend Ihr Passwort.
    </p>

    <form [formGroup]="form" role="form" (ngSubmit)="onSubmit()">
        <cyno-field>
            <cyno-label>E-Mail-Adresse</cyno-label>
            <input cynoInput type="email" formControlName="email" id="modal-email" autocomplete="email" />
        </cyno-field>

        <cyno-field>
            <cyno-label>Geburtsdatum</cyno-label>
            <cyno-birthdate
                formControlName="birthdate"
                placeholder="TT.MM.JJJJ"
                separator="."
                (keydown.enter)="onSubmit()"
            ></cyno-birthdate>
        </cyno-field>
    </form>

    <ins-alert type="validation" *ngIf="errors.length > 0" [errors]="errors"></ins-alert>
</ins-modal-content>

<ins-modal-actions>
    <ins-modal-actions-previous [link]="backLink"></ins-modal-actions-previous>

    <button class="btn btn--primary" [disabled]="form.invalid || this.loading" (click)="onSubmit()">Weiter</button>
</ins-modal-actions>
