import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MyZoneRoutes } from '@app-de/my-zone';
import { AppRoutesEnum } from '@app-de/routes';

@Component({
    selector: 'ins-no-damage-free-years-notification',
    templateUrl: './no-damage-free-years-notification.component.html',
    styleUrls: ['./no-damage-free-years-notification.component.scss'],
})
export class NoDamageFreeYearsNotificationComponent {
    @Input()
    public purchasedProductNumber: number;

    constructor(private router: Router) {}

    public goToDamageFreeYearsImpact(): void {
        this.router.navigate([AppRoutesEnum.MyZone, MyZoneRoutes.MyDamageFreeYears], {
            queryParams: {
                purchased_product: this.purchasedProductNumber,
            },
        });
    }
}
