<ul class="nav nav-toolbar authentication-menu">
    <li class="authentication-menu__item" *ngIf="(loggedIn$ | async) != null && !(loggedIn$ | async)">
        <a title="Einloggen" href="#" [routerLink]="['/', appRoutesEnum.Login]" id="gebruikerMenu">
            <wuc-icon-ui
                icon="user"
                type="dark"
                size="xs"
            ></wuc-icon-ui>
            <span class="authentication-menu__login-text">
                Einloggen
            </span>
        </a>
    </li>
    <li class="my-zone-menu authentication-menu__item" *ngIf="loggedIn$ | async">
        <div insDropdown [options]="{ align: 'right' }" #authenticationMenu="dropdown">
            <button
                insDropdownToggle
                (click)="authenticationMenu.toggle()"
                class="my-zone-menu__button"
                e2e="autorisation_menu_link"
            >
                <span class="my-zone-menu__btn-label-arrow">
                    <span class="my-zone-menu__btn-label-arrow-icon">
                        <wuc-icon icon="arrowRR" size="l"></wuc-icon>
                    </span>
                    Meine Versicherungsmappe
                </span>
                <wuc-icon-ui
                    icon="user"
                    type="dark"
                    size="xs"
                ></wuc-icon-ui>
                <span
                    class="my-zone-menu__name"
                    data-hj-suppress
                    e2e="user_fullname"
                    *ngIf="(loggedIn$ | async) && name"
                >
                    {{ name }}
                </span>
                <span class="my-zone-menu__skeleton" *ngIf="(loggedIn$ | async) && !name">
                    <wuc-skeleton width="12rem" height="1.5rem"></wuc-skeleton>
                </span>
                <span class="my-zone-menu_icon-down">
                    <wuc-icon-ui
                        icon="bottom-closed"
                        type="dark"
                        size="xxs"
                    ></wuc-icon-ui>
                </span>
            </button>
            <ul insDropdownMenu class="dropdown-menu my-zone-menu__dropdown" e2e="dropdown-menu">
                <li routerLinkActive="active" class="my-zone-menu__item">
                    <a
                        title="Meine Versicherungen"
                        [routerLink]="['/', appRoutesEnum.MyZone, myZoneRoutes.MyInsurances]"
                        e2e="versicherungen"
                    >
                        Meine Versicherungen
                    </a>
                </li>
                <li routerLinkActive="active" class="my-zone-menu__item">
                    <a
                        title="Meine Angaben"
                        [routerLink]="['/', appRoutesEnum.MyZone, myZoneRoutes.Details]"
                        e2e="personal_data"
                    >
                        Meine Angaben
                    </a>
                </li>
                <li routerLinkActive="active" class="my-zone-menu__item">
                    <a
                        title="Meine Dokumente"
                        [routerLink]="['/', appRoutesEnum.MyZone, myZoneRoutes.TermsAndConditions]"
                    >
                        Meine Dokumente
                    </a>
                </li>
                <li routerLinkActive="active" class="my-zone-menu__item">
                    <a title="Meine Rechnungen" [routerLink]="['/', appRoutesEnum.MyZone, myZoneRoutes.Invoices]">
                        Meine Rechnungen
                    </a>
                </li>
                <li routerLinkActive="active" class="my-zone-menu__item">
                    <a title="Jahresbelohnung" [routerLink]="['/', appRoutesEnum.MyZone, myZoneRoutes.AnnualReward]">
                        Jahresbelohnung
                    </a>
                </li>
                <li class="my-zone-menu__item">
                    <a title="Ausloggen" (click)="onLogout($event)" e2e="logout">Ausloggen</a>
                </li>
            </ul>
        </div>
    </li>
    <li class="authentication-menu__item">
        <a title="Suchen" [routerLink]="['/', appRoutesEnum.Contact]">
            <wuc-icon-ui
                icon="view"
                type="dark"
                size="xs"
            ></wuc-icon-ui>
        </a>
    </li>
</ul>
