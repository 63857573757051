<form (ngSubmit)="onFormSubmit()" [formGroup]="paymentDetailsForm">
    <cyno-field>
        <cyno-label>Kontonummer</cyno-label>
        <cyno-iban
            e2e="iban_bank_account_number"
            formControlName="ibanAccountNumber"
            id="ibanAccountNumber"
        ></cyno-iban>
        <!-- Errors -->
        <cyno-error
            *ngIf="
                paymentDetailsForm.controls['ibanAccountNumber'].hasError('required') &&
                paymentDetailsForm.controls['ibanAccountNumber'].touched
            "
        >
            Hoppla, da fehlt noch Ihre IBAN.
        </cyno-error>
        <cyno-error
            *ngIf="
                paymentDetailsForm.controls['ibanAccountNumber'].hasError('invalidIban') &&
                paymentDetailsForm.controls['ibanAccountNumber'].touched
            "
        >
            Ungültige IBAN-Kontonummer
        </cyno-error>

        <ins-element-validate-errors-container name="ibanAccountNumber"></ins-element-validate-errors-container>
    </cyno-field>

    <cyno-field>
        <cyno-label>Kontoinhaber</cyno-label>
        <input
            cynoInput
            type="text"
            name=""
            placeholder="Kontoinhaber"
            formControlName="accountHolderName"
            id="accountHolderName"
            e2e="input-payment-details-accountHolderName"
        />

        <!-- Errors -->
        <cyno-error
            *ngIf="
                !paymentDetailsForm.controls['accountHolderName'].valid &&
                paymentDetailsForm.controls['accountHolderName'].touched
            "
        >
            Hoppla, da fehlt noch der Name des Kontoinhabers.
        </cyno-error>
    </cyno-field>

    <cyno-field [showSuccess]="false" class="form-group">
        <cyno-label>SEPA-Lastschriftmandat</cyno-label>
        <cyno-info>
            <div class="alert alert--information">Wir buchen den Beitrag in den ersten 3–6 Tage des Monats ab.</div>
        </cyno-info>
        <cyno-checkbox
            e2e="select-payment-details-directDebitAgreementIndication"
            formControlName="directDebitAgreementIndication"
            id="directDebitAgreementIndication"
            value="J"
        >
            <div #rm="insReadMore" class="read-more" insReadMore>
                <p>
                    Ich ermächtige InShared, Zahlungen von meinem Konto mittels Lastschrift einzuziehen. Zugleich weise
                    ich mein Kreditinstitut an, die von InShared auf mein Konto gezogenen Lastschriften einzulösen.
                    Hinweis: Ich kann innerhalb von acht
                    <!-- prettier-ignore -->
                    <span>Wochen<ng-container *ngIf="rm.visible; else readMoreDots" class="read-more__collapse">,
                            beginnend mit dem Belastungsdatum, die Erstattung des belasteten Betrages verlangen. Es
                            gelten dabei die mit meinem Kreditinstitut vereinbarten Bedingungen. Vor dem ersten Einzug
                            einer SEPA-Basis-Lastschrift wird mich InShared über den Einzug in dieser Verfahrensart
                            unterrichten.
                        </ng-container>
                    </span>
                    <br />
                    <ins-read-more-toggle-button [readMore]="rm"></ins-read-more-toggle-button>
                </p>
            </div>
        </cyno-checkbox>
        <cyno-error
            *ngIf="
                paymentDetailsForm.controls['directDebitAgreementIndication'].touched &&
                !paymentDetailsForm.controls['directDebitAgreementIndication'].valid
            "
        >
            Tut uns leid. Wenn Sie mit dem automatischen Lastschriftverfahren nicht einverstanden sind, können wir Sie
            leider nicht versichern
        </cyno-error>
    </cyno-field>

    <cyno-alert type="error" *ngIf="errors && errors.length" [notifications]="errors"></cyno-alert>
    <ins-details-submit-button [disabled]="disabled">Speichern</ins-details-submit-button>
</form>

<ng-template #readMoreDots>...</ng-template>
