import { Component } from '@angular/core';
import { AuthenticationDataService, RelationDataService, RelationInterface } from 'outshared-lib';
import { Observable } from 'rxjs';

@Component({
    selector: 'ins-insurance-control-container',
    template: `
        <ins-insurance-control [relation]="relation$ | async" [isLoggedIn]="isLoggedIn$ | async">
        </ins-insurance-control>
    `,
})
export class InsuranceControlContainer {
    public relation$: Observable<RelationInterface> = this.relationDataService.getRelation$();
    public isLoggedIn$: Observable<boolean> = this.authenticationDataService.isLoggedIn$();

    constructor(
        private authenticationDataService: AuthenticationDataService,
        private relationDataService: RelationDataService,
    ) {}
}
